import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  Chip,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Container,
  Button,
  TextField,
  Grid,
  Paper,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";

//====================================

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditGdInTree() {
  const Navigate = useNavigate();
  let { state } = useLocation();

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [start_date_time, setStart_date_time] = useState(
    state.start_date_time
      ? moment
          .utc(toIsoFormat(state.start_date_time))
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
      : ""
  );
  const [end_date_time, setEnd_date_time] = useState(
    state.end_date_time
    ? moment
        .utc(toIsoFormat(state.end_date_time))
        .local()
        .format("YYYY-MM-DDTHH:mm:ss.SSS")
    : ""
  );

  const [is_shortlisted_candidates, setIs_shortlisted_candidates] =
    useState(false);
  const [colgOptions, setColgOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColgOptions, setSelectedColgOptions] = useState([state]);
  // all appointments
  const [selectedCollege_nameDate, setSelectedCollege_nameDate] =
    useState(null);

  const [studentOptions, setStudentOptions] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const [addInterviewerOptions, setInterviewerOptions] = useState([]);
  const [interviewer, setInerviewer] = useState([]);
  const [testInterviewer, setTestInterviewer] = useState([]);
  const [testStudent, setTestStudent] = useState([]);
  const [searchInterviewer, setSearchInterviewer] = useState("");

  const [searchStudentName, setSearchStudentname] = useState("");
  const [interviewerToRemove, setInterviewerToRemove] = useState([]);

  const handleShortListCandidates = (event) => {
    setIs_shortlisted_candidates(event.target.checked);
  };

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(state.is_offline ? 1 : 2);

  const getFilteredData = () => {
    if (!state || !state.interviewer) {
      return [];
    }
  
    return Object.entries(state.interviewer)
      .filter(
        ([key, value]) =>
          !interviewer.some((item) => item.id == key) &&
          !interviewerToRemove.some((item) => item.id == key)
      )
      .map(([key, value]) => ({ id: key, name: value.name }));
  };
  
  let data = getFilteredData();

  // const getFilteredData = () => {
  //   return Object.entries(state.interviewer_id)
  //     .filter(
  //       ([key, value]) =>
  //         !interviewer.some((item) => item.id == key) &&
  //         !interviewerToRemove.some((item) => item.id == key)
  //     )
  //     .map(([key, value]) => ({ id: key, name: value.name }));
  // };

  // let data = getFilteredData();

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);
  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      const keys = Object.keys(state.interviewer);
      try {
        if (keys.length > 0) {
          let addFacultyData = await AxiosInstance.get(
            `/user_mini?role_ids=3&is_not_blocked=${true}&user_id=${keys
              .toString()
              .split(",")
              .join("&user_id=")}`
          );
          let finalAddFaculty = addFacultyData.data.data
            ? addFacultyData.data.data
            : [];
          setInerviewer(finalAddFaculty);
          setTestInterviewer(finalAddFaculty);
        }
      } catch (error) {
        console.log(error);
        if (keys.length > 0) {
          setInerviewer([]);
          setTestInterviewer([]);
        }
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchInterviewer !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&search_keyword=${searchInterviewer}&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setInterviewerOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setInterviewerOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchInterviewer]);

  // useEffect(() => {
  //   let fetchData = async () => {
  //     const keys = state.id;

  //     try {
  //       if (Object.keys(state.student_details).length < 1) {
  //         setStudentName([]);
  //       } else {
  //         let addFacultyData = await AxiosInstance.get(
  //           `/gd_registered_students_list?group_id=${keys}`
  //         );
  //         let finalAddFaculty = addFacultyData.data.data;
  //         setStudentName(finalAddFaculty);
  //         setTestStudent(finalAddFaculty);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    let fetchData = async () => {
      try {
        if (!state || !state.id || !state.student_details) {
          console.error("State or its properties are not defined");
          return;
        }

        const keys = state.id;

        if (Object.keys(state.student_details).length < 1) {
          setStudentName([]);
        } else {
          let addFacultyData = await AxiosInstance.get(
            `/gd_registered_students_list?group_id=${keys}`
          );
          let finalAddFaculty = addFacultyData.data.data;
          setStudentName(finalAddFaculty);
          setTestStudent(finalAddFaculty);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      const keyData = Object.keys(state.appointment_id);

      if (searchStudentName !== "" && searchStudentName.length > 2) {
        let addFacultyData = await AxiosInstance.get(
          `/registered_students?appointment_id=${keyData}&search_keyword=${searchStudentName}&limit=10`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setStudentOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/registered_students?appointment_id=${keyData}&limit=10`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setStudentOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchStudentName]);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchTerm !== "") {
        let groupData = await AxiosInstance.get(
          `/group_list?search_keyword=${searchTerm}&limit=10`
        );
        let finalGroupData = groupData.data.data;
        if (finalGroupData) {
          setColgOptions(finalGroupData);
        }
      } else {
        let groupData = await AxiosInstance.get(`group_list?limit=10`);
        let finalGroupData = groupData.data.data;
        setColgOptions(finalGroupData);
      }
    };
    fetchData();
  }, [searchTerm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    //Remove Interviewers

    const newStudents =
      studentName.length > 0 ? studentName.map((e) => e.id.toString()) : [];

    const removeStudents =
      studentName.length > 0 &&
      newStudents.length > 0 &&
      Array.isArray(state.student_details)
        ? state.student_details
            .filter((ex) => !newStudents.includes(ex.id.toString()))
            .map((ex) => ex.id.toString())
        : [];

    // const newStudents =
    //   studentName.length > 0 ? studentName.map((e) => e.id.toString()) : [];
    // const removeStudents =
    //   studentName.length > 0 && newStudents.length > 0
    //     ? state.student_details
    //         .filter((ex) => !newStudents.includes(ex.id.toString()))
    //         .map((ex) => ex.id.toString())
    //     : [];

    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    try {
      const gdtDateTime = moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
      const gdEndDateTime = moment(end_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

      formData.append("id", state.id);
      // formData.append("group_name",groupName)
      // if (start_date_time !== "") {
      //   if (
      //     moment
      //       .utc(state.start_date_time)
      //       .local()
      //       .format("YYYY-MM-DDTHH:mm:ss.SSS") !== start_date_time
      //   ) {
      //     formData.append("gd_start_date", gdtDateTime.split(" ")[0]);
      //     formData.append(
      //       "gd_start_time",
      //       gdtDateTime.split(" ").slice(1).join(" ")
      //     );
      //   }
      // }
      // if (end_date_time !== "") {
      //   if (
      //     moment
      //       .utc(state.end_date_time)
      //       .local()
      //       .format("YYYY-MM-DDTHH:mm:ss.SSS") !== end_date_time
      //   ) {
      //     formData.append("gd_end_date", gdEndDateTime.split(" ")[0]);
      //     formData.append(
      //       "gd_end_time",
      //       gdEndDateTime.split(" ").slice(1).join(" ")
      //     );
      //   }
      // }
      const startDateTimeEdited =
        start_date_time !== "" &&
        moment
          .utc(state.start_date_time)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") !== start_date_time;

      const endDateTimeEdited =
        end_date_time !== "" &&
        moment
          .utc(state.end_date_time)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") !== end_date_time;

      let gdStatus = state.is_offline ? 1 : 2;
      if (examTypeList != gdStatus) {
        formData.append("is_offline", examTypeList == 1 ? "true" : "false");
      }

      // const hasExtraObjectsInArray2 = interviewer.some(
      //   (obj2) => !testInterviewer.some((obj1) => obj1.id === obj2.id)
      // );

      // if (testInterviewer.length > 0 || interviewer.length > 0) {
      //   if (hasExtraObjectsInArray2) {
      //     interviewer.map((value) => {
      //       formData.append("add_interviewer_id", value.id);
      //     });
      //   }
      // }

      const hasExtraObjectsInArray2 = interviewer.filter(
        (obj2) => !testInterviewer.some((obj1) => obj1.id === obj2.id)
      );

      if (hasExtraObjectsInArray2.length > 0) {
        hasExtraObjectsInArray2.forEach((value) => {
          formData.append("add_interviewer_id", value.id);
        });
      }

      if (interviewerToRemove.length > 0) {
        interviewerToRemove.forEach((value) => {
          formData.append("remove_interviewer_id", value.id);
        });
      }

      const studentsToAdd = studentName.filter(
        (obj2) => !testStudent.some((obj1) => obj1.id === obj2.id)
      );

      if (studentsToAdd.length > 0) {
        studentsToAdd.forEach((value) => {
          formData.append("add_student", value.id);
          // formData.append("appointment_id", Object.keys(state.appointment_id));
        });
      }
      if (studentsToAdd.length > 0) {
        formData.append("appointment_id", Object.keys(state.appointment_id));
      }

      if (removeStudents.length > 0) {
        removeStudents.forEach((e) => {
          formData.append("remove_student", Number.parseInt(e));
        });
      }

      if (
        startDateTimeEdited ||
        endDateTimeEdited ||
        hasExtraObjectsInArray2.length > 0
      ) {
        formData.append("gd_start_date", gdtDateTime.split(" ")[0]);
        formData.append(
          "gd_start_time",
          gdtDateTime.split(" ").slice(1).join(" ")
        );

        if (
          (state.is_offline != 1 || examTypeList == 2) &&
          examTypeList != 1
        ) {
          formData.append("gd_end_date", gdEndDateTime.split(" ")[0]);
          formData.append(
            "gd_end_time",
            gdEndDateTime.split(" ").slice(1).join(" ")
          );
        }
      }

      //   const dateTimeToSeconds = (dateTime) => {
      //     return dateTime.getTime() / 1000;
      //   };
      //   const todayDate = new Date();
      //   const startDate = new Date(gdtDateTime);
      //   const endDate = new Date(gdEndDateTime);
      //   const today = dateTimeToSeconds(todayDate);
      //   const start = dateTimeToSeconds(startDate);
      //   const end = dateTimeToSeconds(endDate);
      //   if (end > start && start > today) {
      //     try {
      //       await AxiosInstance.patch("/gd_group", formData, config, {
      //         Authorization: `${token}`,
      //       })
      //         .then((response) => {
      //           if (response.status === 200) {
      //             toast.success("GD Updated Successfully");
      //             setTimeout(() => {
      //               Navigate("/");
      //             }, 1000);
      //           }
      //         })
      //         .catch((error) => {
      //           toast.error(error.response.data.error);
      //         });
      //     } catch (error) {
      //       console.log(error);
      //       console.log(error.message);
      //     }
      //   } else {
      //     if (start <= today) {
      //       toast.warn("Start date should be always greater than current date");
      //     }
      //     if (end <= start) {
      //       toast.warn("End date should be always greater than start date");
      //     }
      //   }
      // } catch (error) {
      //   console.log(error);
      //   console.log(error.message);
      // }
      const dateTimeToSeconds = (dateTime) => {
        return dateTime.getTime() / 1000;
      };

      const todayDate = new Date();
      const startDate = new Date(gdtDateTime);
      const endDate = new Date(gdEndDateTime);
      const today = dateTimeToSeconds(todayDate);
      const start = dateTimeToSeconds(startDate);
      const end = dateTimeToSeconds(endDate);

      // Check conditions before making the API call
      let shouldSubmit = true;

      if (state.is_offline != 1 && examTypeList != 1) {
        if (end <= start) {
          toast.warn("End date should always be greater than start date.");
          shouldSubmit = false;
        }
      }

      if (start <= today) {
        toast.warn("Start date should be always greater than current date.");
        shouldSubmit = false;
      }

      if (shouldSubmit) {
        // Make API call if all conditions are met
        try {
          await AxiosInstance.patch("/gd_group", formData, config);
          toast.success("GD Updated Successfully");
          setTimeout(() => {
            Navigate("/");
          }, 1000);
        } catch (error) {
          toast.error(error.response.data.error);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "70%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#081f37",
              fontSize: {
                xs: "20px", // Smaller font size on small screens
                sm: "25px", // Medium font size on small to medium screens
                md: "30px", // Larger font size on medium to large screens
              },
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            GD Edit
          </Box>
        </Box>
      
          <Paper
            variant="outlined"
            style={{ border: "2px solid #9BA4B5", width: "100%",borderRadius:"10px"  }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    GD Start DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="start_date_time"
                        required
                        fullWidth
                        type="datetime-local"
                        id="start_date_time"
                        value={start_date_time}
                        onChange={(e) => setStart_date_time(e.target.value)}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {examTypeList == 1 ? (
                    ""
                  ) : (
                    <>
                      <Grid
                        item
                        xs={3}
                        sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                      >
                        GD End DateTime :
                      </Grid>
                      <Grid Grid item xs={9}>
                        <FormControl style={{ width: "80%" }}>
                          <TextField
                            name="end_date_time"
                            fullWidth
                            type="datetime-local"
                            id="end_date_time"
                            value={end_date_time}
                            onChange={(e) => setEnd_date_time(e.target.value)}
                            inputProps={{
                              min: new Date().toISOString().slice(0, 16),
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    Mode Of GD :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      {/* <FormControl fullWidth> */}
                      <InputLabel id="demo-simple-select-label">
                        Mode Of GD
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={examTypeList}
                        label="Mode Of GD"
                        onChange={(e) => {
                          setExamTypeList(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Default border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                          },
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {/* </FormControl> */}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    Interviewer :
                  </Grid>
                  {/* <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchInterviewer(e.target.value);
                        }}
                      >
                        <Autocomplete
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                          required
                          multiple
                          options={
                            addInterviewerOptions ? addInterviewerOptions : []
                          }
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.full_name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.full_name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Interviewer"
                              placeholder="Select Multiple Interviewer"
                            />
                          )}
                          value={interviewer}
                          onChange={(e, value) => {
                            setInerviewer([...value]);
                          }}
                        />
                      </Item>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchInterviewer(e.target.value);
                        }}
                      >
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          required
                          size="small"
                          value={interviewer}
                          onChange={(e, value) => {
                            const removedInterviewers = interviewer.filter(
                              (interviewer) =>
                                !value.some(
                                  (selected) => selected.id === interviewer.id
                                )
                            );
                            setInterviewerToRemove([
                              ...interviewerToRemove,
                              ...removedInterviewers,
                            ]);
                            setInerviewer(value);
                          }}
                          options={
                            addInterviewerOptions ? addInterviewerOptions : []
                          }
                          getOptionLabel={(option) => option.full_name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                key={option.full_name + (index + 1)}
                                label={option.full_name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Interviewer"
                              variant="outlined"
                              color="warning"
                              InputProps={{
                                ...params.InputProps,
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Item>
                    </FormControl>
                  </Grid>
                  {data.length > 0 ? (
                    <Grid item xs={9} style={{ marginLeft: "25%" }}>
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {data.map((val) => val.name).join(", ")}{" "}
                        <span style={{ color: "#FF6969" }}>
                          (" These Interviewers are Blocked ")
                        </span>
                      </p>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    Student Name:
                  </Grid>
                  {/* <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchStudentname(e.target.value);
                        }}
                      >
                        <Autocomplete
                          required
                          multiple
                          options={studentOptions}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.full_name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.full_name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Students"
                              placeholder="Select Multiple Students"
                            />
                          )}
                          value={studentName}
                          onChange={(e, value) => {
                            setStudentName(value);
                          }}
                        />
                      </Item>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchStudentname(e.target.value);
                        }}
                      >
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          options={studentOptions ? studentOptions : []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.full_name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.full_name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Students"
                              placeholder="Select Multiple Students"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                          value={studentName}
                          onChange={(e, value) => {
                            const hasEvaluation = value.some(
                              (option) =>
                                option.gd_marks && option.gd_marks.gd_status
                            );

                            if (hasEvaluation) {
                              toast.warning(
                                "Evaluation already exists for this student",
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                }
                              );
                            } else {
                              setStudentName(value);
                            }
                          }}
                        />
                      </Item>
                    </FormControl>
                  </Grid>
                  {/* <Grid item>
                    <Checkbox
                      name="is_shortlisted_candidates"
                      checked={is_shortlisted_candidates}
                      onChange={handleShortListCandidates}
                      id="is_shortlisted_candidates"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label
                      htmlFor="shortListCandidates"
                      style={{ fontSize: "16px" }}
                    >
                      ShortListed Candidates
                    </label>
                  </Grid> */}
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                   <Button
                    variant="contained"
                    style={{ width: "15%", backgroundColor: "crimson" }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "15%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
       
      </article>
    </section>
  );
}
