import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  styled,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Style from "./studentDataNew.module.css";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";
import { toast } from "react-toastify";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { TiDelete } from "react-icons/ti";
import decodeSpl from "../utils/decodeSpl";
import { makeStyles } from "@material-ui/core/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#081F37",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    "& .MuiInputLabel-root": {
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",
    },
  },
}));

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function OtherPincodeStudentDetails() {
  const classes = useStyles();
  //location data
  const term1 = window.sessionStorage.getItem("searchName");
  const term2 = window.sessionStorage.getItem("searchEmail");
  const term3 = window.sessionStorage.getItem("searchMobile");
  const term4 = window.sessionStorage.getItem("examTypeList");

  const location = useLocation();
  const [searchParams, setSearchParms] = useSearchParams();
  const pincode = searchParams.get("id");
  const pageHistory = searchParams.get("page");
  const filterHistory = searchParams.get("filter");
  const urlParam = location.state ? location.state : "";
  let [filtered, setFiltered] = useState(false);
  let [studentList, setStudentList] = useState([]);

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(
    term4 && term4 !== "" ? JSON.parse(term4) : ""
  );

  let [searchName, setSearchName] = useState(
    term1 && term1 !== "" ? JSON.parse(term1) : ""
  );
  let [searchEmail, setSearchEmail] = useState(
    term2 && term2 !== "" ? JSON.parse(term2) : ""
  );
  let [searchMobile, setSearchMobile] = useState(
    term3 && term3 !== "" ? JSON.parse(term3) : ""
  );
  const [selectedQualification, setSelectedQualification] = useState(
    window.sessionStorage.getItem("qualification")
      ? JSON.parse(window.sessionStorage.getItem("qualification"))
      : null
  );
  const [qualificationSearch, setQualificationSearch] = useState("");
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState("");
  useEffect(() => {
    const fetchPreviousData = async () => {
      const page = Number(pageHistory);
      const filters = filterHistory ? decodeSpl(filterHistory) : "";
      try {
        const { data } = await AxiosInstance.get(
          `/user_list?limit=${pagination.limit}${filters}&page=${page}&other_pincode`
        );
        setStudentList(data.data && data.data.length > 0 ? data.data : []);
        setPagination({
          ...pagination,
          length: data.total_length,
          page: page,
          load: data.total_length > pagination.limit ? false : null,
        });
        setAppliedFilter(filters);
        setFiltered(true);
        setSearchParms("id=1");
        window.sessionStorage.setItem("filter", 1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(null);
      }
    };
    if (filterHistory || pageHistory) fetchPreviousData();
  }, []);
  const fetchUserPincode = async () => {
    setLoad(true);
    try {
      const { data } = await AxiosInstance.get(
        `/user_list?limit=${pagination.limit}&other_pincode`
      );
      setStudentList(data.data && data.data.length > 0 ? data.data : []);
      setPagination({
        ...pagination,
        length: data.total_length,
        page: 1,
        load: data.total_length > pagination.limit ? false : null,
      });
      setFiltered(true);
      setLoad(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setLoad(null);
    }
  };
  useEffect(() => {
    const fetchData1 = async () => {
      const filter = window.sessionStorage.getItem("filter");
      try {
        if (qualificationSearch !== "") {
          let qualificationData = await AxiosInstance.get(
            `/qualification?search_keyword=${qualificationSearch}&limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(finalQualificationData);
          if (filter) {
            fetchUsers();
          }
        } else {
          let qualificationData = await AxiosInstance.get(
            `/qualification?limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(finalQualificationData);
          if (filter) {
            fetchUsers();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (!pageHistory && !filterHistory) {
      if (!pincode) {
        fetchData1();
      } else {
        fetchUserPincode();
      }
    }
  }, []);

  // Paid Status
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/paid_status`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  const [load, setLoad] = useState(false);
  const [pagination, setPagination] = useState({
    length: 1,
    page: 1,
    load: false,
    limit: 50,
  });
  const fetchUsers = async (x) => {
    setLoad(true);
    const filter1 = pincode
      ? "&other_pincode"
      : selectedQualification
      ? urlParam
        ? `&${urlParam.split("$").join(`${selectedQualification.name}`)}`
        : `&${selectedQualification.name}`
      : "";
    const filter2 =
      searchName && searchName.length > 2
        ? `&search_keyword=${searchName}`
        : "";
    const filter3 =
      searchEmail && searchEmail.length > 2 ? `&email=${searchEmail}` : "";
    const filter4 =
      searchMobile && searchMobile.length > 2 ? `&mobile=${searchMobile}` : "";
    const filter5 = examTypeList == 1 ? "&is_paid=true" : "";
    const filters = filter1 + filter2 + filter3 + filter4 + filter5;
    setAppliedFilter(filters);
    try {
      const { data } = await AxiosInstance.get(
        `/user_list?limit=${pagination.limit}${filters}`
      );
      setStudentList(data.data && data.data.length > 0 ? data.data : []);
      setPagination({
        ...pagination,
        length: data.total_length,
        page: 1,
        load: data.total_length > pagination.limit ? false : null,
      });
      setFiltered(true);
      window.sessionStorage.setItem("filter", 1);
      setLoad(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setLoad(null);
    }
  };
  const resetFilter = async () => {
    window.sessionStorage.clear();
    setAppliedFilter("");
    if (pincode) {
      if (
        searchEmail === "" &&
        searchName === "" &&
        searchMobile === "" &&
        examTypeList === ""
      ) {
        toast.warn("no filters applied yet");
      } else {
        setFiltered(false);
        setSearchEmail("");
        setSearchName("");
        setSearchMobile("");
        fetchUserPincode();
        setExamTypeList("");
      }
    } else {
      setFiltered(false);
      setSearchEmail("");
      setSearchName("");
      setSearchMobile("");
      setSelectedQualification(null);
      setExamTypeList("");
    }
  };
  const handleChangePage = async (_, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
      load: true,
    });
    const filter1 = pincode
      ? "&other_pincode"
      : selectedQualification
      ? urlParam
        ? `&${urlParam.split("$").join(`${selectedQualification.name}`)}`
        : `&${selectedQualification.name}`
      : "";
    const filter2 =
      searchName && searchName.length > 2
        ? `&search_keyword=${searchName}`
        : "";
    const filter3 =
      searchEmail && searchEmail.length > 2 ? `&email=${searchEmail}` : "";
    const filter4 =
      searchMobile && searchMobile.length > 2 ? `&mobile=${searchMobile}` : "";
    const filter5 = examTypeList === 1 ? "&is_paid=true" : "";
    const filters = filter1 + filter2 + filter3 + filter4 + filter5;
    try {
      const { data } = await AxiosInstance.get(
        `/user_list?limit=${pagination.limit}${filters}&page=${newPage}`
      );
      setStudentList(data.data && data.data.length > 0 ? data.data : []);
      setPagination({
        ...pagination,
        length: data.total_length,
        page: newPage,
        load: data.total_length > pagination.limit ? false : null,
      });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setPagination({
        ...pagination,
        load: false,
        page: newPage,
      });
    }
  };

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setExamTypeList("");
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "4%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>Students List For Other Pincode</h1>
      <Paper
        style={{
          maxHeight: "auto",
          width: "auto",
          margin: "0 auto",
          border: "1px solid #111",
          marginTop: "-1%",
        }}
      >
        <aside className={Style.actionBlock} style={{ width: "100%" }}>
          {/* <div className={Style.inputSearchContainer}> */}
          <Grid  
            container
            style={{
              marginTop: "5px",
            }}
          >
            {!pincode && (
              <Grid item sm={1.7}>
                <Item>
                  <Autocomplete
                    size="small"
                    options={qualificationOptions}
                    value={selectedQualification}
                    disabled={load}
                    name="qualification"
                    onChange={(event, newStream, x, y) => {
                      if (y) {
                        window.sessionStorage.setItem(
                          "qualification",
                          JSON.stringify(y.option)
                        );
                      }
                      setSelectedQualification(newStream);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Qualification"
                        variant="outlined"
                        onChange={async (e) => {
                          const term = e.target.value;
                          if (term.length > 2) {
                            const { data } = await AxiosInstance.get(
                              `/qualification?search_keyword=${term}&limit=10`
                            );
                            setQualificationOptions(data.data);
                          }
                        }}
                        classes={{ root: classes.inputRoot }}
                      />
                    )}
                  />
                </Item>
              </Grid>
            )}
            <aside className={Style.actionBlock} style={{ marginLeft: "-1%" }}>
              <div className={Style.inputSearchContainer}>
                <input
                  placeholder=" Search By Name"
                  type="search"
                  className={Style.searchField}
                  disabled={load}
                  value={searchName}
                  onChange={(e) => {
                    window.sessionStorage.setItem(
                      "searchName",
                      JSON.stringify(e.target.value)
                    );
                    setSearchName(e.target.value);
                  }}
                />
                <b style={{ position: "absolute", left: "6px", top: "30%" }}>
                  <svg height="1.2em" viewBox="0 0 512 512" fill="#081F37">
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                  </svg>
                </b>
              </div>
              <div className={Style.inputSearchContainer}>
                <input
                  placeholder=" Search By Email"
                  type="search"
                  disabled={load}
                  className={Style.searchField}
                  value={searchEmail}
                  onChange={(e) => {
                    window.sessionStorage.setItem(
                      "searchEmail",
                      JSON.stringify(e.target.value)
                    );
                    setSearchEmail(e.target.value);
                  }}
                />
                <b style={{ position: "absolute", left: "6px", top: "30%" }}>
                  <svg height="1.2em" viewBox="0 0 512 512" fill="#081F37">
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                  </svg>
                </b>
              </div>
              <div className={Style.inputSearchContainer}>
                <input
                  placeholder=" Search By Number"
                  type="search"
                  disabled={load}
                  className={Style.searchField}
                  value={searchMobile}
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  onChange={(e) => {
                    window.sessionStorage.setItem(
                      "searchMobile",
                      JSON.stringify(e.target.value)
                    );
                    setSearchMobile(e.target.value);
                  }}
                />
                <b style={{ position: "absolute", left: "6px", top: "30%" }}>
                  <svg height="1.2em" viewBox="0 0 512 512" fill="#081F37">
                    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                  </svg>
                </b>
              </div>
            </aside>
            <Grid  style={{ width: "14vw", marginTop: "0.6%", marginLeft: "-1.5%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label" style={{marginTop:"-2%"}}>Q-Enquiry</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={examTypeList}
                  label="Q-Enquiry"
                  onChange={(e) => {
                    window.sessionStorage.setItem(
                      "examTypeList",
                      JSON.stringify(e.target.value)
                    );
                    setExamTypeList(e.target.value);
                  }}
                >
                  {Object.entries(examTypeListOption).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {examTypeList !== "" ? (
                  <Tooltip title="Clear Status">
                    <div
                      style={{
                        position: "absolute",
                        top: "9px",
                        left: "170px",
                        color: "#CC7351",
                        cursor: "pointer",
                      }}
                    >
                      <TiDelete
                        fontSize={24}
                        title="Reset Status"
                        onClick={resetAppStatus}
                      />
                    </div>
                  </Tooltip>
                ) : null}
              </FormControl>
            </Grid>
            <Grid
              item
              md={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={load}
                onClick={() => {
                  if (
                    selectedQualification ||
                    searchName ||
                    searchEmail ||
                    searchMobile ||
                    examTypeList ||
                    pincode
                  ) {
                    if (selectedQualification) {
                      fetchUsers();
                    } else if (pincode) {
                      if (
                        searchEmail === "" &&
                        searchName === "" &&
                        searchMobile === "" &&
                        examTypeList === ""
                      ) {
                        toast.warn(
                          "please search by name,email or mobile Number to filter"
                        );
                      } else {
                        fetchUsers();
                      }
                    } else {
                      toast.warn("please select a Qualification");
                    }
                  } else {
                    toast.warn("No filters selected");
                  }
                }}
                style={{
                  fontSize: "16px",
                  color: "white",
                  padding: "4px 30px",
                  cursor: "pointer",
                  backgroundColor: "green",
                  borderRadius: "40px",
                  border: "3px solid #efefef",
                }}
              >
                {load ? "Loading..." : "Search"}
              </button>
              {filtered && (
                <button
                  type="button"
                  onClick={resetFilter}
                  style={{
                    fontSize: "16px",
                    color: "white",
                    padding: "4px 30px",
                    cursor: "pointer",
                    backgroundColor: "crimson",
                    borderRadius: "40px",
                    border: "3px solid #efefef",
                  }}
                >
                  Reset
                </button>
              )}
            </Grid>
          </Grid>
        </aside>
        {!filtered ? (
          <>
            {load ? (
              <h4
                style={{
                  textAlign: "center",
                  color: "#495579",
                  marginTop: "5vh",
                }}
              >
                Loading...
              </h4>
            ) : (
              <h4
                style={{
                  textAlign: "center",
                  color: "#495579",
                  marginTop: "5vh",
                }}
              >
                Please select the qualification to show the list{" "}
                <small>Search name/email if required</small>
              </h4>
            )}
          </>
        ) : (
          <>
            <h1
              style={{ marginLeft: "40%", marginTop: "0%", color: "#495579" }}
            >
              Total Students Count : {pagination.length}{" "}
            </h1>
            <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
              <Table
                sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                aria-label="simple table"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" style={{ width: "150px" }}>
                      Student ID
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "200px" }}>
                      Student Name
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "200px" }}>
                      Mobile Number
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "350px" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "200px" }}>
                      Other Pincodes
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "300px" }}>
                      Q-Enquiry
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "270px" }}>
                      Edit
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagination.load ? (
                    <StyledTableRow component="th" scope="row" align="center">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        colSpan={7}
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "red",
                        }}
                      >
                        Loading...
                      </TableCell>
                    </StyledTableRow>
                  ) : studentList.length > 0 ? (
                    studentList.map((val, ind) => {
                      return (
                        <StyledTableRow key={ind + 1}>
                          <TableCell component="th" scope="row" align="center">
                            {val.id}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {val.full_name}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {val.mobiles === null
                              ? "N/A"
                              : val.mobiles?.map((mob) => <Box>{mob}</Box>)}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {val.emails === null
                              ? "N/A"
                              : val.emails?.map((mail) => <Box>{mail}</Box>)}
                          </TableCell>

                          <TableCell component="th" scope="row" align="center">
                            {val?.other_fields?.student?.current_address
                              ?.other_pincode === ""
                              ? "N/A"
                              : val?.other_fields?.student?.current_address
                                  ?.other_pincode}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {val.is_paid === true ? (
                              <Button
                                style={{ background: "#294B29", color: "#fff" }}
                              >
                                Paid
                              </Button>
                            ) : (
                              <p>-----</p>
                            )}
                          </TableCell>

                          <TableCell component="th" scope="row" align="center">
                            <Link
                              to="/personal_details_form"
                              title="Update Student Info"
                              style={{ cursor: "pointer" }}
                              state={{
                                data: val,
                                page: pagination.page,
                                filter: appliedFilter,
                                path: window.location.pathname,
                                pin: pincode,
                              }}
                            >
                              <Button style={{ border: "1px solid" }}>
                                Personal Details &nbsp;
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="orange"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                                  <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                                </svg>
                              </Button>
                            </Link>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow component="th" scope="row" align="center">
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        colSpan={7}
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "red",
                        }}
                      >
                        No Data
                      </TableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {pagination.load !== null && (
              <Pagination
                style={{ maxWidth: "100%", marginTop: "1%", marginLeft: "40%" }}
                count={Math.ceil(pagination.length / pagination.limit)}
                sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
                page={pagination.page}
                onChange={handleChangePage}
              />
            )}
          </>
        )}
      </Paper>
    </section>
  );
}
