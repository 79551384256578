import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Pagination,
} from "@mui/material";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: theme.palette.common.white,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OtpFailedList() {
  const [otpFailed, setOtpFailed] = useState([]);

  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchData = async () => {
      setLoad(true);
      try {
        let quizData = await AxiosInstance.get(
          `/otp_failed_user_list?page=${page}`
        );
        let finalData = quizData.data.data;
        if (finalData !== null) {
          setOtpFailed(finalData);
        }
        setTotalLength(quizData.data.total_length);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, [page]);

  const totalPages = Math.ceil(totalLength / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <section style={{ width: "100%", marginTop: "4%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
          paddingLeft: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
          }}
        >
          OTP Failed List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalLength}
        </h1>
      </Box>
      <article
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          // border: "1px solid #081F37",
          marginTop: "0.5%",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 680 : 550 }}
          // style={{ marginTop: "0.5%" }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  Appointment Name
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Created Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  OTP Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Meeting ID
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={6} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : otpFailed && otpFailed.length > 0 ? (
                otpFailed.map((row, index) => (
                  <React.Fragment>
                    <StyledTableRow key={index + 1}>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        {calculateSlNo(index)}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          textTransform: "capitalize",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                        }}
                      >
                        {Object.keys(row.appointments).length > 0
                          ? Object.keys(row.appointments).map(
                              (key) => row.appointments[key].name
                            )
                          : "NA"}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.created_at === null || row.created_at === ""
                          ? "N/A"
                          : moment(row.created_at)
                              .local()
                              .format("DD-MM-yyyy hh:mm A")}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.mobile === null || row.mobile === "" ? (
                          "N/A"
                        ) : (
                          <a
                            href={`tel:${row.mobile}`}
                            style={{
                              color: "#125B9A",
                              fontWeight:"bold"
                              // textDecoration: "none",
                            }}
                          >
                            {row.mobile}
                          </a>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.otp_count === null || row.otp_count === ""
                          ? "N/A"
                          : row.otp_count}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.meeting_id === null || row.meeting_id === ""
                          ? "N/A"
                          : row.meeting_id}
                      </StyledTableCell>
                      {/* <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.registration_successfull === null ||
                        row.registration_successfull === "" ? (
                          "N/A"
                        ) : row.registration_successfull === true ? (
                          <b style={{ color: "green" }}>Successful</b>
                        ) : (
                          <b style={{ color: "red" }}>Failed</b>
                        )}
                      </StyledTableCell> */}
                    </StyledTableRow>
                  </React.Fragment>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={6}>
                    {/* <LinearProgress sx={{ color: "Red" }} /> */}
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            maxWidth: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0.5% auto",
          }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </article>
    </section>
  );
}
