import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

const StudentCard = () => {
  useEffect(()=>{
    window.sessionStorage.clear()
  },[])
  return (
    <Card
      sx={{
        maxWidth: 500,
        margin: "auto",
        textAlign: "left",
        // background: '#081F37',
        background: "#efefef",
        marginTop: "6%",
        borderRadius: "10px",
        height: "auto",
        zIndex: "99",
        border: "1px solid #111",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          style={{ color: "#000", fontWeight: "bold", textAlign: "center" }}
        >
          Students List
        </Typography>
      </CardContent>
      <CardActions>
        <NavLink
          to="/student_details"
          state=""
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              borderBottom: "1px solid #000",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Student Details
          </Button>
        </NavLink>
      </CardActions>

      <CardActions>
        <NavLink
          to="/othercollege_studentdetails"
          state="other_$_college"
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              borderBottom: "1px solid #000",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              // border:"0.1px solid #111",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Other Colleges
          </Button>
        </NavLink>
      </CardActions>
      <CardActions>
        <NavLink
          to="/otherstream_studentdetails"
          state="other_$_stream"
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              borderBottom: "1px solid #000",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Other Streams
          </Button>
        </NavLink>
      </CardActions>
      <CardActions>
        <NavLink
          to="/otherdegree_studentdetails"
          state="other_$_type"
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              borderBottom: "1px solid #000",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Other Degree
          </Button>
        </NavLink>
      </CardActions>
      <CardActions>
        <NavLink
          to="/otherdistrict_studentdetails"
          state="other_$_district"
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              borderBottom: "1px solid #000",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Other District
          </Button>
        </NavLink>
      </CardActions>
      <CardActions>
        <NavLink
          to="/otheruniversity_studentdetails"
          state="other_$_university"
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              borderBottom: "1px solid #000",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Other Universities
          </Button>
        </NavLink>
      </CardActions>
      <CardActions>
        <NavLink
          to="/otherpincode_studentdetails?id=1"
          style={{ textDecoration: "none", color: "white", width: "100%" }}
        >
          <Button
            sx={{
              color: "#000",
              width: "100%",
              display: "flex",
              borderBottom: "1px solid #000",
              justifyContent: "flex-start",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#081F37",
                color: "#fff",
              },
            }}
          >
            Other Pincodes
          </Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};

export default StudentCard;
