import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Autocomplete,
  Grid,
  TextField,
  Tooltip,
  FormControl,
  Button,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AxiosInstance from "../../../apis/AxiosInstance";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function AppointmentDetailsList() {
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [selectedDegree, setSelectedDegree] = useState(null);
  const [selectedDegreeOptions, setSelectedDegreeOptions] = useState([]);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [yop, setYop] = useState(null);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();

  const [statusOption, setStatusOption] = useState([]);
  const [statusData, setStatusData] = useState(null);
  const [searchStatus, setSearchStatus] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [page, setPage] = useState(1);
  const [totalColleges, setTotalColleges] = useState(0);

  let [searchFilter, setSearchFilter] = useState("");

  let [searchNumber, setSearchNumber] = useState("");
  let [searchName, setSearchName] = useState("");
  let [searchEmail, setSearchEmail] = useState("");
  let [appointmentDate, setAppointmentDate] = useState("");

  const [searchDisable, setSearchDisable] = useState(false);
  const [searchSuccess, setSearchSuccess] = useState(false);

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  const [toastId, setToastId] = useState(null);

  // //! Fetch the college
  let [searchedColleges, SetsearchedColleges] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/appointment?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        SetsearchedColleges(finalData);
        setTotalColleges(data.data.filtered_count);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/appointment?page=${newPage}`;
    setLoad(true);
    try {
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (yop) {
        endpoint += `&yop=${yop}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&college_id=${selectedCollege.id}`;
      }
      if (statusData !== null) {
        endpoint += `&status=${statusData.id}`;
      }
      if (selectedDegree !== null) {
        endpoint += `&degree_id=${selectedDegree.id}`;
      }
      if (searchNumber) {
        endpoint += `&spoc_mobile=${searchNumber}`;
      }
      if (searchName) {
        endpoint += `&spoc_name=${searchName}`;
      }
      if (searchEmail) {
        endpoint += `&spoc_email=${searchEmail}`;
      }
      if (appointmentDate) {
        endpoint += `&appointment_date=${appointmentDate}`;
      }

      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/appointment?page=${1}`;
    setIsSearchDisabled(true);
    try {
      if (
        !yop &&
        !fromDate &&
        !toDate &&
        !unitNameData &&
        !statusData &&
        !selectedColgState &&
        !selectedColgDistrict &&
        !selectedCollege &&
        !selectedDegree &&
        !searchNumber &&
        !searchName &&
        !searchEmail &&
        !appointmentDate
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }

      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (yop) {
        endpoint += `&yop=${yop}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (statusData !== null) {
        endpoint += `&status=${statusData.id}`;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&college_id=${selectedCollege.id}`;
      }
      if (selectedDegree !== null) {
        endpoint += `&degree_id=${selectedDegree.id}`;
      }
      if (searchNumber) {
        endpoint += `&spoc_mobile=${searchNumber}`;
      }
      if (searchName) {
        endpoint += `&spoc_name=${searchName}`;
      }
      if (searchEmail) {
        endpoint += `&spoc_email=${searchEmail}`;
      }
      if (appointmentDate) {
        endpoint += `&appointment_date=${appointmentDate}`;
      }

      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(1);
      setSearchSuccess(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchSuccess(false);
    }
  };

  const totalPages = Math.ceil(totalColleges / 10);

  useEffect(() => {
    const fetchData = async () => {
      let response;
      if (searchStatus !== "") {
        response = await AxiosInstance.get(
          `/appointment_status?search_keyword=${searchStatus}&limit=10`
        );
      } else {
        response = await AxiosInstance.get(`/appointment_status?limit=10`);
      }

      const data = response.data.data;
      const formattedData = Object.entries(data).map(([id, name]) => ({
        id,
        name,
      }));

      setStatusOption(formattedData);
    };

    fetchData();
  }, [searchStatus]);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    const fetchStateData = async () => {
      let url = `/statemini?limit=10&country_id=${countryMiniId}`;
      if (stateSearch !== "") {
        url += `&search_keyword=${stateSearch}`;
      }

      const response = await AxiosInstance.get(url);
      setColgStateOptions(response.data.data);
    };

    if (countryMiniId !== 0) {
      fetchStateData();
    }
  }, [stateSearch, countryMiniId]);

  const [defaultClg, setDefaultClg] = useState([]);
  //! for college district
  // Fetch districts based on selectedColgState
  useEffect(() => {
    const fetchDistrictData = async () => {
      if (selectedColgState !== null) {
        const response = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        const data = response.data.data;
        setcolgDistrictOptions(data);
        setDefaultClg(data);
        // setSearchSuccess(true);
      }
    };

    fetchDistrictData();
  }, [selectedColgState]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchAllDistricts = async () => {
      const response = await AxiosInstance.get(`/district_mini?limit=10`);
      const data = response.data.data;
      setcolgDistrictOptions(data);
      setDefaultClg(data);
      // setSearchSuccess(true);
    };

    fetchAllDistricts();
  }, []);

  // Search colleges based on district
  const serachClg = async (searchKeyword) => {
    try {
      let url = `/district_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgState) {
        url += `&state_id=${selectedColgState.id}`;
      }

      const response = await AxiosInstance.get(url);
      setcolgDistrictOptions(response.data.data);
      // setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  const [defaultdistClg, setDefaultdistClg] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setSelectedCollegeOptions(finalcolgData);
        // setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  useEffect(() => {
    const fetchData = async () => {
      let colgData = await AxiosInstance.get(`/college_mini?limit=10`);
      let finalcolgData = colgData.data.data;
      setSelectedCollegeOptions(finalcolgData);
      // setSearchSuccess(true);
      setDefaultdistClg(finalcolgData);
    };

    fetchData();
  }, []);

  const searchdistClg = async (searchKeyword) => {
    try {
      let url = `/college_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgDistrict) {
        url += `&district_id=${selectedColgDistrict.id}`;
      }

      const response = await AxiosInstance.get(url);
      setSelectedCollegeOptions(response.data.data);
      // setSearchSuccess(true);
    } catch (error) {
      setSelectedCollegeOptions(defaultdistClg);
    }
  };

  const [defaultDegreeClg, setDefaultDegreeClg] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let colgData = await AxiosInstance.get(`/degree_types_mini?limit=10`);
      let finalcolgData = colgData.data.data;
      setSelectedDegreeOptions(finalcolgData);
      // setSearchSuccess(true);
      setDefaultDegreeClg(finalcolgData);
    };

    fetchData();
  }, []);

  const searchdegreeClg = async (searchKeyword) => {
    try {
      let url = `/degree_types_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }

      const response = await AxiosInstance.get(url);
      setSelectedDegreeOptions(response.data.data);
      // setSearchSuccess(true);
    } catch (error) {
      setSelectedDegreeOptions(defaultdistClg);
    }
  };

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    setYop(null);
    setStatusData(null);
    setFromDate("");
    setToDate("");
    setSearchNumber("");
    setSelectedDegree(null);
    setAppointmentDate("");
    setSearchEmail("");
    setSearchName("");
    setSearchNumber("");
    window.location.href = "/appointment_details_list";
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <main style={{ marginTop: "4.5%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          Appointment List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count: {totalColleges}
        </h1>
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "0 30px",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </Box>
      {filterVisible && (
        <Grid container style={{ marginTop: "0.5%" }}>
          <Grid md={1.8}>
            <Item
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                options={colgStateOptions}
                value={selectedColgState}
                size="small"
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setSelectedCollege(null);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="State" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid md={1.8}>
            <Item
              onChange={(e) => {
                setDistSearch(e.target.value);
              }}
            >
              <Autocomplete
                size="small"
                // disabled={searchSuccess}
                options={colgDistrictOptions}
                value={selectedColgDistrict}
                name="state_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setSelectedCollege(null);
                  setIsSearchDisabled(false);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="District" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2}>
            <Item
              onChange={(e) => {
                setCollSearch(e.target.value);
              }}
            >
              <Autocomplete
                size="small"
                // disabled={searchSuccess}
                options={selectedCollegeOptions}
                value={selectedCollege}
                name="college_name"
                onChange={(event, newcollege) => {
                  setSelectedCollege(newcollege);
                  setIsSearchDisabled(false);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdistClg(e);
                  } else {
                    setSelectedCollegeOptions(defaultdistClg);
                  }
                  setCollSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="College" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={1.6}>
            <Item
              onChange={(e) => {
                setSearchUnitName(e.target.value);
              }}
            >
              <Autocomplete
                options={unitNameOption}
                size="small"
                // disabled={searchSuccess}
                value={unitNameData}
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Unit Name" variant="outlined" />
                )}
              />
            </Item>
          </Grid>

          <Grid item md={1.6}>
            <Item>
              <Autocomplete
                size="small"
                // disabled={searchSuccess}
                options={yopOptions}
                value={yop}
                name="year_of_passout"
                onChange={(e, newyop) => {
                  setYop(newyop);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="YOP" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="From Date"
                size="small"
                label="From Date "
                name="From Date"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="toDate"
                size="small"
                label="To Date "
                name="toDate"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
                // disabled={!fromDate || searchSuccess}
                disabled={!fromDate}
              />
            </Item>
          </Grid>
          <Grid item md={1.8}>
            <Item
              onChange={(e) => {
                setSearchStatus(e.target.value);
              }}
            >
              <Autocomplete
                options={statusOption}
                size="small"
                // disabled={searchSuccess}
                value={statusData}
                name="statusOption"
                onChange={(event, newData_xy) => {
                  setStatusData(newData_xy);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField {...params} label="Status" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={1.8}>
            <Item>
              <Autocomplete
                size="small"
                // disabled={searchSuccess}
                options={selectedDegreeOptions}
                value={selectedDegree}
                name="degree_name"
                onChange={(event, newcollege) => {
                  setSelectedDegree(newcollege);
                  setIsSearchDisabled(false);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdegreeClg(e);
                  } else {
                    setSelectedDegreeOptions(defaultdistClg);
                  }
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Degree" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2}>
            <Item>
              <TextField
                placeholder="Mobile Number"
                label="Mobile Number"
                // disabled={searchSuccess}
                fullWidth
                size="small"
                value={searchNumber}
                name="searchNumber"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 10, maxLength: 10 }}
                onChange={(e) => {
                  setSearchNumber(e.target.value);
                  setIsSearchDisabled(false);
                }}
              />
            </Item>
          </Grid>
          <Grid item lg={1.6}>
            <Item>
              <TextField
                size="small"
                fullWidth
                // disabled={searchSuccess}
                type="text"
                label="Search By Name"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
            </Item>
          </Grid>
          <Grid item lg={1.6}>
            <Item>
              <TextField
                size="small"
                fullWidth
                // disabled={searchSuccess}
                type="email"
                label="Email"
                value={searchEmail}
                onChange={(e) => {
                  setSearchEmail(e.target.value);
                  setIsSearchDisabled(false);
                }}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="Appointment Date"
                size="small"
                label="Appointment Date "
                name="Appointment Date"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={appointmentDate}
                onChange={(e) => {
                  setAppointmentDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
              />
            </Item>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              // variant="contained"
              size="small"
              // disabled={searchSuccess}
              // disabled={isSearchDisabled}
              // style={{
              //   backgroundColor: isSearchDisabled ? "gray" : "#003285",
              //   color: "#fff",
              // }}
              style={{
                backgroundColor: "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "0.7%",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}

      {/* <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#495579" }}>
          Total Appointment List : {totalColleges} &nbsp;&nbsp;
        </h1>
      </Grid> */}

      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 580 : 430 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#003285",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  Appointment Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  YOP
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Appointment Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Addressing Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  District Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Degree Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Unit Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Spoc Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Spoc Mobile
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Spoc Email
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={13} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : searchedColleges && searchedColleges.length > 0 ? (
                searchedColleges.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      <Tooltip title={val.name} placement="bottom">
                        <p>
                          {" "}
                          {val.name === null || val.name === ""
                            ? "NA"
                            : val.name.slice(0, 35)}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {val.year_of_passout === null ||
                      val.year_of_passout === ""
                        ? "NA"
                        : val.year_of_passout}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val.appointment_date === null ||
                      val.appointment_date === ""
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(val.appointment_date))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val.addressing_datetime === null ||
                      val.addressing_datetime === ""
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(val.addressing_datetime))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val
                        ? val.ids
                          ? val.ids.state
                            ? val.ids.state.value
                            : "NA"
                          : "NA"
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val
                        ? val.ids
                          ? val.ids.district
                            ? val.ids.district.value
                            : "NA"
                          : "NA"
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val.all_degree ? (
                        <p>All degree</p>
                      ) : !val.ids.degree ||
                        Object.keys(val.ids.degree).length === 0 ? (
                        "N/A"
                      ) : (
                        Object.values(val.ids.degree).map((ea) => (
                          <Box style={{ height: "auto", color: "green" }}>
                            {ea?.name}
                          </Box>
                        ))
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {Object.keys(val.unit).map((key) =>
                        val.unit[key] && val.unit[key].name
                          ? val.unit[key].name
                          : "NA"
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val ? (val.status ? val.status.value : "NA") : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {!val.college_spocs ||
                      Object.keys(val.college_spocs).length === 0
                        ? "N/A"
                        : Object.values(val.college_spocs).map((ea) => (
                            <Box style={{ height: "auto" }}>{ea.name}</Box>
                          ))}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {!val.college_spocs ||
                      Object.keys(val.college_spocs).length === 0
                        ? "N/A"
                        : Object.values(val.college_spocs).map((ea) => (
                            <Box style={{ height: "auto" }}>
                              {ea?.mobile && (
                                <Box style={{ height: "auto" }}>
                                  <a
                                    href={`tel:${ea.mobile
                                      .map((mob) => mob.mobile)
                                      .join(", ")}`}
                                    style={{
                                      color: "#125B9A",
                                      // textDecoration: "none",
                                    }}
                                  >
                                    {ea.mobile
                                      .map((mob) => mob.mobile)
                                      .join(", ")}
                                  </a>
                                </Box>
                              )}
                            </Box>
                          ))}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {!val.college_spocs ||
                      Object.keys(val.college_spocs).length === 0
                        ? "N/A"
                        : Object.values(val.college_spocs).map((ea) => (
                            <Box style={{ height: "auto" }}>
                              {ea?.email && (
                                <Box style={{ height: "auto" }}>
                                  <a
                                    href={`mailto:${ea.email.map(
                                      (emailObj) => emailObj.email
                                    )}`}
                                    style={{
                                      color: "#125B9A",
                                      // textDecoration: "none",
                                    }}
                                  >
                                    {ea.email
                                      .map((emailObj) => emailObj.email)
                                      .join(", ")}
                                  </a>
                                </Box>
                              )}
                            </Box>
                          ))}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={13}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
