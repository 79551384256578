import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { CgListTree } from "react-icons/cg";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import TreeAddressToRounds from "../TreeAddressToRounds";
import { PostContext } from "../../bdTree/ContextApi";
import Styles from "./counts.module.css";
import AxiosInstance from "../../../apis/AxiosInstance";
import { RiFilterOffFill } from "react-icons/ri";
import AddressingEditModal from "../../bd/addressingForm/AddressingEditModal";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Cookies from "js-cookie";
import TotalProfileFilled from "../../bd/appointmentList/TotalProfileFilled";
import ProfileNotFilledAfterAttendAddressing from "../../bd/appointmentList/ProfileNotFilledAfterAttendAddressing";
import ProfileFilledWithoutAttendAddressing from "../../bd/appointmentList/ProfileFilledWithoutAttendAddressing";
import ProfileFilledAfterAttendAddressing from "../../bd/appointmentList/ProfileFilledAfterAttendAddressing";
import StudentJoinedList from "../../bd/appointmentList/StudentJoinedList";
import NotJoinedStudentList from "../../bd/appointmentList/NotJoinedStudentList";
import FormControl from "@mui/material/FormControl";
import CollegeTree from "../CollegeTree";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:
      role === "1" ? "#EAFDFC" : role === "5" ? "#EDEDED" : "#EAFDFC",
    color: "black",
    border: "none",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

const toIsoFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
};

const CountAddressToRounds = () => {
  let navigate = useNavigate();

  const {
    expanded,
    country,
    states,
    district,
    colleges,
    setCountry,
    setStates,
    setDistrict,
    setColleges,
    appointments,
    setAppointments,
    setStateURL,
    setExpanded,
    setDistrictURL,
    setCollegeURL,
    setAppointmentDistrictFilter,
    setAppointmentCollegeFilter,
    setCountryURL,
    appointmentURL,
    setAppointmentURL,
    collegeFilter,
    setCollegeFilter,
  } = React.useContext(PostContext);

  //=========================================Search Section==============================

  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [collegeData, setCollegeData] = useState(null);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [error, setError] = useState(false);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  const [searchSuccess, setSearchSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const ShowSearchError = ($) => {
    let emptySearchTerm =
      selectedColgState !== null ||
      selectedColgDistrict !== null ||
      collegeData !== null ||
      collegeAid !== "" ||
      searchYOP !== "" ||
      unitNameData !== null
        ? false
        : true;
    if (emptySearchTerm) {
      toast.error("Please Select the Items to be filtered", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (searchSuccess) {
      toast.info("Filters applied successfully expand the tree for results", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // dropdown search
  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState("");

  const [collegeAid, setCollegeAid] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [searchYOP, setSearchYOP] = useState("");
  //api calll

  // useEffect(() => {
  //   let fetchData = async () => {
  //     let data = await AxiosInstance.get(`/countrymini?name=india`);
  //     let finalData = data.data.data;
  //     setCountryMini(finalData);
  //     setCountryMiniId(finalData[0].id);
  //   };
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (stateSearch !== "") {
  //       //college state api
  //       let colgStateData = await AxiosInstance.get(
  //         `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${country[0].id}`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setColgStateOptions(finalcolgStateData);
  //       setSearchSuccess(true);
  //     } else {
  //       //college state api
  //       let colgStateData = await AxiosInstance.get(
  //         `/statemini?limit=10&country_id=${country[0].id}`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setColgStateOptions(finalcolgStateData);
  //       setSearchSuccess(true);
  //     }
  //   };
  //   if (country && country.length > 0) {
  //     fetchData();
  //   }
  // }, [stateSearch, country]);
  // const [defaultClg, setDefaultClg] = useState([]);
  // //! for college district
  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (selectedColgState !== null) {
  //       let colgDistrictData = await AxiosInstance.get(
  //         `/district_mini?state_id=${selectedColgState.id}&limit=10`
  //       );
  //       let finalcolgDistrictData = colgDistrictData.data.data;
  //       setcolgDistrictOptions(finalcolgDistrictData);
  //       setDefaultClg(finalcolgDistrictData);
  //       setSearchSuccess(true);
  //     }
  //   };
  //   fetchData();
  // }, [selectedColgState]);
  // const serachClg = async (e) => {
  //   try {
  //     let colgDistrictData = await AxiosInstance.get(
  //       `/district_mini?search_keyword=${e}&state_id=${selectedColgState.id}&limit=10`
  //     );
  //     let finalcolgDistrictData = colgDistrictData.data.data;
  //     setcolgDistrictOptions(finalcolgDistrictData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setcolgDistrictOptions(defaultClg);
  //   }
  // };
  // //! for college

  // const [defaultdistClg, setDefaultdistClg] = useState([]);
  // // useEffect(() => {
  // //   let fetchData = async (e) => {
  // //     if (selectedColgDistrict !== null) {
  // //       if (collSearch !== "") {
  // //         let colgData = await AxiosInstance.get(
  // //           `/college_mini?search_keyword=${collSearch}&district_id=${selectedColgDistrict.id}&limit=10`
  // //         );
  // //         let finalcolgData = colgData.data.data;
  // //         setCollegeOptions(finalcolgData);
  // //         setSearchSuccess(true);
  // //       } else {
  // //         let colgData = await AxiosInstance.get(
  // //           `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
  // //         );
  // //         let finalcolgData = colgData.data.data;
  // //         setCollegeOptions(finalcolgData);
  // //         setSearchSuccess(true);
  // //       }
  // //     }
  // //   };
  // //   fetchData();
  // // }, [collSearch, selectedColgDistrict]);

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (selectedColgDistrict !== null) {
  //       let colgData = await AxiosInstance.get(
  //         `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
  //       );
  //       let finalcolgData = colgData.data.data;
  //       setCollegeOptions(finalcolgData);
  //       setSearchSuccess(true);
  //       setDefaultdistClg(finalcolgData);
  //     }
  //   };
  //   fetchData();
  // }, [selectedColgDistrict]);

  // const searchdistClg = async (e) => {
  //   try {
  //     let colgData = await AxiosInstance.get(
  //       `/college_mini?search_keyword=${e}&district_id=${selectedColgDistrict.id}&limit=10`
  //     );
  //     let finalcolgData = colgData.data.data;
  //     setCollegeOptions(finalcolgData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setCollegeOptions(defaultdistClg);
  //   }
  // };

  useEffect(() => {
    let fetchData = async (e) => {
      if (stateSearch !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${country[0].id}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
        setSearchSuccess(true);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${country[0].id}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
        setSearchSuccess(true);
      }
    };
    if (country && country.length > 0) {
      fetchData();
    }
  }, [stateSearch, country]);
  const [defaultClg, setDefaultClg] = useState([]);
  //! for college district
  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (selectedColgState !== null) {
  //       let colgDistrictData = await AxiosInstance.get(
  //         `/district_mini?state_id=${selectedColgState.id}&limit=10`
  //       );
  //       let finalcolgDistrictData = colgDistrictData.data.data;
  //       setcolgDistrictOptions(finalcolgDistrictData);
  //       setDefaultClg(finalcolgDistrictData);
  //       setSearchSuccess(true);
  //     }
  //   };
  //   fetchData();
  // }, [selectedColgState]);
  // const serachClg = async (e) => {
  //   try {
  //     let colgDistrictData = await AxiosInstance.get(
  //       `/district_mini?search_keyword=${e}&state_id=${selectedColgState.id}&limit=10`
  //     );
  //     let finalcolgDistrictData = colgDistrictData.data.data;
  //     setcolgDistrictOptions(finalcolgDistrictData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setcolgDistrictOptions(defaultClg);
  //   }
  // };
  useEffect(() => {
    const fetchDistrictData = async () => {
      if (selectedColgState !== null) {
        const response = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        const data = response.data.data;
        setcolgDistrictOptions(data);
        setDefaultClg(data);
        setSearchSuccess(true);
      }
    };

    fetchDistrictData();
  }, [selectedColgState]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchAllDistricts = async () => {
      const response = await AxiosInstance.get(`/district_mini?limit=10`);
      const data = response.data.data;
      setcolgDistrictOptions(data);
      setDefaultClg(data);
      setSearchSuccess(true);
    };

    fetchAllDistricts();
  }, []);

  // Search colleges based on district
  const serachClg = async (searchKeyword) => {
    try {
      let url = `/district_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgState) {
        url += `&state_id=${selectedColgState.id}`;
      }

      const response = await AxiosInstance.get(url);
      setcolgDistrictOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };
  //! for college

  const [defaultdistClg, setDefaultdistClg] = useState([]);

  //=============

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (selectedColgDistrict !== null) {
  //       let colgData = await AxiosInstance.get(
  //         `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
  //       );
  //       let finalcolgData = colgData.data.data;
  //       setCollegeOptions(finalcolgData);
  //       setSearchSuccess(true);
  //       setDefaultdistClg(finalcolgData);
  //     }
  //   };
  //   fetchData();
  // }, [selectedColgDistrict]);

  // const searchdistClg = async (e) => {
  //   try {
  //     let colgData = await AxiosInstance.get(
  //       `/college_mini?search_keyword=${e}&district_id=${selectedColgDistrict.id}&limit=10`
  //     );
  //     let finalcolgData = colgData.data.data;
  //     setCollegeOptions(finalcolgData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setCollegeOptions(defaultdistClg);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedColgDistrict !== null) {
        const response = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        const data = response.data.data;
        setCollegeOptions(data);
        setDefaultdistClg(data);
        setSearchSuccess(true);
      }
    };

    fetchData();
  }, [selectedColgDistrict]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchData = async () => {
      const response = await AxiosInstance.get(`/college_mini?limit=10`);
      const data = response.data.data;
      setCollegeOptions(data);
      setDefaultdistClg(data);
      setSearchSuccess(true);
    };

    fetchData();
  }, []);

  // Search colleges based on district
  const searchdistClg = async (searchKeyword) => {
    try {
      let url = `/college_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgDistrict) {
        url += `&district_id=${selectedColgDistrict.id}`;
      }

      const response = await AxiosInstance.get(url);
      setCollegeOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setCollegeOptions(defaultdistClg);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", (event) => {
      window.location.reload();
    });
  }, []);

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    fetchData();
  }, [searchUnitName]);

  // search ends

  //reset  starts
  const resetFilters = async (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setCollegeData(null);
    setAppointmentCollegeFilter(false);
    setAppointmentDistrictFilter(false);
    window.location.href = "/tree_address_torounds";
  };

  // =======================================================================

  const [columns, setColumns] = React.useState([
    "Appointment Date and Time",
    "Addressing Date and Time",
    "Year Of Passout",
    "Addressing Attended",
    "Addressing Not-Attended",
    "Addressing Follow-up",
    "Addressing follow-up Not done",
    "Profile Filled Without Attending Addressing",
    "Profile Filled After Attending Addressing",
    "Total Profile Filled",
    "Profile Not Filled After Attending Addressing",
    "Rounds",
  ]);

  const [expandedState, setExpandedState] = useState([]);
  const [expandedDistrict, setExpandedDistrict] = useState([]);
  const [expandedCollege, setExpandedCollege] = useState([]);
  const [expandedAppointment, setExpandedAppointment] = useState([]);
  const [addressing_id, setAddressing_id] = useState();

  const captureAddressing_ID = (addr_ID) => {
    setAddressing_id(addr_ID);
  };

  useEffect(() => {
    if (expanded.length > 0) {
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let arr4 = [];
      let stateArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          !ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (stateArr.length > 0) {
        stateArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr1.push(xid);
        });
        setExpandedState(arr1);
      } else {
        setExpandedState(arr1);
      }
      let distArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (distArr.length > 0) {
        distArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr2.push(xid);
        });
        setExpandedDistrict(arr2);
      } else {
        setExpandedDistrict(arr2);
      }
      let collegeArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (collegeArr.length > 0) {
        collegeArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr3.push(xid);
        });
        setExpandedCollege(arr3);
      } else {
        setExpandedCollege(arr3);
      }
      setExpandedAppointment(
        expanded.filter(
          (ex) =>
            ex.includes("state") &&
            ex.includes("district") &&
            ex.includes("college") &&
            ex.includes("appointment")
        ).length > 0
          ? expanded
              .filter(
                (ex) =>
                  ex.includes("state") &&
                  ex.includes("district") &&
                  ex.includes("college") &&
                  ex.includes("appointment")
              )
              .map((element) => parseInt(element.match(/\d+/)[0]))
          : []
      );
    }
  }, [expanded]);

  // ===============search button====================
  // const searchFiltersData = () => {
  //   if (
  //     (selectedColgState && selectedColgState.id === 0) ||
  //     (selectedColgDistrict && selectedColgDistrict.id === 0) ||
  //     (collegeData && collegeData.id === 0)
  //   ) {
  //     toast("Select proper data");
  //     return;
  //   }

  //   const params = [];

  //   if (selectedColgState) {
  //     params.push(`state_id=${selectedColgState.id}`);
  //   }

  //   if (selectedColgDistrict) {
  //     params.push(`district_id=${selectedColgDistrict.id}`);
  //   }

  //   if (collegeData) {
  //     params.push(`college_id=${collegeData.id}`);
  //   }

  //   if (unitNameData) {
  //     params.push(`unit_id=${unitNameData.ID}`);
  //   }

  //   if (searchYOP) {
  //     params.push(`appointment_yop=${searchYOP}`);
  //   }

  //   let queryString = params.length > 0 ? `&${params.join("&")}` : "";

  //   if (queryString) {
  //     setCountryURL(`/country?name=india${queryString}`);
  //   }

  //   if (
  //     (selectedColgState && selectedColgState.id === 0) ||
  //     (selectedColgDistrict && selectedColgDistrict.id === 0) ||
  //     (collegeData && collegeData.id === 0)
  //   ) {
  //     // Display toast message
  //     toast("Select proper data");
  //     return;
  //   }

  //   const params1 = [];

  //   if (selectedColgState) {
  //     params1.push(`id=${selectedColgState.id}`);
  //   }

  //   if (selectedColgDistrict) {
  //     params1.push(`district_id=${selectedColgDistrict.id}`);
  //   }

  //   if (collegeData) {
  //     params1.push(`college_id=${collegeData.id}`);
  //   }

  //   if (unitNameData) {
  //     params1.push(`unit_id=${unitNameData.ID}`);
  //   }

  //   if (searchYOP) {
  //     params1.push(`appointment_yop=${searchYOP}`);
  //   }

  //   let queryString1 = params1.length > 0 ? `${params1.join("&")}` : "";

  //   if (queryString1) {
  //     setStateURL(`/state?${queryString1}`);
  //   }

  //   if (
  //     (selectedColgDistrict && selectedColgDistrict.id === 0) ||
  //     (collegeData && collegeData.id === 0)
  //   ) {
  //     // Display toast message
  //     toast("Select proper data");
  //     return;
  //   }

  //   const params2 = [];

  //   if (selectedColgDistrict) {
  //     params2.push(`id=${selectedColgDistrict.id}`);
  //   }

  //   if (collegeData) {
  //     params2.push(`college_id=${collegeData.id}`);
  //   }

  //   if (unitNameData) {
  //     params2.push(`unit_id=${unitNameData.ID}`);
  //   }

  //   if (searchYOP) {
  //     params2.push(`appointment_yop=${searchYOP}`);
  //   }

  //   let queryString2 = params2.length > 0 ? `${params2.join("&")}` : "";

  //   if (queryString2) {
  //     setDistrictURL(`/district?${queryString2}`);
  //   }

  //   if (collegeData && collegeData.id === 0) {
  //     toast("Select proper data");
  //     return;
  //   }

  //   const params3 = [];

  //   if (collegeData) {
  //     params3.push(`id=${collegeData.id}`);
  //   }

  //   if (unitNameData) {
  //     params3.push(`unit_id=${unitNameData.ID}`);
  //   }

  //   if (searchYOP) {
  //     params3.push(`appointment_yop=${searchYOP}`);
  //   }

  //   let queryString3 = params3.length > 0 ? `${params3.join("&")}` : "";

  //   if (queryString3) {
  //     setCollegeURL(`/college?${queryString3}`);
  //   }

  //   const params4 = [];

  //   if (unitNameData) {
  //     params4.push(`unit_id=${unitNameData.ID}`);
  //   }

  //   if (searchYOP) {
  //     params4.push(`yop=${searchYOP}`);
  //   }

  //   let queryString4 = params4.length > 0 ? `${params4.join("&")}` : "";

  //   if (queryString4) {
  //     setAppointmentURL(`/appointment?${queryString4}`);
  //   }
  // };
  const searchFiltersData = () => {
    // if (
    //   (selectedColgState && selectedColgState.id === 0) ||
    //   (selectedColgDistrict && selectedColgDistrict.id === 0) ||
    //   (collegeData && collegeData.id === 0)
    // ) {
    //   toast("Select proper data");
    //   return;
    // }

    const params = [];

    if (selectedColgState) {
      params.push(`state_id=${selectedColgState.id}`);
    }

    if (selectedColgDistrict) {
      params.push(`district_id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params.push(`appointment_yop=${searchYOP}`);
    }

    let queryString = params.length > 0 ? `&${params.join("&")}` : "";

    if (queryString) {
      setCountryURL(`/country?name=india${queryString}`);
    }

    // if (
    //   (selectedColgState && selectedColgState.id === 0) ||
    //   (selectedColgDistrict && selectedColgDistrict.id === 0) ||
    //   (collegeData && collegeData.id === 0)
    // ) {
    //   // Display toast message
    //   toast("Select proper data");
    //   return;
    // }

    const params1 = [];

    if (selectedColgState) {
      params1.push(`id=${selectedColgState.id}`);
    }

    if (selectedColgDistrict) {
      params1.push(`district_id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params1.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params1.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params1.push(`appointment_yop=${searchYOP}`);
    }

    let queryString1 = params1.length > 0 ? `${params1.join("&")}` : "";

    if (queryString1) {
      setStateURL(`/state?${queryString1}`);
    }

    // if (
    //   (selectedColgDistrict && selectedColgDistrict.id === 0) ||
    //   (collegeData && collegeData.id === 0)
    // ) {
    //   // Display toast message
    //   toast("Select proper data");
    //   return;
    // }

    const params2 = [];

    if (selectedColgDistrict) {
      params2.push(`id=${selectedColgDistrict.id}`);
    }

    if (collegeData) {
      params2.push(`college_id=${collegeData.id}`);
    }

    if (unitNameData) {
      params2.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params2.push(`appointment_yop=${searchYOP}`);
    }

    let queryString2 = params2.length > 0 ? `${params2.join("&")}` : "";

    if (queryString2) {
      setDistrictURL(`/district?${queryString2}`);
    }

    // if (collegeData && collegeData.id === 0) {
    //   toast("Select proper data");
    //   return;
    // }

    const params3 = [];

    if (collegeData) {
      params3.push(`id=${collegeData.id}`);
    }

    if (unitNameData) {
      params3.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params3.push(`appointment_yop=${searchYOP}`);
    }

    let queryString3 = params3.length > 0 ? `${params3.join("&")}` : "";

    if (queryString3) {
      setCollegeURL(`/college?${queryString3}`);
    }

    const params4 = [];

    if (unitNameData) {
      params4.push(`unit_id=${unitNameData.ID}`);
    }

    if (searchYOP) {
      params4.push(`yop=${searchYOP}`);
    }

    let queryString4 = params4.length > 0 ? `${params4.join("&")}` : "";

    if (queryString4) {
      setAppointmentURL(`/appointment?${queryString4}`);
    }
  };
  const searchCollegeData = async () => {
    try {
      const { data } = await AxiosInstance.get(`/college?aid=${collegeAid}`);
      if (data && data.length > 0) {
        setColleges(data.data);
        setAppointments([]);
        setExpanded([]);
        setCountry([]);
        setStates([]);
        setDistrict([]);
        setCollegeFilter(true);
      } else {
        toast.error("No college Data Found");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || "No college Data Found");
    }
  };
  let handleStartQuizClick = async () => {
    let payload = {
      addressing_id: addressing_id,
      take_quiz_status: 2,
    };
    await AxiosInstance.post("/enable_quiz", payload, {
      "content-type": "multipart/form-data",
    })
      .then((response) => {
        toast.success("Successfully submitted");
        if (response.status == 200) {
          setTimeout(() => {
            // window.location.href = "/";
            navigate("/");
          }, 1800);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  // let handleStartCommunicationClick = async () => {
  //   let payload = {
  //     addressing_id: addressing_id,
  //     take_quiz_status: 2,
  //   };
  //   await AxiosInstance.post("/enable_quiz", payload, {
  //     "content-type": "multipart/form-data",
  //   })
  //     .then((response) => {
  //       toast.success("Successfully submitted");
  //       if (response.status == 200) {
  //         setTimeout(() => {
  //           window.location.href = "/";
  //         }, 1800);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.error);
  //     });
  // };
  // let handleStartGDClick = async () => {
  //   let payload = {
  //     addressing_id: addressing_id,
  //     take_quiz_status: 2,
  //   };
  //   await AxiosInstance.post("/enable_quiz", payload, {
  //     "content-type": "multipart/form-data",
  //   })
  //     .then((response) => {
  //       toast.success("Successfully submitted");
  //       if (response.status == 200) {
  //         setTimeout(() => {
  //           window.location.href = "/";
  //         }, 1800);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.error);
  //     });
  // };
  const filterRef = useRef();
  const [margin, setMargin] = useState("");
  useEffect(() => {
    // Function to update element height
    const updateElementHeight = () => {
      if (filterRef.current) {
        const { height } = filterRef.current.getBoundingClientRect();
        setMargin(height);
      }
    };
    updateElementHeight();
    window.addEventListener("resize", updateElementHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateElementHeight);
    };
  }, []);
  return (
    <>
      <section style={{ marginTop: "3.41%" }}>
        <Grid style={{ width: "100%" }} id="searchBlock">
          <Grid
            spacing={1}
            container
            ref={filterRef}
            style={{
              // marginTop: "7px",
              width: "100%",
              alignItems: "center",
              margin: "0 auto",
              gap: "5px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              padding: "15px",
              height: "auto",
              position: "fixed",
              zIndex: "10",
              backgroundColor: "#EEEDEB",
              borderBottom: "2px solid gray",
            }}
          >
            {/* <Grid
              item
              md={1.8}
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={colgStateOptions}
                value={selectedColgState}
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setCollegeData(null);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={selectedColgState ? colgDistrictOptions : []}
                value={selectedColgDistrict}
                name="district_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setCollegeData(null);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    variant="outlined"
                    value={distSearch}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={selectedColgDistrict ? collegeOptions : []}
                value={collegeData}
                name="college_name"
                onChange={(event, newColgdis) => {
                  setCollegeData(newColgdis);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdistClg(e);
                  } else {
                    setCollegeOptions(defaultdistClg);
                  }
                  setCollSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="College"
                    variant="outlined"
                    value={collSearch}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              {selectedColgState || unitNameData ? (
                <TextField
                  size="small"
                  fullWidth
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                  disabled
                />
              ) : (
                <TextField
                  size="small"
                  fullWidth
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                />
              )}
            </Grid> */}

<Grid
              item
              md={1.8}
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                disabled={collegeAid}
                size="small"
                options={colgStateOptions}
                value={selectedColgState}
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setCollegeData(null);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                // options={selectedColgState ? colgDistrictOptions : []}
                options={colgDistrictOptions}
                value={selectedColgDistrict}
                name="district_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setCollegeData(null);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    variant="outlined"
                    value={distSearch}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              <Autocomplete
                disabled={collegeAid}
                size="small"
                // options={selectedColgDistrict ? collegeOptions : []}
                options={collegeOptions}
                value={collegeData}
                name="college_name"
                onChange={(event, newColgdis) => {
                  setCollegeData(newColgdis);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdistClg(e);
                  } else {
                    setCollegeOptions(defaultdistClg);
                  }
                  setCollSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="College"
                    variant="outlined"
                    value={collSearch}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.8}>
              {selectedColgState || collegeData || selectedColgDistrict || unitNameData ? (
                <TextField
                  size="small"
                  fullWidth
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                  disabled
                />
              ) : (
                <TextField
                  size="small"
                  fullWidth
                  name="collegeAid"
                  label="College aid"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 5 }}
                  id="collegeAid"
                  value={collegeAid}
                  onChange={(e) => {
                    setCollegeAid(e.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: { fontSize: 16 },
                  }}
                />
              )}
            </Grid>
            <Grid
              item
              md={1.5}
              onChange={(e) => setSearchUnitName(e.target.value)}
            >
              <Autocomplete
                options={unitNameOption}
                disabled={collegeAid}
                value={unitNameData}
                size="small"
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unit Name"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                size="small"
                fullWidth
                disabled={collegeAid}
                name="searchYOP"
                label="Search YOP"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 4, maxLength: 4 }}
                id="searchYOP"
                value={searchYOP}
                onChange={(e) => {
                  setSearchYOP(e.target.value);
                }}
                InputLabelProps={{
                  style: { fontSize: 16 },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderColor: "rgba(0, 0, 0, 10)",
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 5)",
                    },
                  },
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                style={{
                  backgroundColor: "#003285",
                  color: "#fff",
                }}
                onClick={() => {
                  ShowSearchError();
                  if (collegeAid) searchCollegeData();
                  else searchFiltersData();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "0.7%",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                size="small"
                onClick={resetFilters}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section
        style={{ width: "100%", marginTop: "3.5%", height: margin }}
      ></section>
      <section
        id="treeStructureAddressing"
        style={{ width: "100%", minHeight: "100vh", overflowY: "auto" }}
      >
        <article
          style={{
            width: "100%",
            display: "flex",
            minHeight: "100vh",
            position: "relative",
            // top: "9vh",
          }}
        >
          <aside
            style={{
              width: "40%",
              height: "auto",
            }}
            className="treeBlock"
          >
            <Box
              style={{
                backgroundColor:
                  role === "1"
                    ? "#e2eafc"
                    : role === "5"
                    ? "#F5EDDC"
                    : "#e2eafc",
                padding: "7px",
              }}
            >
              <CgListTree fontSize={35} style={{ marginLeft: "30px" }} />{" "}
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#0D4C92",
                  marginLeft: "20px",
                }}
              >
                Addressing To Rounds
              </span>
            </Box>
            <div>
              {collegeFilter ? <CollegeTree /> : <TreeAddressToRounds />}
            </div>
          </aside>
          <aside
            style={{
              width: "45%",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
            }}
            className="tableBlock"
          >
            <div
              style={{ width: "100%", minHeight: "100%", whiteSpace: "nowrap" }}
            >
              <TableContainer component={Paper} style={{ minHeight: "99vh" }}>
                <Table sx={{ minWidth: 700 }} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <StyledTableCell key={index}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            id="innerChild"
                          >
                            {column}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {!collegeFilter && country && country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.joined_students}</p>&nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <StudentJoinedList
                                    appointment_idx={coun.id}
                                    joinedStudentLevel="1"
                                  ></StudentJoinedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.not_joined_students}</p>&nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <NotJoinedStudentList
                                    appointment_idx={coun.id}
                                    notJoinedStudentLevel="1"
                                  ></NotJoinedStudentList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              -----
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {
                                    coun.profile_filled_without_attending_addressing
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <ProfileFilledWithoutAttendAddressing
                                    appointment_idx={coun.id}
                                    profileFilledWithouAttendAddressing="1"
                                  ></ProfileFilledWithoutAttendAddressing>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {
                                    coun.profile_filled_after_attending_addressing
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <ProfileFilledAfterAttendAddressing
                                    appointment_idx={coun.id}
                                    profileFilledAfterAttendAddressing="1"
                                  ></ProfileFilledAfterAttendAddressing>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>{coun.total_profile_filled}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <TotalProfileFilled
                                    appointment_idx={coun.id}
                                    totalProfileFilled="1"
                                  ></TotalProfileFilled>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                {
                                  coun.profile_not_filled_after_attending_addressing
                                }
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <ProfileNotFilledAfterAttendAddressing
                                    appointment_idx={coun.id}
                                    profileNotFilledAfterAttendAddressing="1"
                                  ></ProfileNotFilledAfterAttendAddressing>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              -----
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}

                      {expanded.length > 0
                        ? states.map((state, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Appointment Date and Time">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Addressing Date and Time">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Year Of Passout">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>{state.joined_students}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <StudentJoinedList
                                            appointment_idx={state.id}
                                            joinedStudentLevel="2"
                                          ></StudentJoinedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>{state.not_joined_students}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <NotJoinedStudentList
                                            appointment_idx={state.id}
                                            notJoinedStudentLevel="2"
                                          ></NotJoinedStudentList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Addressing Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Addressing follow-up Not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {
                                            state.profile_filled_without_attending_addressing
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <ProfileFilledWithoutAttendAddressing
                                            appointment_idx={state.id}
                                            profileFilledWithouAttendAddressing="2"
                                          ></ProfileFilledWithoutAttendAddressing>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {
                                            state.profile_filled_after_attending_addressing
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <ProfileFilledAfterAttendAddressing
                                            appointment_idx={state.id}
                                            profileFilledAfterAttendAddressing="2"
                                          ></ProfileFilledAfterAttendAddressing>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p> {state.total_profile_filled}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <TotalProfileFilled
                                            appointment_idx={state.id}
                                            totalProfileFilled="2"
                                          ></TotalProfileFilled>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {
                                            state.profile_not_filled_after_attending_addressing
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <ProfileNotFilledAfterAttendAddressing
                                            appointment_idx={state.id}
                                            profileNotFilledAfterAttendAddressing="2"
                                          ></ProfileNotFilledAfterAttendAddressing>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Rounds">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == state.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Appointment Date and Time">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Addressing Date and Time">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Year Of Passout">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {dist.joined_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <StudentJoinedList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        joinedStudentLevel="3"
                                                      ></StudentJoinedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {dist.not_joined_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <NotJoinedStudentList
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        notJoinedStudentLevel="3"
                                                      ></NotJoinedStudentList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Addressing Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Addressing follow-up Not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.profile_filled_without_attending_addressing
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <ProfileFilledWithoutAttendAddressing
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        profileFilledWithouAttendAddressing="3"
                                                      ></ProfileFilledWithoutAttendAddressing>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.profile_filled_after_attending_addressing
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <ProfileFilledAfterAttendAddressing
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        profileFilledAfterAttendAddressing="3"
                                                      ></ProfileFilledAfterAttendAddressing>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.total_profile_filled
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <TotalProfileFilled
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        totalProfileFilled="3"
                                                      ></TotalProfileFilled>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.profile_not_filled_after_attending_addressing
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <ProfileNotFilledAfterAttendAddressing
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        profileNotFilledAfterAttendAddressing="3"
                                                      ></ProfileNotFilledAfterAttendAddressing>
                                                    </p>{" "}
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Rounds">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )
                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            align="center"
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Date and Time">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            align="center"
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing Date and Time">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            align="center"
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Year Of Passout">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.joined_students
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <StudentJoinedList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    joinedStudentLevel="4"
                                                                  ></StudentJoinedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.not_joined_students
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <NotJoinedStudentList
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    notJoinedStudentLevel="4"
                                                                  ></NotJoinedStudentList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            align="center"
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing follow-up Not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.profile_filled_without_attending_addressing
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <ProfileFilledWithoutAttendAddressing
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    profileFilledWithouAttendAddressing="4"
                                                                  ></ProfileFilledWithoutAttendAddressing>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.profile_filled_after_attending_addressing
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <ProfileFilledAfterAttendAddressing
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    profileFilledAfterAttendAddressing="4"
                                                                  ></ProfileFilledAfterAttendAddressing>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.total_profile_filled
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <TotalProfileFilled
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    totalProfileFilled="4"
                                                                  ></TotalProfileFilled>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.profile_not_filled_after_attending_addressing
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <ProfileNotFilledAfterAttendAddressing
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    profileNotFilledAfterAttendAddressing="4"
                                                                  ></ProfileNotFilledAfterAttendAddressing>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Rounds">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>

                                                        {appointments != null &&
                                                        expandedCollege.length >
                                                          0 &&
                                                        appointments.filter(
                                                          (aph) =>
                                                            aph.ids.college
                                                              .id == coll.id
                                                        ).length > 0
                                                          ? appointments
                                                              .filter(
                                                                (aph) =>
                                                                  aph.ids
                                                                    .college
                                                                    .id ==
                                                                  coll.id
                                                              )
                                                              .map(
                                                                (
                                                                  apps,
                                                                  indApps
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <TableRow
                                                                        key={
                                                                          indApps +
                                                                          1
                                                                        }
                                                                        onMouseEnter={() => {
                                                                          captureAddressing_ID(
                                                                            apps.addressing_id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p>
                                                                              {apps.appointment_date ===
                                                                                null ||
                                                                              apps.appointment_date ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                moment
                                                                                  .utc(
                                                                                    toIsoFormat(
                                                                                      apps.appointment_date
                                                                                    )
                                                                                  )
                                                                                  .local()
                                                                                  .format(
                                                                                    "DD-MM-yyyy hh:mm A"
                                                                                  )
                                                                                // apps.appointment_date
                                                                                //   .replace(
                                                                                //     "+0000 UTC",
                                                                                //     ""
                                                                                //   )
                                                                                //   .replace(
                                                                                //     "+0000",
                                                                                //     ""
                                                                                //   )
                                                                              )}
                                                                              <Tooltip title="Edit Appointment">
                                                                                <Link
                                                                                  to="/edit_college_appointment"
                                                                                  state={
                                                                                    apps
                                                                                  }
                                                                                >
                                                                                  <FaEdit
                                                                                    fontSize={
                                                                                      18
                                                                                    }
                                                                                    color="#537FE7"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      width:
                                                                                        "50px",
                                                                                    }}
                                                                                  />
                                                                                </Link>
                                                                              </Tooltip>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p>
                                                                              {apps.addressing_datetime ==
                                                                              "" ? (
                                                                                <Tooltip title="Create Addressing">
                                                                                  <Link
                                                                                    to="/create_newaddressing"
                                                                                    style={{
                                                                                      textDecoration:
                                                                                        "none",
                                                                                      padding:
                                                                                        "5px 3px",
                                                                                      borderRadius:
                                                                                        "10px",
                                                                                      color:
                                                                                        "Crimson",
                                                                                    }}
                                                                                  >
                                                                                    NA
                                                                                    <span>
                                                                                      <FaPlusCircle
                                                                                        fontSize={
                                                                                          18
                                                                                        }
                                                                                        color="#537FE7"
                                                                                        style={{
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          width:
                                                                                            "40px",
                                                                                        }}
                                                                                      />
                                                                                    </span>
                                                                                  </Link>
                                                                                </Tooltip>
                                                                              ) : (
                                                                                <>
                                                                                  {/* {apps.addressing_datetime.replace(
                                                                                    "+0000 UTC",
                                                                                    ""
                                                                                  )} */}
                                                                                  {moment
                                                                                    .utc(
                                                                                      apps.addressing_datetime
                                                                                    )
                                                                                    .local()
                                                                                    .format(
                                                                                      "DD-MM-yyyy hh:mm A"
                                                                                    )}
                                                                                  <Tooltip title="Edit">
                                                                                    <AddressingEditModal
                                                                                      stateAddresssing={
                                                                                        apps
                                                                                      }
                                                                                      // status={
                                                                                      //   apps
                                                                                      //     .status
                                                                                      //     .id
                                                                                      // }
                                                                                      status={
                                                                                        Object.keys(
                                                                                          apps.addressing_status
                                                                                        )[0]
                                                                                      }
                                                                                    />
                                                                                  </Tooltip>
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          align="center"
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Year Of Passout">
                                                                            <p>
                                                                              {apps &&
                                                                              apps.year_of_passout
                                                                                ? apps.year_of_passout
                                                                                : "NA"}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.joined_students_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <StudentJoinedList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  joinedStudentLevel="5"
                                                                                ></StudentJoinedList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.not_joined_students_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <NotJoinedStudentList
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  notJoinedStudentLevel="5"
                                                                                ></NotJoinedStudentList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Addressing Follow-up">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Addressing follow-up Not done">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.profile_filled_without_attending_addressing_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <ProfileFilledWithoutAttendAddressing
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  profileFilledWithouAttendAddressing="5"
                                                                                ></ProfileFilledWithoutAttendAddressing>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.profile_filled_after_attending_addressing_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <ProfileFilledAfterAttendAddressing
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  profileFilledAfterAttendAddressing="5"
                                                                                ></ProfileFilledAfterAttendAddressing>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.total_profile_filled_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <TotalProfileFilled
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  totalProfileFilled="5"
                                                                                ></TotalProfileFilled>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps
                                                                                  ?.counts
                                                                                  ?.profile_not_filled_after_attending_addressing_count ||
                                                                                  0}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <ProfileNotFilledAfterAttendAddressing
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  profileNotFilledAfterAttendAddressing="5"
                                                                                ></ProfileNotFilledAfterAttendAddressing>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.addToRoundsAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Rounds">
                                                                            <p
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize:
                                                                                  "16px",
                                                                              }}
                                                                            >
                                                                              {apps.addressing_rounds !==
                                                                              null ? (
                                                                                Object.keys(
                                                                                  apps
                                                                                    .addressing_rounds
                                                                                    .rounds
                                                                                ).map(
                                                                                  (
                                                                                    value,
                                                                                    index
                                                                                  ) => {
                                                                                    return (
                                                                                      <Box
                                                                                        style={{
                                                                                          display:
                                                                                            "flex",
                                                                                          flexDirection:
                                                                                            "column",
                                                                                          gap: "2px",
                                                                                          height:
                                                                                            "24px",
                                                                                        }}
                                                                                      >
                                                                                        {apps
                                                                                          .addressing_rounds
                                                                                          .rounds[
                                                                                          value
                                                                                        ]
                                                                                          .name ==
                                                                                        "Quiz" ? (
                                                                                          <button
                                                                                            style={{
                                                                                              padding:
                                                                                                "0.5px 7px",
                                                                                              border:
                                                                                                "none",
                                                                                              borderRadius:
                                                                                                "6px",
                                                                                              cursor:
                                                                                                "pointer",
                                                                                            }}
                                                                                            className={
                                                                                              Styles.roundButton_1
                                                                                            }
                                                                                            onClick={() => {
                                                                                              handleStartQuizClick();
                                                                                            }}
                                                                                            disabled={
                                                                                              isButtonDisabled
                                                                                            }
                                                                                          >
                                                                                            {isButtonDisabled
                                                                                              ? "Starting Quiz..."
                                                                                              : "Start Quiz"}
                                                                                          </button>
                                                                                        ) : undefined}
                                                                                        {apps
                                                                                          .addressing_rounds
                                                                                          .rounds[
                                                                                          value
                                                                                        ]
                                                                                          .name ==
                                                                                        "Communication" ? (
                                                                                          <button
                                                                                            style={{
                                                                                              padding:
                                                                                                "0.5px 7px",
                                                                                              border:
                                                                                                "none",
                                                                                              borderRadius:
                                                                                                "6px",
                                                                                              cursor:
                                                                                                "pointer",
                                                                                            }}
                                                                                            className={
                                                                                              Styles.roundButton_2
                                                                                            }
                                                                                            // onClick={() => {
                                                                                            //   handleStartCommunicationClick();
                                                                                            // }}
                                                                                          >
                                                                                            Start
                                                                                            Communication
                                                                                          </button>
                                                                                        ) : undefined}
                                                                                        {apps
                                                                                          .addressing_rounds
                                                                                          .rounds[
                                                                                          value
                                                                                        ]
                                                                                          .name ==
                                                                                        "Group Discussion" ? (
                                                                                          <button
                                                                                            style={{
                                                                                              padding:
                                                                                                "0.5px 7px",
                                                                                              border:
                                                                                                "none",
                                                                                              borderRadius:
                                                                                                "6px",
                                                                                              cursor:
                                                                                                "pointer",
                                                                                            }}
                                                                                            className={
                                                                                              Styles.roundButton_3
                                                                                            }
                                                                                            // onClick={() => {
                                                                                            //   handleStartGDClick();
                                                                                            // }}
                                                                                          >
                                                                                            Start
                                                                                            Group
                                                                                            Discussion
                                                                                          </button>
                                                                                        ) : undefined}
                                                                                        {/* <button
                                                                                          style={{
                                                                                            padding:
                                                                                              "0.5px 7px",
                                                                                            border:
                                                                                              "none",
                                                                                            borderRadius:
                                                                                              "6px",
                                                                                            cursor:
                                                                                              "pointer",
                                                                                          }}
                                                                                        >
                                                                                          {`Start ${apps.addressing_rounds.rounds[value].name}`}
                                                                                        </button> */}
                                                                                      </Box>
                                                                                    );
                                                                                  }
                                                                                )
                                                                              ) : (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                      </TableRow>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : collegeFilter && colleges.length > 0 ? (
                    colleges.map((coll, indColl) => {
                      return (
                        <>
                          <TableRow key={indColl + 1}>
                            <StyledTableCell
                              align="center"
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Appointment Date and Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Addressing Date and Time">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Year Of Passout">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coll.joined_students}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <StudentJoinedList
                                      appointment_idx={coll.id}
                                      joinedStudentLevel="4"
                                    ></StudentJoinedList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <p>{coll.not_joined_students}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <NotJoinedStudentList
                                      appointment_idx={coll.id}
                                      notJoinedStudentLevel="4"
                                    ></NotJoinedStudentList>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Addressing Follow-up">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Addressing follow-up Not done">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {
                                      coll.profile_filled_without_attending_addressing
                                    }
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <ProfileFilledWithoutAttendAddressing
                                      appointment_idx={coll.id}
                                      profileFilledWithouAttendAddressing="4"
                                    ></ProfileFilledWithoutAttendAddressing>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {
                                      coll.profile_filled_after_attending_addressing
                                    }
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <ProfileFilledAfterAttendAddressing
                                      appointment_idx={coll.id}
                                      profileFilledAfterAttendAddressing="4"
                                    ></ProfileFilledAfterAttendAddressing>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>{coll.total_profile_filled}</p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <TotalProfileFilled
                                      appointment_idx={coll.id}
                                      totalProfileFilled="4"
                                    ></TotalProfileFilled>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip>
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <p>
                                    {
                                      coll.profile_not_filled_after_attending_addressing
                                    }
                                  </p>
                                  &nbsp;&nbsp;
                                  <p
                                    style={{
                                      fontSize: "1px",
                                    }}
                                  >
                                    {" "}
                                    <ProfileNotFilledAfterAttendAddressing
                                      appointment_idx={coll.id}
                                      profileNotFilledAfterAttendAddressing="4"
                                    ></ProfileNotFilledAfterAttendAddressing>
                                  </p>
                                </p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Rounds">
                                <p>-----</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>

                          {appointments != null && appointments.length > 0
                            ? appointments.map((apps, indApps) => {
                                return (
                                  <>
                                    <TableRow
                                      key={indApps + 1}
                                      onMouseEnter={() => {
                                        captureAddressing_ID(
                                          apps.addressing_id
                                        );
                                      }}
                                    >
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p>
                                            {apps.appointment_date === null ||
                                            apps.appointment_date === "" ? (
                                              <Box
                                                sx={{
                                                  color: "#EB455F",
                                                }}
                                              >
                                                NA
                                              </Box>
                                            ) : (
                                              moment
                                                .utc(
                                                  toIsoFormat(
                                                    apps.appointment_date
                                                  )
                                                )
                                                .local()
                                                .format("DD-MM-yyyy hh:mm A")
                                              // apps.appointment_date
                                              //   .replace(
                                              //     "+0000 UTC",
                                              //     ""
                                              //   )
                                              //   .replace(
                                              //     "+0000",
                                              //     ""
                                              //   )
                                            )}
                                            <Tooltip title="Appointment Edit">
                                              <Link
                                                to="/edit_college_appointment"
                                                state={apps}
                                              >
                                                <FaEdit
                                                  fontSize={18}
                                                  color="#537FE7"
                                                  style={{
                                                    cursor: "pointer",
                                                    width: "50px",
                                                  }}
                                                />
                                              </Link>
                                            </Tooltip>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p>
                                            {apps.addressing_datetime == "" ? (
                                              <Tooltip title="Create Addressing">
                                                <Link
                                                  to="/create_newaddressing"
                                                  style={{
                                                    textDecoration: "none",
                                                    padding: "5px 3px",
                                                    borderRadius: "10px",
                                                    color: "Crimson",
                                                  }}
                                                >
                                                  NA
                                                  <span>
                                                    <FaPlusCircle
                                                      fontSize={18}
                                                      color="#537FE7"
                                                      style={{
                                                        cursor: "pointer",
                                                        width: "40px",
                                                      }}
                                                    />
                                                  </span>
                                                </Link>
                                              </Tooltip>
                                            ) : (
                                              <>
                                                {/* {apps.addressing_datetime.replace(
                                                    "+0000 UTC",
                                                    ""
                                                  )} */}
                                                {moment
                                                  .utc(apps.addressing_datetime)
                                                  .local()
                                                  .format("DD-MM-yyyy hh:mm A")}
                                                <Tooltip title="Edit">
                                                  <AddressingEditModal
                                                    stateAddresssing={apps}
                                                    // status={
                                                    //   apps
                                                    //     .status
                                                    //     .id
                                                    // }
                                                    status={
                                                      Object.keys(
                                                        apps.addressing_status
                                                      )[0]
                                                    }
                                                  />
                                                </Tooltip>
                                              </>
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Year Of Passout">
                                          <p>
                                            {apps && apps.year_of_passout
                                              ? apps.year_of_passout
                                              : "NA"}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.joined_students_count || 0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <StudentJoinedList
                                                appointment_idx={apps.id}
                                                joinedStudentLevel="5"
                                              ></StudentJoinedList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {" "}
                                            <p>
                                              {apps?.counts
                                                ?.not_joined_students_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <NotJoinedStudentList
                                                appointment_idx={apps.id}
                                                notJoinedStudentLevel="5"
                                              ></NotJoinedStudentList>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Addressing Follow-up">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Addressing follow-up Not done">
                                          <p>Yet to be developed</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.profile_filled_without_attending_addressing_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <ProfileFilledWithoutAttendAddressing
                                                appointment_idx={apps.id}
                                                profileFilledWithouAttendAddressing="5"
                                              ></ProfileFilledWithoutAttendAddressing>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.profile_filled_after_attending_addressing_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <ProfileFilledAfterAttendAddressing
                                                appointment_idx={apps.id}
                                                profileFilledAfterAttendAddressing="5"
                                              ></ProfileFilledAfterAttendAddressing>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.total_profile_filled_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <TotalProfileFilled
                                                appointment_idx={apps.id}
                                                totalProfileFilled="5"
                                              ></TotalProfileFilled>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip>
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <p>
                                              {apps?.counts
                                                ?.profile_not_filled_after_attending_addressing_count ||
                                                0}
                                            </p>
                                            &nbsp;&nbsp;
                                            <p
                                              style={{
                                                fontSize: "1px",
                                              }}
                                            >
                                              {" "}
                                              <ProfileNotFilledAfterAttendAddressing
                                                appointment_idx={apps.id}
                                                profileNotFilledAfterAttendAddressing="5"
                                              ></ProfileNotFilledAfterAttendAddressing>
                                            </p>
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment
                                        }
                                      >
                                        <BootstrapTooltip title="Rounds">
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {apps.addressing_rounds !== null ? (
                                              Object.keys(
                                                apps.addressing_rounds.rounds
                                              ).map((value, index) => {
                                                return (
                                                  <Box
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      gap: "2px",
                                                      height: "24px",
                                                    }}
                                                  >
                                                    {apps.addressing_rounds
                                                      .rounds[value].name ==
                                                    "Quiz" ? (
                                                      <button
                                                        style={{
                                                          padding: "0.5px 7px",
                                                          border: "none",
                                                          borderRadius: "6px",
                                                          cursor: "pointer",
                                                        }}
                                                        className={
                                                          Styles.roundButton_1
                                                        }
                                                        onClick={() => {
                                                          handleStartQuizClick();
                                                        }}
                                                        disabled={
                                                          isButtonDisabled
                                                        }
                                                      >
                                                        {isButtonDisabled
                                                          ? "Starting Quiz..."
                                                          : "Start Quiz"}
                                                      </button>
                                                    ) : undefined}
                                                    {apps.addressing_rounds
                                                      .rounds[value].name ==
                                                    "Communication" ? (
                                                      <button
                                                        style={{
                                                          padding: "0.5px 7px",
                                                          border: "none",
                                                          borderRadius: "6px",
                                                          cursor: "pointer",
                                                        }}
                                                        className={
                                                          Styles.roundButton_2
                                                        }
                                                        // onClick={() => {
                                                        //   handleStartCommunicationClick();
                                                        // }}
                                                      >
                                                        Start Communication
                                                      </button>
                                                    ) : undefined}
                                                    {apps.addressing_rounds
                                                      .rounds[value].name ==
                                                    "Group Discussion" ? (
                                                      <button
                                                        style={{
                                                          padding: "0.5px 7px",
                                                          border: "none",
                                                          borderRadius: "6px",
                                                          cursor: "pointer",
                                                        }}
                                                        className={
                                                          Styles.roundButton_3
                                                        }
                                                        // onClick={() => {
                                                        //   handleStartGDClick();
                                                        // }}
                                                      >
                                                        Start Group Discussion
                                                      </button>
                                                    ) : undefined}
                                                  </Box>
                                                );
                                              })
                                            ) : (
                                              <Box
                                                sx={{
                                                  color: "#EB455F",
                                                }}
                                              >
                                                NA
                                              </Box>
                                            )}
                                          </p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        {/* <h4 style={{ color: "red" }}>Loading Table Data...</h4> */}
                        <h4 style={{ color: "red" }}>No Data Found...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
          <aside style={{ width: "15%" }} className="actionBlock">
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {!collegeFilter && country && country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p>NA</p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.addToRoundsCountry}
                            >
                              <p>NA</p>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                      {expanded.length > 0
                        ? states.map((stat, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Status">
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.addToRoundsState}
                                  >
                                    <BootstrapTooltip title="Action">
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == stat.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Status">
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={
                                                  Styles.addToRoundsDistrict
                                                }
                                              >
                                                <BootstrapTooltip title="Action">
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )

                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Status">
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.addToRoundsCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Action">
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>
                                                        {appointments != null &&
                                                        appointments.length > 0
                                                          ? appointments.map(
                                                              (
                                                                apps,
                                                                indApps
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <TableRow
                                                                      key={
                                                                        indApps +
                                                                        1
                                                                      }
                                                                    >
                                                                      <StyledTableCell
                                                                        className={
                                                                          Styles.addToRoundsAppointment1
                                                                        }
                                                                      >
                                                                        <BootstrapTooltip title="Status">
                                                                          <p>
                                                                            NA
                                                                          </p>
                                                                        </BootstrapTooltip>
                                                                      </StyledTableCell>

                                                                      <StyledTableCell
                                                                        className={
                                                                          Styles.addToRoundsAppointment1
                                                                        }
                                                                      >
                                                                        <BootstrapTooltip title="Status">
                                                                          <p>
                                                                            NA
                                                                          </p>
                                                                        </BootstrapTooltip>
                                                                      </StyledTableCell>
                                                                    </TableRow>
                                                                  </>
                                                                );
                                                              }
                                                            )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : collegeFilter && colleges.length > 0 ? (
                    colleges.map((coll, indColl) => {
                      return (
                        <>
                          <TableRow key={indColl + 1}>
                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Status">
                                <p>NA</p>
                              </BootstrapTooltip>
                            </StyledTableCell>

                            <StyledTableCell
                              className={Styles.addToRoundsCollege}
                            >
                              <BootstrapTooltip title="Action">
                                <p>NA</p>
                              </BootstrapTooltip>
                            </StyledTableCell>
                          </TableRow>
                          {appointments != null && appointments.length > 0
                            ? appointments.map((apps, indApps) => {
                                return (
                                  <>
                                    <TableRow key={indApps + 1}>
                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment1
                                        }
                                      >
                                        <BootstrapTooltip title="Status">
                                          <p>NA</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={
                                          Styles.addToRoundsAppointment1
                                        }
                                      >
                                        <BootstrapTooltip title="Status">
                                          <p>NA</p>
                                        </BootstrapTooltip>
                                      </StyledTableCell>
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        {/* <h4 style={{ color: "red" }}>Loading Table Data...</h4> */}
                        <h4 style={{ color: "red" }}>No Data Found...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};
export default CountAddressToRounds;
