import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Autocomplete,
  Box,
  Grid,
  LinearProgress,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiFilterOffFill } from "react-icons/ri";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function MoveCollegeSpocList() {
  let { state } = useLocation();

  let navigate = useNavigate();
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  let [searchFilter, setSearchFilter] = useState("");
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalColleges, setTotalColleges] = useState(0);

  // //! Fetch the college
  let [searchedColleges, SetsearchedColleges] = useState([]);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/appointmentcollege?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        SetsearchedColleges(finalData);
        setTotalColleges(data.data.filtered_count);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/appointmentcollege?page=${newPage}`;
    setLoad(true);
    try {
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&id=${selectedCollege.id}`;
      }

      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/appointmentcollege?page=${1}`;
    try {
      if (!selectedColgState && !selectedColgDistrict && !selectedCollege) {
        toast.warning("Select at least one data to filter.");
        return;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&id=${selectedCollege.id}`;
      }

      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(1);
      setSearchSuccess(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchSuccess(false);
    }
  };

  const totalPages = Math.ceil(totalColleges / 10);
  const [searchSuccess, setSearchSuccess] = useState(false);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  const [defaultClg, setDefaultClg] = useState([]);
  const [defaultdistClg, setDefaultdistClg] = useState([]);

  // Fetch college states based on stateSearch and countryMiniId
  useEffect(() => {
    const fetchStateData = async () => {
      let url = `/statemini?limit=10&country_id=${countryMiniId}`;
      if (stateSearch !== "") {
        url += `&search_keyword=${stateSearch}`;
      }

      const response = await AxiosInstance.get(url);
      setColgStateOptions(response.data.data);
    };

    if (countryMiniId !== 0) {
      fetchStateData();
    }
  }, [stateSearch, countryMiniId]);

  // Fetch districts based on selectedColgState
  useEffect(() => {
    const fetchDistrictData = async () => {
      if (selectedColgState !== null) {
        const response = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        const data = response.data.data;
        setcolgDistrictOptions(data);
        setDefaultClg(data);
        setSearchSuccess(true);
      }
    };

    fetchDistrictData();
  }, [selectedColgState]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchAllDistricts = async () => {
      const response = await AxiosInstance.get(`/district_mini?limit=10`);
      const data = response.data.data;
      setcolgDistrictOptions(data);
      setDefaultClg(data);
      setSearchSuccess(true);
    };

    fetchAllDistricts();
  }, []);

  // Search colleges based on district
  const serachClg = async (searchKeyword) => {
    try {
      let url = `/district_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgState) {
        url += `&state_id=${selectedColgState.id}`;
      }

      const response = await AxiosInstance.get(url);
      setcolgDistrictOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setSelectedCollegeOptions(finalcolgData);
        setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  useEffect(() => {
    const fetchData = async () => {
      let colgData = await AxiosInstance.get(`/college_mini?limit=10`);
      let finalcolgData = colgData.data.data;
      setSelectedCollegeOptions(finalcolgData);
      setSearchSuccess(true);
      setDefaultdistClg(finalcolgData);
    };

    fetchData();
  }, []);

  const searchdistClg = async (searchKeyword) => {
    try {
      let url = `/college_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgDistrict) {
        url += `&district_id=${selectedColgDistrict.id}`;
      }

      const response = await AxiosInstance.get(url);
      setSelectedCollegeOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setSelectedCollegeOptions(defaultdistClg);
    }
  };

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    window.location.href = "/move_spoc_college";
  };

  const [quizList, setQuizList] = useState([]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setQuizList([...quizList, id]);
    } else {
      setQuizList(quizList.filter((row) => row !== id));
    }
  };

  const handleSubmit = async (e) => {
    let payload = {
      move_spoc_id: state.id,
      college_id: quizList,
    };
    try {
      await AxiosInstance.patch("/archive_college_spoc", payload)
        .then((response) => {
          toast.success("Successfully Added the College to Spoc");
          setTimeout(() => {
            navigate("/college_spoc_list");
          }, 1600);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <main style={{ marginTop: "4.3%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          College List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalColleges}
        </h1>
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "0 30px",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </Box>
      {filterVisible && (
        <Grid container style={{ marginTop: "0.5%" }} gap={0.5}>
          <Grid item lg={2.5}>
            <Item
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                required
                options={colgStateOptions}
                value={selectedColgState}
                size="small"
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setSelectedCollege(null);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search State"
                    variant="outlined"
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2.5}>
            <Item
              onChange={(e) => {
                setDistSearch(e.target.value);
              }}
            >
              <Autocomplete
                required
                options={colgDistrictOptions}
                value={selectedColgDistrict}
                size="small"
                name="state_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setSelectedCollege(null);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search District"
                    variant="outlined"
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2.5}>
            <Item
              onChange={(e) => {
                setCollSearch(e.target.value);
              }}
            >
              <Autocomplete
                required
                options={selectedCollegeOptions}
                value={selectedCollege}
                name="college_name"
                size="small"
                onChange={(event, newcollege) => {
                  setSelectedCollege(newcollege);
                }}
                onInputChange={(event, value) => {
                  if (value && value.length > 0) {
                    searchdistClg(value);
                  } else {
                    setSelectedCollegeOptions(defaultdistClg);
                  }
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search College"
                    variant="outlined"
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              size="small"
              style={{
                backgroundColor: "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}

      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 600,
            width: "99%",
            margin: "0 auto",
            border: "1px solid gray",
            borderRadius: "10px",
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "160px !important",
                  }}
                >
                  Select Colleges
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  District Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "450px !important",
                  }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "450px !important",
                  }}
                >
                  University Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedColleges.length > 0 ? (
                searchedColleges.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Checkbox
                        disableRipple
                        name={`chk${val.id}`}
                        checked={quizList.includes(val.id)}
                        value={val.id}
                        onChange={(event) =>
                          handleCheckboxChange(event, val.id)
                        }
                        style={{
                          padding: "3px 10px 3px 20px",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.state_name} placement="bottom">
                        <p> {val.state_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.district_name} placement="bottom">
                        <p> {val.district_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.name} placement="bottom">
                        <p> {val.name}</p>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.university_name} placement="bottom">
                        <p>
                          {val.university_name === null
                            ? "N/A"
                            : val.university_name}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {quizList.length > 0 && (
          <div style={{ marginLeft: "50%", marginTop: "1%" }}>
            <p style={{ fontWeight: "bold" }}>
              {quizList.length === 1
                ? quizList.length + " College selected"
                : quizList.length + " Colleges selected"}
            </p>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Proceed
              </Button>
            </Box>
          </div>
        )}
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "45%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
