import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  Container,
  Paper,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";

//====================================

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function EditMultipleInterview() {
  const Navigate = useNavigate();
  let { state } = useLocation();

  const [start_date_time, setStart_date_time] = useState(
    moment.utc(state.start_date_time).local().format("YYYY-MM-DDTHH:mm:ss.SSS")
  );

  const [interview_duration, setInterview_duration] = useState(
    state.interview_duration
  );
  const [interval_per_interview, setInterval_per_interview] = useState(
    state.interval_per_interview
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      appointment_id: Number.parseInt(Object.keys(state.appointment_id)),
      start_date_time: moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
      interview_duration_per_student: Number.parseInt(interview_duration),
      interval_per_interview: Number.parseInt(interval_per_interview),
    };
    try {
      await AxiosInstance.post("/interview_multiple_patch", payload)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Interiew Updated Successfully");
            setTimeout(() => {
              Navigate("/interview_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "95%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Multiple Interview Edit
          </Box>
        </Box>
        <Container
          component="main"
          style={{
            width: "70%",
            borderRadius: "10px",
          }}
        >
          <Paper
            variant="outlined"
            style={{ border: "1px solid #9BA4B5", width: "100%" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Start DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="start_date_time"
                        required
                        fullWidth
                        type="datetime-local"
                        id="start_date_time"
                        value={start_date_time}
                        onChange={(e) => setStart_date_time(e.target.value)}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Interview Duration :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="interview_duration"
                        value={interview_duration}
                        require
                        onChange={(e) => setInterview_duration(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Interval Per Interview :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="interval_per_interview"
                        value={interval_per_interview}
                        require
                        onChange={(e) =>
                          setInterval_per_interview(e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "20%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </article>
    </section>
  );
}
