import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

const StudentRegistration = () => {
  const navigate = useNavigate();
  const [full_name, setfull_name] = useState("");
  const [emails, setemails] = useState("");
  const [mobiles, setmobiles] = useState("");
  const [is_whatsappNumber, setIs_whatsappNumber] = useState(false);
  const [whattsappNumber, setWhattsappNumber] = useState("");
  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");

  const [countryOption, setCountryOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [countryData, setCountryData] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [districtData, setDistrictData] = useState(null);
  const [searchCountry, setSearchCountry] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDistrict, setSearchDistrict] = useState("");
  let [errorCountry, setErrorCountry] = useState(false);
  let [errorForState, setErrorForState] = useState(false);
  let [errorForDistrict, setErrorForDistrict] = useState(false);
  const [uniData, setUniData] = useState(null);
  const [uniOption, setUniOption] = useState([]);
  const [collegeDataa, setCollegeDataa] = useState(null);
  const [collegeOption, setCollegeOption] = useState([]);
  const [searchUniversity, setSearchUniversity] = useState("");
  const [searchCollege, setSearchCollege] = useState("");
  let [errorForUni, setErrorForUni] = useState(false);
  let [errorCollege, setErrorCollege] = useState(false);
  const [otherCollegeName, setOtherCollegeName] = useState("");
  const [otherDistrictName, setOtherDistrictName] = useState("");
  const [otherUniversityName, setOtherUniversityName] = useState("");

  const [searchCountryCode, setSearchCountryCode] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [countryCodeOption, setCountryCodeOption] = useState([]);

  const handleSendBroadCast = (event) => {
    setIs_whatsappNumber(event.target.checked);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  // show password
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      if (searchCountryCode !== "") {
        let data = await AxiosInstance.get(
          `/country_code?search_keyword=${searchCountryCode}`
        );
        let finalData = data.data.data;
        if (finalData) {
          setCountryCodeOption(finalData);
        } else {
          let data = await AxiosInstance.get(`/country_code`);
          let finalData = data.data.data;
          if (finalData) {
            setCountryCodeOption(finalData);
          }
        }
      }
    };
    fetchData();
  }, [searchCountryCode]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchCountry !== "") {
        // State api call
        let countryData = await AxiosInstance.get(
          `/countrymini?search_keyword=${searchCountry}&name=india`
        );
        let finalCountryData = countryData.data.data;
        if (finalCountryData) {
          setCountryOption(finalCountryData);
        }
      } else {
        // State api call
        let countryData = await AxiosInstance.get(`/countrymini?name=india`);
        let finalCountryData = countryData.data.data;
        if (finalCountryData) {
          setCountryOption(finalCountryData);
        }
      }
    };
    fetchData();
  }, [searchCountry]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (countryData !== null) {
        if (searchTerm !== "") {
          // State api call
          let stateData_x = await AxiosInstance.get(
            `/statemini?search_keyword=${searchTerm}&limit=10&country_id=${countryData.id}`
          );
          let finalStateData_X = stateData_x.data.data;
          setStateOption(finalStateData_X);
        } else {
          // State api call
          let stateData_x = await AxiosInstance.get(
            `/statemini?limit=10&country_id=${countryData.id}`
          );
          let finalStateData_X = stateData_x.data.data;
          setStateOption(finalStateData_X);
        }
      }
    };
    fetchData();
  }, [searchTerm, countryData]);

  const [defaultClg, setDefaultClg] = useState([]);
  //! for district filter from state
  useEffect(() => {
    let fetchData = async (e) => {
      if (stateData !== null) {
        let colgDistrictData = await AxiosInstance.get(
          `/district_mini?limit=10&state_id=${stateData.id}`
        );
        let finalcolgDistrictData = colgDistrictData.data.data;
        setDistrictOption(finalcolgDistrictData);
        setDefaultClg(finalcolgDistrictData);
      }
    };
    fetchData();
  }, [stateData]);

  const searchClg = async (e) => {
    try {
      let colgDistrictData = await AxiosInstance.get(
        `/district_mini?search_keyword=${e}&limit=10&state_id=${stateData.id}`
      );
      let finalcolgDistrictData = colgDistrictData.data.data;
      setDistrictOption(finalcolgDistrictData);
    } catch (error) {
      setDistrictOption(defaultClg);
    }
  };

  //!for college filter from district and university
  const [defaultdistClg, setDefaultdistClg] = useState([]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (uniData !== null && districtData !== null) {
        let collegeStateData = await AxiosInstance.get(
          `/college_mini?search_keyword=${searchCollege}&limit=10&district_id=${districtData.id}&university_id=${uniData.id}`
        );
        let finalCollegeStateData = collegeStateData.data.data;
        setCollegeOption(finalCollegeStateData);
        setDefaultdistClg(finalCollegeStateData);
      }
    };
    fetchData();
  }, [districtData, uniData]);

  const searchdistClg = async (e) => {
    try {
      let collegeStateData = await AxiosInstance.get(
        `/college_mini?search_keyword=${e}&limit=10&district_id=${districtData.id}&university_id=${uniData.id}`
      );
      let finalCollegeStateData = collegeStateData.data.data;
      setCollegeOption(finalCollegeStateData);
    } catch (error) {
      setCollegeOption(defaultdistClg);
    }
  };

  const [defaultUni, setDefaultUni] = useState([]);

  useEffect(() => {
    let fetchStateUni = async (e) => {
      let uniData_x = await AxiosInstance.get(`/university?limit=10`);
      let finalUniData_X = uniData_x.data.data;
      setUniOption(finalUniData_X);
      setDefaultUni(finalUniData_X);
    };
    fetchStateUni();
  }, []);

  const searchuni = async (e) => {
    try {
      let uniData_x = await AxiosInstance.get(
        `/university?search_keyword=${e}&limit=10`
      );
      let finalUniData_X = uniData_x.data.data;
      setUniOption(finalUniData_X);
    } catch (error) {
      setUniOption(defaultUni);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let formData = new FormData();
    formData.append("full_name", full_name);
    formData.append("email", emails);
    formData.append("password", password);
    formData.append("confirm_password", confirm_password);
    formData.append("country_code", `+91`);
    formData.append("mobile", mobiles);
    formData.append("country_id", countryData.id);
    formData.append("state_id", stateData.id);
    formData.append("district_id", districtData.id);
    formData.append("university_id", uniData.id);
    formData.append("college_id", collegeDataa.id);
    if (whattsappNumber) {
      formData.append("whattsapp_number", whattsappNumber);
      formData.append("whattsapp_number_country_code", `+91`);
    }
    if (is_whatsappNumber) {
      formData.append("is_whattsapp_number", is_whatsappNumber);
    }

    if (collegeDataa.id === 0) {
      formData.append("current_other_college_name", otherCollegeName);
    }
    if (uniData.id === 0) {
      formData.append("current_other_university_name", otherUniversityName);
    }
    if (districtData.id === 0) {
      formData.append("current_other_district_name", otherDistrictName);
    }
    try {
      await AxiosInstance.post(`/savestudent`, formData, config)
        .then((data) => {
          toast.success(full_name + " " + data.data.message);
          setTimeout(() => {
            navigate("/student_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went Wrong Please Try Again");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    border: "1px solid gray",
    borderRadius: "10px",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#FD8D14" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#176B87",
    color: "#fff",
    border: "1px solid #176B87",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "#A02334",
    color: "#fff",
    border: "1px solid #A02334",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  // for DropDown ScrollBar

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersAndOnePeriodRegex = /^[a-zA-Z\s]*\.?[a-zA-Z\s]*$/;

    if (!lettersAndOnePeriodRegex.test(value)) {
      // If the input contains more than one period or non-letter characters, remove them
      setfull_name(value.replace(/[^a-zA-Z\s.]/g, "").replace(/\.{2,}/g, "."));
    } else {
      setfull_name(value);
    }
  };

  const handleCancelClick = () => {
    navigate("/student_list");
  };

  return (
    <Grid style={{ marginTop: "5%" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Student Registration</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item md={2.5} xs={12}>
                <TextField
                  color="warning"
                  fullWidth
                  name="full_f_name"
                  label="Fullname"
                  required
                  value={full_name}
                  // onChange={(e) => setfull_name(e.target.value)}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <Item
                    onChange={(e) => {
                      setSearchCountryCode(e.target.value);
                    }}
                  >
                    <Autocomplete
                      options={countryCodeOption}
                      size="small"
                      value={countryCode}
                      name="collegeOption"
                      onChange={(event, newData_xy) => {
                        setCountryCode(newData_xy);                       
                      }}
                      getOptionLabel={(option) => option.code}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Country Code"
                          variant="outlined"                        
                          required
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid> */}
              <Grid item md={2.5} xs={12}>
                <TextField
                  color="warning"
                  // size="small"
                  // sx={{ minWidth: 210 }}
                  label="Mobile Number"
                  type="tel"
                  fullWidth
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  name="mobiles"
                  value={mobiles}
                  require
                  onChange={(e) => setmobiles(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Checkbox
                  name="is_whatsappNumber"
                  checked={is_whatsappNumber}
                  onChange={handleSendBroadCast}
                  id="is_whatsappNumber"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="whatsappNumber" style={{ fontSize: "16px" }}>
                  Is Whatsapp Number
                </label>
              </Grid>
              <Grid item md={2.5} xs={12}>
                <TextField
                  color="warning"
                  // size="small"
                  // sx={{ minWidth: 210 }}
                  label="Whatsapp Number"
                  disabled={is_whatsappNumber}
                  type="tel"
                  fullWidth
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  name="whatsappNumber"
                  value={whattsappNumber}
                  required
                  onChange={(e) => setWhattsappNumber(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item md={2.5} xs={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <TextField
                    // size="small"
                    color="warning"
                    fullWidth
                    label="Email"
                    name="emails"
                    type="email"
                    autoComplete="user-email"
                    value={emails}
                    required
                    onChange={(e) => setemails(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item sm={6}>
                    <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <TextField
                     size="small"
                     color="warning"
                     sx={{ minWidth: 210 }}
                     fullWidth
                     label="Confirm Email"
                     name="confirm_email"
                     type="email"
                     autoComplete="user-email"
                     value={confirm_email}
                     required
                     onChange={(e) => setconfirm_email(e.target.value)}
                  />
                  </FormControl>
                </Grid> */}

              <Grid
                item
                md={4}
                xs={12}
                onChange={(e) => {
                  setSearchCountry(e.target.value);
                }}
              >
                <Autocomplete
                  options={countryOption}
                  value={countryData}
                  // size="small"
                  name="countryOption"
                  onChange={(event, newData_xy) => {
                    setCountryData(newData_xy);
                    setStateData(null);
                    setDistrictData(null);
                    setUniData(null);
                    setCollegeDataa(null);
                    setErrorCountry(newData_xy === null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Country"
                      variant="outlined"
                      error={errorCountry}
                      helperText={errorCountry ? "Please select a Country" : ""}
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12} onChange={searchData}>
                <Autocomplete
                  options={countryData ? stateOption : []}
                  value={stateData}
                  // size="small"
                  name="stateOption"
                  onChange={(event, newData_xy) => {
                    setStateData(newData_xy);
                    setDistrictData(null);
                    setCollegeDataa(null);
                    setErrorForState(newData_xy === null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search State"
                      variant="outlined"
                      error={errorForState}
                      helperText={errorForState ? "Please select a state" : ""}
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Autocomplete
                  options={stateData ? districtOption : []}
                  value={districtData}
                  // size="small"
                  name="districtOption"
                  onChange={(event, newData_xy) => {
                    setDistrictData(newData_xy);
                    setErrorForDistrict(newData_xy === null);
                    setCollegeDataa(null);
                  }}
                  onInputChange={(x, e) => {
                    if (e && e.length > 0) {
                      searchClg(e);
                    } else {
                      setDistrictOption(defaultClg);
                    }
                    setSearchDistrict(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search District"
                      variant="outlined"
                      error={errorForDistrict}
                      value={searchDistrict}
                      helperText={
                        errorForDistrict ? "Please select a District" : ""
                      }
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              {(districtData?.id === 0 || otherDistrictName) && (
                <Grid item md={4} xs={12}>
                  <TextField
                    name="OtherDistrictName"
                    // size="small"
                    fullWidth
                    id="OtherDistrictName"
                    label="Other Distrcit Name"
                    value={otherDistrictName}
                    required
                    onChange={(e) => {
                      setOtherDistrictName(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Grid>
              )}

              <Grid item md={4} xs={12}>
                <Autocomplete
                  options={uniOption}
                  // size="small"
                  value={uniData}
                  name="uniOption"
                  onChange={(event, newData_xy) => {
                    setUniData(newData_xy);
                    setErrorForUni(newData_xy === null);
                    setCollegeDataa(null);
                  }}
                  onInputChange={(x, e) => {
                    if (e && e.length > 0) {
                      searchuni(e);
                    } else {
                      setUniOption(defaultUni);
                    }
                    setSearchUniversity(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search University"
                      variant="outlined"
                      error={errorForUni}
                      value={searchUniversity}
                      helperText={
                        errorForUni ? "Please select a university" : ""
                      }
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              {(uniData?.id === 0 || otherUniversityName) && (
                <Grid item md={4} xs={12}>
                  <TextField
                    name="OtherUniversityName"
                    // size="small"
                    fullWidth
                    id="OtherUniversityName"
                    label="Other University Name"
                    value={otherUniversityName}
                    required
                    onChange={(e) => {
                      setOtherUniversityName(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Grid>
              )}

              <Grid item md={4} xs={12}>
                <Autocomplete
                  options={districtData ? collegeOption : []}
                  value={collegeDataa}
                  // size="small"
                  name="collegeOption"
                  onChange={(event, newData_xyc) => {
                    setCollegeDataa(newData_xyc);
                  }}
                  onInputChange={(x, e) => {
                    if (e && e.length > 0) {
                      searchdistClg(e);
                    } else {
                      setCollegeOption(defaultdistClg);
                    }
                    setSearchCollege(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search College"
                      variant="outlined"
                      value={searchCollege}
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
                {/* <Autocomplete
                      multiple                  
                      required
                      // size="small"
                      value={collegeDataa}
                      onChange={(event, newValue) => {
                        setCollegeDataa([...newValue]);
                        seterrorForCollege(newValue === []);
                      }}                    
                      options={collegeOption}
                      getOptionLabel={option =>
                         option.name
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Select College"
                          color="warning"
                          error={errorForCollege}
                          helperText={
                            errorForCollege ? "Please select a college" : ""
                          }
                        />
                      )}
                    /> */}
              </Grid>

              {(collegeDataa?.id === 0 || otherCollegeName) && (
                <Grid item md={4} xs={12}>
                  <TextField
                    name="OtherCollegeName"
                    fullWidth
                    // size="small"
                    id="OtherCollegeName"
                    label="Other College Name"
                    value={otherCollegeName}
                    required
                    onChange={(e) => {
                      setOtherCollegeName(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Grid>
              )}

              <Grid item md={2} xs={12}>
                <FormControl  fullWidth>
                  <InputLabel color="warning">Password*</InputLabel>
                  <OutlinedInput
                    color="warning"
                    name="password"
                    // size="small"
                    value={password}
                    autoComplete="new-password"
                    required
                    onChange={(e) => setpassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 1)", // Border color for the outline
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 1)", // Border color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCA311", // Border color when focused
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                  <InputLabel color="warning">Confirm Password*</InputLabel>
                  <OutlinedInput
                    color="warning"
                    name="confirm_password"
                    // size="small"
                    required
                    autoComplete="confirm-password"
                    value={confirm_password}
                    onChange={(e) => setconfirm_password(e.target.value)}
                    type={showPassword1 ? "text" : "password"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 1)", // Border color for the outline
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 1)", // Border color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCA311", // Border color when focused
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="ConfirmPassword"
                  />
                </FormControl>
              </Grid>

              <Grid item container sm={12} spacing={2}>
                <Grid item md={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={btnstyle1}
                    fullWidth
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={2}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Register Student
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StudentRegistration;
