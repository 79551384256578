import * as React from "react";
import { Modal, Typography, Button, Box, Tooltip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import { Link ,useNavigate} from "react-router-dom";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function EditGdModalInMultipleInterviewerTree(props) {
    const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   if (stateAddresssing.validate) {
  //     setOpen(true);
  //   } else {
  //     toast.warning("You can't Edit the GD");  
  //   }
  // };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  let { stateAddresssing, status } = props;

  const handleClick = () => {
    navigate("/edit_multiplegd_intree", { state: stateAddresssing });
  };

  const handleClick1 = () => {
    navigate("/edit_multiplegd_ingroup", { state: stateAddresssing });
  };

  return (
    <div>
      <Tooltip title="GD Edit">
        <Button
          onClick={handleOpen}
          sx={{
            background: "blue", color: "white" 
          }}
        >
          {/* <FaEdit fontSize={18} /> */}
        
          Multiple
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography id="modal-modal-title" variant="h5">
           Do You Want to Edit..?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {/* <Link to="/edit_multiplegd_intree" state={stateAddresssing}> */}
              <Button
                sx={{ padding: "15px", background: "green", color: "white" }}
                variant="contained"
                onClick={handleClick}
              >
                Interviewer Groups
              </Button>
            {/* </Link> */}

            {/* <Link state={stateAddresssing} to="/edit_multiplegd_ingroup"> */}
              <Button
                sx={{ padding: "15px", background: "blue", color: "white" }}
                variant="contained"
                onClick={handleClick1}
              >
                All Groups
              </Button>
            {/* </Link> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
