import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { NavLink, Outlet } from "react-router-dom";
import Styles from "./drawer.module.css";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import {
  MdPersonAddAlt1,
  MdAddCall,
  MdOutlineDomain,
  MdAppRegistration,
} from "react-icons/md";
import Cookies from "js-cookie";
import { FaList, FaBlenderPhone } from "react-icons/fa";
import { BsFillPhoneVibrateFill } from "react-icons/bs";
import { RiPlayList2Fill, RiPlayListFill } from "react-icons/ri";
import { IoIosNotifications } from "react-icons/io";

const drawerWidth = "20%"; 

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      width: "30vw", 
      top: "0vh", 
    },
    [theme.breakpoints.up("lg")]: {
      width: "20vw",
      top: "0vh",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background:
      "radial-gradient(circle at 24.1% 68.8%, #222831 0%, #222831 99.4%)",
    [theme.breakpoints.up("md")]: {
      // top: "8%",
    },
    [theme.breakpoints.up("lg")]: {
      // top: "6%",
    },
  },
  drawerContainer: {
    overflow: "auto",
    width: "20vw",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20vw",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#001D6E",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#1B4242",
    color: "#fff",
  },
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function DrawerSuperAdmin() {
  const classes = useStyles();
  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setState(open);
  };

  const drawer = (
    <div
      className={classes.drawerContainer}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Box
          style={{
            color: "#EDF7FA",
            width: "100%",
            fontSize: "30px",
            padding: "10px",
            textAlign: "center",
            fontWeight: "bolder",
            borderBottom:
              role === "1"
                ? "4px solid #8ed6ff"
                : role === "5"
                ? "4px solid #FCA311"
                : " 4px solid #8ed6ff",
          }}
        >
          MENU
        </Box>

        <NavLink
          to="/post_video"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              color: "white",
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdPersonAddAlt1 color="white" fontSize={24} />
            &nbsp;&nbsp;Post Video
          </ListItem>
        </NavLink>
        <NavLink
          to="/video_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              color: "white",
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdPersonAddAlt1 color="white" fontSize={24} />
            &nbsp;&nbsp;Video List
          </ListItem>
        </NavLink>
        <NavLink
          to="/call_type"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdAddCall color="white" fontSize={24} />
            &nbsp;&nbsp;Create Call Type
          </ListItem>
        </NavLink>
        <NavLink
          to="/calltype_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <FaBlenderPhone color="white" fontSize={22} />
            &nbsp;&nbsp; Call Type List
          </ListItem>
        </NavLink>
        <NavLink
          to="/call_category"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <BsFillPhoneVibrateFill color="white" fontSize={24} />
            &nbsp;&nbsp;Create Call Category
          </ListItem>
        </NavLink>
        <NavLink
          to="/call_category_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiPlayListFill color="white" fontSize={24} />
            &nbsp;&nbsp; Call Category List
          </ListItem>
        </NavLink>
        <NavLink
          to="/create_points"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdOutlineDomain color="white" fontSize={24} />
            &nbsp;&nbsp;Create Points and Scripts
          </ListItem>
        </NavLink>
        <NavLink
          to="/points_and_scripts_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiPlayList2Fill color="white" fontSize={24} />
            &nbsp;&nbsp;Points And Script List
          </ListItem>
        </NavLink>
        <NavLink
          to="/create_escalation_type"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdAppRegistration color="white" fontSize={24} />
            &nbsp;&nbsp;Create Escalation Type
          </ListItem>
        </NavLink>    
        <NavLink
          to="/create_notification_type"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <IoIosNotifications color="white" fontSize={24} />
              Create Notification Type
          </ListItem>
        </NavLink> 
      </List>
    </div>
  );

  return (
    <div>
      <BootstrapTooltip
        title="Menu"
        placement="bottom"
        sx={{ fontWeight: "bolder" }}
      >
        <Button
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disableElevation
          onClick={toggleDrawer(true)}
          style={{ color: "#fff", fontWeight: "600" }}
        >
          <svg
            width="26"
            height="26"
            baseProfile="tiny"
            version="1.2"
            viewBox="0 0 24 24"
            id="menu"
            fill={role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#fff"}
          >
            <path d="M8 3H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 3zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 3zM8 13H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 13zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 13z"></path>
          </svg>
        </Button>
      </BootstrapTooltip>
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
        id={Styles.pareeent_2}
      >
        <div className={classes.drawer} id={Styles.pareeent_1}>
          {drawer}
        </div>
      </Drawer>
    </div>
  );
}
