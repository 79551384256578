import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Styles from "../../Navbar/navLinks/_DataAnalistMenu.module.css";
import { toast } from "react-toastify";
import {
  Avatar,
  Stack,
  Button,
  MenuItem,
  Modal,
  Box,
  Typography,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Cookies from "js-cookie";
import DrawerDataAnalist from "../../../Bd_Module/pages/DrawerDataAnalist";
import AxiosInstance from "../../../apis/AxiosInstance";
import CancelIcon from "@mui/icons-material/Cancel";
import { Menu, Select } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import Badge from "@mui/material/Badge";
import { RiGroup2Fill } from "react-icons/ri";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#001D6E",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#124076",
    color: role === "1" ? "#111" : role === "5" ? "#111" : "#fff",
    fontWeight: "bold",
  },
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

const DataAnalistMenu = () => {
  const navigate = useNavigate();

  let [treeMenu, setTreemenu] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    fetchData();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let menuRoutes = [
    "/tree_appoint_toaddressing",
    "/tree_address_torounds",
    "/tree_roundsto_shortlist",
    "/tree_onboardingdetails",
    "/tree_branchwise",
    "/count_tree",
    "/main_tree",
  ];
  let create_Menu_withRoutes =
    treeMenu.length > 0
      ? treeMenu.map((menu, index) => {
          return {
            menu: menu,
            route: menuRoutes[index],
          };
        })
      : [];
  let handleLogout = () => {
    Cookies.remove("logged");
    Cookies.remove("incub_BD");
    Cookies.remove("current_role");
    toast.success("Logged out successfully");
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
  };

  const handleNoClick = () => {
    setOpenModal(false);
  };

  let fetchData = async (_) => {
    let apiCall = await AxiosInstance.get("data_split_details");
    let finalApi = apiCall.data.data;
    setTreemenu(finalApi);
  };

  const handleMenuChange = (event, index) => {
    window.location.assign(menuRoutes[index]);
  };

  const [selectedOption, setSelectedOption] = React.useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    // setSelectedOption(value);

    if (value === 1) {
      navigate("/lead_passing_form");
    } else if (value === 2) {
      navigate("/lead_passing_form_list");
    } else if (value === 3) {
      navigate("/rewards");
    }
    setSelectedOption("");
  };

  const renderOptionLabel = (value) => {
    switch (value) {
      case 1:
        return "Lead Passing Form";
      case 2:
        return "Wallet";
      default:
        return "Lead Passing";
    }
  };

  let [leadPassingFormLists, setLeadPassingFormLists] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let fetchData = async () => {
      const leadId = ["19", "20"].includes(role) ? 3 : 5;
      let endpoint = `/get_lead_passing?page=${page}&lead_id=${leadId}`;

      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data;

        setLeadPassingFormLists(finalData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <section id={Styles.NavMenuContaiiner}>
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            sx={{
              border: "#39A7FF 1px solid",
              color: "#fff",
              ":hover": {
                color: "#39A7FF",
                border: "#fff 1px solid",
                fontWeight: "bold",
              },
            }}
          >
            Tree Menu{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fill-rule="evenodd">
                <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                <path
                  fill="#39A7FF"
                  d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
                />
              </g>
            </svg>
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {create_Menu_withRoutes.map((item, index) => {
              return (
                <MenuItem
                  onClick={(event) => handleMenuChange(event, index)}
                  key={index}
                >
                  <Link
                    to={`${item.route}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {" "}
                    {item.menu}
                  </Link>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <div>
        <Select
            value={selectedOption}
            size="small"
            onChange={handleChange}
            displayEmpty
            renderValue={(selected) => (
              <Typography sx={{ fontSize: "0.875rem", color: "white" }}>
                Lead Passing
              </Typography>
            )}
            style={{ width: 200 }}
            sx={{
              color: "white",
              borderColor: "#8ed6ff",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#8ed6ff",
              },
              "& .MuiSelect-icon": {
                color: "white",
              },
              ":hover": {
                color: "#fff",
                border: "#8ed6ff 1px solid",
              },
            }}
          >
            <MenuItem
              value="Lead Passing"
              disabled
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Lead Passing
            </MenuItem>
            <MenuItem
              value={1}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Lead Passing Form
            </MenuItem>
            <MenuItem
              value={2}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Lead Passing List
            </MenuItem>
            <MenuItem
              value={3}
              sx={{
                fontSize: "0.875rem",
              }}
            >
              Wallet
            </MenuItem>
          </Select>
        </div>
        <NavLink
          to="/profile"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip arrow placement="bottom" title="View Profile">
              <Avatar sx={{ bgcolor: "#fff", color: "#39A7FF" }}>
                <PersonIcon size="40" />
              </Avatar>
            </Tooltip>
          </Stack>
        </NavLink>
        <NavLink
          to="/lead_passing_form_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <BootstrapTooltip
            title="Not-Converted Lead Count"
            placement="bottom"
            sx={{ fontWeight: "bolder" }}
          >
            <Badge
              badgeContent={leadPassingFormLists?.not_converted || 0}
              color="warning"
              max={99999}
            >
              <svg
                viewBox="0 0 64 64"
                height="1.7em"
                width="1.5em"
                aria-hidden="true"
                role="img"
                class="iconify iconify--emojione-monotone"
                preserveAspectRatio="xMidYMid meet"
                fill="#fff"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M57.907 25.12c-3.965-7.041-11.125-10.465-21.892-10.465h-.21c-1.921-3.833-6.707-6.68-11.536-6.68c-1.092 0-2.146.146-3.148.429c-1.766-1.774-4.091-2.866-6.206-2.866c-.324 0-.639.032-.944.083l-.167-.115C11.408 3.843 9.244 3 7.373 3c-2.016 0-3.638.952-4.567 2.683c-1.552 2.887-.813 7.601 1.797 11.463l.198.293c-1.543 3.857-.334 9.558 1.936 11.947v.546c0 16.376 17.344 25.315 30.501 25.814C43.666 59.157 50.196 61 52.255 61c2.187 0 3.518-.512 3.867-.664c.008-.002.014-.006.021-.009c.051-.022.081-.037.081-.037l-.003-.004c.622-.31.906-1.157 1.006-2.277l.002.002l.001-.037c.015-.161.025-.327.031-.498c.055-.673.199-1.882.578-3.048C60.597 53.186 62 51.27 62 48.672c0-8.457-.495-17.165-4.093-23.552M7.373 4.87c1.245 0 2.833.537 4.765 1.782l-.115.426c-.562 2.079-1.816 3.597-3.667 5.674a28.988 28.988 0 0 0-1.382 1.713c-.133.173-.261.332-.391.499c-.445.382-.726.581-.751.599C2.846 10.689 3.242 4.87 7.373 4.87m-1 13.779c.271-.284.688-.681 1.19-1.178c3.809-2.205 5.332-5.921 6.351-8.42c.186-.457.36-.887.52-1.229l.184-.394c.099-.008.196-.021.298-.021c1.401 0 3.024.698 4.361 1.841l-.199.674c-.23.781-.508 1.576-.806 2.374c-2.832 6.213-8.346 13.606-10.586 15.298c-1.405-1.995-2.2-6.05-1.313-8.945m2.289 12.456c.879-.843 2.06-2.102 3.356-3.65a28.361 28.361 0 0 0 2.155-2.506c2.011-2.638 5.693-8.002 7.756-14.288l.187-.57a9.817 9.817 0 0 1 2.153-.244c4.236 0 8.529 2.566 10.008 5.985c-2.164 5.166-5.935 10.987-7.115 12.761c-1.037.761-1.685 1.399-1.861 1.553c-1.221 1.066-1.489 2.461-1.251 2.706c-1.225 2.505-2.052 6.406-.704 9.796c1.72 4.326 5.629 7.977 10.06 10.855c-11.659-1.815-24.017-9.784-24.744-22.398M45.264 45.98c-5.404-2.599-9.874-4.372-11.334-8.366c-1.02-2.771-1.803-5.821-2.192-7.95a13.758 13.758 0 0 1 1.96-.292C45.96 28.547 56.251 41.931 57 48.405c-1.802-.565-8.324-.785-11.736-2.425m-9.852-20.564c-.944.065-1.831.231-2.667.455c1.238-2.117 3.008-5.439 4.453-9.319c19.403.495 22.56 13.132 22.885 28.83c-2.377-11.295-16.171-20.559-24.671-19.966"
                    fill="#fff"
                  ></path>
                </g>
              </svg>
            </Badge>
          </BootstrapTooltip>
        </NavLink>
        <DrawerDataAnalist title="Menu" />
        <span
          onClick={() => setOpenModal(true)}
          style={{
            textDecoration: "none",
            color: "#fff",
            background: "none",
            fontSize: "18px",
            cursor: "pointer",
          }}
        >
          Logout
        </span>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 370,
              bgcolor: "background.paper",
              border: "2px solid #182747",
              boxShadow: 24,
              p: 2,
              borderRadius: "5px",
            }}
          >
            <IconButton
              onClick={() => setOpenModal(false)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "red",
              }}
            >
              <CancelIcon />
            </IconButton>
            <Typography
              sx={{
                mt: 2,
                color: "#06113C",
                fontSize: "20px",
                fontWeight: "900",
              }}
            >
              Do you want to Logout ?
            </Typography>
            <ButtonGroup sx={{ mt: 5 }} fullWidth>
              <Button
                onClick={handleLogout}
                style={{
                  background: "#06113C",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                  padding: "10px 10px",
                }}
              >
                Yes
              </Button>{" "}
              &nbsp;&nbsp;
              <Button
                onClick={handleNoClick}
                style={{
                  background: "#C7253E",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </Box>
        </Modal>
      </section>
    </div>
  );
};

export default DataAnalistMenu;
