// // import React, { useState } from "react";
// // import { makeStyles } from "@material-ui/core/styles";
// // import Drawer from "@material-ui/core/Drawer";
// // import Button from "@material-ui/core/Button";
// // import List from "@mui/material/List";
// // import ListItem from "@mui/material/ListItem";
// // import { NavLink } from "react-router-dom";
// // import Styles from "./drawer.module.css";
// // import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// // import styled from "@emotion/styled";
// // import { Box } from "@material-ui/core";
// // import { BiLayerPlus } from "react-icons/bi";
// // import {
// //   MdReduceCapacity,
// //   MdGroups2,
// //   MdGroupRemove,
// //   MdPersonAddAlt1,
// //   MdOutlineDomain,
// //   MdRecentActors,
// //   MdAppRegistration,
// //   MdDashboard,
// //   MdGroup,
// //   MdAddCall,
// // } from "react-icons/md";
// // import { FaList, FaBlenderPhone } from "react-icons/fa";
// // import { BsFillPhoneVibrateFill } from "react-icons/bs";
// // import { RiPlayList2Fill, RiGroup2Fill } from "react-icons/ri";
// // import { TfiLayoutListPost } from "react-icons/tfi";
// // import { IoMdList } from "react-icons/io";
// // import Cookies from "js-cookie";
// // import { MdRecordVoiceOver } from "react-icons/md";
// // import { MdOutlineCallMissedOutgoing } from "react-icons/md";
// // import { TfiLayoutListThumb } from "react-icons/tfi";
// // import { TfiLayoutListThumbAlt } from "react-icons/tfi";
// // import { RiListCheck2 } from "react-icons/ri";
// // import { HiGiftTop } from "react-icons/hi2";

// // const drawerWidth = "20%"; // Set the drawer width to occupy only half of the screen

// // const useStyles = makeStyles((theme) => ({
// //   drawer: {
// //     width: drawerWidth,
// //     height: "100vh",
// //     [theme.breakpoints.up("md")]: {
// //       width: "23vw", // Change the width to 30vw on medium-sized screens (laptop)
// //       top: "0vh", // Change the margin from the top to 10vh on medium-sized screens
// //     },
// //     [theme.breakpoints.up("lg")]: {
// //       width: "20vw", // Change the width to 20vw on large-sized screens (desktop)
// //       top: "0vh",
// //     },
// //   },
// //   drawerPaper: {
// //     width: drawerWidth,
// //     background:
// //       "radial-gradient(circle at 24.1% 68.8%, #222831 0%, #222831 99.4%)",
// //     [theme.breakpoints.up("md")]: {
// //       // top: "8%",
// //     },
// //     [theme.breakpoints.up("lg")]: {
// //       // top: "6%",
// //     },
// //   },
// //   drawerContainer: {
// //     overflow: "auto",
// //     width: "20vw",
// //     [theme.breakpoints.up("md")]: {
// //       width: "23vw",
// //     },
// //     [theme.breakpoints.up("lg")]: {
// //       width: "20vw",
// //     },
// //   },
// //   content: {
// //     flexGrow: 1,
// //     padding: theme.spacing(3),
// //   },
// // }));
// // const BootstrapTooltip = styled(({ className, ...props }) => (
// //   <Tooltip {...props} arrow classes={{ popper: className }} />
// // ))(({ theme }) => ({
// //   [`& .${tooltipClasses.arrow}`]: {
// //     color: "#001D6E",
// //   },
// //   [`& .${tooltipClasses.tooltip}`]: {
// //     backgroundColor:
// //       role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#8ed6ff",
// //     color: "#001D6E",
// //   },
// // }));

// // const parseLog = (a) => {
// //   const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
// //   const { id } = userData.role[0];
// //   return id.toString();
// // };
// // const role = parseLog(Cookies.get("logged"));

// // export default function TemporaryDrawer() {
// //   const classes = useStyles();
// //   const [state, setState] = useState(false);

// //   const toggleDrawer = (open) => (event) => {
// //     setState(open);
// //   };

// //   const drawer = (
// //     <div
// //       className={classes.drawerContainer}
// //       role="presentation"
// //       onClick={toggleDrawer(false)}
// //       onKeyDown={toggleDrawer(false)}
// //     >
// //       <List>
// //         <Box
// //           style={{
// //             color: "#EDF7FA",
// //             width: "100%",
// //             fontSize: "30px",
// //             padding: "10px",
// //             textAlign: "center",
// //             fontWeight: "bolder",
// //             borderBottom:
// //               role === "1"
// //                 ? "4px solid #8ed6ff"
// //                 : role === "5"
// //                 ? "4px solid #FCA311"
// //                 : " 4px solid #8ed6ff",
// //           }}
// //         >
// //           MENU
// //         </Box>
// //         <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>
// //           <ListItem
// //             sx={{
// //               color: "white",
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 250ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdDashboard color="white" fontSize={24} />
// //             &nbsp;&nbsp;Dashboard
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/spoc_creation"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               color: "white",
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 250ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdPersonAddAlt1 color="white" fontSize={24} />
// //             &nbsp;&nbsp;Create Spoc
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/college_spoc_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px", 
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <TfiLayoutListThumbAlt color="white" fontSize={22} />
// //             &nbsp;&nbsp; College Spoc List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/create_collegeappointment"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "14px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 250ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <BiLayerPlus color="white" fontSize={24} />
// //             &nbsp;&nbsp;Create College Appointment
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/college_list_data"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdOutlineDomain color="white" fontSize={24} />
// //             &nbsp;&nbsp;College List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/appointment_details_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <TfiLayoutListPost color="white" fontSize={24} />
// //             &nbsp;&nbsp;Appointment List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/create_newaddressing"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdAppRegistration color="white" fontSize={24} />
// //             &nbsp;&nbsp;Create Addressing
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/addressing_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdRecentActors color="white" fontSize={24} />
// //             &nbsp;&nbsp; Addressing List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/gd_creation"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdReduceCapacity color="white" fontSize={24} />
// //             &nbsp;&nbsp;GD Creation
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           // to="/gd_list"
// //           to="/gd_creation_list" 
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <RiPlayList2Fill color="white" fontSize={24} />
// //             &nbsp;&nbsp;GD List
// //           </ListItem>
// //         </NavLink>

// //         {/* <NavLink
// //           to="/interview_creation"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdGroup color="white" fontSize={24} />
// //             &nbsp;&nbsp;Interview Creation
// //           </ListItem>
// //         </NavLink>

// //         <NavLink
// //           to="/interview_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <IoMdList color="white" fontSize={24} />
// //             &nbsp;&nbsp;Interview List
// //           </ListItem>
// //         </NavLink> */}

// //         <NavLink
// //           to="/post_addressing"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdAppRegistration color="white" fontSize={24} />
// //             &nbsp;&nbsp;Create Onboarding
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/post_addressing_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdRecentActors color="white" fontSize={24} />
// //             &nbsp;&nbsp; Onboarding List
// //           </ListItem>
// //         </NavLink>
// //         {role === "5" ? (
// //           <NavLink
// //             to="/gd_evaluation_criteria"
// //             style={{ textDecoration: "none", color: "white" }}
// //           >
// //             <ListItem
// //               sx={{
// //                 fontSize: "15px",
// //                 "&:hover": {
// //                   backgroundColor: "#182747",
// //                   cursor: "pointer",
// //                   transition: "all ease-in-out 400ms",
// //                   color:
// //                     role === "1"
// //                       ? "#8ed6ff"
// //                       : role === "5"
// //                       ? "#FCA311"
// //                       : "#8ed6ff",
// //                 },
// //               }}
// //             >
// //               <MdGroups2 color="white" fontSize={24} />
// //               &nbsp;&nbsp;Create GD Evaluation Criteria
// //             </ListItem>
// //           </NavLink>
// //         ) : (
// //           ""
// //         )}
// //         {role === "5" ? (
// //           <NavLink
// //             to="/edit_gd_evaluation_criteria"
// //             style={{ textDecoration: "none", color: "white" }}
// //           >
// //             <ListItem
// //               sx={{
// //                 fontSize: "15px",
// //                 "&:hover": {
// //                   backgroundColor: "#182747",
// //                   cursor: "pointer",
// //                   transition: "all ease-in-out 400ms",
// //                   color:
// //                     role === "1"
// //                       ? "#8ed6ff"
// //                       : role === "5"
// //                       ? "#FCA311"
// //                       : "#8ed6ff",
// //                 },
// //               }}
// //             >
// //               <MdGroupRemove color="white" fontSize={24} />
// //               &nbsp;&nbsp;Edit GD Evaluation Criteria
// //             </ListItem>
// //           </NavLink>
// //         ) : (
// //           ""
// //         )}

// //         <NavLink
// //           to="/otp_failed_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <FaList color="white" fontSize={20} />
// //             &nbsp;&nbsp; OTP Failed List
// //           </ListItem>
// //         </NavLink>
// //         {/* <NavLink
// //           to="/call_type"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdAddCall color="white" fontSize={24} />
// //             &nbsp;&nbsp;Create Call Type
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/calltype_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <FaBlenderPhone color="white" fontSize={22} />
// //             &nbsp;&nbsp; Call Type List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/call_category"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <BsFillPhoneVibrateFill color="white" fontSize={24} />
// //             &nbsp;&nbsp;Create Call Category
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/call_category_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <RiPlayListFill color="white" fontSize={24} />
// //             &nbsp;&nbsp; Call Category List
// //           </ListItem>
// //         </NavLink> */}
// //         <NavLink
// //           to="/call_record_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdRecordVoiceOver color="white" fontSize={22} />
// //             &nbsp;&nbsp; Call Record List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/missed_call_details"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdOutlineCallMissedOutgoing  color="white" fontSize={22} />
// //             &nbsp;&nbsp; Missed call List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/nostatus_record_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdRecordVoiceOver  color="white" fontSize={22} />
// //             &nbsp;&nbsp; No Status Record List
// //           </ListItem>
// //         </NavLink>
// //       {/* <NavLink
// //           to="/call_handled_escalation_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <RiPlayListFill color="white" fontSize={23} />
// //             &nbsp;&nbsp;Missed Call Handled Escalation List
// //           </ListItem>
// //         </NavLink> */}
       
// //         <NavLink
// //           to="/missed_call_escalation_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <FaBlenderPhone color="white" fontSize={20} />
// //             &nbsp;&nbsp;Missed Call Escalation List
// //           </ListItem>
// //         </NavLink>
// //         {/* <NavLink
// //           to="/nostatus_handled_escalation_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <MdRecordVoiceOver color="white" fontSize={21} />
// //             &nbsp;&nbsp; No Status Handled Escalation List
// //           </ListItem>
// //         </NavLink> */}
// //         <NavLink
// //           to="/nostatus_escalation_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <TfiLayoutListThumbAlt color="white" fontSize={21} />
// //             &nbsp;&nbsp; No Status Escalation List
// //           </ListItem>
// //         </NavLink>
// //          <NavLink
// //           to="/lead_passing_form"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "14px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 250ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <RiGroup2Fill color="white" fontSize={24} />
// //             &nbsp;&nbsp;Lead Passing Form
// //           </ListItem>
// //         </NavLink>
// //           <NavLink
// //           to="/lead_passing_form_list"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 400ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <RiListCheck2 color="white" fontSize={21} />
// //             &nbsp;&nbsp; Lead Passing List
// //           </ListItem>
// //         </NavLink>
// //         <NavLink
// //           to="/rewards"
// //           style={{ textDecoration: "none", color: "white" }}
// //         >
// //           <ListItem
// //             sx={{
// //               color: "white",
// //               fontSize: "15px",
// //               "&:hover": {
// //                 backgroundColor: "#182747",
// //                 cursor: "pointer",
// //                 transition: "all ease-in-out 250ms",
// //                 color:
// //                   role === "1"
// //                     ? "#8ed6ff"
// //                     : role === "5"
// //                     ? "#FCA311"
// //                     : "#8ed6ff",
// //               },
// //             }}
// //           >
// //             <HiGiftTop  color="white" fontSize={24} />
// //             &nbsp;&nbsp;Lead Passing Rewards
// //           </ListItem>
// //         </NavLink>
// //       </List>
// //     </div>
// //   );

// //   return (
// //     <div>
// //       <BootstrapTooltip
// //         title="Menu"
// //         placement="bottom"
// //         sx={{ fontWeight: "bolder" }}
// //       >
// //         <Button
// //           disableRipple
// //           disableTouchRipple
// //           disableFocusRipple
// //           disableElevation
// //           onClick={toggleDrawer(true)}
// //           style={{ color: "#8ed6ff", fontWeight: "600" }}
// //         >
// //           <svg
// //             width="26"
// //             height="26"
// //             baseProfile="tiny"
// //             version="1.2"
// //             viewBox="0 0 24 24"
// //             id="menu"
// //             fill={
// //               role === "1"
// //                 ? "#8ed6ff"
// //                 : role === "5"
// //                 ? "#FCA311"
// //                 : role === "15"
// //                 ? "#39A7FF"
// //                 : "#8ed6ff"
// //             }
// //           >
// //             <path d="M8 3H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 3zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 3zM8 13H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 13zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 13z"></path>
// //           </svg>
// //         </Button>
// //       </BootstrapTooltip>
// //       <Drawer
// //         anchor="right"
// //         open={state}
// //         onClose={toggleDrawer(false)}
// //         classes={{ paper: classes.drawerPaper }}
// //         id={Styles.pareeent_2}
// //       >
// //         <div className={classes.drawer} id={Styles.pareeent_1}>
// //           {drawer}
// //         </div>
// //       </Drawer>
// //     </div>
// //   );
// // }


// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Drawer from "@material-ui/core/Drawer";
// import Button from "@material-ui/core/Button";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import { NavLink } from "react-router-dom";
// import Styles from "./drawer.module.css";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import styled from "@emotion/styled";
// import { Box } from "@material-ui/core";
// import { BiLayerPlus } from "react-icons/bi";
// import {
//   MdReduceCapacity,
//   MdGroups2,
//   MdGroupRemove,
//   MdPersonAddAlt1,
//   MdOutlineDomain,
//   MdRecentActors,
//   MdAppRegistration,
//   MdDashboard,
//   MdGroup,
//   MdAddCall,
// } from "react-icons/md";
// import { FaList, FaBlenderPhone } from "react-icons/fa";
// import { RiPlayList2Fill, RiGroup2Fill } from "react-icons/ri";
// import { TfiLayoutListPost } from "react-icons/tfi";
// import { IoMdList } from "react-icons/io";
// import Cookies from "js-cookie";
// import { MdRecordVoiceOver } from "react-icons/md";
// import { MdOutlineCallMissedOutgoing } from "react-icons/md";
// import { TfiLayoutListThumb } from "react-icons/tfi";
// import { TfiLayoutListThumbAlt } from "react-icons/tfi";
// import { RiListCheck2 } from "react-icons/ri";
// import { HiGiftTop } from "react-icons/hi2";

// const drawerWidth = "20%"; // Set the drawer width to occupy only half of the screen

// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     height: "100vh",
//     [theme.breakpoints.up("md")]: {
//       width: "23vw", // Change the width to 30vw on medium-sized screens (laptop)
//       top: "0vh", // Change the margin from the top to 10vh on medium-sized screens
//     },
//     [theme.breakpoints.up("lg")]: {
//       width: "20vw", // Change the width to 20vw on large-sized screens (desktop)
//       top: "0vh",
//     },
//   },
//   drawerPaper: {
//     width: drawerWidth,
//     background:
//       "radial-gradient(circle at 24.1% 68.8%, #222831 0%, #222831 99.4%)",
//     [theme.breakpoints.up("md")]: {
//       // top: "8%",
//     },
//     [theme.breakpoints.up("lg")]: {
//       // top: "6%",
//     },
//   },
//   drawerContainer: {
//     overflow: "auto",
//     width: "20vw",
//     [theme.breakpoints.up("md")]: {
//       width: "23vw",
//     },
//     [theme.breakpoints.up("lg")]: {
//       width: "20vw",
//     },
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//   },
// }));
// const BootstrapTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: "#001D6E",
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor:
//       role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#8ed6ff",
//     color: "#001D6E",
//   },
// }));

// const parseLog = (a) => {
//   const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
//   const { id } = userData.role[0];
//   return id.toString();
// };
// const role = parseLog(Cookies.get("logged"));

// export default function TemporaryDrawer() {
//   const classes = useStyles();
//   const [state, setState] = useState(false);

//   const toggleDrawer = (open) => (event) => {
//     setState(open);
//   };

//   const drawer = (
//     <div
//       className={classes.drawerContainer}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <Box
//           style={{
//             color: "#EDF7FA",
//             width: "100%",
//             fontSize: "30px",
//             padding: "10px",
//             textAlign: "center",
//             fontWeight: "bolder",
//             borderBottom:
//               role === "1"
//                 ? "4px solid #8ed6ff"
//                 : role === "5"
//                 ? "4px solid #FCA311"
//                 : " 4px solid #8ed6ff",
//           }}
//         >
//           MENU
//         </Box>
//         <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>
//           <ListItem
//             sx={{
//               color: "white",
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 250ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdDashboard color="white" fontSize={24} />
//             &nbsp;&nbsp;Dashboard
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/spoc_creation"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               color: "white",
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 250ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdPersonAddAlt1 color="white" fontSize={24} />
//             &nbsp;&nbsp;Create Spoc
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/college_spoc_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <TfiLayoutListThumbAlt color="white" fontSize={22} />
//             &nbsp;&nbsp; College Spoc List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/create_collegeappointment"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "14px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 250ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <BiLayerPlus color="white" fontSize={24} />
//             &nbsp;&nbsp;Create College Appointment
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/college_list_data"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdOutlineDomain color="white" fontSize={24} />
//             &nbsp;&nbsp;College List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/appointment_details_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <TfiLayoutListPost color="white" fontSize={24} />
//             &nbsp;&nbsp;Appointment List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/create_newaddressing"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdAppRegistration color="white" fontSize={24} />
//             &nbsp;&nbsp;Create Addressing
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/addressing_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdRecentActors color="white" fontSize={24} />
//             &nbsp;&nbsp; Addressing List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/gd_creation"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdReduceCapacity color="white" fontSize={24} />
//             &nbsp;&nbsp;GD Creation
//           </ListItem>
//         </NavLink>
//         <NavLink
//           // to="/gd_list"
//           to="/gd_creation_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <RiPlayList2Fill color="white" fontSize={24} />
//             &nbsp;&nbsp;GD List
//           </ListItem>
//         </NavLink>

//         {/* <NavLink
//           to="/interview_creation"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdGroup color="white" fontSize={24} />
//             &nbsp;&nbsp;Interview Creation
//           </ListItem>
//         </NavLink>

//         <NavLink
//           to="/interview_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <IoMdList color="white" fontSize={24} />
//             &nbsp;&nbsp;Interview List
//           </ListItem>
//         </NavLink> */}

//         <NavLink
//           to="/post_addressing"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdAppRegistration color="white" fontSize={24} />
//             &nbsp;&nbsp;Create Onboarding
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/post_addressing_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdRecentActors color="white" fontSize={24} />
//             &nbsp;&nbsp; Onboarding List
//           </ListItem>
//         </NavLink>
//         {role === "5" ? (
//           <NavLink
//             to="/gd_evaluation_criteria"
//             style={{ textDecoration: "none", color: "white" }}
//           >
//             <ListItem
//               sx={{
//                 fontSize: "15px",
//                 "&:hover": {
//                   backgroundColor: "#182747",
//                   cursor: "pointer",
//                   transition: "all ease-in-out 400ms",
//                   color:
//                     role === "1"
//                       ? "#8ed6ff"
//                       : role === "5"
//                       ? "#FCA311"
//                       : "#8ed6ff",
//                 },
//               }}
//             >
//               <MdGroups2 color="white" fontSize={24} />
//               &nbsp;&nbsp;Create GD Evaluation Criteria
//             </ListItem>
//           </NavLink>
//         ) : (
//           ""
//         )}
//         {role === "5" ? (
//           <NavLink
//             to="/edit_gd_evaluation_criteria"
//             style={{ textDecoration: "none", color: "white" }}
//           >
//             <ListItem
//               sx={{
//                 fontSize: "15px",
//                 "&:hover": {
//                   backgroundColor: "#182747",
//                   cursor: "pointer",
//                   transition: "all ease-in-out 400ms",
//                   color:
//                     role === "1"
//                       ? "#8ed6ff"
//                       : role === "5"
//                       ? "#FCA311"
//                       : "#8ed6ff",
//                 },
//               }}
//             >
//               <MdGroupRemove color="white" fontSize={24} />
//               &nbsp;&nbsp;Edit GD Evaluation Criteria
//             </ListItem>
//           </NavLink>
//         ) : (
//           ""
//         )}

//         <NavLink
//           to="/otp_failed_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <FaList color="white" fontSize={20} />
//             &nbsp;&nbsp; OTP Failed List
//           </ListItem>
//         </NavLink>
//         {/* <NavLink
//           to="/call_type"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdAddCall color="white" fontSize={24} />
//             &nbsp;&nbsp;Create Call Type
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/calltype_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <FaBlenderPhone color="white" fontSize={22} />
//             &nbsp;&nbsp; Call Type List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/call_category"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <BsFillPhoneVibrateFill color="white" fontSize={24} />
//             &nbsp;&nbsp;Create Call Category
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/call_category_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <RiPlayListFill color="white" fontSize={24} />
//             &nbsp;&nbsp; Call Category List
//           </ListItem>
//         </NavLink> */}
//         <NavLink
//           to="/call_record_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdRecordVoiceOver color="white" fontSize={22} />
//             &nbsp;&nbsp; Call Record List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/missed_call_details"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdOutlineCallMissedOutgoing color="white" fontSize={22} />
//             &nbsp;&nbsp; Missed call List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/nostatus_record_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdRecordVoiceOver color="white" fontSize={22} />
//             &nbsp;&nbsp; No Status Record List
//           </ListItem>
//         </NavLink>
//         {/* <NavLink
//           to="/call_handled_escalation_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <RiPlayListFill color="white" fontSize={23} />
//             &nbsp;&nbsp;Missed Call Handled Escalation List
//           </ListItem>
//         </NavLink> */}

//         <NavLink
//           to="/missed_call_escalation_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <FaBlenderPhone color="white" fontSize={20} />
//             &nbsp;&nbsp;Missed Call Escalation List
//           </ListItem>
//         </NavLink>
//         {/* <NavLink
//           to="/nostatus_handled_escalation_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <MdRecordVoiceOver color="white" fontSize={21} />
//             &nbsp;&nbsp; No Status Handled Escalation List
//           </ListItem>
//         </NavLink> */}
//         <NavLink
//           to="/nostatus_escalation_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <TfiLayoutListThumbAlt color="white" fontSize={21} />
//             &nbsp;&nbsp; No Status Escalation List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/lead_passing_form"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "14px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 250ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <RiGroup2Fill color="white" fontSize={24} />
//             &nbsp;&nbsp;Lead Passing Form
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/lead_passing_form_list"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <RiListCheck2 color="white" fontSize={21} />
//             &nbsp;&nbsp; Lead Passing List
//           </ListItem>
//         </NavLink>
//         <NavLink
//           to="/rewards"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               color: "white",
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 250ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <HiGiftTop  color="white" fontSize={24} />
//             &nbsp;&nbsp;Wallet
//           </ListItem>
//         </NavLink>
//         {/* <NavLink
//           to="/appointment_report"
//           style={{ textDecoration: "none", color: "white" }}
//         >
//           <ListItem
//             sx={{
//               fontSize: "15px",
//               "&:hover": {
//                 backgroundColor: "#182747",
//                 cursor: "pointer",
//                 transition: "all ease-in-out 400ms",
//                 color:
//                   role === "1"
//                     ? "#8ed6ff"
//                     : role === "5"
//                     ? "#FCA311"
//                     : "#8ed6ff",
//               },
//             }}
//           >
//             <RiListCheck2 color="white" fontSize={21} />
//             &nbsp;&nbsp; Appointment Report
//           </ListItem>
//         </NavLink> */}
//       </List>
//     </div>
//   );

//   return (
//     <div>
//       <BootstrapTooltip
//         title="Menu"
//         placement="bottom"
//         sx={{ fontWeight: "bolder" }}
//       >
//         <Button
//           disableRipple
//           disableTouchRipple
//           disableFocusRipple
//           disableElevation
//           onClick={toggleDrawer(true)}
//           style={{ color: "#8ed6ff", fontWeight: "600" }}
//         >
//           <svg
//             width="26"
//             height="26"
//             baseProfile="tiny"
//             version="1.2"
//             viewBox="0 0 24 24"
//             id="menu"
//             fill={
//               role === "1"
//                 ? "#8ed6ff"
//                 : role === "5"
//                 ? "#FCA311"
//                 : role === "15"
//                 ? "#39A7FF"
//                 : "#8ed6ff"
//             }
//           >
//             <path d="M8 3H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 3zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 3zM8 13H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 13zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 13z"></path>
//           </svg>
//         </Button>
//       </BootstrapTooltip>
//       <Drawer
//         anchor="right"
//         open={state}
//         onClose={toggleDrawer(false)}
//         classes={{ paper: classes.drawerPaper }}
//         id={Styles.pareeent_2}
//       >
//         <div className={classes.drawer} id={Styles.pareeent_1}>
//           {drawer}
//         </div>
//       </Drawer>
//     </div>
//   );
// }


import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { NavLink } from "react-router-dom";
import Styles from "./drawer.module.css";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import { BiLayerPlus } from "react-icons/bi";
import {
  MdReduceCapacity,
  MdGroups2,
  MdGroupRemove,
  MdPersonAddAlt1,
  MdOutlineDomain,
  MdRecentActors,
  MdAppRegistration,
  MdDashboard,
  MdGroup,
  MdAddCall,
} from "react-icons/md";
import { FaList, FaBlenderPhone } from "react-icons/fa";
import { RiPlayList2Fill, RiGroup2Fill } from "react-icons/ri";
import { TfiLayoutListPost } from "react-icons/tfi";
import { IoMdList } from "react-icons/io";
import Cookies from "js-cookie";
import { MdRecordVoiceOver } from "react-icons/md";
import { MdOutlineCallMissedOutgoing } from "react-icons/md";
import { TfiLayoutListThumb } from "react-icons/tfi";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { RiListCheck2 } from "react-icons/ri";
import { HiGiftTop } from "react-icons/hi2";
import { useEffect } from "react";
import { FaDownload } from "react-icons/fa";

const drawerWidth = "20%"; // Set the drawer width to occupy only half of the screen

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      width: "23vw", // Change the width to 30vw on medium-sized screens (laptop)
      top: "0vh", // Change the margin from the top to 10vh on medium-sized screens
    },
    [theme.breakpoints.up("lg")]: {
      width: "20vw", // Change the width to 20vw on large-sized screens (desktop)
      top: "0vh",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background:
      "radial-gradient(circle at 24.1% 68.8%, #222831 0%, #222831 99.4%)",
    [theme.breakpoints.up("md")]: {
      // top: "8%",
    },
    [theme.breakpoints.up("lg")]: {
      // top: "6%",
    },
  },
  drawerContainer: {
    overflow: "auto",
    width: "20vw",
    [theme.breakpoints.up("md")]: {
      width: "23vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20vw",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#001D6E",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#8ed6ff",
    color: "#001D6E",
  },
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = useState(false);
  const [openShell, setOpenShell] = useState(null);

  useEffect(() => {
    let shellData = function isRunningInElectron() {
      return navigator.userAgent.toLowerCase().includes("electron");
    };

    if (shellData()) {
      setOpenShell(true);
      console.log("Running in Electron");
    } else {
      setOpenShell(false);
      console.log("Running in a web browser");
    }
  }, []);

  const toggleDrawer = (open) => (event) => {
    setState(open);
  };

  const drawer = (
    <div
      className={classes.drawerContainer}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Box
          style={{
            color: "#EDF7FA",
            width: "100%",
            fontSize: "30px",
            padding: "10px",
            textAlign: "center",
            fontWeight: "bolder",
            borderBottom:
              role === "1"
                ? "4px solid #8ed6ff"
                : role === "5"
                ? "4px solid #FCA311"
                : " 4px solid #8ed6ff",
          }}
        >
          MENU
        </Box>
        <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>
          <ListItem
            sx={{
              color: "white",
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdDashboard color="white" fontSize={24} />
            &nbsp;&nbsp;Dashboard
          </ListItem>
        </NavLink>
        {!openShell && (
        <a
          href="https://github.com/TechBhoomi/incubation-shell-app/releases/download/v-1.0.1/incubation-app-Setup-1.0.1.exe"
          style={{ textDecoration: "none", color: "white" }}
          download
        >
          <ListItem
            sx={{
              color: "white",
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <FaDownload color="white" fontSize={22} />
            &nbsp;&nbsp;Download Shell
          </ListItem>
        </a>
      )}

        <NavLink
          to="/spoc_creation"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              color: "white",
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdPersonAddAlt1 color="white" fontSize={24} />
            &nbsp;&nbsp;Create Spoc
          </ListItem>
        </NavLink>
        <NavLink
          to="/college_spoc_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <TfiLayoutListThumbAlt color="white" fontSize={22} />
            &nbsp;&nbsp; College Spoc List
          </ListItem>
        </NavLink>
        <NavLink
          to="/create_collegeappointment"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <BiLayerPlus color="white" fontSize={24} />
            &nbsp;&nbsp;Create College Appointment
          </ListItem>
        </NavLink>
        <NavLink
          to="/college_list_data"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdOutlineDomain color="white" fontSize={24} />
            &nbsp;&nbsp;College List
          </ListItem>
        </NavLink>
        <NavLink
          to="/appointment_details_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <TfiLayoutListPost color="white" fontSize={24} />
            &nbsp;&nbsp;Appointment List
          </ListItem>
        </NavLink>
        <NavLink
          to="/create_newaddressing"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdAppRegistration color="white" fontSize={24} />
            &nbsp;&nbsp;Create Addressing
          </ListItem>
        </NavLink>
        <NavLink
          to="/addressing_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdRecentActors color="white" fontSize={24} />
            &nbsp;&nbsp; Addressing List
          </ListItem>
        </NavLink>
        <NavLink
          to="/gd_creation"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdReduceCapacity color="white" fontSize={24} />
            &nbsp;&nbsp;GD Creation
          </ListItem>
        </NavLink>
        <NavLink
          // to="/gd_list"
          to="/gd_creation_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiPlayList2Fill color="white" fontSize={24} />
            &nbsp;&nbsp;GD List
          </ListItem>
        </NavLink>

        {/* <NavLink
          to="/interview_creation"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdGroup color="white" fontSize={24} />
            &nbsp;&nbsp;Interview Creation
          </ListItem>
        </NavLink>

        <NavLink
          to="/interview_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <IoMdList color="white" fontSize={24} />
            &nbsp;&nbsp;Interview List
          </ListItem>
        </NavLink> */}

        <NavLink
          to="/post_addressing"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdAppRegistration color="white" fontSize={24} />
            &nbsp;&nbsp;Create Onboarding
          </ListItem>
        </NavLink>
        <NavLink
          to="/post_addressing_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdRecentActors color="white" fontSize={24} />
            &nbsp;&nbsp; Onboarding List
          </ListItem>
        </NavLink>
        {role === "5" ? (
          <NavLink
            to="/gd_evaluation_criteria"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItem
              sx={{
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: "#182747",
                  cursor: "pointer",
                  transition: "all ease-in-out 400ms",
                  color:
                    role === "1"
                      ? "#8ed6ff"
                      : role === "5"
                      ? "#FCA311"
                      : "#8ed6ff",
                },
              }}
            >
              <MdGroups2 color="white" fontSize={24} />
              &nbsp;&nbsp;Create GD Evaluation Criteria
            </ListItem>
          </NavLink>
        ) : (
          ""
        )}
        {role === "5" ? (
          <NavLink
            to="/edit_gd_evaluation_criteria"
            style={{ textDecoration: "none", color: "white" }}
          >
            <ListItem
              sx={{
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: "#182747",
                  cursor: "pointer",
                  transition: "all ease-in-out 400ms",
                  color:
                    role === "1"
                      ? "#8ed6ff"
                      : role === "5"
                      ? "#FCA311"
                      : "#8ed6ff",
                },
              }}
            >
              <MdGroupRemove color="white" fontSize={24} />
              &nbsp;&nbsp;Edit GD Evaluation Criteria
            </ListItem>
          </NavLink>
        ) : (
          ""
        )}

        <NavLink
          to="/otp_failed_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <FaList color="white" fontSize={20} />
            &nbsp;&nbsp; OTP Failed List
          </ListItem>
        </NavLink>
        {/* <NavLink
          to="/call_type"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdAddCall color="white" fontSize={24} />
            &nbsp;&nbsp;Create Call Type
          </ListItem>
        </NavLink>
        <NavLink
          to="/calltype_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <FaBlenderPhone color="white" fontSize={22} />
            &nbsp;&nbsp; Call Type List
          </ListItem>
        </NavLink>
        <NavLink
          to="/call_category"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <BsFillPhoneVibrateFill color="white" fontSize={24} />
            &nbsp;&nbsp;Create Call Category
          </ListItem>
        </NavLink>
        <NavLink
          to="/call_category_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiPlayListFill color="white" fontSize={24} />
            &nbsp;&nbsp; Call Category List
          </ListItem>
        </NavLink> */}
        <NavLink
          to="/call_record_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdRecordVoiceOver color="white" fontSize={22} />
            &nbsp;&nbsp; Call Record List
          </ListItem>
        </NavLink>
        <NavLink
          to="/missed_call_details"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdOutlineCallMissedOutgoing color="white" fontSize={22} />
            &nbsp;&nbsp; Missed call List
          </ListItem>
        </NavLink>
        <NavLink
          to="/nostatus_record_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdRecordVoiceOver color="white" fontSize={22} />
            &nbsp;&nbsp; No Status Record List
          </ListItem>
        </NavLink>
        {/* <NavLink
          to="/call_handled_escalation_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiPlayListFill color="white" fontSize={23} />
            &nbsp;&nbsp;Missed Call Handled Escalation List
          </ListItem>
        </NavLink> */}

        <NavLink
          to="/missed_call_escalation_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <FaBlenderPhone color="white" fontSize={20} />
            &nbsp;&nbsp;Missed Call Escalation List
          </ListItem>
        </NavLink>
        {/* <NavLink
          to="/nostatus_handled_escalation_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <MdRecordVoiceOver color="white" fontSize={21} />
            &nbsp;&nbsp; No Status Handled Escalation List
          </ListItem>
        </NavLink> */}
        <NavLink
          to="/nostatus_escalation_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <TfiLayoutListThumbAlt color="white" fontSize={21} />
            &nbsp;&nbsp; No Status Escalation List
          </ListItem>
        </NavLink>
        <NavLink
          to="/lead_passing_form"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiGroup2Fill color="white" fontSize={24} />
            &nbsp;&nbsp;Lead Passing Form
          </ListItem>
        </NavLink>
        <NavLink
          to="/lead_passing_form_list"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiListCheck2 color="white" fontSize={21} />
            &nbsp;&nbsp; Lead Passing List
          </ListItem>
        </NavLink>
        <NavLink
          to="/rewards"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              color: "white",
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 250ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <HiGiftTop color="white" fontSize={24} />
            &nbsp;&nbsp;Wallet
          </ListItem>
        </NavLink>
        {/* <NavLink
          to="/appointment_report"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem
            sx={{
              fontSize: "15px",
              "&:hover": {
                backgroundColor: "#182747",
                cursor: "pointer",
                transition: "all ease-in-out 400ms",
                color:
                  role === "1"
                    ? "#8ed6ff"
                    : role === "5"
                    ? "#FCA311"
                    : "#8ed6ff",
              },
            }}
          >
            <RiListCheck2 color="white" fontSize={21} />
            &nbsp;&nbsp; Appointment Report
          </ListItem>
        </NavLink> */}
      </List>
    </div>
  );

  return (
    <div>
      <BootstrapTooltip
        title="Menu"
        placement="bottom"
        sx={{ fontWeight: "bolder" }}
      >
        <Button
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disableElevation
          onClick={toggleDrawer(true)}
          style={{ color: "#8ed6ff", fontWeight: "600" }}
        >
          <svg
            width="26"
            height="26"
            baseProfile="tiny"
            version="1.2"
            viewBox="0 0 24 24"
            id="menu"
            fill={
              role === "1"
                ? "#8ed6ff"
                : role === "5"
                ? "#FCA311"
                : role === "15"
                ? "#39A7FF"
                : "#8ed6ff"
            }
          >
            <path d="M8 3H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 3zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 6v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 11h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 8V6a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 3zM8 13H6a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 3 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 6 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 11 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 8 13zm10 0h-2a2.99 2.99 0 0 0-2.119.881A2.99 2.99 0 0 0 13 16v2c0 .825.337 1.575.881 2.119A2.99 2.99 0 0 0 16 21h2a2.99 2.99 0 0 0 2.119-.881A2.99 2.99 0 0 0 21 18v-2a2.99 2.99 0 0 0-.881-2.119A2.99 2.99 0 0 0 18 13z"></path>
          </svg>
        </Button>
      </BootstrapTooltip>
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
        id={Styles.pareeent_2}
      >
        <div className={classes.drawer} id={Styles.pareeent_1}>
          {drawer}
        </div>
      </Drawer>
    </div>
  );
}

