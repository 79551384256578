import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Box,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import AxiosInstance from "../../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

const removeDuplicatesById = array => {
  const uniqueObjects = array.reduce((acc, obj) => {
    const isDuplicate = acc.some(item => item.id === obj.id);
    if (!isDuplicate) {
      acc.push(obj);
    }
    return acc;
  }, []);
  return uniqueObjects;
};

const Createpoints = () => {
  const navigate = useNavigate();

  const [searchCategory, setSearchCategory] = useState("");
  const [categoryData, setCategoryData] = useState(null);
  const [categoryOption, setCategoryOption] = useState([]);

  const [mainData, setMainData] = useState([
    {
      script: "",
      scriptWeight: "",
      subData: [
        {
          points: "",
          pointsWeight: "",
          pointsMarks: "",
        },
      ],
      showRemoveIcon: false,
    },
  ]);

  const [mainData1, setMainData1] = useState([
    {
      main_statusWeight: "",
      subData1: [
        {
          subStatusWeight: "",
        },
      ],
    },
  ]);

  const [mainStatusOption, setMainStatusOption] = useState([]);
  const [mainStatusData, setMainStatusData] = useState(null);
  const [mainStatusWeight, setMainStatusWeight] = useState("");

  const [subStatusOption, setSubStatusOption] = useState([]);
  const [subStatusData, setSubStatusData] = useState(null);
  const [subStatusWeight, setSubStatusWeight] = useState("");

  const handleSubChange1 = (id, index, field) => {
    const { name, value } = field;
    const arr = [...mainData1];
    const target = { ...arr[id] };
    const fields = [...target["subData1"]];
    fields[index] = { ...fields[index], [name]: value };
    target["subData1"] = fields;
    arr[id] = target;
    setMainData1(arr);
  };

  const handleMainChange1 = (index, name, value) => {
    const arr = [...mainData1];
    const target = { ...arr[index] };
    target[name] = value;
    arr[index] = target;
    setMainData1(arr);
  };

  const resetMain = () => {
    setMainStatusOption([]);
    setSubStatusOption([]);
    setMainStatusData(null);
    setSubStatusData(null);
    setMainStatusWeight("");
    setSubStatusWeight("");
  };
  const setOptions = (data) => {
    if (data && data.call_status && data.call_status.length > 0) {
      setMainStatusOption(data.call_status);
      setSubStatusOption([]);
      setMainStatusWeight("");
      setSubStatusWeight("");
    } else {
      setMainStatusOption([]);
      setSubStatusOption([]);
      setMainStatusWeight("");
      setSubStatusWeight("");
    }
  };
  const addField = (id) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    target["subData"] = [
      ...target["subData"],
      {
        points: "",
        pointsWeight: "",
        pointsMarks: "",
      },
    ];
    arr[id] = target;
    setMainData(arr);
  };

  const removeSub = (id, index) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    target["subData"].splice(index, 1);
    arr[id] = target;
    setMainData(arr);
  };
  const handleSubChange = (id, index, field) => {
    const { name, value } = field;
    const arr = [...mainData];
    const target = { ...arr[id] };
    const fields = [...target["subData"]];
    fields[index] = { ...fields[index], [name]: value };
    target["subData"] = fields;
    arr[id] = target;
    setMainData(arr);
  };
  const handleMainChange = (index, name, value) => {
    const arr = [...mainData];
    const target = { ...arr[index] };
    target[name] = value;
    arr[index] = target;
    setMainData(arr);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchCategory !== "") {
        let data = await AxiosInstance.get(
          `/call_category?disable=false&search_keyword=${searchCategory}&limit=10`
        );
        let finalData = data.data.data;
        setCategoryOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/call_category?disable=false&limit=10`);
        let finalData = data.data.data;
        setCategoryOption(finalData);
      }
    };
    fetchData();
  }, [searchCategory]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryData || !mainStatusData || !subStatusData) {
      toast.error("Please select category, main status, and sub status.");
      return;
    }

    // Initialize payload object
    const payload = {
      script: mainData[0].script,
      weights: parseInt(mainData[0].scriptWeight),
      points: [],
      call_substatus: {
        category_id: {
          id: categoryData.ID,
          name: categoryData.category,
        },
        call_status: {
          id: mainStatusData.id,
          main_status: mainStatusData.main_status,
          weight: parseInt(mainStatusWeight),
          sub_status: {
            id: subStatusData.id,
            sub_status: subStatusData.sub_status,
            weight: parseInt(subStatusWeight),
            positive:subStatusData.positive
          },
        },
      },
    };

    // Adding points to payload
    mainData.forEach((item) => {
      item.subData.forEach((point) => {
        payload.points.push({
          point: point.points,
          marks: parseInt(point.pointsMarks),
          weight: parseInt(point.pointsWeight),
        });
      });
    });

    try {
      await AxiosInstance.post(`/create_points_and_script`, payload);
      toast.success("CallScriptPoints saved successfully.");
      setTimeout(() => {
        navigate("/points_and_scripts_list");
      }, 1500);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error || "Something went wrong.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const removeMain = (index) => {
    const updatedMainData = [...mainData];
    updatedMainData.splice(index, 1);
    setMainData(updatedMainData);
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border:"1px solid gray",
    borderRadius:"10px"
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  // for DropDown ScrollBar

  return (
    <Box
      style={{
        marginTop: "4.1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Create Points And Scripts</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item sm={12} style={{marginTop:"-1.5%"}}>
                <Item
                  onChange={(e) => {
                    setSearchCategory(e.target.value);
                  }}
                >
                  <Autocomplete
                    options={categoryOption}
                    value={categoryData}
                    name="categoryOption"
                    onChange={(event, newData_xy) => {
                      setCategoryData(newData_xy);
                      resetMain();
                      if (newData_xy) {
                        setOptions(newData_xy);
                      }
                    }}
                    getOptionLabel={(option) => option.category}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Category Name"
                        variant="outlined"
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
          
            </Grid>
            <Box
              style={{
                width: "98%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "8px",
                marginTop: "1%",
                marginLeft: "-1%",
              }}
            >
              {mainData1.map((val, ind) => {
                return (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{
                      border: "1px solid #06113C",
                      margin: "0 auto",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      item
                      sm={12}
                      style={{
                        color: "blue",
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                    >
                      Main Status
                    </Grid>
                    <Grid item sm={6} style={{ marginTop: "-0.5%" }}>
                      <Item>
                        <Autocomplete
                          options={mainStatusOption}
                          value={mainStatusData}
                          name="mainStatusOption"
                          onChange={(_, y) => {
                            setMainStatusData(y);
                            setSubStatusData(null);
                            setSubStatusWeight("");
                            setMainStatusWeight("");
                            setSubStatusOption([]);
                            setTimeout(() => {
                              if (y) {
                                setSubStatusOption(
                                  y.sub_status.filter(e => !e.disable) ? y.sub_status.filter(e => !e.disable) : []
                                );
                                setMainStatusWeight(y.weight);
                                setSubStatusWeight("");
                              }
                            }, 100);
                          }}
                          getOptionLabel={(option) => option.main_status}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select MainStatus"
                              variant="outlined"
                              required
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Item>
                    </Grid>
                    <Grid item sm={5} style={{ marginTop: "-0.3%" }}>
                      <TextField
                         sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                        fullWidth
                        required
                        disabled
                        name="main_statusWeight"
                        label="Main Status Weight"
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 2 }}
                        value={mainStatusWeight}
                        onChange={(e) =>
                          handleMainChange1(
                            ind,
                            "main_statusWeight",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      style={{
                        // marginTop: "0.5%", 
                        color: "blue",
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                    >
                      Sub Status
                    </Grid>
                    {val.subData1.length > 0 ? (
                      val.subData1.map((value, index) => {
                        return (
                          <Grid
                            item
                            container
                            sm={12}
                            spacing={2}
                            style={{
                              margin: "0 auto",
                              marginLeft: "1%",
                              paddingBottom: "15px",
                            }}
                          >
                            <Grid item md={5.85} xs={12} style={{ marginTop: "-1.5%" }}>
                              <Item>
                                <Autocomplete
                                  options={subStatusOption}
                                  value={subStatusData}
                                  name="subStatusOption"
                                  onChange={(e, y) => {
                                    setSubStatusData(y);
                                    setSubStatusWeight("");
                                    setTimeout(() => {
                                      if (y) {
                                        setSubStatusWeight(y.weight);
                                      }
                                    }, 100);
                                  }}
                                  getOptionLabel={(option) => option.sub_status}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select SubStatus"
                                      variant="outlined"
                                      required
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderColor: "rgba(0, 0, 0, 10)",
                                          "& fieldset": {
                                            borderColor: "rgba(0, 0, 0, 5)",
                                          },
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                            <Grid item md={5.15} xs={12} style={{ marginTop: "-1.5%" }}>
                              <TextField
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                                fullWidth
                                required
                                name="subStatusWeight"
                                label="Sub Status Weight"
                                disabled
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 2 }}
                                value={subStatusWeight}
                                onChange={(e) =>
                                  handleSubChange1(ind, index, e.target)
                                }
                              />
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          color: "red",
                          padding: "5px 10px",
                          background: "lightgrey",
                          width: "100%",
                        }}
                      >
                        No Data Found
                      </p>
                    )}
                  </Grid>
                );
              })}
            </Box>
            <Grid
              item
              container
              sm={12}
              spacing={1}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "8px",
                marginTop: "1%",
              }}
            >
              <Box
                style={{
                  width: "97%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // gap: "8px",
                }}
              >
                {mainData.map((val, ind) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      style={{
                        border: "1px solid #06113C",
                        marginTop: "5px",
                        margin: "0 auto",
                        marginLeft: "1%",
                        paddingBottom: "15px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        style={{
                          color: "#1B4242",
                          textAlign: "start",
                          fontWeight: "bold",
                        }}
                      >
                        Script
                      </Grid>
                      <Grid item sm={6} style={{ marginTop: "-0.5%" }}>
                        <TextField
                           sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                          fullWidth
                          name="script"
                          label="Script Name"
                           rows={1}
                           multiline
                          required
                          value={val.script}
                          onChange={(e) =>
                            handleMainChange(ind, "script", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item sm={5} style={{ marginTop: "-0.5%" }}>
                        <TextField
                           sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                          fullWidth
                          required
                          name="scriptWeight"
                          label="Script Weight"
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={{ minLength: 1, maxLength: 2 }}
                          value={val.scriptWeight}
                          onChange={(e) =>
                            handleMainChange(
                              ind,
                              "scriptWeight",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        style={{
                          marginTop: "-1%",
                          color: "#1B4242",
                          textAlign: "start",
                          fontWeight: "bold",
                        }}
                      >
                        Points
                      </Grid>
                      <div
                        style={{
                          height: "30vh",
                          overflowY: "auto",
                          width: "100%",
                        }}
                      >
                      {val.subData.map((value, index) => {
                        return (
                          <Grid
                            item
                            container
                            sm={12}
                            spacing={2}
                            style={{
                              margin: "0 auto",
                              marginLeft: "1%",
                              paddingBottom: "15px",
                            }}
                          >
                            <Grid item sm={5} style={{ marginTop: "-0.5%" }}>
                              <TextField
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                                fullWidth
                                required
                                name="points"
                                label="Points Name"                             
                                value={value.points}
                                onChange={(e) =>
                                  handleSubChange(ind, index, e.target)
                                }
                              />
                            </Grid>
                            <Grid item sm={2.5} style={{ marginTop: "-0.5%" }}>
                              <TextField                      
                                fullWidth
                                required
                                name="pointsWeight"
                                label="Points Weight"
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 2 }}
                                value={value.pointsWeight}
                                onChange={(e) =>
                                  handleSubChange(ind, index, e.target)
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item sm={2.5} style={{ marginTop: "-0.5%" }}>
                              <TextField
                                fullWidth
                                required
                                name="pointsMarks"
                                label="Points Marks"
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 2 }}
                                value={value.pointsMarks}
                                onChange={(e) =>
                                  handleSubChange(ind, index, e.target)
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            {index !== 0 && (
                              <Grid item sm={2} style={{ marginTop: "-0.5%" }}>
                                <Button
                                  style={{
                                    border: "none",
                                    background: "crimson",
                                    color: "#fff",
                                    borderRadius: "5px",
                                    marginTop: "10%",
                                  }}
                                  onClick={() => removeSub(ind, index)}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                      <Grid item sm={2} style={{ marginTop: "-2%" }}>
                        <Button
                          style={{
                            border: "none",
                            background: "#87A922",
                            color: "#fff",
                            borderRadius: "5px",
                            marginTop: "10%",
                          }}
                          onClick={() => addField(ind)}
                        >
                          Add
                        </Button>
                      </Grid>
                      {val.showRemoveIcon && (
                        <Grid
                          style={{ marginLeft: "99.8%" }}
                          onClick={() => removeMain(ind)}
                        >
                          <CancelIcon
                            color="error"
                            style={{ fontSize: "35px" }}
                          />
                        </Grid>
                      )}
                      </div>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>

            <Grid item container sm={12} spacing={4}>
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default Createpoints;
