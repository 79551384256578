import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Grid,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Chip,
  Checkbox,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import AxiosInstance from "../apis/AxiosInstance";
import Style from "../superAdmin/lists.module.css";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HistoryForCallRecordList from "../Bd_Module/juniorVerifer/HistoryForCallRecordList";
import Cookies from "js-cookie";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#40679E",
    color: "#fff",
    padding: "4px",
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: "1px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function CallNotHandledEscalationList() {
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const current_role = Cookies.get("current_role");
  const selectedRole = current_role ? JSON.parse(current_role) : null;

  const [escalation_list, setEscalation_list] = useState([]);
  let [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [totalEscalation, setTotalEscalation] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [roleOption, setRoleOption] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [searchRoleName, setSearcRoleName] = useState("");

  // const [colgStateOptions, setColgStateOptions] = useState([]);
  // const [selectedColgState, setSelectedColgState] = useState(null);
  // const [searchTermState, setSearchTermState] = useState("");

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);
  const [inputValue1, setInputValue1] = useState([]);

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  const [searchDisable, setSearchDisable] = useState(false);

  const [duration, setDuration] = useState("");

  const [load, setLoad] = useState(false);

  const [allStates, setAllStates] = useState(false);

  const [calledBack, setCalledBack] = useState(false);
  const [notCallBackNotHandled, setNotCallBackNotHandled] = useState(false);

  const handleCheckboxChange = (event) => {
    setAllStates(event.target.checked);
  };

  const handleCalledBackChange = (event) => {
    setCalledBack(event.target.checked);
  };

  const handleNotCalledBackNotHandledChange = (event) => {
    setNotCallBackNotHandled(event.target.checked);
  };

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (searchTermState !== "") {
  //       //college state api
  //       let colgStateData = await AxiosInstance.get(
  //         `/statemini?search_keyword=${searchTermState}&limit=10&country_id=${countryMiniId}`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setColgStateOptions(finalcolgStateData);
  //     } else {
  //       //college state api
  //       let colgStateData = await AxiosInstance.get(
  //         `/statemini?limit=10&country_id=${countryMiniId}`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setColgStateOptions(finalcolgStateData);
  //     }
  //   };
  //   if (countryMiniId !== 0) {
  //     fetchData();
  //   }
  // }, [searchTermState, countryMiniId]);

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalData = response.data.data;
      setStateOptions(finalData);
      setDefaultCourseList(finalData);
    };
    fetchData();
  }, [countryMiniId]);
  const fetchOptions1 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/statemini?search_keyword=${searchTerm_1}&limit=10&country_id=${countryMiniId}`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setStateOptions(data);
      } else {
        setStateOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setStateOptions(defaultCourseList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedStateOptions(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (searchRoleName !== "") {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=1&role_ids=5&search_keyword=${searchRoleName}&limit=10`
          );
        } else {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=1&role_ids=5&limit=10`
          );
        }
        const finalStateData_X = response.data.data || [];
        setRoleOption(finalStateData_X);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setRoleOption([]);
      }
    };

    fetchData();
  }, [searchRoleName]);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/missed_call_escalation?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setEscalation_list(finalData);
        setTotalEscalation(data.data.filtered_count);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/missed_call_escalation?page=${newPage}`;
    setLoad(true);
    try {
      if (searchFilter) {
        endpoint += `&number=${searchFilter}`;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (duration) {
        endpoint += `&call_handled=${duration}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (allStates) {
        endpoint += `&all_states=${allStates}`;
      }
      if (calledBack) {
        endpoint += `&call_back=${calledBack}`;
      }
      if (notCallBackNotHandled) {
        endpoint += `&not_call_back_not_handled=${notCallBackNotHandled}`;
      }
      if (roleData !== null) {
        endpoint += `&author_id=${roleData.id}`;
      }
      if (selectedStateOptions.length > 0) {
        const stateIds = selectedStateOptions
          .map((option) => `state_id=${option.id}`)
          .join("&");
        endpoint += `&${stateIds}`;
      }
      // if (selectedColgState !== null) {
      //   endpoint += `&state_id=${selectedColgState.id}`;
      // }
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setEscalation_list(finalData);
      setTotalEscalation(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/missed_call_escalation?page=${1}`;
    setIsSearchDisabled(true);
    try {
      if (
        !searchFilter &&
        !fromDate &&
        !toDate &&
        !unitNameData &&
        !roleData &&
        !allStates &&
        !calledBack &&
        !notCallBackNotHandled &&
        (!selectedStateOptions || selectedStateOptions.length === 0) &&
        !duration
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }

      // if (selectedColgState && selectedColgState.id === 0) {
      //   toast.error("Select Proper State.");
      //   return;
      // }

      if (selectedStateOptions && selectedStateOptions.id === 0) {
        toast.error("Select Proper State.");
        return;
      }

      if (searchFilter) {
        endpoint += `&number=${searchFilter}`;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (duration) {
        endpoint += `&call_handled=${duration}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (allStates) {
        endpoint += `&all_states=${allStates}`;
      }
      if (calledBack) {
        endpoint += `&call_back=${calledBack}`;
      }
      if (notCallBackNotHandled) {
        endpoint += `&not_call_back_not_handled=${notCallBackNotHandled}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (roleData !== null) {
        endpoint += `&author_id=${roleData.id}`;
      }
      if (selectedStateOptions.length > 0) {
        const stateIds = selectedStateOptions
          .map((option) => `state_id=${option.id}`)
          .join("&");
        endpoint += `&${stateIds}`;
      }
      // if (selectedStateOptions !== null) {
      //   endpoint += `&state_id=${selectedStateOptions.id}`;
      // }
      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      setEscalation_list(finalData);
      setTotalEscalation(data.filtered_count);
      setPage(1);
      setSearchDisable(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchDisable(false);
    }
  };

  const totalPages = Math.ceil(totalEscalation / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  let handleClearFilterData = async () => {
    setSearchFilter("");
    setFromDate("");
    setToDate("");
    setDuration("");
    setAllStates(false);
    setCalledBack(false);
    setNotCallBackNotHandled(false);
    setUnitNameData(null);
    setRoleData(null);
    setIsSearchDisabled(false);
    setSelectedStateOptions([]);
    let endpoint = `/missed_call_escalation?page=${page}`;
    try {
      const response = await AxiosInstance.get(
        `/user_mini?role_ids=1&role_ids=5&limit=10`
      );
      const initialRoleOption = response.data.data || [];
      setRoleOption(initialRoleOption);
    } catch (error) {
      console.error("Error fetching initial role data: ", error);
      setRoleOption([]);
    }
    let fetchData = async (e) => {
      //college state api
      // let colgStateData = await AxiosInstance.get(
      //   `/statemini?limit=10&country_id=${countryMiniId}`
      // );
      // let finalcolgStateData = colgStateData.data.data;
      // setColgStateOptions(finalcolgStateData);
      // setSelectedColgState(null);
      let response = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalData = response.data.data;
      setStateOptions(finalData);
      setDefaultCourseList(finalData);
    };

    if (countryMiniId !== 0) {
      fetchData();
    }
    try {
      let data = await AxiosInstance.get(endpoint);
      let finalData = data.data.data;
      setEscalation_list(finalData);
      setTotalEscalation(data.data.filtered_count);
      setDefaultState({
        escalation_list: finalData,
        totalEscalation: data.data.filtered_count,
      });
      if (searchFilter) setPage(1);
      setSearchDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "4.5%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>
        Missed Call Escalation List
        <span
          style={{
            color: "#06113C",
            fontSize: "20px",
            marginLeft: "25%",
            fontWeight: "bold",
          }}
        >
          Total Count: {totalEscalation}
        </span>
        {/* <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
           
            marginLeft: "25%",
            fontWeight: "bold",
            cursor: "pointer", // Adds a pointer cursor on hover
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
             <ArrowDropUpIcon style={{fontSize:"30px"}} />
            ) : (
              <ArrowDropDownIcon style={{fontSize:"30px"}}  />
            )}
          </Tooltip>
        </span> */}
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            marginLeft: "25%",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </h1>

      <Paper
        style={{
          width: "99%",
          margin: "0 auto",
          border: "1px solid #111",
          marginTop: filterVisible ? "-1%" : "-1%",
        }}
      >
        {filterVisible && (
          <Grid container gap={1} style={{ marginTop: "1%" }}>
            <Grid md={1} style={{ paddingLeft: "5px" }}>
              <TextField
                placeholder="Number"
                label="Number"
                size="small"
                fullWidth
                value={searchFilter}
                name="searchFilter"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 10, maxLength: 10 }}
                onChange={(e) => {
                  setSearchFilter(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchDisable}
              />
            </Grid>
            <Grid md={1.25}>
              <TextField
                type="date"
                fullWidth
                id="From Date"
                size="small"
                label="From Date "
                name="From Date"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchDisable}
              />
            </Grid>
            <Grid md={1.25}>
              <TextField
                type="date"
                fullWidth
                id="toDate"
                size="small"
                label="To Date "
                name="toDate"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchDisable}
              />
            </Grid>
            {(selectedRole === 5 ||
              selectedRole === 17 ||
              selectedRole === 18) && (
              <Grid
                md={1}
                onChange={(e) => setSearchUnitName(e.target.value)}
              >
                <Autocomplete
                  options={unitNameOption}
                  // disabled={searchDisable}
                  value={unitNameData}
                  size="small"
                  name="unitNameOption"
                  onChange={(event, newData_xy) => {
                    setUnitNameData(newData_xy);
                    setIsSearchDisabled(false);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unit"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {(selectedRole === 5 ||
              selectedRole === 17 ||
              selectedRole === 18) && (
              <Grid md={1.1} onChange={(e) => setSearcRoleName(e.target.value)}>
                <Autocomplete
                  options={roleOption}
                  // disabled={searchDisable}
                  value={roleData}
                  size="small"
                  name="roleOption"
                  onChange={(event, newData_xy) => {
                    setRoleData(newData_xy);
                    setIsSearchDisabled(false);
                  }}
                  getOptionLabel={(option) => option.full_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Author"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {(selectedRole === 5 ||
              selectedRole === 17 ||
              selectedRole === 18) && (
              <Grid md={1.35}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  required
                  // disabled={searchDisable}
                  size="small"
                  value={selectedStateOptions}
                  onChange={handleOptionSelect1}
                  onInputChange={handleInputChange1}
                  options={stateOptions}
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        key={option.name + (index + 1)}
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid md={1.15} style={{ marginTop: "-0.8%" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="duration"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                  setIsSearchDisabled(false);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio style={{ transform: "scale(0.75)" }} />}
                  label={
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      Handled
                    </Typography>
                  }
                  name="duration"
                  // disabled={searchDisable}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio style={{ transform: "scale(0.75)" }} />}
                  label={
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      Not Handled
                    </Typography>
                  }
                  name="duration"
                  // disabled={searchDisable}
                  style={{ marginTop: "-5%", fontSize: "10px" }}
                />
              </RadioGroup>
            </Grid>
            {/* {(selectedRole === 5 ||
              selectedRole === 17 ||
              selectedRole === 18) && (
              <Grid md={1.15} style={{ marginTop: "-1%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allStates}
                      onChange={handleCheckboxChange}
                      name="allStates"
                      color="primary"
                      // disabled={searchDisable}
                    />
                  }
                  label={
                    <Typography
                      style={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                      All States
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ marginTop: "-1%" }}
                  control={
                    <Checkbox
                      checked={calledBack}
                      onChange={handleCalledBackChange}
                      name="calledBack"
                      color="primary"
                      // disabled={searchDisable}
                    />
                  }
                  label={
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      Called back
                    </Typography>
                  }
                />
              </Grid>
            )} */}
            <Grid md={2.05} style={{ marginTop: "-1%" }}>
              {(selectedRole === 5 ||
                selectedRole === 17 ||
                selectedRole === 18) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allStates}
                      onChange={handleCheckboxChange}
                      name="allStates"
                      color="primary"
                      // disabled={searchDisable}
                    />
                  }
                  label={
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      All States
                    </Typography>
                  }
                />
              )}
              <FormControlLabel
                // style={{ marginTop: "-1%" }}
                control={
                  <Checkbox
                    checked={calledBack}
                    onChange={handleCalledBackChange}
                    name="calledBack"
                    color="primary"
                    // disabled={searchDisable}
                  />
                }
                label={
                  <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Called back
                  </Typography>
                }
              />
              <FormControlLabel
                // style={{ marginTop: "-1%" }}
                control={
                  <Checkbox
                    checked={notCallBackNotHandled}
                    onChange={handleNotCalledBackNotHandledChange}
                    name="notCallBackNotHandled"
                    color="primary"
                    // disabled={searchDisable}
                  />
                }
                label={
                  <Typography style={{ fontSize: "13px", fontWeight: "bold" }}>
                    Not Called Back Not Handled
                  </Typography>
                }
              />
            </Grid>

            <Grid md={0.5}>
              <Button
                onClick={handleSearch}
                variant="contained"
                size="small"
                fullWidth
                // disabled={isSearchDisabled}
                // style={{
                //   backgroundColor: isSearchDisabled ? "gray" : "#003285",
                //   color: "#fff",
                // }}
                style={{
                  backgroundColor: "#003285",
                  color: "#fff",
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid md={0.5}>
              <Button
                onClick={handleClearFilterData}
                variant="contained"
                color="warning"
                fullWidth
                size="small"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
        <TableContainer
          component={Paper}
          style={{
            maxHeight: window.innerWidth > 1800 ? 680 : 470,
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "70px",
                  }}
                >
                  Sl No
                </StyledTableCell>
                {(selectedRole === 5 ||
                  selectedRole === 17 ||
                  selectedRole === 18) && (
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                      width: "160px",
                    }}
                  >
                    Author Name
                  </StyledTableCell>
                )}
                {(selectedRole === 5 ||
                  selectedRole === 17 ||
                  selectedRole === 18) && (
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                      width: "150px",
                    }}
                  >
                    Unit Name
                  </StyledTableCell>
                )}
                {(selectedRole === 5 ||
                  selectedRole === 17 ||
                  selectedRole === 18) && (
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                      width: "180px",
                    }}
                  >
                    State
                  </StyledTableCell>
                )}
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "150px",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "140px",
                  }}
                >
                  Called Back
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "180px",
                  }}
                >
                  Missed Call date
                </StyledTableCell>
                {(selectedRole === 5 ||
                  selectedRole === 17 ||
                  selectedRole === 18) && (
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                      width: "190px",
                    }}
                  >
                    Escalation Role Name
                  </StyledTableCell>
                )}
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "180px",
                  }}
                >
                  Escalation Time
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "230px",
                  }}
                >
                  User Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={6} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : escalation_list.length > 0 ? (
                escalation_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    {(selectedRole === 5 ||
                      selectedRole === 17 ||
                      selectedRole === 18) && (
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p style={{ textTransform: "capitalize" }}>
                          {" "}
                          {val &&
                          val.call_record &&
                          val.call_record.author &&
                          val.call_record.author.name
                            ? val.call_record.author.name
                            : "NA"}
                        </p>
                      </StyledTableCell>
                    )}
                    {(selectedRole === 5 ||
                      selectedRole === 17 ||
                      selectedRole === 18) && (
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p style={{ textTransform: "capitalize" }}>
                          {val &&
                          val.call_record &&
                          val.call_record.author &&
                          val.call_record.author.unit &&
                          Object.keys(val.call_record.author.unit).length > 0
                            ? Object.values(val.call_record.author.unit).map(
                                (unit) => {
                                  return (
                                    <Box style={{ height: "22px" }}>
                                      {unit.name}
                                    </Box>
                                  );
                                }
                              )
                            : "NA"}
                        </p>
                      </StyledTableCell>
                    )}
                    {(selectedRole === 5 ||
                      selectedRole === 17 ||
                      selectedRole === 18) && (
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p style={{ textTransform: "capitalize" }}>
                          {val.all_states ? (
                            <p style={{ color: "green", fontWeight: "bold" }}>
                              All states
                            </p>
                          ) : val.state && Object.keys(val.state).length > 0 ? (
                            <p>
                              {Object.keys(val.state)
                                .map((key) => val.state[key].name)
                                .find((name) => name) || "NA"}
                            </p>
                          ) : (
                            <p>NA</p>
                          )}
                        </p>
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          <a
                            href={`tel:${val.call_record.number}`}
                            style={{
                              color: "#125B9A",
                              // textDecoration: "none",
                            }}
                          >
                            {val.call_record.number}
                          </a>
                        </p>
                        &nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <HistoryForCallRecordList
                            call_record_history={val.call_record.number}
                            call_to_date={val.call_record.id}
                          ></HistoryForCallRecordList>
                        </p>
                      </p>
                      <p>
                        {val.handled === true ? (
                          <span
                            style={{
                              color: "#fff",
                              marginLeft: "2%",
                              border: "none",
                              background: "#ED6C02",
                              borderRadius: "10px",
                              padding: "4px 10px",
                              fontSize: "10px",
                              letterSpacing: "1px",
                            }}
                          >
                            Handled
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {Object.keys(val.missed_call_try).length === 0 ? (
                        "----"
                      ) : (
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          Called Back
                        </p>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_record.missed_call_date === null ||
                      val.call_record.missed_call_date === ""
                        ? "N/A"
                        : // : moment(val.call_record.missed_call_date)
                          moment
                            .utc(toIsoFormat(val.call_record.missed_call_date))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>

                    {(selectedRole === 5 ||
                      selectedRole === 17 ||
                      selectedRole === 18) && (
                      <StyledTableCell
                        align="left"
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {Object.keys(val.es_level).length < 1 ? (
                          <Box style={{ color: "red" }}>N/A</Box>
                        ) : (
                          Object.values(val.es_level).map((ea) => {
                            return (
                              <Box style={{ height: "22px" }}>
                                {ea?.level.role.name}
                              </Box>
                            );
                          })
                        )}
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {Object.keys(val.es_level).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(val.es_level).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {
                                // moment(ea?.escalation_time)
                                moment
                                  .utc(toIsoFormat(ea.escalation_time))
                                  .local()
                                  .format("DD-MM-yyyy hh:mm A")
                              }
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* <p style={{ textTransform: "capitalize", color: "red" }}>
                        {val.call_record &&
                        val.call_record.user_id &&
                        Object.keys(val.call_record.user_id).length > 0
                          ? Object.values(val.call_record.user_id).map((user) =>
                              user.name && user.role_id
                                ? `${Object.values(user.role_id).map(
                                    (role) => role.name
                                  )}`
                                : "Unknown"
                            )
                          : ["Unknown"]}
                      </p> */}

                      <p style={{ textTransform: "capitalize", color: "red" }}>
                        {val.call_record &&
                        val.call_record.user_id &&
                        Object.keys(val.call_record.user_id).length > 0
                          ? Object.values(val.call_record.user_id).map((user) =>
                              user.name && user.role_id !== undefined
                                ? Object.keys(user.role_id).length > 0
                                  ? Object.values(user.role_id).map(
                                      (role) => role.name
                                    )
                                  : user.from_spoc === true
                                  ? "Spoc"
                                  : "Unknown"
                                : user.from_spoc === true
                                ? "Spoc"
                                : "Unknown"
                            )
                          : ["Unknown"]}
                      </p>

                      {/* </p> */}

                      <p style={{ textTransform: "capitalize" }}>
                        {val.call_record &&
                        val.call_record.user_id &&
                        Object.keys(val.call_record.user_id).length > 0
                          ? Object.values(val.call_record.user_id).map(
                              (user) => user.name || "Unknown"
                            )
                          : ["Unknown"]}
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={6}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1B4242", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
