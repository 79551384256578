import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  Container,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDownloadDone } from "react-icons/md";

//====================================

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDE6ED",
    color: "#111",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function InterviewCreation() {
  const Navigate = useNavigate();
  const [start_date_time, setStart_date_time] = useState("");
  const [interview_duration_per_student, setInterview_duration_per_student] =
    useState("");
  const [interval_per_interview, setInterval_per_interview] = useState("");
  const [is_shortlisted_candidates, setIs_shortlisted_candidates] =
    useState(false);
  const [assign_interviewer, setAssign_interviewer] = useState(false);
  const [assign_date, setAssign_date] = useState(false);
  const [is_Quiz, setIs_Quiz] = useState(false);
  const [colgOptions, setColgOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColgOptions, setSelectedColgOptions] = useState([]);
  // all appointments
  const [selectedCollege_nameDate, setSelectedCollege_nameDate] =
    useState(null);

  const [addInterviewerOptions, setInterviewerOptions] = useState([]);
  const [interviewer, setInerviewer] = useState([]);
  const [searchInterviewer, setSearchInterviewer] = useState("");

  //college

  const [selectedCollege, setSelectedCollege] = useState([]);
  const [collegeTerm, setCollegeTerm] = useState("");
  const [collegeOption, setCollegeOption] = useState("");

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState("");

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  //college appointment rounds
  const [gdCriteria, setGdCriteria] = useState([]);
  const [criteria, setCriteria] = useState(null);
  useEffect(() => {
    let fetchData = async () => {
      try {
        let gdData = await AxiosInstance.get(
          `/college_appointment_rounds?id=2`
        );
        let finalGdData = gdData.data.data;
        setGdCriteria(finalGdData);
        const criteriaObj = (e) => {
          const obj = {};
          e.forEach((el, ex) => {
            obj[ex] = {
              text: el[0],
              marks: el[1].marks,
              checked: false,
              disabled: true,
            };
          });
          return obj;
        };
        setCriteria(
          finalGdData &&
            finalGdData.length > 0 &&
            finalGdData[0].criteria &&
            Object.keys(finalGdData[0].criteria).length > 0
            ? criteriaObj(Object.entries(finalGdData[0].criteria))
            : null
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleShortListCandidates = (event) => {
    setIs_shortlisted_candidates(event.target.checked);
  };

  const handleAssignInterviewer = (event) => {
    setAssign_interviewer(event.target.checked);
  };
  const handleAssignDate = (event) => {
    setAssign_date(event.target.checked);
  };

  const handleQuiz = (event) => {
    setIs_Quiz(event.target.checked);
  };

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (searchTerm && searchTerm.length > 2) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?search_keyword=${searchTerm}&limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        if (finalAppointments) {
          setColgOptions(finalAppointments);
        }
      } else if (searchTerm === "" || searchTerm === null) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      } else {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      }
    };
    fetchData1();
  }, [searchTerm, selectedColgOptions]);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchInterviewer !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&search_keyword=${searchInterviewer}&is_not_blocked=${true}&limit=10`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setInterviewerOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(`/user_mini?role_ids=3&is_not_blocked=${true}&limit=10`);
        let finalAddFaculty = addFacultyData.data.data;
        setInterviewerOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchInterviewer]);

  useEffect(() => {
    let fetchData2 = async () => {
      if (selectedColgOptions && selectedColgOptions.length > 0) {
        // college Degree API without search term
        let degreeData = await AxiosInstance.get(
          `/appointment_mini?id=${selectedColgOptions.map(
            (val) => val.id
          )}&limit=10`
        );
        let finaldegreeData = degreeData.data.data;
        if (
          finaldegreeData &&
          finaldegreeData.length > 0 &&
          finaldegreeData[0].colleges &&
          Object.keys(finaldegreeData[0].colleges).length > 0
        ) {
          setCollegeOption(
            Object.entries(finaldegreeData[0].colleges).map((e) => ({
              id: Number(e[0]),
              name: e[1].name,
            }))
          );
        }
      }
    };

    fetchData2();
  }, [selectedColgOptions]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));
    const isCriteriaSelected = Object.values(criteria).some(
      (item) => item.checked
    );
    if (!isCriteriaSelected) {
      toast.warning("Criteria is required");
      return;
    }
    try {
      const interviewDateTime = moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
      let appointmentData = selectedCollege_nameDate.map(({ id }) => id);
      let collegeData = selectedCollege.map(({ id }) => id);

      const payload = {
        interviewer: interviewer.map((value) => value.id),
        start_date_time: assign_date === true ? interviewDateTime : "",
        assign_date: assign_date,
        appointment_id: appointmentData,
        college_id: collegeData,
        is_shortlisted_candidates: is_shortlisted_candidates,
        assign_interviewer: assign_interviewer,
        interview_duration_per_student:
          !assign_date || examTypeList == 1
            ? 0
            : Number.parseInt(interview_duration_per_student),
        interval_per_interview:
          !assign_date || examTypeList == 1
            ? 0
            : Number.parseInt(interval_per_interview),
        is_offline: examTypeList == 1 ? true : false,
        is_quiz: is_Quiz,
        criteria: {},
      };
      // Collect criteria data
      Object.keys(criteria).forEach((key) => {
        if (criteria[key].checked) {
          payload.criteria[criteria[key].text.toLowerCase()] = {
            marks: parseInt(criteria[key].marks, 10),
          };
        }
      });

      await AxiosInstance.post("/interview", payload, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Interview Created Successfully");
            setTimeout(() => {
              Navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "95%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Interview Creation
          </Box>
        </Box>
        <Container
          component="main"
          style={{
            width: "70%",
            borderRadius: "10px",
          }}
        >
          <Paper
            variant="outlined"
            style={{ border: "1px solid #9BA4B5", width: "100%" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "1%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Appointments :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      >
                        <Autocomplete
                          multiple
                          required
                          options={colgOptions?colgOptions:[]}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Appointments"
                              placeholder="Select Multiple Appointments"
                              required={selectedColgOptions.length===0}
                            />
                          )}
                          value={selectedColgOptions}
                          onChange={(e, value, ex, ey) => {
                            if (ex === "selectOption") {
                              if (selectedColgOptions.length === 0) {
                                setSelectedColgOptions(value);
                                setSelectedCollege_nameDate(value);
                              } else if (
                                selectedColgOptions.filter(
                                  (e) => e.id === ey.option.id
                                ).length === 0
                              ) {
                                setSelectedColgOptions(value);
                                setSelectedCollege_nameDate(value);
                              } else {
                                setSelectedColgOptions(selectedColgOptions);
                              }
                            } else {
                              setSelectedColgOptions(value);
                              setSelectedCollege_nameDate(value);
                            }
                          }}
                        />
                      </Item>
                    </FormControl>
                  </Grid>
                  {selectedColgOptions.length > 0 && (
                    <>
                      <Grid
                        item
                        xs={3}
                        sx={{ alignItems: "center", display: "flex" }}
                      >
                        Colleges :
                      </Grid>
                      <Grid Grid item xs={9}>
                        <FormControl style={{ width: "81%" }}>
                          <Item
                            onChange={(e) => {
                              setCollegeTerm(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              value={selectedCollege}
                              onChange={(event, newValue) => {
                                setSelectedCollege([...newValue]);
                              }}
                              options={collegeOption || []}
                              getOptionLabel={(option) => option.name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={
                                    collegeOption && collegeOption.length === 0
                                      ? "No options available"
                                      : "Select College"
                                  }
                                  color="warning"
                                />
                              )}
                            />
                          </Item>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Mode Of Interview :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      {/* <FormControl fullWidth> */}
                      <InputLabel id="demo-simple-select-label">
                        Mode Of Interview
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        value={examTypeList}
                        label="Mode Of Interviewer"
                        onChange={(e) => {
                          setExamTypeList(e.target.value);
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {/* </FormControl> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox
                      name="is_Quiz"
                      checked={is_Quiz}
                      onChange={handleQuiz}
                      id="is_Quiz"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label htmlFor="quiz" style={{ fontSize: "16px" }}>
                      Quiz
                    </label>
                  </Grid>
                  <Grid item xs={3}>
                    <Checkbox
                      name="is_shortlisted_candidates"
                      disabled={is_Quiz}
                      checked={is_shortlisted_candidates}
                      onChange={handleShortListCandidates}
                      id="is_shortlisted_candidates"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label
                      htmlFor="shortListCandidates"
                      style={{ fontSize: "16px" }}
                    >
                      ShortListed Candidates
                    </label>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Checkbox
                      name="assign_date"
                      checked={assign_date}
                      onChange={handleAssignDate}
                      id="assign_date"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label
                      htmlFor="assignInterviewer"
                      style={{ fontSize: "16px" }}
                    >
                      Assign Date
                    </label>
                  </Grid>
                  <Grid item xs={3.7}>
                    <Checkbox
                      name="assign_interviewer"
                      checked={assign_interviewer}
                      onChange={handleAssignInterviewer}
                      id="assign_interviewer"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label
                      htmlFor="assignInterviewer"
                      style={{ fontSize: "16px" }}
                    >
                      Assign Interviewer
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Start DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="start_date_time"
                        fullWidth
                        required
                        type="datetime-local"
                        id="start_date_time"
                        value={start_date_time}
                        onChange={(e) => setStart_date_time(e.target.value)}
                        disabled={!assign_date}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Interview Duration Per Student :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        disabled={!assign_date || examTypeList == 1}
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="interview_duration_per_student"
                        value={interview_duration_per_student}
                        require
                        onChange={(e) =>
                          setInterview_duration_per_student(e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Interval Per Interview :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        disabled={!assign_date || examTypeList == 1}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="interval_per_interview"
                        value={interval_per_interview}
                        require
                        onChange={(e) =>
                          setInterval_per_interview(e.target.value)
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Interviewer:
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchInterviewer(e.target.value);
                        }}
                      >
                        <Autocomplete
                          multiple
                          options={addInterviewerOptions?addInterviewerOptions:[]}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.full_name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.full_name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Interviewer"
                              placeholder="Select Multiple Interviewer"
                              required={interviewer.length === 0}
                            />
                          )}
                          value={interviewer}
                          onChange={(e, value) => {
                            setInerviewer(value);
                          }}
                          disabled={!assign_interviewer}
                        />
                      </Item>
                    </FormControl>
                  </Grid>
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: "90%",
                      overflowY: "auto",
                      whiteSpace: "nowrap",
                      marginTop: "1%",
                      marginLeft: "6%",
                    }}
                  >
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Select
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Criteria
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Marks
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Edit
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {criteria ? (
                          Object.entries(criteria).map((value, ind) => (
                            <StyledTableRow>
                              <StyledTableCell>
                                <Checkbox
                                  disableRipple
                                  name={criteria[ind].text + ind}
                                  checked={criteria[ind].checked}
                                  value={ind}
                                  onChange={(event) =>
                                    setCriteria({
                                      ...criteria,
                                      [ind]: {
                                        ...criteria[ind],
                                        checked: event.target.checked,
                                      },
                                    })
                                  }
                                  style={{
                                    padding: "3px 10px 3px 20px",
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                <TextField
                                  name="criteriaName"
                                  fullWidth
                                  id="criteriaName"
                                  required
                                  label="Criteria Name"
                                  value={criteria[ind].text}
                                  disabled={criteria[ind].disabled}
                                  onChange={(e) => {
                                    setCriteria({
                                      ...criteria,
                                      [ind]: {
                                        ...criteria[ind],
                                        text: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </StyledTableCell>

                              <StyledTableCell
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                <TextField
                                  name="criteriaMarks"
                                  type="tel"
                                  required
                                  fullWidth
                                  id="criteriaMarks"
                                  label="Marks"
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 3 }}
                                  value={criteria[ind].marks}
                                  disabled={criteria[ind].disabled}
                                  onChange={(e) => {
                                    setCriteria({
                                      ...criteria,
                                      [ind]: {
                                        ...criteria[ind],
                                        marks: e.target.value,
                                      },
                                    });
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                {criteria[ind].disabled ? (
                                  <FaEdit
                                    fontSize={18}
                                    color="#537FE7"
                                    style={{
                                      cursor: "pointer",
                                      width: "50px",
                                    }}
                                    onClick={() => {
                                      setCriteria({
                                        ...criteria,
                                        [ind]: {
                                          ...criteria[ind],
                                          disabled: !criteria[ind].disabled,
                                        },
                                      });
                                    }}
                                  />
                                ) : (
                                  <MdOutlineDownloadDone
                                    color="green"
                                    fontSize={18}
                                    style={{
                                      cursor: "pointer",
                                      width: "50px",
                                    }}
                                    onClick={() => {
                                      setCriteria({
                                        ...criteria,
                                        [ind]: {
                                          ...criteria[ind],
                                          disabled: !criteria[ind].disabled,
                                        },
                                      });
                                    }}
                                  />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={4} align="center">
                              No Data Found
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "20%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </article>
    </section>
  );
}
