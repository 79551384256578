import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Button,
  Box,
  Drawer,
  TextField,
  IconButton,
  Grid,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import MergedNumberModal from "./MergedNumberModal";
import moment from "moment";
import { FaEdit, FaTimes } from "react-icons/fa";

import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import decodeSpl from "../utils/decodeSpl";
import encodeSpl from "../utils/encodeSpl";
import AxiosInstance from "../apis/AxiosInstance";
import { useRef } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "normal",
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function CallRecordList() {
  const [callRecord_list, setCallRecord_list] = useState([]);
  // const [page, setPage] = useState(1);
  const [totalCallRecord, setTotalCallRecord] = useState(0);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [resetData, setResetData] = useState(false);
  const [searchButton, setSearchButton] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [categoryOption, setCategoryOption] = useState([]);
  const [categorydata, setCategoryData] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");

  const [callTypeOption, setCallTypeOption] = useState([]);
  const [callTypeData, setCallTypeData] = useState(null);
  const [searchCallType, setSearchCallType] = useState("");

  const [callDate, setCallDate] = useState("");

  const [roleOption, setRoleOption] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [searchRoleName, setSearcRoleName] = useState("");

  const [searchParam, setSearchParam] = useSearchParams("");

  const [filteredData, setFilteredData] = useState({
    filtered: false,
    term: "",
  });
  const [defaultState, setDefaultState] = useState(null);
  const [load, setLoad] = useState(false);

  const [currentAudio, setCurrentAudio] = useState(null);

  useEffect(() => {
    fetchCallRecord();
  }, []);
  let pageParam = searchParam.get("page");
  const [page, setPage] = useState(pageParam ? Number(pageParam) : 1);
  let fetchCallRecord = async (filter, param) => {
    const pageParamX = searchParam.get("page");
    const filterParamX = searchParam.get("filter");
    setLoad(true);
    if (param) {
      pageParam = "";
      setPage(1);
      setSearchParam("");
    }
    try {
      let callRecordData = await AxiosInstance.get(
        filter
          ? `/call_record?limit=10${filter}`
          : param
          ? `/call_record?limit=10`
          : `/call_record?page=${pageParamX || 1}${
              filterParamX ? decodeSpl(filterParamX) : ""
            }`
      );
      setTotalCallRecord(callRecordData.data.total_length);
      let finalGdData = callRecordData.data.data || [];
      setCallRecord_list(finalGdData);
      if (filter) {
        // setIsSidebarOpen(false);
        setIsSidebarOpen(true);
        setFilteredData({ filtered: true, term: filter });
        setResetData(true);
        setSearchButton(false);
        setPage(1);
        setSearchParam(
          `?page=${1}${
            filter
              ? `&filter=${encodeSpl(filter)}`
              : filteredData.filtered
              ? `&filter=${encodeSpl(filteredData.term)}`
              : ""
          }`
        );
      } else {
        setFilteredData({
          filtered: filterParamX ? true : false,
          term: filterParamX ? decodeSpl(filterParamX) : "",
        });
        if (filterParamX) {
          setResetData(true);
          setSearchButton(false);
        } else {
          setResetData(false);
          setSearchButton(true);
        }
        setPage(pageParamX || 1);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
    }
  };

  const handleChangePage = async (event, newPage) => {
    setLoad(true);
    try {
      const { data } = await AxiosInstance.get(
        `/call_record?page=${newPage}${
          filteredData.filtered ? filteredData.term : ""
        }`
      );
      let finalData = data.data;

      setSearchParam(
        `?page=${newPage}${
          filteredData.filtered ? `&filter=${encodeSpl(filteredData.term)}` : ""
        }`
      );
      setCallRecord_list(finalData);
      setTotalCallRecord(data.total_length);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearchFilterData = () => {
    const filterArr = [
      { data: mobileNumber ? `&number=${mobileNumber}` : "" },
      { data: unitNameData ? `&unit_id=${unitNameData.ID}` : "" },
      { data: callTypeData ? `&call_type_id=${callTypeData.ID}` : "" },
      { data: categorydata ? `&call_category_id=${categorydata.id}` : "" },
      { data: roleData ? `&author_id=${roleData.id}` : "" },
      { data: callDate ? `&call_date=${callDate}` : "" },
    ];
    fetchCallRecord(
      filterArr
        .filter((e) => e.data)
        .map((e) => e.data)
        .join("")
    );
  };

  const handleClearFilterData = () => {
    setMobileNumber("");
    setUnitNameData(null);
    setCategoryData(null);
    setCallTypeData(null);
    setCallDate("");
    setRoleData(null);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
    // window.location.href = "/call_record_list";
  };

  const isInitialLoad = useRef(true);

  const handleResetFilterData = () => {
    setMobileNumber("");
    setUnitNameData(null);
    setCategoryData(null);
    setCallTypeData(null);
    setCallDate("");
    setRoleData(null);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);
    window.location.href = "/call_record_list";
  };

  useEffect(() => {
    const navigationEntries = window.performance.getEntriesByType("navigation");
    const isPageReloaded =
      window.performance.navigation.type === 1 ||
      (navigationEntries.length > 0 && navigationEntries[0].type === "reload");

    if (isPageReloaded && isInitialLoad.current) {
      isInitialLoad.current = false; // Prevent continuous reloads

      // Reset all filter data and set the page to 1
      setMobileNumber("");
      setUnitNameData(null);
      setCategoryData(null);
      setCallTypeData(null);
      setCallDate("");
      setRoleData(null);
      setFilteredData({ filtered: false, term: "" });
      setPage(1); // Set the page to 1

      // Use `window.location.replace` to reload the page to the initial state
      setTimeout(() => {
        window.location.replace(window.location.pathname);
      }, 100);
    } else if (!isPageReloaded) {
      // Normal case: fetch data based on current URL params and filters
      fetchCallRecord();
    }
  }, []);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const handleSearchClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsSidebarOpen(false);
  };

  // const handleDisabledClick = () => {
  //   toast.warning("You can't edit already verified ");

  // };

  const handleDisabledClick = () => {
    toast.warning("This record has already been verified, You cannot edit");
  };

  const handleStatusClick = () => {
    toast.warning("Add status in no status record list ");
  };

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [searchUnitName, isSidebarOpen]);

  //Call Type
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchCallType !== "") {
        // State api call
        let stateData_x = await AxiosInstance.get(
          `/call_type?search_keyword=${searchCallType}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setCallTypeOption(finalStateData_X);
      } else {
        // State api call
        let stateData_x = await AxiosInstance.get(`/call_type?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setCallTypeOption(finalStateData_X);
      }
    };

    if (isSidebarOpen) {
      fetchData();
    }
  }, [searchCallType, isSidebarOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleIds = "1,5";
        let response;
        if (searchRoleName !== "") {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=${roleIds}&search_keyword=${searchRoleName}&limit=10`
          );
        } else {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=${roleIds}&limit=10`
          );
        }
        const finalStateData_X = response.data.data || [];
        setRoleOption(finalStateData_X);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setRoleOption([]);
      }
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [searchRoleName, isSidebarOpen]);

  const [loadCategory, setLoadCategory] = useState(false);

  useEffect(() => {
    let fetchData = async (e) => {
      let data = await AxiosInstance.get(
        `/call_category_mini?disable=false&limit=10`
      );
      let finalData = data.data.data;
      setCategoryOption(finalData);
      setDefaultState(data.data);
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [isSidebarOpen]);

  //Category Name
  let handleCatSearch = (_, e) => {
    setLoadCategory(true);
    setSearchCategory(e);
    if (e && e.length > 0) {
      searchCategoryData(e);
    } else {
      setCategoryOption([]);
      setTimeout(() => {
        setCategoryOption(defaultState.data);
      }, 10);
      setLoadCategory(false);
    }
  };
  const searchCategoryData = async (searchCategory) => {
    try {
      let { data } = await AxiosInstance.get(
        `/call_category_mini?disable=false&search_keyword=${searchCategory}`
      );
      setCategoryOption(data.data || []);
      setLoadCategory(false);
    } catch (error) {
      console.log(error);
      setCategoryOption([]);
      setLoadCategory(false);
    }
  };

  const btnstyle = {
    marginLeft: "25%",
    margin: "8px 0",
    backgroundColor: "#40679E",
    color: "#fff",
    border: "1px solid #40679E",
    "&:disabled": {
      cursor: "not-allowed",
    },
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const handlePlayAudio = (audioElement) => {
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    setCurrentAudio(audioElement);
  };

  return (
    <section style={{ width: "100%", marginTop: "4.5%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            margin: "0 auto",
            justifyContent: "center",
          }}
        >
          Call Record List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalCallRecord}
        </h1>
        {/* <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          {searchButton ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleSearchClick}
              disabled={load}
            >
              Search
            </Button>
          ) : undefined}
        </Grid>
        <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "40px",
          }}
        >
          {" "}
          {resetData ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={btnstyle1}
              onClick={() => {
                handleClearFilterData();
              }}
            >
              Reset
            </Button>
          ) : undefined}
        </Grid> */}
        <Grid md={0.5} sx={{ marginRight: 2 }}>
          <Button
            style={{
              backgroundColor: "#003285",
              color: "#fff",
            }}
            size="small"
            variant="contained"
            fullWidth
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </Grid>
        <Grid md={0.5} sx={{ marginRight: 2 }}>
          <Button
            variant="contained"
            color="warning"
            fullWidth
            size="small"
            onClick={() => {
              handleResetFilterData();
            }}
          >
            Reset
          </Button>
        </Grid>
      </Box>
      <Box sx={{ width: 300 }}>
        <Drawer
          anchor="right"
          open={isSidebarOpen}
          onClose={handleCloseSidebar}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 1,
              paddingTop: 1,
            }}
          >
            <h2
              style={{
                width: "100%",
                marginTop: "2%",
                marginLeft: "2%",
                color: "#40679E",
              }}
            >
              Search List
            </h2>
            <IconButton onClick={handleCloseDrawer}>
              <FaTimes style={{ color: "red" }} />
            </IconButton>
          </Box>
          <Box sx={{ width: 300 }}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{ padding: "8px 8px" }}
            >
              <Grid item sm={12}>
                <p style={{ fontWeight: "bold" }}>Search By Number</p>
                <TextField
                  style={{ marginTop: "1%" }}
                  size="small"
                  sx={{ minWidth: 210 }}
                  fullWidth
                  name="mobileNumber"
                  label="Number"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  value={mobileNumber}
                  onChange={e => setMobileNumber(e.target.value)}
                  // onChange={(e) => handleFilterChange(e.target.value)}
                />
              </Grid>
              <Grid item sm={12}>
                <p style={{ fontWeight: "bold" }}>Call Date</p>
                <Item>
                  <TextField
                    type="date"
                    fullWidth
                    id="Call Date"
                    size="small"
                    label="Call Date "
                    name="callDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoFocus
                    value={callDate}
                    onChange={e => {
                      setCallDate(e.target.value);
                    }}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-3%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Call Type
                </p>
                <Item onChange={e => setSearchCallType(e.target.value)}>
                  <Autocomplete
                    options={callTypeOption}
                    value={callTypeData}
                    size="small"
                    name="callTypeOption"
                    onChange={(event, newData_xy) => {
                      setCallTypeData(newData_xy);
                    }}
                    getOptionLabel={option => option.call_type}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Call type"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold" }}>Search Unit Name</p>
                <Item onChange={e => setSearchUnitName(e.target.value)}>
                  <Autocomplete
                    options={unitNameOption}
                    value={unitNameData}
                    size="small"
                    name="unitNameOption"
                    onChange={(event, newData_xy) => {
                      setUnitNameData(newData_xy);
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Unit Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold" }}>Search Call Category</p>
                <Item>
                  <Autocomplete
                    options={categoryOption}
                    value={categorydata}
                    size="small"
                    name="categoryOption"
                    inputValue={searchCategory}
                    onInputChange={handleCatSearch}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newData_xy) => {
                      setCategoryData(newData_xy);
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Category"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold" }}>Search Author Name</p>
                <Item onChange={e => setSearcRoleName(e.target.value)}>
                  <Autocomplete
                    options={roleOption}
                    value={roleData}
                    size="small"
                    name="roleOption"
                    onChange={(event, newData_xy) => {
                      setRoleData(newData_xy);
                    }}
                    getOptionLabel={option => option.full_name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Author Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={4} style={{ padding: "5px" }}>
              <Grid item sm={4}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleClearFilterData}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item sm={4}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                  onClick={handleSearchFilterData}
                  sx={{
                    cursor:
                      !mobileNumber &&
                      !unitNameData &&
                      !categorydata &&
                      !roleData &&
                      !callTypeData &&
                      !callDate
                        ? "not-allowed !important"
                        : "pointer",
                    opacity:
                      !mobileNumber &&
                      !unitNameData &&
                      !categorydata &&
                      !roleData &&
                      !callTypeData &&
                      !callDate
                        ? 0.3
                        : 1,
                  }}
                  disabled={
                    !mobileNumber &&
                    !unitNameData &&
                    !categorydata &&
                    !roleData &&
                    !callTypeData &&
                    !callDate
                  }
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </Box>
      <section
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          whiteSpace: "nowrap",
          // border: "1px solid #111",
          borderRadius: "10px",
          marginTop: "0.5%",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 690 : 540 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "90px",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px",
                  }}
                >
                  Author
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Call Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px",
                  }}
                >
                  Call Type
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "170px",
                  }}
                >
                  Duration in sec
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "280px",
                  }}
                >
                  Audio
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "280px",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  Call Category
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "280px",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  Main Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "280px",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  Sub Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px",
                  }}
                >
                  Edit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : callRecord_list?.length > 0 ? (
                callRecord_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.author
                        ? val.author.name
                          ? val.author.name
                          : "NA"
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.number === null || val.number === "" ? (
                        "N/A"
                      ) : (
                        <a
                          href={`tel:${val.number}`}
                          style={{
                            color: "#125B9A",
                            // textDecoration: "none",
                          }}
                        >
                          {val.number}
                        </a>
                      )}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_date === null || val.call_date === ""
                        ? "NA"
                        : moment(toIsoFormat(val?.["call_date"]))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_type
                        ? val.call_type.call_type
                          ? val.call_type.call_type
                          : "NA"
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.duration === null || val.duration === ""
                        ? "NA"
                        : val.duration}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.audio ? (
                        <audio
                          src={`https://incubationb.qspiders.com/api/v1/get_audio?name=${val.audio}`}
                          controls
                          controlsList="nodownload"
                          onPlay={e => handlePlayAudio(e.target)}
                        />
                      ) : (
                        "NA"
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_status && val.call_status.category_id
                        ? val.call_status.category_id.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* {val.call_status && val.call_status.call_status
                        ? val.call_status.call_status.main_status
                        : "NA"} */}
                      {val.call_status &&
                      val.call_status.call_status &&
                      val.call_status.call_status.main_status !== ""
                        ? val.call_status.call_status.main_status
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* {val.call_status &&
                      val.call_status.call_status &&
                      val.call_status.call_status.sub_status &&
                      val.call_status.call_status.sub_status.sub_status !== ""
                        ? val.call_status.call_status.sub_status.sub_status
                        : "NA"} */}
                      {val.call_status &&
                      val.call_status.call_status &&
                      val.call_status.call_status.sub_status &&
                      typeof val.call_status.call_status.sub_status
                        .sub_status === "string" &&
                      val.call_status.call_status.sub_status.sub_status.trim() !==
                        "" &&
                      Object.keys(val.call_status.call_status.sub_status)
                        .length !== 0
                        ? val.call_status.call_status.sub_status.sub_status
                        : "NA"}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_status &&
                      val.call_status.category_id &&
                      val.call_status.category_id.id === 6 ? (
                        <Tooltip title="You can't edit" disableInteractive>
                          <span>
                            <Button
                              sx={{
                                "&:hover": { background: "#DBDFFD" },
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaEdit
                                fontSize={20}
                                style={{
                                  color: "#EB455F",
                                }}
                              />
                            </Button>
                          </span>
                        </Tooltip>
                      ) : val.call_status &&
                        val.call_status.call_status &&
                        val.call_status.call_status.main_status &&
                        val.call_status.call_status.sub_status &&
                        val.call_status.call_status.main_status !== "" &&
                        val.call_status.call_status.sub_status.sub_status !==
                          "" &&
                        Object.keys(val.verification).length === 0 ? (
                        <Link to="/edit_callrecord" state={val}>
                          <Tooltip title="Edit Call Record">
                            <Button
                              sx={{
                                "&:hover": { background: "#DBDFFD" },
                              }}
                            >
                              <FaEdit
                                fontSize={20}
                                style={{
                                  color: "#008DDA",
                                  "&:hover": { color: "#EB455F" },
                                  cursor: "pointer",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </Link>
                      ) : (
                        <Tooltip>
                          <span
                            onClick={() => {
                              if (
                                val.call_status &&
                                val.call_status.call_status &&
                                val.call_status.call_status.main_status &&
                                val.call_status.call_status.sub_status &&
                                val.call_status.call_status.main_status !==
                                  "" &&
                                val.call_status.call_status.sub_status
                                  .sub_status !== ""
                              ) {
                                handleDisabledClick();
                              } else {
                                handleStatusClick();
                              }
                            }}
                          >
                            <Button
                              sx={{
                                "&:hover": { background: "#DBDFFD" },
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              <FaEdit
                                fontSize={20}
                                style={{
                                  color: "#EB455F",
                                }}
                              />
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </StyledTableCell>

                    {/* <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_status &&
                      val.call_status.call_status &&
                      val.call_status.call_status.main_status &&
                      val.call_status.call_status.sub_status &&
                      val.call_status.call_status.main_status !== "" &&
                      val.call_status.call_status.sub_status.sub_status !==
                        "" &&
                      Object.keys(val.verification).length === 0 ? (
                        <Link to="/edit_callrecord" state={val}>
                          <Tooltip title="Edit Call Record">
                            <Button
                              sx={{
                                "&:hover": { background: "#DBDFFD" },
                              }}
                            >
                              <FaEdit
                                fontSize={20}
                                style={{
                                  color: "#008DDA",
                                  "&:hover": { color: "#EB455F" },
                                  cursor: "pointer",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </Link>
                      ) : val.call_status.category_id &&
                        val.call_status.category_id.id == 10 ? (
                        <Tooltip title="You can't edit">
                          <Button
                            sx={{
                              "&:hover": { background: "#DBDFFD" },
                              cursor: "not-allowed",
                            }}
                            disabled
                          >
                            <FaEdit
                              fontSize={20}
                              style={{
                                color: "#EB455F",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip>
                          <Button
                            sx={{
                              "&:hover": { background: "#DBDFFD" },
                              cursor: "not-allowed",
                            }}
                            onClick={() => {
                              if (
                                val.call_status &&
                                val.call_status.call_status &&
                                val.call_status.call_status.main_status &&
                                val.call_status.call_status.sub_status &&
                                val.call_status.call_status.main_status !==
                                  "" &&
                                val.call_status.call_status.sub_status
                                  .sub_status !== ""
                              ) {
                                handleDisabledClick();
                              } else {
                                handleStatusClick();
                              }
                            }}
                          >
                            <FaEdit
                              fontSize={20}
                              style={{
                                color: "#EB455F",
                              }}
                              disabled
                            />
                          </Button>
                        </Tooltip>
                      )}
                    </StyledTableCell> */}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={12}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            maxWidth: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0.5% auto",
          }}
          count={Math.ceil(totalCallRecord / 10)}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={Number(pageParam) || page}
          onChange={handleChangePage}
        />
      </section>
    </section>
  );
}
