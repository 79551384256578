import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import CreateCallVerification from "../Bd_Module/juniorVerifer/CreateCallVerification";
import Createpoints from "../Bd_Module/juniorVerifer/Createpoints";
import NoVerificationCallRecordList from "../Bd_Module/juniorVerifer/NoVerificationCallRecordList";
import VerifierCallRecordList from "../Bd_Module/juniorVerifer/VerifierCallRecordList";
import Profile from "../components/profile/Profile";
import EscalationLevelList from "../superAdmin/EscalationLevelList";
import CallNotHandledEscalationList from "../superAdmin/CallNotHandledEscalationList";
import NoStatusRecordList from "../callRecord/NoStatusRecordList";
import NoStatusHandledEscalationList from "../Bd_Module/juniorVerifer/NoStatusHandledEscalationList";
import NoStatusNotHandledEscalationList from "../Bd_Module/juniorVerifer/NoStatusNotHandledEscalationList";
import EditCallRecordMainStatus from "../callRecord/EditCallRecordMainStatus";
import EditCallStatus from "../Bd_Module/juniorVerifer/EditCallStatus";
import VerifiedNotVerifiedCallRecordLists from "../Bd_Module/juniorVerifer/VerifiedNotVerifiedCallRecordLists";
import LeadPassingForm from "../newlead/LeadPassingForm";
import LeadPassingFormList from "../newlead/LeadPassingFormList";
import Rewards from "../newlead/Rewards";

const SeniorVerifierRoutes = () => {
  let routes = useRoutes([
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/" /> },
    // { path: "/", element: <VerifierCallRecordList /> },
    { path: "/", element: <VerifiedNotVerifiedCallRecordLists /> },
    // { path: "/verifier_call_record_list", element: <VerifierCallRecordList /> },
    { path: "/verifier_call_record_list", element:<VerifiedNotVerifiedCallRecordLists/>  },
    {
      path: "/no_verifier_call_record_list",
      element: <NoVerificationCallRecordList />,
    },
    { path: "/create_points", element: <Createpoints /> },
    { path: "/create_call_verification", element: <CreateCallVerification /> },
    { path: "/call_handled_escalation_list", element: <EscalationLevelList /> },
    // {
    //   path: "/call_not_handled_escalation_list",
    //   element: <CallNotHandledEscalationList />,
    // },
    {
      path: "/missed_call_escalation_list",
      element: <CallNotHandledEscalationList />,
    },
    { path: "/nostatus_record_list", element: <NoStatusRecordList /> },
    {
      path: "/nostatus_handled_escalation_list",
      element: <NoStatusHandledEscalationList />,
    },
    { path: "/edit_call_status", element: <EditCallStatus/> },
   
    // {
    //   path: "/nostatus_not_handled_escalation_list",
    //   element: <NoStatusNotHandledEscalationList />,
    // },
    {
      path: "/nostatus_escalation_list",
      element: <NoStatusNotHandledEscalationList />,
    },
    { path: "/lead_passing_form", element: <LeadPassingForm /> },
    { path: "/lead_passing_form_list", element: <LeadPassingFormList /> },
    {path:"/rewards",element:<Rewards/>}
  ]);
  return routes;
};

export default SeniorVerifierRoutes;
