import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Container,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import AxiosInstance from "../../../apis/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";

//====================================

export default function AssignQuiz({ data, addMore }) {
  const Navigate = useNavigate();
  const [available, setChecked] = React.useState(false);

  // const [examTypeList, setExamTypeList] = useState(
  //   addMore ? Object.values(addMore.quiz_details.quizes)[0].exam_type : ""
  // );
  // const [examTypeListOption, setExamTypeListOption] = useState([]);

  const [state, setState] = useState({
    from_date: addMore
      ? moment
          .utc(Object.values(addMore.quiz_details.quizes)[0].from_date)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
      : "",
    end_date: addMore
      ? moment
          .utc(Object.values(addMore.quiz_details.quizes)[0].end_date)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
      : "",
  });
  const { from_date, end_date } = state;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    if (moment(end_date).isSameOrBefore(from_date)) {
      toast.error(
        "Close Date and Time should be greater than Open Date and Time"
      );
      return;
    }

    try {
      const addressDateTime = moment(from_date)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      const addressDateTime1 = moment(end_date)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      let quizData = available
        ? {
            from_date: addressDateTime,
            // always_open: 0,
            // exam_type: examTypeList === "offline" ? 1 : 2,
            status: 1,
          }
        : {
            end_date: addressDateTime1,
            from_date: addressDateTime,
            // always_open: 1,
            // exam_type: examTypeList === "offline" ? 1 : 2,
            status: 1,
          };
      let obj = {};
      data.data.forEach((e) => {
        obj[e] = quizData;
      });
      const payload = {
        addressing_id: [Number(data.aid)],
        quizes_id: {
          quizes: obj,
        },
      };

      await AxiosInstance.post("/assign_quiz", payload, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Quiz Asigned Successfully");
            setTimeout(() => {
              if (data.to === 1) {
                Navigate("/addressing_list");
              } else {
                Navigate("/");
              }
            }, 1000);
          } else if (response.status == 401) {
            Navigate("/login", {
              state: {
                data: response.data.data[0],
              },
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          toast.error(error.response.data.error1);
          toast.error(error.response.data.error2);
          toast.error(error.response.data.error3);
          toast.error(error.response.data.error4);
          toast.error(error.response.data.error5);
          // toast.error("quiz start time should be greater than end time of addressing");
          console.log(error);
        });
    } catch (error) {
      console.log(error.code);
    }
  };

  // useEffect(() => {
  //   let fetchData = async () => {
  //     let examTypeData = await AxiosInstance.get(`/exam_types`);
  //     let finalExamTypeData = examTypeData.data.data;
  //     setExamTypeListOption(finalExamTypeData);
  //   };
  //   fetchData();
  // }, []);

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "95%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Assign Quiz
          </Box>
        </Box>
        <Container
          component="main"
          style={{
            width: "70%",
            border: "1px solid #081F37",
            borderRadius: "10px",
          }}
        >
          <Paper variant="outlined">
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  {/* <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Exam Type :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "50%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Exam Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Exam Type"
                        value={examTypeList}
                        name="examTypeList"
                        onChange={(e) => {
                          setExamTypeList(e.target.value);
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Open Date and Time :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "50%" }}>
                      <TextField
                        name="from_date"
                        required
                        fullWidth
                        type="datetime-local"
                        id="from_date"
                        value={from_date}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    {!available && "Close Date and Time :"}
                  </Grid>
                  <Grid item xs={9}>
                    {!available && (
                      <FormControl style={{ width: "50%" }}>
                        <TextField
                          name="end_date"
                          required
                          fullWidth
                          type="datetime-local"
                          id="end_date"
                          value={end_date}
                          onChange={handleChange}
                        />
                      </FormControl>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "20%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </article>
    </section>
  );
}
