import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Chip,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import AxiosInstance from "../../../apis/AxiosInstance";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaUserCheck, FaAddressCard } from "react-icons/fa";
import moment from "moment/moment";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CollegeAppointmentFromSpoc() {
  //props form the spoc-creation page
  const { state } = useLocation();

  // state values  for the autocomplete component
  const [selectedUniversity, setSelectedUniversity] = useState(
    state.data.university.id
  );
  const [selectedCollege, setSelectedCollege] = useState(state.data.college.id);
  const [degTerm, setDegTerm] = useState("");
  const [streamTerm, setStreamTerm] = useState(null);
  const [selectedSpoc, setSelectedSpoc] = useState(state.data.id);
  const [selectedDegrees, setselectedDegrees] = useState([]);
  const [selectedStreams, setselectedStreams] = useState([]);
  const [expectedStudents, setExpectedStudents] = useState("");
  const [incubation_type, setincubation_type] = useState("");
  const [comment, setComment] = useState("");
  const [appointment_date, setAppointment_date] = useState("");
  const [appointment_time, setAppointment_time] = useState("");
  const [all_degree, setAll_Degree] = useState(false);
  const [all_streams, setAll_Streams] = useState(false);
  const [selectedColgState, setSelectedColgState] = useState(
    state.data.state.id
  );
  const [selectedColgDistrict, setselectedColgDistrict] = useState(
    state.data.district.id
  );
  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");
  const [programmeData, setProgrammeData] = useState("");
  const [examTypeListOption, setExamTypeListOption] = useState([]);
  let [errorForProgramme, setErrorForProgramme] = React.useState(false);
  const navigate = useNavigate();

  // for rendering the data from api

  const [degreeOptions, setDegreeOptions] = useState("");
  const [streamsOptions, setStreamsOptions] = useState("");
  const [isSubmitAddressing, setIsSubmitAddressing] = useState(false);

  //Year
  const [yop, setYop] = useState(null);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();

  // All degree and All Stream
  const [isDisabledDeg, setIsDisabledDeg] = useState(false);
  const [isDisabledStrm, setIsDisabledStrm] = useState(false);

  const handleCheckboxDegree = (event) => {
    setIsDisabledDeg(event.target.checked);
    setAll_Degree(true);
  };
  const handleCheckboxStream = (event) => {
    setIsDisabledStrm(event.target.checked);
    setAll_Streams(true);
  };

  //!FUNCTION TO HANDLE INPUT-CHANGE
  let handleChangeIncubation = (e) => {
    setincubation_type(e.target.value);
  };

  // to expectedStudents
  let handleStudents = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ""); // remove non-numeric characters
    if (inputValue.length <= 5) {
      setExpectedStudents(inputValue);
    }
  };

  // to handle Date and Time
  let handleDate = (e) => {
    setAppointment_date(e.target.value);
    if (!appointment_date === "") {
      setError(true);
    } else {
      setError(false);
    }
  };
  let handleTime = (e) => {
    setAppointment_time(e.target.value);
  };

  // Error Handling for Create Addressing Faculty
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //==================================FUNCTION TO HANDLE FORM SUBMITION=============================
  let handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      // let dateData = appointment_date + " " + appointment_time + " " + "+0000";
      const dateData = new Date(appointment_date + "T" + appointment_time);
      const appointmentDateTime = moment(dateData)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");

      if (expectedStudents.toString().startsWith("0")) {
        toast.error("Please enter a valid number for Students Targeted");
        setIsSubmitting(false);
        return; // Stop further execution
      }

      if (incubation_type === "") {
        setError(true);
        setIsSubmitting(false);
      } else {
        setError(false);
        let payload = {
          state_id: selectedColgState,
          district_id: selectedColgDistrict,
          university_id: selectedUniversity,
          college_id: selectedCollege,
          college_spoc_id: [selectedSpoc],
          programme_id: programmeData == 1 ? 1 : 2,
          degree_id:
            all_degree === true ? [] : selectedDegrees.map((value) => value.id),
          stream_id:
            all_streams === true
              ? []
              : selectedStreams.map((value) => value.id),
          year_of_passout: Number.parseInt(yop),
          expected_students: Number.parseInt(expectedStudents),
          incubation_type: Number.parseInt(incubation_type),
          comment: comment,
          appointment_date: appointmentDateTime,
          all_degree: all_degree !== true ? false : true,
          all_streams: all_streams !== true ? false : true,
          unit_id: [unitNameData.ID],
        };

        await AxiosInstance.post("/appointment", payload, {
          "content-type": "multipart/form-data",
          Authorization: `${token}`,
        })
          .then((response) => {
            toast.success("Successfully college appointment data is submitted");
            if (response.status == 200) {
              setTimeout(() => {
                navigate("/");
              }, 1200);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.error);
            setIsSubmitting(false);
          });
      }
    } catch (error) {
      console.log(error.code);
      setIsSubmitting(false);
    }
  };
  // =================================submit save==========================================
  // ===============================create addressing faculty starts=======================================

  let handleCreateFacultySpoc = async (event) => {
    event.preventDefault();
    setIsSubmitAddressing(true);
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      // let dateData = appointment_date + " " + appointment_time + " " + "+0000";
      const dateData = new Date(appointment_date + "T" + appointment_time);
      const appointmentDateTime = moment(dateData)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");

      if (expectedStudents.toString().startsWith("0")) {
        toast.error("Targeted Students cannot be zero");
        setIsSubmitAddressing(false);
        return; // Stop further execution
      }

      if (
        !selectedColgState ||
        !selectedColgDistrict ||
        !selectedUniversity ||
        !selectedCollege ||
        !selectedSpoc ||
        !yop ||
        !expectedStudents ||
        !incubation_type ||
        !appointment_date ||
        !appointment_time
      ) {
        setError(true);
        toast.error("Please fill out all required fields.");
        setIsSubmitAddressing(false);
        return;
      } else {
        setError(false);
      }

      let payload = {
        state_id: selectedColgState,
        district_id: selectedColgDistrict,
        university_id: selectedUniversity,
        college_id: selectedCollege,
        college_spoc_id: [selectedSpoc],
        programme_id: programmeData == 1 ? 1 : 2,
        degree_id:
          all_degree === true ? [] : selectedDegrees.map((value) => value.id),
        stream_id:
          all_streams === true ? [] : selectedStreams.map((value) => value.id),
        year_of_passout: Number.parseInt(yop),
        expected_students: Number.parseInt(expectedStudents),
        incubation_type: Number.parseInt(incubation_type),
        comment: comment,
        appointment_date: appointmentDateTime,
        all_degree: all_degree,
        all_streams: all_streams,
        unit_id: [unitNameData.ID],
      };
      await AxiosInstance.post("/appointment", payload, {
        "content-type": "multipart/form-data",
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            window.sessionStorage.setItem(
              "appointment_date",
              response.data.data.appointment_date
            );
            navigate("/create_addressingfaculty");
            toast.success("Successfully college appointment data is submitted");
            setTimeout(() => {
              navigate("/create_addressingfaculty");
            }, 1200);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
          setIsSubmitAddressing(false);
        });
    } catch (error) {
      // console.log(error.code);
      toast.error(error.response.data.error);
      setIsSubmitAddressing(false);
      toast.error("Something went wrong");
    }
  };

  // ===================================create address faculty ends =================================================
  //! API Calls

  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/appointment_programme`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  // deg and stream
  useEffect(() => {
    let fetchData1 = async (e) => {
      if (unitNameData !== null) {
        if (degTerm !== "") {
          // college Degree api
          let degreeData = await AxiosInstance.get(
            `/unit?degree_name=${degTerm}&limit=10&id=${unitNameData.ID}`
          );
          let finaldegreeData = degreeData.data.data;
          function convertToArrayOfObjects(data) {
            let arrayOfObjects = [];

            for (let key in data) {
              let id = parseInt(key);
              let name = data[key].name;
              arrayOfObjects.push({ id, name });
            }
            setDegreeOptions(arrayOfObjects);
            return arrayOfObjects;
          }
          convertToArrayOfObjects(finaldegreeData[0]?.degree?.degree);
          // setDegreeOptions(finaldegreeData);
        } else {
          // college Degree api
          let degreeData = await AxiosInstance.get(
            `/unit?limit=10&id=${unitNameData.ID}`
          );
          let finaldegreeData = degreeData.data.data;
          function convertToArrayOfObjects(data) {
            let arrayOfObjects = [];
            for (let key in data) {
              let id = parseInt(key);
              let name = data[key].name;
              arrayOfObjects.push({ id, name });
            }
            setDegreeOptions(arrayOfObjects);

            return arrayOfObjects;
          }
          convertToArrayOfObjects(finaldegreeData[0]?.degree?.degree);
          // setDegreeOptions(finaldegreeData);
        }
      }
    };
    fetchData1();
  }, [degTerm, unitNameData]);

  let [url, setUrl] = useState("/academic_streams");
  useEffect(() => {
    let QueryParams = selectedDegrees.map((val, i) => {
      return `degree_id=${val.id}`;
    });
    setStreamsOptions([]);
    let finalURL =
      QueryParams.length > 0 ? `${url}?${QueryParams.join("&")}` : url;
    let fetchDataS = async (e) => {
      if (streamTerm !== null) {
        // college Stream api
        let streamData = await AxiosInstance.get(
          `${finalURL}${
            finalURL === url ? "?" : "&"
          }search_keyword=${streamTerm}`
        );
        let finalstreamData = streamData.data.data;
        if (finalstreamData) {
          setStreamsOptions(finalstreamData);
        }
      } else {
        // college Stream api
        let streamData = await AxiosInstance.get(`${finalURL}`);
        let finalstreamData = streamData.data.data;
        setStreamsOptions(finalstreamData);
      }
    };
    fetchDataS();
  }, [streamTerm, selectedDegrees]);

  // search terms

  //deg and stream

  let searchDegree = async (e) => {
    if (e.target.value === "") {
      setDegTerm("a");
    } else {
      setDegTerm(e.target.value);
    }
  };

  let searchStream = async (e) => {
    if (e.target.value === "") {
      setStreamTerm("a");
    } else {
      setStreamTerm(e.target.value);
    }
  };

  // Reset Data
  let resetData = (e) => {
    e.preventDefault();

    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedUniversity(null);
    setSelectedCollege(null);
    setSelectedSpoc(null);
    setselectedDegrees(null);
    setselectedStreams(null);
    setYop(null);
    setExpectedStudents("");
    setincubation_type("");
    setComment("");
    setAppointment_date("");
    setAppointment_time("");
    setAll_Degree(false);
    setAll_Streams(false);
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "98%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4.3%",
          background: "#efefef",
        }}
      >
        <Box>
          <Paper
            variant="outlined"
            style={{ border: "2px solid #9BA4B5", borderRadius: "10px" }}
          >
            <Box
              sx={{
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "2px solid #9BA4B5",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#081f37",
                  fontSize: {
                    xs: "20px", // Smaller font size on small screens
                    sm: "25px", // Medium font size on small to medium screens
                    md: "30px", // Larger font size on medium to large screens
                  },
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                COLLEGE APPOINTMENT FORM
              </Box>
            </Box>
            <form
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "1%",
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                {/* colleg state */}
                <Grid md={3} xs={12}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      College State
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      // label="State"
                      variant="outlined"
                      onChange={() => {
                        setSelectedColgState(state.data.state.id);
                      }}
                      required
                      value={state.data.state.name}
                      name="state_name"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>

                {/* colleg district */}
                <Grid md={3} xs={12}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      College District
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      onChange={() => {
                        setselectedColgDistrict(state.data.district.id);
                      }}
                      variant="outlined"
                      required
                      value={state.data.district.name}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>

                {/* university  */}
                <Grid md={3} xs={12}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      University
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      disabled
                      value={state.data.university.name}
                      name="university_name"
                      onChange={(event) => {
                        setSelectedUniversity(state.data.university.id);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>

                {/* College   */}
                <Grid md={3} xs={12}>
                  <Item>
                    <InputLabel
                      htmlFor="my-input"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      College
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      required
                      value={state.data.college.name}
                      onChange={() => {
                        setSelectedCollege(state.data.college.id);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>

                {/* SPOC  */}
                <Grid md={3} xs={12}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      Spoc Name
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled
                      onChange={() => {
                        setSelectedSpoc(state.data.id);
                      }}
                      value={state.data.name}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>

                {/* YOP */}
                <Grid md={3} xs={12}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      Year Of Passout
                    </InputLabel>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <Autocomplete
                        required
                        options={yopOptions}
                        value={yop}
                        name="year_of_passout"
                        onChange={(e, newyop) => {
                          setYop(newyop);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select YOP"
                            variant="outlined"
                            required
                            error={error && !yop}
                            helperText={
                              error && !yop ? "Please select YOP" : null
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Item>
                </Grid>
                <Grid md={3} xs={12} style={{ marginTop: "1%" }}>
                  <Item
                    onChange={(e) => {
                      setSearchUnitName(e.target.value);
                    }}
                  >
                    <Autocomplete
                      options={unitNameOption}
                      value={unitNameData}
                      name="unitNameOption"
                      onChange={(event, newData_xy) => {
                        setUnitNameData(newData_xy);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Unit Name"
                          variant="outlined"
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Degree */}
                <Grid md={3} xs={12} style={{ marginTop: "1%" }}>
                  <Item onChange={searchDegree}>
                    <Autocomplete
                      multiple
                      disabled={isDisabledDeg || !unitNameData}
                      required
                      // size="small"
                      value={selectedDegrees}
                      onChange={(event, newValue) => {
                        setselectedDegrees([...newValue]);
                      }}
                      options={degreeOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Degree"
                          color="warning"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Streams */}
                <Grid md={3} xs={12}>
                  <Item onChange={searchStream}>
                    <Autocomplete
                      multiple
                      disabled={isDisabledStrm || !unitNameData}
                      required
                      // size="small"
                      value={selectedStreams}
                      onChange={(event, newValue) => {
                        setselectedStreams([...newValue]);
                      }}
                      options={streamsOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Streams"
                          color="warning"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>

                {/* Checkboxes */}
                <Grid md={3} xs={12}>
                  <Item Component={"aside"} sx={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        position: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {selectedDegrees.length !== 0 ? (
                        <>
                          <label
                            htmlFor="chkDeg"
                            style={{
                              fontSize: "17px",
                              fontWeight: "500",
                            }}
                          >
                            All Degrees
                          </label>
                          <Checkbox
                            disabled
                            id="chkDeg"
                            checked={isDisabledDeg}
                            onChange={handleCheckboxDegree}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                                color: "#efefef",
                                background: "grey",
                              },
                            }}
                            value={all_degree}
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="chkDeg"
                            style={{
                              fontSize: "17px",
                              fontWeight: "500",
                            }}
                          >
                            All Degrees
                          </label>
                          <Checkbox
                            id="chkDeg"
                            required
                            checked={isDisabledDeg}
                            onChange={handleCheckboxDegree}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 26 },
                            }}
                            value={all_degree}
                          />
                        </>
                      )}
                      &nbsp; &nbsp;&nbsp;
                      {selectedStreams.length !== 0 ? (
                        <>
                          <label
                            htmlFor="chkStrm"
                            style={{
                              fontSize: "17px",
                              fontWeight: "500",
                            }}
                          >
                            All Streams
                          </label>
                          <Checkbox
                            disabled
                            id="chkStrm"
                            checked={isDisabledStrm}
                            onChange={handleCheckboxStream}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                                color: "#efefef",
                                background: "grey",
                              },
                            }}
                            label="Disabled"
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="chkStrm"
                            style={{
                              fontSize: "17px",
                              fontWeight: "500",
                            }}
                          >
                            All Streams
                          </label>
                          <Checkbox
                            id="chkStrm"
                            required
                            checked={isDisabledStrm}
                            onChange={handleCheckboxStream}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 26 },
                            }}
                            value={all_streams}
                            error={error && !all_streams}
                          />

                          {all_streams === "" || selectedStreams === "" ? (
                            <>
                              {error && (
                                <FormHelperText style={{ color: "d32f2f" }}>
                                  Please select Appointment Date
                                </FormHelperText>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </FormControl>
                  </Item>
                </Grid>

                {/* Date and Time */}
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      type="datetime-local"
                      fullWidth
                      id="appointment_date"
                      label="Appointment Date"
                      name="appointment_date"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={handleDate}
                      autoFocus
                      value={appointment_date}
                      error={error && !appointment_date}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                    {appointment_date === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select Appointment Date
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      type="datetime-local"
                      fullWidth
                      id="appointmentTime"
                      label="Appointment Time"
                      name="appointment_time"
                      onChange={handleTime}
                      onFocus={(e) => (e.target.type = "time")}
                      onBlur={(e) => (e.target.type = "text")}
                      autoFocus
                      value={appointment_time}
                      error={error && !appointment_time}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                    {appointment_time === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select Appointment Time
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>

                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      fullWidth
                      id="expected_students"
                      label="Students Targeted"
                      name="expected_students"
                      value={expectedStudents}
                      onChange={handleStudents}
                      inputProps={{ maxLength: 5 }}
                      variant="outlined"
                      type="text"
                      error={error && !expectedStudents}
                      helperText={
                        error && !expectedStudents
                          ? "Please Select Expected Students"
                          : null
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-name-label">
                        Programme
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={programmeData}
                        onChange={(event) => {
                          setProgrammeData(event.target.value);
                          setErrorForProgramme(setProgrammeData == "");
                        }}
                        input={<OutlinedInput label="Programme" />}
                        MenuProps={MenuProps}
                        error={errorForProgramme}
                        helperText={
                          errorForProgramme ? "Please select Programme" : ""
                        }
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Default border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                          },
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item Component={"aside"} sx={{ width: "100%" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="incubation_type"
                        value={incubation_type}
                        onChange={handleChangeIncubation}
                        required
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Pre-incubation"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio color="primary" />}
                          label="Post-incubation"
                        />
                      </RadioGroup>

                      {incubation_type === "" ? (
                        <>
                          {error && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              Please select an Incubation Type.
                            </FormHelperText>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  </Item>
                </Grid>

                <Grid xs={12} md={12}>
                  <Item sx={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>
                <Grid
                  xs={12}
                  item
                  md={12}
                  // spacing={2}
                  // sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "right",
                    padding: "5px",
                  }}
                >
                  {/* <Button
                    onClick={resetData}
                    type="button"
                    variant="contained"
                    style={{
                      width: "10%",
                      marginRight: "3%",
                      backgroundColor: "#DF2E38",
                      color: "#e74646",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button> */}

                  <Button
                    type="button"
                    variant="contained"
                    style={{
                      color: "#F5F5F5",
                      backgroundColor: "#1F8A70",
                      marginRight: "17px",
                      fontWeight: "bold",
                    }}
                    onClick={handleCreateFacultySpoc}
                    disabled={isSubmitAddressing}
                  >
                    Create Addressing
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "10%",
                      color: "#F5F5F5",
                      backgroundColor: "#1976d2",
                      fontWeight: "bold",
                    }}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </article>
    </section>
  );
}
