import React from "react";
import { Link, NavLink } from "react-router-dom";
import BdMenu from "./navLinks/BdMenu";
import Styles from "./_navbar.module.css";
import BdManagerMenu from "./navLinks/BdManagerMenu";
import AdminMenu from "./navLinks/AdminMenu";
import Cookies from "js-cookie";
import DataAnalistMenu from "./navLinks/DataAnalistMenu";
import SuperAdminMenu from "./navLinks/SuperAdminMenu";
import TpoMenu from "./navLinks/TpoMenu";
import SeniorVerifierMenu from "./navLinks/SeniorVerifierMenu";
import JuniorVerifierMenu from "./navLinks/JuniorVerifierMenu";
import CampusManagerMenu from "./navLinks/CampusManagerMenu";

const Menu = () => {
  //!TOKEN AND ROLE
  const parseLog = (a) => {
    const userData = a ? JSON.parse(a) : { token: "" };
    const { token } = userData;
    return token;
  };
  const token = parseLog(Cookies.get("logged"));
  const parseLogA = (a) => {
    const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
    const { id } = userData.role[0];
    return id.toString();
  };
  // const role = parseLogA(Cookies.get("logged"));
  const current_role = Cookies.get("current_role");
  const role = current_role ? JSON.parse(current_role).toString() : null;

  return token !== null && role == "1" ? (
    <BdMenu />
  ) : token !== null && role == "5" ? (
    <BdManagerMenu />
  ) : token !== null && role == "15" ? (
    <DataAnalistMenu />
  ) : token !== null && role == "16" ? (
    <SuperAdminMenu />
  ) : token !== null && role == "13" ? (
    <TpoMenu />
  ) : token !== null && role == "17" ? (
    <SeniorVerifierMenu />
  ) : token !== null && role == "18" ? (
    <SeniorVerifierMenu />
  ) : token !== null && role == "19" ? (
    <CampusManagerMenu />
  ): token !== null && role == "20" ? (
    <CampusManagerMenu />
  )  : token !== null && role == "12" ? (
    <AdminMenu />
  ) : (
    <div>
      <section id={Styles.NavMenuContaiiner}>
        {/* <NavLink
          to="/login"
          style={{ textDecoration: "none", color: "#8ed6ff", background: "none" }}
        >
          Login
        </NavLink> */}
      </section>
    </div>
  );
};

export default Menu;
