// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { Avatar, Button, Grid, Paper, TextField, Box } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import { useLocation, useNavigate } from "react-router-dom";

// import AxiosInstance from "../../apis/AxiosInstance";

// const CreateCallVerification = () => {
//   let { state } = useLocation();

//   console.log("state", state);

//   const location = useLocation();
//   const navigate = useNavigate();

//   // Retrieve the current page from location state or query parameter
//   // const currentPage = location.state?.currentPage || new URLSearchParams(location.search).get('page') || 1;

//   const currentPage =
//     new URLSearchParams(location.search).get("currentPage") || 1;
//   const recordId = new URLSearchParams(location.search).get("recordId");

//   const [comment, setComment] = useState("");
//   const [callStatusComment, setCallStatusComment] = useState(
//     state?.record?.call_status?.call_status?.comment ?? "NA"
//   );
//   const [pointsData, setPointsData] = useState("");
//   const [mainData, setMainData] = useState([
//     {
//       id: null,
//       points: "",
//       pointsWeight: "",
//       pointsMarks: "",
//       selected: false,
//     },
//   ]);

//   const handleButtonClick = (index) => {
//     const updatedMainData = mainData.map((item, idx) => {
//       if (idx === index) {
//         return { ...item, selected: !item.selected };
//       }
//       return item;
//     });
//     setMainData(updatedMainData);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     let cData =
//       pointsData && pointsData.length > 0 ? pointsData.map((e) => e.ID) : 0;

//     // Construct the payload object
//     const payload = {
//       rid: state.record.id,
//       csid: Number(cData),
//       comment: comment,
//       points:
//         cData === 0
//           ? [{}]
//           : mainData.map(({ id, points, pointsMarks, selected }) => ({
//               id,
//               point: points,
//               marks: parseInt(pointsMarks),
//               pass: selected, // pass is true if the button is selected, false otherwise
//             })),
//     };

//     try {
//       await AxiosInstance.post(`/call_record_verify`, payload);
//       toast.success("Verified successfully.");
//       setTimeout(() => {
//         // navigate("/no_verifier_call_record_list");
//         // window.history.back();
//         navigate(`/no_verifier_call_record_list?page=${currentPage}`);
//       }, 1500);
//     } catch (error) {
//       if (error.response && error.response.data) {
//         toast.error(error.response.data.error || "Something went wrong.");
//       } else {
//         toast.error("Something went wrong. Please try again.");
//       }
//     }
//   };

//   const paperStyle = {
//     padding: 20,
//     gap: 0,
//     width: "1150px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//   };
//   const avatarStyle = { backgroundColor: "#40679E" };
//   const btnstyle = {
//     margin: "8px 0",
//     backgroundColor: "#40679E",
//     color: "#fff",
//     border: "1px solid #40679E",
//   };

//   const btnstyle1 = {
//     margin: "8px 0",
//     backgroundColor: "crimson",
//     color: "#fff",
//     border: "1px solid crimson",
//   };

//   const handleCancelClick = () => {
//     // window.history.back();
//     navigate(`/no_verifier_call_record_list?page=${currentPage}`);
//   };

//   useEffect(() => {
//     let fetchData = async () => {
//       try {
//         let key = state.record.id;
//         let { data } = await AxiosInstance.get(
//           `/get_points_and_script?call_record_id=${key}`
//         );
//         let finalData = data.data;

//         if (finalData && finalData.length > 0) {
//           setPointsData(finalData);
//           setMainData(
//             finalData[0].points && finalData[0].points.length > 0
//               ? finalData[0].points.map((e) => ({
//                   id: e.id,
//                   points: e.point,
//                   pointsWeight: e.weight,
//                   pointsMarks: e.marks,
//                   selected: false,
//                 }))
//               : [
//                   {
//                     id: null,
//                     points: "",
//                     pointsWeight: "",
//                     pointsMarks: "",
//                     selected: false,
//                   },
//                 ]
//           );
//         } else {
//           // Handle the case where finalData is empty
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//     // }, [state.id]);
//   }, [recordId]);

//   const isCommentRequired = mainData.some(
//     (value) => value.points === "" || value.points === null
//   );

//   return (
//     <Box
//       style={{
//         marginTop: "5%",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         gap: "10px",
//       }}
//     >
//       <Grid xs={12} item container align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>Verify Call Record</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid
//               item
//               container
//               sm={12}
//               spacing={2}
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 gap: "10px",
//               }}
//             >
//               <Box
//                 style={{
//                   width: "95%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   gap: "10px",
//                 }}
//               >
//                 <Grid
//                   item
//                   container
//                   spacing={2}
//                   style={{
//                     border: "1px solid #06113C",
//                     marginTop: "5px",
//                     margin: "0 auto",
//                     marginLeft: "2%",
//                     paddingBottom: "15px",
//                     borderRadius: "20px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "center",
//                       margin: "0 auto",
//                       marginTop: "1%",
//                     }}
//                   >
//                     <span style={{ fontSize: "20px", fontWeight: "bold" }}>
//                       Audio :{" "}
//                     </span>{" "}
//                     &nbsp; &nbsp; &nbsp;
//                     <span>
//                       {state.record.audio ? (
//                         <audio
//                           src={`https://testincub2.qspiders.com/api/v1/get_audio?name=${state.record.audio}`}
//                           controls
//                           controlsList="nodownload"
//                         />
//                       ) : (
//                         "NA"
//                       )}
//                     </span>
//                   </div>
//                   <Grid
//                     container
//                     spacing={2}
//                     style={{ width: "90%", margin: "0 auto" }}
//                   >
//                     {mainData.map((value, index) => {
//                       return (
//                         <>
//                           {value.points === "" || value.points === null ? (
//                             <Grid item sm={12}>
//                               <p
//                                 style={{
//                                   color: "red",
//                                   fontSize: "20px",
//                                   fontWeight: "bold",
//                                   textAlign: "center",
//                                   width: "100%",
//                                   // background: "#DDE6ED",
//                                 }}
//                               >
//                                 No Points Data
//                               </p>
//                             </Grid>
//                           ) : (
//                             <Grid item sm={3}>
//                               <Button
//                                 variant="outlined"
//                                 color={value.selected ? "secondary" : "primary"}
//                                 fullWidth
//                                 style={{
//                                   textTransform: "none",
//                                   borderRadius: "10px",
//                                   backgroundColor: value.selected
//                                     ? "#4CAF50" // green when selected
//                                     : undefined, // default background color
//                                   color: value.selected ? "#ffffff" : undefined,
//                                 }}
//                                 onClick={() => handleButtonClick(index)}
//                               >
//                                 <p style={{ textTransform: "capitalize" }}>
//                                   {value.points}
//                                 </p>
//                               </Button>
//                             </Grid>
//                           )}
//                         </>
//                       );
//                     })}
//                   </Grid>
//                   <Grid item sm={11.5}>
//                     <TextField
//                       id="outlined-comment-input"
//                       label="Comment"
//                       name="callStatusComment"
//                       type="text"
//                       disabled
//                       value={callStatusComment}
//                       onChange={(e) => {
//                         setCallStatusComment(e.target.value);
//                       }}
//                       rows={1}
//                       multiline
//                       fullWidth
//                     />
//                   </Grid>
//                 </Grid>
//                 <Grid item xs={12} style={{ marginLeft: "3%" }}>
//                   <TextField
//                     id="outlined-comment-input"
//                     label="Comment"
//                     name="comment"
//                     type="text"
//                     value={comment}
//                     onChange={(e) => {
//                       setComment(e.target.value);
//                     }}
//                     rows={2}
//                     multiline
//                     fullWidth
//                     required={isCommentRequired}
//                   />
//                 </Grid>
//               </Box>
//             </Grid>

//             <Grid item container sm={12} spacing={4}>
//               <Grid item sm={2}>
//                 <Button
//                   color="primary"
//                   variant="contained"
//                   style={btnstyle1}
//                   fullWidth
//                   onClick={handleCancelClick}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//               <Grid item sm={2}>
//                 <Button
//                   type="submit"
//                   color="primary"
//                   variant="contained"
//                   style={btnstyle}
//                   fullWidth
//                 >
//                   Submit
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Box>
//   );
// };

// export default CreateCallVerification;



import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Avatar, Button, Grid, Paper, TextField, Box } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../apis/AxiosInstance";

const CreateCallVerification = () => {
  let { state } = useLocation();

  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [callStatusComment, setCallStatusComment] = useState(
    state?.record?.call_status?.call_status?.comment ?? "NA"
  );
  const [pointsData, setPointsData] = useState("");
  const [mainData, setMainData] = useState([
    {
      id: null,
      points: "",
      pointsWeight: "",
      pointsMarks: "",
      selected: false,
    },
  ]);

  const handleButtonClick = (index) => {
    const updatedMainData = mainData.map((item, idx) => {
      if (idx === index) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setMainData(updatedMainData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let cData =
      pointsData && pointsData.length > 0 ? pointsData.map((e) => e.ID) : 0;

    // Construct the payload object
    const payload = {
      rid: state.record.id,
      csid: Number(cData),
      comment: comment,
      points:
        cData === 0
          ? [{}]
          : mainData.map(({ id, points, pointsMarks, selected }) => ({
              id,
              point: points,
              marks: parseInt(pointsMarks),
              pass: selected, // pass is true if the button is selected, false otherwise
            })),
    };

    try {
      await AxiosInstance.post(`/call_record_verify`, payload);
      toast.success("Verified successfully.");
      setTimeout(() => {
        // navigate("/no_verifier_call_record_list");
        window.history.back();
      }, 1500);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error || "Something went wrong.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "1150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#40679E" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#40679E",
    color: "#fff",
    border: "1px solid #40679E",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  // const handleCancelClick = () => {
  //   navigate("/no_verifier_call_record_list", {
  //     state: { page: state.page || 1 },
  //   });
  // };

  const handleCancelClick = () => {
    window.history.back();
  };

  useEffect(() => {
    let fetchData = async () => {
      try {
        let key = state.record.id;
        let { data } = await AxiosInstance.get(
          `/get_points_and_script?call_record_id=${key}`
        );
        let finalData = data.data;

        if (finalData && finalData.length > 0) {
          setPointsData(finalData);
          setMainData(
            finalData[0].points && finalData[0].points.length > 0
              ? finalData[0].points.map((e) => ({
                  id: e.id,
                  points: e.point,
                  pointsWeight: e.weight,
                  pointsMarks: e.marks,
                  selected: false,
                }))
              : [
                  {
                    id: null,
                    points: "",
                    pointsWeight: "",
                    pointsMarks: "",
                    selected: false,
                  },
                ]
          );
        } else {
          // Handle the case where finalData is empty
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [state.id]);

  const isCommentRequired = mainData.some(
    (value) => value.points === "" || value.points === null
  );

  return (
    <Box
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Verify Call Record</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box
                style={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Grid
                  item
                  container
                  spacing={2}
                  style={{
                    border: "1px solid #06113C",
                    marginTop: "5px",
                    margin: "0 auto",
                    marginLeft: "2%",
                    paddingBottom: "15px",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      margin: "0 auto",
                      marginTop: "1%",
                    }}
                  >
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Audio :{" "}
                    </span>{" "}
                    &nbsp; &nbsp; &nbsp;
                    <span>
                      {state.record.audio ? (
                        <audio
                          src={`https://incubationb.qspiders.com/api/v1/get_audio?name=${state.record.audio}`}
                          controls
                          controlsList="nodownload"
                        />
                      ) : (
                        "NA"
                      )}
                    </span>
                  </div>
                  <Grid
                    container
                    spacing={2}
                    style={{ width: "90%", margin: "0 auto" }}
                  >
                    {mainData.map((value, index) => {
                      return (
                        <>
                          {value.points === "" || value.points === null ? (
                            <Grid item sm={12}>
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  width: "100%",
                                  // background: "#DDE6ED",
                                }}
                              >
                                No Points Data
                              </p>
                            </Grid>
                          ) : (
                            <Grid item sm={3}>
                              <Button
                                variant="outlined"
                                color={value.selected ? "secondary" : "primary"}
                                fullWidth
                                style={{
                                  textTransform: "none",
                                  borderRadius: "10px",
                                  backgroundColor: value.selected
                                    ? "#4CAF50" // green when selected
                                    : undefined, // default background color
                                  color: value.selected ? "#ffffff" : undefined,
                                }}
                                onClick={() => handleButtonClick(index)}
                              >
                                <p style={{ textTransform: "capitalize" }}>
                                  {value.points}
                                </p>
                              </Button>
                            </Grid>
                          )}
                        </>
                      );
                    })}
                  </Grid>
                  <Grid item sm={11.5}>
                    <TextField
                      id="outlined-comment-input"
                      label="Comment"
                      name="callStatusComment"
                      type="text"
                      disabled
                      value={callStatusComment}
                      onChange={(e) => {
                        setCallStatusComment(e.target.value);
                      }}
                      rows={1}
                      multiline
                      fullWidth
                      // sx={{
                      //   "& .MuiInputBase-root": {
                      //     color: "black",
                      //     fontWeight: "bold",
                      //   },
                      //   "& .MuiInputBase-input.Mui-disabled": {
                      //     "-webkit-text-fill-color": "black",
                      //   },
                      //   "& .MuiInputLabel-root": {
                      //     color: "black",
                      //   },
                      //   "& .MuiInputLabel-root.Mui-focused": {
                      //     color: "blue",
                      //   },
                      //   "& .MuiInputLabel-root.Mui-disabled": {
                      //     color: "black",
                      //   },
                      // }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginLeft: "3%" }}>
                  <TextField
                    id="outlined-comment-input"
                    label="Comment"
                    name="comment"
                    type="text"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    rows={2}
                    multiline
                    fullWidth
                    required={isCommentRequired}
                  />
                </Grid>
              </Box>
            </Grid>

            <Grid item container sm={12} spacing={4}>
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default CreateCallVerification;

