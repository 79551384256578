import * as React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { useState } from "react";
import { useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Modal, Button } from "@mui/material";
import { PostContext } from "../bdTree/ContextApi";
import AxiosInstance from "../../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import Styles from "../spiltingBdTree/tree.module.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}></Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "#fff",
    minWidth: "maxContent",
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    // backgroundColor: "#F6CF9B",
    fill: "#58D68D",
    color: "#D2B",
  },
}));
function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 9l6 6 6-6" />
      </svg>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      tslint:disable-next-line: max-line-length
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </SvgIcon>
  );
}
function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = styled(TreeItem)(({ rootNode }) => {
  const borderColor = "gray";

  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 28.5,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #081F37` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #081F37`,
    },
  };
});
const StyledTreeItem1 = styled(TreeItem)(({ rootNode }) => {
  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 50,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #27374D` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #27374D`,
    },
  };
});

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
  p: 1,
};

let TreeBranchJourney = () => {
  const {
    expanded,
    setExpanded,
    country,
    setCountry,
    states,
    setStates,
    district,
    setDistrict,
    colleges,
    setColleges,
    appointments,
    setAppointments,
    stateURL,
    districtURL,
    collegeURL,
    appointmentURL,
    countryIDx,
    setCountryIDx,
    appointmentStateFilter,
    setAppointmentStateFilter,
    appointmentDistrictFilter,
    setAppointmentDistrictFilter,
    appointmentCollegeFilter,
    setAppointmentCollegeFilter,
    setAppointmentDataFilter,
    appointmentDataFilter,
    countryURL,
  } = React.useContext(PostContext);
  const compareObjects = (obj1, obj2) => {
    return obj1.id === obj2.id && obj1.name === obj2.name;
  };
  // //! image Preview
  let [openModel, setOpenModel] = useState(false);
  let [state_ID, setState_ID] = useState(0);
  let [district_ID, setDistrict_ID] = useState(0);
  let [college_ID, setCollege_ID] = useState(0);
  let [imageAddress, setImageAddress] = useState("");
  const [selectedAppointments, setSelectedAppointments] = useState("");
  let [state_page, setState_page] = useState(1);
  let [district_page, setDistrict_page] = useState(1);
  let [districtLength, setDistrict_length] = useState(1);
  //!COLLEGE PAGE LIMIT
  let [collegePage, setCollegePage] = useState(1);
  let [appointmentPage, setAppointmentsPage] = useState(1);

  //state
  let updateState_ID = new_ID => {
    setDistrict_page(1);
    setState_ID(new_ID);
  };

  //District
  let updateDistrict_ID = new_ID => {
    setDistrict_ID(new_ID);
  };

  //College
  let updateCollege_ID = new_ID => {
    setCollege_ID(new_ID);
  };

  const handleAppointments = key => {
    setSelectedAppointments(key);
  };
  const handleImageModelClose = () => {
    setOpenModel(false);
    setImageAddress("");
  };

  // =====================trail incubation=========================

  let navigate = useNavigate();

  let sendPropstoAppointmentDetails = apps => {
    navigate("/appointment_details", { state: { data: apps } });
  };

  // BD user token
  const parseLog = a => {
    const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
    const { id } = userData.role[0];
    return id.toString();
  };
  const role = parseLog(Cookies.get("logged"));

  useEffect(() => {
    let fetchCountry = async () => {
      try {
        let response = await AxiosInstance.get(`${countryURL}`);
        let result = response.data.data;
        setCountry(result);
        setCountryIDx(result[0].id);
      } catch (err) {
        setCountry(null);
      }
    };
    fetchCountry();
  }, [countryURL]);

  let fetchStates = async () => {
    try {
      let { data } = await AxiosInstance.get(
        `${stateURL}&country_id=${countryIDx}&page=${state_page}`
      );
      let result =
      data.data.length > 0 && states.length > 0
        ? data.data.filter(newObj => {
            return !states.some(stateObj => compareObjects(stateObj, newObj));
          })
        : data.data;
      states.length < 1 ? setStates(result) : setStates(states.concat(result));
      setAppointmentStateFilter(!(data.filtered_count > 10));
    } catch (err) {
      console.log(err);
    }
  };
  const [loadMoreState, setLoadMoreState] = useState(false);
  const [loadMoreDistrict, setLoadMoreDistrict] = useState(false);
  const [loadMoreCollege, setLoadMoreCollege] = useState(false);
  const [loadmoreAppointment, setLoadMoreAppointment] = useState(false);
  let fetchMoreStates = async () => {
    setLoadMoreState(true);
    try {
      let { data } = await AxiosInstance.get(
        `${stateURL}&country_id=${countryIDx}&page=${state_page + 1}`
      );
      let result =
        data.data.length > 0 && states.length > 0
          ? data.data.filter(newObj => {
              return !states.some(stateObj => compareObjects(stateObj, newObj));
            })
          : data.data;
      setStates([...states, ...result]);
      setLoadMoreState(false);
      setState_page(state_page + 1);
      const dataLeft = data.filtered_count - Number(state_page + 1) * 10;
      setAppointmentStateFilter(dataLeft > 0 ? false : true);
    } catch (err) {
      toast.error(err?.response?.data?.error || "Unable to load state");
      console.log(err);
      setLoadMoreState(false);
    }
  };

  useEffect(() => {
    // district / city
    let fetchDistrict = async () => {
      setLoadMoreDistrict(true);
      try {
        const { data } = await AxiosInstance.get(
          `${districtURL}&state_id=${state_ID}&page=${district_page}`
        );
        let result =
          data.data.length > 0 && states.length > 0
            ? data.data.filter(newObj => {
                return !district.some(stateObj =>
                  compareObjects(stateObj, newObj)
                );
              })
            : data.data;
        setDistrict_length(data.filter_count);
        const dataLeft = data.filtered_count - Number(district_page) * 10;
        setAppointmentDistrictFilter(dataLeft > 0 ? false : true);
        district.length < 1
          ? setDistrict(result)
          : setDistrict(district.concat(result));
        setLoadMoreDistrict(false);
      } catch (error) {
        console.log(error);
        setLoadMoreDistrict(false);
      }
    };
    if (state_ID !== 0) {
      fetchDistrict();
    }
  }, [state_ID, districtURL, district_page]);

  useEffect(() => {
    let fetchAPI = async () => {
      setLoadMoreCollege(true);
      try {
        let {data} = await AxiosInstance.get(
          `${collegeURL}&district_id=${district_ID}&page=${collegePage}`
        );
        let result =
          data.data.length > 0 && states.length > 0
            ? data.data.filter(newObj => {
                return !colleges.some(stateObj =>
                  compareObjects(stateObj, newObj)
                );
              })
            : data.data;
        colleges.length < 1
          ? setColleges(result)
          : setColleges(colleges.concat(result));
        setLoadMoreCollege(false);
        const dataLeft = data.filtered_count - Number(collegePage) * 10;
        setAppointmentCollegeFilter(dataLeft > 0 ? false : true);
      } catch (err) {
        console.log(err);
        setLoadMoreCollege(false);
      }
    };
    if (district_ID !== 0) {
      fetchAPI();
    }
  }, [district_ID, collegeURL, collegePage]);

  useEffect(() => {
    let fetcchAPI = async () => {
      setLoadMoreAppointment(true);
      try {
        let {data} = await AxiosInstance.get(
          `${appointmentURL}&college_id=${college_ID}&page=${appointmentPage}`
        );
        let result =
          data.data.length > 0 && states.length > 0
            ? data.data.filter(newObj => {
                return !appointments.some(stateObj =>
                  compareObjects(stateObj, newObj)
                );
              })
            : data.data;
        appointments.length < 1
          ? setAppointments(result)
          : setAppointments(appointments.concat(result));
        setLoadMoreAppointment(false);
        const dataLeft = data.filtered_count - Number(appointmentPage) * 10;
        setAppointmentDataFilter(dataLeft > 0 ? false : true);
      } catch (err) {
        console.log(err);
        setLoadMoreAppointment(false);
      }
    };
    if (college_ID !== 0) {
      fetcchAPI();
    }
  }, [college_ID, appointmentPage, appointmentURL]);

  const [loadDistrict, setLoadDistrict] = useState(false);

  useEffect(() => {}, [loadDistrict]);
  useEffect(() => {}, [expanded]);

  let updateDistrictPage = new_ID => {
    setState_ID(new_ID);
    setDistrict_page(district_page + 1);
  };
  let updtaeCollegePage = e => {
    setDistrict_ID(e);
    setCollegePage(collegePage + 1);
  };

  let updateAppointmentPage = e => {
    setCollege_ID(e);
    setAppointmentsPage(appointmentPage + 1);
  };

  return (
    <div style={{ marginTop: "0%" }} id="mastreTree">
      {country && country.length > 0 ? (
        <section id="tree2">
          <Modal open={openModel} onClose={handleImageModelClose}>
            <Box sx={style} style={{ position: "relative" }}>
              <Button
                onClick={handleImageModelClose}
                variant="contained"
                color="warning"
                style={{
                  position: "absolute",
                  right: "1%",
                }}
              >
                close
              </Button>
              {imageAddress === "" ? <></> : <></>}
            </Box>
          </Modal>
          <TreeView
            aria-label="customized"
            className="treeCont"
            defaultCollapseIcon={<MinusSquare className={Styles.minusSquare} />}
            defaultExpandIcon={<PlusSquare className={Styles.plusSquare} />}
            defaultEndIcon={<CloseSquare className={Styles.closeSquare} />}
            sx={{
              flexGrow: 1,
            }}
          >
            {country && country.length > 0
              ? country.map((sub, subin) => {
                  return (
                    <TreeView
                      className="treeCont"
                      defaultCollapseIcon={
                        <MinusSquare className={Styles.minusSquare} />
                      }
                      defaultExpandIcon={
                        <PlusSquare className={Styles.plusSquare} />
                      }
                      defaultEndIcon={
                        <CloseSquare className={Styles.closeSquare} />
                      }
                      onNodeToggle={(e, x) => {
                        setExpanded(x);
                      }}
                    >
                      <StyledTreeItem
                        style={{ paddingLeft: "20px" }}
                        className="subjectBranch"
                        nodeId={`####country-${sub.id}`}
                        onClick={() => {
                          if (
                            expanded.length === 0 ||
                            !expanded.includes(`####country-${sub.id}`)
                          ) {
                            fetchStates();
                          }
                        }}
                        key={sub.id}
                        label={
                          <section
                            id="subjectBranch"
                            style={{ padding: "18.7px" }}
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                                color: "#081F37",
                                fontWeight: "700",
                                marginTop: "50px",
                                fontFamily: " 'Poppins', sans-serif",
                              }}
                            >
                              {sub.name}
                            </span>
                            &nbsp;&nbsp;
                            <span>
                              <button
                                style={{
                                  borderRadius: "40%",
                                  background: "#CBFFA9",
                                  width: "auto",
                                  border: "none",
                                  cursor: "pointer",
                                  padding: "0px 4px",
                                }}
                              >
                                {sub.appointment_count === null
                                  ? 0
                                  : sub.appointment_count}
                              </button>
                            </span>
                          </section>
                        }
                      >
                        {states.length > 0
                          ? states
                              .filter(val => val.country_id == sub.id)
                              .map((val, valin) => {
                                return (
                                  <>
                                    <StyledTreeItem
                                      style={{ paddingLeft: "20px" }}
                                      onClick={() => {
                                        updateState_ID(val.id);
                                      }}
                                      className="subjectBranch"
                                      nodeId={`state-${val.id}~country-${sub.id}`}
                                      key={val.id}
                                      label={
                                        <section
                                          id="subjectBranch"
                                          style={{
                                            padding: "16.1px",
                                            borderBottom:
                                              "2px solid transparent",
                                          }}
                                        >
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                              color: "#081F37",
                                              fontWeight: "bold",
                                              width: "15vw",
                                              fontFamily:
                                                " 'Poppins', sans-serif",
                                            }}
                                          >
                                            &nbsp; {val.name}
                                          </span>
                                          &nbsp;&nbsp;
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <button
                                              style={{
                                                borderRadius: "40%",
                                                background: "#CBFFA9",
                                                width: "auto",
                                                border: "none",
                                                cursor: "pointer",
                                                padding: "0px 4px",
                                              }}
                                            >
                                              {val.appointment_count === null
                                                ? 0
                                                : val.appointment_count}
                                            </button>
                                          </span>
                                          <span
                                            style={{ position: "relative" }}
                                          >
                                            <small
                                              style={{
                                                position: "absolute",
                                                left: "-10px",
                                              }}
                                            ></small>
                                          </span>
                                        </section>
                                      }
                                    >
                                      {district.length > 0 && !loadDistrict
                                        ? district
                                            .filter(
                                              chp => chp.state_id == val.id
                                            )
                                            .map(chp => {
                                              return (
                                                <>
                                                  <StyledTreeItem
                                                    style={{
                                                      paddingLeft: "20px",
                                                    }}
                                                    onClick={() => {
                                                      updateDistrict_ID(chp.id);
                                                    }}
                                                    nodeId={`district-${chp.id}~state-${val.id}~country-${sub.id}`}
                                                    key={"chp" + chp.id}
                                                    label={
                                                      <section
                                                        className="chapterBranch"
                                                        style={{
                                                          padding: "16.1px",
                                                          borderBottom:
                                                            "2px solid white",
                                                          color: "#081F37",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          &nbsp;
                                                          {chp.name}
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <span
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          <button
                                                            style={{
                                                              borderRadius:
                                                                "40%",
                                                              background:
                                                                "#CBFFA9",
                                                              width: "auto",
                                                              border: "none",
                                                              cursor: "pointer",
                                                              padding:
                                                                "0px 4px",
                                                            }}
                                                          >
                                                            {chp.appointment_count ===
                                                            null
                                                              ? 0
                                                              : chp.appointment_count}
                                                          </button>
                                                        </span>
                                                      </section>
                                                    }
                                                  >
                                                    {colleges.length > 0
                                                      ? colleges
                                                          .filter(
                                                            ea =>
                                                              ea.district_id ==
                                                              chp.id
                                                          )
                                                          .map((top, index) => {
                                                            return (
                                                              <>
                                                                <StyledTreeItem
                                                                  style={{
                                                                    paddingLeft:
                                                                      "20px",
                                                                  }}
                                                                  onClick={() => {
                                                                    updateCollege_ID(
                                                                      top.id
                                                                    );
                                                                  }}
                                                                  nodeId={`college-${top.id}~district-${chp.id}~state-${val.id}~country-${sub.id}`}
                                                                  key={
                                                                    "topic" +
                                                                    top.id
                                                                  }
                                                                  id={`node+${index}`}
                                                                  label={
                                                                    <section
                                                                      className="topicBranch"
                                                                      style={{
                                                                        overflow:
                                                                          "clip",
                                                                        padding:
                                                                          "16px",
                                                                        borderBottom:
                                                                          "2px solid white",
                                                                        color:
                                                                          "#081F37",
                                                                        fontWeight:
                                                                          "bold",
                                                                      }}
                                                                    >
                                                                      <Tooltip
                                                                        title={`${top.name}`}
                                                                        draggable
                                                                        placement="bottom"
                                                                        disableFocusListener
                                                                        disableTouchListener
                                                                        arrow
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontFamily:
                                                                              " 'Poppins', sans-serif",
                                                                            width:
                                                                              "5% ! important",
                                                                          }}
                                                                        >
                                                                          {top.name.slice(
                                                                            0,
                                                                            25
                                                                          )}
                                                                        </span>
                                                                      </Tooltip>
                                                                      &nbsp;&nbsp;
                                                                      <span
                                                                        style={{
                                                                          textTransform:
                                                                            "capitalize",
                                                                        }}
                                                                      >
                                                                        <button
                                                                          style={{
                                                                            borderRadius:
                                                                              "40%",
                                                                            background:
                                                                              "#CBFFA9",
                                                                            width:
                                                                              "auto",
                                                                            border:
                                                                              "none",
                                                                            cursor:
                                                                              "pointer",
                                                                            padding:
                                                                              "0px 4px",
                                                                          }}
                                                                        >
                                                                          {top.appointment_count ===
                                                                          null
                                                                            ? 0
                                                                            : top.appointment_count}
                                                                        </button>
                                                                      </span>
                                                                    </section>
                                                                  }
                                                                >
                                                                  {appointments
                                                                    ? appointments
                                                                        .filter(
                                                                          aph =>
                                                                            aph
                                                                              .ids
                                                                              .college
                                                                              .id ==
                                                                            top.id
                                                                        )
                                                                        .map(
                                                                          apps => {
                                                                            return (
                                                                              <>
                                                                                <StyledTreeItem1
                                                                                  style={{
                                                                                    paddingLeft:
                                                                                      "20px",
                                                                                  }}
                                                                                  nodeId={`appointment-${apps.id}~college-${top.id}~district-${chp.id}~state-${val.id}~country-${sub.id}`}
                                                                                  key={
                                                                                    "apps" +
                                                                                    apps.id
                                                                                  }
                                                                                  // onClick={() => {
                                                                                  //   handleAppointments(
                                                                                  //     apps.id
                                                                                  //   );
                                                                                  //   sendPropstoAppointmentDetails(
                                                                                  //     apps
                                                                                  //   );
                                                                                  // }}
                                                                                  label={
                                                                                    <section
                                                                                      className={
                                                                                        Styles.appointmentTree
                                                                                      }
                                                                                      style={{
                                                                                        overflow:
                                                                                          "clip",
                                                                                        borderBottom:
                                                                                          "2px solid white",
                                                                                        color:
                                                                                          "#081F37",
                                                                                        fontWeight:
                                                                                          "bold",
                                                                                      }}
                                                                                    >
                                                                                      <Tooltip
                                                                                        title={`${apps.name}`}
                                                                                        draggable
                                                                                        placement="bottom"
                                                                                        disableFocusListener
                                                                                        disableTouchListener
                                                                                        arrow
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontFamily:
                                                                                              " 'Poppins', sans-serif",
                                                                                            width:
                                                                                              "5% ! important",
                                                                                            color:
                                                                                              "purple",
                                                                                          }}
                                                                                        >
                                                                                          {apps.name.slice(
                                                                                            0,
                                                                                            25
                                                                                          )}
                                                                                        </span>
                                                                                      </Tooltip>
                                                                                      <span
                                                                                        style={{
                                                                                          position:
                                                                                            "relative",
                                                                                        }}
                                                                                      >
                                                                                        <small
                                                                                          style={{
                                                                                            position:
                                                                                              "absolute",
                                                                                            left: "-30px",
                                                                                          }}
                                                                                        ></small>
                                                                                      </span>
                                                                                    </section>
                                                                                  }
                                                                                ></StyledTreeItem1>
                                                                              </>
                                                                            );
                                                                          }
                                                                        )
                                                                    : "No Appointments Found"}
                                                                  {appointmentDataFilter ===
                                                                  false ? (
                                                                    appointments &&
                                                                    appointments.length >
                                                                      0 ? (
                                                                      <Button
                                                                        onClick={() => {
                                                                          updateAppointmentPage(top.id);
                                                                        }}
                                                                        variant="text"
                                                                        size="small"
                                                                        style={{
                                                                          display:
                                                                            top.appointment_count ===
                                                                            appointments.filter(
                                                                              e =>
                                                                                e
                                                                                  .ids
                                                                                  .college
                                                                                  .id ===
                                                                                top.id
                                                                            )
                                                                              .length
                                                                              ? "none"
                                                                              : "block",
                                                                          fontSize:
                                                                            "10px",
                                                                          marginTop:
                                                                            "-23px",
                                                                          backgroundColor:
                                                                            loadmoreAppointment
                                                                              ? "grey"
                                                                              : "#2F58CD",
                                                                          padding:
                                                                            "2.5px 10px",
                                                                          variant:
                                                                            "text",
                                                                          color:
                                                                            "#fff",
                                                                          fontWeight:
                                                                            "500",
                                                                          borderRadius:
                                                                            "20px",
                                                                        }}
                                                                        disabled={
                                                                          loadmoreAppointment
                                                                        }
                                                                      >
                                                                        {loadmoreAppointment
                                                                          ? "Loading..."
                                                                          : "Load more Appointments"}
                                                                      </Button>
                                                                    ) : undefined
                                                                  ) : null}
                                                                </StyledTreeItem>
                                                              </>
                                                            );
                                                          })
                                                      : "NO Colleges Found"}
                                                    {appointmentCollegeFilter ===
                                                    false ? (
                                                      colleges.length > 0 ? (
                                                        <Button
                                                          onClick={() => {
                                                            updtaeCollegePage(chp.id);
                                                          }}
                                                          variant="text"
                                                          size="small"
                                                          style={{
                                                            display:
                                                              chp.college_count ===
                                                              colleges.filter(
                                                                e =>
                                                                  e.district_id ===
                                                                  chp.id
                                                              ).length
                                                                ? "none"
                                                                : "block",
                                                            fontSize: "10px",
                                                            marginTop: "-23px",
                                                            backgroundColor:
                                                              loadMoreCollege
                                                                ? "grey"
                                                                : "#2F58CD",
                                                            padding:
                                                              "2.5px 10px",
                                                            variant: "text",
                                                            color: "#fff",
                                                            fontWeight: "500",
                                                            borderRadius:
                                                              "20px",
                                                          }}
                                                          disabled={
                                                            loadMoreCollege
                                                          }
                                                        >
                                                          {loadMoreCollege
                                                            ? "Loading..."
                                                            : "Load more colleges"}
                                                        </Button>
                                                      ) : undefined
                                                    ) : null}
                                                  </StyledTreeItem>
                                                </>
                                              );
                                            })
                                        : "Loading...."}
                                      {appointmentDistrictFilter === false ? (
                                        district.length > 0 ? (
                                          <Button
                                            onClick={() => {
                                              updateDistrictPage(val.id);
                                            }}
                                            variant="text"
                                            size="small"
                                            style={{
                                              display:
                                                val.districts_counts ===
                                                district.filter(
                                                  e => e.state_id === val.id
                                                ).length
                                                  ? "none"
                                                  : "block",
                                              fontSize: "10px",
                                              marginTop: "-23px",
                                              backgroundColor: loadMoreDistrict
                                                ? "grey"
                                                : "#2F58CD",
                                              padding: "2.5px 10px",
                                              variant: "text",
                                              color: "#fff",
                                              fontWeight: "500",
                                              borderRadius: "20px",
                                            }}
                                            disabled={loadMoreDistrict}
                                          >
                                            {loadMoreDistrict
                                              ? "Loading...."
                                              : "Load more districts"}
                                          </Button>
                                        ) : undefined
                                      ) : null}
                                    </StyledTreeItem>
                                  </>
                                );
                              })
                          : "Loading...."}
                        {appointmentStateFilter === false ? (
                          states.length > 0 ? (
                            <Button
                              onClick={() => {
                                fetchMoreStates();
                              }}
                              variant="text"
                              size="small"
                              style={{
                                display:
                                  sub.states_count ===
                                  states.filter(e => e.country_id === sub.id)
                                    .length
                                    ? "none"
                                    : "block",
                                fontSize: "10px",
                                marginTop: "-23px",
                                backgroundColor: loadMoreState
                                  ? "grey"
                                  : "#2F58CD",
                                padding: "2.5px 10px",
                                variant: "text",
                                color: "#fff",
                                fontWeight: "500",
                                borderRadius: "20px",
                              }}
                              disabled={loadMoreState}
                            >
                              {loadMoreState
                                ? "Loading..."
                                : "Load more states"}
                            </Button>
                          ) : undefined
                        ) : null}
                      </StyledTreeItem>
                    </TreeView>
                  );
                })
              : "Loading...."}
          </TreeView>
        </section>
      ) : (
        // <Loader />
        <h4 style={{ color: "red" }}>No Data Found...</h4>
      )}
    </div>
  );
};
export default TreeBranchJourney;
