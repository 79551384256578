import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Profile from "../components/profile/Profile";
import TpoList from "../tpo/TpoAppointmentList";
import TpoAppointmentListCard from "../tpo/TpoAppointmentListCard";

const TpoRoutes = () => {
  let routes = useRoutes([
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/" /> },
    { path: "/", element: <TpoAppointmentListCard /> },
    { path: "/tpo_appointment_list", element: <TpoList /> },
    {path:"/tpo_appointment_list_card",element:<TpoAppointmentListCard/>}
  ]);
  return routes;
};

export default TpoRoutes;
