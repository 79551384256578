import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tooltip,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Pagination,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import AddressingFacultyModal from "./AddressingFacultyModal";
import Styles from "./_addressing.module.css";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddressingEditModal from "./AddressingEditModal";
import InListAddressingEditModal from "./InListAddressingEditModal";
import AddressingJoinedList from "./AddressingJoinedList";
import AddressingNotJoinedList from "./AddressingNotJoinedList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: theme.palette.common.white,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function AddressingList() {
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [addressingApi, setAddressingApi] = useState([]);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [searchDisable, setSearchDisable] = useState(false);
  let [searchFilter, setSearchFilter] = useState("");

  const [adressingStatus, setAddressingStatus] = useState("");
  const [isOffline, setIsOffline] = useState(null);

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  const [load, setLoad] = useState(false);

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [yop, setYop] = useState(null);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [date, setDate] = useState("");

  const [searchSuccess, setSearchSuccess] = useState(false);

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  // const fetchData = async (newPage = page, newIsOffline = isOffline) => {
  //   let endpoint = `/addressing?page=${newPage}`;
  //   if (newIsOffline !== null) {
  //     endpoint += `&is_offline=${newIsOffline}`;
  //   }
  //   setLoad(true);
  //   try {
  //     let { data } = await AxiosInstance.get(endpoint);
  //     let finalData = data.data;
  //     setAddressingApi(finalData);
  //     setTotalLength(data.total_length);
  //     setDefaultState({
  //       escalation_list: finalData,
  //       totalEscalation: data.total_length,
  //     });
  //     setLoad(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoad(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [page, isOffline]);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/addressing?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setAddressingApi(finalData);
        setTotalLength(data.data.total_length);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.total_length,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  // const handleChangePage = async (event, newPage) => {
  //   fetchData(newPage);
  //   setPage(newPage);
  // };

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/addressing?page=${newPage}`;
    setLoad(true);
    try {
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (yop) {
        endpoint += `&year_of_passout=${yop}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (date) {
        endpoint += `&date=${date}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (adressingStatus) {
        endpoint += `&is_offline=${adressingStatus}`;
      }
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setAddressingApi(finalData);
      setTotalLength(data.total_length);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/addressing?page=${1}`;
    setIsSearchDisabled(true);
    try {
      if (
        !yop &&
        !fromDate &&
        !toDate &&
        !unitNameData &&
        !adressingStatus &&
        !date
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }
      if (fromDate && !toDate) {
        toast.warning('Please select "To Date" before searching.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        return;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (yop) {
        endpoint += `&year_of_passout=${yop}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (date) {
        endpoint += `&date=${date}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (adressingStatus) {
        endpoint += `&is_offline=${adressingStatus}`;
      }
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setAddressingApi(finalData);
      setTotalLength(data.total_length);
      setPage(1);
      setLoad(false);
      setSearchSuccess(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  let ResetFilterData = (e) => {
    e.preventDefault();
    setYop(null);
    setUnitNameData(null);
    setFromDate("");
    setToDate("");
    setAddressingStatus("");
    window.location.href = "/addressing_list";
  };

  const handleRadioChange = (e) => {
    const newValue = e.target.value;
    if (newValue === adressingStatus) {
      setAddressingStatus("");
      setIsOffline(null);
      setPage(1);
      // fetchData(1, null);
    } else {
      setAddressingStatus(newValue);
      setIsOffline(
        newValue === "true" ? false : newValue === "false" ? true : null
      );
      setPage(1);
    }
  };

  const handleClearFilterData = () => {
    setAddressingStatus("");
    setIsOffline(null);
    setPage(1);
    // fetchData(1, null);
  };

  const totalPages = Math.ceil(totalLength / 10);

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <section style={{ width: "100%", marginTop: "4.5%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          Addressing List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
            // paddingLeft: "500px",
          }}
        >
          Total Count: {totalLength}
        </h1>

        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            // marginLeft: "25%",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "0 30px",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
        {/* <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="adressingStatus"
            value={adressingStatus}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="true"
              control={<Radio sx={{ transform: "scale(0.75)" }} />}
              // label="Online"
              label={
                <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Online
                </Typography>
              }
              name="adressingStatus"
            />
            <FormControlLabel
              value="false"
              control={<Radio sx={{ transform: "scale(0.75)" }} />}
              // label="Offline"
              label={
                <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Offline
                </Typography>
              }
              name="adressingStatus"
            />
          </RadioGroup>
        </FormControl> */}
        {/* 
        {adressingStatus && (
          <Grid style={{ paddingRight: "100px" }}>
            <Button
              onClick={handleClearFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        )} */}
      </Box>
      {filterVisible && (
        <Grid container style={{ marginTop: "0.5%" }}>
          <Grid item md={1.6}>
            <Item
              onChange={(e) => {
                setSearchUnitName(e.target.value);
              }}
            >
              <Autocomplete
                options={unitNameOption}
                size="small"
                // disabled={searchSuccess}

                value={unitNameData}
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Unit Name" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="Date"
                size="small"
                label="Date "
                name="date"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
              />
            </Item>
          </Grid>
          <Grid item md={1.6}>
            <Item>
              <Autocomplete
                size="small"
                // disabled={searchSuccess}
                options={yopOptions}
                value={yop}
                name="year_of_passout"
                onChange={(e, newyop) => {
                  setYop(newyop);
                  setIsSearchDisabled(false);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="YOP" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="From Date"
                size="small"
                label="From Date "
                name="From Date"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={fromDate}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="toDate"
                size="small"
                label="To Date "
                name="toDate"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                  setIsSearchDisabled(false);
                }}
                // disabled={searchSuccess}
                // disabled={!fromDate || searchSuccess}
                disabled={!fromDate}
              />
            </Item>
          </Grid>
          <Grid md={1.8}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="adressingStatus"
              value={adressingStatus}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="false"
                control={<Radio sx={{ transform: "scale(0.90)" }} />}
                // label="Online"
                label={
                  <Typography style={{ fontSize: "17px", fontWeight: "bold" }}>
                    Online
                  </Typography>
                }
                name="adressingStatus"
                // disabled={searchSuccess}
              />
              <FormControlLabel
                value="true"
                control={<Radio sx={{ transform: "scale(0.90)" }} />}
                // label="Offline"
                label={
                  <Typography style={{ fontSize: "17px", fontWeight: "bold" }}>
                    Offline
                  </Typography>
                }
                name="adressingStatus"
                // disabled={searchSuccess}
              />
            </RadioGroup>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              // variant="contained"
              size="small"
              // disabled={searchSuccess}
              // disabled={isSearchDisabled}
              // style={{
              //   backgroundColor: isSearchDisabled ? "gray" : "#003285",
              //   color: "#fff",
              // }}
              style={{
                backgroundColor: "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "0.7%",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}
      <TableContainer
        component={Paper}
        style={{ maxHeight: window.innerWidth > 1800 ? 680 : 490 }}
      >
        <Table
          sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead
            style={{
              color: "#081f37",
              fontWeight: "600",
            }}
          >
            <TableRow>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "100px !important",
                }}
              >
                Sl No
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "200px !important",
                }}
              >
                Addressing Start Date
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "200px !important",
                }}
              >
                Addressing End Date
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "200px !important",
                }}
              >
                Addressing Faculty
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "180px !important",
                }}
              >
                Addressing Status
              </StyledTableCell>
              {/* <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "250px !important",
                }}
              >
                Edit Addressing
              </StyledTableCell> */}
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "200px !important",
                }}
              >
                College Executive
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "180px !important",
                }}
              >
                Appointment Name
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "130px !important",
                }}
              >
                Joined Count
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "170px !important",
                }}
              >
                Not Joined Count
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "130px !important",
                }}
              >
                Assign Quiz
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "130px !important",
                }}
              >
                Edit Quiz
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "200px !important",
                }}
              >
                Quiz Start Date
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  color: "white",
                  width: "200px !important",
                }}
              >
                Quiz End Date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {load ? (
              <StyledTableRow>
                <StyledTableCell colSpan={9} align="center">
                  Loading...
                </StyledTableCell>
              </StyledTableRow>
            ) : addressingApi && addressingApi.length > 0 ? (
              addressingApi.map((row, index) => (
                <React.Fragment>
                  <StyledTableRow key={index + 1}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {calculateSlNo(index)}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {/* {row.start_date_time === null
                        ? "N/A"
                        : moment(row.start_date_time)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")} */}
                      {row.start_date_time === null
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(row.start_date_time))
                            .local()
                            .format("DD-MM-YYYY hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {row.end_date_time === null
                        ? "N/A"
                        : moment(row.end_date_time)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")} */}
                      {row.end_date_time === null
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(row.end_date_time))
                            .local()
                            .format("DD-MM-YYYY hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {Object.keys(row.addressing_faculty).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.addressing_faculty).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>{ea?.["name"]}</Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.is_offline === true ? (
                        <p style={{ color: "red" }}>Offline</p>
                      ) : (
                        <p style={{ color: "green" }}>Online</p>
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      <p>
                       
                            <InListAddressingEditModal
                              stateAddresssing={row}                            
                              status={Object.keys(row.status)[0]}
                            />
                      
                    
                      </p>
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {Object.keys(row.college_executive).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.college_executive).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>{ea?.["name"]}</Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <Box>{Object.keys(row.appointments).length}</Box>
                        <AddressingFacultyModal
                          data={row?.appointments}
                        ></AddressingFacultyModal>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{row.joined_count}</p>
                        &nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <AddressingJoinedList appointment_idx={row.id} />
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{row.not_joined_count}</p>
                        &nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <AddressingNotJoinedList appointment_idx={row.id} />
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <button
                        style={{
                          backgroundColor: `${
                            Object.keys(row.quiz_details.quizes).length > 0
                              ? "#FF6969"
                              : "#A6FF96"
                          }`,
                        }}
                        id={Styles.assignButtn}
                        onClick={() => {
                          Object.keys(row.quiz_details.quizes).length < 1
                            ? navigate(`/assign-quiz?to=1`, {
                                state: row,
                              })
                            : toast.warning("Quiz already assigned");
                        }}
                      >
                        {Object.keys(row.quiz_details.quizes).length < 1
                          ? "Assign  "
                          : "Assigned"}
                      </button>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="Edit">
                        {Object.keys(row.quiz_details.quizes).length > 0 ||
                        row.quiz_start_status === true ? (
                          <Link to="/assign-quiz?to=1&addmore=1" state={row}>
                            <FaEdit
                              fontSize={18}
                              color="#537FE7"
                              style={{
                                cursor: "pointer",
                                width: "50px",
                              }}
                            />
                          </Link>
                        ) : (
                          <div>
                            <FaEdit
                              fontSize={18}
                              color="#537FE7"
                              onClick={() => {
                                toast.info("Please assign a quiz first");
                              }}
                              style={{
                                cursor: "pointer",
                                width: "50px",
                                color: "red",
                              }}
                            />
                          </div>
                        )}
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {Object.values(row.quiz_details.quizes).map(
                        (quiz) => quiz.from_date
                      ).length === 0 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.quiz_details.quizes).map((quiz) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {moment(quiz.from_date)
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {Object.values(row.quiz_details.quizes).map(
                        (quiz) => quiz.end_date
                      ).length === 0 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.quiz_details.quizes).map((quiz) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {moment(quiz.end_date)
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={9}>
                  <h1 style={{ color: "red", textAlign: "center" }}>
                    No Data Found
                  </h1>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
        count={totalPages}
        sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
        page={page}
        onChange={handleChangePage}
      />
    </section>
  );
}
