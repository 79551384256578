// import React, { useEffect, useState } from "react";
// import AxiosInstance from "../../apis/AxiosInstance";
// import Loader from "../../pages/Loader";

// import Styles from "./profile.module.css";
// import ProfileImg from "./user.png";
// import Bdmanager from "./human.png";
// import Cookies from "js-cookie";
// import { Button } from "@mui/material";

// const parseLog = (a) => {
//   const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
//   const { id } = userData.role[0];
//   return id.toString();
// };
// const role = parseLog(Cookies.get("logged"));

// const Profile = () => {
//   const logged = Cookies.get("logged");
//   const userData = logged ? JSON.parse(logged) : null;
//   const current_role = Cookies.get("current_role");
//   const role1 = current_role ? JSON.parse(current_role).toString() : null;

//   let [profile, setProfile] = useState([]);

//   useEffect(() => {
//     let fetchData = async (e) => {
//       let profileData = await AxiosInstance.get("/user");
//       setProfile(profileData.data.data);
//     };
//     fetchData();
//   }, []);
//   return (
//     <>
//       {" "}
//       {profile.length < 1 ? (
//         <Loader />
//       ) : (
//         <section id={Styles.userProfile}>
//           <header>
//             <h1
//               style={{
//                 textAlign: "center",
//                 fontSize: "25px",
//                 letterSpacing: "1px",
//                 textTransform: "uppercase",
//               }}
//             >
//               {" "}
//               Profile Information
//             </h1>
//           </header>
//           <article>
//             <aside
//               style={{
//                 borderRight: "2px solid #DDE6ED",
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column-reverse",
//                 justifyContent: "space-around",
//                 alignItems: "center",
//               }}
//             >
//               <figure>
//                 <img
//                   src={
//                     role === "1"
//                       ? ProfileImg
//                       : role === "5"
//                       ? Bdmanager
//                       : ProfileImg
//                   }
//                   alt=""
//                   style={{ width: "100px", height: "100px" }}
//                 />
//                 <figcaption>
//                   <h3>{profile[0].full_name}</h3>
//                 </figcaption>
//                 <small>
//                   Current Role :{" "}
//                   {profile[0].role_names.filter((ee) => ee.id == role1)[0].name}
//                 </small>
//                 <Button
//                   style={{ background: "green", color: "#fff" }}
//                   onClick={() => {
//                     const role = userData.role[1].id;
//                     Cookies.set("current_role", role);
//                     setTimeout(() => {
//                       window.location.reload();
//                     }, 100);
//                   }}
//                 >
//                   Switch
//                 </Button>
//               </figure>
//             </aside>
//             <aside>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Name : </span>
//                 <h5>{profile[0].full_name}</h5>
//               </div>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Email : </span>
//                 <h5>{profile[0].emails}</h5>
//               </div>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Role : </span>
//                 <h5 style={{ marginTop: "-2%" }}>
//                   {profile[0].role_names.map((e) => (
//                     <div key={e.name}>{e.name}</div>
//                   ))}
//                 </h5>
//               </div>

//               <div>
//                 <span style={{ fontWeight: 600 }}>Contact : </span>
//                 <h5>
//                   {profile[0].mobiles === null ? "NA" : profile[0].mobiles}
//                 </h5>
//               </div>
//             </aside>
//           </article>
//         </section>
//       )}
//     </>
//   );
// };

// export default Profile;

import React, { useEffect, useState } from "react";
import AxiosInstance from "../../apis/AxiosInstance";
import Styles from "./profile.module.css";
import ProfileImg from "./user.png";
import Bdmanager from "./human.png";
import Cookies from "js-cookie";
import { Button, Modal, Box, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../../Loader/Loader";

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

const Profile = () => {
  const logged = Cookies.get("logged");
  const userData = logged ? JSON.parse(logged) : null;
  const current_role = Cookies.get("current_role");
  const role1 = current_role ? JSON.parse(current_role).toString() : null;

  const [profile, setProfile] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      let profileData = await AxiosInstance.get("/user");
      setProfile(profileData.data.data);
    };
    fetchData();
  }, []);

  return (
    <>
      {profile.length < 1 ? (
        <Loader />
      ) : (
        <section id={Styles.userProfile}>
          <header>
            <h1
              style={{
                textAlign: "center",
                fontSize: "25px",
                letterSpacing: "1px",
                textTransform: "uppercase",
              }}
            >
              Profile Information
            </h1>
          </header>
          <article>
            <aside
              style={{
                borderRight: "2px solid #DDE6ED",
                width: "100%",
                display: "flex",
                flexDirection: "column-reverse",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <figure>
                <img
                  src={
                    role === "1"
                      ? ProfileImg
                      : role === "5"
                      ? Bdmanager
                      : ProfileImg
                  }
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
                <figcaption>
                  <h3>{profile[0].full_name}</h3>
                </figcaption>
                {userData && userData.role.length > 1 && (
                  <small
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      Current Role &nbsp;&nbsp;&nbsp;:
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        // marginLeft: "-60px",
                      }}
                    >
                      {
                        profile[0].role_names.filter((ee) => ee.id == role1)[0]
                          .name
                      }
                    </span>
                  </small>
                )}

                {/* {userData && userData.role.length > 1 && (
                  <Button
                    style={{ background: "green", color: "#fff" }}
                    onClick={handleOpen}
                  >
                    Switch
                  </Button>
                )} */}
              </figure>
            </aside>
            <aside>
              <div>
                <span style={{ fontWeight: 600 }}>Name: </span>
                <h5>{profile[0].full_name}</h5>
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Email: </span>
                <h5>{profile[0].emails}</h5>
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Role: </span>
                <h5 style={{ marginTop: "-1%" }}>
                  {profile[0].role_names.map((e) => (
                    <div key={e.name}>{e.name}</div>
                  ))}
                </h5>
              </div>
              <div>
                <span style={{ fontWeight: 600 }}>Contact: </span>
                <h5>
                  {profile[0].mobiles === null ? "NA" : profile[0].mobiles}
                </h5>
              </div>
            </aside>
          </article>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CancelIcon color="error" />
              </IconButton>
              <h3 id="modal-title" style={{ color: "#003285" }}>
                Do you want to Switch?
              </h3>
              <div
                id="modal-description"
                style={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  marginTop: "10%",
                }}
              >
                {userData.role.map((r, index) => (
                  <Button
                    size="small"
                    key={index}
                    onClick={() => {
                      Cookies.set("current_role", r.id);
                      setTimeout(() => {
                        window.location.reload();
                      }, 100);
                    }}
                    style={{
                      background: "#508D4E",
                      color: "#fff",
                      border: "1px solid #F4A311",
                      gap: "10px",
                    }}
                  >
                    {r.name}
                  </Button>
                ))}
              </div>
            </Box>
          </Modal>
        </section>
      )}
    </>
  );
};

export default Profile;

// import React, { useEffect, useState } from "react";
// import AxiosInstance from "../../apis/AxiosInstance";
// import Loader from "../../pages/Loader";
// import Styles from "./profile.module.css";
// import ProfileImg from "./user.png";
// import Bdmanager from "./human.png";
// import Cookies from "js-cookie";
// import { Button } from "@mui/material";

// const parseLog = (a) => {
//   const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
//   const { id } = userData.role[0];
//   return id.toString();
// };
// const role = parseLog(Cookies.get("logged"));

// const Profile = () => {
//   const logged = Cookies.get("logged");
//   const userData = logged ? JSON.parse(logged) : null;
//   const current_role = Cookies.get("current_role");
//   const role1 = current_role ? JSON.parse(current_role).toString() : null;

//   let [profile, setProfile] = useState([]);

//   useEffect(() => {
//     let fetchData = async () => {
//       let profileData = await AxiosInstance.get("/user");
//       setProfile(profileData.data.data);
//     };
//     fetchData();
//   }, []);

//   return (
//     <>
//       {profile.length < 1 ? (
//         <Loader />
//       ) : (
//         <section id={Styles.userProfile}>
//           <header>
//             <h1
//               style={{
//                 textAlign: "center",
//                 fontSize: "25px",
//                 letterSpacing: "1px",
//                 textTransform: "uppercase",
//               }}
//             >
//               Profile Information
//             </h1>
//           </header>
//           <article>
//             <aside
//               style={{
//                 borderRight: "2px solid #DDE6ED",
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column-reverse",
//                 justifyContent: "space-around",
//                 alignItems: "center",
//               }}
//             >
//               <figure>
//                 <img
//                   src={
//                     role === "1"
//                       ? ProfileImg
//                       : role === "5"
//                       ? Bdmanager
//                       : ProfileImg
//                   }
//                   alt=""
//                   style={{ width: "100px", height: "100px" }}
//                 />
//                 <figcaption>
//                   <h3>{profile[0].full_name}</h3>
//                 </figcaption>
//                 {userData && userData.role.length > 1 && (
//                   <small>
//                     Current Role:{" "}
//                     {
//                       profile[0].role_names.filter((ee) => ee.id == role1)[0]
//                         .name
//                     }
//                   </small>
//                 )}

//                 {userData && userData.role.length > 1 && (
//                   <Button
//                     style={{ background: "green", color: "#fff" }}
//                     onClick={() => {
//                       const role = userData.role[1].id;
//                       Cookies.set("current_role", role);
//                       setTimeout(() => {
//                         window.location.reload();
//                       }, 100);
//                     }}
//                   >
//                     Switch
//                   </Button>
//                 )}
//               </figure>
//             </aside>
//             <aside>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Name: </span>
//                 <h5>{profile[0].full_name}</h5>
//               </div>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Email: </span>
//                 <h5>{profile[0].emails}</h5>
//               </div>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Role: </span>
//                 <h5 style={{ marginTop: "-2%" }}>
//                   {profile[0].role_names.map((e) => (
//                     <div key={e.name}>{e.name}</div>
//                   ))}
//                 </h5>
//               </div>
//               <div>
//                 <span style={{ fontWeight: 600 }}>Contact: </span>
//                 <h5>
//                   {profile[0].mobiles === null ? "NA" : profile[0].mobiles}
//                 </h5>
//               </div>
//             </aside>
//           </article>
//         </section>
//       )}
//     </>
//   );
// };

// export default Profile;
