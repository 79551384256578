import * as React from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
} from "@mui/material";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RiFilterOffFill } from "react-icons/ri";
import { CSVLink } from "react-csv";
import AxiosInstance from "../apis/AxiosInstance";
import Loader from "../pages/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  height: "250px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  pb: 2,
  zIndex: "1000",
  display: "flex",
  alignItems: "end",
};

export default function SameCollegeRejectlist(props) {
  let navigate = useNavigate();
  let [college_Id, setCollege_Id] = React.useState(props.college_idx);
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx
  );
  const downloadRef = React.useRef();
  let [regdStudents, setRegdStudents] = React.useState([]);
  let level = props.finalReject;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  //Search Filter
  const [searchName, setSearchName] = React.useState("");
  const [searchEmail, setSearchEmail] = React.useState("");
  const [searchMobile, setSearchMobile] = React.useState("");

  let paramValue =
    level === "1"
      ? `&country_id=${appointment_id}`
      : level === "2"
      ? `&state_id=${appointment_id}`
      : level === "3"
      ? `&district_id=${appointment_id}`
      : level === "4"
      ? `&college_id=${appointment_id}`
      : level === "5"
      ? `&appointment_id=${appointment_id}`
      : "";

  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    // { label: "ID", key: "id" },
    { label: "Full Name", key: "full_name" },
    { label: "Emails", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Whatsapp Number", key: "whatsapp_number" },
    { label: "Group Name", key: "group_name" },
    { label: "Total Marks", key: "total_marks" },
    { label: "Quiz Score", key: "total_marks_scored" },
    { label: "10th Percentage/CGPA", key: "tenth_percentage" },
    { label: "12th Percentage/CGPA", key: "twelth_percentage" },
    { label: "ITI Percentage/CGPA", key: "iti_percentage" },
    { label: "ITI Stream", key: "iti_stream" },
    { label: "Diploma Percentage/CGPA", key: "diploma_percentage" },
    { label: "Diploma Stream", key: "diploma_stream" },
    { label: "Degree Percentage/CGPA", key: "degree_percentage" },
    { label: "Degree College Name", key: "degree_college" },
    { label: "Degree Stream", key: "degree_stream" },
    { label: "Degree Type", key: "degree_type" },
    { label: "Degree Backlogs", key: "degree_backlogs" },
    { label: "Master Degree Percentage/CGPA", key: "master_degree_pecentage" },
    { label: "Master Degree College Name", key: "master_degree_college" },
    { label: "Master Degree Stream", key: "master_degree_stream" },
    { label: "Master Degree Type", key: "master_degree_type" },
    { label: "Master Degree Backlogs", key: "master_degree_backlogs" },
  ];

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${searchEmail !== "" ? "&email=" + searchEmail : ""}${
      searchMobile !== "" ? "&mobile=" + searchMobile : ""
    }`;
    try {
      let response = await AxiosInstance.get(
        `same_college_final_reject?page=${newPage}${paramValue}${
          blockFilter ? otherParams : ""
        }`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whatsapp_number: `${
            !item.whatsapp_number ||
            Object.keys(item.whatsapp_number).length === 0 ||
            item.whatsapp_number?.number === ""
              ? "N/A"
              : item.whatsapp_number.number
          }`,
          group_name: `${
            item.group_name === null || item.group_name === ""
              ? "N/A"
              : item.group_name
          }`,
          total_marks: `${
            item.quiz_total_marks === null || item.quiz_total_marks === ""
              ? 0
              : item.quiz_total_marks
          }`,
          total_marks_scored: `${
            item.quiz_scored_marks === null || item.quiz_scored_marks === ""
              ? 0
              : item.quiz_scored_marks
          }`,
          tenth_percentage: `${
            item.other_fields.student.tenth_details.tenth_mark_type_value ===
              null ||
            item.other_fields.student.tenth_details.tenth_mark_type_value === ""
              ? 0
              : item.other_fields.student.tenth_details.tenth_mark_type_value
          }`,
          twelth_percentage: `${
            item.other_fields.student.puc_details.puc_mark_type_value ===
              null ||
            item.other_fields.student.puc_details.puc_mark_type_value === ""
              ? 0
              : item.other_fields.student.puc_details.puc_mark_type_value
          }`,
          iti_percentage: `Percentage:${
            item.other_fields.student.iti_details.iti_mark_type_value ===
              null ||
            item.other_fields.student.iti_details.iti_mark_type_value === ""
              ? 0
              : item.other_fields.student.iti_details.iti_mark_type_value
          }`,
          iti_stream: `${
            item.other_fields.student.iti_details.iti_stream &&
            item.other_fields.student.iti_details.iti_stream.name
              ? item.other_fields.student.iti_details.iti_stream.name
              : item.other_fields.student.iti_details.iti_other_stream_name
              ? item.other_fields.student.iti_details.iti_other_stream_name
              : "NA"
          }`,
          diploma_percentage: `${
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === null ||
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === ""
              ? 0
              : item.other_fields.student.diploma_details
                  .diploma_mark_type_value
          }`,
          diploma_stream: `${
            item.other_fields.student.diploma_details.diploma_stream &&
            item.other_fields.student.diploma_details.diploma_stream.name
              ? item.other_fields.student.diploma_details.diploma_stream.name
              : item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              ? item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              : "NA"
          }`,
          degree_percentage: ` ${
            item.other_fields.student.degree_details.degree_mark_type_value ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type_value ===
              ""
              ? 0
              : item.other_fields.student.degree_details.degree_mark_type_value
          }`,
          degree_college: `${
            item.other_fields.student.degree_details.degree_college &&
            item.other_fields.student.degree_details.degree_college.name
              ? item.other_fields.student.degree_details.degree_college.name
              : item.other_fields.student.degree_details
                  .degree_other_college_name
              ? item.other_fields.student.degree_details
                  .degree_other_college_name
              : "NA"
          }`,
          degree_stream: `${
            item.other_fields.student.degree_details.degree_stream &&
            item.other_fields.student.degree_details.degree_stream.name
              ? item.other_fields.student.degree_details.degree_stream.name
              : item.other_fields.student.degree_details
                  .degree_other_stream_name
              ? item.other_fields.student.degree_details
                  .degree_other_stream_name
              : "NA"
          }`,
          degree_type: `${
            item.other_fields.student.degree_details.degree_type &&
            item.other_fields.student.degree_details.degree_type.name
              ? item.other_fields.student.degree_details.degree_type.name
              : item.other_fields.student.degree_details.other_degree_type
              ? item.other_fields.student.degree_details.other_degree_type
              : "NA"
          } `,
          degree_backlogs: `${
            item.other_fields.student.degree_details.degree_active_backlogs ===
              null ||
            item.other_fields.student.degree_details.degree_active_backlogs ===
              ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_active_backlogs
          }`,
          master_degree_pecentage: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === ""
              ? 0
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type_value
          }`,

          master_degree_college: `${
            item.other_fields.student.master_degree_details
              .master_degree_college &&
            item.other_fields.student.master_degree_details
              .master_degree_college.name
              ? item.other_fields.student.master_degree_details
                  .master_degree_college.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              : "NA"
          }`,

          master_degree_stream: `${
            item.other_fields.student.master_degree_details
              .master_degree_stream &&
            item.other_fields.student.master_degree_details.master_degree_stream
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_stream.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              : "NA"
          }`,
          master_degree_type: `${
            item.other_fields.student.master_degree_details
              .master_degree_type &&
            item.other_fields.student.master_degree_details.master_degree_type
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_type.name
              : item.other_fields.student.master_degree_details
                  .other_master_degree_type
              ? item.other_fields.student.master_degree_details
                  .other_master_degree_type
              : "NA"
          }`,
          master_degree_backlogs: `${
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === null ||
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_active_backlogs
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const loadAllData = async (e) => {
    const totalPage = Math.ceil(totalLength / 10);
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${searchEmail !== "" ? "&email=" + searchEmail : ""}${
      searchMobile !== "" ? "&mobile=" + searchMobile : ""
    }`;
    try {
      let arr = [];
      for (let i = 1; i <= totalPage; i++) {
        let { data } = await AxiosInstance.get(
          `/same_college_final_reject?page=${i}${paramValue}${
            blockFilter ? otherParams : ""
          }`
        );
        arr = [...arr, ...data.data];
      }
      const formattedData = arr.map((item) => ({
        // ...item,
        full_name:
          item.full_name === null || item.full_name === ""
            ? "NA"
            : item.full_name,
        email: `${
          item.emails === null || item.emails === ""
            ? "N/A"
            : item.emails.map((ea, d) => ea)
        }`,
        mobile: `${
          item.mobiles && item.mobiles.length > 0
            ? item.mobiles
                .map((mobile, index) => {
                  return mobile.length > 10 ? mobile.slice(3) : mobile;
                })
                .join(", ")
            : ""
        }`,
        whatsapp_number: `${
          !item.whatsapp_number ||
          Object.keys(item.whatsapp_number).length === 0 ||
          item.whatsapp_number?.number === ""
            ? "N/A"
            : item.whatsapp_number.number
        }`,
        group_name: `${
          item.group_name === null || item.group_name === ""
            ? "N/A"
            : item.group_name
        }`,
        total_marks: `${
          item.quiz_total_marks === null || item.quiz_total_marks === ""
            ? 0
            : item.quiz_total_marks
        }`,
        total_marks_scored: `${
          item.quiz_scored_marks === null || item.quiz_scored_marks === ""
            ? 0
            : item.quiz_scored_marks
        }`,
        tenth_percentage: `${
          item.other_fields.student.tenth_details.tenth_mark_type_value ===
            null ||
          item.other_fields.student.tenth_details.tenth_mark_type_value === ""
            ? 0
            : item.other_fields.student.tenth_details.tenth_mark_type_value
        }`,
        twelth_percentage: `${
          item.other_fields.student.puc_details.puc_mark_type_value === null ||
          item.other_fields.student.puc_details.puc_mark_type_value === ""
            ? 0
            : item.other_fields.student.puc_details.puc_mark_type_value
        }`,
        iti_percentage: `${
          item.other_fields.student.iti_details.iti_mark_type_value === null ||
          item.other_fields.student.iti_details.iti_mark_type_value === ""
            ? 0
            : item.other_fields.student.iti_details.iti_mark_type_value
        }`,
        iti_stream: `${
          item.other_fields.student.iti_details.iti_stream &&
          item.other_fields.student.iti_details.iti_stream.name
            ? item.other_fields.student.iti_details.iti_stream.name
            : item.other_fields.student.iti_details.iti_other_stream_name
            ? item.other_fields.student.iti_details.iti_other_stream_name
            : "NA"
        }`,
        diploma_percentage: `${
          item.other_fields.student.diploma_details.diploma_mark_type_value ===
            null ||
          item.other_fields.student.diploma_details.diploma_mark_type_value ===
            ""
            ? 0
            : item.other_fields.student.diploma_details.diploma_mark_type_value
        }`,
        diploma_stream: `${
          item.other_fields.student.diploma_details.diploma_stream &&
          item.other_fields.student.diploma_details.diploma_stream.name
            ? item.other_fields.student.diploma_details.diploma_stream.name
            : item.other_fields.student.diploma_details
                .diploma_other_stream_name
            ? item.other_fields.student.diploma_details
                .diploma_other_stream_name
            : "NA"
        }`,
        degree_percentage: ` ${
          item.other_fields.student.degree_details.degree_mark_type_value ===
            null ||
          item.other_fields.student.degree_details.degree_mark_type_value === ""
            ? 0
            : item.other_fields.student.degree_details.degree_mark_type_value
        }`,
        degree_college: `${
          item.other_fields.student.degree_details.degree_college &&
          item.other_fields.student.degree_details.degree_college.name
            ? item.other_fields.student.degree_details.degree_college.name
            : item.other_fields.student.degree_details.degree_other_college_name
            ? item.other_fields.student.degree_details.degree_other_college_name
            : "NA"
        }`,
        degree_stream: `${
          item.other_fields.student.degree_details.degree_stream &&
          item.other_fields.student.degree_details.degree_stream.name
            ? item.other_fields.student.degree_details.degree_stream.name
            : item.other_fields.student.degree_details.degree_other_stream_name
            ? item.other_fields.student.degree_details.degree_other_stream_name
            : "NA"
        }`,
        degree_type: `${
          item.other_fields.student.degree_details.degree_type &&
          item.other_fields.student.degree_details.degree_type.name
            ? item.other_fields.student.degree_details.degree_type.name
            : item.other_fields.student.degree_details.other_degree_type
            ? item.other_fields.student.degree_details.other_degree_type
            : "NA"
        } `,
        degree_backlogs: `${
          item.other_fields.student.degree_details.degree_active_backlogs ===
            null ||
          item.other_fields.student.degree_details.degree_active_backlogs === ""
            ? "NA"
            : item.other_fields.student.degree_details.degree_active_backlogs
        }`,
        master_degree_pecentage: `${
          item.other_fields.student.master_degree_details
            .master_degree_mark_type_value === null ||
          item.other_fields.student.master_degree_details
            .master_degree_mark_type_value === ""
            ? 0
            : item.other_fields.student.master_degree_details
                .master_degree_mark_type_value
        }`,
        master_degree_college: `${
          item.other_fields.student.master_degree_details
            .master_degree_college &&
          item.other_fields.student.master_degree_details.master_degree_college
            .name
            ? item.other_fields.student.master_degree_details
                .master_degree_college.name
            : item.other_fields.student.master_degree_details
                .master_degree_other_college_name
            ? item.other_fields.student.master_degree_details
                .master_degree_other_college_name
            : "NA"
        }`,

        master_degree_stream: `${
          item.other_fields.student.master_degree_details
            .master_degree_stream &&
          item.other_fields.student.master_degree_details.master_degree_stream
            .name
            ? item.other_fields.student.master_degree_details
                .master_degree_stream.name
            : item.other_fields.student.master_degree_details
                .master_degree_other_stream_name
            ? item.other_fields.student.master_degree_details
                .master_degree_other_stream_name
            : "NA"
        }`,
        master_degree_type: `${
          item.other_fields.student.master_degree_details.master_degree_type &&
          item.other_fields.student.master_degree_details.master_degree_type
            .name
            ? item.other_fields.student.master_degree_details.master_degree_type
                .name
            : item.other_fields.student.master_degree_details
                .other_master_degree_type
            ? item.other_fields.student.master_degree_details
                .other_master_degree_type
            : "NA"
        }`,
        master_degree_backlogs: `${
          item.other_fields.student.master_degree_details
            .master_degree_active_backlogs === null ||
          item.other_fields.student.master_degree_details
            .master_degree_active_backlogs === ""
            ? "NA"
            : item.other_fields.student.master_degree_details
                .master_degree_active_backlogs
        }`,
      }));
      setCsvData(formattedData);
      setTimeout(() => {
        downloadRef.current.link.click();
        handleClose1();
      }, 1000);
    } catch (error) {
      toast.error("Downloading Failed");
      handleClose1();
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleStateOpen = async (e, x) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `same_college_final_reject?page=${page}${paramValue}${x ? x : ""}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whatsapp_number: `${
            !item.whatsapp_number ||
            Object.keys(item.whatsapp_number).length === 0 ||
            item.whatsapp_number?.number === ""
              ? "N/A"
              : item.whatsapp_number.number
          }`,
          group_name: `${
            item.group_name === null || item.group_name === ""
              ? "N/A"
              : item.group_name
          }`,
          total_marks: `${
            item.quiz_total_marks === null || item.quiz_total_marks === ""
              ? 0
              : item.quiz_total_marks
          }`,
          total_marks_scored: `${
            item.quiz_scored_marks === null || item.quiz_scored_marks === ""
              ? 0
              : item.quiz_scored_marks
          }`,
          tenth_percentage: `${
            item.other_fields.student.tenth_details.tenth_mark_type_value ===
              null ||
            item.other_fields.student.tenth_details.tenth_mark_type_value === ""
              ? 0
              : item.other_fields.student.tenth_details.tenth_mark_type_value
          }`,
          twelth_percentage: `${
            item.other_fields.student.puc_details.puc_mark_type_value ===
              null ||
            item.other_fields.student.puc_details.puc_mark_type_value === ""
              ? 0
              : item.other_fields.student.puc_details.puc_mark_type_value
          }`,
          iti_percentage: `Percentage:${
            item.other_fields.student.iti_details.iti_mark_type_value ===
              null ||
            item.other_fields.student.iti_details.iti_mark_type_value === ""
              ? 0
              : item.other_fields.student.iti_details.iti_mark_type_value
          }`,
          iti_stream: `${
            item.other_fields.student.iti_details.iti_stream &&
            item.other_fields.student.iti_details.iti_stream.name
              ? item.other_fields.student.iti_details.iti_stream.name
              : item.other_fields.student.iti_details.iti_other_stream_name
              ? item.other_fields.student.iti_details.iti_other_stream_name
              : "NA"
          }`,
          diploma_percentage: `${
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === null ||
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === ""
              ? 0
              : item.other_fields.student.diploma_details
                  .diploma_mark_type_value
          }`,
          diploma_stream: `${
            item.other_fields.student.diploma_details.diploma_stream &&
            item.other_fields.student.diploma_details.diploma_stream.name
              ? item.other_fields.student.diploma_details.diploma_stream.name
              : item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              ? item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              : "NA"
          }`,
          degree_percentage: ` ${
            item.other_fields.student.degree_details.degree_mark_type_value ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type_value ===
              ""
              ? 0
              : item.other_fields.student.degree_details.degree_mark_type_value
          }`,
          degree_college: `${
            item.other_fields.student.degree_details.degree_college &&
            item.other_fields.student.degree_details.degree_college.name
              ? item.other_fields.student.degree_details.degree_college.name
              : item.other_fields.student.degree_details
                  .degree_other_college_name
              ? item.other_fields.student.degree_details
                  .degree_other_college_name
              : "NA"
          }`,
          degree_stream: `${
            item.other_fields.student.degree_details.degree_stream &&
            item.other_fields.student.degree_details.degree_stream.name
              ? item.other_fields.student.degree_details.degree_stream.name
              : item.other_fields.student.degree_details
                  .degree_other_stream_name
              ? item.other_fields.student.degree_details
                  .degree_other_stream_name
              : "NA"
          }`,
          degree_type: `${
            item.other_fields.student.degree_details.degree_type &&
            item.other_fields.student.degree_details.degree_type.name
              ? item.other_fields.student.degree_details.degree_type.name
              : item.other_fields.student.degree_details.other_degree_type
              ? item.other_fields.student.degree_details.other_degree_type
              : "NA"
          } `,
          degree_backlogs: `${
            item.other_fields.student.degree_details.degree_active_backlogs ===
              null ||
            item.other_fields.student.degree_details.degree_active_backlogs ===
              ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_active_backlogs
          }`,
          master_degree_pecentage: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === ""
              ? 0
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type_value
          }`,
          master_degree_college: `${
            item.other_fields.student.master_degree_details
              .master_degree_college &&
            item.other_fields.student.master_degree_details
              .master_degree_college.name
              ? item.other_fields.student.master_degree_details
                  .master_degree_college.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              : "NA"
          }`,

          master_degree_stream: `${
            item.other_fields.student.master_degree_details
              .master_degree_stream &&
            item.other_fields.student.master_degree_details.master_degree_stream
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_stream.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              : "NA"
          }`,
          master_degree_type: `${
            item.other_fields.student.master_degree_details
              .master_degree_type &&
            item.other_fields.student.master_degree_details.master_degree_type
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_type.name
              : item.other_fields.student.master_degree_details
                  .other_master_degree_type
              ? item.other_fields.student.master_degree_details
                  .other_master_degree_type
              : "NA"
          }`,
          master_degree_backlogs: `${
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === null ||
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_active_backlogs
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const resetFilters = async (e) => {
    e.preventDefault();
    setSearchName("");
    setSearchEmail("");
    setSearchMobile("");
    setBlockFilter(false);
    handleStateOpen();
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const totalPages = Math.ceil(totalLength / 10);

  const [blockFilter, setBlockFilter] = useState(false);
  const applyFilter = async () => {
    setBlockFilter(true);
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${searchEmail !== "" ? "&email=" + searchEmail : ""}${
      searchMobile !== "" ? "&mobile=" + searchMobile : ""
    }`;
    try {
      const { data } = await AxiosInstance.get(
        `/same_college_final_reject?limit=10${paramValue}${otherParams}`
      );
      if (data && data.length > 0) {
        setBlockFilter(true);
        handleStateOpen(null, `${otherParams}`);
      } else {
        toast.error("No data found for the applied filters");
        setBlockFilter(false);
      }
    } catch (error) {
      setBlockFilter(false);
      console.log(error);
    }
  };

  //Modal Data
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalState(null);
  };

  const paperStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    p: 2,
    height: "auto",
    zIndex: "999",
  };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Final Rejected Students Details">
        <button
          onClick={handleStateOpen}
          style={{
            backgroundColor: "transparent",
            border: "none",
            // boxShadow:"0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
            padding: "1px 5px",
            borderRadius: "5px",
            backgroundColor: "#DAC0A3",
            // color:"#fff",
            // outline: "none",
            cursor: "pointer",
            fontSize: "15px",
            // textDecoration: "underline",
            fontWeight: "700",
          }}
        >
          {props.counts}
        </button>
      </Tooltip>
      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Final Rejected Student Details{" "}
            <Button
              style={{ background: "green", marginLeft: "10%", color: "#fff" }}
              onClick={(e) => {
                handleOpen1();
                loadAllData();
              }}
            >
              Download CSV File
            </Button>
            <Button
              style={{
                marginLeft: "10%",
                color: "#124076",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Total Count : {totalLength}
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`Final Rejected List.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            />
          </Typography>
          <Grid
            spacing={1}
            container
            style={{
              marginTop: "5px",
              gap: "10px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              padding: "2px",
            }}
          >
            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                type="search"
                label="Search Students Name"
                placeholder="Type Atleast 3 char Name"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                disabled={blockFilter}
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                type="search"
                label="Search Students Email"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                disabled={blockFilter}
                value={searchEmail}
                onChange={(e) => {
                  setSearchEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <TextField
                size="small"
                fullWidth
                type="search"
                label="Search Mobile Number"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                disabled={blockFilter}
                value={searchMobile}
                onChange={(e) => {
                  setSearchMobile(e.target.value);
                }}
              />
            </Grid>
            {blockFilter ? (
              <Grid item md={1.8}>
                <button
                  type="button"
                  style={{
                    fontSize: "16px",
                    color: "#111",
                    padding: "4px 10px",
                    cursor: "pointer",
                    backgroundColor: "#DDE6ED",
                    borderRadius: "40px",
                    border: "3px solid #efefef",
                  }}
                  onClick={resetFilters}
                >
                  Reset Filters
                  <span>
                    <RiFilterOffFill fontSize={16} />
                  </span>
                </button>
              </Grid>
            ) : (
              <Grid
                item
                md={1.2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    padding: "4px 30px",
                    cursor: "pointer",
                    backgroundColor: "#6C9BCF",
                    borderRadius: "40px",
                    border: "2px solid #efefef",
                  }}
                  onClick={() => {
                    if (
                      searchName === "" &&
                      searchEmail === "" &&
                      searchMobile === ""
                    ) {
                      toast.warn("No filters selected");
                    } else if (searchName !== "" && searchName.length < 3) {
                      toast.warn(
                        "Type atleast 3 characters to search student by name"
                      );
                    } else {
                      applyFilter();
                    }
                  }}
                >
                  Search
                </button>
              </Grid>
            )}
          </Grid>
          <TableContainer component={Paper} sx={{ maxHeight: 410 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#124076" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                      alignItems: "center",
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                      alignItems: "center",
                    }}
                  >
                    Whatsapp Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "600px !important",
                    }}
                  >
                    Group Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Total Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Quiz Score
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    10th Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    12th Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree College Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Backlogs
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree College Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Backlogs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {calculateSlNo(id)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.full_name == null ? "NA" : value.full_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.mobiles === null || value.mobiles === ""
                            ? "N/A"
                            : value.mobiles.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {!value.whatsapp_number ||
                          Object.keys(value.whatsapp_number).length === 0 ||
                          value.whatsapp_number.number === ""
                            ? "N/A"
                            : value.whatsapp_number.number}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.emails === null || value.emails === ""
                            ? "N/A"
                            : value.emails.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            width: "600px !important",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {value.group_name === null || value.group_name === ""
                            ? "NA"
                            : value.group_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.quiz_total_marks === null ||
                          value.quiz_total_marks === ""
                            ? 0
                            : value.quiz_total_marks}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.quiz_scored_marks === null ||
                          value.quiz_scored_marks === ""
                            ? 0
                            : value.quiz_scored_marks}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.tenth_details
                            .tenth_mark_type_value === null ||
                          value.other_fields.student.tenth_details
                            .tenth_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.tenth_details
                                .tenth_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.puc_details
                            .puc_mark_type_value === null ||
                          value.other_fields.student.puc_details
                            .puc_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.puc_details
                                .puc_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details
                            .iti_mark_type_value === null ||
                          value.other_fields.student.iti_details
                            .iti_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.iti_details
                                .iti_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details.iti_stream &&
                          value.other_fields.student.iti_details.iti_stream.name
                            ? value.other_fields.student.iti_details.iti_stream
                                .name
                            : value.other_fields.student.iti_details
                                .iti_other_stream_name
                            ? value.other_fields.student.iti_details
                                .iti_other_stream_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_mark_type_value === null ||
                          value.other_fields.student.diploma_details
                            .diploma_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.diploma_details
                                .diploma_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_stream &&
                          value.other_fields.student.diploma_details
                            .diploma_stream.name
                            ? value.other_fields.student.diploma_details
                                .diploma_stream.name
                            : value.other_fields.student.diploma_details
                                .diploma_other_stream_name
                            ? value.other_fields.student.diploma_details
                                .diploma_other_stream_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_mark_type_value === null ||
                          value.other_fields.student.degree_details
                            .degree_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.degree_details
                                .degree_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_college &&
                          value.other_fields.student.degree_details
                            .degree_college.name
                            ? value.other_fields.student.degree_details
                                .degree_college.name
                            : value.other_fields.student.degree_details
                                .degree_other_college_name
                            ? value.other_fields.student.degree_details
                                .degree_other_college_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_stream &&
                          value.other_fields.student.degree_details
                            .degree_stream.name
                            ? value.other_fields.student.degree_details
                                .degree_stream.name
                            : value.other_fields.student.degree_details
                                .degree_other_stream_name
                            ? value.other_fields.student.degree_details
                                .degree_other_stream_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_type &&
                          value.other_fields.student.degree_details.degree_type
                            .name
                            ? value.other_fields.student.degree_details
                                .degree_type.name
                            : value.other_fields.student.degree_details
                                .other_degree_type
                            ? value.other_fields.student.degree_details
                                .other_degree_type
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_active_backlogs === null ||
                          value.other_fields.student.degree_details
                            .degree_active_backlogs === ""
                            ? "NA"
                            : value.other_fields.student.degree_details
                                .degree_active_backlogs}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_mark_type_value === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.master_degree_details
                                .master_degree_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_college &&
                          value.other_fields.student.master_degree_details
                            .master_degree_college.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_college.name
                            : value.other_fields.student.master_degree_details
                                .master_degree_other_college_name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_other_college_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_stream &&
                          value.other_fields.student.master_degree_details
                            .master_degree_stream.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_stream.name
                            : value.other_fields.student.master_degree_details
                                .master_degree_other_stream_name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_other_stream_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_type &&
                          value.other_fields.student.master_degree_details
                            .master_degree_type.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_type.name
                            : value.other_fields.student.master_degree_details
                                .other_master_degree_type
                            ? value.other_fields.student.master_degree_details
                                .other_master_degree_type
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_active_backlogs === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_active_backlogs === ""
                            ? "NA"
                            : value.other_fields.student.master_degree_details
                                .master_degree_active_backlogs}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={27}>
                      <h3 style={{ color: "" }}>No Data Found</h3>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Grid style={{ marginTop: "3%" }}>
              <Grid xs={12} item container style={gridContainer} align="center">
                <Paper elevation={10} style={paperStyle}>
                  <Grid align="center" item>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AiFillCloseSquare
                        fontSize={30}
                        onClick={handleCloseModal}
                        color="#FC2947"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <h2>Appointment List</h2>
                    <br />
                  </Grid>
                  <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table
                      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead sx={{ backgroundColor: "#124076" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#124076",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "100px !important",
                            }}
                          >
                            Sl.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#124076",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "700px !important",
                            }}
                          >
                            Appointment Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {modalState && Object.keys(modalState).length > 0 ? (
                          Object.entries(modalState).map((value, id) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{id + 1}</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {value[1]?.["college"]?.value || "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{ color: "#000" }}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
