import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Autocomplete,
  Grid,
  TextField,
  Tooltip,
  FormControl,
  Button,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { RiFilterOffFill } from "react-icons/ri";
import { toast } from "react-toastify";
import AxiosInstance from "../../apis/AxiosInstance";
import { Link } from "react-router-dom";
import DocTypeList from "./DocTypeList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003285",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function CampusConnectAppointmentList() {
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [yop, setYop] = useState(null);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();

  const [page, setPage] = useState(1);
  const [totalColleges, setTotalColleges] = useState(0);

  const [toastId, setToastId] = useState(null);

  // //! Fetch the college
  let [searchedColleges, SetsearchedColleges] = useState([]);
  const [load, setLoad] = useState(false);
  let [searchFilter, setSearchFilter] = useState("");
  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  const [searchSuccess, setSearchSuccess] = useState(false);

  const totalPages = Math.ceil(totalColleges / 10);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/cc_appointment?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        SetsearchedColleges(finalData);
        setTotalColleges(data.data.total_length);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.total_length,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/cc_appointment?page=${newPage}`;
    setLoad(true);
    try {
      if (yop) {
        endpoint += `&yop=${yop}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&college_id=${selectedCollege.id}`;
      }

      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.total_length);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/cc_appointment?page=${1}`;
    try {
      if (
        !yop &&
        !unitNameData &&
        !selectedColgState &&
        !selectedColgDistrict &&
        !selectedCollege
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }
      if (yop) {
        endpoint += `&yop=${yop}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }

      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&college_id=${selectedCollege.id}`;
      }

      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.total_length);
      setPage(1);
      setSearchSuccess(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchSuccess(false);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    const fetchStateData = async () => {
      let url = `/statemini?limit=10&country_id=${countryMiniId}`;
      if (stateSearch !== "") {
        url += `&search_keyword=${stateSearch}`;
      }

      const response = await AxiosInstance.get(url);
      setColgStateOptions(response.data.data);
    };

    if (countryMiniId !== 0) {
      fetchStateData();
    }
  }, [stateSearch, countryMiniId]);

  const [defaultClg, setDefaultClg] = useState([]);
  //! for college district
  // Fetch districts based on selectedColgState
  useEffect(() => {
    const fetchDistrictData = async () => {
      if (selectedColgState !== null) {
        const response = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        const data = response.data.data;
        setcolgDistrictOptions(data);
        setDefaultClg(data);
        // setSearchSuccess(true);
      }
    };

    fetchDistrictData();
  }, [selectedColgState]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchAllDistricts = async () => {
      const response = await AxiosInstance.get(`/district_mini?limit=10`);
      const data = response.data.data;
      setcolgDistrictOptions(data);
      setDefaultClg(data);
      // setSearchSuccess(true);
    };

    fetchAllDistricts();
  }, []);

  // Search colleges based on district
  const serachClg = async (searchKeyword) => {
    try {
      let url = `/district_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgState) {
        url += `&state_id=${selectedColgState.id}`;
      }

      const response = await AxiosInstance.get(url);
      setcolgDistrictOptions(response.data.data);
      // setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  const [defaultdistClg, setDefaultdistClg] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setSelectedCollegeOptions(finalcolgData);
        // setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  useEffect(() => {
    const fetchData = async () => {
      let colgData = await AxiosInstance.get(`/college_mini?limit=10`);
      let finalcolgData = colgData.data.data;
      setSelectedCollegeOptions(finalcolgData);
      // setSearchSuccess(true);
      setDefaultdistClg(finalcolgData);
    };

    fetchData();
  }, []);

  const searchdistClg = async (searchKeyword) => {
    try {
      let url = `/college_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgDistrict) {
        url += `&district_id=${selectedColgDistrict.id}`;
      }

      const response = await AxiosInstance.get(url);
      setSelectedCollegeOptions(response.data.data);
      // setSearchSuccess(true);
    } catch (error) {
      setSelectedCollegeOptions(defaultdistClg);
    }
  };

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    setYop(null);
    window.location.href = "/cc_appointment_list";
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <main style={{ marginTop: "4.5%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#003285",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          CC Appointment List
        </Box>
        <h1
          style={{
            color: "#003285",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count: {totalColleges}
        </h1>
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </Box>
      {filterVisible && (
        <Grid container style={{ marginTop: "0.5%" }} gap={1}>
          <Grid item lg={2}>
            <Item
              onChange={(e) => {
                setStateSearch(e.target.value);
              }}
            >
              <Autocomplete
                options={colgStateOptions}
                value={selectedColgState}
                size="small"
                name="state_name"
                onChange={(event, newColgState) => {
                  setSelectedColgState(newColgState);
                  setselectedColgDistrict(null);
                  setSelectedCollege(null);
                }}
                disabled={searchSuccess}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="State" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2}>
            <Item
              onChange={(e) => {
                setDistSearch(e.target.value);
              }}
            >
              <Autocomplete
                size="small"
                disabled={searchSuccess}
                options={colgDistrictOptions}
                value={selectedColgDistrict}
                name="state_name"
                onChange={(event, newColgdis) => {
                  setselectedColgDistrict(newColgdis);
                  setSelectedCollege(null);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    serachClg(e);
                  } else {
                    setcolgDistrictOptions(defaultClg);
                  }
                  setDistSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="District" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2}>
            <Item
              onChange={(e) => {
                setCollSearch(e.target.value);
              }}
            >
              <Autocomplete
                size="small"
                disabled={searchSuccess}
                options={selectedCollegeOptions}
                value={selectedCollege}
                name="college_name"
                onChange={(event, newcollege) => {
                  setSelectedCollege(newcollege);
                }}
                onInputChange={(x, e) => {
                  if (e && e.length > 0) {
                    searchdistClg(e);
                  } else {
                    setSelectedCollegeOptions(defaultdistClg);
                  }
                  setCollSearch(e);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="College" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={2.2}>
            <Item
              onChange={(e) => {
                setSearchUnitName(e.target.value);
              }}
            >
              <Autocomplete
                options={unitNameOption}
                size="small"
                disabled={searchSuccess}
                value={unitNameData}
                name="unitNameOption"
                onChange={(event, newData_xy) => {
                  setUnitNameData(newData_xy);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Unit Name" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid item md={1.5}>
            <Item>
              <Autocomplete
                size="small"
                disabled={searchSuccess}
                options={yopOptions}
                value={yop}
                name="year_of_passout"
                onChange={(e, newyop) => {
                  setYop(newyop);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="YOP" variant="outlined" />
                )}
              />
            </Item>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              // variant="contained"
              size="small"
              disabled={searchSuccess}
              style={{
                backgroundColor: searchSuccess ? "gray" : "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}
      {/* <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#495579" }}>
          Total Appointment List : {totalColleges} &nbsp;&nbsp;
        </h1>
      </Grid> */}

      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          style={{
            maxHeight: window.innerWidth > 1800 ? 680 : 490,
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#003285",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "320px !important",
                  }}
                >
                  Appointment Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  YOP
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Edit
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  District Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  University Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Expected Students
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Unit Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  Spoc Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "280px !important",
                  }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Mobile
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  Doc Type Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "600px !important",
                  }}
                >
                  File Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "400px !important",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  Comment
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={13} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : searchedColleges && searchedColleges.length > 0 ? (
                searchedColleges.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      <Tooltip title={val.name} placement="bottom">
                        <p>
                          {val.name === null || val.name === ""
                            ? "NA"
                            : val.name.slice(0, 35)}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val.yop === null || val.yop === "" ? "NA" : val.yop}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      <Tooltip title="Edit Appointment">
                        <Link to="/edit_cc_appointment" state={val}>
                          <FaEdit
                            fontSize={18}
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val && val.state && val.state.name
                        ? val.state.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val && val.district && val.district.name
                        ? val.district.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      <Tooltip title={val.college.name} placement="bottom">
                        <p>
                          {val && val.college && val.college.name
                            ? val.college.name.slice(0, 35)
                            : "NA"}
                        </p>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      <Tooltip title={val.univeristy.name} placement="bottom">
                        <p>
                          {" "}
                          {val && val.univeristy && val.univeristy.name
                            ? val.univeristy.name.slice(0, 35)
                            : "NA"}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.expected_students === null ||
                      val.expected_students === ""
                        ? 0
                        : val.expected_students}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                    >
                      {val && val.unit && val.unit.name ? val.unit.name : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Table>
                        {val && val.college_spoc
                          ? Object.values(val.college_spoc).map(
                              (spoc, index) => (
                                <TableRow key={`row-${index}`}>
                                  <TableCell
                                    style={{
                                      borderBottom: "2px solid #efefef",
                                      padding: "5px",
                                    }}
                                    align="center"
                                    sx={{ color: "#000", fontWeight: "bold" }}
                                  >
                                    {spoc.name}
                                  </TableCell>
                                </TableRow>
                              )
                            )
                          : "NA"}
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Table>
                        {val && val.college_spoc
                          ? Object.values(val.college_spoc).map((spoc, ind) => (
                              <TableRow key={`row-${ind}`}>
                                <TableCell
                                  style={{
                                    borderBottom: "2px solid #efefef",
                                    padding: "5px",
                                  }}
                                  align="center"
                                  sx={{ color: "#000", fontWeight: "bold" }}
                                >
                                  {spoc.email && spoc.email[0]
                                    ? spoc.email[0].email
                                    : "NA"}
                                </TableCell>
                              </TableRow>
                            ))
                          : "NA"}
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Table>
                        {val && val.college_spoc
                          ? Object.values(val.college_spoc).map((spoc, ind) => (
                              <TableRow key={`row-${ind}`}>
                                <TableCell
                                  style={{
                                    borderBottom: "2px solid #efefef",
                                    padding: "5px",
                                  }}
                                  align="center"
                                  sx={{ color: "#000", fontWeight: "bold" }}
                                >
                                  {spoc.mobile && spoc.mobile[0]
                                    ? spoc.mobile[0].mobile
                                    : "NA"}
                                </TableCell>
                              </TableRow>
                            ))
                          : "NA"}
                      </Table>
                    </StyledTableCell>

                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Table>
                        <TableBody>
                          {val && val.file && val.file.length > 0 ? (
                            val.file.map((file, index) => (
                              <TableRow key={index}>
                                {file && file.doc_type ? (
                                  Object.values(file.doc_type).map(
                                    (docType, ind) => (
                                      <TableCell
                                        key={ind}
                                        style={{
                                          borderBottom: "2px solid #efefef",
                                          padding: "5px",
                                        }}
                                        align="center"
                                        sx={{
                                          color: "#000",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {docType.name}
                                      </TableCell>
                                    )
                                  )
                                ) : (
                                  <TableCell
                                    colSpan={1}
                                    align="center"
                                    style={{
                                      borderBottom: "2px solid #efefef",
                                      padding: "5px",
                                    }}
                                  >
                                    NA
                                  </TableCell>
                                )}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={1} align="center">
                                NA
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Table>
                        <TableBody>
                          {val && val.file && val.file.length > 0 ? (
                            val.file.map((file, index) => (
                              <TableRow key={`row-${index}`}>
                                {/* Display file_name and DocTypeList */}
                                <TableCell
                                  style={{
                                    borderBottom: "2px solid #efefef",
                                    padding: "5px",
                                  }}
                                  align="center"
                                  sx={{ color: "#000", fontWeight: "bold" }}
                                >
                                  {file ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {file.file_name
                                          ? file.file_name.substring(16)
                                          : "NA"}
                                      </p>
                                      &nbsp;&nbsp;&nbsp;
                                      {file.file_name && (
                                        <p>
                                          <DocTypeList
                                            docfile={file.file_name}
                                          />
                                        </p>
                                      )}
                                    </div>
                                  ) : (
                                    "NA"
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                NA
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Table>
                        <TableBody>
                          {val && val.file && val.file.length > 0 ? (
                            val.file.map((file, index) => (
                              <TableRow key={`row-${index}`}>
                                {/* Display file_name and DocTypeList */}
                                <TableCell
                                  style={{
                                    borderBottom: "2px solid #efefef",
                                    padding: "5px",
                                  }}
                                  align="center"
                                  sx={{ color: "#000", fontWeight: "bold" }}
                                >
                                  {file ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>{file.status ? file.status : "NA"}</p>
                                    </div>
                                  ) : (
                                    "NA"
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                NA
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {val.comment === null || val.comment === ""
                        ? "NA"
                        : val.comment}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={13}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
