import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Grid,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import AxiosInstance from "../../apis/AxiosInstance";
import Style from "../../superAdmin/lists.module.css";
import { toast } from "react-toastify";
import HistoryForCallRecordList from "./HistoryForCallRecordList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#40679E",
    color: "#fff",
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "7px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function NoStatusHandledEscalationList() {
  const [escalation_list, setEscalation_list] = useState([]);
  let [searchFilter, setSearchFilter] = useState("");
  const [page, setPage] = useState(1);
  const [totalEscalation, setTotalEscalation] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [roleOption, setRoleOption] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [searchRoleName, setSearcRoleName] = useState("");

  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [searchTermState, setSearchTermState] = useState("");

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  const [searchDisable, setSearchDisable] = useState(false);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  const [load, setLoad] = useState(false);

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  //Unit Name
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    fetchData();
  }, [searchUnitName]);

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (searchRoleName !== "") {
  //       let stateData_x = await AxiosInstance.get(
  //         `/user_mini?role_ids=1&role_ids=5&search_keyword=${searchRoleName}&limit=10`
  //       );
  //       let finalStateData_X = stateData_x.data.data;
  //       setRoleOption(finalStateData_X);
  //     } else {
  //       let stateData_x = await AxiosInstance.get(`/user_mini?role_ids=1&role_ids=5&limit=10`);
  //       let finalStateData_X = stateData_x.data.data;
  //       setRoleOption(finalStateData_X);
  //     }
  //   };

  //   fetchData();
  // }, [searchRoleName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (searchRoleName !== "") {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=1&role_ids=5&search_keyword=${searchRoleName}&is_not_blocked=${true}&limit=10`
          );
        } else {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=1&role_ids=5&is_not_blocked=${true}&limit=10`
          );
        }
        const finalStateData_X = response.data.data || [];
        setRoleOption(finalStateData_X);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setRoleOption([]);
      }
    };

    fetchData();
  }, [searchRoleName]);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchTermState !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${searchTermState}&limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [searchTermState, countryMiniId]);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/no_status_escalation?call_handled=${true}&page=${page}`;
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setEscalation_list(finalData);
        setTotalEscalation(data.data.filtered_count);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/no_status_escalation?call_handled=${true}&page=${newPage}`;
    try {
      if (searchFilter) {
        endpoint += `&number=${searchFilter}`;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (roleData !== null) {
        endpoint += `&author_id=${roleData.id}`;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setEscalation_list(finalData);
      setTotalEscalation(data.filtered_count);
      setPage(newPage);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/no_status_escalation?call_handled=${true}&page=${1}`;
    try {
      if (
        !searchFilter &&
        !fromDate &&
        !toDate &&
        !unitNameData &&
        !roleData &&
        !selectedColgState
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }
      if (searchFilter) {
        endpoint += `&number=${searchFilter}`;
      }
      if (fromDate) {
        endpoint += `&from_date=${fromDate}`;
      }
      if (toDate) {
        endpoint += `&to_date=${toDate}`;
      }
      if (unitNameData !== null) {
        endpoint += `&unit_id=${unitNameData.ID}`;
      }
      if (roleData !== null) {
        endpoint += `&author_id=${roleData.id}`;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      setEscalation_list(finalData);
      setTotalEscalation(data.filtered_count);
      setPage(1);
      setSearchDisable(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchDisable(false);
    }
  };
  const totalPages = Math.ceil(totalEscalation / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  let handleClearFilterData = async () => {
    setSearchFilter("");
    setFromDate("");
    setToDate("");
    setUnitNameData(null);
    setSelectedColgState(null);
    setRoleData(null);
    let endpoint = `/no_status_escalation?call_handled=${true}&page=${page}`;
    try {
      const response = await AxiosInstance.get(
        `/user_mini?role_ids=1&role_ids=5&is_not_blocked=${true}&limit=10`
      );
      const initialRoleOption = response.data.data || [];
      setRoleOption(initialRoleOption);
    } catch (error) {
      console.error("Error fetching initial role data: ", error);
      setRoleOption([]);
    }

    let fetchData = async (e) => {
      //college state api
      let colgStateData = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalcolgStateData = colgStateData.data.data;
      setColgStateOptions(finalcolgStateData);
      setSelectedColgState(null);
    };
    if (countryMiniId !== 0) {
      fetchData();
    }

    try {
      let data = await AxiosInstance.get(endpoint);
      let finalData = data.data.data;
      setEscalation_list(finalData);
      setTotalEscalation(data.data.filtered_count);
      setDefaultState({
        escalation_list: finalData,
        totalEscalation: data.data.filtered_count,
      });
      if (searchFilter) setPage(1);
      setSearchDisable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "4.5%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>
        No Status Handled Escalation List
        <span
          style={{
            color: "#06113C",
            fontSize: "20px",
            marginLeft: "25%",
            fontWeight: "bold",
          }}
        >
          Total Count: {totalEscalation}
        </span>
      </h1>
      <Paper
        style={{
          width: "98%",
          margin: "0 auto",
          border: "1px solid #111",
          marginTop: "-1%",
        }}
      >
        <aside className={Style.actionBlock} style={{ marginTop: "-1%" }}>
          <div
            className={Style.inputSearchContainer}
            style={{ padding: "10px" }}
          >
            <Grid container gap={1}>
              <Grid item md={1.7}>
                <TextField
                  placeholder=" Search By Number"
                  label="Search By Number"
                  size="small"
                  value={searchFilter}
                  name="searchFilter"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  onChange={(e) => {
                    setSearchFilter(e.target.value);
                  }}
                  disabled={searchDisable}
                  // style={{ width: "250px", height: "35px" }}
                />
              </Grid>
              <Grid item md={1.5}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  id="From Date"
                  size="small"
                  label="From Date "
                  name="From Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                  disabled={searchDisable}
                />
              </Grid>
              <Grid item md={1.5}>
                {" "}
                <TextField
                  type="date"
                  fullWidth
                  id="toDate"
                  size="small"
                  label="To Date "
                  name="toDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoFocus
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  disabled={searchDisable}
                />
              </Grid>
              <Grid item md={1.6} style={{ marginTop: "-0.5%" }}>
                <Item onChange={(e) => setSearchUnitName(e.target.value)}>
                  <Autocomplete
                    options={unitNameOption}
                    disabled={searchDisable}
                    value={unitNameData}
                    size="small"
                    name="unitNameOption"
                    onChange={(event, newData_xy) => {
                      setUnitNameData(newData_xy);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Unit Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                md={1.6}
                style={{ marginTop: "-0.5%", marginLeft: "-1%" }}
              >
                <Item onChange={(e) => setSearcRoleName(e.target.value)}>
                  <Autocomplete
                    options={roleOption}
                    disabled={searchDisable}
                    value={roleData}
                    size="small"
                    name="roleOption"
                    onChange={(event, newData_xy) => {
                      setRoleData(newData_xy);
                    }}
                    getOptionLabel={(option) => option.full_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Author Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                md={1.6}
                style={{ marginTop: "-0.5%", marginLeft: "-1%" }}
              >
                <Item onChange={(e) => setSearchTermState(e.target.value)}>
                  <Autocomplete
                    required
                    size="small"
                    disabled={searchDisable}
                    options={colgStateOptions}
                    value={selectedColgState}
                    name="state_name"
                    onChange={(event, newColgState) => {
                      setSelectedColgState(newColgState);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid
                item
                // md={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button onClick={handleSearch} variant="contained" size="small">
                  Search
                </Button>
              </Grid>
              <Grid
                item
                // md={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={handleClearFilterData}
                  variant="contained"
                  color="warning"
                  size="small"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>

          {/* <p
            style={{
              fontSize: "18px",
              width: "200px",

              fontWeight: "bold",
            }}
          >
            Total Count: {totalEscalation}
          </p> */}
        </aside>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 550 }}
          style={{ marginTop: "-0.7%" }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "100px",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "250px",
                  }}
                >
                  Author Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Unit Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "150px",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Call date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Escalation Role Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Handled By
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "250px",
                  }}
                >
                  User Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px",
                  }}
                >
                  Escalation Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {escalation_list && escalation_list.length > 0 ? (
                escalation_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {" "}
                        {val &&
                        val.call_record &&
                        val.call_record.author &&
                        val.call_record.author.name
                          ? val.call_record.author.name
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {val &&
                        val.call_record &&
                        val.call_record.author &&
                        val.call_record.author.unit &&
                        Object.keys(val.call_record.author.unit).length > 0
                          ? Object.values(val.call_record.author.unit).map(
                              (unit) => {
                                return (
                                  <Box style={{ height: "22px" }}>
                                    {unit.name}
                                  </Box>
                                );
                              }
                            )
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {val &&
                        val.call_record &&
                        val.call_record.author &&
                        val.call_record.author.state &&
                        Object.keys(val.call_record.author.state).length > 0
                          ? Object.values(val.call_record.author.state).map(
                              (state) => {
                                return (
                                  <Box style={{ height: "22px" }}>
                                    {state.name}
                                  </Box>
                                );
                              }
                            )
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{val.call_record.number}</p>
                        &nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <HistoryForCallRecordList
                            call_record_history={val.call_record.number}
                          ></HistoryForCallRecordList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_record.call_date === null ||
                      val.call_record.call_date === ""
                        ? "N/A"
                        : // : moment(val.call_record.call_date)
                          moment
                            .utc(toIsoFormat(val.call_record.call_date))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {Object.keys(val.es_level).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(val.es_level).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {ea?.level.role.name}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p style={{ textTransform: "capitalize" }}>
                        {val && val.handled_by
                          ? val.handled_by.name
                            ? val.handled_by.name
                            : "NA"
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* <p style={{ textTransform: "capitalize", color: "red" }}>
                        {val.call_record &&
                        val.call_record.user_id &&
                        Object.keys(val.call_record.user_id).length > 0
                          ? Object.values(val.call_record.user_id).map((user) =>
                              user.name && user.role_id
                                ? `${Object.values(user.role_id).map(
                                    (role) => role.name
                                  )}`
                                : "Unknown"
                            )
                          : ["Unknown"]}
                      </p> */}
                        <p style={{ textTransform: "capitalize", color: "red" }}>
                        {val.call_record &&
                        val.call_record.user_id &&
                        Object.keys(val.call_record.user_id).length > 0
                          ? Object.values(val.call_record.user_id).map((user) =>
                              user.name && user.role_id !== undefined
                                ? Object.keys(user.role_id).length > 0
                                  ? Object.values(user.role_id).map(
                                      (role) => role.name
                                    )
                                  : user.from_spoc === true
                                  ? "Spoc"
                                  : "Unknown"
                                : user.from_spoc === true
                                ? "Spoc"
                                : "Unknown"
                            )
                          : ["Unknown"]}
                      </p>

                      <p style={{ textTransform: "capitalize" }}>
                        {val.call_record &&
                        val.call_record.user_id &&
                        Object.keys(val.call_record.user_id).length > 0
                          ? Object.values(val.call_record.user_id).map(
                              (user) => user.name || "Unknown"
                            )
                          : ["Unknown"]}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {Object.keys(val.es_level).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(val.es_level).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {
                                // moment(ea?.escalation_time)
                                moment
                                  .utc(toIsoFormat(ea.escalation_time))
                                  .local()
                                  .format("DD-MM-yyyy hh:mm A")
                              }
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "1%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1B4242", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
