import * as React from "react";
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
} from "@mui/material";
import { HiUserGroup } from "react-icons/hi";
import { AiFillCloseSquare } from "react-icons/ai";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { RiFilterOffFill } from "react-icons/ri";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import Loader from "../pages/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  height: "250px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  pb: 2,
  zIndex: "1000",
  display: "flex",
  alignItems: "end",
};

export default function SameCollegeQuizAttendedList(props) {
  let Navigate = useNavigate();
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx
  );
  let [addressing_id, setAddressing_id] = React.useState(props.addressing_idx);
  const downloadRef = React.useRef();
  let [regdStudents, setRegdStudents] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const [loadReg, setLoadRed] = useState(false);

  //! SearchFilter
  let [searchName, setSearchName] = useState("");
  const [searchPercentage, setSearchPercentage] = useState("");
  const [percentageFrom, setPercentageFrom] = useState("");
  const [percentageTo, setPercentageTo] = useState("");
  const [cgpaFrom, setCgpaFrom] = useState("");
  const [cgpaTo, setCgpaTo] = useState("");
  let [searchBacklogs, setSearchBacklogs] = useState("");
  let [searchTotalMarks, setSearchTotalMarks] = useState("");
  let [searchMarksRangeFrom, setSearchMarksRangeFrom] = useState("");
  const [searchMarksRangeTo, setSearchMarksRangeTo] = useState("");
  const [streamOptions, setStreamOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [streamSearch, setStreamSearch] = useState("");
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [qualificationSearch, setQualificationSearch] = useState("");

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    // { label: "ID", key: "id" },
    { label: "Full Name", key: "full_name" },
    { label: "Emails", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Whatsapp Number", key: "whattsapp_number" },
    { label: "Total Marks", key: "total_marks" },
    { label: "Quiz Score", key: "total_marks_scored" },
    { label: "10th Percentage/CGPA", key: "tenth_percentage" },
    { label: "10th Marks", key: "tenth_marks" },
    { label: "12th Percentage/CGPA", key: "twelth_percentage" },
    { label: "12th Marks", key: "twelth_marks" },
    { label: "ITI Percentage/CGPA", key: "iti_percentage" },
    { label: "ITI Marks", key: "iti_marks" },
    { label: "ITI Stream", key: "iti_stream" },
    { label: "Diploma Percentage/CGPA", key: "diploma_percentage" },
    { label: "Diploma Marks", key: "diploma_marks" },
    { label: "Diploma Stream", key: "diploma_stream" },
    { label: "Degree Percentage/CGPA", key: "degree_percentage" },
    { label: "Degree Marks", key: "degree_marks" },
    { label: "Degree College Name", key: "degree_college" },
    { label: "Degree Stream", key: "degree_stream" },
    { label: "Degree Type", key: "degree_type" },
    { label: "Degree Backlogs", key: "degree_backlogs" },
    { label: "Master Degree Percentage/CGPA", key: "master_degree_pecentage" },
    { label: "Master Degree Marks", key: "master_degree_marks" },
    { label: "Master Degree College Name", key: "master_degree_college" },
    { label: "Master Degree Stream", key: "master_degree_stream" },
    { label: "Master Degree Type", key: "master_degree_type" },
    { label: "Master Degree Backlogs", key: "master_degree_backlogs" },
  ];

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    const qualifictionParams =
      selectedQualification === null
        ? ""
        : `${
            searchPercentage !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage=" +
                searchPercentage
              : ""
          }${
            percentageFrom !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage_from=" +
                percentageFrom
              : ""
          }${
            percentageTo !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage_to=" +
                percentageTo
              : ""
          }${
            cgpaFrom !== ""
              ? "&" + selectedQualification.name + "_cgpa_from=" + cgpaFrom
              : ""
          }${
            cgpaTo !== ""
              ? "&" + selectedQualification.name + "_cgpa_to=" + cgpaTo
              : ""
          }${
            searchBacklogs !== ""
              ? "&" +
                selectedQualification.name +
                "_active_backlogs=" +
                searchBacklogs
              : ""
          }${
            selectedStream === null
              ? ""
              : "&" +
                selectedQualification.name +
                "_stream=" +
                selectedStream.name
          }`;
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${
      searchTotalMarks !== "" ? "&total_marks_scored=" + searchTotalMarks : ""
    }${
      searchMarksRangeFrom !== "" ? "&range_from=" + searchMarksRangeFrom : ""
    }${searchMarksRangeTo !== "" ? "&range_to=" + searchMarksRangeTo : ""}`;
    try {
      let response = await AxiosInstance.get(
        `quiz_attended_students?appointment_id=${appointment_id}&page=${newPage}${
          blockFilter ? qualifictionParams + otherParams : ""
        }`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whattsapp_number: `${
            Object.keys(item.whattsapp_number).length === 0 ||
            item.whattsapp_number?.number === ""
              ? "N/A"
              : item.whattsapp_number.number
          }`,
          total_marks: `${
            item.total_marks === null || item.total_marks === ""
              ? 0
              : item.total_marks
          }`,
         total_marks_scored: `${
          item.total_marks_scored === null || item.total_marks_scored === ""
            ? 0
            : item.total_marks_scored
        }`,
          tenth_percentage: `${
            item.other_fields.student.tenth_details.tenth_mark_type === null ||
            item.other_fields.student.tenth_details.tenth_mark_type === ""
              ? "NA"
              : item.other_fields.student.tenth_details.tenth_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          tenth_marks: `${
            item.other_fields.student.tenth_details.tenth_mark_type_value ===
              null ||
            item.other_fields.student.tenth_details.tenth_mark_type_value === ""
              ? 0
              : item.other_fields.student.tenth_details.tenth_mark_type_value
          }`,
          twelth_percentage: `${
            item.other_fields.student.puc_details.puc_mark_type === null ||
            item.other_fields.student.puc_details.puc_mark_type === ""
              ? "NA"
              : item.other_fields.student.puc_details.puc_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          twelth_marks: `${
            item.other_fields.student.puc_details.puc_mark_type_value ===
              null ||
            item.other_fields.student.puc_details.puc_mark_type_value === ""
              ? 0
              : item.other_fields.student.puc_details.puc_mark_type_value
          }`,
          iti_percentage: `${
            item.other_fields.student.iti_details.iti_mark_type === null ||
            item.other_fields.student.iti_details.iti_mark_type === ""
              ? "NA"
              : item.other_fields.student.iti_details.iti_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          iti_marks: `${
            item.other_fields.student.iti_details.iti_mark_type_value ===
              null ||
            item.other_fields.student.iti_details.iti_mark_type_value === ""
              ? 0
              : item.other_fields.student.iti_details.iti_mark_type_value
          }`,
          iti_stream: `${
            item.other_fields.student.iti_details.iti_stream &&
            item.other_fields.student.iti_details.iti_stream.name
              ? item.other_fields.student.iti_details.iti_stream.name
              : item.other_fields.student.iti_details.iti_other_stream_name
              ? item.other_fields.student.iti_details.iti_other_stream_name
              : "NA"
          }`,
          diploma_percentage: `${
            item.other_fields.student.diploma_details.diploma_mark_type ===
              null ||
            item.other_fields.student.diploma_details.diploma_mark_type === ""
              ? "NA"
              : item.other_fields.student.diploma_details.diploma_mark_type ===
                "1"
              ? "CGPA"
              : "Percentage"
          }`,
          diploma_marks: `${
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === null ||
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === ""
              ? 0
              : item.other_fields.student.diploma_details
                  .diploma_mark_type_value
          }`,
          diploma_stream: `${
            item.other_fields.student.diploma_details.diploma_stream &&
            item.other_fields.student.diploma_details.diploma_stream.name
              ? item.other_fields.student.diploma_details.diploma_stream.name
              : item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              ? item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              : "NA"
          }`,
          degree_percentage: ` ${
            item.other_fields.student.degree_details.degree_mark_type ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_mark_type ===
                "1"
              ? "CGPA"
              : "Percentage"
          }`,
          degree_marks: ` ${
            item.other_fields.student.degree_details.degree_mark_type_value ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type_value ===
              ""
              ? 0
              : item.other_fields.student.degree_details.degree_mark_type_value
          }`,
          degree_college: `${
            item.other_fields.student.degree_details.degree_college &&
            item.other_fields.student.degree_details.degree_college.name
              ? item.other_fields.student.degree_details.degree_college.name
              : item.other_fields.student.degree_details
                  .degree_other_college_name
              ? item.other_fields.student.degree_details
                  .degree_other_college_name
              : "NA"
          }`,
          degree_stream: `${
            item.other_fields.student.degree_details.degree_stream &&
            item.other_fields.student.degree_details.degree_stream.name
              ? item.other_fields.student.degree_details.degree_stream.name
              : item.other_fields.student.degree_details
                  .degree_other_stream_name
              ? item.other_fields.student.degree_details
                  .degree_other_stream_name
              : "NA"
          }`,
          degree_type: `${
            item.other_fields.student.degree_details.degree_type &&
            item.other_fields.student.degree_details.degree_type.name
              ? item.other_fields.student.degree_details.degree_type.name
              : item.other_fields.student.degree_details.other_degree_type
              ? item.other_fields.student.degree_details.other_degree_type
              : "NA"
          } `,
          degree_backlogs: `${
            item.other_fields.student.degree_details.degree_active_backlogs ===
              null ||
            item.other_fields.student.degree_details.degree_active_backlogs ===
              ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_active_backlogs
          }`,
          master_degree_pecentage: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          master_degree_marks: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === ""
              ? 0
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type_value
          }`,
          master_degree_college: `${
            item.other_fields.student.master_degree_details
              .master_degree_college &&
            item.other_fields.student.master_degree_details
              .master_degree_college.name
              ? item.other_fields.student.master_degree_details
                  .master_degree_college.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              : "NA"
          }`,
          master_degree_stream: `${
            item.other_fields.student.master_degree_details
              .master_degree_stream &&
            item.other_fields.student.master_degree_details.master_degree_stream
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_stream.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              : "NA"
          }`,
          master_degree_type: `${
            item.other_fields.student.master_degree_details
              .master_degree_type &&
            item.other_fields.student.master_degree_details.master_degree_type
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_type.name
              : item.other_fields.student.master_degree_details
                  .other_master_degree_type
              ? item.other_fields.student.master_degree_details
                  .other_master_degree_type
              : "NA"
          }`,
          master_degree_backlogs: `${
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === null ||
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_active_backlogs
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const loadAllData = async (data) => {
    const totalPage = Math.ceil(totalLength / 10);
    const qualifictionParams =
      selectedQualification === null
        ? ""
        : `${
            searchPercentage !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage=" +
                searchPercentage
              : ""
          }${
            percentageFrom !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage_from=" +
                percentageFrom
              : ""
          }${
            percentageTo !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage_to=" +
                percentageTo
              : ""
          }${
            cgpaFrom !== ""
              ? "&" + selectedQualification.name + "_cgpa_from=" + cgpaFrom
              : ""
          }${
            cgpaTo !== ""
              ? "&" + selectedQualification.name + "_cgpa_to=" + cgpaTo
              : ""
          }${
            searchBacklogs !== ""
              ? "&" +
                selectedQualification.name +
                "_active_backlogs=" +
                searchBacklogs
              : ""
          }${
            selectedStream === null
              ? ""
              : "&" +
                selectedQualification.name +
                "_stream=" +
                selectedStream.name
          }`;
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${
      searchTotalMarks !== "" ? "&total_marks_scored=" + searchTotalMarks : ""
    }${
      searchMarksRangeFrom !== "" ? "&range_from =" + searchMarksRangeFrom : ""
    }${searchMarksRangeTo !== "" ? "&range_to=" + searchMarksRangeTo : ""}`;
    try {
      let arr = [];
      for (let i = 1; i <= totalPage; i++) {
        let { data } = await AxiosInstance.get(
          `/quiz_attended_students?page=${i}&appointment_id=${appointment_id}${
            blockFilter ? qualifictionParams + otherParams : ""
          }`
        );
        arr = [...arr, ...data.data];
      }
      const formattedData = arr.map((item) => ({
        // ...item,
        full_name:
          item.full_name === null || item.full_name === ""
            ? "NA"
            : item.full_name,
            email: `${
              item.emails === null || item.emails === ""
                ? "N/A"
                : item.emails.map((ea, d) => ea)
            }`,
            mobile: `${
              item.mobiles && item.mobiles.length > 0
                ? item.mobiles
                    .map((mobile, index) => {
                      return mobile.length > 10 ? mobile.slice(3) : mobile;
                    })
                    .join(", ")
                : ""
            }`,
            whattsapp_number: `${
              Object.keys(item.whattsapp_number).length === 0 ||
              item.whattsapp_number?.number === ""
                ? "N/A"
                : item.whattsapp_number.number
            }`,
       total_marks: `${
              item.total_marks === null || item.total_marks === ""
                ? 0
                : item.total_marks
            }`,
           total_marks_scored: `${
            item.total_marks_scored === null || item.total_marks_scored === ""
              ? 0
              : item.total_marks_scored
          }`,
        tenth_percentage: `${
          item.other_fields.student.tenth_details.tenth_mark_type === null ||
          item.other_fields.student.tenth_details.tenth_mark_type === ""
            ? "NA"
            : item.other_fields.student.tenth_details.tenth_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        tenth_marks: `${
          item.other_fields.student.tenth_details.tenth_mark_type_value ===
            null ||
          item.other_fields.student.tenth_details.tenth_mark_type_value === ""
            ? 0
            : item.other_fields.student.tenth_details.tenth_mark_type_value
        }`,
        twelth_percentage: `${
          item.other_fields.student.puc_details.puc_mark_type === null ||
          item.other_fields.student.puc_details.puc_mark_type === ""
            ? "NA"
            : item.other_fields.student.puc_details.puc_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        twelth_marks: `${
          item.other_fields.student.puc_details.puc_mark_type_value === null ||
          item.other_fields.student.puc_details.puc_mark_type_value === ""
            ? 0
            : item.other_fields.student.puc_details.puc_mark_type_value
        }`,
        iti_percentage: `${
          item.other_fields.student.iti_details.iti_mark_type === null ||
          item.other_fields.student.iti_details.iti_mark_type === ""
            ? "NA"
            : item.other_fields.student.iti_details.iti_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        iti_marks: `${
          item.other_fields.student.iti_details.iti_mark_type_value === null ||
          item.other_fields.student.iti_details.iti_mark_type_value === ""
            ? 0
            : item.other_fields.student.iti_details.iti_mark_type_value
        }`,
        iti_stream: `${
          item.other_fields.student.iti_details.iti_stream &&
          item.other_fields.student.iti_details.iti_stream.name
            ? item.other_fields.student.iti_details.iti_stream.name
            : item.other_fields.student.iti_details.iti_other_stream_name
            ? item.other_fields.student.iti_details.iti_other_stream_name
            : "NA"
        }`,
        diploma_percentage: `${
          item.other_fields.student.diploma_details.diploma_mark_type ===
            null ||
          item.other_fields.student.diploma_details.diploma_mark_type === ""
            ? "NA"
            : item.other_fields.student.diploma_details.diploma_mark_type ===
              "1"
            ? "CGPA"
            : "Percentage"
        }`,
        diploma_marks: `${
          item.other_fields.student.diploma_details.diploma_mark_type_value ===
            null ||
          item.other_fields.student.diploma_details.diploma_mark_type_value ===
            ""
            ? 0
            : item.other_fields.student.diploma_details.diploma_mark_type_value
        }`,
        diploma_stream: `${
          item.other_fields.student.diploma_details.diploma_stream &&
          item.other_fields.student.diploma_details.diploma_stream.name
            ? item.other_fields.student.diploma_details.diploma_stream.name
            : item.other_fields.student.diploma_details
                .diploma_other_stream_name
            ? item.other_fields.student.diploma_details
                .diploma_other_stream_name
            : "NA"
        }`,
        degree_percentage: ` ${
          item.other_fields.student.degree_details.degree_mark_type === null ||
          item.other_fields.student.degree_details.degree_mark_type === ""
            ? "NA"
            : item.other_fields.student.degree_details.degree_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        degree_marks: ` ${
          item.other_fields.student.degree_details.degree_mark_type_value ===
            null ||
          item.other_fields.student.degree_details.degree_mark_type_value === ""
            ? 0
            : item.other_fields.student.degree_details.degree_mark_type_value
        }`,
        degree_college: `${
          item.other_fields.student.degree_details.degree_college &&
          item.other_fields.student.degree_details.degree_college.name
            ? item.other_fields.student.degree_details.degree_college.name
            : item.other_fields.student.degree_details.degree_other_college_name
            ? item.other_fields.student.degree_details.degree_other_college_name
            : "NA"
        }`,
        degree_stream: `${
          item.other_fields.student.degree_details.degree_stream &&
          item.other_fields.student.degree_details.degree_stream.name
            ? item.other_fields.student.degree_details.degree_stream.name
            : item.other_fields.student.degree_details.degree_other_stream_name
            ? item.other_fields.student.degree_details.degree_other_stream_name
            : "NA"
        }`,
        degree_type: `${
          item.other_fields.student.degree_details.degree_type &&
          item.other_fields.student.degree_details.degree_type.name
            ? item.other_fields.student.degree_details.degree_type.name
            : item.other_fields.student.degree_details.other_degree_type
            ? item.other_fields.student.degree_details.other_degree_type
            : "NA"
        } `,
        degree_backlogs: `${
          item.other_fields.student.degree_details.degree_active_backlogs ===
            null ||
          item.other_fields.student.degree_details.degree_active_backlogs === ""
            ? "NA"
            : item.other_fields.student.degree_details.degree_active_backlogs
        }`,
        master_degree_pecentage: `${
          item.other_fields.student.master_degree_details
            .master_degree_mark_type === null ||
          item.other_fields.student.master_degree_details
            .master_degree_mark_type === ""
            ? "NA"
            : item.other_fields.student.master_degree_details
                .master_degree_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        master_degree_marks: `${
          item.other_fields.student.master_degree_details
            .master_degree_mark_type_value === null ||
          item.other_fields.student.master_degree_details
            .master_degree_mark_type_value === ""
            ? 0
            : item.other_fields.student.master_degree_details
                .master_degree_mark_type_value
        }`,
        master_degree_college: `${
          item.other_fields.student.master_degree_details
            .master_degree_college &&
          item.other_fields.student.master_degree_details.master_degree_college
            .name
            ? item.other_fields.student.master_degree_details
                .master_degree_college.name
            : item.other_fields.student.master_degree_details
                .master_degree_other_college_name
            ? item.other_fields.student.master_degree_details
                .master_degree_other_college_name
            : "NA"
        }`,
        master_degree_stream: `${
          item.other_fields.student.master_degree_details
            .master_degree_stream &&
          item.other_fields.student.master_degree_details.master_degree_stream
            .name
            ? item.other_fields.student.master_degree_details
                .master_degree_stream.name
            : item.other_fields.student.master_degree_details
                .master_degree_other_stream_name
            ? item.other_fields.student.master_degree_details
                .master_degree_other_stream_name
            : "NA"
        }`,
        master_degree_type: `${
          item.other_fields.student.master_degree_details.master_degree_type &&
          item.other_fields.student.master_degree_details.master_degree_type
            .name
            ? item.other_fields.student.master_degree_details.master_degree_type
                .name
            : item.other_fields.student.master_degree_details
                .other_master_degree_type
            ? item.other_fields.student.master_degree_details
                .other_master_degree_type
            : "NA"
        }`,
        master_degree_backlogs: `${
          item.other_fields.student.master_degree_details
            .master_degree_active_backlogs === null ||
          item.other_fields.student.master_degree_details
            .master_degree_active_backlogs === ""
            ? "NA"
            : item.other_fields.student.master_degree_details
                .master_degree_active_backlogs
        }`,
      }));

      setCsvData(formattedData);
      setTimeout(() => {
        downloadRef.current.link.click();
        handleClose1();
      }, 1000);
    } catch (error) {
      toast.error("Downloading Failed");
      handleClose1();
    }
  };

  const handleAppointmentOpen = async (e, x) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `quiz_attended_students?page=${page}&appointment_id=${appointment_id}${
          x ? x : ""
        }`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);

        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whattsapp_number: `${
            Object.keys(item.whattsapp_number).length === 0 ||
            item.whattsapp_number?.number === ""
              ? "N/A"
              : item.whattsapp_number.number
          }`,
          total_marks: `${
              item.total_marks === null || item.total_marks === ""
                ? 0
                : item.total_marks
            }`,
           total_marks_scored: `${
            item.total_marks_scored === null || item.total_marks_scored === ""
              ? 0
              : item.total_marks_scored
          }`,
          tenth_percentage: `${
            item.other_fields.student.tenth_details.tenth_mark_type === null ||
            item.other_fields.student.tenth_details.tenth_mark_type === ""
              ? "NA"
              : item.other_fields.student.tenth_details.tenth_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          tenth_marks: `${
            item.other_fields.student.tenth_details.tenth_mark_type_value ===
              null ||
            item.other_fields.student.tenth_details.tenth_mark_type_value === ""
              ? 0
              : item.other_fields.student.tenth_details.tenth_mark_type_value
          }`,
          twelth_percentage: `${
            item.other_fields.student.puc_details.puc_mark_type === null ||
            item.other_fields.student.puc_details.puc_mark_type === ""
              ? "NA"
              : item.other_fields.student.puc_details.puc_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          twelth_marks: `${
            item.other_fields.student.puc_details.puc_mark_type_value ===
              null ||
            item.other_fields.student.puc_details.puc_mark_type_value === ""
              ? 0
              : item.other_fields.student.puc_details.puc_mark_type_value
          }`,
          iti_percentage: `${
            item.other_fields.student.iti_details.iti_mark_type === null ||
            item.other_fields.student.iti_details.iti_mark_type === ""
              ? "NA"
              : item.other_fields.student.iti_details.iti_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          iti_marks: `${
            item.other_fields.student.iti_details.iti_mark_type_value ===
              null ||
            item.other_fields.student.iti_details.iti_mark_type_value === ""
              ? 0
              : item.other_fields.student.iti_details.iti_mark_type_value
          }`,
          iti_stream: `${
            item.other_fields.student.iti_details.iti_stream &&
            item.other_fields.student.iti_details.iti_stream.name
              ? item.other_fields.student.iti_details.iti_stream.name
              : item.other_fields.student.iti_details.iti_other_stream_name
              ? item.other_fields.student.iti_details.iti_other_stream_name
              : "NA"
          }`,
          diploma_percentage: `${
            item.other_fields.student.diploma_details.diploma_mark_type ===
              null ||
            item.other_fields.student.diploma_details.diploma_mark_type === ""
              ? "NA"
              : item.other_fields.student.diploma_details.diploma_mark_type ===
                "1"
              ? "CGPA"
              : "Percentage"
          }`,
          diploma_marks: `${
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === null ||
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === ""
              ? 0
              : item.other_fields.student.diploma_details
                  .diploma_mark_type_value
          }`,
          diploma_stream: `${
            item.other_fields.student.diploma_details.diploma_stream &&
            item.other_fields.student.diploma_details.diploma_stream.name
              ? item.other_fields.student.diploma_details.diploma_stream.name
              : item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              ? item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              : "NA"
          }`,
          degree_percentage: ` ${
            item.other_fields.student.degree_details.degree_mark_type ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_mark_type ===
                "1"
              ? "CGPA"
              : "Percentage"
          }`,
          degree_marks: ` ${
            item.other_fields.student.degree_details.degree_mark_type_value ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type_value ===
              ""
              ? 0
              : item.other_fields.student.degree_details.degree_mark_type_value
          }`,
          degree_college: `${
            item.other_fields.student.degree_details.degree_college &&
            item.other_fields.student.degree_details.degree_college.name
              ? item.other_fields.student.degree_details.degree_college.name
              : item.other_fields.student.degree_details
                  .degree_other_college_name
              ? item.other_fields.student.degree_details
                  .degree_other_college_name
              : "NA"
          }`,
          degree_stream: `${
            item.other_fields.student.degree_details.degree_stream &&
            item.other_fields.student.degree_details.degree_stream.name
              ? item.other_fields.student.degree_details.degree_stream.name
              : item.other_fields.student.degree_details
                  .degree_other_stream_name
              ? item.other_fields.student.degree_details
                  .degree_other_stream_name
              : "NA"
          }`,
          degree_type: `${
            item.other_fields.student.degree_details.degree_type &&
            item.other_fields.student.degree_details.degree_type.name
              ? item.other_fields.student.degree_details.degree_type.name
              : item.other_fields.student.degree_details.other_degree_type
              ? item.other_fields.student.degree_details.other_degree_type
              : "NA"
          } `,
          degree_backlogs: `${
            item.other_fields.student.degree_details.degree_active_backlogs ===
              null ||
            item.other_fields.student.degree_details.degree_active_backlogs ===
              ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_active_backlogs
          }`,
          master_degree_pecentage: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          master_degree_marks: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === ""
              ? 0
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type_value
          }`,
          master_degree_college: `${
            item.other_fields.student.master_degree_details
              .master_degree_college &&
            item.other_fields.student.master_degree_details
              .master_degree_college.name
              ? item.other_fields.student.master_degree_details
                  .master_degree_college.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              : "NA"
          }`,
          master_degree_stream: `${
            item.other_fields.student.master_degree_details
              .master_degree_stream &&
            item.other_fields.student.master_degree_details.master_degree_stream
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_stream.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              : "NA"
          }`,
          master_degree_type: `${
            item.other_fields.student.master_degree_details
              .master_degree_type &&
            item.other_fields.student.master_degree_details.master_degree_type
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_type.name
              : item.other_fields.student.master_degree_details
                  .other_master_degree_type
              ? item.other_fields.student.master_degree_details
                  .other_master_degree_type
              : "NA"
          }`,
          master_degree_backlogs: `${
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === null ||
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_active_backlogs
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };


  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const resetFilters = async (e) => {
    e.preventDefault();
    setSearchName("");
    setSearchTotalMarks("");
    setSearchMarksRangeFrom("");
    setSearchMarksRangeTo("");
    setSelectedQualification(null);
    setSelectedStream(null);
    setSearchPercentage("");
    setPercentageFrom("");
    setPercentageTo("");
    setCgpaFrom("");
    setCgpaTo("");
    setSearchBacklogs("");
    setBlockFilter(false);
    handleAppointmentOpen();
  };

  const totalPages = Math.ceil(totalLength / 10);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        if (qualificationSearch !== "") {
          let qualificationData = await AxiosInstance.get(
            `/qualification?search_keyword=${qualificationSearch}&limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(finalQualificationData);
        } else {
          let qualificationData = await AxiosInstance.get(
            `/qualification?limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(finalQualificationData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (open) {
      fetchData1();
    }
  }, [open]);
  const [defaultStream, setDefaultStream] = useState([]);
  //! stream on selcting qualification
  const fetchStream = async (e) => {
    try {
      const { data } = await AxiosInstance.get(
        `/academic_streams?qualification_type_ids=${e}&limit=10`
      );
      setStreamOptions(data.data);
      setDefaultStream(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [blockFilter, setBlockFilter] = useState(false);
  const applyFilter = async () => {
    setBlockFilter(true);
    const qualifictionParams =
      selectedQualification === null
        ? ""
        : `${
            searchPercentage !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage=" +
                searchPercentage
              : ""
          }${
            percentageFrom !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage_from=" +
                percentageFrom
              : ""
          }${
            percentageTo !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage_to=" +
                percentageTo
              : ""
          }${
            cgpaFrom !== ""
              ? "&" + selectedQualification.name + "_cgpa_from=" + cgpaFrom
              : ""
          }${
            cgpaTo !== ""
              ? "&" + selectedQualification.name + "_cgpa_to=" + cgpaTo
              : ""
          }${
            searchBacklogs !== ""
              ? "&" +
                selectedQualification.name +
                "_active_backlogs=" +
                searchBacklogs
              : ""
          }${
            selectedStream === null
              ? ""
              : "&" +
                selectedQualification.name +
                "_stream=" +
                selectedStream.name
          }`;
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${
      searchTotalMarks !== "" ? "&total_marks_scored=" + searchTotalMarks : ""
    }${
      searchMarksRangeFrom !== "" ? "&range_from=" + searchMarksRangeFrom : ""
    }${searchMarksRangeTo !== "" ? "&range_to=" + searchMarksRangeTo : ""}`;
    try {
      const { data } = await AxiosInstance.get(
        `/quiz_attended_students?limit=10&appointment_id=${appointment_id}${qualifictionParams}${otherParams}`
      );
      if (data && data.length > 0) {
        setBlockFilter(true);
        handleAppointmentOpen(null, `${qualifictionParams}${otherParams}`);
      } else {
        toast.error("No data found for the applied filters");
        setBlockFilter(false);
      }
    } catch (error) {
      setBlockFilter(false);
      console.log(error);
    }
  };


  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  //Modal Data
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal1 = () => {
    setModalOpen1(true);
  };

  const handleCloseModal1 = () => {
    setModalOpen1(false);
    setModalState(null);
  };

  const paperStyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    p: 2,
    height: "auto",
    zIndex: "999",
  };

  return (
    <div>
      <Tooltip title="Quiz Attended Students Details">
        <button
          onClick={handleAppointmentOpen}
          style={{
            backgroundColor: "transparent",
            border: "none",
            // boxShadow:"0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
            padding:"1px 5px",
            borderRadius:"5px",
            backgroundColor:"#DAC0A3",
            // color:"#fff",
            // outline: "none",
            cursor: "pointer",
            fontSize: "15px",
            // textDecoration: "underline",
            fontWeight: "700",
          }}
        >
          {props.counts}
        </button>
      </Tooltip>
      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Quiz Attended Student Details
            <Button
              style={{ background: "green", marginLeft: "10%", color: "#fff" }}
              onClick={(e) => {
                handleOpen1();
                loadAllData();
              }}
            >
              Download CSV File
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`Attended list.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            />
          </Typography>
          <Grid
            spacing={1}
            container
            style={{
              marginTop: "5px",
              gap: "10px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              padding: "2px",
            }}
          >
            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                type="search"
                label="Search Students Name"
                placeholder="Type Atleast 3 char Name"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                disabled={blockFilter}
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={1.5}>
              <TextField
                size="small"
                fullWidth
                type="tel"
                label="Search QuizScore"
                onKeyPress={preventMinus}
                disabled={blockFilter}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 1, maxLength: 3 }}
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={searchTotalMarks}
                onChange={(e) => {
                  setSearchTotalMarks(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                type="tel"
                label="QuizScore Range From"
                onKeyPress={preventMinus}
                disabled={blockFilter}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 1, maxLength: 3 }}
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={searchMarksRangeFrom}
                onChange={(e) => {
                  setSearchMarksRangeFrom(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                type="tel"
                label="QuizScore Range To"
                onKeyPress={preventMinus}
                disabled={blockFilter}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 1, maxLength: 3 }}
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={searchMarksRangeTo}
                onChange={(e) => {
                  setSearchMarksRangeTo(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={2}>
              <Autocomplete
                size="small"
                options={qualificationOptions}
                value={selectedQualification}
                name="qualification"
                disabled={blockFilter}
                onChange={(event, newStream, x, y) => {
                  setSelectedQualification(newStream);
                  if (x === "selectOption") {
                    const qual = y.option.id;
                    fetchStream(qual);
                  }
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    disabled={blockFilter}
                    {...params}
                    label="Search Qualification"
                    variant="outlined"
                    onChange={async (e) => {
                      const term = e.target.value;
                      if (term.length > 2) {
                        const { data } = await AxiosInstance.get(
                          `/qualification?search_keyword=${term}&limit=10`
                        );
                        setQualificationOptions(data.data);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {selectedQualification && (
              <>
                <Grid item lg={2.8}>
                  <Autocomplete
                    size="small"
                    disabled={blockFilter}
                    options={streamOptions}
                    value={selectedStream}
                    name="stream_name"
                    onChange={(event, newStream) => {
                      setSelectedStream(newStream);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onFocus={() => {
                          setStreamOptions(defaultStream);
                        }}
                        onChange={async (e) => {
                          const term = e.target.value;
                          if (term.length > 1) {
                            const { data } = await AxiosInstance.get(
                              `/academic_streams?search_keyword=${term}&limit=10`
                            );
                            setStreamOptions(data.data);
                          }
                        }}
                        label="Search Stream"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    disabled={blockFilter}
                    label="Search Backlogs"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 2 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={searchBacklogs}
                    onChange={(e) => {
                      setSearchBacklogs(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    disabled={blockFilter}
                    label="Search Percentage"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={searchPercentage}
                    onChange={(e) => {
                      setSearchPercentage(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={1.5}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    disabled={blockFilter}
                    label="Percentage From"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={percentageFrom}
                    onChange={(e) => {
                      setPercentageFrom(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={1.5}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    disabled={blockFilter}
                    label="Percentage To"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={percentageTo}
                    onChange={(e) => {
                      setPercentageTo(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={1.5}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    disabled={blockFilter}
                    label="CGPA From"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={cgpaFrom}
                    onChange={(e) => {
                      setCgpaFrom(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={1.5}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    disabled={blockFilter}
                    label="CGPA To"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={cgpaTo}
                    onChange={(e) => {
                      setCgpaTo(e.target.value);
                    }}
                  />
                </Grid>
              </>
            )}
            {blockFilter ? (
              <Grid item md={1.8}>
                <button
                  type="button"
                  style={{
                    fontSize: "16px",
                    color: "#111",
                    padding: "4px 10px",
                    cursor: "pointer",
                    backgroundColor: "#DDE6ED",
                    borderRadius: "40px",
                    border: "3px solid #efefef",
                  }}
                  onClick={resetFilters}
                >
                  Reset Filters
                  <span>
                    <RiFilterOffFill fontSize={16} />
                  </span>
                </button>
              </Grid>
            ) : (
              <Grid
                item
                md={1.2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    padding: "4px 30px",
                    cursor: "pointer",
                    backgroundColor: "#6C9BCF",
                    borderRadius: "40px",
                    border: "2px solid #efefef",
                  }}
                  onClick={() => {
                    if (
                      searchName === "" &&
                      searchBacklogs === "" &&
                      searchPercentage === "" &&
                      percentageFrom === "" &&
                      percentageTo === "" &&
                      cgpaFrom === "" &&
                      cgpaTo === "" &&
                      selectedStream === null &&
                      selectedQualification === null &&
                      searchTotalMarks === "" &&
                      searchMarksRangeFrom === "" &&
                      searchMarksRangeTo === ""
                    ) {
                      toast.warn("No filters selected");
                    } else if (searchName !== "" && searchName.length < 3) {
                      toast.warn(
                        "Type atleast 3 characters to search student by name"
                      );
                    } else {
                      applyFilter();
                    }
                  }}
                >
                  Search
                </button>
              </Grid>
            )}
          </Grid>
          <h3
            style={{ marginLeft: "45%", marginTop: "0.5%", color: "#124076" }}
          >
            Total Count : {totalLength}
          </h3>
          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#124076" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                      alignItems: "center",
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                      alignItems: "center",
                    }}
                  >
                    Whatsapp Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Email
                  </TableCell>                
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Total Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Quiz Score
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    10th Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    10th Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    12th Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    12th Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree College Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Backlogs
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree College Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#124076",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Backlogs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {calculateSlNo(id)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.full_name == null ? "NA" : value.full_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.mobiles === null || value.mobiles === ""
                            ? "N/A"
                            : value.mobiles.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {Object.keys(value.whattsapp_number).length === 0 ||
                          value.whattsapp_number?.number === ""
                            ? "N/A"
                            : value.whattsapp_number.number}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.emails === null || value.emails === ""
                            ? "N/A"
                            : value.emails.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                           {value.total_marks === null ||
                          value.total_marks === ""
                            ? 0
                            : value.total_marks}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                           {value.total_marks_scored === null ||
                          value.total_marks_scored === ""
                            ? 0
                            : value.total_marks_scored}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.tenth_details
                            .tenth_mark_type === null ||
                          value.other_fields.student.tenth_details
                            .tenth_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.tenth_details
                                .tenth_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.tenth_details
                            .tenth_mark_type_value === null ||
                          value.other_fields.student.tenth_details
                            .tenth_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.tenth_details
                                .tenth_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.puc_details
                            .puc_mark_type === null ||
                          value.other_fields.student.puc_details
                            .puc_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.puc_details
                                .puc_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.puc_details
                            .puc_mark_type_value === null ||
                          value.other_fields.student.puc_details
                            .puc_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.puc_details
                                .puc_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details
                            .iti_mark_type === null ||
                          value.other_fields.student.iti_details
                            .iti_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.iti_details
                                .iti_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details
                            .iti_mark_type_value === null ||
                          value.other_fields.student.iti_details
                            .iti_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.iti_details
                                .iti_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details.iti_stream &&
                          value.other_fields.student.iti_details.iti_stream.name
                            ? value.other_fields.student.iti_details.iti_stream
                                .name
                            : value.other_fields.student.iti_details
                                .iti_other_stream_name
                            ? value.other_fields.student.iti_details
                                .iti_other_stream_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_mark_type === null ||
                          value.other_fields.student.diploma_details
                            .diploma_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.diploma_details
                                .diploma_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_mark_type_value === null ||
                          value.other_fields.student.diploma_details
                            .diploma_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.diploma_details
                                .diploma_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_stream &&
                          value.other_fields.student.diploma_details
                            .diploma_stream.name
                            ? value.other_fields.student.diploma_details
                                .diploma_stream.name
                            : value.other_fields.student.diploma_details
                                .diploma_other_stream_name
                            ? value.other_fields.student.diploma_details
                                .diploma_other_stream_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_mark_type === null ||
                          value.other_fields.student.degree_details
                            .degree_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.degree_details
                                .degree_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_mark_type_value === null ||
                          value.other_fields.student.degree_details
                            .degree_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.degree_details
                                .degree_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_college &&
                          value.other_fields.student.degree_details
                            .degree_college.name
                            ? value.other_fields.student.degree_details
                                .degree_college.name
                            : value.other_fields.student.degree_details
                                .degree_other_college_name
                            ? value.other_fields.student.degree_details
                                .degree_other_college_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_stream &&
                          value.other_fields.student.degree_details
                            .degree_stream.name
                            ? value.other_fields.student.degree_details
                                .degree_stream.name
                            : value.other_fields.student.degree_details
                                .degree_other_stream_name
                            ? value.other_fields.student.degree_details
                                .degree_other_stream_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_type &&
                          value.other_fields.student.degree_details.degree_type
                            .name
                            ? value.other_fields.student.degree_details
                                .degree_type.name
                            : value.other_fields.student.degree_details
                                .other_degree_type
                            ? value.other_fields.student.degree_details
                                .other_degree_type
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_active_backlogs === null ||
                          value.other_fields.student.degree_details
                            .degree_active_backlogs === ""
                            ? "NA"
                            : value.other_fields.student.degree_details
                                .degree_active_backlogs}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_mark_type === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.master_degree_details
                                .master_degree_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_mark_type_value === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.master_degree_details
                                .master_degree_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_college &&
                          value.other_fields.student.master_degree_details
                            .master_degree_college.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_college.name
                            : value.other_fields.student.master_degree_details
                                .master_degree_other_college_name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_other_college_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_stream &&
                          value.other_fields.student.master_degree_details
                            .master_degree_stream.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_stream.name
                            : value.other_fields.student.master_degree_details
                                .master_degree_other_stream_name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_other_stream_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_type &&
                          value.other_fields.student.master_degree_details
                            .master_degree_type.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_type.name
                            : value.other_fields.student.master_degree_details
                                .other_master_degree_type
                            ? value.other_fields.student.master_degree_details
                                .other_master_degree_type
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_active_backlogs === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_active_backlogs === ""
                            ? "NA"
                            : value.other_fields.student.master_degree_details
                                .master_degree_active_backlogs}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                 <TableRow>
                  <TableCell colSpan={27}>
                    <h3 style={{color:"red"}}>No Data Found</h3>
                  </TableCell>
                 </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />

          <Modal open={isModalOpen1} onClose={handleCloseModal1}>
            <Grid style={{ marginTop: "3%" }}>
              <Grid xs={12} item container style={gridContainer} align="center">
                <Paper elevation={10} style={paperStyle1}>
                  <Grid align="center" item>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AiFillCloseSquare
                        fontSize={30}
                        onClick={handleCloseModal1}
                        color="#FC2947"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <h2>Appointment List</h2>
                    <br />
                  </Grid>
                  <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
                    <Table
                      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead sx={{ backgroundColor: "#124076" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#124076",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "100px !important",
                            }}
                          >
                            Sl.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#124076",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "700px !important",
                            }}
                          >
                            Appointment Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {modalState && Object.keys(modalState).length > 0 ? (
                          Object.entries(modalState).map((value, id) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{id + 1}</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {value[1]?.["college"]?.value || "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{ color: "#000" }}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
