import * as React from "react";
import { Box, Button, Typography, Modal, Tooltip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function AddressingEditModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let { stateAddresssing, status } = props;

  return (
    <div>
      <Tooltip title="Edit Addressing">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <FaEdit
            fontSize={18}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
            }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>

          {status == 3 ? (
            <>
              <Typography id="modal-modal-title" variant="h5">
                Select Single or Multiple Appointment For Edit Addressing..?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Link to="/edit_addressing_form" state={stateAddresssing}>
                  <Button
                    sx={{
                      padding: "15px",
                      background: "green",
                      color: "white",
                    }}
                    variant="contained"
                  >
                    single
                  </Button>
                </Link>

                <Link to="/edit_addressing_multiple" state={stateAddresssing}>
                  <Button
                    sx={{ padding: "15px", background: "blue", color: "white" }}
                    variant="contained"
                  >
                    Multiple
                  </Button>
                </Link>
              </div>
            </>
          ) : status == 1 ? (
            <>
              <Typography id="modal-modal-title" variant="h5">
                Select Single Button For Edit Addressing..?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link to="/edit_addressing_form" state={stateAddresssing}>
                  <Button
                    sx={{
                      padding: "15px",
                      background: "green",
                      color: "white",
                    }}
                    variant="contained"
                  >
                    single
                  </Button>
                </Link>
              </Box>
            </>
          ) : (
            <h2 style={{ color: "#51829B" }}>
              You can't Edit this Appointment
            </h2>
          )}
        </Box>
      </Modal>
    </div>
  );
}
