import { useState } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AxiosInstance from "../apis/AxiosInstance";

const EditStudentList = () => {
  let Navigate = useNavigate();
  let { state } = useLocation();

  const [full_name, setfull_name] = useState("");

  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [isValid, setIsValid] = useState(false);

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  let [mobiles, setmobiles] = useState([
    {
      number: state.mobiles[0],
      is_whatsappNumber: false,
      whatsappNumber: state?.whattsapp_number?.number,
    },
  ]);
  const [emails, setEmails] = useState([state.emails[0]]);

  const handleMobChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...mobiles];
    list[index][name] = value;
    setmobiles(list);
  };

  const handleWhatsAppChange = (event, index) => {
    let list = [...mobiles];
    list[index].is_whatsappNumber = event.target.checked;
    setmobiles(list);
  };

  const handleRemoveMob = (index) => {
    const list = [...mobiles];
    list.splice(index, 1);
    setmobiles(list);
  };

  const handleAddMob = () => {
    setmobiles([
      ...mobiles,
      { number: "", is_whatsappNumber: false, whatsappNumber: "" },
    ]);
  };

  const handleEmailChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...emails];
    list[index] = value;
    setEmails(list);
  };

  const handleRemoveEmail = (index) => {
    const list = [...emails];
    list.splice(index, 1);
    setEmails(list);
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let formData = new FormData();
    formData.append("id", state.id);

    emails.forEach((val, imd) => {
      if (imd !== 0) {
        formData.append("email", val);
      }
    });

    mobiles.forEach((value, ind) => {
      if (ind !== 0) {
        formData.append("mobile", value.number);
        formData.append("country_code", `+91`);

        if (value.is_whatsappNumber && value.whatsappNumber) {
          formData.append("is_whattsapp_number", value.is_whatsappNumber);
          formData.append("whatsapp_number", value.whatsappNumber);
          formData.append("whatsapp_number_country_code", `+91`);
        }
      }
    });

    formData.append("password", password);
    formData.append("confirm_password", confirm_password);

    try {
      await AxiosInstance.patch(`/edit_student`, formData, config)
        .then((data) => {
          toast.success("Successfully Student updated");
          setTimeout(() => {
            Navigate("/student_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went Wrong Please Try Again");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "2px solid gray",
  };
  const avatarStyle = { backgroundColor: "#FD8D14" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#176B87",
    color: "#FD8D14",
    border: "1px solid #176B87",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <Grid container style={{ marginTop: "5%" }}>
      <Grid xs={12} item style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Student Info</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container md={12} spacing={2}>
              <Grid item md={12} xs={12}>
                <TextField
                  color="warning"
                  size="small"
                  sx={{ minWidth: 210 }}
                  fullWidth
                  name="full_f_name"
                  label="Fullname"
                  disabled
                  value={state.full_name}
                  onChange={(e) => setfull_name(e.target.value)}
                />
              </Grid>

              {mobiles.map((item, index) => (
                <Grid item container spacing={2} key={index + 1}>
                  <Grid item md={3.5} xs={12}>
                    <TextField
                      fullWidth
                      type="tel"
                      label="Mobile"
                      variant="outlined"
                      onKeyPress={preventMinus}
                      inputProps={{
                        minLength: 10,
                        maxLength: 10,
                      }}
                      name="number"
                      required
                      disabled={index === 0}
                      value={item.number}
                      onChange={(e) => handleMobChange(e, index)}
                    />
                  </Grid>

                  <Grid item md={4.2} xs={12}>
                    <Checkbox
                      name="is_whatsappNumber"
                      checked={item.is_whatsappNumber}
                      disabled={index === 0}
                      onChange={(e) => handleWhatsAppChange(e, index)}
                      id={`is_whatsappNumber-${index}`}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label
                      htmlFor={`is_whatsappNumber-${index}`}
                      style={{ fontSize: "16px" }}
                    >
                      Is Whatsapp Number
                    </label>
                  </Grid>
                  <Grid item md={4.3} xs={12}>
                    <TextField
                      color="warning"
                      label="Whatsapp Number"
                      disabled={!item.is_whatsappNumber || index === 0}
                      // disabled={index === 0}
                      type="tel"
                      fullWidth
                      onKeyPress={preventMinus}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      name="whatsappNumber"
                      value={item.whatsappNumber}
                      required
                      onChange={(e) => handleMobChange(e, index)}
                    />
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xs={12}
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: "-2.5%" }}
                  >
                    {index !== 0 && (
                      <IconButton onClick={() => handleRemoveMob(index)}>
                        <CancelIcon color="error" />
                      </IconButton>
                    )}
                    {index === mobiles.length - 1 && mobiles.length < 3 && (
                      <IconButton onClick={handleAddMob}>
                        <AddCircleOutlineIcon color="success" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))}

              <Grid item md={11.8} xs={12} style={{ marginTop: "-1.2%" }}>
                {emails.map((item, index) => (
                  <div style={{ position: "relative" }} key={index + 1}>
                    <Grid item>
                      <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        name="email"
                        required
                        disabled={index === 0}
                        value={item}
                        onChange={(e) => {
                          handleEmailChange(e, index);
                          const regexPattern =
                            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                          const isValid = regexPattern.test(e.target.value);
                          setIsValid(isValid);
                        }}
                        // color={isValid ? "success" : "error"}
                        focused
                        sx={{ my: 1 }}
                      />
                    </Grid>
                    {index !== 0 && (
                      <IconButton
                        style={{
                          position: "absolute",
                          right: "-30px",
                          top: "20px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => handleRemoveEmail(index)}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    )}
                  </div>
                ))}
                <IconButton
                  onClick={handleAddEmail}
                  style={{
                    // position: "absolute",
                    right: "-383px",
                    top: "-18px",
                    transform: "translateY(-50%)",
                  }}
                >
                  <AddCircleOutlineIcon color="success" />
                </IconButton>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: "-4%" }}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <InputLabel color="warning">Password*</InputLabel>
                  <OutlinedInput
                    color="warning"
                    name="password"
                    value={password}
                    autoComplete="new-password"
                    required
                    onChange={(e) => setpassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword} //
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: "-4%" }}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <InputLabel color="warning">Confirm Password*</InputLabel>
                  <OutlinedInput
                    color="warning"
                    name="confirm_password"
                    required
                    autoComplete="confirm-password"
                    value={confirm_password}
                    onChange={(e) => setconfirm_password(e.target.value)}
                    type={showPassword1 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="ConfirmPassword"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container sm={12} spacing={2}>
              <Grid item sm={3}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={3}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditStudentList;
