import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useState } from "react";
import EditInterviewModal from "./EditInterviewModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function InterviewerList() {
  const [interview_list, setInterview_list] = useState([]);

  const [page, setPage] = useState(1);
  const [totalInterview, setTotalInterview] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchGD = async () => {
      setLoad(true);
      try {
        let interviewData = await AxiosInstance.get(`/interview?page=${page}`);
        setTotalInterview(interviewData.data.filtered_count);
        let finalInterviewData = interviewData.data.data;
        if (finalInterviewData !== null) {
          setInterview_list(finalInterviewData);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };
    fetchGD();
  }, [page]);

  const totalPages = Math.ceil(totalInterview / 10);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    setScrollPosition(scrollLeft);
  };

  return (
    <main style={{ marginTop: "5%" }}>
      <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#081F37" }}>
          Total Interview List : {totalInterview} &nbsp;&nbsp;
        </h1>
      </Grid>

      <section style={{ marginTop: "1%" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            overflowY: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    position: "sticky",
                    left: "0",
                    zIndex: "2",
                    backgroundColor: "#535962",
                    borderRight: "1.5px solid #EAEAEA",
                  }}
                >
                  Appointment Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interview Start Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interview Actual End Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interview End Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interview Edit
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interviewer Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interview Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Interview Duration in Min
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Total Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Shortlisted Students
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Joined Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Not Joined Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Rejected Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Job Offered Students
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={14} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : interview_list.length > 0 ? (
                interview_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell
                      className={`table-container ${
                        scrollPosition > 0 ? "box-shadow" : ""
                      }`}
                      component="th"
                      scope="row"
                      align="left"
                      onScroll={handleScroll}
                      style={{
                        position: "sticky",
                        left: "0",
                        zIndex: "2",
                        backgroundColor: `${
                          ind % 2 == 0 ? "#F5F5F5" : "white"
                        }`,
                        borderRight: "2.5px solid #EAEAEA",
                        fontWeight: "bold",
                      }}
                    >
                      <Tooltip
                        title={Object.values(val.appointment_id).map(
                          (ea) => ea.name
                        )}
                      >
                        <p>
                          {" "}
                          {val.appointment_id === null ||
                          val.appointment_id === ""
                            ? "N/A"
                            : Object.values(val.appointment_id).map((ea) => (
                                <Box style={{ height: "22px" }}>
                                  {ea?.["name"].slice(0, 45)}
                                </Box>
                              ))}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.start_date_time === null ||
                      val.start_date_time === ""
                        ? "N/A"
                        : moment(val.start_date_time)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.actual_end_date === null ||
                      val.actual_end_date === ""
                        ? "N/A"
                        : moment(val.actual_end_date)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.end_date_time === null || val.end_date_time === ""
                        ? "N/A"
                        : moment(val.end_date_time)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title="Edit">
                        {/* <Link to="/edit_interview" state={val}>
                          <FaEdit
                            fontSize={18}
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link> */}
                        <EditInterviewModal stateAddresssing={val} />
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.interviewer === null || val.interviewer === ""
                        ? "N/A"
                        : Object.values(val.interviewer).map((ea) => (
                            <Box style={{ height: "22px" }}>{ea?.["name"]}</Box>
                          ))}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.interview_name} placement="bottom">
                        <p> {val.interview_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.interview_duration === null ||
                      val.interview_duration === ""
                        ? "N/A"
                        : val.interview_duration}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{val.total_students}</p>&nbsp;&nbsp;
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.shortlisted_count === null ||
                          val.shortlisted_count === ""
                            ? 0
                            : val.shortlisted_count}
                        </p>
                        &nbsp;&nbsp;
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.joined_students_count === null ||
                          val.joined_students_count === ""
                            ? 0
                            : val.joined_students_count}
                        </p>
                        &nbsp;&nbsp;
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.not_joined_students_count === null ||
                          val.not_joined_students_count === ""
                            ? 0
                            : val.not_joined_students_count}
                        </p>
                        &nbsp;&nbsp;
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.rejected_students_count === null ||
                          val.rejected_students_count === ""
                            ? 0
                            : val.rejected_students_count}
                        </p>
                        &nbsp;&nbsp;
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.job_offered_students_count === null ||
                          val.job_offered_students_count === ""
                            ? 0
                            : val.job_offered_students_count}
                        </p>
                        &nbsp;&nbsp;
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={14}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
