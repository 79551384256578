import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaUserCheck } from "react-icons/fa";
import Cookies from "js-cookie";
import AxiosInstance from "../../apis/AxiosInstance";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function CampusCollegeAppointment() {
  // state values  for the autocomplete component
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedSpoc, setSelectedSpoc] = useState([]);
  const [expectedStudents, setExpectedStudents] = useState("");
  const [comment, setComment] = useState("");
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  //search terms for state

  const [searchTermState, setSearchTermState] = useState("");
  const [searchTermDistrict, setSearchTermDistrict] = useState("");
  const [searchTermUniversity, setSearchTermUniversity] = useState("");
  const [searchCollege, setSearchCollege] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [yop, setYop] = useState(null);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();

  // const [doc_type_Options, setDoc_type_Options] = useState([]);
  // const [searchDocType, setSearchDocType] = useState("");
  // const [fields, setFields] = useState([
  //   { docTypeData: null, selectedVideo: null },
  // ]);

  // const handleAddFields = () => {
  //   if (fields.length < 3) {
  //     setFields([...fields, { docTypeData: null, selectedVideo: null }]);
  //   } else {
  //     alert("You can only add up to 3 fields.");
  //   }
  // };

  // const handleRemoveFields = index => {
  //   const values = [...fields];
  //   values.splice(index, 1);
  //   setFields(values);
  // };

  // const handleInputChange = (index, event) => {
  //   const values = [...fields];
  //   if (event.target.name === "selectedVideo") {
  //     values[index].selectedVideo = event.target.files[0];
  //   }
  //   setFields(values);
  // };

  // const handleAutocompleteChange = (index, event, newValue) => {
  //   const values = [...fields];
  //   values[index].docTypeData = newValue;
  //   setFields(values);
  // };

  const [doc_type_Options, setDoc_type_Options] = useState([]);
  const [searchDocType, setSearchDocType] = useState("");
  const [fields, setFields] = useState([
    { id: Date.now(), docTypeData: null, selectedVideo: null, status: "" },
  ]);

  const handleAddFields = () => {
    setFields([
      ...fields,
      { id: Date.now(), docTypeData: null, selectedVideo: null, status: "" },
    ]);
  };

  const handleRemoveFields = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  // const handleInputChange = (id, event) => {
  //   const values = fields.map((field) => {
  //     if (field.id === id && event.target.name === "selectedVideo") {
  //       return { ...field, selectedVideo: event.target.files[0] };
  //     }
  //     return field;
  //   });
  //   setFields(values);
  // };

  const handleInputChange = (id, event) => {
    const { name, value, files } = event.target;
    const values = fields.map((field) => {
      if (field.id === id) {
        if (name === "selectedVideo") {
          return { ...field, selectedVideo: files[0] };
        } else if (name === "status") {
          return { ...field, status: value };
        }
      }
      return field;
    });
    setFields(values);
  };

  const handleAutocompleteChange = (id, event, newValue) => {
    const values = fields.map((field) => {
      if (field.id === id) {
        return { ...field, docTypeData: newValue };
      }
      return field;
    });
    setFields(values);
  };

  const navigate = useNavigate();

  // for rendering the data from api
  const [universityOptions, setUniversityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [colgSpocOptions, setColgSpocOptions] = useState([]);
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);

  // to expectedStudents
  let handleStudents = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ""); // remove non-numeric characters
    if (inputValue.length <= 5) {
      setExpectedStudents(inputValue);
    }
  };

  // Error Handling for Create Addressing Faculty
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //==================================FUNCTION TO HANDLE FORM SUBMITION=============================
  let handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    let formData = new FormData();

    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      // if (expectedStudents.toString().startsWith("0")) {
      //   toast.error("Please enter a valid number for Students Targeted");
      //   setIsSubmitting(false);
      //   return; // Stop further execution
      // }
      // setError(false);
      const collegeSpoc = {};
      selectedSpoc.forEach((spoc) => {
        collegeSpoc[spoc.id] = { name: spoc.name };
      });
      formData.append(
        "state",
        JSON.stringify({
          id: selectedColgState.id,
          name: selectedColgState.name,
        })
      );
      formData.append(
        "district",
        JSON.stringify({
          id: selectedColgDistrict.id,
          name: selectedColgDistrict.name,
        })
      );
      formData.append(
        "college",
        JSON.stringify({ id: selectedCollege.id, name: selectedCollege.name })
      );
      formData.append(
        "university",
        JSON.stringify({
          id: selectedUniversity.id,
          name: selectedUniversity.name,
        })
      );
      formData.append("expected_students", Number.parseInt(expectedStudents));
      formData.append("college_spoc", JSON.stringify(collegeSpoc));
      formData.append("yop", yop);
      formData.append("comment", comment);
      formData.append(
        "unit",
        JSON.stringify({
          id: unitNameData.ID,
          name: unitNameData.name,
        })
      );
      fields.forEach((field, index) => {
        if (field.selectedVideo) {
          formData.append("file", field.selectedVideo);
        }
        if (field.docTypeData) {
          formData.append("doc_type", JSON.stringify(field.docTypeData.ID));
        }
        formData.append("status", field.status ? field.status : "");
      });

      await AxiosInstance.post("/cc_appointment", formData, config)
        .then((response) => {
          toast.success("Successfully college appointment data is submitted");
          if (response.status == 200) {
            setTimeout(() => {
              navigate("/");
            }, 1800);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          setIsSubmitting(false);
        });
    } catch (error) {
      console.log(error.code);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchTermState !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${searchTermState}&limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [searchTermState, countryMiniId]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    let fetchData = async () => {
      //DocType Api
      if (searchDocType !== "") {
        let data = await AxiosInstance.get(
          `/doc_type?search_keyword=${searchDocType}&limit=10`
        );
        let finalData = data.data.data;

        setDoc_type_Options(finalData);
      } else {
        let data = await AxiosInstance.get(`/doc_type?limit=10`);
        let finalData = data.data.data;

        setDoc_type_Options(finalData);
      }
    };
    fetchData();
  }, [searchDocType]);

  // for particular spoc for its college and univrsity
  useEffect(() => {
    let fetchData = async (e) => {
      const parseLog = (a) => {
        const userData = a ? JSON.parse(a) : { user_id: "" };
        const { user_id } = userData;
        return user_id;
      };
      if (selectedCollege === null) {
        return null;
      } else {
        let collegeSpocData1 = await AxiosInstance.get(
          `/college_spoc?college_id=${selectedCollege.id}&university_id=${selectedUniversity.id}`
        );
        let finalColgSpocData = collegeSpocData1.data.data;
        setColgSpocOptions(finalColgSpocData);
        if (finalColgSpocData === null) {
          toast.warning("Please Create a Spoc");
          setTimeout(() => {
            navigate("/campus_spoc_form_college_appointment", {
              state: {
                selectedUniversity,
                selectedCollege,
                selectedColgState,
                selectedColgDistrict,
              },
            });
          }, 500);
        }
      }
    };
    fetchData();
  }, [selectedCollege, selectedUniversity]);

  //! for college district filter from state
  const [defaultClg, setDefaultClg] = useState([]);
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        let colgDistrictData = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        let finalcolgDistrictData = colgDistrictData.data.data;
        setcolgDistrictOptions(finalcolgDistrictData);
        setDefaultClg(finalcolgDistrictData);
      }
    };
    fetchData();
  }, [selectedColgState]);

  const searchClg = async (e) => {
    try {
      let colgDistrictData = await AxiosInstance.get(
        `/district_mini?state_id=${selectedColgState.id}&limit=10&search_keyword=${e}`
      );
      let finalcolgDistrictData = colgDistrictData.data.data;
      setcolgDistrictOptions(finalcolgDistrictData);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  //! university Filter based on state
  const [defaultUni, setDefaultUni] = useState([]);

  useEffect(() => {
    let fetchUniData = async (e) => {
      let universityData = await AxiosInstance.get(`/university?limit=10`);
      let finalUniversityData = universityData.data.data;
      setUniversityOptions(finalUniversityData);
      setDefaultUni(finalUniversityData);
    };
    fetchUniData();
  }, []);

  const searchuni = async (e) => {
    try {
      let universityData = await AxiosInstance.get(
        `/university?limit=10&search_keyword=${e}`
      );
      let finalUniversityData = universityData.data.data;
      setUniversityOptions(finalUniversityData);
    } catch (error) {
      setUniversityOptions(defaultUni);
    }
  };

  //! for college filter based on district and university
  const [defaultdistClg, setDefaultdistClg] = useState([]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedUniversity !== null && selectedColgDistrict !== null) {
        // college api
        let collegeData = await AxiosInstance.get(
          `/college_mini?limit=10&district_id=${selectedColgDistrict.id}&university_id=${selectedUniversity.id}`
        );
        let finalCollegeData = collegeData.data.data;
        setCollegeOptions(finalCollegeData);
        setDefaultdistClg(finalCollegeData);
      }
    };
    fetchData();
  }, [selectedColgDistrict, selectedUniversity]);

  const searchdistClg = async (e) => {
    try {
      let collegeData = await AxiosInstance.get(
        `/college_mini?limit=10&district_id=${selectedColgDistrict.id}&university_id=${selectedUniversity.id}&search_keyword=${e}`
      );
      let finalCollegeData = collegeData.data.data;
      setCollegeOptions(finalCollegeData);
    } catch (error) {
      setCollegeOptions(defaultdistClg);
    }
  };

  // Reset Data
  let resetData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedUniversity(null);
    setSelectedCollege(null);
    setSelectedSpoc([]);
    setExpectedStudents("");
    setComment("");
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "98%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4.3%",
          background: "#efefef",
        }}
      >
        <Box>
          <Paper variant="outlined">
            <Box
              sx={{
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "2px solid #9BA4B5",
              
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#081f37",
                  fontSize: {
                    xs: "20px", // Smaller font size on small screens
                    sm: "25px", // Medium font size on small to medium screens
                    md: "30px", // Larger font size on medium to large screens
                  },
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                  justifyContent: "center",
                  margin:"0 auto"
                }}
              >
                COLLEGE APPOINTMENT FORM
              </Box>
              <NavLink
                to="/campus_spoc_creation"
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "5px 10px",
                    background: "#081F37",
                    color: "#8ed6ff",
                    boxShadow: "none",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    color: "white",
                    marginRight: "5px",
                  }}
                >
                  Create Spoc &nbsp;&nbsp;
                  <FaUserCheck fontSize={20} />
                </Button>
              </NavLink>
            </Box>
            <form
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "1%",
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1}>
                {/* colleg state */}
                <Grid md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchTermState(e.target.value);
                    }}
                  >
                    <Autocomplete
                      required
                      options={colgStateOptions}
                      value={selectedColgState}
                      name="state_name"
                      onChange={(event, newColgState) => {
                        setSelectedColgState(newColgState);
                        setselectedColgDistrict(null);
                        setSelectedCollege(null);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search State"
                          variant="outlined"
                          required
                          error={error && !selectedColgState}
                          helperText={
                            error && !selectedColgState
                              ? "Please select State"
                              : null
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* colleg district */}
                <Grid md={3} xs={12}>
                  <Item>
                    <Autocomplete
                      required
                      options={selectedColgState ? colgDistrictOptions : []}
                      value={selectedColgDistrict}
                      name="state_name"
                      onChange={(event, newColgdis) => {
                        setselectedColgDistrict(newColgdis);
                        setSelectedCollege(null);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchClg(e);
                        } else {
                          setcolgDistrictOptions(defaultClg);
                        }
                        setSearchTermDistrict(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search District"
                          variant="outlined"
                          required
                          value={searchTermDistrict}
                          error={error && !selectedColgDistrict}
                          helperText={
                            error && !selectedColgDistrict
                              ? "Please select District"
                              : null
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid>

                <Grid md={3} xs={12}>
                  <Item>
                    <Autocomplete
                      required
                      options={
                        selectedUniversity
                          ? [selectedUniversity]
                          : universityOptions
                      }
                      value={selectedUniversity}
                      name="university_name"
                      onChange={(event, newuniversity) => {
                        setSelectedUniversity(newuniversity);
                        setSelectedCollege(null);
                        setSelectedSpoc([]);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchuni(e);
                        } else {
                          setUniversityOptions(defaultUni);
                        }
                        setSearchTermUniversity(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search University"
                          variant="outlined"
                          required
                          error={error && !selectedUniversity}
                          value={searchTermUniversity}
                          helperText={
                            error && !selectedUniversity
                              ? "Please select University"
                              : null
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <Autocomplete
                      required
                      options={selectedColgDistrict ? collegeOptions : []}
                      value={selectedCollege}
                      name="college_name"
                      onChange={(event, newcollege, x, y, z) => {
                        if (x === "selectOption" && y.option.id === 0) {
                          toast.warn("Select proper option");
                        } else {
                          setSelectedCollege(newcollege);
                        }
                        setSelectedSpoc([]);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchdistClg(e);
                        } else {
                          setCollegeOptions(defaultdistClg);
                        }
                        setSearchCollege(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search College"
                          variant="outlined"
                          required
                          value={searchCollege}
                          error={error && !selectedCollege}
                          helperText={
                            error && !selectedCollege
                              ? "Please select College"
                              : null
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* SPOC */}
                <Grid md={3} xs={12}>
                  <Item>
                    {colgSpocOptions === null ? (
                      <>Loading...</>
                    ) : (
                      <>
                        <Autocomplete
                          multiple
                          options={colgSpocOptions ? colgSpocOptions : []}
                          value={selectedSpoc}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <li {...props} style={{ width: "100%" }}>
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required={selectedSpoc.length === 0}
                              variant="outlined"
                              label="Select Spoc Name"
                              placeholder="Select Spoc Name "
                            />
                          )}
                          onChange={(e, value) => {
                            setSelectedSpoc(value);
                          }}
                        />
                      </>
                    )}
                  </Item>
                </Grid>

                <Grid md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchUnitName(e.target.value);
                    }}
                  >
                    <Autocomplete
                      options={unitNameOption}
                      value={unitNameData}
                      name="unitNameOption"
                      onChange={(event, newData_xy) => {
                        setUnitNameData(newData_xy);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Unit Name"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Item>
                </Grid>

                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      fullWidth
                      id="expected_students"
                      label="Students Targeted"
                      name="expected_students"
                      value={expectedStudents}
                      onChange={handleStudents}
                      inputProps={{ maxLength: 5 }}
                      variant="outlined"
                      type="text"
                      error={error && !expectedStudents}
                      helperText={
                        error && !expectedStudents
                          ? "Please Select Expected Students"
                          : null
                      }
                    />
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <Autocomplete
                        required
                        options={yopOptions}
                        value={yop}
                        name="year_of_passout"
                        onChange={(e, newyop) => {
                          setYop(newyop);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select YOP"
                            variant="outlined"
                            required
                            error={error && !yop}
                            helperText={
                              error && !yop ? "Please select YOP" : null
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Item>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  item
                  xs={12}
                  style={{ marginTop: "0.3%", marginLeft: "3%" }}
                >
                  {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                      <Paper
                        elevation={3}
                        style={{
                          padding: "16px",
                          marginBottom: "16px",
                          width: "95%",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ marginLeft: "8px" }}
                            onChange={(e) => setSearchDocType(e.target.value)}
                          >
                            <Autocomplete
                              options={doc_type_Options}
                              value={field.docTypeData}
                              name="doc_type_Options"
                              onChange={(event, newValue) =>
                                handleAutocompleteChange(
                                  field.id,
                                  event,
                                  newValue
                                )
                              }
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Doc Type"
                                  variant="outlined"
                                  // required
                                />
                              )}
                            />
                          </Grid>

                          <Grid
                            item
                            md={5}
                            xs={12}
                            style={{ marginLeft: "17px" }}
                          >
                            <TextField
                              type="file"
                              fullWidth
                              name="selectedVideo"
                              // required={Boolean(field.docTypeData)}
                              onChange={(e) => handleInputChange(field.id, e)}
                              inputProps={{
                                accept: "*/*",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={3}
                            xs={12}
                            style={{ marginLeft: "17px" }}
                          >
                            <TextField
                              fullWidth
                              id="status"
                              label="Status"
                              name="status"
                              value={field.status}
                              onChange={(e) => handleInputChange(field.id, e)}
                              variant="outlined"
                              type="text"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Grid
                        container
                        justifyContent="flex-end"
                        spacing={1}
                        style={{ width: "96%", marginTop: "-2%" }}
                      >
                        {/* {index === fields.length - 1 && fields.length < 3 && ( */}
                        {index === fields.length - 1 && (
                          <Grid item>
                            <IconButton
                              onClick={handleAddFields}
                              sx={{ color: "green" }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {fields.length > 1 && (
                          <Grid item>
                            <IconButton
                              onClick={() => handleRemoveFields(field.id)}
                              sx={{ color: "red" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Item sx={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Item>
                </Grid>

                <Grid
                  xs={12}
                  item
                  md={12}
                  spacing={2}
                  style={{
                    textAlign: "right",
                    padding: "5px",
                  }}
                >
                  <Button
                    onClick={resetData}
                    type="button"
                    variant="contained"
                    style={{
                      width: "10%",
                      marginRight: "3%",
                      backgroundColor: "#DF2E38",
                      color: "#e74646",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "10%",
                      color: "#F5F5F5",
                      backgroundColor: "#1976d2",
                      fontWeight: "bold",
                    }}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </article>
    </section>
  );
}
