import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function CollegeListSpocNameModal(props) {
  let { state } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title="Spoc Names">
        <Button
          size="small"
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={19}
            style={{ color: "#646FD4", "&:hover": { color: "#EB455F" } }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Spoc Name Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 550 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl.No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "800px !important",
                    }}
                  >
                    Appointment Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Spoc Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {state.appointment_field.map((val, ind) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{ind + 1}</TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {val?.["appointment_name"]}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {/* {val.college_spoc &&
                          Object.values(val.college_spoc).map(faculty => {
                            return (
                              <Box style={{ height: "22px" }}>
                                {faculty?.["name"]}
                              </Box>
                            );
                          })} */}
                        {val.college_spoc &&
                        Object.values(val.college_spoc).length > 0 ? (
                          Object.values(val.college_spoc).map(faculty => (
                            <Box style={{ height: "22px" }}>
                              {faculty?.name}
                            </Box>
                          ))
                        ) : (
                          <Box style={{ height: "22px" }}>
                            <p>-----</p>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
