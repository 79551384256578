import { createContext, useEffect, useState, useRef } from "react";
import AxiosInstance from "../apis/AxiosInstance";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const socketContext = createContext();

export const SocketContextProvider = ({ children, userData }) => {
  const parseLog = (a) => {
    const userData = a ? JSON.parse(a) : { token: "" };
    const { token } = userData;
    return token;
  };
  const token = parseLog(Cookies.get("logged"));
  const parseLogA = (a) => {
    const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
    const { id } = userData.role[0];
    return id.toString();
  };
  const role = parseLogA(Cookies.get("logged"));

  const [ws, setWs] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [notreadNotification, setNotreadNotification] = useState(0);
  const [isReadAll, setIsReadAll] = useState(false);
  const [alreadyRead, setAlreadyRead] = useState([]);
  const messageQueue = useRef([]);

  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(1);

  const [totalNotification, setTotalNotification] = useState(0);
  const [load, setLoad] = useState(false);
  const [defaultState, setDefaultState] = useState({
    notificationList: [],
    totalNotification: 0,
  });
  const [readAllData, setReadAllData] = useState(null);

  // useEffect(() => {
  // const fetchData = async () => {
  //   let endpoint = `/get_notification?page=${page}`;
  //   try {
  //     setLoad(true);
  //     let data = await AxiosInstance.get(endpoint);
  //     let finalData = data.data.data;
  //     setNotificationList(finalData);
  //     if (page === 1) {
  //       setNotificationList(finalData);
  //       setReadAllData(finalData[0]);
  //     }
  //     setTotalNotification(data.data.filtered_count);
  //     setDefaultState({
  //       notificationList: finalData,
  //       totalNotification: data.data.filtered_count,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoad(false);
  //   }
  // };

  const fetchData = async () => {
    let endpoint = `/get_notification?page=${page}`;
    try {
      setLoad(true);
      let data = await AxiosInstance.get(endpoint);
      let finalData = data.data.data || []; // Default to an empty array if data.data.data is null
      setNotificationList(finalData);
      if (page === 1) {
        setNotificationList(finalData);
        if (finalData.length > 0) {
          setReadAllData(finalData[0]);
        }
      }
      setTotalNotification(data.data.filtered_count);
      setDefaultState({
        notificationList: finalData,
        totalNotification: data.data.filtered_count,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    // Function to connect WebSocket
    const connectWebSocket = () => {
      if (!userData || !userData.token || !userData.user_id) {
        // console.error("Invalid userData provided");
        return;
      }

      const newWs = new WebSocket(
        `wss://incubationb.qspiders.com/ws/notifications?token=${userData.token}&user_id=${userData.user_id}`
      );

      newWs.onopen = () => {
        // console.log("WebSocket connection opened");
        setWs(newWs); // Set the WebSocket instance

        // Send any queued messages
        while (messageQueue.current.length > 0) {
          const message = messageQueue.current.shift();
          newWs.send(JSON.stringify(message));
        }
      };

      newWs.onmessage = (event) => {
        let messageDet = JSON.parse(event.data);
        console.log("Message from server:", event.data);
        setNotificationData((prev) => [...prev, event.data]);
        if (messageDet.type == "notification") {
          setNotreadNotification((val) => val + 1);
          toast.info(`${messageDet.notification.title}`, {
            position: toast.POSITION.TOP_CENTER,
            style: { width: "520px" },
            autoClose: 5000, // duration in milliseconds (10 seconds)
          });
        }
        if (messageDet.type == "success") {
          setNotreadNotification((val) => val - 1);
          setAlreadyRead((ea) => [...ea, messageDet.notification.id]);
        } else if (messageDet.type == "read all success") {
          setNotreadNotification(0);
          fetchData();
        }
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

  
    };

    // Connect WebSocket if userData is valid
    if (userData && userData.token && userData.user_id && !ws) {
      connectWebSocket();
    }

    // Clean up function
    return () => {
      if (ws) {
        ws.close();
        // console.log("WebSocket connection closed");
      }
    };
  }, [userData, ws]);

  // useEffect(() => {
  //   // Function to connect WebSocket
  //   const connectWebSocket = () => {
  //     if (!userData || !userData.token || !userData.user_id) {
  //       return;
  //     }
  
  //     const newWs = new WebSocket(
  //       `ws://testincub2.qspiders.com/ws/notifications?token=${userData.token}&user_id=${userData.user_id}`
  //     );
  
  //     newWs.onopen = () => {
  //       setWs(newWs); // Set the WebSocket instance
  
  //       // Send any queued messages
  //       while (messageQueue.current.length > 0) {
  //         const message = messageQueue.current.shift();
  //         newWs.send(JSON.stringify(message));
  //       }
  //     };
  
  //     newWs.onmessage = (event) => {
  //       let messageDet = JSON.parse(event.data);
  //       console.log("Message from server:", event.data);
  //       setNotificationData((prev) => [...prev, event.data]);
  //       if (messageDet.type === "notification") {
  //         setNotreadNotification((val) => val + 1);
  //         toast.info(`${messageDet.notification.title}`, {
  //           position: toast.POSITION.TOP_CENTER,
  //           style: { width: "520px" },
  //           autoClose: 5000, // duration in milliseconds (5 seconds)
  //         });
  //       }
  //       if (messageDet.type === "success") {
  //         setNotreadNotification((val) => val - 1);
  //         setAlreadyRead((ea) => [...ea, messageDet.notification.id]);
  //       } else if (messageDet.type === "read all success") {
  //         setNotreadNotification(0);
  //         fetchData();
  //       }
  //     };
  
  //     newWs.onerror = (error) => {
  //       console.error("WebSocket error:", error);
  //     };
  
  //     newWs.onclose = () => {
  //       console.log("WebSocket connection closed, attempting to reconnect...");
  //       setWs(null); // Clear the WebSocket instance
  //       setTimeout(connectWebSocket, 3000); // Attempt to reconnect after 3 seconds
  //     };
  //   };
  
  //   // Connect WebSocket if userData is valid and no existing WebSocket connection
  //   if (userData && userData.token && userData.user_id && !ws) {
  //     connectWebSocket();
  //   }
  
  //   // Clean up function
  //   return () => {
  //     if (ws) {
  //       ws.close();
  //       // console.log("WebSocket connection closed");
  //     }
  //   };
  // }, [userData, ws]);
  

  const sendMessage = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(message));
    } else {
      //   console.warn("WebSocket is not open, queuing message for reconnection.");
      messageQueue.current.push(message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let endpoint = `/get_notification`;
      try {
        let data = await AxiosInstance.get(endpoint);
        setNotreadNotification(data.data.not_read_count);
      } catch (error) {
        console.log(error);
      }
    };
    if (
      token !== null &&
      (role == "1" || role == "5" || role == "17" || role == "18")
    ) {
      fetchData();
    }
  }, [isReadAll]);

  return (
    <socketContext.Provider
      value={{
        ws,
        notificationData,
        setNotificationData,
        setNotreadNotification,
        notreadNotification,
        sendMessage,
        isReadAll,
        setIsReadAll,
        alreadyRead,
        setAlreadyRead,
        notificationList,
        setNotificationList,
        page,
        setPage,
        totalNotification,
        setTotalNotification,
        load,
        setLoad,
        defaultState,
        setDefaultState,
        readAllData,
        setReadAllData,
        fetchData,
      }}
    >
      {children}
    </socketContext.Provider>
  );
};
