import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Modal,
  Box,
  Pagination,
  Grid,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { useEffect, useState } from "react";

import AxiosInstance from "../apis/AxiosInstance";
import TpoAppointmentList from "./TpoAppointmentList";
import TpoAppointmentListModal from "./TpoAppointmentListModal";
import SameCollegeRegisterStudentList from "./SameCollegeRegisterStudentList";
import StudentJoinedList from "../Bd_Module/bd/appointmentList/StudentJoinedList";
import NotJoinedStudentList from "../Bd_Module/bd/appointmentList/NotJoinedStudentList";
import SameCollegeQuizAttendedList from "./SameCollegeQuizAttendedList";
import SameCollegeQuizNotAttendedList from "./SameCollegeQuizNotAttendedList";
import SameCollegeQuizShortlistList from "./SameCollegeQuizShortlistList";
import SameCollegeQuizNotSelectedList from "./SameCollegeQuizNotSelectedList";
import QuizRejectedList from "../Bd_Module/bd/appointmentList/QuizRejectedList";
import SameCollegeGDRegisteredStudentList from "./SameCollegeGDRegisteredStudentList";
import SameCollegeGDJoinedStudentList from "./SameCollegeGDJoinedStudentList";
import SameCollegeGDNotJoinedStudentList from "./SameCollegeGDNotJoinedStudentList";
import SameCollegeGDShortlistStudentList from "./SameCollegeGDShortlistStudentList";
import SameCollegeGDRejectStudentList from "./SameCollegeGDRejectStudentList";
import SameCollegeGDHoldStudentList from "./SameCollegeGDHoldStudentList";
import SameCollegeFinalShortlist from "./SameCollegeFinalShortlist";
import SameCollegeRejectlist from "./SameCollegeRejectlist";
import AddressingAttendedStudentList from "./AddressingAttendedStudentList";
import AddressingNotAttendedStudentList from "./AddressingNotAttendedStudentList";
import SameCollegeQuizRejectlist from "./SameCollegeQuizRejectlist";

const useStyles = makeStyles({
  root: {
    minWidth: 100,
    margin: "10px",
    background: "blue",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const toIsoFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
};

export default function TpoAppointmentListCard() {
  const classes = useStyles();
  let [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [totalAppointment, setTotalAppointment] = useState(0);
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal open/close
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleShowDetails = (appointment) => {
    setSelectedAppointment(appointment);
    setModalOpen(true);
  };

  // //! Fetch the college
  let [searchedAppointment, setSearchedAppointment] = useState([]);
  useEffect(() => {
    let fetchAppointment = async (_) => {
      if (query !== "") {
        try {
          let collegeData = await AxiosInstance.get(
            `/appointments_for_tpo?${query}&page=${page}`
          );
          setTotalAppointment(collegeData.data.filter_count);
          let finalCollege = collegeData.data.data;
          setSearchedAppointment(finalCollege);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let collegeData = await AxiosInstance.get(
            `/appointments_for_tpo?page=${page}`
          );
          setTotalAppointment(collegeData.data.filter_count);
          let finalCollege = collegeData.data.data;
          setSearchedAppointment(finalCollege);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchAppointment();
  }, [page, query]);

  const totalPages = Math.ceil(totalAppointment / 10);

  return (
    <main>
      <Grid style={{ marginTop: "4%" }}>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#124076" }}>
          Total Appointments : {totalAppointment} &nbsp;&nbsp;
        </h1>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{ marginTop: "1%", width: "95%", margin: "0 auto" }}
      >
        {searchedAppointment && searchedAppointment.length > 0 ? (
          searchedAppointment.map((item, index) => (
            <Grid item xs={3} key={index} style={{ width: "80%" }}>
              <Card style={{ background: "#efefef",}}>
                <CardContent>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                      textTransform:"capitalize",
                      color:"#DD5746"
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#124076" }}>
                      College Name :
                    </span>
                    &nbsp; {item.ids.college.value}
                  </Typography>
                  {/* <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                      textTransform:"capitalize",
                      color:"#DD5746"
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#124076",alignItems:"center",justifyContent:"center",display:"flex" }}>
                     Appointment Details
                    </span>
                   
                   
                  </Typography> */}
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "35px",
                      color:"#DD5746"
                    }}
                  >
                    <span style={{ fontWeight: "bold", color: "#124076" }}>
                      {" "}
                      Appointment Date :
                    </span>
                    &nbsp;
                    {item.appointment_date === null ||
                    item.appointment_date === "" ? (
                      <Box
                        sx={{
                          color: "#EB455F",
                        }}
                      >
                        NA
                      </Box>
                    ) : (
                      moment
                        .utc(toIsoFormat(item.appointment_date))
                        .local()
                        .format("DD-MM-yyyy hh:mm A")
                    )}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        Appointment Registered Students :
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.registered_count === null
                          ? 0
                          : item.registered_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        {" "}
                        <SameCollegeRegisterStudentList
                          appointment_idx={item.id}
                          counts={item.registered_count}
                          level="5"
                        >                           
                        </SameCollegeRegisterStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        Addressing Joined Students : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.joined_student_count === null
                          ? 0
                          : item.joined_student_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        {" "}
                        <AddressingAttendedStudentList
                          appointment_idx={item.id}
                          counts={item.joined_student_count}
                          joinedStudentLevel="5"
                        ></AddressingAttendedStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        {" "}
                        Addressing Not Joined Students : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.not_joined_student_count === null
                          ? 0
                          : item.not_joined_student_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        {" "}
                        <AddressingNotAttendedStudentList
                          appointment_idx={item.id}
                          counts={item.not_joined_student_count}
                          notJoinedStudentLevel="5"
                        ></AddressingNotAttendedStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        {" "}
                        Quiz Attended : &nbsp;
                      </span>
                      &nbsp;
                      {/* <p>{item?.quiz_count?.quiz_attended_count || 0}</p> */}
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        {" "}
                        <SameCollegeQuizAttendedList
                          appointment_idx={item.id}
                          counts={item?.quiz_count?.quiz_attended_count}
                          addressing_idx={item.addressing_id}
                        ></SameCollegeQuizAttendedList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        {" "}
                        Quiz Not Attended : &nbsp;
                      </span>
                      &nbsp;
                      {/* <p>{item?.quiz_count?.quiz_not_attended_count || 0}</p> */}
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeQuizNotAttendedList
                          appointment_idx={item.id}
                          counts={item?.quiz_count?.quiz_not_attended_count}
                          addressing_idx={item.addressing_id}
                        ></SameCollegeQuizNotAttendedList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        {" "}
                        Quiz Shortlisted : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item?.quiz_count?.quiz_shortlisted_student_count || 0} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeQuizShortlistList
                          appointment_idx={item.id}
                          counts={item?.quiz_count?.quiz_shortlisted_student_count}
                          addressing_idx={item.addressing_id}
                        ></SameCollegeQuizShortlistList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        {" "}
                        Quiz Not Selected : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item?.quiz_count?.quiz_not_selected_student_count || 0} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeQuizNotSelectedList
                          appointment_idx={item.id}
                          counts={item?.quiz_count?.quiz_not_selected_student_count}
                          addressing_idx={item.addressing_id}
                        ></SameCollegeQuizNotSelectedList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        Quiz Rejected : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item?.quiz_count?.quiz_rejected_student_count || 0} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeQuizRejectlist
                          appointment_idx={item.id}
                          counts={item?.quiz_count?.quiz_rejected_student_count}
                          quizRejectedList="5"
                        ></SameCollegeQuizRejectlist>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        {" "}
                        GD Registered : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.gd_registered_count === null
                          ? 0
                          : item.gd_registered_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeGDRegisteredStudentList
                          appointment_idx={item.id}
                          counts={item.gd_registered_count}
                          gdRegistered="5"
                        ></SameCollegeGDRegisteredStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        GD Joined : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.gd_joined_count === null
                          ? 0
                          : item.gd_joined_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeGDJoinedStudentList
                          appointment_idx={item.id}
                          counts={item.gd_joined_count}
                          gdJoined="5"
                        ></SameCollegeGDJoinedStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        GD Not Joined : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.gd_not_joined_count === null
                          ? 0
                          : item.gd_not_joined_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeGDNotJoinedStudentList
                          appointment_idx={item.id}
                          counts={item.gd_not_joined_count}
                          gdNotJoined="5"
                        ></SameCollegeGDNotJoinedStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        GD Shortlist : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.gd_shortlisted_count === null
                          ? 0
                          : item.gd_shortlisted_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeGDShortlistStudentList
                          appointment_idx={item.id}
                          counts={item.gd_shortlisted_count}
                          gdShortlist="5"
                        ></SameCollegeGDShortlistStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        GD Reject : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.gd_rejected_count === null
                          ? 0
                          : item.gd_rejected_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeGDRejectStudentList
                          appointment_idx={item.id}
                          counts={item.gd_rejected_count}
                        ></SameCollegeGDRejectStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        GD Hold : &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.gd_hold_count === null ? 0 : item.gd_hold_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeGDHoldStudentList
                          appointment_idx={item.id}
                          counts={item.gd_hold_count}
                          gdHold="5"
                        ></SameCollegeGDHoldStudentList>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        Final Shortlist: &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.final_shortlist_count === null
                          ? 0
                          : item.final_shortlist_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeFinalShortlist
                          appointment_idx={item.id}
                          counts={item.final_shortlist_count}
                          finalShortlist="5"
                        ></SameCollegeFinalShortlist>
                      </p>
                    </p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: "35px",
                    }}
                  >
                    <p
                      style={{
                        display: "flex",
                        marginTop: "-1%",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#124076" }}>
                        Final Reject: &nbsp;
                      </span>
                      &nbsp;
                      <p>
                        {/* {item.final_reject_count === null
                          ? 0
                          : item.final_reject_count} */}
                      </p>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          fontSize: "1px",
                          marginTop: "1%",
                        }}
                      >
                        <SameCollegeRejectlist
                          appointment_idx={item.id}
                          counts={item.final_reject_count}
                          finalReject="5"
                        ></SameCollegeRejectlist>
                      </p>
                    </p>
                  </Typography>
                  {/* <Typography>
                    <TpoAppointmentListModal
                      group_idx={item.id}
                    ></TpoAppointmentListModal>
                  </Typography> */}
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <h1 style={{ color: "red", textAlign: "center" }}>No Data Found</h1>
        )}
      </Grid>
      <Pagination
        style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
        count={totalPages}
        sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
        page={page}
        onChange={handleChangePage}
      />
    </main>
  );
}
