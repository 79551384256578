import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  Box,
  Autocomplete,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

const PostEscalationLevel = () => {
  const navigate = useNavigate();

  const [searchRole, setSearchRole] = useState("");
  const [roleOption, setRoleOption] = useState([]);
  const [escalationName, setEscalationName] = useState("");
  const [selectedRoles, setSelectedRoles] = useState({ 1: null });

  useEffect(() => {
    let fetchData = async () => {
      try {
        const response = await AxiosInstance.get(
          `/roles?search_keyword=${searchRole}&limit=10`
        );
        const finalData = response.data.data.filter(
          (option) => option.id !== 2
        );
        setRoleOption(finalData);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchData();
  }, [searchRole]);

  const handleAddLevel = () => {
    const newLevel = Object.keys(selectedRoles).length + 1;
    setSelectedRoles((prev) => ({
      ...prev,
      [newLevel]: null,
    }));
  };

  const handleRemoveLevel = (level) => {
    const updatedSelectedRoles = { ...selectedRoles };
    delete updatedSelectedRoles[level];
    setSelectedRoles(updatedSelectedRoles);
  };

  const handleRoleSelect = (event, newValue, level) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [level]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const es_level = Object.keys(selectedRoles).map((level) => ({
      role: {
        id: selectedRoles[level].id,
        name: selectedRoles[level].name,
      },
      level: parseInt(level),
    }));

    const payload = {
      name: escalationName,
      es_level: es_level,
    };

    try {
      await AxiosInstance.post(`/escalation_type`, payload);
      toast.success("Escalation saved successfully");
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error || "Something went wrong.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <Box
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper
          elevation={10}
          style={{
            padding: 20,
            gap: 0,
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            margin: "10px auto",
            border:'1px solid gray',
            borderRadius:"10px"
          }}
        >
          <Grid align="center" item>
            <Avatar style={{ backgroundColor: "#1B4242" }}>
              <GroupAddIcon />
            </Avatar>
            <h2>Create Escalation Type</h2>
            <br />
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                marginTop: "1%",
              }}
            >
              <Grid
                style={{
                  color: "#1B4242",
                  fontWeight: "bold",
                  alignItems: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  sm={2}
                  style={{
                    color: "#1B4242",
                    fontWeight: "bold",
                  }}
                >
                  Name :
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    name="escalationName"
                    label="Escalation Name"
                    required
                    value={escalationName}
                    onChange={(e) => setEscalationName(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)", 
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)", 
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                lg={12}
                style={{
                  color: "#1B4242",
                  fontWeight: "bold",
                  fontSize: "20px",
                  padding: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#DDE6ED",
                  marginTop: "1%",
                  textTransform: "capitalize",
                }}
              >
                Escalation type
              </Grid>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                  padding:"5px",
                  border: "1px solid #06113C",
                  borderRadius: "8px",
                }}
              >
                {Object.keys(selectedRoles).map((level) => (
                  <Grid
                    key={level}
                    item
                    container
                    spacing={1}
                    style={{
                      marginTop: "5px",
                      margin: "0 auto",
                    }}
                  >
                    <Grid
                      item
                      sm={3}
                      style={{
                        color: "#1B4242",
                        fontWeight: "bold",
                        alignItems: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3>Escalation Level {level}</h3>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl sx={{ minWidth: 260 }} fullWidth>
                        <Item onChange={(e) => setSearchRole(e.target.value)}>
                          <Autocomplete
                            options={roleOption}
                            value={selectedRoles[level]}
                            name={`roleOption_${level}`}
                            onChange={(event, newValue) =>
                              handleRoleSelect(event, newValue, level)
                            }
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Role"
                                variant="outlined"
                                required
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)", 
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)", 
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                        </Item>
                      </FormControl>
                    </Grid>
                    <Grid item sm={2}>
                      <Button
                        size="small"
                        style={{
                          border: "none",
                          background: "crimson",
                          color: "#fff",
                          borderRadius: "5px",
                          marginTop: "10%",
                        }}
                        onClick={() => handleRemoveLevel(level)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Box>
              <Grid item sm={12}>
                <Button
                  style={{
                    border: "none",
                    background: "#87A922",
                    color: "#fff",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleAddLevel}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <Grid item container sm={12} spacing={4}>
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleCancelClick}
                  style={{
                    margin: "8px 0",
                    backgroundColor: "crimson",
                    color: "#fff",
                    border: "1px solid crimson",
                   
                  }}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{
                    margin: "8px 0",
                    backgroundColor: "#1B4242",
                    color: "#fff",
                    border: "1px solid #1B4242",
                  }}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default PostEscalationLevel;
