// import TextField from "@mui/material/TextField";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import { useState, useEffect } from "react";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { TiDelete } from "react-icons/ti";
// import { toast } from "react-toastify";
// import { Autocomplete, Avatar, Button, Chip, Grid, Paper,Box } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import styled from "@emotion/styled";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import AxiosInstance from "../../../apis/AxiosInstance";
// import { useNavigate } from "react-router-dom";
// import { FaEdit } from "react-icons/fa";
// import Modal from "@mui/material/Modal";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { keyboard } from "@testing-library/user-event/dist/keyboard";

// const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "auto",
//     bgcolor: "background.paper",
//     border: "2px solid #000",
//     p: 2,
//   };

// const EditGDEvalution = (props) => {
//     let { stateAddresssing } = props;
//   let navigate = useNavigate();
//   const [open, setOpen] =useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [confidence, setConfidence] = useState("");
//   const [communication, setCommunication] = useState("");
//   const [grammar, setGrammar] = useState("");
//   const [topic_knowledge, setTopic_knowledge] = useState("");
//   const [marks, setMarks] = useState("");
//   let [status, setStatus] = useState("");
//   let [statusOption, setStatusOption] = useState(null);
//   const [gdEvalutionData,setGDEvalutionData] = useState([])

//   //! Mobile field validation
//   const preventMinus = (e) => {
//     if (isNaN(e.key)) {
//       e.preventDefault();
//     }
//   };
//   const preventPasteNegative = (e) => {
//     const clipboardData = e.clipboardData || window.clipboardData;
//     const pastedData = parseFloat(clipboardData.getData("text"));
//     if (pastedData < 0) {
//       e.preventDefault();
//     }
//   };

//   //for appointmentStatus
//   useEffect(() => {
//     let fetchData = async () => {
//       let appStatus = await AxiosInstance.get(`/gd_status`);
//       let finalAppStatus = appStatus.data.data;
//       setStatusOption(finalAppStatus);
//     };
//     fetchData();
//   }, []);

//   useEffect(()=>{
// let fetchData=async ()=>{
//     const key=stateAddresssing.id
//     let data=await AxiosInstance.get(`/gd_student_marks?student_id=${key}`)

//     let finalData=data.data.data;
//     setMarks(finalData[0]?.gd_marks?.total_marks || '')
//     setCommunication(finalData[0]?.gd_marks?.communication || '')
//     setGrammar(finalData[0]?.gd_marks?.grammar || '')
//     setConfidence(finalData[0]?.gd_marks?.confidence
//       || '')
//       setTopic_knowledge(finalData[0]?.gd_marks?.topic_knowledge

//         || '')
//     setStatus(finalData[0]?.gd_marks?.gd_status ? Number(Object.keys(finalData[0].gd_marks.gd_status)[0]) : '')
//     setGDEvalutionData(finalData)

// }
// fetchData()
//   },[])

//   const resetAppStatus = async (e) => {
//     e.preventDefault();
//     setStatus("");
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const payload = {
//       group_id:gdEvalutionData[0].id,
//       student_id: [stateAddresssing.id],
//       confidence: Number.parseInt(confidence),
//       status: status,
//       communication: Number.parseInt(communication),
//       marks_for_each_criteria: Number.parseInt(marks),
//       grammar: Number.parseInt(grammar),
//       topic_knowledge: Number.parseInt(topic_knowledge),
//     };
//     try {
//       await AxiosInstance.post(`/gd_evaluation`, payload)
//         .then((response) => {
//           toast.success(response.data.message);

//           if (response.status == 200) {
//             setTimeout(() => {
//               // navigate("/");
//               window.location.reload();
//             }, 1000);
//           }
//         })
//         .catch((error) => {
//           console.log("error", error);
//           if (error.response) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error(error);
//           }
//         });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const paperStyle = {
//     padding: 20,
//     gap: 0,

//     width: "500px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//   };
//   const avatarStyle = { backgroundColor: "#081F37" };
//   const btnstyle = {
//     margin: "8px 0",
//     backgroundColor: "#081F37",
//     color: "#fff",
//     border: "1px solid #081F37",
//   };
//   const gridContainer = {
//     margin: "0 auto",
//     gap: 20,
//   };
//   // for DropDown ScrollBar

//   return (
//     <div>
//          <Tooltip title="Edit GD Evaluation">
//         <Button
//           onClick={handleOpen}
//           sx={{
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <FaEdit
//             fontSize={18}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//               cursor: "pointer",
//             }}
//           />
//         </Button>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >

//       <Grid style={{ marginTop: "5%" }}>
//       <Grid xs={12} item container style={gridContainer} align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>GD Evaluation</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid item container sm={12} spacing={2}>
//               <Grid item sm={12}>
//                 <TextField
//                   // color="#081F37"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   label="Marks For Each Criteria"
//                   type="tel"
//                   fullWidth
//                   onKeyPress={preventMinus}
//                   onPaste={preventPasteNegative}
//                   inputProps={{ minLength: 1, maxLength: 3 }}
//                   name="marks"
//                   value={marks}
//                   required
//                   onChange={(e) => setMarks(e.target.value)}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 <TextField
//                   //   color="warning"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   fullWidth
//                   type="tel"
//                   onKeyPress={preventMinus}
//                   onPaste={preventPasteNegative}
//                   inputProps={{ minLength: 1, maxLength: 3 }}
//                   name="communication"
//                   label="Communication"
//                   required
//                   value={communication}
//                   onChange={(e) => setCommunication(e.target.value)}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 <TextField
//                   //   color="warning"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   fullWidth
//                   type="tel"
//                   onKeyPress={preventMinus}
//                   onPaste={preventPasteNegative}
//                   inputProps={{ minLength: 1, maxLength: 3 }}
//                   name="grammar"
//                   label="Grammar"
//                   required
//                   value={grammar}
//                   onChange={(e) => setGrammar(e.target.value)}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 <TextField
//                   //   color="warning"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   type="tel"
//                   fullWidth
//                   onKeyPress={preventMinus}
//                   onPaste={preventPasteNegative}
//                   inputProps={{ minLength: 1, maxLength: 3 }}
//                   name="confidence"
//                   label="Confidence"
//                   required
//                   value={confidence}
//                   onChange={(e) => setConfidence(e.target.value)}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 <TextField
//                   //   color="warning"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   type="tel"
//                   fullWidth
//                   onKeyPress={preventMinus}
//                   onPaste={preventPasteNegative}
//                   inputProps={{ minLength: 1, maxLength: 3 }}
//                   name="topic_knowledge"
//                   label="Topic knowledge"
//                   required
//                   value={topic_knowledge}
//                   onChange={(e) => setTopic_knowledge(e.target.value)}
//                 />
//               </Grid>

//               <Grid
//                 item
//                 sm={12}
//                 id="refreshTool"
//                 style={{ position: "relative" }}
//               >
//                 <FormControl fullWidth size="small">
//                   <InputLabel id="demo-simple-select-label">Status</InputLabel>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     required
//                     value={status}
//                     label="status"
//                     onChange={(e) => {
//                       setStatus(e.target.value);
//                     }}
//                   >
//                     {statusOption === null ? (
//                       <MenuItem>No data Available</MenuItem>
//                     ) : (
//                       statusOption.map((e) => {
//                         return (
//                           <MenuItem value={e.id} key={"status" + e.id}>
//                             {e.name}
//                           </MenuItem>
//                         );
//                       })
//                     )}
//                   </Select>
//                   {status !== "" ? (
//                     <Tooltip title="Clear Status">
//                       <div
//                         style={{
//                           position: "absolute",
//                           top: "9px",
//                           left: "380px",
//                           color: "#CC7351",
//                           cursor: "pointer",
//                         }}
//                       >
//                         <TiDelete
//                           fontSize={24}
//                           title="Reset Status"
//                           onClick={resetAppStatus}
//                         />
//                       </div>
//                     </Tooltip>
//                   ) : null}
//                 </FormControl>
//               </Grid>

//               <Grid item container sm={12} spacing={4}>
//                 <Grid item sm={12}>
//                   <Button
//                     type="submit"
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle}
//                     fullWidth
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Grid>
//     </Modal>
//     </div>

//   );
// };

// export default EditGDEvalution;

import React, { useMemo, useState, useEffect } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Avatar,
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  Paper,
  Tooltip,
  Modal,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";
import { FaEdit } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

//====================================

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditGDEvalution(props) {
  const Navigate = useNavigate();
  let { stateAddresssing } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [start_date_time, setStart_date_time] = useState(
    moment
      .utc(stateAddresssing.start_date_time)
      .local()
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
  );
  const [end_date_time, setEnd_date_time] = useState(
    moment
      .utc(stateAddresssing.end_date_time)
      .local()
      .format("YYYY-MM-DDTHH:mm:ss.SSS")
  );

  const [is_shortlisted_candidates, setIs_shortlisted_candidates] =
    useState(false);

  const [colgOptions, setColgOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // all appointments
  const [selectedCollege_nameDate, setSelectedCollege_nameDate] =
    useState(null);

  const [studentOptions, setStudentOptions] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const [addInterviewerOptions, setInterviewerOptions] = useState([]);
  const [interviewer, setInerviewer] = useState([]);
  const [testInterviewer, setTestInterviewer] = useState([]);
  const [testStudent, setTestStudent] = useState([]);
  const [searchInterviewer, setSearchInterviewer] = useState("");

  const [searchStudentName, setSearchStudentname] = useState("");

  const handleShortListCandidates = (event) => {
    setIs_shortlisted_candidates(event.target.checked);
  };

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(
    stateAddresssing.is_offline ? 1 : 2
  );

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);
  // useEffect(() => {
  //   let fetchData = async () => {
  //     // Addressing Faculty api
  //     const keys = Object.keys(stateAddresssing.interviewer);
  //     try {
  //       if (keys.length > 0) {
  //         let addFacultyData = await AxiosInstance.get(
  //           `/user_mini?role_ids=3&user_id=${keys
  //             .toString()
  //             .split(",")
  //             .join("&user_id=")}`
  //         );
  //         let finalAddFaculty = addFacultyData.data.data
  //           ? addFacultyData.data.data
  //           : [];
  //         setInerviewer(finalAddFaculty);
  //         setTestInterviewer(finalAddFaculty);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       if (keys.length > 0) {
  //         setInerviewer([]);
  //         setTestInterviewer([]);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchInterviewer !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&search_keyword=${searchInterviewer}&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setInterviewerOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setInterviewerOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchInterviewer]);

  // useEffect(() => {
  //   let fetchData = async () => {
  //     const keys = stateAddresssing.id

  //     try {
  //       if (Object.keys(stateAddresssing.student_details).length < 1) {
  //         setStudentName([]);
  //       } else {
  //         let addFacultyData = await AxiosInstance.get(
  //           `/gd_registered_students_list?group_id=${keys}`
  //         );
  //         let finalAddFaculty = addFacultyData.data.data;
  //         setStudentName(finalAddFaculty);
  //         setTestStudent(finalAddFaculty);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       // if (keys.length > 0) {
  //       //   setStudentName([]);
  //       // setTestStudent([]);
  //       // }
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    let fetchData = async () => {
      const keyData = Object.keys(stateAddresssing.appointment_name);

      if (searchStudentName !== "" && searchStudentName.length > 2) {
        let addFacultyData = await AxiosInstance.get(
          `/registered_students?appointment_id=${keyData}&search_keyword=${searchStudentName}&limit=10`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setStudentOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/registered_students?appointment_id=${keyData}&limit=10`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setStudentOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchStudentName]);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchTerm !== "") {
        let groupData = await AxiosInstance.get(
          `/group_list?search_keyword=${searchTerm}&limit=10`
        );
        let finalGroupData = groupData.data.data;
        if (finalGroupData) {
          setColgOptions(finalGroupData);
        }
      } else {
        let groupData = await AxiosInstance.get(`group_list?limit=10`);
        let finalGroupData = groupData.data.data;
        setColgOptions(finalGroupData);
      }
    };
    fetchData();
  }, [searchTerm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    //Remove Interviewers
    // const newInterviewer =
    //   interviewer.length > 0 ? interviewer.map((e) => e.id.toString()) : [];
    // const removedInteriewer =
    //   interviewer.length > 0 &&
    //   newInterviewer &&
    //   Object.keys(stateAddresssing.interviewer).length > 0
    //     ? Object.keys(stateAddresssing.interviewer).filter(
    //         (ex) => !newInterviewer.includes(ex)
    //       )
    //     : [];
    //Remove Students
    // const newStudents =
    //   studentName.length > 0 ? studentName.map((e) => e.id.toString()) : [];
    // const removeStudents =
    //   studentName.length > 0 &&
    //   newStudents &&
    //   Object.keys(stateAddresssing.student_details).length > 0
    //     ? Object.keys(stateAddresssing.student_details).filter(
    //         (ex) => !newStudents.includes(ex)
    //       )
    //     : [];
    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    try {
      const gdtDateTime = moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
      const gdEndDateTime = moment(end_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

      formData.append("id", stateAddresssing.id);
      // formData.append("group_name",groupName)
      if (start_date_time !== "") {
        if (
          moment
            .utc(stateAddresssing.start_date_time)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS") !== start_date_time
        ) {
          formData.append("gd_start_date", gdtDateTime.split(" ")[0]);
          formData.append(
            "gd_start_time",
            gdtDateTime.split(" ").slice(1).join(" ")
          );
        }
      }
      if (end_date_time !== "") {
        if (
          moment
            .utc(stateAddresssing.end_date_time)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS") !== end_date_time
        ) {
          formData.append("gd_end_date", gdEndDateTime.split(" ")[0]);
          formData.append(
            "gd_end_time",
            gdEndDateTime.split(" ").slice(1).join(" ")
          );
        }
      }
      let gdStatus = stateAddresssing.is_offline ? 1 : 2;
      if (examTypeList != gdStatus) {
        formData.append("is_offline", examTypeList == 1 ? "true" : "false");
      }

      const hasExtraObjectsInArray2 = interviewer.some(
        (obj2) => !testInterviewer.some((obj1) => obj1.id === obj2.id)
      );

      if (testInterviewer.length > 0 || interviewer.length > 0) {
        if (hasExtraObjectsInArray2) {
          interviewer.map((value) => {
            formData.append("add_interviewer_id", value.id);
          });
        }
      }

      const studentsToAdd = studentName.filter(
        (obj2) => !testStudent.some((obj1) => obj1.id === obj2.id)
      );

      if (studentsToAdd.length > 0) {
        studentsToAdd.forEach((value) => {
          formData.append("add_student", value.id);
        });
      }

      // if (removedInteriewer.length > 0) {
      //   removedInteriewer.forEach((e) => {
      //     formData.append("remove_interviewer_id", Number.parseInt(e));
      //   });
      // }
      // if (removeStudents.length > 0) {
      //   removeStudents.forEach((e) => {
      //     formData.append("remove_student", Number.parseInt(e));
      //   });
      // }

      const dateTimeToSeconds = (dateTime) => {
        return dateTime.getTime() / 1000;
      };
      const todayDate = new Date();
      const startDate = new Date(gdtDateTime);
      const endDate = new Date(gdEndDateTime);
      const today = dateTimeToSeconds(todayDate);
      const start = dateTimeToSeconds(startDate);
      const end = dateTimeToSeconds(endDate);
      if (end > start && end > today) {
        await AxiosInstance.patch("/gd_group", formData, config, {
          Authorization: `${token}`,
        })
          .then((response) => {
            if (response.status == 200) {
              toast.success("GD Updated Successfully");
              setTimeout(() => {
                Navigate("/");
              }, 1000);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } else {
        if (end <= today) {
          toast.warn("End date should be always greater than todays date");
        } else {
          toast.warn("End date should be always greater than start date");
        }
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,

    width: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#081F37" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#081F37",
    color: "#fff",
    border: "1px solid #081F37",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  // for DropDown ScrollBar

  return (
    <div>
      <Tooltip title="Edit GD Evaluation">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <FaEdit
            fontSize={18}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
            }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid style={{ marginTop: "5%" }}>
          <Grid xs={12} item container style={gridContainer} align="center">
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center" item>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <AiFillCloseSquare
                    fontSize={30}
                    onClick={handleClose}
                    color="#FC2947"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Avatar style={avatarStyle}>
                  <GroupAddIcon />
                </Avatar>
                <h2>GD Evaluation</h2>
                <br />
              </Grid>
              <form onSubmit={handleSubmit}>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={3}
                      sx={{ alignItems: "center", display: "flex" }}
                    >
                      GD Start DateTime :
                    </Grid>
                    <Grid Grid item xs={9}>
                      <FormControl style={{ width: "80%" }}>
                        <TextField
                          name="start_date_time"
                          required
                          fullWidth
                          type="datetime-local"
                          id="start_date_time"
                          value={start_date_time}
                          onChange={(e) => setStart_date_time(e.target.value)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sx={{ alignItems: "center", display: "flex" }}
                    >
                      GD End DateTime :
                    </Grid>
                    <Grid Grid item xs={9}>
                      <FormControl style={{ width: "80%" }}>
                        <TextField
                          name="end_date_time"
                          required
                          fullWidth
                          type="datetime-local"
                          id="end_date_time"
                          value={end_date_time}
                          onChange={(e) => setEnd_date_time(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ alignItems: "center", display: "flex" }}
                    >
                      Mode Of GD :
                    </Grid>

                    <Grid item xs={9}>
                      <FormControl style={{ width: "80%" }}>
                        {/* <FormControl fullWidth> */}
                        <InputLabel id="demo-simple-select-label">
                          Mode Of GD
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={examTypeList}
                          label="Mode Of GD"
                          onChange={(e) => {
                            setExamTypeList(e.target.value);
                          }}
                        >
                          {Object.entries(examTypeListOption).map(
                            ([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {/* </FormControl> */}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ alignItems: "center", display: "flex" }}
                    >
                      Interviewer :
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl style={{ width: "81%" }}>
                        <Item
                          onChange={(e) => {
                            setSearchInterviewer(e.target.value);
                          }}
                        >
                          <Autocomplete
                            required
                            multiple
                            options={addInterviewerOptions?addInterviewerOptions:[]}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.full_name}
                            renderOption={(props, option) => (
                              <li {...props}>{option.full_name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Multiple Interviewer"
                                placeholder="Select Multiple Interviewer"
                              />
                            )}
                            value={interviewer}
                            onChange={(e, value) => {
                              setInerviewer([...value]);
                            }}
                          />
                        </Item>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      sx={{ alignItems: "center", display: "flex" }}
                    >
                      Student Name:
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl style={{ width: "81%" }}>
                        <Item
                          onChange={(e) => {
                            setSearchStudentname(e.target.value);
                          }}
                        >
                          <Autocomplete
                            required
                            multiple
                            options={studentOptions?studentOptions:[]}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.full_name}
                            renderOption={(props, option) => (
                              <li {...props}>{option.full_name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Select Multiple Students"
                                placeholder="Select Multiple Students"
                              />
                            )}
                            value={studentName}
                            onChange={(e, value) => {
                              setStudentName(value);
                            }}
                          />
                        </Item>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Checkbox
                        name="is_shortlisted_candidates"
                        checked={is_shortlisted_candidates}
                        onChange={handleShortListCandidates}
                        id="is_shortlisted_candidates"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                      />{" "}
                      <label
                        htmlFor="shortListCandidates"
                        style={{ fontSize: "16px" }}
                      >
                        ShortListed Candidates
                      </label>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    spacing={2}
                    sx={{ mt: 5, mb: 2 }}
                    style={{
                      textAlign: "center",
                      padding: "5px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ width: "20%" }}
                    >
                      Assign
                    </Button>
                  </Grid>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </div>
    // <section style={{ width: "100%", height: "auto" }}>
    //   <article
    //     style={{
    //       width: "95%",
    //       height: "auto",
    //       margin: "0 auto",
    //       marginTop: "5%",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         height: "6vh",
    //         width: "70%",
    //         margin: "0 auto",
    //         background: "#DDE6ED",
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         borderBottom: "2px solid #9BA4B5",
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           flexBasis: "70%",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //           color: "#081f37",
    //           fontSize: "34px",
    //           textTransform: "capitalize",
    //           fontWeight: "600",
    //           letterSpacing: "1.02px",
    //         }}
    //       >
    //         GD Edit
    //       </Box>
    //     </Box>
    //     <Container
    //       component="main"
    //       style={{
    //         width: "70%",
    //         borderRadius: "10px",
    //       }}
    //     >
    //       <Paper
    //         variant="outlined"
    //         style={{ border: "1px solid #9BA4B5", width: "100%" }}
    //       >
    //         <form
    //           onSubmit={handleSubmit}
    //           style={{
    //             padding: "3%",
    //           }}
    //         >
    //           <Box sx={{ mt: 2 }}>
    //             <Grid container spacing={3}>
    //               <Grid
    //                 item
    //                 xs={3}
    //                 sx={{ alignItems: "center", display: "flex" }}
    //               >
    //                 GD Start DateTime :
    //               </Grid>
    //               <Grid Grid item xs={9}>
    //                 <FormControl style={{ width: "80%" }}>
    //                   <TextField
    //                     name="start_date_time"
    //                     required
    //                     fullWidth
    //                     type="datetime-local"
    //                     id="start_date_time"
    //                     value={start_date_time}
    //                     onChange={(e) => setStart_date_time(e.target.value)}
    //                   />
    //                 </FormControl>
    //               </Grid>

    //               <Grid
    //                 item
    //                 xs={3}
    //                 sx={{ alignItems: "center", display: "flex" }}
    //               >
    //                 GD End DateTime :
    //               </Grid>
    //               <Grid Grid item xs={9}>
    //                 <FormControl style={{ width: "80%" }}>
    //                   <TextField
    //                     name="end_date_time"
    //                     required
    //                     fullWidth
    //                     type="datetime-local"
    //                     id="end_date_time"
    //                     value={end_date_time}
    //                     onChange={(e) => setEnd_date_time(e.target.value)}
    //                   />
    //                 </FormControl>
    //               </Grid>
    //               <Grid
    //                 item
    //                 xs={3}
    //                 sx={{ alignItems: "center", display: "flex" }}
    //               >
    //                 Mode Of GD :
    //               </Grid>

    //               <Grid item xs={9}>
    //                 <FormControl style={{ width: "80%" }}>
    //                   {/* <FormControl fullWidth> */}
    //                   <InputLabel id="demo-simple-select-label">
    //                     Mode Of GD
    //                   </InputLabel>
    //                   <Select
    //                     labelId="demo-simple-select-label"
    //                     id="demo-simple-select"
    //                     value={examTypeList}
    //                     label="Mode Of GD"
    //                     onChange={(e) => {
    //                       setExamTypeList(e.target.value);
    //                     }}
    //                   >
    //                     {Object.entries(examTypeListOption).map(
    //                       ([key, value]) => (
    //                         <MenuItem key={key} value={key}>
    //                           {value}
    //                         </MenuItem>
    //                       )
    //                     )}
    //                   </Select>
    //                   {/* </FormControl> */}
    //                 </FormControl>
    //               </Grid>
    //               <Grid
    //                 item
    //                 xs={3}
    //                 sx={{ alignItems: "center", display: "flex" }}
    //               >
    //                 Interviewer :
    //               </Grid>
    //               <Grid item xs={9}>
    //                 <FormControl style={{ width: "81%" }}>
    //                   <Item
    //                     onChange={(e) => {
    //                       setSearchInterviewer(e.target.value);
    //                     }}
    //                   >
    //                     <Autocomplete
    //                       required
    //                       multiple
    //                       options={addInterviewerOptions}
    //                       disableCloseOnSelect
    //                       getOptionLabel={(option) => option.full_name}
    //                       renderOption={(props, option) => (
    //                         <li {...props}>{option.full_name}</li>
    //                       )}
    //                       renderInput={(params) => (
    //                         <TextField
    //                           {...params}
    //                           variant="outlined"
    //                           label="Select Multiple Interviewer"
    //                           placeholder="Select Multiple Interviewer"
    //                         />
    //                       )}
    //                       value={interviewer}
    //                       onChange={(e, value) => {
    //                         setInerviewer([...value]);
    //                       }}
    //                     />
    //                   </Item>
    //                 </FormControl>
    //               </Grid>

    //               <Grid
    //                 item
    //                 xs={3}
    //                 sx={{ alignItems: "center", display: "flex" }}
    //               >
    //                 Student Name:
    //               </Grid>
    //               <Grid item xs={9}>
    //                 <FormControl style={{ width: "81%" }}>
    //                   <Item
    //                     onChange={(e) => {
    //                       setSearchStudentname(e.target.value);
    //                     }}
    //                   >
    //                     <Autocomplete
    //                       required
    //                       multiple
    //                       options={studentOptions}
    //                       disableCloseOnSelect
    //                       getOptionLabel={(option) => option.full_name}
    //                       renderOption={(props, option) => (
    //                         <li {...props}>{option.full_name}</li>
    //                       )}
    //                       renderInput={(params) => (
    //                         <TextField
    //                           {...params}
    //                           variant="outlined"
    //                           label="Select Multiple Students"
    //                           placeholder="Select Multiple Students"
    //                         />
    //                       )}
    //                       value={studentName}
    //                       onChange={(e, value) => {
    //                         setStudentName(value);
    //                       }}
    //                     />
    //                   </Item>
    //                 </FormControl>
    //               </Grid>
    //               <Grid item>
    //                 <Checkbox
    //                   name="is_shortlisted_candidates"
    //                   checked={is_shortlisted_candidates}
    //                   onChange={handleShortListCandidates}
    //                   id="is_shortlisted_candidates"
    //                   sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
    //                 />{" "}
    //                 <label
    //                   htmlFor="shortListCandidates"
    //                   style={{ fontSize: "16px" }}
    //                 >
    //                   ShortListed Candidates
    //                 </label>
    //               </Grid>
    //             </Grid>
    //             <Grid
    //               xs={12}
    //               item
    //               spacing={2}
    //               sx={{ mt: 5, mb: 2 }}
    //               style={{
    //                 textAlign: "center",
    //                 padding: "5px",
    //               }}
    //             >
    //               <Button
    //                 type="submit"
    //                 variant="contained"
    //                 style={{ width: "20%" }}
    //               >
    //                 Assign
    //               </Button>
    //             </Grid>
    //           </Box>
    //         </form>
    //       </Paper>
    //     </Container>
    //   </article>
    // </section>
  );
}
