import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function RegisteredStudents(props) {
  let { appointmentId, total_students } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [registeredStudents, setRegisteredStudents] = useState([]);
  useEffect(() => {
    let fetchData = async () => {
      let regStudents = await AxiosInstance.get(
        `/get_appointment_student_details?appointment_id=${appointmentId}`
      );
      let finalRegStudents = regStudents.data.data;
      setRegisteredStudents(finalRegStudents);
    };
    fetchData();
  }, [appointmentId]);

  return (
    <div>
      <Tooltip title="Registered Students">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >        
          {props.total_students}/{props.profile_updated}
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Registered Student Details
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: "#495579" }}>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Sl.No
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Mobile
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {registeredStudents == null || registeredStudents.length < 1 ? (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" colSpan={4}>
                      NO Data Found
                    </TableCell>
                  </TableRow>
                ) : (
                  registeredStudents.map((val, ind) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{ind + 1}</TableCell>
                        <TableCell align="center">
                          {val.full_name === null || val.full_name === ""
                            ? "N/A"
                            : val.full_name}
                        </TableCell>
                        <TableCell align="center">
                          {val.mobiles === null || val.mobiles === ""
                            ? "N/A"
                            : val.mobiles}
                        </TableCell>
                        <TableCell align="center">
                          {val.emails === null || val.emails === ""
                            ? "N/A"
                            : val.emails}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
