import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tooltip,
  Pagination,
} from "@mui/material";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: theme.palette.common.white,
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PostAddressingList() {
  const [postaddressingApi, setPostAddressingApi] = useState([]);

  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchData = async () => {
      setLoad(true);
      try {
        let quizData = await AxiosInstance.get(`/post_addressing?page=${page}`);
        let finalData = quizData.data.data;
        setPostAddressingApi(finalData);
        setTotalLength(quizData.data.filtered_count);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, [page]);

  const totalPages = Math.ceil(totalLength / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <section style={{ marginTop: "4.3%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          Onboarding List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalLength}
        </h1>
      </Box>

      <section
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          whiteSpace: "nowrap",
          borderRadius: "10px",
          marginTop: "0.5%",
        }}
      >
        <TableContainer component={Paper}  style={{ maxHeight: window.innerWidth > 1800 ? 680 : 540 }}>
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "90px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Addressing Faculty
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Mode Of Addressing
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "400px !important",
                  }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  Videos Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "260px !important",
                  }}
                >
                  Videos Start Date & Time
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "260px !important",
                  }}
                >
                  Videos End Date & Time
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Edit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "auto" }}>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={9} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : postaddressingApi && postaddressingApi.length > 0 ? (
                postaddressingApi.map((row, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.addressing_faculty).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.addressing_faculty).map((ea) => {
                          return (
                            <Box style={{ height: "auto" }}>{ea?.["name"]}</Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {row.is_offline === true ? "Offline" : "Online"}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.appointment_ids).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.appointment_ids).map((ea) => {
                          return (
                            <Box style={{ height: "auto" }}>
                              <Tooltip title={ea?.college.value}>
                                <p>{ea?.college.value.slice(0,45)}</p>
                              </Tooltip>
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>

                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.videos).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.videos).map((ea) => {
                          return (
                            <Box style={{ height: "auto" }}>{ea?.["name"]}</Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.videos).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.videos).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {moment(ea?.["start_time"])
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell> */}
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.videos).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.videos).map((ea, index) => {
                          const dateTime = ea?.["start_time"];
                          const formattedDateTime = moment(
                            dateTime,
                            "YYYY-MM-DD HH:mm:ss Z"
                          )
                            .local()
                            .format("DD-MM-yyyy hh:mm A");
                          return (
                            <Box key={index} style={{ height: "auto" }}>
                              {formattedDateTime}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.videos).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.videos).map((ea) => {
                          return (
                            <Box style={{ height: "22px" }}>
                              {moment(ea?.["end_time"])
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell> */}
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(row.videos).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(row.videos).map((ea, index) => {
                          const endTime = ea?.["end_time"];
                          const formattedEndTime = moment(
                            endTime,
                            "YYYY-MM-DD HH:mm:ss Z"
                          )
                            .local()
                            .format("DD-MM-yyyy hh:mm A");
                          return (
                            <Box key={index} style={{ height: "auto" }}>
                              {formattedEndTime}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="Edit Onboarding">
                        <Link to="/edit_post_addressing" state={row}>
                          <FaEdit
                            fontSize={18}
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%",display:"flex",margin:"0.5% auto",justifyContent:"center",alignItems:"center" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </section>
  );
}
