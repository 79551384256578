import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  TextareaAutosize,
  Button,
  CssBaseline,
  Grid,
  Box,
  Autocomplete,
  Container,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
  Select,
} from "@mui/material";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { styled } from "@mui/material/styles";
import AxiosInstance from "../../src/apis/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/core/styles";
import encodeSpl from "../utils/encodeSpl";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FE7A36",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FE7A36",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FE7A36",
    },
    "& .MuiInputLabel-root": {
      color: "#FE7A36",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#FE7A36",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function PersonalDetails() {
  const classes = useStyles();

  let navigate = useNavigate();

  let locationState = useLocation();
  const state = locationState?.state?.data || null;
  const page = locationState?.state?.page || null;
  const filter = locationState?.state?.filter || null;
  const path = locationState?.state?.path || null;
  const pin = locationState?.state?.pin || null;
  const handleCancelClick = () => {
    if (state && page && path) {
      if (pin) {
        const pathTogo = filter
          ? `${path}?id=${pin}&page=${page}&filter=${encodeSpl(filter)}`
          : `${path}?id=${pin}&page=${page}`;
        window.sessionStorage.removeItem("filter");
        navigate(pathTogo);
      } else {
        const pathTogo = filter
          ? `${path}?page=${page}&filter=${encodeSpl(filter)}`
          : `${path}?page=${page}`;
        window.sessionStorage.removeItem("filter");
        navigate(pathTogo);
      }
    } else {
      window.history.back();
    }
  };
  const [fullName, setFullName] = useState(state.full_name);
  const [dob, setDob] = useState(state.other_fields.student.dob);
  const [gender, setGender] = useState("");

  const [genderOption, setGenderOption] = useState([]);
  const [uploadPhoto, setUploadPhoto] = useState("");

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [stateData, setStateData] = useState(
    state?.other_fields.student?.current_address?.state &&
      Object.keys(state.other_fields.student.current_address.state).length > 0
      ? state.other_fields.student.current_address.state
      : null
  );
  const [districtData, setDistrictData] = useState(
    state?.other_fields.student?.current_address?.district &&
      Object.keys(state.other_fields.student.current_address.district).length >
        0
      ? state.other_fields.student.current_address.district
      : null
  );
  const [pincodeData, setPincodeData] = useState(
    state?.other_fields.student?.current_address?.pincode &&
      Object.keys(state.other_fields.student.current_address.pincode).length > 0
      ? state.other_fields.student.current_address.pincode
      : null
  );
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [pincodeOption, setPincodeOption] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDistrict, setSearchDistrict] = useState("");
  const [serachPincode, setSerachPincode] = useState("");
  const [currentAddress, setCurrentAddress] = useState(
    state.other_fields.student.current_address.address
  );
  const [otherCurrentState, setOtherCurrentState] = useState(
    state?.other_fields?.student?.current_address?.other_state_name || ""
  );
  const [otherCurrentDistrict, setOtherCurrentDistrict] = useState(
    state?.other_fields?.student?.current_address?.other_district_name || ""
  );
  const [otherPincode, setOtherPincode] = useState(
    state?.other_fields?.student?.current_address?.other_pincode || ""
  );

  const [aadharCard, setAadharCard] = useState(
    state.other_fields.student.is_adharcard
  );
  const [passport, setPassport] = useState(
    state.other_fields.student.is_passport
  );
  const [panCard, setPanCard] = useState(state.other_fields.student.is_pancard);

  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    formData.append("id", state.id);
    formData.append("full_name", fullName);
    formData.append("dob", dob);
    formData.append("gender", gender);
    formData.append("is_adharcard", aadharCard);
    formData.append("is_passport", passport);
    formData.append("is_pancard", panCard);
    // formData.append("passport_photo", uploadPhoto);
    formData.append("current_address", currentAddress);
    if (
      stateData &&
      stateData !== state?.other_fields.student?.current_address?.state
    ) {
      formData.append("current_state_id", stateData.id);
    }
    if (
      otherCurrentState !==
      state?.other_fields?.student?.current_address?.other_state_name
    ) {
      formData.append("current_other_state_name", otherCurrentState);
    }
    if (
      districtData &&
      districtData !== state?.other_fields.student?.current_address?.district
    ) {
      formData.append("current_district_id", districtData.id);
    }
    if (
      otherCurrentDistrict !==
      state?.other_fields?.student?.current_address?.other_district_name
    ) {
      formData.append("current_other_district_name", otherCurrentDistrict);
    }
    if (
      pincodeData &&
      pincodeData !== state?.other_fields.student?.current_address?.pincode
    ) {
      formData.append("current_pincode_id", pincodeData.id);
    }
    if (pincodeData === 0) {
      formData.append("is_other_pincode", "");
    }
    formData.append("current_other_pincode", otherPincode);

    await AxiosInstance.patch("/update_students_data_analyst", formData, config)
      .then((data) => {
        toast.success("Personal Details Updated Successfully");
        setTimeout(() => {
          // navigate("/otherpincode_studentdetails?id=1");
          const pathTogo = filter
            ? `${path}?page=${page}&filter=${encodeSpl(filter)}`
            : `${path}?page=${page}`;
          window.sessionStorage.removeItem("filter");
          navigate(pathTogo);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  useEffect(() => {
    let fetchData = async () => {
      let genderData = await AxiosInstance.get(`/gender`);
      let finalData = genderData.data.data;
      setGenderOption(finalData);
      setGender(
        state.other_fields?.student?.gender
          ? finalData.filter((e) => e === state.other_fields.student.gender)
          : ""
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);
  //!fetching the apis
  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (searchTerm !== "") {
          // State api call
          let stateData_x = await AxiosInstance.get(
            `/statemini?search_keyword=${searchTerm}&limit=10&country_id=${countryMiniId}`
          );
          let finalStateData_X = stateData_x.data.data;
          setStateOption(finalStateData_X);
          setDistrictData(null);
          setPincodeData(null);
          setOtherPincode("");
          setOtherCurrentDistrict("");
        } else {
          // State api call
          let stateData_x = await AxiosInstance.get(
            `/statemini?limit=10&country_id=${countryMiniId}`
          );
          let finalStateData_X = stateData_x.data.data;
          setStateOption(finalStateData_X);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [searchTerm, countryMiniId]);

  //! for district filter from state
  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (stateData !== null) {
          if (searchDistrict !== "") {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?search_keyword=${searchDistrict}&limit=10&state_id=${stateData.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setDistrictOption(finalcolgDistrictData);
            setPincodeData(null);
            setOtherPincode("");
          } else {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?limit=10&state_id=${stateData.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setDistrictOption(finalcolgDistrictData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [searchDistrict, stateData]);

  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (districtData !== null) {
          if (serachPincode !== "") {
            let colgDistrictData = await AxiosInstance.get(
              `/pincode?search_keyword=${serachPincode}&limit=10&district_id=${districtData.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setPincodeOption(finalcolgDistrictData);
          } else {
            let colgDistrictData = await AxiosInstance.get(
              `/pincode?limit=10&district_id=${districtData.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setPincodeOption(finalcolgDistrictData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [serachPincode, districtData]);

  return (
    <section style={{ margin: "5% 0" }}>
      <Container component="main" maxWidth="80%">
        <Paper
          variant="outlined"
          md={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <div
            component="h1"
            variant="h5"
            style={{
              padding: "10px",
              fontSize: "22px",
              color: "white",
              backgroundColor: "#081F37",
              // backgroundColor: "rgb(251,116,62)",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Personal Details Form
          </div>
          <p
            style={{
              marginLeft: "80%",
              color: "#D24545",
              fontWeight: "bold",
              marginTop: "1%",
            }}
          >
            Mobile Number : {state.mobiles}
          </p>

          <Container component="main" maxWidth="80%">
            <CssBaseline />
            <Box sx={{ m: 5 }}>
              <form
                onSubmit={handleSubmit}
                style={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container lg={12} spacing={3}>
                  <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                    <Grid item md={3}>
                      <Item>
                        <TextField
                          name="fullName"
                          required
                          fullWidth
                          id="fullName"
                          label="Full Name"
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                        />
                      </Item>
                    </Grid>
                    <Grid item md={3}>
                      <Item>
                        <TextField
                          required
                          type="datetime-local"
                          name="dob"
                          fullWidth
                          id="dob"
                          label="Date Of Birth"
                          onFocus={(e) => (e.target.type = "date")}
                          onBlur={(e) => (e.target.type = "text")}
                          autoFocus
                          value={dob}
                          onChange={(e) => {
                            setDob(e.target.value);
                          }}
                        />
                      </Item>
                    </Grid>
                    <Grid item md={3}>
                      <Item>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Select Gender
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Gender"
                            value={gender}
                            required  
                            name="gender"
                            sx={{ textAlign: "left" }}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          >
                            {genderOption.map((key, value) => (
                              <MenuItem key={key} value={key}>
                                {key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Item>
                    </Grid>
                    <Grid item md={3}>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Upload Passport Size Photo
                      </FormLabel>

                      <Grid item md={3}>
                        <Item>
                          <input
                            disabled
                            type="file"
                            name="uploadPhoto"
                            value={uploadPhoto}
                            onchange={(e) => {
                              setUploadPhoto(e.target.value);
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Current Address */}
                  <Grid
                    style={{
                      width: "100%",
                      height: "1.5px",
                      borderBottom: "1px solid lightGrey",
                      margin: "1% 0 1% 0",
                    }}
                  ></Grid>
                  {/* //! Degree Information Starts */}
                  <div style={{ width: "100%" }}>
                    <Typography
                      component="h5"
                      variant="h6"
                      style={{
                        fontSize: "20px",
                        color: "rgb(248,151,28)",
                        backgroundColor: "#efefef",
                        padding: "0 0 0 10px",
                      }}
                    >
                      Current Address
                    </Typography>
                  </div>
                  <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                    {!otherCurrentState && (
                      <Grid item md={3}>
                        <Item onChange={searchData}>
                          <Autocomplete
                            options={stateOption}
                            value={stateData}
                            name="stateOption"
                            onChange={(event, newData_xy) => {
                              setStateData(newData_xy);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select - State"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Item>
                      </Grid>
                    )}
                    {(stateData?.id === 0 || otherCurrentState) && (
                      <Grid item md={3}>
                        <Item>
                          <TextField
                            name="otherCurrentState"
                            fullWidth
                            id="otherCurrentState"
                            label="Other Current State"
                            value={otherCurrentState}
                            className={classes.root}
                            onChange={(e) => {
                              setOtherCurrentState(e.target.value);
                            }}
                          />
                        </Item>
                      </Grid>
                    )}
                    {!otherCurrentDistrict && (
                      <Grid item md={3}>
                        <Item
                          onChange={(e) => {
                            setSearchDistrict(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={districtOption}
                            value={districtData}
                            name="districtOption"
                            onChange={(event, newData_xy) => {
                              setDistrictData(newData_xy);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select District"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Item>
                      </Grid>
                    )}
                    {(districtData?.id === 0 || otherCurrentDistrict) && (
                      <Grid item md={3}>
                        <Item>
                          <TextField
                            name="otherCurrentDistrict"
                            className={classes.root}
                            fullWidth
                            required
                            id="otherCurrentDistrict"
                            label="Other Current District "
                            value={otherCurrentDistrict}
                            onChange={(e) => {
                              setOtherCurrentDistrict(e.target.value);
                            }}
                          />
                        </Item>
                      </Grid>
                    )}
                    {!otherPincode && (
                      <Grid item md={3}>
                        <Item
                          onChange={(e) => {
                            setSerachPincode(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={pincodeOption}
                            value={pincodeData}
                            name="pincodeOption"
                            onChange={(event, newData_xy) => {
                              setPincodeData(newData_xy);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Pincode"
                                variant="outlined"                               
                              />
                            )}
                          />
                        </Item>
                      </Grid>
                    )}
                    {(pincodeData?.id === 0 || otherPincode) && (
                      <Grid item md={3}>
                        <Item>
                          <TextField
                            name="otherPincode"
                            className={classes.root}
                            type="tel"
                            fullWidth                          
                            onKeyPress={preventMinus}
                            onPaste={preventPasteNegative}
                            inputProps={{ minLength: 6, maxLength: 6 }}
                            id="otherPincode"
                            label="Other Current Pincode "
                            value={otherPincode}
                            onChange={(e) => {
                              setOtherPincode(e.target.value);
                            }}
                          />
                        </Item>
                      </Grid>
                    )}
                    <Grid item md={3}>
                      <Item>
                        <TextareaAutosize
                          name="currentAddress"
                          rowsMin={10}
                          fullWidth
                          id="currentAddress"
                          placeholder="Current Address"
                          value={currentAddress}
                          onChange={(e) => {
                            setCurrentAddress(e.target.value);
                          }}
                          style={{ width: "100%", height: "55px" }}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                    <Grid item md={3}>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ marginLeft: "2%" }}
                      >
                        Do you have Aadhar Card
                      </FormLabel>
                      <Item>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="aadharCard"
                          value={aadharCard}
                          onChange={(e) => {
                            setAadharCard(e.target.value);
                          }}
                          style={{ marginTop: "-2%" }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="YES"
                            name="aadharCard"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="NO"
                            name="aadharCard"
                            style={{ marginLeft: "10%" }}
                          />
                        </RadioGroup>
                      </Item>
                    </Grid>
                    <Grid item md={3}>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ marginLeft: "2%" }}
                      >
                        Do you have Passport
                      </FormLabel>
                      <Item>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="passport"
                          value={passport}
                          onChange={(e) => {
                            setPassport(e.target.value);
                          }}
                          style={{ marginTop: "-2%" }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="YES"
                            name="passport"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="NO"
                            name="passport"
                            style={{ marginLeft: "10%" }}
                          />
                        </RadioGroup>
                      </Item>
                    </Grid>
                    <Grid item md={3}>
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        style={{ marginLeft: "2%" }}
                      >
                        Do you have PAN Card
                      </FormLabel>
                      <Item>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="panCard"
                          value={panCard}
                          onChange={(e) => {
                            setPanCard(e.target.value);
                          }}
                          style={{ marginTop: "-2%" }}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="YES"
                            name="panCard"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="NO"
                            name="panCard"
                            style={{ marginLeft: "10%" }}
                          />
                        </RadioGroup>
                      </Item>
                    </Grid>
                  </Grid>

                  <Grid
                    md={12}
                    item
                    spacing={2}
                    sx={{ mt: 6, mb: 4 }}
                    style={{
                      alignItems: "center",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        width: "10%",
                        backgroundColor: "#D24545",
                        color: "#fff",
                      }}
                      onClick={handleCancelClick}
                    >
                      <HiArrowNarrowLeft
                        style={{
                          fontSize: "20px",
                          color: "white",
                        }}
                      />
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ width: "10%" }}
                    >
                      Submit
                      <HiArrowNarrowRight
                        style={{
                          fontSize: "20px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Paper>
      </Container>
    </section>
  );
}
