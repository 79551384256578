import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Box,
  Checkbox,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

const EditMainStatus = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [categoryName, setCategoryName] = useState(state.category);

  const [mainData, setMainData] = useState(
    state.call_status.map((mainStatus) => ({
      main_status: mainStatus.main_status,
      id: mainStatus.id,
      main_statusWeight: mainStatus.weight.toString(),
      main_disable: mainStatus.disable,
      subData: mainStatus?.sub_status?.map((subStatus) => ({
        subStatus: subStatus.sub_status,
        id: subStatus.id,
        subStatusWeight: subStatus.weight.toString(),
        positive_key: subStatus.positive,
      })) || {
        subStatus: "",
        id: "",
        subStatusWeight: "",
        positive_key: false,
      },
      showRemoveIcon: false,
    }))
  );

  const addMain = () => {
    setMainData([
      ...mainData,
      {
        main_status: "",
        main_statusWeight: "",
        main_disable: false,
        subData: [
          {
            subStatus: "",
            subStatusWeight: "",
            positive_key: false,
          },
        ],
        showRemoveIcon: true,
      },
    ]);
  };

  // const addField = (id) => {
  //   const arr = [...mainData];
  //   const target = { ...arr[id] };
  //   target["subData"] = [
  //     ...target["subData"],
  //     {
  //       subStatus: "",
  //       subStatusWeight: "",
  //     },
  //   ];
  //   arr[id] = target;
  //   setMainData(arr);
  // };

  const addField = (id) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    if (!Array.isArray(target["subData"])) {
      target["subData"] = [];
    }

    target["subData"] = [
      ...target["subData"],
      {
        subStatus: "",
        subStatusWeight: "",
      },
    ];
    arr[id] = target;
    setMainData(arr);
  };

  const removeSub = (id, index) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    target["subData"].splice(index, 1);
    arr[id] = target;
    setMainData(arr);
  };
  // const handleSubChange = (id, index, field) => {
  //   const { name, value } = field;
  //   const arr = [...mainData];
  //   const target = { ...arr[id] };
  //   const fields = [...target["subData"]];
  //   fields[index] = { ...fields[index], [name]: value };
  //   target["subData"] = fields;
  //   arr[id] = target;
  //   setMainData(arr);
  // };

  const handleSubChange = (id, index, field) => {
    const { name, value, type, checked } = field; // Destructure type and checked for checkbox
    const arr = [...mainData];
    const target = { ...arr[id] };
    const fields = [...target["subData"]];

    // Check if the field type is a checkbox
    if (type === "checkbox") {
      fields[index] = { ...fields[index], [name]: checked }; // Update checked value
    } else {
      fields[index] = { ...fields[index], [name]: value }; // For other types of fields
    }

    target["subData"] = fields;
    arr[id] = target;
    setMainData(arr);
  };

  // const handleMainChange = (index, name, value) => {
  //   const arr = [...mainData];
  //   const target = { ...arr[index] };
  //   target[name] = value;
  //   arr[index] = target;
  //   setMainData(arr);
  // };

  const handleMainChange = (index, field) => {
    const { name, value, type, checked } = field; // Destructure type and checked for checkbox
    const arr = [...mainData];
    const target = { ...arr[index] };

    // Check if the field type is a checkbox
    if (type === "checkbox") {
      target[name] = checked; // Update checked value
    } else {
      target[name] = value; // For other types of fields
    }

    arr[index] = target;
    setMainData(arr);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const callStatusData = mainData.map((mainItem) => {
      const subStatusData = mainItem.subData.map((subItem) => ({
        sub_status: subItem.subStatus,
        id: subItem.id,
        weight: parseInt(subItem.subStatusWeight),
        positive: subItem.positive_key,
      }));

      return {
        main_status: mainItem.main_status,
        id: mainItem.id,
        weight: parseInt(mainItem.main_statusWeight),
        disable: mainItem.main_disable,
        sub_status: subStatusData,
      };
    });

    const payload = {
      category_id: state.ID,
      call_status: callStatusData,
    };

    try {
      await AxiosInstance.post(`/call_main_status`, payload)
        .then((response) => {
          toast.success("Call Main Status Updated Successfully");
          setTimeout(() => {
            navigate("/call_category_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const removeMain = (index) => {
    const updatedMainData = [...mainData];
    updatedMainData.splice(index, 1);
    setMainData(updatedMainData);
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border:"1px solid gray",
    borderRadius:"10px"
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "18px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };

  const btnstyle1 = {
    margin: "18px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  // for DropDown ScrollBar

  return (
    <Box
      style={{
        marginTop: "4.1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Main Status</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                marginTop:"-2.5%"
              }}
            >
              <Grid item sm={12}>
                <TextField
                  size="small"
                  sx={{ minWidth: 210 }}
                  fullWidth
                  name="categoryName"
                  label="Category"
                  required
                  disabled
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Grid>
              <Box
                style={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {mainData.map((val, ind) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={1}
                      style={{
                        border: "1px solid #06113C",
                        marginTop: "5px",
                        margin: "0 auto",
                        marginLeft: "2%",
                        paddingBottom: "15px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        style={{
                          color: "blue",
                          textAlign: "start",
                        }}
                      >
                        Main Status
                      </Grid>
                      <Grid item sm={5} style={{ marginTop: "-0.1%" }}>
                        <TextField
                           sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                          fullWidth
                          name="main_status"
                          label="Main Status"
                          required
                          value={val.main_status}
                          // onChange={(e) =>
                          //   handleMainChange(ind, "main_status", e.target.value)
                          // }
                          onChange={(e) => handleMainChange(ind, e.target)}
                        />
                      </Grid>
                      <Grid item sm={4} style={{ marginTop: "-0.1%" }}>
                        <TextField
                           sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                          fullWidth
                          required
                          name="main_statusWeight"
                          label="Main Status Weight"
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={{ minLength: 1, maxLength: 2 }}
                          value={val.main_statusWeight}
                          // onChange={(e) =>
                          //   handleMainChange(
                          //     ind,
                          //     "main_statusWeight",
                          //     e.target.value
                          //   )
                          // }
                          onChange={(e) => handleMainChange(ind, e.target)}
                        />
                      </Grid>
                      <Grid item sm={2.5}>
                        <Checkbox
                          name="main_disable"
                          checked={val.main_disable}
                          onChange={(e) => handleMainChange(ind, e.target)}
                          id="main_disable"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 25 },
                          }}
                        />
                        <label
                          htmlFor="main_disable"
                          style={{ fontSize: "16px" }}
                        >
                          Disable
                        </label>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        style={{                      
                          color: "blue",
                          textAlign: "start",
                        }}
                      >
                        Sub Status
                      </Grid>
                      {val.subData.length > 0 ? (
                        val.subData.map((value, index) => {
                          return (
                            <Grid
                              item
                              container
                              sm={12}
                              spacing={2}
                              style={{
                                margin: "0 auto",
                                marginLeft: "1%",
                                paddingBottom: "15px",
                              }}
                            >
                              <Grid item sm={4.9} style={{ marginTop: "-1.5%" }}>
                                <TextField
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                  fullWidth
                                  name="subStatus"
                                  label="Sub Status"
                                  value={value.subStatus}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                />
                              </Grid>
                              <Grid item sm={3} style={{ marginTop: "-1.5%" }}>
                                <TextField
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                  fullWidth
                                  name="subStatusWeight"
                                  label="Sub Status Weight"
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                  value={value.subStatusWeight}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={2.5}
                                style={{ marginTop: "-1.5%" }}
                              >
                                <Checkbox
                                  name="positive_key"
                                  checked={value.positive_key}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                  id="positive_key"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />{" "}
                                <label
                                  htmlFor="positive_key"
                                  style={{ fontSize: "16px" }}
                                >
                                  Positive Key
                                </label>
                              </Grid>

                              {/* {
                                <Grid
                                  item
                                  sm={2}
                                  style={{ marginTop: "-3.5%" }}
                                >
                                  <Button
                                    style={{
                                      border: "none",
                                      background: "crimson",
                                      color: "#fff",
                                      borderRadius: "5px",
                                      marginTop: "10%",
                                    }}
                                    onClick={() => removeSub(ind, index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              } */}
                            </Grid>
                          );
                        })
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            color: "red",
                            padding: "5px 10px",
                            background: "lightgrey",
                            width: "100%",
                          }}
                        >
                          No Data Found
                        </p>
                      )}
                      <Grid item sm={2} style={{ marginTop: "-0.5%" }}>
                        <Button
                          style={{
                            border: "none",
                            background: "#87A922",
                            color: "#fff",
                            borderRadius: "5px",
                            // marginTop: "10%",
                          }}
                          onClick={() => addField(ind)}
                        >
                          Add
                        </Button>
                      </Grid>
                      {val.showRemoveIcon && (
                        <Grid
                          style={{ marginLeft: "99.8%" }}
                          onClick={() => removeMain(ind)}
                        >
                          <CancelIcon
                            color="error"
                            style={{ fontSize: "35px" }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Box>

              <Grid
                item
                container
                sm={12}
                style={{
                  marginLeft: "96.5%",
                  marginTop: "-15%",
                  cursor: "pointer",
                }}
              >
                <AddCircleOutlineIcon
                  onClick={addMain}
                  color="success"
                  style={{ fontSize: "35px" }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              sm={12}
              spacing={4}
              style={{ marginTop: "7.5%" }}
            >
              <Grid item sm={2} >
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default EditMainStatus;

// import { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import {
//   Avatar,
//   Button,
//   Grid,
//   Paper,
//   TextField,
//   Box,
//   Checkbox,
// } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import styled from "@emotion/styled";
// import AxiosInstance from "../apis/AxiosInstance";
// import { useLocation, useNavigate } from "react-router-dom";
// import CancelIcon from "@mui/icons-material/Cancel";

// const EditMainStatus = () => {
//   const navigate = useNavigate();
//   let { state } = useLocation();

//   const [categoryName, setCategoryName] = useState(state.category);

//   const [mainData, setMainData] = useState(
//     state.call_status.map((mainStatus) => ({
//       main_status: mainStatus.main_status,
//       id: mainStatus.id,
//       main_statusWeight: mainStatus.weight.toString(),
//       main_disable: mainStatus.disable,
//       subData: mainStatus?.sub_status?.map((subStatus) => ({
//         subStatus: subStatus.sub_status,
//         id: subStatus.id,
//         subStatusWeight: subStatus.weight.toString(),
//         positive_key: subStatus.positive,
//       })) || {
//         subStatus: "",
//         id: "",
//         subStatusWeight: "",
//         positive_key: false,
//       },
//       showRemoveIcon: false,
//     }))
//   );

//   const addMain = () => {
//     setMainData([
//       ...mainData,
//       {
//         main_status: "",
//         main_statusWeight: "",
//         main_disable: false,
//         subData: [
//           {
//             subStatus: "",
//             subStatusWeight: "",
//             positive_key: false,
//           },
//         ],
//         showRemoveIcon: true,
//       },
//     ]);
//   };

//   const addField = (id) => {
//     const arr = [...mainData];
//     const target = { ...arr[id] };
//     if (!Array.isArray(target["subData"])) {
//       target["subData"] = [];
//     }

//     target["subData"] = [
//       ...target["subData"],
//       {
//         subStatus: "",
//         subStatusWeight: "",
//       },
//     ];
//     arr[id] = target;
//     setMainData(arr);
//   };

//   const removeSub = (id, index) => {
//     const arr = [...mainData];
//     const target = { ...arr[id] };
//     target["subData"].splice(index, 1);
//     arr[id] = target;
//     setMainData(arr);
//   };

//   const handleSubChange = (id, index, field) => {
//     const { name, value, type, checked } = field; // Destructure type and checked for checkbox
//     const arr = [...mainData];
//     const target = { ...arr[id] };
//     const fields = [...target["subData"]];

//     // Check if the field type is a checkbox
//     if (type === "checkbox") {
//       fields[index] = { ...fields[index], [name]: checked }; // Update checked value
//     } else {
//       fields[index] = { ...fields[index], [name]: value }; // For other types of fields
//     }

//     target["subData"] = fields;
//     arr[id] = target;
//     setMainData(arr);
//   };

//   const handleMainChange = (index, field) => {
//     const { name, value, type, checked } = field; // Destructure type and checked for checkbox
//     const arr = [...mainData];
//     const target = { ...arr[index] };

//     // Check if the field type is a checkbox
//     if (type === "checkbox") {
//       target[name] = checked; // Update checked value
//     } else {
//       target[name] = value; // For other types of fields
//     }

//     arr[index] = target;
//     setMainData(arr);
//   };

//   //! Mobile field validation
//   const preventMinus = (e) => {
//     if (isNaN(e.key)) {
//       e.preventDefault();
//     }
//   };
//   const preventPasteNegative = (e) => {
//     const clipboardData = e.clipboardData || window.clipboardData;
//     const pastedData = parseFloat(clipboardData.getData("text"));
//     if (pastedData < 0) {
//       e.preventDefault();
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const callStatusData = mainData.map((mainItem) => {
//       const subStatusData = mainItem.subData.map((subItem) => ({
//         sub_status: subItem.subStatus,
//         id: subItem.id,
//         weight: parseInt(subItem.subStatusWeight),
//         positive: subItem.positive_key,
//       }));

//       return {
//         main_status: mainItem.main_status,
//         id: mainItem.id,
//         weight: parseInt(mainItem.main_statusWeight),
//         disable: mainItem.main_disable,
//         sub_status: subStatusData,
//       };
//     });

//     const payload = {
//       category_id: state.ID,
//       call_status: callStatusData,
//     };

//     try {
//       await AxiosInstance.post(`/call_main_status`, payload)
//         .then((response) => {
//           toast.success("Call Main Status Updated Successfully");
//           setTimeout(() => {
//             navigate("/call_category_list");
//           }, 1500);
//         })
//         .catch((error) => {
//           if (error.response.data) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error("Something Went wrong");
//           }
//         });
//     } catch (error) {
//       if (error.response.data) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something Went wrong");
//       }
//     }
//   };

//   const removeMain = (index) => {
//     const updatedMainData = [...mainData];
//     updatedMainData.splice(index, 1);
//     setMainData(updatedMainData);
//   };

//   const handleCancelClick = () => {
//     window.history.back();
//   };

//   const paperStyle = {
//     padding: 20,
//     gap: 0,
//     width: "1050px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//   };
//   const avatarStyle = { backgroundColor: "#1B4242" };
//   const btnstyle = {
//     margin: "18px 0",
//     backgroundColor: "#1B4242",
//     color: "#fff",
//     border: "1px solid #1B4242",
//   };

//   const btnstyle1 = {
//     margin: "18px 0",
//     backgroundColor: "crimson",
//     color: "#fff",
//     border: "1px solid crimson",
//   };

//   return (
//     <Box
//       style={{
//         marginTop: "5%",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         gap: "10px",
//       }}
//     >
//       <Grid xs={12} item container align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>Edit Main Status</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid
//               item
//               container
//               sm={12}
//               spacing={2}
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 gap: "10px",
//               }}
//             >
//               <Grid item sm={12}>
//                 <TextField
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   fullWidth
//                   name="categoryName"
//                   label="Category"
//                   required
//                   disabled
//                   value={categoryName}
//                   onChange={(e) => setCategoryName(e.target.value)}
//                 />
//               </Grid>
//               <Box
//                 style={{
//                   width: "95%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   gap: "10px",
//                 }}
//               >
//                 {mainData.map((val, ind) => {
//                   return (
//                     <Grid
//                       item
//                       container
//                       spacing={2}
//                       style={{
//                         border: "1px solid #06113C",
//                         marginTop: "5px",
//                         margin: "0 auto",
//                         marginLeft: "2%",
//                         paddingBottom: "15px",
//                         borderRadius: "20px",
//                       }}
//                     >
//                       <Grid
//                         item
//                         sm={12}
//                         style={{
//                           color: "blue",
//                           textAlign: "start",
//                         }}
//                       >
//                         Main Status
//                       </Grid>
//                       <Grid item sm={5} style={{ marginTop: "-1%" }}>
//                         <TextField
//                           sx={{ minWidth: 210 }}
//                           fullWidth
//                           name="main_status"
//                           label="Main Status"
//                           required
//                           value={val.main_status}
//                           onChange={(e) =>
//                             handleMainChange(ind, e.target)
//                           }
//                         />
//                       </Grid>
//                       <Grid item sm={4} style={{ marginTop: "-1%" }}>
//                         <TextField
//                           sx={{ minWidth: 210 }}
//                           fullWidth
//                           required
//                           name="main_statusWeight"
//                           label="Main Status Weight"
//                           onKeyPress={preventMinus}
//                           onPaste={preventPasteNegative}
//                           value={val.main_statusWeight}
//                           onChange={(e) =>
//                             handleMainChange(ind, e.target)
//                           }
//                         />
//                       </Grid>
//                       <Grid
//                         item
//                         sm={3}
//                         style={{ display: "flex", alignItems: "center" }}
//                       >
//                         <Checkbox
//                           name="main_disable"
//                           color="primary"
//                           checked={val.main_disable}
//                           onChange={(e) =>
//                             handleMainChange(ind, e.target)
//                           }
//                         />
//                         Disable
//                         {val.showRemoveIcon && (
//                           <Button
//                             onClick={() => removeMain(ind)}
//                             color="error"
//                           >
//                             <CancelIcon />
//                           </Button>
//                         )}
//                       </Grid>

//                       <Grid
//                         item
//                         container
//                         sm={12}
//                         spacing={2}
//                         style={{
//                           display: "flex",
//                           justifyContent: "center",
//                           gap: "10px",
//                           marginTop: "5px",
//                         }}
//                       >
//                         <Grid
//                           item
//                           container
//                           style={{
//                             display: "flex",
//                             justifyContent: "start",
//                             marginTop: "15px",
//                             marginBottom: "15px",
//                             marginLeft: "15px",
//                             fontSize: "16px",
//                             color: "blue",
//                           }}
//                         >
//                           Sub Status
//                         </Grid>
//                         {val.subData &&
//                           val.subData.map((subVal, index) => {
//                             return (
//                               <>
//                                 <Grid
//                                   item
//                                   sm={5}
//                                   style={{ marginTop: "-1%" }}
//                                 >
//                                   <TextField
//                                     sx={{ minWidth: 210 }}
//                                     fullWidth
//                                     required
//                                     name="subStatus"
//                                     label="Sub Status"
//                                     value={subVal.subStatus}
//                                     onChange={(e) =>
//                                       handleSubChange(ind, index, e.target)
//                                     }
//                                   />
//                                 </Grid>
//                                 <Grid
//                                   item
//                                   sm={4}
//                                   style={{ marginTop: "-1%" }}
//                                 >
//                                   <TextField
//                                     sx={{ minWidth: 210 }}
//                                     fullWidth
//                                     name="subStatusWeight"
//                                     label="Sub Status Weight"
//                                     required
//                                     onKeyPress={preventMinus}
//                                     onPaste={preventPasteNegative}
//                                     value={subVal.subStatusWeight}
//                                     onChange={(e) =>
//                                       handleSubChange(ind, index, e.target)
//                                     }
//                                   />
//                                 </Grid>
//                                 <Grid
//                                   item
//                                   sm={3}
//                                   style={{
//                                     display: "flex",
//                                     alignItems: "center",
//                                   }}
//                                 >
//                                   <Checkbox
//                                     name="positive_key"
//                                     color="primary"
//                                     checked={subVal.positive_key}
//                                     onChange={(e) =>
//                                       handleSubChange(ind, index, e.target)
//                                     }
//                                   />
//                                   Positive Key
//                                   <Button
//                                     onClick={() =>
//                                       removeSub(ind, index)
//                                     }
//                                     color="error"
//                                   >
//                                     <CancelIcon />
//                                   </Button>
//                                 </Grid>
//                               </>
//                             );
//                           })}

//                         <Grid
//                           item
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <Button
//                             color="primary"
//                             onClick={() => addField(ind)}
//                           >
//                             <AddCircleOutlineIcon />
//                             Add Sub Status
//                           </Button>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                   );
//                 })}
//               </Box>

//               <Grid
//                 item
//                 container
//                 sm={12}
//                 spacing={2}
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "25px",
//                 }}
//               >
//                 <Button
//                   color="primary"
//                   onClick={addMain}
//                   startIcon={<AddCircleOutlineIcon />}
//                 >
//                   Add Main Status
//                 </Button>
//               </Grid>
//               <Grid
//                 item
//                 container
//                 sm={12}
//                 spacing={2}
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginTop: "25px",
//                 }}
//               >
//                 <Button
//                   type="submit"
//                   color="primary"
//                   variant="contained"
//                   style={btnstyle}
//                 >
//                   Save
//                 </Button>
//                 <Button
//                   type="button"
//                   color="secondary"
//                   variant="contained"
//                   style={btnstyle1}
//                   onClick={handleCancelClick}
//                 >
//                   Cancel
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Box>
//   );
// };

// export default EditMainStatus;
