import * as React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
} from "@mui/material";
import { HiUserGroup } from "react-icons/hi";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../../apis/AxiosInstance";
import { toast } from "react-toastify";
import { BsFillEyeFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import Loader from "./../../../pages/Loader";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "98vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  height: "250px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  pb: 2,
  zIndex: "1000",
  display: "flex",
  alignItems: "end",
};

export default function OnboardingJoinedList(props) {
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx1
  );
  let [onboardData, setOnboardData] = useState(props.appointment_idx2);

  let [video_id, setVideo_id] = useState(props.video_idx);

  const downloadRef = React.useRef();
  let [regdStudents, setRegdStudents] = React.useState([]);

  console.log("regdStudents", regdStudents);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const [loadReg, setLoadRed] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    // { label: "ID", key: "id" },
    { label: "Full Name", key: "full_name" },
    { label: "Emails", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Appointment Count", key: "appointment_count" },
    { label: "Call Count", key: "call_count" },
    { label: "Start Time", key: "start_time" },
    { label: "End time", key: "end_time" },
    { label: "Total Duration", key: "total_duration" },
  ];

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);

    try {
      let response = await AxiosInstance.get(
        `onbording_joined_students?appointment_id=${appointment_id}&page=${newPage}&video_id=${video_id}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.filtered_count);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email:
            item.emailes && item.emailes.length > 0
              ? item.emailes.join(", ")
              : "",
          mobile:
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles[0].length > 10
                ? item.mobiles[0].slice(3)
                : item.mobiles[0]
              : "",
          appointment_count:
            item.appointment_count == null ? 0 : item.appointment_count,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const loadAllData = async (data) => {
    const totalPage = Math.ceil(totalLength / 10);
    try {
      let arr = [];
      for (let i = 1; i <= totalPage; i++) {
        let { data } = await AxiosInstance.get(
          `/onbording_joined_students?page=${i}&appointment_id=${appointment_id}&video_id=${video_id}`
        );
        arr = [...arr, ...data.data];
      }
      const formattedData = arr.map((item) => ({
        // ...item,
        full_name:
          item.full_name === null || item.full_name === ""
            ? "NA"
            : item.full_name,
        email:
          item.emailes && item.emailes.length > 0
            ? item.emailes.join(", ")
            : "",
        mobile:
          item.mobiles && item.mobiles.length > 0
            ? item.mobiles[0].length > 10
              ? item.mobiles[0].slice(3)
              : item.mobiles[0]
            : "",
        appointment_count:
          item.appointment_count == null ? 0 : item.appointment_count,
      }));

      setCsvData(formattedData);
      setTimeout(() => {
        downloadRef.current.link.click();
        handleClose1();
      }, 1000);
    } catch (error) {
      toast.error("Downloading Failed");
      handleClose1();
    }
  };

  const handleAppointmentOpen = async (e, x) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `onbording_joined_students?page=${page}&appointment_id=${appointment_id}&video_id=${video_id}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.filtered_count);

        const formattedData = response.data.data.map((item) => ({
          ...item,
          email:
            item.emailes && item.emailes.length > 0
              ? item.emailes.join(", ")
              : "",
          mobile:
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles[0].length > 10
                ? item.mobiles[0].slice(3)
                : item.mobiles[0]
              : "",
          appointment_count:
            item.appointment_count == null ? 0 : item.appointment_count,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const totalPages = Math.ceil(totalLength / 10);

  //Modal Data
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalState(null);
  };

  const paperStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    p: 2,
    height: "auto",
    zIndex: "999",
  };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Onboarding Joined Students Details">
        <Button
          onClick={handleAppointmentOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <HiUserGroup
            fontSize={24}
            style={{ color: "#646FD4", "&:hover": { color: "#EB455F" } }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Onboarding Joined Student Details
            {/* <Button
              style={{ background: "green", marginLeft: "10%", color: "#fff" }}
              onClick={(e) => {
                handleOpen1();
                loadAllData();
              }}
            >
              Download CSV File
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`onboarding joined list.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            /> */}
          </Typography>

          <h3
            style={{ marginLeft: "45%", marginTop: "0.5%", color: "#495579" }}
          >
            Total Count : {totalLength}
          </h3>
          <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                      alignItems: "center",
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Appointment Count
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Start Time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    End time
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Total Duration in Min
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {calculateSlNo(id)}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.full_name === null || value.full_name === ""
                            ? "NA"
                            : value.full_name}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.contacts.length > 0
                            ? value.contacts.map((val) => val.number)
                            : "NA"}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.emailes == null
                            ? "NA"
                            : value.emailes.length > 0
                            ? value.emailes
                            : "NA"}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              {" "}
                              {value.appointment_count == null
                                ? 0
                                : value.appointment_count}
                            </p>
                            &nbsp;&nbsp;
                            <Tooltip title="Appointment List">
                              <p
                                onClick={() => {
                                  handleOpenModal();
                                  setModalState(value.appointment);
                                }}
                                sx={{
                                  "&:hover": { background: "#DBDFFD" },
                                }}
                              >
                                <BsFillEyeFill
                                  fontSize={17}
                                  style={{
                                    color: "#646FD4",
                                    cursor: "pointer",
                                    "&:hover": { color: "#EB455F" },
                                  }}
                                />
                              </p>
                            </Tooltip>
                          </p>
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.login_time === null || value.login_time === ""
                            ? "N/A"
                            : moment(value.login_time)
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.logout_time === null ||
                          value.logout_time === ""
                            ? "N/A"
                            : moment(value.logout_time)
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.duration === null || value.duration === ""
                            ? "N/A"
                            : value.duration}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                    <TableCell align="center" sx={{ color: "#000" }}>
                      ---
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Grid style={{ marginTop: "3%" }}>
              <Grid xs={12} item container style={gridContainer} align="center">
                <Paper elevation={10} style={paperStyle}>
                  <Grid align="center" item>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AiFillCloseSquare
                        fontSize={30}
                        onClick={handleCloseModal}
                        color="#FC2947"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <h2>Appointment List</h2>
                    <br />
                  </Grid>
                  <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table
                      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead sx={{ backgroundColor: "#495579" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "100px !important",
                            }}
                          >
                            Sl.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "700px !important",
                            }}
                          >
                            Appointment Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {modalState && Object.keys(modalState).length > 0 ? (
                          Object.entries(modalState).map((value, id) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{id + 1}</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {value[1]?.["college"]?.value || "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={2}
                              sx={{ color: "#000" }}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
