import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Avatar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { FaEdit } from "react-icons/fa";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import { AiFillCloseSquare } from "react-icons/ai";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#081F37",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function ChangeGroupName(props) {
  let Navigate = useNavigate();
  const [gd_list, setGdList] = useState([]);
  const [groupNameSearch, setGroupNameSearch] = useState("");
  const [groupOption, setGroupOption] = useState([]);
  const [groupData, setGroupData] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let { state } = props;

  const [changeGroup_id, setChangeGroup_id] = useState(
    props.appointment_changegroup_id
  );

  let appointmentKey = Object.keys(state.appointment_name);

  useEffect(() => {
    let fetchGD = async (_) => {
      let gdData = await AxiosInstance.get(`/gd_group?student_id=${state.id}`);
      let finalGdData = gdData.data.data;
      if (finalGdData !== null) {
        setGdList(finalGdData);
      }
    };
    if (open) {
      fetchGD();
    }
  }, [open]);

  useEffect(() => {
    let fetchData = async () => {
      try {
        let allGroupData = await AxiosInstance.get(
          `/group_mini?limit=10&search_keyword=${groupNameSearch}&appointment_id=${appointmentKey}`
        );
        let finalAllGroupData = allGroupData.data.data;
        setGroupOption(finalAllGroupData);
      } catch (error) {
        console.log(error);
      }
    };

    if (open) {
      fetchData();
    }
  }, [groupNameSearch, open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };

    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    try {
      formData.append("add_student", state.id);
      formData.append("appointment_id", changeGroup_id);
      formData.append("id", groupData.id);

      await AxiosInstance.patch("/gd_group", formData, config, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Group Name Changed Successfully");
            setTimeout(() => {
              setOpen(false);
              Navigate("/tree_roundsto_shortlist");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,

    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#081F37" };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Change Group Name">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <FaEdit
            fontSize={18}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
            }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid style={{ marginTop: "7%" }}>
          <Grid xs={12} item container style={gridContainer} align="center">
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center" item>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <AiFillCloseSquare
                    fontSize={30}
                    onClick={handleClose}
                    color="#FC2947"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Avatar style={avatarStyle}>
                  <GroupAddIcon />
                </Avatar>
                <h2>Change Group</h2>
                <br />
              </Grid>
              <main
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1% auto",
                }}
              >
                <section style={{ marginTop: "1%" }}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      width: "100%",
                      overflowY: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Table sx={{ minWidth: 400 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            Group Name
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            Interviewer Name
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            GD Start Date
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "white",
                            }}
                          >
                            GD End Date
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gd_list.length > 0 ? (
                          gd_list.map((val, ind) => (
                            <StyledTableRow key={ind + 1}>
                              <StyledTableCell
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                {val.group_name === null ||
                                val.group_name === ""
                                  ? "NA"
                                  : val.group_name}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                {val.interviewer === null ||
                                val.interviewer === ""
                                  ? "N/A"
                                  : Object.values(val.interviewer).map((ea) => (
                                      <Box style={{ height: "22px" }}>
                                        {ea?.["name"]}
                                      </Box>
                                    ))}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                {val.start_date_time === null ||
                                val.start_date_time === ""
                                  ? "N/A"
                                  : moment(val.start_date_time)
                                      .local()
                                      .format("DD-MM-yyyy hh:mm A")}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                {val.end_date_time === null ||
                                val.end_date_time === ""
                                  ? "N/A"
                                  : moment(val.end_date_time)
                                      .local()
                                      .format("DD-MM-yyyy hh:mm A")}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={13}>
                              <h1 style={{ color: "red", textAlign: "center" }}>
                                No Data Found
                              </h1>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <form onSubmit={handleSubmit}>
                    <Grid style={{ marginTop: "2%" }}>
                      <Grid item md={12}>
                        <Item
                          onChange={(e) => {
                            setGroupNameSearch(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={groupOption}
                            fullWidth
                            value={groupData}
                            name="groupOption"
                            onChange={(event, newData_xyc) => {
                              setGroupData(newData_xyc);
                            }}
                            getOptionLabel={(option) => option.group_name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Group Name"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Item>
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ width: "20%", marginTop: "2%" }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </section>
              </main>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
