import React, { useState, useEffect } from "react";
import {
  Box,
  Autocomplete,
  Chip,
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  Container,
} from "@mui/material";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";
import { styled } from "@mui/material/styles";

//====================================

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditGD() {
  const Navigate = useNavigate();
  let { state } = useLocation();

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [start_date_time, setStart_date_time] = useState(
    state.stateAddresssing.start_date_time
      ? moment
          .utc(toIsoFormat(state.stateAddresssing.start_date_time))
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
      : ""
  );
  const [end_date_time, setEnd_date_time] = useState(
    state.stateAddresssing.end_date_time
      ? moment
          .utc(toIsoFormat(state.stateAddresssing.end_date_time))
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
      : ""
  );

  const [colgOptions, setColgOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [studentOptions, setStudentOptions] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const [addInterviewerOptions, setInterviewerOptions] = useState([]);
  const [interviewer, setInerviewer] = useState([]);
  const [testInterviewer, setTestInterviewer] = useState([]);
  const [testStudent, setTestStudent] = useState([]);
  const [searchInterviewer, setSearchInterviewer] = useState("");

  const [searchStudentName, setSearchStudentname] = useState("");

  const [studentsToRemove, setStudentsToRemove] = useState([]);
  const [interviewerToRemove, setInterviewerToRemove] = useState([]);

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(
    state.stateAddresssing.is_offline ? 1 : 2
  );

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);
  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      // const keys = Object.keys(state.interviewer);
      const keys = state.stateAddresssing?.interviewer_id
        ? Object.keys(state.stateAddresssing.interviewer_id)
        : [];
      try {
        if (keys.length > 0) {
          let addFacultyData = await AxiosInstance.get(
            `/user_mini?role_ids=3&is_not_blocked=${true}&user_id=${keys
              .toString()
              .split(",")
              .join("&user_id=")}`
          );
          let finalAddFaculty = addFacultyData.data.data
            ? addFacultyData.data.data
            : [];
          setInerviewer(finalAddFaculty);
          setTestInterviewer(finalAddFaculty);
        }
      } catch (error) {
        console.log(error);
        if (keys.length > 0) {
          setInerviewer([]);
          setTestInterviewer([]);
        }
      }
    };
    fetchData();
  }, []);

  const getFilteredData = () => {
    return Object.entries(state.stateAddresssing.interviewer_id)
      .filter(
        ([key, value]) =>
          !interviewer.some((item) => item.id == key) &&
          !interviewerToRemove.some((item) => item.id == key)
      )
      .map(([key, value]) => ({ id: key, name: value.name }));
  };

  let data = getFilteredData();

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchInterviewer !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&is_not_blocked=${true}&search_keyword=${searchInterviewer}&limit=10`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setInterviewerOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setInterviewerOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchInterviewer]);

  useEffect(() => {
    let fetchData = async () => {
      const keys = state.group_idz;

      try {
        let addFacultyData = await AxiosInstance.get(
          `/gd_registered_students_list?group_id=${keys}`
        );
        let finalAddFaculty = addFacultyData.data.data || [];
        setStudentName(finalAddFaculty);
        setTestStudent(finalAddFaculty);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   let fetchData = async () => {
  //     const keyData = Object.keys(state.stateAddresssing1.appointment_id);

  //     if (searchStudentName !== "" && searchStudentName.length > 2) {
  //       let addFacultyData = await AxiosInstance.get(
  //         `/gd_registered_students_list?appointment_id=${keyData}&search_keyword=${searchStudentName}&limit=10`
  //       );
  //       let finalAddFaculty = addFacultyData.data.data;

  //       if (finalAddFaculty && finalAddFaculty.length > 0) {
  //         setStudentOptions(finalAddFaculty);
  //       } else {
  //         setStudentOptions([]);
  //       }
  //     } else {
  //       let addFacultyData = await AxiosInstance.get(
  //         `/gd_registered_students_list?appointment_id=${keyData}&limit=10`
  //       );
  //       let finalAddFaculty = addFacultyData.data.data;

  //       if (finalAddFaculty && finalAddFaculty.length > 0) {
  //         setStudentOptions(finalAddFaculty);
  //       } else {
  //         setStudentOptions([]);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [searchStudentName]);

  useEffect(() => {
    let fetchData = async () => {
      let keyData = [];

      if (
        state &&
        state.stateAddresssing1 &&
        state.stateAddresssing1.appointment_id
      ) {
        keyData = Object.keys(state.stateAddresssing1.appointment_id);
        if (keyData.length === 0) {
          setStudentOptions([]);
          return;
        }
      } else {
        console.error(
          "state.stateAddresssing1.appointment_id is undefined or null."
        );
        setStudentOptions([]);
        return;
      }

      try {
        if (searchStudentName !== "" && searchStudentName.length > 1) {
          let addFacultyData = await AxiosInstance.get(
            `/registered_students?appointment_id=${keyData}&search_keyword=${searchStudentName}&limit=10`
          );
          let finalAddFaculty = addFacultyData.data.data || [];
          setStudentOptions(finalAddFaculty);
        } else {
          let addFacultyData = await AxiosInstance.get(
            `/registered_students?appointment_id=${keyData}&limit=10`
          );
          let finalAddFaculty = addFacultyData.data.data || [];
          setStudentOptions(finalAddFaculty);
        }
      } catch (error) {
        console.log(error);
        setStudentOptions([]);
      }
    };
    fetchData();
  }, [searchStudentName]);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchTerm !== "") {
        let groupData = await AxiosInstance.get(
          `/gd_group?search_keyword=${searchTerm}&limit=10`
        );
        let finalGroupData = groupData.data.data;
        if (finalGroupData) {
          setColgOptions(finalGroupData);
        }
      } else {
        let groupData = await AxiosInstance.get(`gd_group?limit=10`);
        let finalGroupData = groupData.data.data;
        setColgOptions(finalGroupData);
      }
    };
    fetchData();
  }, [searchTerm]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let formData = new FormData();
  //   const parseLog = (a) => {
  //     const userData = a ? JSON.parse(a) : { token: "" };
  //     const { token } = userData;
  //     return token;
  //   };

  //   const token = parseLog(Cookies.get("logged"));
  //   let config = {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `${token}`,
  //     },
  //   };
  //   try {
  //     const gdtDateTime = moment(start_date_time)
  //       .utc()
  //       .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
  //     const gdEndDateTime = moment(end_date_time)
  //       .utc()
  //       .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

  //     formData.append("id", state.group_idz);

  //     const startDateTimeEdited =
  //       start_date_time !== "" &&
  //       moment
  //         .utc(state.stateAddresssing.start_date_time)
  //         .local()
  //         .format("YYYY-MM-DDTHH:mm:ss.SSS") !== start_date_time;

  //     const endDateTimeEdited =
  //       end_date_time !== "" &&
  //       moment
  //         .utc(state.stateAddresssing.end_date_time)
  //         .local()
  //         .format("YYYY-MM-DDTHH:mm:ss.SSS") !== end_date_time;

  //     let gdStatus = state.stateAddresssing.is_offline ? 1 : 2;
  //     if (examTypeList != gdStatus) {
  //       formData.append("is_offline", examTypeList == 1 ? "true" : "false");
  //     }

  //     const hasExtraObjectsInArray2 = interviewer.filter(
  //       (obj2) => !testInterviewer.some((obj1) => obj1.id === obj2.id)
  //     );

  //     if (hasExtraObjectsInArray2.length > 0) {
  //       hasExtraObjectsInArray2.forEach((value) => {
  //         formData.append("add_interviewer_id", value.id);
  //       });
  //     }

  //     const studentsToAdd = studentName.filter(
  //       (obj2) => !testStudent.some((obj1) => obj1.id === obj2.id)
  //     );

  //     if (studentsToAdd.length > 0) {
  //       studentsToAdd.forEach((value) => {
  //         formData.append("add_student", value.id);
  //       });
  //     }
  //     if (studentsToAdd.length > 0) {
  //       formData.append(
  //         "appointment_id",
  //         Object.keys(state.stateAddresssing1.appointment_id)
  //       );
  //     }
  //     if (studentsToRemove.length > 0) {
  //       studentsToRemove.forEach((value) => {
  //         formData.append("remove_student", value.id);
  //       });
  //     }

  //     if (interviewerToRemove.length > 0) {
  //       interviewerToRemove.forEach((value) => {
  //         formData.append("remove_interviewer_id", value.id);
  //       });
  //     }

  //     if (
  //       startDateTimeEdited ||
  //       endDateTimeEdited ||
  //       hasExtraObjectsInArray2.length > 0
  //     ) {
  //       formData.append("gd_start_date", gdtDateTime.split(" ")[0]);
  //       formData.append(
  //         "gd_start_time",
  //         gdtDateTime.split(" ").slice(1).join(" ")
  //       );

  //       if (
  //         (state.stateAddresssing.is_offline != 1 || examTypeList == 2) &&
  //         examTypeList != 1
  //       ) {
  //         formData.append("gd_end_date", gdEndDateTime.split(" ")[0]);
  //         formData.append(
  //           "gd_end_time",
  //           gdEndDateTime.split(" ").slice(1).join(" ")
  //         );
  //       }
  //     }

  //     const dateTimeToSeconds = (dateTime) => {
  //       return dateTime.getTime() / 1000;
  //     };
  //     const todayDate = new Date();
  //     const startDate = new Date(gdtDateTime);
  //     const endDate = new Date(gdEndDateTime);
  //     const today = dateTimeToSeconds(todayDate);
  //     const start = dateTimeToSeconds(startDate);
  //     const end = dateTimeToSeconds(endDate);

  //     if (
  //       (state.stateAddresssing.is_offline == 1 &&
  //         examTypeList == 1 &&
  //         start > today) ||
  //       (end > start && start > today)
  //     ) {
  //       try {
  //         await AxiosInstance.patch("/gd_group", formData, config, {
  //           Authorization: `${token}`,
  //         })
  //           .then((response) => {
  //             if (response.status === 200) {
  //               toast.success("GD Updated Successfully");
  //               setTimeout(() => {
  //                 Navigate("/gd_creation_list");
  //               }, 1000);
  //             }
  //           })
  //           .catch((error) => {
  //             toast.error(error.response.data.error);
  //           });
  //       } catch (error) {
  //         console.log(error);
  //         console.log(error.message);
  //       }
  //     } else {
  //       if (start <= today) {
  //         toast.warn("Start date should be always greater than current date");
  //       }

  //       if (examTypeList !== 1 && end <= start) {
  //         toast.warn("End date should always be greater than start date.");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     console.log(error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    // Function to parse user data from cookies
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };

    // Extract token from cookies
    const token = parseLog(Cookies.get("logged"));

    // Configuration for Axios request
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      // Format start and end date times for API submission
      const gdtDateTime = moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
      const gdEndDateTime = moment(end_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

      // Check if start and end date times have been edited
      const startDateTimeEdited =
        start_date_time !== "" &&
        moment
          .utc(state.stateAddresssing.start_date_time)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") !== start_date_time;

      const endDateTimeEdited =
        end_date_time !== "" &&
        moment
          .utc(state.stateAddresssing.end_date_time)
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS") !== end_date_time;

      // Determine GD status based on exam type list
      let gdStatus = state.stateAddresssing.is_offline ? 1 : 2;
      if (examTypeList != gdStatus) {
        formData.append("is_offline", examTypeList == 1 ? "true" : "false");
      }
      formData.append("id", state.group_idz);

      // Find interviewers and students to add and remove
      const hasExtraObjectsInArray2 = interviewer.filter(
        (obj2) => !testInterviewer.some((obj1) => obj1.id === obj2.id)
      );

      if (hasExtraObjectsInArray2.length > 0) {
        hasExtraObjectsInArray2.forEach((value) => {
          formData.append("add_interviewer_id", value.id);
        });
      }

      const studentsToAdd = studentName.filter(
        (obj2) => !testStudent.some((obj1) => obj1.id === obj2.id)
      );

      if (studentsToAdd.length > 0) {
        studentsToAdd.forEach((value) => {
          formData.append("add_student", value.id);
        });
      }

      if (studentsToAdd.length > 0) {
        formData.append(
          "appointment_id",
          Object.keys(state.stateAddresssing1.appointment_id)
        );
      }

      // const studentsToRemove = studentName.filter(
      //   (obj1) => !testStudent.some((obj2) => obj2.id === obj1.id)
      // );

      if (studentsToRemove.length > 0) {
        studentsToRemove.forEach((value) => {
          formData.append("remove_student", value.id);
        });
      }

      // const interviewerToRemove = interviewer.filter(
      //   (obj1) => !testInterviewer.some((obj2) => obj2.id === obj1.id)
      // );

      if (interviewerToRemove.length > 0) {
        interviewerToRemove.forEach((value) => {
          formData.append("remove_interviewer_id", value.id);
        });
      }

      // Append start and end date times if edited
      if (
        startDateTimeEdited ||
        endDateTimeEdited ||
        hasExtraObjectsInArray2.length > 0
      ) {
        formData.append("gd_start_date", gdtDateTime.split(" ")[0]);
        formData.append(
          "gd_start_time",
          gdtDateTime.split(" ").slice(1).join(" ")
        );

        if (
          (state.stateAddresssing.is_offline != 1 || examTypeList == 2) &&
          examTypeList != 1
        ) {
          formData.append("gd_end_date", gdEndDateTime.split(" ")[0]);
          formData.append(
            "gd_end_time",
            gdEndDateTime.split(" ").slice(1).join(" ")
          );
        }
      }

      // Validate date and time
      const dateTimeToSeconds = (dateTime) => {
        return dateTime.getTime() / 1000;
      };

      const todayDate = new Date();
      const startDate = new Date(gdtDateTime);
      const endDate = new Date(gdEndDateTime);
      const today = dateTimeToSeconds(todayDate);
      const start = dateTimeToSeconds(startDate);
      const end = dateTimeToSeconds(endDate);

      // Check conditions before making the API call
      let shouldSubmit = true;

      if (state.stateAddresssing.is_offline != 1 && examTypeList != 1) {
        if (end <= start) {
          toast.warn("End date should always be greater than start date.");
          shouldSubmit = false;
        }
      }

      if (start <= today) {
        toast.warn("Start date should be always greater than current date.");
        shouldSubmit = false;
      }

      if (shouldSubmit) {
        // Make API call if all conditions are met
        try {
          await AxiosInstance.patch("/gd_group", formData, config);
          toast.success("GD Updated Successfully");
          setTimeout(() => {
            Navigate("/gd_creation_list");
          }, 1000);
        } catch (error) {
          toast.error(error.response.data.error);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "70%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#081f37",
              fontSize: {
                xs: "20px", // Smaller font size on small screens
                sm: "25px", // Medium font size on small to medium screens
                md: "30px", // Larger font size on medium to large screens
              },
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            GD Edit
          </Box>
        </Box>
        {/* <Container
          component="main"
          style={{
            width: "100%",
            borderRadius: "10px",
          }}
        > */}
          <Paper
            variant="outlined"
            style={{ border: "2px solid #9BA4B5", width: "100%",borderRadius:"10px" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    GD Start DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="start_date_time"
                        required
                        fullWidth
                        type="datetime-local"
                        id="start_date_time"
                        value={start_date_time}
                        onChange={(e) => setStart_date_time(e.target.value)}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {examTypeList == 1 ? (
                    ""
                  ) : (
                    <>
                      <Grid
                        item
                        xs={3}
                        sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                      >
                        GD End DateTime :
                      </Grid>
                      <Grid Grid item xs={9}>
                        <FormControl style={{ width: "80%" }}>
                          <TextField
                            name="end_date_time"
                            fullWidth
                            type="datetime-local"
                            id="end_date_time"
                            value={end_date_time}
                            onChange={(e) => setEnd_date_time(e.target.value)}
                            inputProps={{
                              min: new Date().toISOString().slice(0, 16),
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    Mode Of GD :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      {/* <FormControl fullWidth> */}
                      <InputLabel id="demo-simple-select-label">
                        Mode Of GD
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={examTypeList}
                        label="Mode Of GD"
                        onChange={(e) => {
                          setExamTypeList(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Default border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                          },
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {/* </FormControl> */}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    Interviewer :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchInterviewer(e.target.value);
                        }}
                      >
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          required
                          size="small"
                          value={interviewer}
                          onChange={(e, value) => {
                            const removedInterviewers = interviewer.filter(
                              (interviewer) =>
                                !value.some(
                                  (selected) => selected.id === interviewer.id
                                )
                            );
                            setInterviewerToRemove([
                              ...interviewerToRemove,
                              ...removedInterviewers,
                            ]);
                            setInerviewer(value);
                          }}
                          options={
                            addInterviewerOptions ? addInterviewerOptions : []
                          }
                          getOptionLabel={(option) => option.full_name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                key={option.full_name + (index + 1)}
                                label={option.full_name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Interviewer"
                              variant="outlined"
                              color="warning"
                              InputProps={{
                                ...params.InputProps,
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Item>
                    </FormControl>
                  </Grid>

                  {data.length > 0 ? (
                    <Grid item xs={9} style={{ marginLeft: "25%" }}>
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {data.map((val) => val.name).join(", ")}{" "}
                        <span style={{ color: "#FF6969" }}>
                          (" These Interviewers are Blocked ")
                        </span>
                      </p>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold" }}
                  >
                    Student Name:
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchStudentname(e.target.value);
                        }}
                      >
                        <Autocomplete
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          multiple
                          required
                          size="small"
                          value={studentName}
                          onChange={(e, value) => {
                            const hasEvaluation = value.some(
                              (option) =>
                                option.gd_marks && option.gd_marks.gd_status
                            );
                            if (hasEvaluation) {
                              toast.warning(
                                "Evaluation already exists for this student",
                                {
                                  position: toast.POSITION.TOP_CENTER,
                                }
                              );
                            } else {
                              const removedStudents = studentName.filter(
                                (student) =>
                                  !value.some(
                                    (selected) => selected.id === student.id
                                  )
                              );
                              setStudentsToRemove([
                                ...studentsToRemove,
                                ...removedStudents,
                              ]);
                              setStudentName(value);
                            }
                          }}
                          options={studentOptions ? studentOptions : []}
                          getOptionLabel={(option) => option.full_name}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                key={option.full_name + (index + 1)}
                                label={option.full_name}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Student"
                              variant="outlined"
                              color="warning"
                              InputProps={{
                                ...params.InputProps,
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Item>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ width: "15%", backgroundColor: "crimson" }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "15%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
        {/* </Container> */}
      </article>
    </section>
  );
}
