import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Autocomplete,
  Chip,
  Checkbox,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({}));

const UserRegistration = () => {
  const navigate = useNavigate();
  const [full_name, setfull_name] = useState("");
  const [emails, setemails] = useState("");
  const [mobiles, setmobiles] = useState("");
  const [password, setpassword] = useState("");

  const [selectedRole, setSelectedRole] = useState([]);
  const [roleTerm, setRoleTerm] = useState("");
  const [roleOption, setRoleOption] = useState([]);

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [assign_state, setAssign_State] = useState(false);

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);

  const [collegeOption, setCollegeOption] = useState([]);
  const [collegeData, setCollegeData] = useState(null);
  const [defaultCollege, setDefaultCollege] = useState([]);
  const [searchCollege, setSearchCollege] = useState("");

  const handleAssignDate = (event) => {
    setAssign_State(event.target.checked);
    if (event.target.checked) {
      setSelectedStateOptions([]);
    }
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  // show password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    let fetchdata = async (e) => {
      let data = await AxiosInstance.get(`/college_mini?limit=10`);
      let finalData = data.data.data;
      setCollegeOption(finalData);
      setDefaultCollege(finalData);
    };
    fetchdata();
  }, []);

  const searchcollege = async (e) => {
    try {
      let data = await AxiosInstance.get(
        `/college_mini?search_keyword=${e}&limit=10`
      );
      let finalData = data.data.data;
      setCollegeOption(finalData);
    } catch (error) {
      setCollegeOption(defaultCollege);
    }
  };

  // useEffect(() => {
  //   let fethchData = async () => {
  //     if (roleTerm !== "") {
  //       let data = await AxiosInstance.get(
  //         `/roles?search_keyword=${roleTerm}&limit=10`
  //       );
  //       // let finalData = data.data.data;
  //       let finalData = data.data.data.filter((option) => option.id !== 2);
  //       setRoleOption(finalData);
  //     } else {
  //       let data = await AxiosInstance.get(`/roles?limit=10`);
  //       // let finalData = data.data.data;
  //       let finalData = data.data.data.filter((option) => option.id !== 2);
  //       setRoleOption(finalData);
  //     }
  //   };
  //   fethchData();
  // }, [roleTerm]);

  useEffect(() => {
    let fethchData = async () => {
      if (roleTerm !== "") {
        let data = await AxiosInstance.get(
          `/roles?search_keyword=${roleTerm}&limit=10`
        );
        // Filter out both ID 2 and ID 13
        let finalData = data.data.data.filter(
          (option) => option.id !== 2 && option.id !== 13
        );
        setRoleOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/roles?limit=10`);
        // Filter out both ID 2 and ID 13
        let finalData = data.data.data.filter(
          (option) => option.id !== 2 && option.id !== 13
        );
        setRoleOption(finalData);
      }
    };
    fethchData();
  }, [roleTerm]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     full_name: full_name,
  //     role_id: selectedRole.map((val) => val.id),
  //     email: emails,
  //     mobile: mobiles,
  //     country_code: `+91`,
  //     password: password,
  //     status: 1,
  //     unit_id: unitNameData.ID,
  //   };
  //   try {
  //     await AxiosInstance.post(`/bd/user_create`, payload)
  //       .then((response) => {
  //         toast.success("User created Successfully");
  //         setTimeout(() => {
  //           navigate("/user_list");
  //         }, 1500);
  //       })
  //       .catch((error) => {
  //         if (error.response.data) {
  //           toast.error(error.response.data.error);
  //         } else {
  //           toast.error("Something Went wrong");
  //         }
  //       });
  //   } catch (error) {
  //     if (error.response.data) {
  //       toast.error(error.response.data.error);
  //     } else {
  //       toast.error("Something Went wrong");
  //     }
  //   }
  // };

  //Selecting All Appointments
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalData = response.data.data;
      setStateOptions(finalData);
      setDefaultCourseList(finalData);
    };
    fetchData();
  }, [countryMiniId]);
  const fetchOptions1 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/statemini?search_keyword=${searchTerm_1}&limit=10&country_id=${countryMiniId}`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setStateOptions(data);
      } else {
        setStateOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setStateOptions(defaultCourseList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedStateOptions(newValue);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let payload = {
  //     full_name: full_name,
  //     role_id: selectedRole.map((val) => val.id),
  //     email: emails,
  //     mobile: mobiles,
  //     country_code: `+91`,
  //     password: password,
  //     status: 1,
  //     // all_state:assign_state
  //   };

  //   if (unitNameData) {
  //     payload.unit_id = unitNameData.ID;
  //   }
  //   if (assign_state) {
  //     payload.all_state = assign_state;
  //   } else if (selectedStateOptions.length > 0) {
  //     payload.state_id = selectedStateOptions.map((e) => e.id);
  //   }

  //   try {
  //     await AxiosInstance.post(`/bd/user_create`, payload)
  //       .then((response) => {
  //         toast.success("User created Successfully");
  //         setTimeout(() => {
  //           navigate("/user_list");
  //         }, 1500);
  //       })
  //       .catch((error) => {
  //         if (error.response.data) {
  //           toast.error(error.response.data.error);
  //         } else {
  //           toast.error("Something Went wrong");
  //         }
  //       });
  //   } catch (error) {
  //     if (error.response.data) {
  //       toast.error(error.response.data.error);
  //     } else {
  //       toast.error("Something Went wrong");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {
      full_name: full_name,
      role_id: selectedRole.map((val) => val.id),
      email: emails,
      mobile: mobiles,
      country_code: "+91",
      password: password,
      status: 1,
      // all_state: assign_state
    };

    if (unitNameData) {
      payload.unit_id = unitNameData.ID;
    }
    if (assign_state) {
      payload.all_state = assign_state;
    } else if (selectedStateOptions.length > 0) {
      payload.state_id = selectedStateOptions.map((e) => e.id);
    }

    try {
      // Check if role with id 13 is present in selectedRole array
      if (selectedRole.some((role) => role.id === 13)) {
        // Add the college key when role 13 is present
        if (collegeData && collegeData.id) {
          payload.college_id = [collegeData.id];
        }

        // Send data to `/tpo_create` endpoint
        await AxiosInstance.post(`/tpo_create`, payload)
          .then((response) => {
            toast.success("User created successfully");
            setTimeout(() => {
              navigate("/user_list");
            }, 1500);
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              // toast.error(error.response.data.error);
              toast.error("Add The College Spoc Details First");
            } else {
              toast.error("Something went wrong");
            }
          });
      } else {
        // Send data to `/bd/user_create` endpoint when role 13 is not present
        await AxiosInstance.post(`/bd/user_create`, payload)
          .then((response) => {
            toast.success("User created successfully");
            setTimeout(() => {
              navigate("/user_list");
            }, 1500);
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              toast.error(error.response.data.error);
            } else {
              toast.error("Something went wrong");
            }
          });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    border: "1px solid gray",
    borderRadius: "10px",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#FD8D14" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#176B87",
    color: "#FD8D14",
    border: "1px solid #176B87",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  // for DropDown ScrollBar

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersAndOnePeriodRegex = /^[a-zA-Z\s]*\.?[a-zA-Z\s]*$/;

    if (!lettersAndOnePeriodRegex.test(value)) {
      // If the input contains more than one period or non-letter characters, remove them
      setfull_name(value.replace(/[^a-zA-Z\s.]/g, "").replace(/\.{2,}/g, "."));
    } else {
      setfull_name(value);
    }
  };

  return (
    <Grid style={{ marginTop: "4.5%" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>User Registration</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={12}>
                <TextField
                  color="warning"
                  size="small"
                  fullWidth
                  name="full_f_name"
                  label="Fullname"
                  required
                  value={full_name}
                  // onChange={(e) => setfull_name(e.target.value)}
                  onChange={handleInputChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  color="warning"
                  size="small"
                  label="Mobile Number"
                  type="tel"
                  fullWidth
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  name="mobiles"
                  value={mobiles}
                  required
                  onChange={(e) => setmobiles(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item container sm={12} spacing={1}>
                <Grid item sm={12}>
                  <FormControl sx={{ minWidth: 260 }} fullWidth>
                    <TextField
                      size="small"
                      color="warning"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                      fullWidth
                      label="Email"
                      name="emails"
                      type="email"
                      autoComplete="user-email"
                      value={emails}
                      required
                      onChange={(e) => setemails(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <InputLabel color="warning">Password*</InputLabel>
                  <OutlinedInput
                    color="warning"
                    // size="small"
                    name="password"
                    value={password}
                    autoComplete="new-password"
                    required
                    onChange={(e) => setpassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 1)", // Border color for the outline
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 1)", // Border color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FCA311", // Border color when focused
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                sm={12}
                id="refreshTool"
                style={{ position: "relative" }}
              >
                <FormControl fullWidth size="small">
                  <Item
                    onChange={(e) => {
                      setRoleTerm(e.target.value);
                    }}
                  >
                    <Autocomplete
                      multiple
                      size="small"
                      options={roleOption}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <li {...props} style={{ width: "100%" }}>
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          required={selectedRole.length === 0}
                          label="Select Role"
                          placeholder="Select Role"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                      value={selectedRole}
                      onChange={(e, value) => {
                        setSelectedRole(value);
                      }}
                    />
                  </Item>
                </FormControl>
              </Grid>

              {/* <Grid item sm={12}>
                  <Item>
                    <Autocomplete
                      options={collegeData ? [collegeData] : collegeOption}
                      value={collegeData}
                      size="small"
                      name="collegeOption"
                      onChange={(event, newData_xy) => {
                        setCollegeData(newData_xy);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchcollege(e);
                        } else {
                          setCollegeOption(defaultCollege);
                        }
                        setSearchCollege(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select College"
                          variant="outlined"
                          value={searchCollege}
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
              */}

              {selectedRole.some((role) => role.id === 13) && ( // Check if any selected role has id 13
                <Grid item sm={12}>
                  <Item>
                    <Autocomplete
                      options={collegeData ? [collegeData] : collegeOption}
                      value={collegeData}
                      size="small"
                      name="collegeOption"
                      onChange={(event, newData_xy) => {
                        setCollegeData(newData_xy);
                      }}
                      onInputChange={(x, e) => {
                        if (e && e.length > 0) {
                          searchcollege(e);
                        } else {
                          setCollegeOption(defaultCollege);
                        }
                        setSearchCollege(e);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select College"
                          variant="outlined"
                          value={searchCollege}
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
              )}

              <Grid item sm={12}>
                <Item
                  onChange={(e) => {
                    setSearchUnitName(e.target.value);
                  }}
                >
                  <Autocomplete
                    options={unitNameOption}
                    size="small"
                    value={unitNameData}
                    name="unitNameOption"
                    onChange={(event, newData_xy) => {
                      setUnitNameData(newData_xy);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Unit Name"
                        variant="outlined"
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="assign_state"
                  checked={assign_state}
                  onChange={handleAssignDate}
                  id="assign_state"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="assignInterviewer" style={{ fontSize: "16px" }}>
                  All States
                </label>
              </Grid>
              <Grid item sm={12}>
                <Item>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    size="small"
                    disabled={assign_state}
                    value={selectedStateOptions}
                    onChange={handleOptionSelect1}
                    onInputChange={handleInputChange1}
                    options={stateOptions}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select State"
                        required={
                          !assign_state && selectedStateOptions.length === 0
                        }
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>

              <Grid item container sm={12} spacing={4}>
                <Grid item sm={12}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Register User
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserRegistration;
