import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function VerifierCallrecordVerificationList(props) {
  let { state } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title="Verification Details">
        <Button
          size="small"
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#474F7A" },
            border: "none",
            backgroundColor: "#474F7A",
            color: "#fff",
          }}
        >
          View Details
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Verification Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#40679E" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "100px !important",
                    }}
                  >
                    Sl.No
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "300px !important",
                    }}
                  >
                    Points Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "300px !important",
                    }}
                  >
                    Points marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px !important",
                    }}
                  >
                    Author Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px !important",
                    }}
                  >
                    Total Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px !important",
                    }}
                  >
                    Scored Percentage
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "300px !important",
                    }}
                  >
                    Comment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {state ? (
                  state.verification &&
                  state.verification.points &&
                  Object.keys(state.verification.points).length > 0 ? (
                    state.verification.points.map((point, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: point.pass === false ? "red" : "#111",
                          }}
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: point.pass === false ? "red" : "#111",
                          }}
                        >
                          {point.point === null || point.point === ""
                            ? "NA"
                            : point.point}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: point.pass === false ? "red" : "#111",
                          }}
                        >
                          {point.marks}
                        </TableCell>
                        {index === 0 && (
                          <TableCell
                            align="center"
                            rowSpan={state.verification.points.length}
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            {state.verification.author?.name ?? "NA"}
                          </TableCell>
                        )}
                        {index === 0 && (
                          <TableCell
                            align="center"
                            rowSpan={state.verification.points.length}
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            {state.verification.total_marks}
                          </TableCell>
                        )}
                        {index === 0 && (
                          <TableCell
                            align="center"
                            rowSpan={state.verification.points.length}
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            {state.verification.percentage}%
                          </TableCell>
                        )}

                        {index === 0 && (
                          <TableCell
                            align="center"
                            rowSpan={state.verification.points.length}
                            style={{ fontWeight: "bold", fontSize: "15px" }}
                          >
                            {state.verification.comment || "NA"}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          align="center"
                          style={{ fontWeight: "bold", color: "red" }}
                        >
                          No points data available
                        </TableCell>
                        <TableCell
                          rowSpan={state.verification.points.length}
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#111",
                          }}
                        >
                          {state.verification.author?.name ?? "NA"}
                        </TableCell>
                        <TableCell
                          rowSpan={state.verification.points.length}
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#111",
                          }}
                        >
                          {state.verification.total_marks}
                        </TableCell>
                        <TableCell
                          rowSpan={state.verification.points.length}
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#111",
                          }}
                        >
                          {state.verification.percentage}%
                        </TableCell>
                        <TableCell
                          rowSpan={state.verification.points.length}
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                            color: "#111",
                          }}
                        >
                          {state.verification.comment || "NA"}
                        </TableCell>
                      </TableRow>
                    </>
                  )
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      align="center"
                      style={{ fontWeight: "bold" }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
