import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Box,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { useState, useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import GDJoinedStudentList from "./GDJoinedStudentList";
import GDShortListStudent from "./GDShortListStudent";
import GDRegistredStudentList from "./GDRegistredStudentList";
import GDRejectedStudentList from "./GDRejectedStudentList";
import GDJobOfferedList from "./GDJobOfferedList";
import EditGdModal from "./EditGDModal";
import GDNotJoinedStudentList from "./GDNotJoinedStudentList";
import { RiFilterOffFill } from "react-icons/ri";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddGDModal from "./AddGDModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "10px",
    // borderRight: `1px solid ${theme.palette.divider}`, // Vertical border
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
    // borderRight: `1px solid ${theme.palette.divider}`, // Vertical border
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& > *": {
    borderBottom: "none",
  },
  // "& > *:not(:last-child)": {
  //   borderRight: `2px solid ${theme.palette.divider}`,
  // },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function GDCreationList() {
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [gd_list, setGdList] = useState([]);
  const [appointmentOptions, setAppointmentOptions] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [appointmentSearch, setAppointmentSearch] = useState("");
  const [dataFiltered, setDataFiltered] = useState(false);
  let [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalGD, setTotalGD] = useState(0);

  // for filtering
  const [appointmentDateFilter, setAppointmentDateFilter] = useState("");

  let handleAppointmentDateFilter = (e) => {
    setAppointmentDateFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const params = {
    appointment_id:
      selectedAppointment !== null ? selectedAppointment.id : null,
  };

  const [toastId, setToastId] = useState(null);
  // Handle search button click
  const handleSearch = () => {
    setIsSearchDisabled(true);
    if (!selectedAppointment) {
      // Display toast if any required fields are not selected
      if (!toast.isActive(toastId)) {
        setToastId(
          toast.warning("Please select search parameters before searching")
        );
      }
    } else {
      // Clear existing toast if any
      setIsSearchDisabled(true);
      toast.dismiss(toastId);
      const validParams = Object.keys(params).filter(
        (key) => params[key] !== null
      );
      const generateURL_Query = validParams
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      setQuery(generateURL_Query);
      toast.success("Data filterd");
      setDataFiltered(true);
    }
  };

  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchGD = async (_) => {
      if (query !== "") {
        setLoad(true);
        try {
          let gdData = await AxiosInstance.get(
            `/gd_creation?${query}&page=${page}`
          );
          setTotalGD(gdData.data.filtered_count);
          let finalGdData = gdData.data.data;
          if (finalGdData !== null) {
            setGdList(finalGdData);
          } else {
            setGdList([]);
          }
          setLoad(false);
        } catch (error) {
          setLoad(false);
        }
      } else {
        setLoad(true);
        try {
          let gdData = await AxiosInstance.get(`/gd_creation?page=${page}`);
          setTotalGD(gdData.data.filtered_count);
          let finalGdData = gdData.data.data;
          if (finalGdData !== null) {
            setGdList(finalGdData);
          }
          setLoad(false);
        } catch (error) {
          setLoad(false);
        }
      }
    };
    fetchGD();
  }, [page, query]);

  useEffect(() => {
    let fetchData2 = async () => {
      try {
        if (appointmentDateFilter !== "") {
          let apiUrl;

          if (appointmentSearch !== "") {
            // get appointments based on addressing date and search keyword
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&search_keyword=${appointmentSearch}&limit=10`;
          } else {
            // get appointments based only on addressing date
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&limit=10`;
          }

          let allAddressingAppointments = await AxiosInstance.get(apiUrl);
          let finalAppointmentsDate = allAddressingAppointments.data.data;
          setAppointmentOptions(
            finalAppointmentsDate ? finalAppointmentsDate : []
          );
        } else if (appointmentSearch !== "") {
          // get appointments based only on search keyword
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?search_keyword=${appointmentSearch}&limit=10`
          );
          let finalAppointmentsSearch = allAddressingAppointments.data.data;
          setAppointmentOptions(
            finalAppointmentsSearch ? finalAppointmentsSearch : []
          );
        } else {
          // get all appointments when neither date nor search term is selected
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?limit=10`
          );
          let finalAppointmentsAll = allAddressingAppointments.data.data;
          setAppointmentOptions(
            finalAppointmentsAll ? finalAppointmentsAll : []
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [appointmentSearch, appointmentDateFilter]);

  const totalPages = Math.ceil(totalGD / 10);

  // const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = (event) => {
  //   const { scrollLeft } = event.target;
  //   setScrollPosition(scrollLeft);
  // };

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedAppointment(null);
    // window.location.href = "/gd_list";
    window.location.href = "/gd_creation_list";
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [groupData, setGroupData] = useState("");

  const getData = (data) => {
    setGroupData(data);
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <main style={{ marginTop: "4.3%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
          paddingLeft: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
          }}
        >
          GD List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",

          }}
        >
         <span  style={{
            color: "#06113C",
            fontSize: "20px",
            fontWeight: "bold",
            padding: "0 100px",
          }}>
         Total Count : {totalGD}
         </span>
          <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            // marginLeft: "25%",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "0 30px",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
        </h1>
     
      </Box>
      {filterVisible && (
        <Grid container style={{ marginTop: "0.5%" }} gap={1}>
          <Grid item md={2}>
            <Item>
              <TextField
                type="datetime-local"
                fullWidth
                size="small"
                id="addressingDate"
                label="Appointment Date"
                name="addressing_date"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                autoFocus
                value={appointmentDateFilter}
                onChange={handleAppointmentDateFilter}
              />
            </Item>
          </Grid>
          <Grid item lg={5}>
            <Item
              onChange={(e) => {
                setAppointmentSearch(e.target.value);
              }}
            >
              <Autocomplete
                required
                size="small"
                options={appointmentOptions}
                value={selectedAppointment}
                name="appointment_name"
                onChange={(event, newColgState) => {
                  setSelectedAppointment(newColgState);
                  setIsSearchDisabled(false); 
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props} style={{ width: "200%" }}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Appointment"
                    variant="outlined"
                    disabled={dataFiltered}
                  />
                )}
              />
            </Item>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              size="small"
              // disabled={isSearchDisabled}
              // style={{
              //   backgroundColor: isSearchDisabled ? "gray" : "#003285",
              //   color: "#fff",
              // }}
              style={{
                backgroundColor:  "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}

      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            overflowY: "auto",
            whiteSpace: "nowrap",
           
          }}
          style={{ maxHeight: window.innerWidth > 1800 ? 680 : 490 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "90px !important",
                  }}
                >
                  Sl.No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "400px !important",
                    whiteSpace: "normal", // Allow text to wrap
                    wordBreak: "break-word", // Break long words
                  }}
                >
                  Appointment Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "120px !important",
                  }}
                >
                  Add GD
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  GD Start Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  GD End Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "120px !important",
                  }}
                >
                  GD Edit
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                    whiteSpace: "normal", // Allow text to wrap
                    wordBreak: "break-word", // Break long words
                  }}
                >
                  Group Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                    whiteSpace: "normal", // Allow text to wrap
                    wordBreak: "break-word", // Break long words
                  }}
                >
                  Interviewer Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "130px !important",
                  }}
                >
                  Total Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "130px !important",
                  }}
                >
                  Joined Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Duration in Min
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Question Per Group
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Not Joined Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Shortlised Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  GD Rejected Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  GD Hold Count
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={13} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : gd_list && gd_list.length > 0 ? (
                gd_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tooltip
                        title={Object.values(val.appointment_id).map(
                          (ea) => ea.name
                        )}
                      >
                        <p>
                          {val.appointment_id === null ||
                          val.appointment_id === ""
                            ? "N/A"
                            : Object.values(val.appointment_id).map(
                                (ea, index) => (
                                  <Box
                                    key={index} // Ensure each Box has a unique key
                                    align="center"
                                    sx={{
                                      height: "auto", // Adjust height to auto to allow wrapping
                                      fontWeight: "bold",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                    }}
                                  >
                                    {/* {ea?.["name"].slice(0, 45)} */}
                                    {ea?.["name"].slice(0, 40)}
                                  </Box>
                                )
                              )}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        height: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      <Tooltip title="Edit">
                        <AddGDModal stateAddresssing={val} />
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (val, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val.start_date_time
                                      ? moment
                                          .utc(toIsoFormat(val.start_date_time))
                                          .local()
                                          .format("DD-MM-yyyy hh:mm A")
                                      : "N/A"}
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (val, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {val.end_date_time
                                      ? moment
                                          .utc(toIsoFormat(val.end_date_time))
                                          .local()
                                          .format("DD-MM-yyyy hh:mm A")
                                      : "N/A"}
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    onClick={() => {
                                      getData(value);
                                    }}
                                  >
                                    <Tooltip title="Edit">
                                      <EditGdModal
                                        stateAddresssing={groupData}
                                        stateAddresssing1={val}
                                        group_idz={
                                          Object.keys(val.group_details)[index]
                                        }
                                      />
                                    </Tooltip>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    sx={{
                                      height: "auto", // Adjust height to auto to allow wrapping
                                      fontWeight: "bold",
                                      whiteSpace: "normal", // Allow text to wrap
                                      wordBreak: "break-word", // Break long words
                                    }}
                                  >
                                    <Tooltip title={value.group_name}>
                                      <p>
                                        {" "}
                                        {value.group_name
                                          ? value.group_name
                                          : "N/A"}
                                      </p>
                                    </Tooltip>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                      fontWeight: "bold",
                                      whiteSpace: "normal",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {Object.keys(value.interviewer_id).length >
                                    0
                                      ? Object.values(value.interviewer_id)
                                          .map(
                                            (interviewer) => interviewer.name
                                          )
                                          .join(", ")
                                      : "N/A"}
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {value.total_students
                                          ? value.total_students
                                          : "N/A"}
                                      </p>{" "}
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        <GDRegistredStudentList
                                          group_idz={
                                            Object.keys(val.group_details)[
                                              index
                                            ]
                                          }
                                        ></GDRegistredStudentList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {value.joined_students_count
                                          ? value.joined_students_count
                                          : 0}
                                      </p>{" "}
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        <GDJoinedStudentList
                                          group_idx={
                                            Object.keys(val.group_details)[
                                              index
                                            ]
                                          }
                                        ></GDJoinedStudentList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {value.gd_duration_per_group
                                      ? value.gd_duration_per_group
                                      : "N/A"}
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {value.questions_per_group
                                      ? value.questions_per_group
                                      : "N/A"}
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {value.not_joined_students_count
                                          ? value.not_joined_students_count
                                          : 0}
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        <GDNotJoinedStudentList
                                          group_idNot={
                                            Object.keys(val.group_details)[
                                              index
                                            ]
                                          }
                                        ></GDNotJoinedStudentList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {" "}
                                        {value.shortlisted_count
                                          ? value.shortlisted_count
                                          : 0}
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        <GDShortListStudent
                                          group_idy={
                                            Object.keys(val.group_details)[
                                              index
                                            ]
                                          }
                                        ></GDShortListStudent>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.values(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {value.rejected_count
                                          ? value.rejected_count
                                          : 0}
                                      </p>{" "}
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        <GDRejectedStudentList
                                          group_idR={
                                            Object.keys(val.group_details)[
                                              index
                                            ]
                                          }
                                        ></GDRejectedStudentList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table>
                        <TableBody>
                          {!val.group_details ||
                          Object.keys(val.group_details).length === 0 ? (
                            <TableRow>
                              <TableCell align="center">N/A</TableCell>
                            </TableRow>
                          ) : (
                            Object.values(val.group_details).map(
                              (value, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell
                                    align="center"
                                    style={{
                                      height: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>
                                        {" "}
                                        {value.hold_students_count
                                          ? value.hold_students_count
                                          : 0}
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        <GDJobOfferedList
                                          group_idJob={
                                            Object.keys(val.group_details)[
                                              index
                                            ]
                                          }
                                        ></GDJobOfferedList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={13}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
