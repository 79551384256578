import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Container,
  Paper,
  Checkbox,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, FormControlLabel, Chip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./CampusSpocCreation.module.css";
import { useEffect } from "react";

import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import Cookies from "js-cookie";
import AxiosInstance from "../../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function CCspocCreationFromCollegeList() {
  const { state } = useLocation();

  console.log("state", state);

  //!MUI THEME
  const theme = useTheme();

  const [selectedUniversity, setSelectedUniversity] = useState(
    state.university_name
  );
  const [selectedCollege, setSelectedCollege] = useState(state.name);
  const [selectedColgState, setSelectedColgState] = useState(state.state_name);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(
    state.district_name
  );
  const [designationOption, setDesignationOption] = useState([]);
  const [designationOption_1, setDesignationOption_1] = useState([]);
  // const [uniStataeOptions, setUniStataeOptions] = useState([]);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  //!EMAIL VALIDATION STATE
  const [isValid, setIsValid] = useState(false);
  const [isValid_1, setIsValid_1] = useState(false);

  //!SEARCH
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDesignation, setSearchDesignation] = useState("");
  const [searchDesignation_1, setSearchDesignation_1] = useState("");
  // const [uniSeacrh, setUniSeacrh] = useState("a");
  const [searchDistrict, setSearchDistrict] = useState("");
  const [searchUniversity, setSearchUniversity] = useState("");
  const [searchCollege, setSearchCollege] = useState("");

  //!FORM STATE VALUES
  //navigation
  const Navigate = useNavigate();

  //college spoc name
  const [name, setName] = useState("");
  const [name_1, setName_1] = useState("");
  //comment
  const [comment, setComment] = useState("");
  const [comment_1, setComment_1] = useState("");
  //university
  const [uniData, setUniData] = useState(null);

  //state
  const [stateData, setStateData] = useState(null);

  //district
  const [districtData, setDistrictData] = useState(null);

  //college
  // const [collegeDataa, setCollegeDataa] = useState([]);
  const [collegeDataa, setCollegeDataa] = useState(null);

  //designation
  const [designationData, setDesignationData] = useState(null);
  const [designationData_1, setDesignationData_1] = useState(null);
  //our programme
  const [programmeData, setProgrammeData] = useState("");
  const [programmeListOption, setProgrammeListOption] = useState([]);
  //Primary spoc checkbox
  const [primarySpoc, setChecked] = React.useState(false);
  const [primarySpoc1, setChecked1] = useState(false);
  //Landline
  let [landlines, setLandlines] = useState([
    {
      landline: "",
      is_personal: false,
    },
  ]);

  let [landlines1, setLandlines1] = useState([
    { landline: "", is_personal: false },
  ]);
  const [error, setError] = useState(false);
  //Personal Mobile  Number
  let [mobile, setmobile] = React.useState([
    {
      mobile: "",
      is_personal: false,
    },
  ]);

  //Personal Email
  let [email, setEmail] = React.useState([
    {
      email: "",
      is_personal: false,
    },
  ]);

  //Personal Mobile  Number1
  let [mobile1, setmobile1] = React.useState([
    {
      mobile: "",
      is_personal: false,
    },
  ]);

  //Personal Email1
  let [email1, setEmail1] = React.useState([
    {
      email: "",
      is_personal: false,
    },
  ]);

  //!ERROR MESSAGE STATES
  let [errorForProgramme, setErrorForProgramme] = React.useState(false);
  let [errorForDesignation_1, setErrorForDesignation_1] = React.useState(false);
  let [errorForDesignation_2, setErrorForDesignation_2] = React.useState(false);

  // let searchUniState = async v => {
  //   v.target.value === "" ? setUniSeacrh("a") : setUniSeacrh(v.target.value);
  // };

  //!EMAIL VALIDATION FUNVTION

  const handleCancelClick = () => {
    window.history.back();
  };

  //!DISABLE FORM SUBMIT BUTTON
  let handleDisableForm =
    name == "" ||
    designationData == null ||
    // designationData_1 == null ||
    programmeData == "" ||
    landlines == [{ landline: null, is_personal: false }] ||
    // landlines1 == [{ landline: "" }] ||
    mobile ==
      [
        {
          mobile: "",
          is_personal: false,
        },
      ] ||
    // mobile1 ==
    //   [
    //     {
    //       mobile: "",
    //       is_personal: false,
    //     },
    //   ] ||
    email ==
      [
        {
          email: "",
          is_personal: false,
        },
      ];
  // ||
  // email1 ==
  //   [
  //     {
  //       email: "",
  //       is_personal: false,
  //     },
  //   ];

  // const handleChange_22 = event => {
  //   setProgrammeData(event.target.value);

  // };

  const [states, setStates] = useState({
    primarySpoc: "",
    primarySpoc1: "",
    college_contact_details: "",
    college_contact_details1: "",
  });

  //! ? handle change for Landline
  const handleLandChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...landlines];
    list[index][name] = value;
    setLandlines(list);
  };

  let handlepersonalLandlineChange = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...landlines];
    list[ind][name] = checked;
    setLandlines(list);
  };

  const handleRemoveLand = (index) => {
    const list = [...landlines];
    list.splice(index, 1);
    setLandlines(list);
  };
  const handleAddLand = () => {
    setLandlines([...landlines, { landline: "", is_personal: false }]);
  };

  //! handle change for LandLine1

  //! ? handle change for Landline
  const handleLandChange1 = (val, index) => {
    let { name, value } = val.target;
    let list = [...landlines1];
    list[index][name] = value;
    setLandlines1(list);
  };

  let handlepersonalLandlineChange1 = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...landlines1];
    list[ind][name] = checked;
    setLandlines1(list);
  };

  const handleRemoveLand1 = (index) => {
    const list = [...landlines1];
    list.splice(index, 1);
    setLandlines1(list);
  };
  const handleAddLand1 = () => {
    setLandlines1([...landlines1, { landline: "", is_personal: false }]);
  };

  //! ? handle change for mobile
  const handleMobChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...mobile];
    list[index][name] = value;
    setmobile(list);
  };

  let handlepersonalMobChange = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...mobile];
    list[ind][name] = checked;
    setmobile(list);
  };

  const handleRemoveMob = (index) => {
    const list = [...mobile];
    list.splice(index, 1);
    setmobile(list);
  };
  const handleAddMob = () => {
    setmobile([
      ...mobile,
      {
        mobile: "",
        is_personal: false,
      },
    ]);
  };

  //! ? handle change for mobile1
  const handleMobChange1 = (val, index) => {
    let { name, value } = val.target;
    let list = [...mobile1];
    list[index][name] = value;
    setmobile1(list);
  };

  let handlepersonalMobChange1 = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...mobile1];
    list[ind][name] = checked;
    setmobile1(list);
  };

  const handleRemoveMob1 = (index) => {
    const list = [...mobile1];
    list.splice(index, 1);
    setmobile1(list);
  };
  const handleAddMob1 = () => {
    setmobile1([
      ...mobile1,
      {
        mobile: "",
        is_personal: false,
      },
    ]);
  };

  //! ? handle change for Email
  const handleEmailChange = (val, index) => {
    let { name, value } = val.target;
    if (value !== "") {
      let list = [...email];
      list[index][name] = value;
      setEmail(list);
    } else {
      let list = [...email];
      list[index][name] = value;
      setEmail(list);
    }
  };

  let handlepersonalEmailChange = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...email];
    list[ind][name] = checked;
    setEmail(list);
  };

  const handleRemoveEmail = (index) => {
    const list = [...email];
    list.splice(index, 1);
    setEmail(list);
  };
  const handleAddEmail = () => {
    setEmail([
      ...email,
      {
        email: "",
        is_personal: false,
      },
    ]);
  };

  //! ? handle change for Email1
  const handleEmailChange1 = (val, index) => {
    let { name, value } = val.target;
    if (value !== "") {
      let list = [...email1];
      list[index][name] = value;
      setEmail1(list);
    } else {
      let list = [...email1];
      list[index][name] = value;
      setEmail1(list);
    }
  };

  let handlepersonalEmailChange1 = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...email1];
    list[ind][name] = checked;
    setEmail1(list);
  };

  const handleRemoveEmail1 = (index) => {
    const list = [...email1];
    list.splice(index, 1);
    setEmail1(list);
  };
  const handleAddEmail1 = () => {
    setEmail1([
      ...email1,
      {
        email: "",
        is_personal: false,
      },
    ]);
  };

  const handleChange = (e) => {
    setStates({ ...states, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersAndOnePeriodRegex = /^[a-zA-Z\s]*\.?[a-zA-Z\s]*$/;

    if (!lettersAndOnePeriodRegex.test(value)) {
      // If the input contains more than one period or non-letter characters, remove them
      setName(value.replace(/[^a-zA-Z\s.]/g, "").replace(/\.{2,}/g, "."));
    } else {
      setName(value);
    }
  };

  const handleInputChange1 = (e) => {
    const { value } = e.target;
    const lettersAndOnePeriodRegex = /^[a-zA-Z\s]*\.?[a-zA-Z\s]*$/;

    if (!lettersAndOnePeriodRegex.test(value)) {
      // If the input contains more than one period or non-letter characters, remove them
      setName_1(value.replace(/[^a-zA-Z\s.]/g, "").replace(/\.{2,}/g, "."));
    } else {
      setName_1(value);
    }
  };

  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange2 = (event) => {
    setChecked1(event.target.checked);
  };

  //! Add Page

  let [addPage, setAddPage] = useState(false);

  let handleAddPageClick = () => {
    setAddPage(!addPage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      // Filter out empty landline values
      const filteredLandlines = landlines.filter(
        (item) => item.landline && item.landline.trim() !== ""
      );
      const filteredLandlines1 = landlines1.filter(
        (item) => item.landline && item.landline.trim() !== ""
      );

      let payload;

      if (addPage === false) {
        payload = {
          college_id: state.id,
          state_id: state.state_id,
          district_id: state.district_id,
          university_id: state.university_id,
          programme_id: 1,
          spoc_type: programmeData == "1" ? 1 : 2,
          college_contact_details: [
            {
              name: name,
              email: email,
              mobile: mobile,
              landline: filteredLandlines.length > 0 ? filteredLandlines : [],
              designation_id: designationData.ID,
              comment: comment,
              is_primary_spoc: primarySpoc == true ? 1 : 2,
            },
          ],
        };
      } else {
        payload = {
          college_id: state.id,
          state_id: state.state_id,
          district_id: state.district_id,
          university_id: state.university_id,
          programme_id: 1,
          spoc_type: programmeData == "1" ? 1 : 2,
          college_contact_details: [
            {
              name: name,
              email: email,
              mobile: mobile,
              landline: filteredLandlines.length > 0 ? filteredLandlines : [],
              designation_id: designationData.ID,
              comment: comment,
              is_primary_spoc: primarySpoc == true ? 1 : 2,
            },
            {
              name: name_1,
              email: email1,
              mobile: mobile1,
              landline: filteredLandlines1.length > 0 ? filteredLandlines1 : [],
              designation_id: designationData_1.ID,
              comment: comment_1,
              is_primary_spoc: primarySpoc1 == true ? 1 : 2,
            },
          ],
        };
      }
      await AxiosInstance.post("/college_spoc", payload, {
        "content-type": "multipart/form-data",
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Spoc Created Successfully");
            setTimeout(() => {
              Navigate("/college_appointment_from_campus_spoc", {
                state: {
                  data: response.data.data[0],
                },
              });
            }, 1000);
          } else if (response.status == 401) {
            Navigate("/login", {
              state: {
                data: response.data.data[0],
              },
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error1);
          toast.error(error.response.data.error2);
          toast.error(error.response.data.error3);
          toast.error(error.response.data.error4);
          toast.error(error.response.data.error5);
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.code);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/appointment_programme`);
      let finalExamTypeData = examTypeData.data.data;
      delete finalExamTypeData["2"];
      setProgrammeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      if (searchDesignation !== "") {
        //Designation api call
        let designationApi = await AxiosInstance.get(
          "/spoc_designation?search_keyword=${searchDesignation}"
        );
        let finalDesignation = designationApi.data.data;
        setDesignationOption(finalDesignation);
      } else {
        //Designation api call
        let designationApi = await AxiosInstance.get("/spoc_designation");
        let finalDesignation = designationApi.data.data;
        setDesignationOption(finalDesignation);
      }
    };
    fetchData();
  }, [searchDesignation]);

  useEffect(() => {
    let fetchData = async () => {
      if (searchDesignation_1 !== "") {
        //Designation api call_1
        let designationApi_1 = await AxiosInstance.get(
          "/spoc_designation?search_keyword=${searchDesignation_1}"
        );
        let finalDesignation_1 = designationApi_1.data.data;
        setDesignationOption_1(finalDesignation_1);
      } else {
        //Designation api call_1
        let designationApi_1 = await AxiosInstance.get("/spoc_designation");
        let finalDesignation_1 = designationApi_1.data.data;
        setDesignationOption_1(finalDesignation_1);
      }
    };
    fetchData();
  }, [searchDesignation_1]);

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <section
      style={{
        width: "99%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "4% auto",
      }}
    >
      <Paper
        variant="outlined"
        style={{
          width: "100%",
          border: "2px solid #9BA4B5",
          borderRadius: "10px",
        }}
      >
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#081f37",
                fontSize: {
                  xs: "20px", // Smaller font size on small screens
                  sm: "25px", // Medium font size on small to medium screens
                  md: "30px", // Larger font size on medium to large screens
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "1.02px",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              SPOC CREATION
            </Box>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              // marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1%",
            }}
          >
            <Grid container spacing={1}>
              <Grid sm={2.5} xs={12}>
                <Item>
                  <InputLabel
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#495579",
                    }}
                  >
                    College State
                  </InputLabel>
                  <TextField
                    fullWidth
                    disabled
                    // label="State"
                    variant="outlined"
                    required
                    value={selectedColgState}
                    name="state_name"
                  />
                </Item>
              </Grid>
              <Grid sm={2.5} xs={12}>
                <Item>
                  <InputLabel
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#495579",
                    }}
                  >
                    College District
                  </InputLabel>
                  <TextField
                    fullWidth
                    disabled
                    variant="outlined"
                    required
                    value={selectedColgDistrict}
                  />
                </Item>
              </Grid>

              <Grid sm={2.5} xs={12}>
                <Item>
                  <InputLabel
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#495579",
                    }}
                  >
                    University
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    required
                    disabled
                    value={selectedUniversity}
                    name="university_name"
                  />
                </Item>
              </Grid>

              {/* College   */}
              <Grid sm={2.5} xs={12}>
                <Item>
                  <InputLabel
                    htmlFor="my-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#495579",
                    }}
                  >
                    College
                  </InputLabel>
                  <TextField
                    fullWidth
                    disabled
                    variant="outlined"
                    required
                    value={selectedCollege}
                  />
                </Item>
              </Grid>
              <Grid sm={2} xs={12} style={{ marginTop: "1%" }}>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-name-label">
                      Programme
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      value={programmeData}
                      onChange={(event) => {
                        setProgrammeData(event.target.value);
                        setErrorForProgramme(setProgrammeData == "");
                      }}
                      input={<OutlinedInput label="Programme" />}
                      MenuProps={MenuProps}
                      error={errorForProgramme}
                      helperText={
                        errorForProgramme ? "Please select Programme" : ""
                      }
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 10)", // Default border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                        },
                      }}
                    >
                      {/* <MenuItem value="Incubation">Incubation</MenuItem> */}
                      {Object.entries(programmeListOption).map(
                        ([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        )
                      )}
                      {/* {names.map(name => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, programmeData, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))} */}
                    </Select>
                  </FormControl>
                </Item>
              </Grid>

              <Paper
                item
                sm={12}
                style={{
                  position: "relative",
                  margin: "0.5% auto",
                  border: "2px solid #9BA4B5",
                  borderRadius: "10px",
                }}
                variant="outlined"
                sx={{
                  my: { xs: 2, md: 2 },
                  p: { xs: 2, md: 3 },
                  width: "95%",
                  boxShadow: "1px 2px 10px #efefef",
                }}
              >
                <Grid>
                  <Grid item xs={12} container>
                    <Grid item sm={12} lg={12} md={12}>
                      <Box
                        fontSize={22}
                        fontWeight={600}
                        color={
                          role === "1"
                            ? "#00337c"
                            : role === "5"
                            ? "#F86F03"
                            : "#00337c"
                        }
                      >
                        College Contact Details
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid item container spacing={2}>
                  <Grid item xs={12} lg={3.66} md={3.66}>
                    <TextField
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="College Spoc Name"
                      value={name}
                      // onChange={e => {
                      //   setName(e.target.value);
                      // }}
                      onChange={handleInputChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Grid
                    item
                    lg={3.81}
                    md={3.81}
                    xs={12}
                    onChange={(e) => setSearchDesignation(e.target.value)}
                  >
                    {/* ========================================== */}
                    <Autocomplete
                      options={designationOption}
                      value={designationData}
                      name="designationOption"
                      onChange={(event, newData_xyc) => {
                        setDesignationData(newData_xyc);
                        setErrorForDesignation_1(newData_xyc == null);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Designation"
                          variant="outlined"
                          fullWidth
                          error={errorForDesignation_1}
                          helperText={
                            errorForDesignation_1
                              ? "Select Your Designation"
                              : ""
                          }
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                    {/* ============================================= */}
                  </Grid>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Grid item xs={12} md={3.85} lg={3.85}>
                    <TextField
                      id="outlined-comment-input"
                      label="Comment"
                      name="comment"
                      type="text"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      rows={1}
                      multiline
                      // style={{ width: "850px" }}
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    // value={mobile}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="primarySpoc"
                          checked={primarySpoc}
                          onChange={handleChange1}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Primary Spoc"
                      style={{ color: "grey" }}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid item container spacing={2}>
                  <Grid item xs={12} lg={3.8} md={3.8}>
                    {landlines.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                          }}
                          key={index + 1}
                        >
                          <Grid xs={12}>
                            <Grid item xs={12}>
                              <TextField
                                style={{ marginBottom: "5px" }}
                                type="tel"
                                label="Landline"
                                name="landline"
                                variant="outlined"
                                onKeyPress={preventMinus}
                                inputProps={{ minLength: 8, maxLength: 10 }}
                                // size="small"
                                fullWidth
                                value={item.landline}
                                onChange={(e) => handleLandChange(e, index)}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item sx={{ marginLeft: "15px" }}>
                              <Checkbox
                                name="is_personal"
                                value={item.is_personal}
                                onChange={(e) => {
                                  handlepersonalLandlineChange(e, index);
                                }}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                style={{ marginRight: "2%" }}
                              />
                              Personal Number
                            </Grid>
                          </Grid>
                          <div style={{ width: "min-content" }}>
                            {landlines.length !== 1 && (
                              <span
                                title="Delete Landline Number"
                                className={Styles.btns}
                                type="button"
                                onClick={() => handleRemoveLand(index)}
                              >
                                <CancelIcon color="error" />
                              </span>
                            )}
                            {landlines.length - 1 === index && (
                              <span
                                title="Add More Landline Number"
                                className={Styles.btns}
                                type="button"
                                style={
                                  landlines.length < 3
                                    ? { display: "inline-block" }
                                    : { display: "none" }
                                }
                                onClick={handleAddLand}
                              >
                                <AddCircleOutlineIcon color="success" />
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid item xs={12} position="relative" lg={4}>
                    {mobile.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                          }}
                          key={index + 1}
                        >
                          <Grid xs={12}>
                            <Grid item xs={12}>
                              <TextField
                                type="tel"
                                label="Mobile"
                                variant="outlined"
                                onKeyPress={preventMinus}
                                inputProps={{
                                  minLength: 10,
                                  maxLength: 10,
                                }}
                                fullWidth
                                name="mobile"
                                required
                                value={item.mobile}
                                onChange={(e) => handleMobChange(e, index)}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item sx={{ marginLeft: "15px" }}>
                              <Checkbox
                                name="is_personal"
                                value={item.is_personal}
                                onChange={(e) => {
                                  handlepersonalMobChange(e, index);
                                }}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                style={{ marginRight: "2%" }}
                              />
                              Personal Number
                            </Grid>
                          </Grid>

                          <div
                            style={{
                              width: "min-content",
                            }}
                          >
                            {mobile.length !== 1 && (
                              <span
                                title="Delete Mobile Number"
                                className={Styles.btns}
                                type="button"
                                onClick={() => handleRemoveMob(index)}
                              >
                                <CancelIcon color="error" />
                              </span>
                            )}
                            {mobile.length - 1 === index && (
                              <span
                                title="Add More email"
                                className={Styles.btns}
                                type="button"
                                style={
                                  mobile.length < 3
                                    ? { display: "inline-block" }
                                    : { display: "none" }
                                }
                                onClick={handleAddMob}
                              >
                                <AddCircleOutlineIcon color="success" />
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid item position="relative" lg={4} md={4} xs={12}>
                    {email.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                          }}
                          key={index + 1}
                        >
                          <Grid xs={12}>
                            <Grid item xs={12}>
                              <TextField
                                type="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                name="email"
                                required
                                value={item.email}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                                onChange={(e) => {
                                  const regexPattern =
                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  handleEmailChange(e, index);
                                  return setIsValid(
                                    regexPattern.test(e.target.value)
                                  );
                                }}
                                helperText={
                                  email[0].email == ""
                                    ? ""
                                    : isValid
                                    ? ""
                                    : "Please enter the valid email"
                                }
                                error={
                                  email[0].email == ""
                                    ? ""
                                    : isValid
                                    ? ""
                                    : "Please enter the valid email"
                                }
                              />
                            </Grid>
                            <Grid>
                              <Checkbox
                                name="is_personal"
                                value={item.is_personal}
                                onChange={(e) => {
                                  handlepersonalEmailChange(e, index);
                                }}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                style={{ marginRight: "2%" }}
                              />
                              Personal Email
                            </Grid>
                          </Grid>

                          <div style={{ width: "min-content" }}>
                            {email.length !== 1 && (
                              <span
                                title="Delete Email "
                                className={Styles.btns}
                                type="button"
                                onClick={() => handleRemoveEmail(index)}
                              >
                                <CancelIcon color="error" />
                              </span>
                            )}
                            {email.length - 1 === index && (
                              <span
                                title="Add More email"
                                className={Styles.btns}
                                type="button"
                                style={
                                  email.length < 3
                                    ? { display: "inline-block" }
                                    : { display: "none" }
                                }
                                onClick={handleAddEmail}
                              >
                                <AddCircleOutlineIcon color="success" />
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>

                {addPage === true ? (
                  <span
                    className={Styles.addPage}
                    type="button"
                    onClick={handleAddPageClick}
                  >
                    <CancelIcon color="error" />
                  </span>
                ) : (
                  ""
                )}

                <span
                  className={Styles.addPage}
                  type="button"
                  onClick={handleAddPageClick}
                  style={{ display: addPage === true ? "none" : "block" }}
                >
                  <AddCircleOutlineIcon color="success" />
                </span>
              </Paper>
              {addPage ? (
                <Paper
                  item
                  sm={12}
                  style={{
                    position: "relative",
                    margin: "1.5% auto",
                    display: addPage === true ? "block" : "none",
                    border: "2px solid #9BA4B5",
                    borderRadius: "10px",
                  }}
                  variant="outlined"
                  sx={{
                    my: { xs: 5, md: 5 },
                    p: { xs: 2, md: 3 },
                    width: "95%",
                    boxShadow: "1px 2px 10px #efefef",
                  }}
                >
                  <Grid>
                    <Grid item xs={12} container>
                      <Grid item sm={12} lg={12} md={12}>
                        <Box fontSize={22} fontWeight={600} color={"#00337c"}>
                          College Contact Details
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid item container spacing={2}>
                    <Grid item xs={12} lg={3.66} md={3.66}>
                      <TextField
                        name="college_contact_details1"
                        required
                        fullWidth
                        id="college_contact_details1"
                        label="College Spoc Name"
                        value={name_1}
                        // onChange={event => {
                        //   setName_1(event.target.value);
                        // }}
                        onChange={handleInputChange1}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Grid
                      item
                      lg={3.81}
                      md={3.81}
                      xs={12}
                      onchange={(e) => setSearchDesignation_1(e.target.value)}
                    >
                      {/* ========================================== */}
                      <Autocomplete
                        options={designationOption_1}
                        value={designationData_1}
                        name="designationOption_1"
                        onChange={(event, newData_xyc) => {
                          setDesignationData_1(newData_xyc);
                          setErrorForDesignation_2(newData_xyc == null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="*Select Designation"
                            variant="outlined"
                            fullWidth
                            error={errorForDesignation_2}
                            helperText={
                              errorForDesignation_2
                                ? "Select Your Designation"
                                : ""
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        )}
                      />
                      {/* ============================================= */}
                    </Grid>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Grid item xs={12} md={3.85} lg={3.85}>
                      <TextField
                        id="outlined-comment-input"
                        label="Comment"
                        name="comment_1"
                        type="text"
                        value={comment_1}
                        onChange={(e) => {
                          setComment_1(e.target.value);
                        }}
                        rows={1}
                        multiline
                        // style={{ width: "850px" }}
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} onChange={handleChange}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="primarySpoc1"
                            checked={primarySpoc1}
                            onChange={handleChange2}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Primary Spoc"
                        style={{ color: "grey" }}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid item container spacing={2}>
                    <Grid item xs={12} md={3.8} lg={3.8}>
                      {landlines1.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                            }}
                            key={index + 1}
                          >
                            <Grid xs={12}>
                              <Grid item xs={12}>
                                <TextField
                                  style={{ marginBottom: "5px" }}
                                  type="tel"
                                  label="Landline"
                                  variant="outlined"
                                  onKeyPress={preventMinus}
                                  inputProps={{ minLength: 8, maxLength: 10 }}
                                  // size="small"
                                  fullWidth
                                  name="landline"
                                  value={item.landline}
                                  onChange={(e) => handleLandChange1(e, index)}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid>
                                <Checkbox
                                  name="is_personal"
                                  value={item.is_personal}
                                  onChange={(e) => {
                                    handlepersonalLandlineChange1(e, index);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  style={{ marginRight: "2%" }}
                                />
                                Personal Number
                              </Grid>
                            </Grid>
                            <div style={{ width: "min-content" }}>
                              {landlines1.length !== 1 && (
                                <span
                                  title="Delete Landline Number"
                                  className={Styles.btns}
                                  type="button"
                                  onClick={() => handleRemoveLand1(index)}
                                >
                                  <CancelIcon color="error" />
                                </span>
                              )}
                              {landlines1.length - 1 === index && (
                                <span
                                  title="Add More Landline Number"
                                  className={Styles.btns}
                                  type="button"
                                  style={
                                    landlines1.length < 3
                                      ? { display: "inline-block" }
                                      : { display: "none" }
                                  }
                                  onClick={handleAddLand1}
                                >
                                  <AddCircleOutlineIcon color="success" />
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Grid>

                    <Grid item xs={12} position="relative" lg={4}>
                      {mobile1.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                            }}
                            key={index + 1}
                          >
                            <Grid xs={12}>
                              <Grid item xs={12}>
                                <TextField
                                  type="text"
                                  label="Mobile"
                                  variant="outlined"
                                  onKeyPress={preventMinus}
                                  inputProps={{
                                    minLength: 10,
                                    maxLength: 10,
                                  }}
                                  fullWidth
                                  name="mobile"
                                  required
                                  value={item.mobile}
                                  onChange={(e) => {
                                    handleMobChange1(e, index);
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid>
                                <Checkbox
                                  name="is_personal"
                                  value={item.is_personal}
                                  onChange={(e) => {
                                    handlepersonalMobChange1(e, index);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  style={{ marginRight: "2%" }}
                                />
                                Personal Number
                              </Grid>
                            </Grid>

                            <div
                              style={{
                                width: "min-content",
                              }}
                            >
                              {mobile1.length !== 1 && (
                                <span
                                  title="Delete Mobile Number"
                                  className={Styles.btns}
                                  type="button"
                                  onClick={() => handleRemoveMob1(index)}
                                >
                                  <CancelIcon color="error" />
                                </span>
                              )}
                              {mobile1.length - 1 === index && (
                                <span
                                  title="Add More Mobile Number"
                                  className={Styles.btns}
                                  type="button"
                                  style={
                                    mobile1.length < 3
                                      ? { display: "inline-block" }
                                      : { display: "none" }
                                  }
                                >
                                  <AddCircleOutlineIcon
                                    color="success"
                                    onClick={handleAddMob1}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Grid>

                    <Grid item position="relative" lg={4} md={4} xs={12}>
                      {email1.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              // marginLeft: "25px",
                            }}
                            key={index + 1}
                          >
                            <Grid xs={12}>
                              <Grid item xs={12}>
                                <TextField
                                  type="email"
                                  label="Email"
                                  variant="outlined"
                                  fullWidth
                                  name="email"
                                  required
                                  value={item.email}
                                  onChange={(e) => {
                                    const regexPattern =
                                      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    handleEmailChange1(e, index);
                                    return setIsValid_1(
                                      regexPattern.test(e.target.value)
                                    );
                                  }}
                                  error={
                                    email1[0].email == ""
                                      ? null
                                      : isValid_1
                                      ? null
                                      : true
                                  }
                                  helperText={
                                    email1[0].email == ""
                                      ? null
                                      : isValid_1
                                      ? null
                                      : "Please enter the valid Email"
                                  }
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid>
                                <Checkbox
                                  name="is_personal"
                                  value={item.is_personal}
                                  onChange={(e) => {
                                    handlepersonalEmailChange1(e, index);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  // style={{ marginRight: "2%" }}
                                />
                                Personal Email
                              </Grid>
                            </Grid>

                            <div style={{ width: "min-content" }}>
                              {email1.length !== 1 && (
                                <span
                                  title="Delete Email "
                                  className={Styles.btns}
                                  type="button"
                                  onClick={() => handleRemoveEmail1(index)}
                                >
                                  <CancelIcon color="error" />
                                </span>
                              )}
                              {email1.length - 1 === index && (
                                <span
                                  title="Add More email"
                                  className={Styles.btns}
                                  type="button"
                                  style={
                                    email1.length < 3
                                      ? { display: "inline-block" }
                                      : { display: "none" }
                                  }
                                  onClick={handleAddEmail1}
                                >
                                  <AddCircleOutlineIcon color="success" />
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>

                  <span
                    className={Styles.addPage}
                    type="button"
                    onClick={handleAddPageClick}
                  >
                    <CancelIcon color="error" />
                  </span>
                </Paper>
              ) : null}
            </Grid>

            <Grid
              xs={12}
              item
              spacing={1}
              sx={{ mt: 2 }}
              style={{
                textAlign: "center",
                padding: "5px",
              }}
            >
              <p
                hidden={!handleDisableForm}
                style={{ fontSize: "14px", color: "#E74646" }}
              >
                Please fill in all the details to proceed
              </p>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  // backgroundColor:"green"
                }}
              >
                <Button
                  onClick={handleCancelClick}
                  variant="contained"
                  style={{
                    backgroundColor: "#E74646",
                    flex: 1,
                    margin: "0 20px",
                    width: "150px",
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  disabled={handleDisableForm}
                  variant="contained"
                  style={{
                    color: "#fff",
                    fontWeight: "500",
                    flex: 1,
                    margin: "0 20px",
                    width: "150px",
                  }}
                >
                  {/* <Link style={{ textDecoration: "none", color: "#fff" }}>
                      Save
                    </Link> */}
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </ThemeProvider>
      </Paper>
    </section>
  );
}
