import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Styles from "../Bd_Module/pages/drawer.module.css";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import { Box } from "@material-ui/core";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Pagination,
  Stack,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { toast } from "react-toastify";
import AxiosInstance from "../apis/AxiosInstance";
import CancelIcon from "@mui/icons-material/Cancel";
import { socketContext } from "./IoContext";
import Cookies from "js-cookie";
import moment from "moment/moment";

const drawerWidth = "31%";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
      top: "0vh",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30vw",
      top: "0vh",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background:
      "radial-gradient(circle at 24.1% 68.8%, #222831 0%, #222831 99.4%)",
    [theme.breakpoints.up("md")]: {
      // top: "8%",
    },
    [theme.breakpoints.up("lg")]: {},
  },
  drawerContainer: {
    overflow: "hidden",
    width: "30vw",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30vw",
    },
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  scrollableContent: {
    overflowY: "auto",
    flexGrow: 1,
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#001D6E",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      role === "1" ? "#8ed6ff" : role === "5" ? "#FCA311" : "#124076",
    color: role === "1" ? "#111" : role === "5" ? "#111" : "#fff",
    fontWeight: "bold",
  },
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function NotificationDrawer() {
  const classes = useStyles();
  const [state, setState] = useState(false);
  const {
    setNotreadNotification,
    sendMessage,
    notificationData,
    notreadNotification,
    isReadAll,
    setIsReadAll,
    alreadyRead,
    setAlreadyRead,
    notificationList,
    setNotificationList,
    page,
    setPage,
    totalNotification,
    setTotalNotification,
    load,
    setLoad,
    defaultState,
    setDefaultState,
    readAllData,
    setReadAllData,
    fetchData,
  } = useContext(socketContext);

  // if (state) {
  // fetchData();
  // }
  // }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/get_notification?page=${newPage}`;
    try {
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setNotificationList(finalData);
      setTotalNotification(data.filtered_count);
      setIsReadAll(!isReadAll);
      if (page !== newPage) {
        setAlreadyRead([]);
      }
      setPage(newPage);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handleSubmit = async (ID, user_id) => {
    const singleNotification = {
      type: "read-notification",
      notification: {
        id: ID,
        user_id: user_id,
      },
    };
    sendMessage(singleNotification);
    let read = [...alreadyRead];
    read.push(ID);
    setAlreadyRead(read);
    // setNotreadNotification(notreadNotification - 1);
  };

  const handleReadAll = async (ID, user_id) => {
    const singleNotification = {
      type: "read-all-notification",
      notification: {
        // id: id,
        id: readAllData ? readAllData?.ID : ID,
        user_id: user_id,
      },
    };
    sendMessage(singleNotification);
    let read = [...alreadyRead];
    notificationList.forEach((e) => {
      read.push(e.ID);
    });
    setAlreadyRead(read);
    fetchData();
    setIsReadAll(!isReadAll);
  };

  const totalPages = Math.ceil(totalNotification / 10);

  const toggleDrawer = (open) => (event) => {
    setState(open);
    if (open) {
      fetchData();
    }
    if (!open) {
      setPage(1);
    }
  };

  const drawer = (
    <div className={classes.drawerContainer} role="presentation">
      <Box
        style={{
          color: "#EDF7FA",
          width: "100%",
          fontSize: "20px",
          padding: "10px",
          textAlign: "center",
          fontWeight: "bolder",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "left",
          borderBottom:
            role === "1"
              ? "4px solid #8ed6ff"
              : role === "5"
              ? "4px solid #FCA311"
              : " 4px solid #378CE7",
        }}
      >
        Notification List
        {/* <span>
          {notificationList.length > 0 && notreadNotification > 0 && (
            <Button
              size="small"
              style={{ background: "#FF6969", color: "#fff" }}
              onClick={() => {
                handleReadAll(
                  notificationList[0].ID,
                  notificationList[0].user_id
                );
              }}
            >
              Read All
            </Button>
          )}
        </span> */}
        <span>
          {notificationList?.length > 0 && notreadNotification > 0 && (
            <Button
              size="small"
              style={{ background: "#FF6969", color: "#fff" }}
              onClick={() => {
                handleReadAll(
                  notificationList[0].ID,
                  notificationList[0].user_id
                );
              }}
            >
              Read All
            </Button>
          )}
        </span>
        <span>
          <Badge badgeContent={notreadNotification} color="warning" max={99999}>
            <NotificationsNoneIcon style={{ fontSize: 30 }} />
          </Badge>
        </span>
        <span>
          <CancelIcon
            style={{ color: "red", fontSize: "30px", cursor: "pointer" }}
            onClick={toggleDrawer(false)}
          />
        </span>
      </Box>
      <div className={classes.scrollableContent}>
        <Grid
          container
          spacing={1}
          style={{ marginTop: "1%", width: "90%", margin: "0 auto" }}
        >
          {load ? (
            <Card style={{ width: "90%", marginTop: "1%" }}>
              <CardContent>
                <Typography variant="h6" textAlign="center" alignItems="center">
                  {" "}
                  Loading...
                </Typography>
              </CardContent>
            </Card>
          ) : notificationList?.length > 0 ? (
            notificationList.map((val, ind) => (
              <Grid item xs={12} md={12} key={ind}>
                <Card
                  style={{
                    background: "#efefef",
                    wordBreak: "break-word",
                    maxHeight: "150px",
                  }}
                >
                  <CardContent>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        fontSize: "13px",
                      }}
                    >
                      {val.description ?? "NA"}

                      {/* <p>
                        {moment(val.created_at)
                          .local()
                          .format("DD-MM-yyyy hh:mm A") ?? "NA"}
                      </p>
                      <p
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {" "}
                        {alreadyRead.includes(val.ID) || val.read ? null : (
                          <Button
                            size="small"
                            style={{
                              background: "#55AD9B",
                              color: "#fff",
                              padding: "2px",
                              fontSize: "11px",
                            }}
                            onClick={() => {
                              handleSubmit(val.ID, val.user_id);
                            }}
                          >
                            Read
                          </Button>
                        )}
                      </p> */}
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{ alignSelf: "flex-start", fontSize: "13px" }}
                      >
                        {moment(val.created_at)
                          .local()
                          .format("DD-MM-yyyy hh:mm A") ?? "NA"}
                      </span>
                      <span style={{ alignSelf: "flex-end" }}>
                        {alreadyRead.includes(val.ID) || val.read ? null : (
                          <Button
                            size="small"
                            style={{
                              background: "#55AD9B",
                              color: "#fff",
                              padding: "2px",
                              fontSize: "11px",
                            }}
                            onClick={() => {
                              handleSubmit(val.ID, val.user_id);
                            }}
                          >
                            Read
                          </Button>
                        )}
                      </span>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Card style={{ width: "100%", marginTop: "1%" }}>
              <CardContent
                style={{
                  background: "#efefef",
                  wordBreak: "break-word",
                  maxHeight: "150px",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ color: "red", textAlign: "center" }}
                >
                  No Data Found
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
        {/* <Pagination
          style={{ maxWidth: "100%", marginTop: "1%" }}
          count={totalPages}
          sx={{ button: { color: "#fff", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        /> */}
        <Pagination
          style={{ maxWidth: "100%", marginTop: "1%", marginLeft: "10%" }}
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          sx={{
            button: {
              color: "#fff",
              fontWeight: "bold",
              "&.Mui-selected": {
                backgroundColor: "#219C90",
                "&:hover": {
                  backgroundColor: "#55AD9B",
                },
              },
            },
          }}
        />
      </div>
    </div>
  );

  return (
    <div>
      <BootstrapTooltip
        title="Notifications"
        placement="bottom"
        sx={{ fontWeight: "bolder" }}
      >
        <Button
          disableRipple
          disableTouchRipple
          disableFocusRipple
          disableElevation
          onClick={toggleDrawer(true)}
          style={{ color: "#fff", fontWeight: "600" }}
        >
          <Stack direction="row" spacing={1}>
            <Badge
              badgeContent={notreadNotification}
              color="warning"
              max={99999}
            >
              <NotificationsNoneIcon style={{ fontSize: 30 }} />
            </Badge>
          </Stack>
        </Button>
      </BootstrapTooltip>
      <Drawer
        anchor="right"
        open={state}
        classes={{ paper: classes.drawerPaper }}
        id={Styles.pareeent_2}
      >
        <div className={classes.drawer} id={Styles.pareeent_1}>
          {drawer}
        </div>
      </Drawer>
    </div>
  );
}
