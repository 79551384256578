// import * as React from "react";
// import { Box, Button, Typography, Modal, Tooltip } from "@mui/material";
// import { FaEdit } from "react-icons/fa";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import EditGDMultipleInterviewerModal from "./EditGDMultipleInterviewerModal";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "auto",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
// };

// export default function EditGdModal(props) {
//   const navigate = useNavigate();
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   // const handleOpen = () => {
//   //   if (stateAddresssing.validate) {
//   //     setOpen(true);
//   //   } else {
//   //     toast.warning("You can't Edit the GD");
//   //   }
//   // };

//   const handleClose = () => setOpen(false);
//   let { stateAddresssing, status } = props;

//   const handleClick = () => {
//     navigate("/edit_gd", { state: stateAddresssing });
//   };

//   return (
//     <div>
//       <Tooltip title="GD Edit">
//         <Button
//           onClick={handleOpen}
//           sx={{
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <FaEdit
//             fontSize={18}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//               cursor: "pointer",
//             }}
//           />
//         </Button>

//           {/* <FaEdit
//            onClick={handleOpen}
//             fontSize={16}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//               cursor: "pointer",
//             }}
//           /> */}

//         {/* <Button
//           onClick={handleOpen}
//           sx={{
//             "&:hover": { background: "#DBDFFD" },
//             color: stateAddresssing.validate == false ? "#A0A0A0" : "#646FD4",
//             cursor:
//               stateAddresssing.validate == false ? "not-allowed" : "pointer",
//             "&:hover": {
//               color: stateAddresssing.validate == false ? "#A0A0A0" : "#EB455F",
//             },
//           }}
//         >
//           <FaEdit fontSize={18} />
//         </Button> */}
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Typography id="modal-modal-title" variant="h5">
//             Select Single or Multiple GD to Edit GD
//           </Typography>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-around",
//               alignItems: "center",
//               marginTop: "30px",
//             }}
//           >
//             {/* <Link to="/edit_gd" state={stateAddresssing}> */}
//             <Button
//               sx={{ padding: "15px", background: "green", color: "white" }}
//               variant="contained"
//               onClick={handleClick}
//             >
//               single
//             </Button>
//             {/* </Link> */}

//             {/* <Link state={stateAddresssing} to="/edit_multiple_gd"> */}
//             <Button
//               sx={{ background: "blue", color: "white" }}
//               variant="contained"
//             >
//               <EditGDMultipleInterviewerModal
//                 stateAddresssing={stateAddresssing}
//               />
//             </Button>
//             {/* </Link> */}
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import * as React from "react";
import { Box, Button, Typography, Modal, Tooltip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditGDMultipleInterviewerModal from "./EditGDMultipleInterviewerModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function EditGdModal(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleOpen = () => {
  //   if (stateAddresssing.validate) {
  //     setOpen(true);
  //   } else {
  //     toast.warning("You can't Edit the GD");
  //   }
  // };

  const handleClose = () => setOpen(false);
  let { stateAddresssing, stateAddresssing1, group_idz } = props;

  const handleClick = () => {
    navigate("/edit_gd", {
      state: {
        stateAddresssing,
        stateAddresssing1,
        group_idz,
      },
    });
  };

  return (
    <div>
      <Tooltip title="GD Edit">
        {/* <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        > */}
        <p onClick={handleOpen}>
          <FaEdit
            fontSize={15}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
              padding: "-10px",
            }}
          />
        </p>
        {/* </Button> */}

        {/* <FaEdit
           onClick={handleOpen}
            fontSize={16}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
            }}
          /> */}

        {/* <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
            color: stateAddresssing.validate == false ? "#A0A0A0" : "#646FD4",
            cursor:
              stateAddresssing.validate == false ? "not-allowed" : "pointer",
            "&:hover": {
              color: stateAddresssing.validate == false ? "#A0A0A0" : "#EB455F",
            },
          }}
        >
          <FaEdit fontSize={18} />
        </Button> */}
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography id="modal-modal-title" variant="h5">
            Select Single or Multiple GD to Edit GD
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {/* <Link to="/edit_gd" state={stateAddresssing}> */}
            <Button
              sx={{ padding: "15px", background: "green", color: "white" }}
              variant="contained"
              onClick={handleClick}
            >
              single
            </Button>
            {/* </Link> */}

            {/* <Link state={stateAddresssing} to="/edit_multiple_gd"> */}
            <Button
              sx={{ background: "blue", color: "white" }}
              variant="contained"
            >
              <EditGDMultipleInterviewerModal
                stateAddresssing={stateAddresssing}
                stateAddresssing1={stateAddresssing1}
              />
            </Button>
            {/* </Link> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
