
import React from "react";
import { Button, Grid, Box, Paper, Typography } from "@mui/material";
import { HiOutlineCurrencyRupee } from "react-icons/hi";

export default function Rewards() {
  return (
    <section
      style={{
        width: "80%",
        margin: "0 auto",
        marginTop: "5%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          width: "60%",
          margin: "4% auto",
          padding: "15px",
          background: "#efefef",
          border: "2px solid #9BA4B5",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#DDE6ED",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Typography
            sx={{
              color: "#081f37",
              fontSize: { xs: "20px", sm: "25px", md: "25px" },
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
          >
            Wallet
          </Typography>
        </Box>

        <Box component="form" sx={{ width: "100%", mt: 3 }}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={6} md={4} sx={{ textAlign: "center" }}>
              <Button
                fullWidth
                sx={{
                  backgroundColor: "#608BC1",
                  color: "#fff",
                  padding: "10px 0",
                  fontSize: "15px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  letterSpacing: "2px",
                  "&:hover": {
                    backgroundColor: "#608BC1",
                  },
                }}
              >
                Total Points
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#111", fontSize: "20px", fontWeight: "bold" }}
              >
                Coming soon......
              </Typography>
            </Grid>

            <Grid item xs={6} md={4} sx={{ textAlign: "center" }}>
              <Button
                fullWidth
                sx={{
                  backgroundColor: "green",
                  color: "#fff",
                  fontSize: "16px",
                  padding: "6px 0",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  letterSpacing: "2px",
                  "&:hover": {
                    backgroundColor: "green",
                  },
                }}
              >
                Redeem &nbsp;
                <span>
                  <HiOutlineCurrencyRupee
                    style={{
                      color: "#fff",
                      fontSize: "27px",
                    }}
                  />
                </span>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#111", fontSize: "20px", fontWeight: "bold" }}
              >
                Coming soon......
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </section>
  );
}
