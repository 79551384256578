import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Box,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { useState, useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import GDJoinedStudentList from "./GDJoinedStudentList";
import GDShortListStudent from "./GDShortListStudent";
import GDRegistredStudentList from "./GDRegistredStudentList";
import GDRejectedStudentList from "./GDRejectedStudentList";
import GDJobOfferedList from "./GDJobOfferedList";
import EditGdModal from "./EditGDModal";
import GDNotJoinedStudentList from "./GDNotJoinedStudentList";
import { RiFilterOffFill } from "react-icons/ri";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function GDList() {
  const [gd_list, setGdList] = useState([]);
  const [appointmentOptions, setAppointmentOptions] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [appointmentSearch, setAppointmentSearch] = useState("");
  const [dataFiltered, setDataFiltered] = useState(false);
  let [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalGD, setTotalGD] = useState(0);

  // for filtering
  const [appointmentDateFilter, setAppointmentDateFilter] = useState("");

  let handleAppointmentDateFilter = (e) => {
    setAppointmentDateFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const params = {
    appointment_id:
      selectedAppointment !== null ? selectedAppointment.id : null,
  };

  const [toastId, setToastId] = useState(null);
  // Handle search button click
  const handleSearch = () => {
    if (!selectedAppointment) {
      // Display toast if any required fields are not selected
      if (!toast.isActive(toastId)) {
        setToastId(
          toast.warning("Please select search parameters before searching")
        );
      }
    } else {
      // Clear existing toast if any
      toast.dismiss(toastId);
      const validParams = Object.keys(params).filter(
        (key) => params[key] !== null
      );
      const generateURL_Query = validParams
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      setQuery(generateURL_Query);
      toast.success("Data filterd");
      setDataFiltered(true);
    }
  };

  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchGD = async (_) => {
      if (query !== "") {
        setLoad(true);
        try {
          let gdData = await AxiosInstance.get(
            `/gd_group?${query}&page=${page}`
          );
          setTotalGD(gdData.data.filtered_count);
          let finalGdData = gdData.data.data;
          if (finalGdData !== null) {
            setGdList(finalGdData);
          } else {
            setGdList([]);
          }
          setLoad(false);
        } catch (error) {
          setLoad(false);
        }
      } else {
        setLoad(true);
        try {
          let gdData = await AxiosInstance.get(`/gd_group?page=${page}`);
          setTotalGD(gdData.data.filtered_count);
          let finalGdData = gdData.data.data;
          if (finalGdData !== null) {
            setGdList(finalGdData);
          }
          setLoad(false);
        } catch (error) {
          setLoad(false);
        }
      }
    };
    fetchGD();
  }, [page, query]);

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (appointmentSearch !== "") {
  //       let colgStateData = await AxiosInstance.get(
  //         `/appointment_mini?search_keyword=${appointmentSearch}&limit=10`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setAppointmentOptions(finalcolgStateData);
  //     } else {
  //       let colgStateData = await AxiosInstance.get(
  //         `/appointment_mini?limit=10`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setAppointmentOptions(finalcolgStateData);
  //     }
  //   };
  //   fetchData();
  // }, [appointmentSearch]);

  // useEffect(() => {
  //   let fetchData = async () => {
  //     if (appointmentSearch !== "") {
  //       try {
  //         let colgStateData = await AxiosInstance.get(
  //           `/appointment_mini?search_keyword=${appointmentSearch}&limit=10`
  //         );
  //         if (colgStateData.data && colgStateData.data.data) {
  //           let finalcolgStateData = colgStateData.data.data;
  //           setAppointmentOptions(finalcolgStateData);
  //         } else {
  //           setAppointmentOptions([]);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching appointment data:", error);
  //       }
  //     } else {
  //       try {
  //         let colgStateData = await AxiosInstance.get(
  //           `/appointment_mini?search_keyword=${appointmentSearch}&limit=10`
  //         );
  //         if (colgStateData.data && colgStateData.data.data) {
  //           let finalcolgStateData = colgStateData.data.data;
  //           setAppointmentOptions(finalcolgStateData);
  //         } else {
  //           setAppointmentOptions([]);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching appointment data:", error);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [appointmentSearch]);

  useEffect(() => {
    let fetchData2 = async () => {
      try {
        if (appointmentDateFilter !== "") {
          let apiUrl;

          if (appointmentSearch !== "") {
            // get appointments based on addressing date and search keyword
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&search_keyword=${appointmentSearch}&limit=10`;
          } else {
            // get appointments based only on addressing date
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&limit=10`;
          }

          let allAddressingAppointments = await AxiosInstance.get(apiUrl);
          let finalAppointmentsDate = allAddressingAppointments.data.data;
          setAppointmentOptions(
            finalAppointmentsDate ? finalAppointmentsDate : []
          );
        } else if (appointmentSearch !== "") {
          // get appointments based only on search keyword
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?search_keyword=${appointmentSearch}&limit=10`
          );
          let finalAppointmentsSearch = allAddressingAppointments.data.data;
          setAppointmentOptions(
            finalAppointmentsSearch ? finalAppointmentsSearch : []
          );
        } else {
          // get all appointments when neither date nor search term is selected
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?limit=10`
          );
          let finalAppointmentsAll = allAddressingAppointments.data.data;
          setAppointmentOptions(
            finalAppointmentsAll ? finalAppointmentsAll : []
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [appointmentSearch, appointmentDateFilter]);

  const totalPages = Math.ceil(totalGD / 10);

  // const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = (event) => {
  //   const { scrollLeft } = event.target;
  //   setScrollPosition(scrollLeft);
  // };

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedAppointment(null);
    window.location.href = "/gd_list";
  };

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  return (
    <main style={{ marginTop: "4.3%" }}>
    <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
          paddingLeft: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
          }}
        >
          GD List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalGD}
        </h1>
      </Box>
      <Grid container>
        <Grid item md={2}>
          <Item>
            <TextField
              type="datetime-local"
              fullWidth
              id="addressingDate"
              label="Appointment Date"
              name="addressing_date"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              autoFocus
              value={appointmentDateFilter}
              onChange={handleAppointmentDateFilter}
            />
          </Item>
        </Grid>
        <Grid item lg={5}>
          <Item
            onChange={(e) => {
              setAppointmentSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={appointmentOptions}
              value={selectedAppointment}
              name="appointment_name"
              onChange={(event, newColgState) => {
                setSelectedAppointment(newColgState);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <li {...props} style={{ width: "200%" }}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Appointment"
                  variant="outlined"
                  disabled={dataFiltered}
                />
              )}
            />
          </Item>
        </Grid>
        <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handleSearch}
            size="small"
            style={{
              backgroundColor: "#003285",
              color: "#fff",
            }}
          >
            Search
          </Button>
        </Grid>
        <Grid
          item
          md={0.9}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={ResetFilterData}
            variant="contained"
            color="warning"
            size="small"
          >
            Reset
          </Button>
        </Grid>
        {/* <Grid
          item
          md={4.2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 style={{ color: "#081F37" }}>
            Total GD List : {totalGD} &nbsp;&nbsp;
          </h1>
        </Grid> */}
      </Grid>
      {/* <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#081F37" }}>
          Total GD List : {totalGD} &nbsp;&nbsp;
        </h1>
      </Grid> */}

      <section style={{ marginTop: "1%" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            overflowY: "auto",
            whiteSpace: "nowrap",
            maxHeight: 550,
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "450px !important",
                  }}
                  // sx={{
                  //   fontWeight: "bold",
                  //   fontSize: "15px",
                  //   position: "sticky",
                  //   left: "0",
                  //   zIndex: "2",
                  //   backgroundColor: "#535962",
                  //   borderRight: "1.5px solid #EAEAEA",
                  // }}
                >
                  Appointment Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  GD Start Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  GD End Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  GD Edit
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px !important",
                  }}
                >
                  Group Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                  }}
                >
                  Interviewer Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Total Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Joined Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Duration in Min
                </StyledTableCell>               
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Not Joined Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Shortlised Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Rejected Count
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Hold Count
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={13} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : gd_list && gd_list.length > 0 ? (
                gd_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell
                    // className={`table-container ${
                    //   scrollPosition > 0 ? "box-shadow" : ""
                    // }`}
                    // component="th"
                    // scope="row"
                    // align="left"
                    // onScroll={handleScroll}
                    // style={{
                    //   position: "sticky",
                    //   left: "0",
                    //   zIndex: "2",
                    //   backgroundColor: `${
                    //     ind % 2 == 0 ? "#F5F5F5" : "white"
                    //   }`,
                    //   borderRight: "2.5px solid #EAEAEA",
                    //   fontWeight: "bold",
                    // }}
                    >
                      <Tooltip
                        title={Object.values(val.appointment_id).map(
                          (ea) => ea.name
                        )}
                      >
                        <p>
                          {" "}
                          {val.appointment_id === null ||
                          val.appointment_id === ""
                            ? "N/A"
                            : Object.values(val.appointment_id).map((ea) => (
                                <Box
                                  style={{ height: "22px", fontWeight: "bold" }}
                                >
                                  {ea?.["name"].slice(0, 45)}
                                </Box>
                              ))}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.start_date_time === null ||
                      val.start_date_time === ""
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(val.start_date_time))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.end_date_time === null || val.end_date_time === ""
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(val.end_date_time))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title="Edit">
                        <EditGdModal stateAddresssing={val} />
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.group_name} placement="bottom">
                        <p> {val.group_name.slice(0, 40)}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {!val.interviewer ||
                      Object.keys(val.interviewer).length === 0
                        ? "N/A"
                        : Object.values(val.interviewer).map((ea) => (
                            <Box style={{ height: "22px" }}>{ea?.name}</Box>
                          ))}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{val.total_students}</p>&nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <GDRegistredStudentList
                            group_idz={val.id}
                          ></GDRegistredStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p> {val.joined_students_count}</p>&nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          {" "}
                          <GDJoinedStudentList
                            group_idx={val.id}
                          ></GDJoinedStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.gd_duration}
                    </StyledTableCell>                 
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {val.not_joined_students_count === null ||
                          val.not_joined_students_count === ""
                            ? 0
                            : val.not_joined_students_count}
                        </p>
                        &nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <GDNotJoinedStudentList
                            group_idNot={val.id}
                          ></GDNotJoinedStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{val.shortlisted_count}</p>&nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <GDShortListStudent
                            group_idy={val.id}
                          ></GDShortListStudent>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{val.rejected_count}</p>&nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <GDRejectedStudentList
                            group_idR={val.id}
                          ></GDRejectedStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>{val.hold_students_count}</p>&nbsp;&nbsp;
                        <p style={{ fontSize: "1px" }}>
                          <GDJobOfferedList
                            group_idJob={val.id}
                          ></GDJobOfferedList>
                        </p>
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={13}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
