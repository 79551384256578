import * as React from "react";
import { Box, Button, Typography, Modal, Tooltip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import { Link } from "react-router-dom";
import EditRemoveSpocCollege from "./EditRemoveSpocCollege";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function EditSpocAddRemoveModal(props) {
  const [open, setOpen] = React.useState(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRemoveModalOpen(false);
  };
  let { editSpocResult, status } = props;

  return (
    <div>
      <Tooltip title="Edit Spoc">
       <p> <FaEdit
          fontSize={17}
          onClick={handleOpen}
          style={{
            color: "#646FD4",
            "&:hover": { color: "#EB455F" },
            cursor: "pointer",
          }}
        /></p>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography id="modal-modal-title" variant="h5">
            Do you want to Add or Remove the Colleges?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {props.editSpocResult.appointment_count > 0 ? (
              <Link to="/editspoc_college" state={editSpocResult}>
                <Button
                  sx={{
                    padding: "10px 20px",
                    background: "green",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Add
                </Button>
              </Link>
            ) : (
              <Button
                sx={{
                  padding: "10px 20px",
                  background: "green",
                  color: "white",
                }}
                variant="contained"
                onClick={() => {
                  toast.error("No Spocs Created yet for this college to edit");
                  setTimeout(() => {
                    handleClose();
                  }, 800);
                }}
              >
                Add
              </Button>
            )}
            {props.editSpocResult.appointment_count > 0 ? (
              <Button
                onClick={() => setRemoveModalOpen(true)}
                sx={{
                  padding: "10px 20px",
                  background: "blue",
                  color: "white",
                }}
                variant="contained"
                state={editSpocResult}
              >
                Remove
              </Button>
            ) : (
              <Button
                sx={{
                  padding: "10px 20px",
                  background: "blue",
                  color: "white",
                }}
                variant="contained"
                onClick={() => {
                  toast.error("No Spocs Created yet for this college to edit");
                  setTimeout(() => {
                    handleClose();
                  }, 800);
                }}
              >
                Remove
              </Button>
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        aria-labelledby="remove-modal-title"
        aria-describedby="remove-modal-description"
      >
        <Box sx={style1}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={() => setRemoveModalOpen(false)}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <EditRemoveSpocCollege props={editSpocResult} />
        </Box>
      </Modal>
    </div>
  );
}
