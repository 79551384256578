import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Box,
  Container,
  Paper,
  Autocomplete,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { TiDelete } from "react-icons/ti";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function RemoveCollegeSpocList() {
  let { state } = useLocation();

  const navigate = useNavigate();

  const theme = useTheme();
  const [programmeData, setProgrammeData] = useState("");
  const [examTypeListOption, setExamTypeListOption] = useState([]);

  const [comment, setComment] = useState("");

  const [colleges, setColleges] = useState(state.colleges);
  const [removedCollegeIds, setRemovedCollegeIds] = useState([]);

  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/reasons`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      move_spoc_id: state.id,
      remove_college_id: removedCollegeIds,
      remove_college_reason:
        programmeData == "1" ? "wrong enter" : "spoc change",
      remove_college_comment: comment,
    };
    try {
      await AxiosInstance.patch("/archive_college_spoc", payload)
        .then((response) => {
          toast.success("Successfully Removed the College in Spoc");
          setTimeout(() => {
            navigate("/college_spoc_list");
          }, 1600);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleRemove = (id) => {
    const updatedColleges = { ...colleges };
    delete updatedColleges[id];
    setColleges(updatedColleges);
    setRemovedCollegeIds((prevIds) => [...prevIds, parseInt(id)]);
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#06113C",
    color: "#fff",
    border: "1px solid #06113C",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const CustomMenuItem = styled(MenuItem)({
    justifyContent: "flex-start",
    textTransform: "capitalize",
  });

  const CustomSelect = styled(Select)({
    ".MuiSelect-select": {
      display: "flex",
      alignItems: "flex-start",
      textTransform: "capitalize",
    },
  });

  return (
    <section style={{ margin: "5%" }}>
      <Paper
        variant="outlined"
        style={{ border: "1px solid gray", borderRadius: "10px" }}
      >
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                color: "#081f37",
                fontSize: {
                  xs: "20px", // Smaller font size on small screens
                  sm: "25px", // Medium font size on small to medium screens
                  md: "30px", // Larger font size on medium to large screens
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "1.02px",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              Reason for Remove College
            </Box>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              // marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1%",
            }}
          >
            <Box style={{ width: "90%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer component={Paper} sx={{ maxHeight: 470 }}>
                    <Table
                      sx={{
                        borderCollapse: "separate",
                        tableLayout: "fixed",
                      }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead
                        style={{
                          color: "#081f37",
                          fontWeight: "600",
                        }}
                      >
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "white",
                              width: "100px !important",
                            }}
                          >
                            Sl No
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "white",
                              width: "300px !important",
                            }}
                          >
                            College Name
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "white",
                              width: "200px !important",
                            }}
                          >
                            Remove
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {colleges && Object.keys(colleges).length > 0 ? (
                          Object.values(colleges).map((college, index) => (
                            <StyledTableRow key={index + 1}>
                              <StyledTableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                {college.name}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ fontWeight: "bold" }}
                              >
                                <Button
                                  type="button"
                                  size="small"
                                  style={{
                                    backgroundColor: "#EE4E4E",
                                    color: "#fff",
                                    // width:"100px"
                                  }}
                                  onClick={() => {
                                    const id = Object.keys(colleges)[index];
                                    handleRemove(id);
                                  }}
                                >
                                  Remove
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <StyledTableCell colSpan={3}>
                              <h1 style={{ color: "red", textAlign: "center" }}>
                                No Data Found
                              </h1>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <h1>
                      {colleges && Object.keys(colleges).length > 0 ? (
                        Object.values(colleges).map((college, index) => (
                          <Box
                            key={index}
                            style={{
                              paddingBottom: "10px",
                              textTransform: "capitalize",
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            <p style={{ width: "20px" }}>{index + 1}</p>
                            <p>{college.name}</p>
                            <Button
                              type="button"
                              size="small"
                              style={{
                                backgroundColor: "#EE4E4E",
                                color: "#fff",
                                // width:"100px"
                              }}
                              onClick={() => {
                                const id = Object.keys(colleges)[index];
                                handleRemove(id);
                              }}
                            >
                              Remove
                            </Button>
                          </Box>
                        ))
                      ) : (
                        <Box
                          style={{
                            paddingBottom: "10px",
                            textTransform: "capitalize",
                            color: "#EE4E4E",
                            textAlign: "center",
                          }}
                        >
                          No Colleges Found
                        </Box>
                      )}
                    </h1> */}
                </Grid>
                <Grid item md={6}>
                  <Item>
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-name-label">
                        Reason
                      </InputLabel>
                      <CustomSelect
                        required
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={programmeData}
                        onChange={(event) => {
                          setProgrammeData(event.target.value);
                        }}
                        input={<OutlinedInput label="Reason" />}
                        MenuProps={MenuProps}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Default border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                          },
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <CustomMenuItem key={key} value={key}>
                              {value}
                            </CustomMenuItem>
                          )
                        )}
                      </CustomSelect>
                    </FormControl>
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-comment-input"
                    label="Comment"
                    name="comment"
                    type="text"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    rows={2}
                    multiline
                    fullWidth
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} spacing={1}>
                <Grid item md={1.2}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    style={btnstyle1}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={1.2}>
                  <Button
                    type="submit"
                    color="primary"
                      fullWidth
                    variant="contained"
                    style={btnstyle}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </ThemeProvider>
      </Paper>
    </section>
  );
}
