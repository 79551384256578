import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  Button,
} from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../apis/AxiosInstance";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#40679E",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "normal",
    padding: "8px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function StatusHistory(props) {
  let [record_id, setRecord_id] = React.useState(props.group_idx);

  let [statusHistory, setStatusHistory] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `call_status_history?id=${record_id}&page=${newPage}`
      );
      if (response.data.data !== null) {
        setStatusHistory(response.data.data);
        setTotalLength(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleAppointmentOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `call_status_history?id=${record_id}&page=${page}`
      );
      if (response.data.data !== null) {
        setStatusHistory(response.data.data);
        setTotalLength(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const totalPages = Math.ceil(totalLength / 10);

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  return (
    <div>
      <Tooltip title="Status History Details">
        <Button
          onClick={handleAppointmentOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
          style={{
            background: "#227B94",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            padding: "5px",
            fontSize: "12px",
          }}
        >
          View History
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h3"
            sx={{ fontSize: "25px", fontWeight: "bold" }}
          >
            Status History Details
          </Typography>

          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#40679E" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "150px",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px",
                    }}
                  >
                    Category Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px",
                    }}
                  >
                    Main Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px",
                    }}
                  >
                    Sub Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#40679E",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px",
                    }}
                  >
                    Author Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : statusHistory.length > 0 ? (
                  statusHistory.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          <Table>
                            {value.CallStatusHistory &&
                            value.CallStatusHistory.length > 0
                              ? value.CallStatusHistory.map(
                                  (history, index) => (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <StyledTableCell
                                        key={index}
                                        align="center"
                                        sx={{ color: "#000" }}
                                      >
                                        {history.date
                                          ? moment
                                              .utc(toIsoFormat(history.date))
                                              .local()
                                              .format("DD-MM-YYYY hh:mm A")
                                          : "NA"}
                                      </StyledTableCell>
                                    </TableRow>
                                  )
                                )
                              : "NA"}
                          </Table>
                        </StyledTableCell>

                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          <Table>
                            {value.CallStatusHistory &&
                            value.CallStatusHistory.length > 0
                              ? value.CallStatusHistory.map(
                                  (history, index) => (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <StyledTableCell
                                        key={index}
                                        align="center"
                                        sx={{ color: "#000" }}
                                      >
                                        {history.category_id?.name || "NA"}
                                      </StyledTableCell>
                                    </TableRow>
                                  )
                                )
                              : "NA"}
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          <Table>
                            {value.CallStatusHistory &&
                            value.CallStatusHistory.length > 0
                              ? value.CallStatusHistory.map(
                                  (history, index) => (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <StyledTableCell
                                        key={index}
                                        align="center"
                                        sx={{ color: "#000" }}
                                      >
                                        {history.call_status?.main_status ||
                                          "NA"}
                                      </StyledTableCell>
                                    </TableRow>
                                  )
                                )
                              : "NA"}
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          <Table>
                            {value.CallStatusHistory &&
                            value.CallStatusHistory.length > 0
                              ? value.CallStatusHistory.map(
                                  (history, index) => (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <StyledTableCell
                                        key={index}
                                        align="center"
                                        sx={{ color: "#000" }}
                                      >
                                        {history.call_status?.sub_status
                                          ?.sub_status || "NA"}
                                      </StyledTableCell>
                                    </TableRow>
                                  )
                                )
                              : "NA"}
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          <Table>
                            {value.CallStatusHistory &&
                            value.CallStatusHistory.length > 0
                              ? value.CallStatusHistory.map(
                                  (history, index) => (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <StyledTableCell
                                        key={index}
                                        align="center"
                                        sx={{ color: "#000" }}
                                      >
                                        {history.call_status?.author?.name ||
                                          "NA"}
                                      </StyledTableCell>
                                    </TableRow>
                                  )
                                )
                              : "NA"}
                          </Table>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <h3 style={{ color: "red" }}>No Data Found</h3>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Modal>
    </div>
  );
}
