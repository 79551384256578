// import { useState } from "react";
// import { toast } from "react-toastify";
// import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import { useLocation, useNavigate } from "react-router-dom";
// import CancelIcon from "@mui/icons-material/Cancel";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import AxiosInstance from "../../../apis/AxiosInstance";

// const EditSpoc = () => {
//   let Navigate = useNavigate();
//   let { state } = useLocation();

//   console.log("state",state)

//   const [full_name, setfull_name] = useState(state.name);
//   const [mobiles, setmobiles] = useState([...state.Mobile]);
//   const [landlines, setLandlines] = useState([...state.Landline]);
//   const [emails, setEmails] = useState([...state.Email]);
//   const [isValid, setIsValid] = useState(false);

//   const preventMinus = (e) => {
//     if (isNaN(e.key)) {
//       e.preventDefault();
//     }
//   };

//   const handleMobChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...mobiles];
//     list[index] = value;
//     setmobiles(list);
//   };

//   const handleRemoveMob = (index) => {
//     const list = [...mobiles];
//     list.splice(index, 1);
//     setmobiles(list);
//   };

//   const handleAddMob = () => {
//     setmobiles([...mobiles, ""]);
//   };

//   const handleLandlineChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...landlines];
//     list[index] = value;
//     setLandlines(list);
//   };

//   const handleRemoveLandline = (index) => {
//     const list = [...landlines];
//     list.splice(index, 1);
//     setLandlines(list);
//   };

//   const handleAddLandline = () => {
//     setLandlines([...landlines, ""]);
//   };

//   const handleEmailChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...emails];
//     list[index] = value;
//     setEmails(list);
//   };

//   const handleRemoveEmail = (index) => {
//     const list = [...emails];
//     list.splice(index, 1);
//     setEmails(list);
//   };

//   const handleAddEmail = () => {
//     setEmails([...emails, ""]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     let payload = { id: [state.id] };

//     const newEmails = emails.filter((email) => !state.Email.includes(email));
//     const newMobiles = mobiles.filter(
//       (mobile) => !state.Mobile.includes(mobile)
//     );
//     const newLandlines = landlines.filter(
//       (landline) => !state.Landline.includes(landline)
//     );

//     if (newEmails.length > 0) {
//       payload.email = newEmails[0];
//     }
//     if (newMobiles.length > 0) {
//       payload.mobile = newMobiles[0];
//     }
//     if (newLandlines.length > 0) {
//       payload.landline = newLandlines[0];
//     }

//     if (!payload.email && !payload.mobile && !payload.landline) {
//       toast.error("No changes detected.");
//       return;
//     }

//     try {
//       await AxiosInstance.patch(`/college_spoc`, payload)
//         .then((data) => {
//           toast.success("Successfully User updated");
//           setTimeout(() => {
//             Navigate("/college_spoc_list");
//           }, 1500);
//         })
//         .catch((error) => {
//           if (error.response.data) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error("Something Went wrong");
//           }
//         });
//     } catch (error) {
//       if (error.response) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went Wrong Please Try Again");
//       }
//     }
//   };

//   const paperStyle = {
//     padding: 20,
//     gap: 0,
//     width: "600px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//   };

//   const avatarStyle = { backgroundColor: "#06113C" };
//   const btnstyle = {
//     margin: "8px 0",
//     backgroundColor: "#06113C",
//     color: "#fff",
//     border: "1px solid #06113C",
//   };
//   const btnstyle1 = {
//     margin: "8px 0",
//     backgroundColor: "crimson",
//     color: "#fff",
//     border: "1px solid crimson",
//   };
//   const gridContainer = {
//     margin: "0 auto",
//     gap: 20,
//   };

//   const handleCancelClick = () => {
//     window.history.back();
//   };

//   return (
//     <Grid container style={{ marginTop: "5%" }}>
//       <Grid xs={12} item style={gridContainer} align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>Edit Spoc</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid item container sm={12} spacing={2}>
//               <Grid item sm={12}>
//                 <TextField
//                   color="warning"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   fullWidth
//                   disabled
//                   name="full_f_name"
//                   label="Fullname"
//                   value={full_name}
//                   onChange={(e) => setfull_name(e.target.value)}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 {mobiles.map((item, index) => (
//                   <div style={{ position: "relative" }} key={index + 1}>
//                     <Grid item style={{ paddingBottom: "10px" }}>
//                       <TextField
//                         fullWidth
//                         type="tel"
//                         label="Mobile"
//                         variant="outlined"
//                         onKeyPress={preventMinus}
//                         inputProps={{
//                           minLength: 10,
//                           maxLength: 10,
//                         }}
//                         name="mobile"
//                         required
//                         disabled={index < state.Mobile.length} // Disable only auto-populated data
//                         value={item}
//                         onChange={(e) => handleMobChange(e, index)}
//                       />
//                     </Grid>
//                     <div style={{ width: "min-content" }}>
//                       {mobiles.length !== 1 && index >= state.Mobile.length && (
//                         <span
//                           title="Delete Mobile Number"
//                           //   style={{ left: "546px",marginTop:"-10%",backgroundColor:"red"}}
//                           type="button"
//                           onClick={() => handleRemoveMob(index)}
//                         >
//                           <CancelIcon color="error" />
//                         </span>
//                       )}
//                       {mobiles.length - 1 === index && (
//                         <span
//                           title="Add More Mobile"
//                           type="button"
//                           style={
//                             mobiles.length < 5
//                               ? {
//                                   display: "inline-block",
//                                   position: "absolute",
//                                   left: "546px",
//                                   marginTop: "-15px",
//                                 }
//                               : { display: "none" }
//                           }
//                           onClick={handleAddMob}
//                         >
//                           <AddCircleOutlineIcon color="success" />
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </Grid>
//               <Grid item sm={12}>
//                 {landlines.map((item, index) => (
//                   <div style={{ position: "relative" }} key={index + 1}>
//                     <Grid item style={{ paddingBottom: "10px" }}>
//                       <TextField
//                         fullWidth
//                         type="tel"
//                         label="Landline"
//                         variant="outlined"
//                         onKeyPress={preventMinus}
//                         inputProps={{
//                           minLength: 10,
//                           maxLength: 10,
//                         }}
//                         name="Landline"
//                         required
//                         disabled={index < state.Landline.length} // Disable only auto-populated data
//                         value={item}
//                         onChange={(e) => handleLandlineChange(e, index)}
//                       />
//                     </Grid>
//                     <div style={{ width: "min-content" }}>
//                       {landlines.length !== 1 && index >= state.Landline.length && (
//                         <span
//                           title="Delete Landline Number"
//                           //   style={{ left: "546px",marginTop:"-10%",backgroundColor:"red"}}
//                           type="button"
//                           onClick={() => handleRemoveLandline(index)}
//                         >
//                           <CancelIcon color="error" />
//                         </span>
//                       )}
//                       {landlines.length - 1 === index && (
//                         <span
//                           title="Add More Landline"
//                           type="button"
//                           style={
//                             mobiles.length < 5
//                               ? {
//                                   display: "inline-block",
//                                   position: "absolute",
//                                   left: "546px",
//                                   marginTop: "-15px",
//                                 }
//                               : { display: "none" }
//                           }
//                           onClick={handleAddLandline}
//                         >
//                           <AddCircleOutlineIcon color="success" />
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </Grid>
//               <Grid item sm={12}>
//                 {emails.map((item, index) => (
//                   <div style={{ position: "relative" }} key={index + 1}>
//                     <Grid item style={{ paddingBottom: "10px" }}>
//                       <TextField
//                         type="email"
//                         label="Email"
//                         variant="outlined"
//                         fullWidth
//                         name="email"
//                         required
//                         disabled={index < state.Email.length} // Disable only auto-populated data
//                         value={item}
//                         onChange={(e) => {
//                           handleEmailChange(e, index);
//                           const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//                           return setIsValid(regexPattern.test(e.target.value));
//                         }}
//                       />
//                     </Grid>
//                     <div style={{ width: "min-content" }}>
//                       {emails.length !== 1 && index >= state.Email.length && (
//                         <span
//                           title="Delete Email"
//                           type="button"
//                           onClick={() => handleRemoveEmail(index)}
//                         >
//                           <CancelIcon color="error" />
//                         </span>
//                       )}
//                       {emails.length - 1 === index && (
//                         <span
//                           title="Add More Email"
//                           type="button"
//                           style={
//                             emails.length < 5
//                               ? {
//                                   display: "inline-block",
//                                   position: "absolute",
//                                   left: "546px",
//                                   marginTop: "-15px",
//                                 }
//                               : { display: "none" }
//                           }
//                           onClick={handleAddEmail}
//                         >
//                           <AddCircleOutlineIcon color="success" />
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </Grid>
//               <Grid item container sm={12} spacing={4}>
//                 <Grid item sm={3}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle1}
//                     fullWidth
//                     onClick={handleCancelClick}
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//                 <Grid item sm={3}>
//                   <Button
//                     type="submit"
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle}
//                     fullWidth
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default EditSpoc;


// import { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import { useLocation, useNavigate } from "react-router-dom";
// import CancelIcon from "@mui/icons-material/Cancel";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import AxiosInstance from "../../../apis/AxiosInstance";

// const EditSpoc = () => {
//   let Navigate = useNavigate();
//   let { state } = useLocation();

//   const [full_name, setfull_name] = useState(state.name);
//   const [mobiles, setmobiles] = useState([...state.Mobile]);
//   const [landlines, setLandlines] = useState([...state.Landline]);
//   const [emails, setEmails] = useState([...state.Email]);
//   const [isValid, setIsValid] = useState(false);

//   // Ensure there's at least one input for landline
//   useEffect(() => {
//     if (landlines.length === 0) {
//       setLandlines([""]);
//     }
//   }, [landlines]);

//   const preventMinus = (e) => {
//     if (isNaN(e.key)) {
//       e.preventDefault();
//     }
//   };

//   const handleMobChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...mobiles];
//     list[index] = value;
//     setmobiles(list);
//   };

//   const handleRemoveMob = (index) => {
//     const list = [...mobiles];
//     list.splice(index, 1);
//     setmobiles(list);
//   };

//   const handleAddMob = () => {
//     setmobiles([...mobiles, ""]);
//   };

//   const handleLandlineChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...landlines];
//     list[index] = value;
//     setLandlines(list);
//   };

//   const handleRemoveLandline = (index) => {
//     const list = [...landlines];
//     list.splice(index, 1);
//     setLandlines(list);
//   };

//   const handleAddLandline = () => {
//     setLandlines([...landlines, ""]);
//   };

//   const handleEmailChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...emails];
//     list[index] = value;
//     setEmails(list);
//   };

//   const handleRemoveEmail = (index) => {
//     const list = [...emails];
//     list.splice(index, 1);
//     setEmails(list);
//   };

//   const handleAddEmail = () => {
//     setEmails([...emails, ""]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     let payload = { id: [state.id] };

//     const newEmails = emails.filter((email) => !state.Email.includes(email));
//     const newMobiles = mobiles.filter(
//       (mobile) => !state.Mobile.includes(mobile)
//     );
//     const newLandlines = landlines.filter(
//       (landline) => !state.Landline.includes(landline)
//     );

//     if (newEmails.length > 0) {
//       payload.email = newEmails[0];
//     }
//     if (newMobiles.length > 0) {
//       payload.mobile = newMobiles[0];
//     }
//     if (newLandlines.length > 0) {
//       payload.landline = newLandlines[0];
//     }

//     if (!payload.email && !payload.mobile && !payload.landline) {
//       toast.error("No changes detected.");
//       return;
//     }

//     try {
//       await AxiosInstance.patch(`/college_spoc`, payload)
//         .then((data) => {
//           toast.success("Successfully User updated");
//           setTimeout(() => {
//             Navigate("/college_spoc_list");
//           }, 1500);
//         })
//         .catch((error) => {
//           if (error.response.data) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error("Something Went wrong");
//           }
//         });
//     } catch (error) {
//       if (error.response) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went Wrong Please Try Again");
//       }
//     }
//   };

//   const paperStyle = {
//     padding: 20,
//     gap: 0,
//     width: "600px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//   };

//   const avatarStyle = { backgroundColor: "#06113C" };
//   const btnstyle = {
//     margin: "8px 0",
//     backgroundColor: "#06113C",
//     color: "#fff",
//     border: "1px solid #06113C",
//   };
//   const btnstyle1 = {
//     margin: "8px 0",
//     backgroundColor: "crimson",
//     color: "#fff",
//     border: "1px solid crimson",
//   };
//   const gridContainer = {
//     margin: "0 auto",
//     gap: 20,
//   };

//   const handleCancelClick = () => {
//     window.history.back();
//   };

//   return (
//     <Grid container style={{ marginTop: "5%" }}>
//       <Grid xs={12} item style={gridContainer} align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>Edit Spoc</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid item container sm={12} spacing={2}>
//               <Grid item sm={12}>
//                 <TextField
//                   color="warning"
//                   size="small"
//                   sx={{ minWidth: 210 }}
//                   fullWidth
//                   disabled
//                   name="full_f_name"
//                   label="Fullname"
//                   value={full_name}
//                   onChange={(e) => setfull_name(e.target.value)}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 {mobiles.map((item, index) => (
//                   <div style={{ position: "relative" }} key={index + 1}>
//                     <Grid item style={{ paddingBottom: "10px" }}>
//                       <TextField
//                         fullWidth
//                         type="tel"
//                         label="Mobile"
//                         variant="outlined"
//                         onKeyPress={preventMinus}
//                         inputProps={{
//                           minLength: 10,
//                           maxLength: 10,
//                         }}
//                         name="mobile"
//                         required
//                         disabled={index < state.Mobile.length} // Disable only auto-populated data
//                         value={item}
//                         onChange={(e) => handleMobChange(e, index)}
//                       />
//                     </Grid>
//                     <div style={{ width: "min-content" }}>
//                       {mobiles.length !== 1 && index >= state.Mobile.length && (
//                         <span
//                           title="Delete Mobile Number"
//                           type="button"
//                           onClick={() => handleRemoveMob(index)}
//                         >
//                           <CancelIcon color="error" />
//                         </span>
//                       )}
//                       {mobiles.length - 1 === index && (
//                         <span
//                           title="Add More Mobile"
//                           type="button"
//                           style={
//                             mobiles.length < 5
//                               ? {
//                                   display: "inline-block",
//                                   position: "absolute",
//                                   left: "546px",
//                                   marginTop: "-15px",
//                                 }
//                               : { display: "none" }
//                           }
//                           onClick={handleAddMob}
//                         >
//                           <AddCircleOutlineIcon color="success" />
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </Grid>
//               <Grid item sm={12}>
//                 {landlines.map((item, index) => (
//                   <div style={{ position: "relative" }} key={index + 1}>
//                     <Grid item style={{ paddingBottom: "10px" }}>
//                       <TextField
//                         fullWidth
//                         type="tel"
//                         label="Landline"
//                         variant="outlined"
//                         onKeyPress={preventMinus}
//                         inputProps={{
//                           minLength: 10,
//                           maxLength: 10,
//                         }}
//                         name="Landline"                       
//                         disabled={index < state.Landline.length} // Disable only auto-populated data
//                         value={item}
//                         onChange={(e) => handleLandlineChange(e, index)}
//                       />
//                     </Grid>
//                     <div style={{ width: "min-content" }}>
//                       {landlines.length !== 1 && index >= state.Landline.length && (
//                         <span
//                           title="Delete Landline Number"
//                           type="button"
//                           onClick={() => handleRemoveLandline(index)}
//                         >
//                           <CancelIcon color="error" />
//                         </span>
//                       )}
//                       {landlines.length - 1 === index && (
//                         <span
//                           title="Add More Landline"
//                           type="button"
//                           style={
//                             mobiles.length < 5
//                               ? {
//                                   display: "inline-block",
//                                   position: "absolute",
//                                   left: "546px",
//                                   marginTop: "-15px",
//                                 }
//                               : { display: "none" }
//                           }
//                           onClick={handleAddLandline}
//                         >
//                           <AddCircleOutlineIcon color="success" />
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </Grid>
//               <Grid item sm={12}>
//                 {emails.map((item, index) => (
//                   <div style={{ position: "relative" }} key={index + 1}>
//                     <Grid item style={{ paddingBottom: "10px" }}>
//                       <TextField
//                         type="email"
//                         label="Email"
//                         variant="outlined"
//                         fullWidth
//                         name="email"
//                         required
//                         disabled={index < state.Email.length} // Disable only auto-populated data
//                         value={item}
//                         onChange={(e) => {
//                           handleEmailChange(e, index);
//                           const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//                           return setIsValid(regexPattern.test(e.target.value));
//                         }}
//                       />
//                     </Grid>
//                     <div style={{ width: "min-content" }}>
//                       {emails.length !== 1 && index >= state.Email.length && (
//                         <span
//                           title="Delete Email"
//                           type="button"
//                           onClick={() => handleRemoveEmail(index)}
//                         >
//                           <CancelIcon color="error" />
//                         </span>
//                       )}
//                       {emails.length - 1 === index && (
//                         <span
//                           title="Add More Email"
//                           type="button"
//                           style={
//                             emails.length < 5
//                               ? {
//                                   display: "inline-block",
//                                   position: "absolute",
//                                   left: "546px",
//                                   marginTop: "-15px",
//                                 }
//                               : { display: "none" }
//                           }
//                           onClick={handleAddEmail}
//                         >
//                           <AddCircleOutlineIcon color="success" />
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 ))}
//               </Grid>
//               <Grid item container sm={12} spacing={4}>
//                 <Grid item sm={3}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle1}
//                     fullWidth
//                     onClick={handleCancelClick}
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//                 <Grid item sm={3}>
//                   <Button
//                     type="submit"
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle}
//                     fullWidth
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default EditSpoc;



//////////////////////////////////////

import { useState } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Checkbox,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "@emotion/styled";
import AxiosInstance from "../../../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({}));

const EditSpoc = () => {
  let Navigate = useNavigate();
  let { state } = useLocation();

  const [full_name, setfull_name] = useState(state.name);
  const [isValid, setIsValid] = useState(false);

  const defaultItem = { number: "", is_personal: false };
  const defaultMobileItem = { number: "", is_personal: false };
  const defaultEmailItem = { address: "", is_personal: false };

  let [landlines, setLandlines] = useState(
    state.Landline?.length
      ? state.Landline.map((landline) => ({
          number: landline.landline,
          is_personal: landline.is_personal,
          isAutoPopulated: true,
        }))
      : [defaultItem]
  );

  let [mobiles, setmobiles] = useState(
    state.Mobile?.length
      ? state.Mobile.map((mobile) => ({
          number: mobile.mobile,
          is_personal: mobile.is_personal,
          isAutoPopulated: true,
        }))
      : [defaultMobileItem]
  );

  const [emails, setEmails] = useState(
    state.Email?.length
      ? state.Email.map((email) => ({
          address: email.email,
          is_personal: email.is_personal,
          isAutoPopulated: true,
        }))
      : [defaultEmailItem]
  );

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  const handleMobChange = (val, index) => {
    let { value } = val.target;
    let list = [...mobiles];
    list[index].number = value;
    setmobiles(list);
  };

  const handleMobilePersonal = (index, event) => {
    let list = [...mobiles];
    list[index].is_personal = event.target.checked;
    setmobiles(list);
  };

  const handleRemoveMob = (index) => {
    const list = [...mobiles];
    list.splice(index, 1);
    setmobiles(list);
  };

  const handleAddMob = () => {
    setmobiles([...mobiles, defaultMobileItem]);
  };

  const handleLandlineChange = (val, index) => {
    let { value } = val.target;
    let list = [...landlines];
    list[index].number = value;
    setLandlines(list);
  };

  const handleLandlinePersonal = (index, event) => {
    let list = [...landlines];
    list[index].is_personal = event.target.checked;
    setLandlines(list);
  };

  const handleRemoveLandline = (index) => {
    const list = [...landlines];
    list.splice(index, 1);
    setLandlines(list);
  };

  const handleAddLandline = () => {
    setLandlines([...landlines, defaultItem]);
  };

  const handleEmailChange = (val, index) => {
    let { value } = val.target;
    let list = [...emails];
    list[index].address = value;
    setEmails(list);
  };

  const handleEmailPersonal = (index, event) => {
    let list = [...emails];
    list[index].is_personal = event.target.checked;
    setEmails(list);
  };

  const handleRemoveEmail = (index) => {
    const list = [...emails];
    list.splice(index, 1);
    setEmails(list);
  };

  const handleAddEmail = () => {
    setEmails([...emails, defaultEmailItem]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let payload = {};
  
    // Check if full name is edited
    const isFullNameEdited = full_name !== state.name;
  
    // Extract edited data for other fields
    const editedMobiles = mobiles.filter(mobile => 
      mobile.number !== '' && 
      mobile.number !== state.Mobile?.find(m => m.mobile === mobile.number)?.mobile
    );
  
    const editedLandlines = landlines.filter(landline => 
      landline.number !== '' && 
      landline.number !== state.Landline?.find(l => l.landline === landline.number)?.landline
    );
  
    const editedEmails = emails.filter(email => 
      email.address !== '' && 
      email.address !== state.Email?.find(e => e.email === email.address)?.email
    );
  
    // If nothing is edited, show a warning message and return early
    if (!isFullNameEdited && editedMobiles.length === 0 && editedLandlines.length === 0 && editedEmails.length === 0) {
      toast.warning("Nothing to update");
      return;
    }
  
    // If the full name is edited, include the name field
    if (isFullNameEdited) {
      payload = { name: full_name };
    }
  
    // Build the payload for other fields
    const otherPayload = {
      id: [state.id],
      ...editedEmails.reduce((acc, email) => ({
        ...acc,
        [`email`]: email.address,
        [`is_email_personal`]: email.is_personal,
      }), {}),
      ...editedMobiles.reduce((acc, mobile) => ({
        ...acc,
        [`mobile`]: mobile.number,
        [`is_mobile_personal`]: mobile.is_personal,
      }), {}),
      ...editedLandlines.reduce((acc, landline) => ({
        ...acc,
        [`landline`]: landline.number,
        [`is_landline_personal`]: landline.is_personal,
      }), {}),
    };
  
    // Merge payloads
    payload = { ...payload, ...otherPayload };
  
    try {
      await AxiosInstance.patch(`/college_spoc`, payload)
        .then((data) => {
          toast.success("Successfully updated");
          setTimeout(() => {
            Navigate("/college_spoc_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went Wrong Please Try Again");
      }
    }
  };
  

  const paperStyle = {
    padding: 10,
    gap: 0,
    width: "98%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "2px solid #5F6F65",
  };
  const avatarStyle = { backgroundColor: "#06113C" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#06113C",
    color: "#fff",
    border: "1px solid #06113C",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const inputRowStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  };

  const cardStyle = {
    margin: "10px 0",
    padding: "10px",
    border: "1px solid gray",
    borderRadius: "8px",
  };

  return (
    <Grid container style={{ marginTop: "5%" }}>
      <Grid xs={12} item style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Spoc</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container spacing={1}>
              <Grid item xs={12} lg={4} md={4}>
                <TextField
                  color="warning"
                  size="small"
                  fullWidth
                  name="full_name"
                  label="Fullname"
                  value={full_name}
                  onChange={(e) => setfull_name(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)", 
                      },
                    },
                  }}
                />
              </Grid>

              <Grid container item xs={12} spacing={1}>
                {/* Mobile Numbers */}
                <Grid item xs={12} lg={4} md={4}>
                  <Card style={cardStyle}>
                    <CardHeader title="Mobile Numbers"/>
                    <CardContent>
                      {mobiles.map((item, index) => (
                        <div style={inputRowStyle} key={index}>
                          <Grid item xs={12} lg={4} md={4}>
                            <Checkbox
                              name="is_mobile_personal"
                              fullWidth
                              checked={item.is_personal}
                              onChange={(e) =>
                                handleMobilePersonal(index, e)
                              }
                              id={`is_mobile_personal_${index}`}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              disabled={item.isAutoPopulated}
                            />
                            <label
                              htmlFor={`is_mobile_personal_${index}`}
                              style={{ fontSize: "16px" }}
                            >
                              Is Personal
                            </label>
                          </Grid>
                          <Grid item xs={12} lg={5} md={5}>
                            <TextField
                              fullWidth
                              type="tel"
                              label="Mobile"
                              variant="outlined"
                              onKeyPress={preventMinus}
                              inputProps={{
                                minLength: 10,
                                maxLength: 10,
                              }}
                              name="mobile"
                              value={item.number}
                              disabled={item.isAutoPopulated}
                              onChange={(e) => handleMobChange(e, index)}
                              style={{ marginRight: "8px" }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)", 
                                  },
                                },
                              }}
                            />
                          </Grid>
                         
                          <Grid item xs={12} lg={1} md={1}>
                            {index === mobiles.length - 1 && (
                              <AddCircleOutlineIcon
                                fontSize="medium"
                                sx={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={handleAddMob}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} lg={0.5} md={0.5}>
                            {/* {!item.isAutoPopulated && ( */}
                            {index !== 0 && !item.isAutoPopulated && (
                              <CancelIcon
                                fontSize="medium"
                                sx={{
                                  color: "crimson",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveMob(index)}
                              />
                            )}
                          </Grid>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>

              

                {/* Email Addresses */}
                <Grid item xs={12} lg={4} md={4}>
                  <Card style={cardStyle}>
                    <CardHeader title="Email Address" />
                    <CardContent>
                      {emails.map((item, index) => (
                        <div style={inputRowStyle} key={index}>
                          <Grid item xs={12} lg={4} md={4}>
                            <Checkbox
                              name="is_email_personal"
                              fullWidth
                              checked={item.is_personal}
                              onChange={(e) => handleEmailPersonal(index, e)}
                              id={`is_email_personal_${index}`}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              disabled={item.isAutoPopulated}
                            />
                            <label
                              htmlFor={`is_email_personal_${index}`}
                              style={{ fontSize: "16px" }}
                            >
                              Is Personal
                            </label>
                          </Grid>
                          <Grid item xs={12} lg={5} md={5}>
                            <TextField
                              fullWidth
                              type="email"
                              label="Email"
                              variant="outlined"
                              name="email"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)", 
                                  },
                                },
                              }}
                              value={item.address}
                              disabled={item.isAutoPopulated}
                              onChange={(e) => handleEmailChange(e, index)}
                              style={{ marginRight: "8px" }}
                            />
                          </Grid>
                          
                          <Grid item xs={12} lg={1} md={1}>
                            {index === emails.length - 1 && (
                              <AddCircleOutlineIcon
                                fontSize="medium"
                                sx={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={handleAddEmail}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} lg={0.5} md={0.5}>
                            {/* {!item.isAutoPopulated && ( */}
                            {index !== 0 && !item.isAutoPopulated && (
                              <CancelIcon
                                fontSize="medium"
                                sx={{
                                  color: "crimson",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveEmail(index)}
                              />
                            )}
                          </Grid>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                  {/* Landline Numbers */}
                  <Grid item xs={12} lg={4} md={4}>
                  <Card style={cardStyle}>
                    <CardHeader title="Landline Numbers" />
                    <CardContent>
                      {landlines.map((item, index) => (
                        <div style={inputRowStyle} key={index}>
                          <Grid item xs={12} lg={4} md={4}>
                            <Checkbox
                              name="is_landline_personal"
                              fullWidth
                              checked={item.is_personal}
                              onChange={(e) =>
                                handleLandlinePersonal(index, e)
                              }
                              id={`is_landline_personal_${index}`}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              disabled={item.isAutoPopulated}
                            />
                            <label
                              htmlFor={`is_landline_personal_${index}`}
                              style={{ fontSize: "16px" }}
                            >
                              Is Personal
                            </label>
                          </Grid>
                          <Grid item xs={12} lg={5} md={5}>
                            <TextField
                              fullWidth
                              type="tel"
                              label="Landline"
                              variant="outlined"
                              onKeyPress={preventMinus}
                              inputProps={{
                                minLength: 8,
                                maxLength: 10,
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderColor: "rgba(0, 0, 0, 10)",
                                  "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 5)", 
                                  },
                                },
                              }}
                              name="landline"
                              value={item.number}
                              disabled={item.isAutoPopulated}
                              onChange={(e) =>
                                handleLandlineChange(e, index)
                              }
                              style={{ marginRight: "8px" }}
                            />
                          </Grid>
                          
                          <Grid item xs={12} lg={1} md={1}>
                            {index === landlines.length - 1 && (
                              <AddCircleOutlineIcon
                                fontSize="medium"
                                sx={{
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                                onClick={handleAddLandline}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} lg={0.5} md={0.5}>
                          {index !== 0 && !item.isAutoPopulated && (
                              <CancelIcon
                                fontSize="medium"
                                sx={{
                                  color: "crimson",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveLandline(index)}
                              />
                            )}
                          </Grid>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ display: "flex", gap: "20px" }}
              >
                <Grid item xs={12} lg={1} md={1}>
                  <Button
                    type="button"
                    variant="contained"
                    style={btnstyle1}
                    onClick={handleCancelClick}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} lg={1} md={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditSpoc;









// import { useState } from "react";
// import { toast } from "react-toastify";
// import {
//   Avatar,
//   Button,
//   Grid,
//   Paper,
//   TextField,
//   Checkbox,
// } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import { useLocation, useNavigate } from "react-router-dom";
// import CancelIcon from "@mui/icons-material/Cancel";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// import styled from "@emotion/styled";
// import AxiosInstance from "../../../apis/AxiosInstance";

// const Item = styled(Paper)(({ theme }) => ({}));

// const EditSpoc = () => {
//   let Navigate = useNavigate();
//   let { state } = useLocation();

//   console.log("state", state);

//   // Initialize state with default values if state properties are empty or undefined
//   const [full_name, setfull_name] = useState("");
//   const [isValid, setIsValid] = useState(false);

//   const defaultItem = { number: "", is_personal: false };
//   const defaultEmailItem = { address: "", is_personal: false };

//   let [landlines, setLandlines] = useState(
//     state.Landline?.length
//       ? state.Landline.map((landline) => ({ number: landline.landline, is_personal: landline.is_personal }))
//       : [defaultItem]
//   );

//   let [mobiles, setmobiles] = useState(
//     state.Mobile?.length
//       ? state.Mobile.map((mobile) => ({ number: mobile.mobile, is_personal: mobile.is_personal }))
//       : [defaultItem]
//   );

//   const [emails, setEmails] = useState(
//     state.Email?.length
//       ? state.Email.map((email) => ({ address: email.email, is_personal: email.is_personal }))
//       : [defaultEmailItem]
//   );

//   const preventMinus = (e) => {
//     if (isNaN(e.key)) {
//       e.preventDefault();
//     }
//   };

//   const handleMobChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...mobiles];
//     list[index].number = value;
//     setmobiles(list);
//   };

//   const handleMobilePersonal = (index, event) => {
//     let list = [...mobiles];
//     list[index].is_personal = event.target.checked;
//     setmobiles(list);
//   };

//   const handleRemoveMob = (index) => {
//     const list = [...mobiles];
//     list.splice(index, 1);
//     setmobiles(list);
//   };

//   const handleAddMob = () => {
//     setmobiles([...mobiles, defaultItem]);
//   };

//   const handleLandlineChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...landlines];
//     list[index].number = value;
//     setLandlines(list);
//   };

//   const handleLandlinePersonal = (index, event) => {
//     let list = [...landlines];
//     list[index].is_personal = event.target.checked;
//     setLandlines(list);
//   };

//   const handleRemoveLandline = (index) => {
//     const list = [...landlines];
//     list.splice(index, 1);
//     setLandlines(list);
//   };

//   const handleAddLandline = () => {
//     setLandlines([...landlines, defaultItem]);
//   };

//   const handleEmailChange = (val, index) => {
//     let { value } = val.target;
//     let list = [...emails];
//     list[index].address = value;
//     setEmails(list);
//   };

//   const handleEmailPersonal = (index, event) => {
//     let list = [...emails];
//     list[index].is_personal = event.target.checked;
//     setEmails(list);
//   };

//   const handleRemoveEmail = (index) => {
//     const list = [...emails];
//     list.splice(index, 1);
//     setEmails(list);
//   };

//   const handleAddEmail = () => {
//     setEmails([...emails, defaultEmailItem]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const config = {
//       headers: { "content-type": "multipart/form-data" },
//     };
//     let formData = new FormData();
//     formData.append("id", state.id);

//     emails.forEach((val) => {
//       formData.append("email", val.address);
//       formData.append("email_archive", val.is_personal);
//     });

//     mobiles.forEach((value) => {
//       formData.append("mobile", value.number);
//       formData.append("country_code", `+91`);
//       formData.append("mobile_archive", value.is_personal);
//     });

//     try {
//       await AxiosInstance.patch(`/archive_college_spoc`, formData, config)
//         .then((data) => {
//           toast.success("Successfully updated");
//           setTimeout(() => {
//             Navigate("/college_spoc_list");
//           }, 1500);
//         })
//         .catch((error) => {
//           if (error.response.data) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error("Something Went wrong");
//           }
//         });
//     } catch (error) {
//       if (error.response) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went Wrong Please Try Again");
//       }
//     }
//   };

//   const paperStyle = {
//     padding: 10,
//     gap: 0,
//     width: "98%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//     border:"2px solid #5F6F65"
//   };
//   const avatarStyle = { backgroundColor: "#06113C" };
//   const btnstyle = {
//     margin: "8px 0",
//     backgroundColor: "#06113C",
//     color: "#fff",
//     border: "1px solid #06113C",
//   };
//   const btnstyle1 = {
//     margin: "8px 0",
//     backgroundColor: "crimson",
//     color: "#fff",
//     border: "1px solid crimson",
//   };
//   const gridContainer = {
//     margin: "0 auto",
//     gap: 20,
//   };

//   const handleCancelClick = () => {
//     window.history.back();
//   };

//   const inputRowStyle = {
//     display: "flex",
//     alignItems: "center",
//     marginBottom: "10px",
//   };

//   return (
//     <Grid container style={{ marginTop: "5%" }}>
//       <Grid xs={12} item style={gridContainer} align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>Edit Spoc</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid item container spacing={1}>
//               <Grid item xs={12} lg={4} md={4}>
//                 <TextField
//                   color="warning"
//                   size="small"
//                   fullWidth
//                   name="full_f_name"
//                   label="Fullname"
//                   disabled
//                   value={state.name}
//                   onChange={(e) => setfull_name(e.target.value)}
//                 />
//               </Grid>

//               <Grid container spacing={1} style={{ marginTop: "0.5%" }}>
//               <Grid item xs={12} lg={4} md={4}>
//                 {mobiles.map((item, index) => {
//                   return (
//                     <div style={inputRowStyle} key={index}>
//                       <Grid item xs={12} lg={4.5} md={4.5}>
//                         <Checkbox
//                           name="is_mobile_personal"
//                           fullWidth
//                           checked={item.is_personal}
//                           onChange={(e) => handleMobilePersonal(index, e)}
//                           id={`is_mobile_personal_${index}`}
//                           sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
//                         />
//                         <label
//                           htmlFor={`is_mobile_personal_${index}`}
//                           style={{ fontSize: "16px" }}
//                         >
//                           Is Personal
//                         </label>
//                       </Grid>
//                       <Grid item xs={12} lg={6} md={6}>
//                         <TextField
//                           fullWidth
//                           type="tel"
//                           label="Mobile"
//                           variant="outlined"
//                           onKeyPress={preventMinus}
//                           inputProps={{
//                             minLength: 10,
//                             maxLength: 10,
//                           }}
//                           name="mobile"
//                           required
//                           disabled={index === 0 ? true : false}
//                           value={item.number}
//                           onChange={(e) => handleMobChange(e, index)}
//                           style={{ marginRight: "10px" }}
//                         />
//                       </Grid>

//                       <div style={{ marginLeft: "10px" }}>
//                         {mobiles.length !== 1 && (
//                           <span
//                             title="Delete Mobile Number"
//                             type="button"
//                             onClick={() => handleRemoveMob(index)}
//                           >
//                             <CancelIcon color="error" />
//                           </span>
//                         )}
//                         {mobiles.length - 1 === index && (
//                           <span
//                             title="Add More Mobile"
//                             type="button"
//                             style={
//                               mobiles.length < 3
//                                 ? { display: "inline-block" }
//                                 : { display: "none" }
//                             }
//                             onClick={handleAddMob}
//                           >
//                             <AddCircleOutlineIcon color="success" />
//                           </span>
//                         )}
//                       </div>
//                     </div>
//                   );
//                 })}
//               </Grid>

//               <Grid item xs={12} lg={4} md={4}>
//                 {emails.map((item, index) => {
//                   return (
//                     <div style={inputRowStyle} key={index}>
//                       <Grid item xs={12} lg={4} md={4}>
//                         <Checkbox
//                           name="is_email_personal"
//                           fullWidth
//                           checked={item.is_personal}
//                           onChange={(e) => handleEmailPersonal(index, e)}
//                           id={`is_email_personal_${index}`}
//                           sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
//                         />
//                         <label
//                           htmlFor={`is_email_personal_${index}`}
//                           style={{ fontSize: "16px" }}
//                         >
//                           Is Personal
//                         </label>
//                       </Grid>
//                       <Grid item xs={12} lg={6} md={6}>
//                         <TextField
//                           fullWidth
//                           type="email"
//                           label="Email"
//                           variant="outlined"
//                           name="email"
//                           required
//                           disabled={index === 0 ? true : false}
//                           value={item.address}
//                           onChange={(e) => {
//                             handleEmailChange(e, index);
//                             const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//                             return setIsValid(regexPattern.test(e.target.value));
//                           }}
//                           style={{ marginRight: "10px" }}
//                         />
//                       </Grid>

//                       <div style={{ marginLeft: "10px" }}>
//                         {emails.length !== 1 && (
//                           <span
//                             title="Delete Email"
//                             type="button"
//                             onClick={() => handleRemoveEmail(index)}
//                           >
//                             <CancelIcon color="error" />
//                           </span>
//                         )}
//                         {emails.length - 1 === index && (
//                           <span
//                             title="Add More Email"
//                             type="button"
//                             style={
//                               emails.length < 3
//                                 ? { display: "inline-block" }
//                                 : { display: "none" }
//                             }
//                             onClick={handleAddEmail}
//                           >
//                             <AddCircleOutlineIcon color="success" />
//                           </span>
//                         )}
//                       </div>
//                     </div>
//                   );
//                 })}
//               </Grid>
//               <Grid item xs={12} lg={4} md={4}>
//                 {landlines.map((item, index) => {
//                   return (
//                     <div style={inputRowStyle} key={index}>
//                       <Grid item xs={12} lg={4.5} md={4.5}>
//                         <Checkbox
//                           name="is_landline_personal"
//                           fullWidth
//                           checked={item.is_personal}
//                           onChange={(e) => handleLandlinePersonal(index, e)}
//                           id={`is_landline_personal_${index}`}
//                           sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
//                         />
//                         <label
//                           htmlFor={`is_landline_personal_${index}`}
//                           style={{ fontSize: "16px" }}
//                         >
//                          Is Personal
//                         </label>
//                       </Grid>
//                       <Grid item xs={12} lg={6} md={6}>
//                         <TextField
//                           fullWidth
//                           type="tel"
//                           label="Landline"
//                           variant="outlined"
//                           onKeyPress={preventMinus}
//                           inputProps={{
//                             minLength: 10,
//                             maxLength: 10,
//                           }}
//                           name="landline"
//                           required
//                           disabled={index === 0 ? true : false}
//                           value={item.number}
//                           onChange={(e) => handleLandlineChange(e, index)}
//                           style={{ marginRight: "10px" }}
//                         />
//                       </Grid>

//                       <div style={{ marginLeft: "10px" }}>
//                         {landlines.length !== 1 && (
//                           <span
//                             title="Delete Landline Number"
//                             type="button"
//                             onClick={() => handleRemoveLandline(index)}
//                           >
//                             <CancelIcon color="error" />
//                           </span>
//                         )}
//                         {landlines.length - 1 === index && (
//                           <span
//                             title="Add More Landline"
//                             type="button"
//                             style={
//                               landlines.length < 3
//                                 ? { display: "inline-block" }
//                                 : { display: "none" }
//                             }
//                             onClick={handleAddLandline}
//                           >
//                             <AddCircleOutlineIcon color="success" />
//                           </span>
//                         )}
//                       </div>
//                     </div>
//                   );
//                 })}
//               </Grid>
//               </Grid>
//               <Grid item container sm={12} spacing={4}>
//                 <Grid item sm={2}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle1}
//                     fullWidth
//                     onClick={handleCancelClick}
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//                 <Grid item sm={2}>
//                   <Button
//                     type="submit"
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle}
//                     fullWidth
//                     disabled={!isValid} // Disable submit button if email is invalid
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default EditSpoc;

//

