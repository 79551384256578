import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import StudentJoinedList from "../Bd_Module/bd/appointmentList/StudentJoinedList";
import NotJoinedStudentList from "../Bd_Module/bd/appointmentList/NotJoinedStudentList";
import QuizRejectedList from "../Bd_Module/bd/appointmentList/QuizRejectedList";
import SameCollegeRegisterStudentList from "./SameCollegeRegisterStudentList";
import SameCollegeGDRegisteredStudentList from "./SameCollegeGDRegisteredStudentList";
import SameCollegeGDJoinedStudentList from "./SameCollegeGDJoinedStudentList";
import SameCollegeGDNotJoinedStudentList from "./SameCollegeGDNotJoinedStudentList";
import SameCollegeGDShortlistStudentList from "./SameCollegeGDShortlistStudentList";
import SameCollegeGDHoldStudentList from "./SameCollegeGDHoldStudentList";
import SameCollegeGDRejectStudentList from "./SameCollegeGDRejectStudentList";
import SameCollegeFinalShortlist from "./SameCollegeFinalShortlist";
import SameCollegeRejectlist from "./SameCollegeRejectlist";
import SameCollegeQuizAttendedList from "./SameCollegeQuizAttendedList";
import SameCollegeQuizNotAttendedList from "./SameCollegeQuizNotAttendedList";
import SameCollegeQuizShortlistList from "./SameCollegeQuizShortlistList";
import SameCollegeQuizNotSelectedList from "./SameCollegeQuizNotSelectedList";
import AxiosInstance from "../apis/AxiosInstance";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#124076",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function TpoAppointmentListModal(props) {
  let [appointment_id, setAppontment_id] = React.useState(props.group_idx);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalAppointment, setTotalAppointment] = useState(0);

  let [searchedAppointment, setSearchedAppointment] = useState([]);

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `appointments_for_tpo?id=${appointment_id}&page=${page}`
      );
      if (response.data.data !== null) {
        setSearchedAppointment(response.data.data);
        setTotalAppointment(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleAppointmentOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let collegeData = await AxiosInstance.get(
        `/appointments_for_tpo?id=${appointment_id}&page=${page}`
      );
      setTotalAppointment(collegeData.data.filter_count);
      let finalCollege = collegeData.data.data;
      setSearchedAppointment(finalCollege);
    } catch (error) {
      console.log(error);
    }
  };

  const totalPages = Math.ceil(totalAppointment / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <div>
      <Tooltip >
        <Button
          onClick={handleAppointmentOpen}
          sx={{
            "&:hover": { background: "#124076" },
          backgroundColor:"#124076",
          color:"#fff",
          border:"none",
          borderRadius:"5px"
          

          }}
        >
          Show Details
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Appointment Details
          </Typography>

          <TableContainer component={Paper} sx={{ maxHeight: 470 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead
                style={{
                  color: "#081f37",
                  fontWeight: "600",
                }}
              >
                <TableRow>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </StyledTableCell>             
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "300px !important",
                    }}
                  >
                    Registered Students
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "300px !important",
                    }}
                  >
                    Addressing Attended
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "300px !important",
                    }}
                  >
                    Addressing Not-Attended
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Quiz Attended
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Quiz Not-Attended
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Quiz Shortlist
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Quiz Not-Selected
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Quiz Rejected
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    GD Registered
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    GD Joined
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    GD Not-Joined
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    GD Shortlist
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    GD Rejected
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    GD Hold
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Final Shortlist
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Final Reject
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedAppointment && searchedAppointment.length > 0 ? (
                  searchedAppointment.map((val, ind) => (
                    <StyledTableRow key={ind + 1}>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        {calculateSlNo(ind)}
                      </StyledTableCell>                  
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {val.registered_count === null
                              ? 0
                              : val.registered_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeRegisterStudentList
                              appointment_idx={val.id}
                              level="5"
                            ></SameCollegeRegisterStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <p>
                            {val.joined_student_count === null
                              ? 0
                              : val.joined_student_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <StudentJoinedList
                              appointment_idx={val.id}
                              joinedStudentLevel="5"
                            ></StudentJoinedList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {val.not_joined_student_count === null
                              ? 0
                              : val.not_joined_student_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <NotJoinedStudentList
                              appointment_idx={val.id}
                              notJoinedStudentLevel="5"
                            ></NotJoinedStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <p>{val?.quiz_count?.quiz_attended_count || 0}</p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeQuizAttendedList
                              appointment_idx={val.id}
                              addressing_idx={val.addressing_id}
                            ></SameCollegeQuizAttendedList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <p>{val?.quiz_count?.quiz_not_attended_count || 0}</p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeQuizNotAttendedList
                              appointment_idx={val.id}
                              addressing_idx={val.addressing_id}
                            ></SameCollegeQuizNotAttendedList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <p>
                            {val?.quiz_count?.quiz_shortlisted_student_count ||
                              0}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            <SameCollegeQuizShortlistList
                              appointment_idx={val.id}
                              addressing_idx={val.addressing_id}
                            ></SameCollegeQuizShortlistList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <p>
                            {val?.quiz_count?.quiz_not_selected_student_count ||
                              0}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeQuizNotSelectedList
                              appointment_idx={val.id}
                              addressing_idx={val.addressing_id}
                            ></SameCollegeQuizNotSelectedList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <p>
                            {val?.quiz_count?.quiz_rejected_student_count || 0}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <QuizRejectedList
                              appointment_idx={val.id}
                              quizRejectedList="5"
                            ></QuizRejectedList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.gd_registered_count === null
                              ? 0
                              : val.gd_registered_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeGDRegisteredStudentList
                              appointment_idx={val.id}
                              gdRegistered="5"
                            ></SameCollegeGDRegisteredStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.gd_joined_count === null
                              ? 0
                              : val.gd_joined_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            <SameCollegeGDJoinedStudentList
                              appointment_idx={val.id}
                              gdJoined="5"
                            ></SameCollegeGDJoinedStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.gd_not_joined_count === null
                              ? 0
                              : val.gd_not_joined_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeGDNotJoinedStudentList
                              appointment_idx={val.id}
                              gdNotJoined="5"
                            ></SameCollegeGDNotJoinedStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.gd_shortlisted_count === null
                              ? 0
                              : val.gd_shortlisted_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeGDShortlistStudentList
                              appointment_idx={val.id}
                              gdShortlist="5"
                            ></SameCollegeGDShortlistStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.gd_rejected_count === null
                              ? 0
                              : val.gd_rejected_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeGDRejectStudentList
                              appointment_idx={val.id}
                            ></SameCollegeGDRejectStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.gd_hold_count === null ? 0 : val.gd_hold_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeGDHoldStudentList
                              appointment_idx={val.id}
                              gdHold="5"
                            ></SameCollegeGDHoldStudentList>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.final_shortlist_count === null
                              ? 0
                              : val.final_shortlist_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeFinalShortlist
                              appointment_idx={val.id}
                              finalShortlist="5"
                            ></SameCollegeFinalShortlist>
                          </p>
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            {" "}
                            {val.final_reject_count === null
                              ? 0
                              : val.final_reject_count}
                          </p>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              fontSize: "1px",
                            }}
                          >
                            {" "}
                            <SameCollegeRejectlist
                              appointment_idx={val.id}
                              finalReject="5"
                            ></SameCollegeRejectlist>
                          </p>
                        </p>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={10}>
                      <h1 style={{ color: "red", textAlign: "center" }}>
                        No Data Found
                      </h1>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Modal>
    </div>
  );
}
