import React, { useState } from "react";
import {
  Box,
  Autocomplete,
  Checkbox,
  Button,
  TextField,
  Grid,
  Container,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";
import { styled } from "@mui/material/styles";

//====================================

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditInterview() {
  const Navigate = useNavigate();
  let { state } = useLocation();

  const [start_date_time, setStart_date_time] = useState(
    moment.utc(state.start_date_time).local().format("YYYY-MM-DDTHH:mm:ss.SSS")
  );
  const [end_date_time, setEnd_date_time] = useState(
    moment.utc(state.end_date_time).local().format("YYYY-MM-DDTHH:mm:ss.SSS")
  );


  const [addInterviewerOptions, setInterviewerOptions] = useState([]);
  const [interviewer, setInerviewer] = useState([]);
  const [testInterviewer, setTestInterviewer] = useState([]);
  const [searchInterviewer, setSearchInterviewer] = useState("");


  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(state.is_offline ? 1 : 2);

  //   // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

   useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      const keys = Object.keys(state.interviewer);
      try {
        if (keys.length > 0) {
          let addFacultyData = await AxiosInstance.get(
            `/user_mini?role_ids=3&is_not_blocked=${true}&user_id=${keys
              .toString()
              .split(",")
              .join("&user_id=")}`
          );
          let finalAddFaculty = addFacultyData.data.data
            ? addFacultyData.data.data
            : [];
          setInerviewer(finalAddFaculty);
          setTestInterviewer(finalAddFaculty);
        }
      } catch (error) {
        console.log(error);
        if (keys.length > 0) {
          setInerviewer([]);
          setTestInterviewer([]);
        }
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchInterviewer !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&search_keyword=${searchInterviewer}&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setInterviewerOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=3&limit=10&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setInterviewerOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchInterviewer]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };

     //Remove Interviewers
    const newInterviewer =
    interviewer.length > 0 ? interviewer.map((e) => e.id.toString()) : [];
  const removedInteriewer =
    interviewer.length > 0 &&
    newInterviewer &&
    Object.keys(state.interviewer).length > 0
      ? Object.keys(state.interviewer).filter(
          (ex) => !newInterviewer.includes(ex)
        )
      : [];
    
  
    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    try {
      const interviewDateTime = moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
      const interviewEndDateTime = moment(end_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");

      formData.append("id", state.id);
      if (start_date_time !== "") {
        if (
          moment
            .utc(state.start_date_time)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS") !== start_date_time
        ) {
          formData.append("gd_start_date", interviewDateTime.split(" ")[0]);
          formData.append(
            "gd_start_time",
            interviewDateTime.split(" ").slice(1).join(" ")
          );
        }
      }
      if (end_date_time !== "") {
        if (
          moment
            .utc(state.end_date_time)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSS") !== end_date_time
        ) {
          formData.append("gd_end_date", interviewEndDateTime.split(" ")[0]);
          formData.append(
            "gd_end_time",
            interviewEndDateTime.split(" ").slice(1).join(" ")
          );
        }
      }
      let interviewStatus = state.is_offline ? 1 : 2;
      if (examTypeList != interviewStatus) {
        formData.append("is_offline", examTypeList == 1 ? "true" : "false");
      }

      const hasExtraObjectsInArray2 = interviewer.some(
        (obj2) => !testInterviewer.some((obj1) => obj1.id === obj2.id)
      );

      if (testInterviewer.length > 0 || interviewer.length > 0) {
        if (hasExtraObjectsInArray2) {
          interviewer.map((value) => {
            formData.append("add_interviewer_id", value.id);
          });
        }
      }

      if (removedInteriewer.length > 0) {
        removedInteriewer.forEach((e) => {
          formData.append("remove_interviewer_id", Number.parseInt(e));
        });
      }

      

     
      await AxiosInstance.patch("/interview", formData, config, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Interview Updated Successfully");
            setTimeout(() => {
              Navigate("/interview_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "95%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Interview Edit
          </Box>
        </Box>
        <Container
          component="main"
          style={{
            width: "70%",
            borderRadius: "10px",
          }}
        >
          <Paper
            variant="outlined"
            style={{ border: "1px solid #9BA4B5", width: "100%" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Start DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="start_date_time"
                        required
                        fullWidth
                        type="datetime-local"
                        id="start_date_time"
                        value={start_date_time}
                        onChange={(e) => setStart_date_time(e.target.value)}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    End DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="end_date_time"
                        required
                        fullWidth
                        type="datetime-local"
                        id="end_date_time"
                        value={end_date_time}
                        onChange={(e) => setEnd_date_time(e.target.value)}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Mode Of Interview :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      {/* <FormControl fullWidth> */}
                      <InputLabel id="demo-simple-select-label">
                        Mode Of Interview
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={examTypeList}
                        label="Mode Of Interview"
                        onChange={(e) => {
                          setExamTypeList(e.target.value);
                        }}
                      >
                        {Object.entries(examTypeListOption).map(
                          ([key, value]) => (
                            <MenuItem key={key} value={key}>
                              {value}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {/* </FormControl> */}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Interviewer :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "81%" }}>
                      <Item
                        onChange={(e) => {
                          setSearchInterviewer(e.target.value);
                        }}
                      >
                        <Autocomplete
                          required
                          multiple
                          options={addInterviewerOptions?addInterviewerOptions:[]}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.full_name}
                          renderOption={(props, option) => (
                            <li {...props}>{option.full_name}</li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Multiple Interviewer"
                              placeholder="Select Multiple Interviewer"
                            />
                          )}
                          value={interviewer}
                          onChange={(e, value) => {
                            setInerviewer([...value]);
                          }}
                        />
                      </Item>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "20%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </article>
    </section>
  );
}
