import { useState, useEffect } from "react";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDE6ED",
    color: "#111",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const GDEvolution = ({ studentData, groupIdx }) => {
  let navigate = useNavigate();

  let [status, setStatus] = useState("");
  let [statusOption, setStatusOption] = useState(null);

  const [scoredMarks, setScoredMarks] = useState({});
  const [comment, setComment] = useState("");

  const [open, setOpen] = useState(false);

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  //for appointmentStatus
  useEffect(() => {
    let fetchData = async () => {
      let appStatus = await AxiosInstance.get(`/gd_status`);
      let finalAppStatus = appStatus.data.data;
      setStatusOption(finalAppStatus);
    };
    fetchData();
  }, []);

  const [gdEvalutionData, setGDEvalutionData] = useState([]);
  const [criteria, setCriteria] = useState(null);
  useEffect(() => {
    let fetchData = async () => {
      try {
        let gdData = await AxiosInstance.get(`/gd_group?id=${groupIdx}`);
        let finalGdData = gdData.data.data;
        setGDEvalutionData(finalGdData);
        const criteriaObj = (e) => {
          const obj = {};
          e.forEach((el, ex) => {
            obj[ex] = {
              text: el[0],
              marks: el[1].marks,
              checked: false,
              disabled: true,
            };
          });
          return obj;
        };
        setCriteria(
          finalGdData &&
            finalGdData.length > 0 &&
            finalGdData[0].criteria &&
            Object.keys(finalGdData[0].criteria).length > 0
            ? criteriaObj(Object.entries(finalGdData[0].criteria))
            : null
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setStatus("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Creating the payload structure based on the new state
    const payload = {
      group_id: Number.parseInt(groupIdx),
      student_id: studentData,
      gd_evaluation: {},
      comment: comment,
      status: status,
    };

    if (criteria) {
      Object.entries(criteria).forEach(([index, value]) => {
        const criteriaName = value.text;
        const criteriaMarks = scoredMarks[index] || 0;

        payload.gd_evaluation[criteriaName] = {
          take: true,
          scored_marks: parseFloat(criteriaMarks),
        };
      });
    }

    try {
      await AxiosInstance.post(`/gd_evaluation`, payload)
        .then((response) => {
          toast.success(response.data.message);

          if (response.status === 200) {
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error.response) {
            toast.error(error.response.data.error);
          } else {
            toast.error(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "90vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#081F37" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#081F37",
    color: "#fff",
    border: "1px solid #081F37",
  };
  const gridContainer = {
    width: "100vw !important",
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Grid style={{ marginTop: "5%" }}>
        <Grid xs={12} item container style={gridContainer} align="center">
          <Paper style={paperStyle}>
            <Grid align="center" item>
              <Avatar style={avatarStyle}>
                <GroupAddIcon />
              </Avatar>
              <h2>GD Evaluation</h2>
              <br />
            </Grid>
            <form action="" onSubmit={handleSubmit}>
              <TableContainer
                component={Paper}
                sx={{
                  width: "90%",
                  overflowY: "auto",
                  whiteSpace: "nowrap",
                  marginTop: "1%",
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        Criteria
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "19px",
                        }}
                      >
                        Scored Marks / Actual Marks
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {criteria ? (
                      Object.entries(criteria).map((value, ind) => (
                        <StyledTableRow key={ind}>
                          <StyledTableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            <TextField
                              name="criteriaName"
                              fullWidth
                              id="criteriaName"
                              required
                              disabled
                              label="Criteria Name"
                              value={criteria[ind].text}
                              onChange={(e) => {
                                setCriteria({
                                  ...criteria,
                                  [ind]: {
                                    ...criteria[ind],
                                    text: e.target.value,
                                  },
                                });
                              }}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            <TextField
                              name="criteriaMarks"
                              type="tel"
                              required
                              id="criteriaMarks"
                              label="Scored Marks"
                              inputProps={{ minLength: 1, maxLength: 5 }}
                              value={scoredMarks[ind] || ""}
                              onChange={(e) => {
                                // Update the scored marks state
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setScoredMarks({
                                    ...scoredMarks,
                                    [ind]: e.target.value,
                                  });
                                }
                              }}
                              onInput={(e) => {
                                e.preventDefault();
                                const inputValue = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                setScoredMarks(inputValue);
                              }}
                            />
                            &nbsp;
                            <TextField
                              name="criteriaMarks"
                              type="tel"
                              required
                              disabled
                              id="criteriaMarks"
                              label="Actual Marks"
                              onKeyPress={preventMinus}
                              onPaste={preventPasteNegative}
                              inputProps={{ minLength: 1, maxLength: 3 }}
                              value={criteria[ind].marks}
                              onChange={(e) => {
                                setCriteria({
                                  ...criteria,
                                  [ind]: {
                                    ...criteria[ind],
                                    marks: e.target.value,
                                  },
                                });
                              }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell colSpan={2} align="center">
                          No Data Found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                item
                sm={12}
                id="refreshTool"
                style={{ position: "relative", marginTop: "3%" }}
              >
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    required
                    value={status}
                    label="status"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    {statusOption === null ? (
                      <MenuItem>No data Available</MenuItem>
                    ) : (
                      statusOption.map((e) => {
                        return (
                          <MenuItem value={e.id} key={"status" + e.id}>
                            {e.name}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                  {status !== "" ? (
                    <Tooltip title="Clear Status">
                      <div
                        style={{
                          position: "absolute",
                          top: "9px",
                          left: "680px",
                          color: "#CC7351",
                          cursor: "pointer",
                        }}
                      >
                        <TiDelete
                          fontSize={24}
                          title="Reset Status"
                          onClick={resetAppStatus}
                        />
                      </div>
                    </Tooltip>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid style={{ marginTop: "1%" }}>
                <TextField
                  name="comment"
                  fullWidth
                  id="comment"
                  label="Comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                item
                spacing={2}
                sx={{ mt: 5, mb: 2 }}
                style={{
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{ width: "20%" }}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GDEvolution;
