import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Button,
  Box,
  Drawer,
  TextField,
  IconButton,
  Grid,
  Tooltip,
  Autocomplete,
  Chip,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";

import moment from "moment";
import { FaEdit, FaTimes } from "react-icons/fa";
import AxiosInstance from "../../apis/AxiosInstance";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import VerifierCallrecordVerificationList from "./VerifierCallrecordVerificationList";
import { toast } from "react-toastify";
import decodeSpl from "../../utils/decodeSpl";
import encodeSpl from "../../utils/encodeSpl";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#40679E",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: "normal",
    padding: "5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = e => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = e => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

const removeDuplicatesById = array => {
  const uniqueObjects = array.reduce((acc, obj) => {
    const isDuplicate = acc.some(item => item.id === obj.id);
    if (!isDuplicate) {
      acc.push(obj);
    }
    return acc;
  }, []);
  return uniqueObjects;
};

export default function VerifierCallRecordList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [verifierCallRecord_list, setVerifierCallRecord_list] = useState([]);
  // const [page, setPage] = useState(1);
  const [totalVerifierCallRecord, setTotalVerifierCallRecord] = useState(0);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [resetData, setResetData] = useState(false);
  const [searchButton, setSearchButton] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");

  const [callTypeOption, setCallTypeOption] = useState([]);
  const [callTypeData, setCallTypeData] = useState(null);
  const [searchCallType, setSearchCallType] = useState("");

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(null);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [categoryOption, setCategoryOption] = useState([]);
  const [categorydata, setCategoryData] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");

  const [mainStatusOption, setMainStatusOption] = useState([]);
  const [mainStatusData, setMainStatusData] = useState(null);
  const [searchMainStatus, setSearchMainStatus] = useState("");

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);
  const [inputValue1, setInputValue1] = useState([]);

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  const [subStatusOption, setSubStatusOption] = useState([]);
  const [subStatusData, setSubStatusData] = useState(null);
  const [searchSubStatus, setSearchSubStatus] = useState("");

  const [roleOption, setRoleOption] = useState([]);
  const [roleData, setRoleData] = useState(null);
  const [searchRoleName, setSearcRoleName] = useState("");

  const [duration, setDuration] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [allStates, setAllStates] = useState(false);

  const handleCheckboxChange = event => {
    setAllStates(event.target.checked);
  };

  const [searchParam, setSearchParam] = useSearchParams("");

  const [filteredData, setFilteredData] = useState({
    filtered: false,
    term: "",
  });
  const [defaultState, setDefaultState] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetchVerifierCallRecord();
  }, []);

  let pageParam = searchParam.get("page");
  const [page, setPage] = useState(pageParam ? Number(pageParam) : 1);
  let fetchVerifierCallRecord = async (filter, param) => {
    const pageParamX = searchParam.get("page");
    const filterParamX = searchParam.get("filter");
    setLoad(true);
    if (param) {
      pageParam = "";
      setPage(1);
      setSearchParam("");
    }
    try {
      let verifierData = await AxiosInstance.get(
        filter
          ? `/get_no_verification_record?is_zero=${false}&limit=10${filter}`
          : param
          ? `/get_no_verification_record?is_zero=${false}&limit=10`
          : `/get_no_verification_record?is_zero=${false}&page=${
              pageParamX || 1
            }${filterParamX ? decodeSpl(filterParamX) : ""}`
      );
      setTotalVerifierCallRecord(verifierData.data.filtered_count);
      let finalGdData = verifierData.data.data || [];
      setVerifierCallRecord_list(finalGdData);
      // setDefaultState(verifierData.data);
      // setCategoryOption(finalGdData);
      if (filter) {
        // setIsSidebarOpen(false);
        setIsSidebarOpen(true);
        setFilteredData({ filtered: true, term: filter });
        setResetData(true);
        setSearchButton(false);
        setPage(1);
        setSearchParam(
          `?page=${1}${
            filter
              ? `&filter=${encodeSpl(filter)}`
              : filteredData.filtered
              ? `&filter=${encodeSpl(filteredData.term)}`
              : ""
          }`
        );
      } else {
        setFilteredData({
          filtered: filterParamX ? true : false,
          term: filterParamX ? decodeSpl(filterParamX) : "",
        });
        if (filterParamX) {
          setResetData(true);
          setSearchButton(false);
        } else {
          setResetData(false);
          setSearchButton(true);
        }
        setPage(pageParamX || 1);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
    }
  };

  const handleChangePage = async (event, newPage) => {
    setLoad(true);
    try {
      const { data } = await AxiosInstance.get(
        `/get_no_verification_record?is_zero=${false}&page=${newPage}${
          filteredData.filtered ? filteredData.term : ""
        }`
      );
      let finalData = data.data;

      setSearchParam(
        `?page=${newPage}${
          filteredData.filtered ? `&filter=${encodeSpl(filteredData.term)}` : ""
        }`
      );
      setVerifierCallRecord_list(finalData);
      setTotalVerifierCallRecord(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  useEffect(() => {
    let fetchData = async e => {
      let data = await AxiosInstance.get(
        `/call_category_mini?disable=false&limit=10`
      );
      let finalData = data.data.data;
      setCategoryOption(finalData);
      setDefaultState(data.data);
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [isSidebarOpen]);

  const fetchMainData = async e => {
    try {
      const { data } = await AxiosInstance.get(
        `/call_category?disable=false&id=${e.id}`
      );
      if (data.data && data.data.length > 0) {
        const fetcheddata = data.data.length > 0 ? data.data : null;
        if (fetcheddata) {
          let mainXData = [];
          fetcheddata.forEach(item => {
            if (item.call_status && item.call_status.length > 0) {
              mainXData.push(...item.call_status.filter(e => !e.disable));
            }
          });
          setMainStatusOption(mainXData);
          const subStatusOptions = mainXData
            .filter(e => (mainStatusData ? e.id === mainStatusData.id : e))
            .flatMap(item =>
              item.sub_status
                .filter(e => !e.disable)
                .map(sub => ({
                  ...sub,
                  main_status: item.main_status,
                  main_id: item.id,
                }))
            );
          setSubStatusOption(removeDuplicatesById(subStatusOptions));
        } else {
          setMainStatusOption([]);
          setSubStatusOption([]);
        }
      } else {
        setMainStatusOption([]);
        setSubStatusOption([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalData = response.data.data;
      setStateOptions(finalData);
      setDefaultCourseList(finalData);
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [countryMiniId, isSidebarOpen]);
  const fetchOptions1 = async searchTerm_1 => {
    try {
      const response = await AxiosInstance.get(
        `/statemini?search_keyword=${searchTerm_1}&limit=10&country_id=${countryMiniId}`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setStateOptions(data);
      } else {
        setStateOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setStateOptions(defaultCourseList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedStateOptions(newValue);
  };

  //Category Name
  let handleCatSearch = (_, e) => {
    setLoadCategory(true);
    setSearchCategory(e);
    if (e && e.length > 0) {
      searchCategoryData(e);
    } else {
      setCategoryOption([]);
      setTimeout(() => {
        setCategoryOption(defaultState.data);
      }, 10);
      setLoadCategory(false);
    }
  };
  const searchCategoryData = async searchCategory => {
    try {
      let { data } = await AxiosInstance.get(
        `/call_category_mini?disable=false&search_keyword=${searchCategory}`
      );
      setCategoryOption(data.data || []);
      setLoadCategory(false);
    } catch (error) {
      console.log(error);
      setCategoryOption([]);
      setLoadCategory(false);
    }
  };

  const handleSearchFilterData = () => {
    if (selectedStateOptions && selectedStateOptions.id === 0) {
      toast.error("Select Proper State.");
      return;
    }

    const buildQueryString = selectedOptions => {
      if (!selectedOptions || selectedOptions.length === 0) return "";
      return selectedOptions.map(option => `state_id=${option.id}`).join("&");
    };

    const queryString = buildQueryString(selectedStateOptions);

    const filterArr = [
      { data: mobileNumber ? `&number=${mobileNumber}` : "" },
      { data: duration ? `&is_zero=${duration}` : "" },
      { data: fromDate ? `&from_date=${fromDate}` : "" },
      { data: toDate ? `&to_date=${toDate}` : "" },
      { data: callTypeData ? `&call_type=${callTypeData.ID}` : "" },
      { data: unitNameData ? `&unit_id=${unitNameData.ID}` : "" },
      { data: categorydata ? `&category_id=${categorydata.id}` : "" },
      { data: mainStatusData ? `&main_status_id=${mainStatusData.id}` : "" },
      { data: subStatusData ? `&sub_status_id=${subStatusData.id}` : "" },
      { data: selectedStateOptions && queryString ? `&${queryString}` : "" },
      { data: allStates ? `&all_states=${allStates}` : "" },
      { data: roleData ? `&author_id=${roleData.id}` : "" },
    ];
    fetchVerifierCallRecord(
      filterArr
        .filter(e => e.data)
        .map(e => e.data)
        .join("")
    );
    setIsSidebarOpen(true);
  };

  const handleResetFilterData = () => {
    setMobileNumber("");
    setSearchParam("");
    setDuration("");
    setFromDate("");
    setToDate("");
    setCallTypeData(null);
    setUnitNameData(null);
    setCategoryData(null);
    setMainStatusData(null);
    setSubStatusData(null);
    setAllStates(false);
    setRoleData(null);
    setSelectedStateOptions([]);
    setTimeout(() => {
      // fetchVerifierCallRecord("", 1);
      window.location.reload();
    }, 100);
    // let fetchData=async ()=>{
    //   let verifierData = await AxiosInstance.get(`/get_no_verification_record?limit=10` );
    //   setTotalVerifierCallRecord(verifierData.data.filtered_count);
    //   let finalGdData = verifierData.data.data || [];
    //   setVerifierCallRecord_list(finalGdData);
    // }
    // fetchData();
  };
  const handleClearFilterData = () => {
    setMobileNumber("");
    setSearchParam("");
    setDuration("");
    setFromDate("");
    setToDate("");
    setCallTypeData(null);
    setUnitNameData(null);
    setCategoryData(null);
    setMainStatusData(null);
    setSubStatusData(null);
    setAllStates(false);
    setRoleData(null);
    setSelectedStateOptions([]);
  };

  const calculateSlNo = index => {
    return (page - 1) * 10 + index + 1;
  };

  const handleSearchClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsSidebarOpen(false);
  };

  //Call Type
  useEffect(() => {
    let fetchData = async e => {
      if (searchCallType !== "") {
        // State api call
        let stateData_x = await AxiosInstance.get(
          `/call_type?search_keyword=${searchCallType}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setCallTypeOption(finalStateData_X);
      } else {
        // State api call
        let stateData_x = await AxiosInstance.get(`/call_type?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setCallTypeOption(finalStateData_X);
      }
    };

    if (isSidebarOpen) {
      fetchData();
    }
  }, [searchCallType, isSidebarOpen]);

  //Unit Name
  useEffect(() => {
    let fetchData = async e => {
      if (searchUnitName !== "") {
        let stateData_x = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      } else {
        let stateData_x = await AxiosInstance.get(`/unit?limit=10`);
        let finalStateData_X = stateData_x.data.data;
        setUnitNameOption(finalStateData_X);
      }
    };

    if (isSidebarOpen) {
      fetchData();
    }
  }, [searchUnitName, isSidebarOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleIds = "1,5";
        let response;
        if (searchRoleName !== "") {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=${roleIds}&search_keyword=${searchRoleName}&limit=10`
          );
        } else {
          response = await AxiosInstance.get(
            `/user_mini?role_ids=${roleIds}&limit=10`
          );
        }
        const finalStateData_X = response.data.data || [];
        setRoleOption(finalStateData_X);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setRoleOption([]);
      }
    };
    if (isSidebarOpen) {
      fetchData();
    }
  }, [searchRoleName, isSidebarOpen]);

  const [open, setOpen] = useState(false);
  const [loadCategory, setLoadCategory] = useState(false);

  const btnstyle = {
    marginLeft: "25%",
    margin: "8px 0",
    backgroundColor: "#40679E",
    color: "#fff",
    border: "1px solid #40679E",
    "&:disabled": {
      cursor: "not-allowed",
    },
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const toIsoFormat = dateString => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [currentAudio, setCurrentAudio] = useState(null);

  const handlePlayAudio = audioElement => {
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    setCurrentAudio(audioElement);
  };

  return (
    <section style={{ width: "100%", marginTop: "4.5%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            margin: "0 auto",
            justifyContent: "center",
          }}
        >
          Not Verified Call Record List
        </Box>

        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count: {totalVerifierCallRecord}
        </h1>
        {/* <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          {searchButton ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={handleSearchClick}
              disabled={load}
            >
              Search
            </Button>
          ) : undefined}
        </Grid>
        <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "40px",
          }}
        >
          {" "}
          {resetData ? (
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={btnstyle1}
              onClick={() => {
                handleClearFilterData();
              }}
            >
              Reset
            </Button>
          ) : undefined}
        </Grid> */}
        <Grid md={0.5} sx={{ marginRight: 2 }}>
          <Button
            style={{
              backgroundColor: "#003285",
              color: "#fff",
            }}
            size="small"
            variant="contained"
            fullWidth
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </Grid>
        <Grid md={0.5} sx={{ marginRight: 2 }}>
          <Button
            variant="contained"
            color="warning"
            fullWidth
            size="small"
            onClick={handleResetFilterData}
          >
            Reset
          </Button>
        </Grid>
      </Box>
      <Box sx={{ width: 200 }}>
        <Drawer
          anchor="right"
          open={isSidebarOpen}
          onClose={handleCloseSidebar}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 1,
              paddingTop: 1,
            }}
          >
            <h2
              style={{
                width: "100%",
                marginTop: "2%",
                marginLeft: "2%",
                color: "#40679E",
              }}
            >
              Search List
            </h2>
            <IconButton onClick={handleCloseDrawer}>
              <FaTimes style={{ color: "red" }} />
            </IconButton>
          </Box>
          <Box sx={{ width: 300 }}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{ padding: "8px 8px" }}
            >
              <Grid item sm={12}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search By Number
                </p>
                <TextField
                  style={{ marginTop: "1%" }}
                  size="small"
                  sx={{ minWidth: 210 }}
                  fullWidth
                  name="mobileNumber"
                  label="Number"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 10, maxLength: 10 }}
                  value={mobileNumber}
                  onChange={e => setMobileNumber(e.target.value)}
                  // onChange={(e) => handleFilterChange(e.target.value)}
                />
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Unit Name
                </p>
                <Item onChange={e => setSearchUnitName(e.target.value)}>
                  <Autocomplete
                    options={unitNameOption}
                    value={unitNameData}
                    size="small"
                    name="unitNameOption"
                    onChange={(event, newData_xy) => {
                      setUnitNameData(newData_xy);
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Unit Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Author Name
                </p>
                <Item onChange={e => setSearcRoleName(e.target.value)}>
                  <Autocomplete
                    options={roleOption}
                    value={roleData}
                    size="small"
                    name="roleOption"
                    onChange={(event, newData_xy) => {
                      setRoleData(newData_xy);
                    }}
                    getOptionLabel={option => option.full_name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Author Name"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search State Name
                </p>
                <Item>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    required
                    size="small"
                    value={selectedStateOptions}
                    onChange={handleOptionSelect1}
                    onInputChange={handleInputChange1}
                    options={stateOptions}
                    getOptionLabel={option => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="State"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Select All States
                </p>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allStates}
                      onChange={handleCheckboxChange}
                      name="allStates"
                      color="primary"
                    />
                  }
                  label="All States"
                />
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-3%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Call Type
                </p>
                <Item onChange={e => setSearchCallType(e.target.value)}>
                  <Autocomplete
                    options={callTypeOption}
                    value={callTypeData}
                    size="small"
                    name="callTypeOption"
                    onChange={(event, newData_xy) => {
                      setCallTypeData(newData_xy);
                    }}
                    getOptionLabel={option => option.call_type}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Call type"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              {/* <Grid item sm={12} style={{ marginTop: "-3%" }}>
                <p style={{ fontWeight: "bold" }}>Duration</p>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="duration"
                  value={duration}
                  onChange={(e) => {
                    setDuration(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Zero"
                    name="duration"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Greater Than Zero"
                    name="duration"
                    style={{ marginLeft: "10%" }}
                  />
                </RadioGroup>
              </Grid> */}
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Call Category
                </p>
                <Autocomplete
                  options={categoryOption}
                  value={categorydata}
                  size="small"
                  name="categoryOption"
                  inputValue={searchCategory}
                  onInputChange={handleCatSearch}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newData_xy) => {
                    setCategoryData(newData_xy);
                    setMainStatusData(null);
                    setSubStatusData(null);
                    if (newData_xy) {
                      fetchMainData(newData_xy);
                    }
                  }}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Category"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Main Status
                </p>
                <Item onChange={e => setSearchMainStatus(e.target.value)}>
                  <Autocomplete
                    options={mainStatusOption}
                    value={mainStatusData}
                    size="small"
                    disabled={!categorydata}
                    name="mainStatusOption"
                    onChange={(event, newData_xy) => {
                      setMainStatusData(newData_xy);
                      setSubStatusData(null);
                      if (newData_xy) {
                        setSubStatusOption(
                          newData_xy.sub_status.filter(e => !e.disable)
                        );
                      } else {
                        setSubStatusOption([]);
                      }
                    }}
                    getOptionLabel={option => option.main_status}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Main Status"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  Search Sub Status
                </p>
                <Item onChange={e => setSearchSubStatus(e.target.value)}>
                  <Autocomplete
                    options={subStatusOption}
                    value={subStatusData}
                    disabled={!mainStatusData}
                    size="small"
                    name="subStatusOption"
                    onChange={(event, newData_xy) => {
                      setSubStatusData(newData_xy);
                    }}
                    getOptionLabel={option => option.sub_status}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Sub Status"
                        variant="outlined"
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  From Date
                </p>
                <Item>
                  <TextField
                    type="date"
                    fullWidth
                    id="From Date"
                    size="small"
                    label="From Date "
                    name="From Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoFocus
                    value={fromDate}
                    onChange={e => {
                      setFromDate(e.target.value);
                    }}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>To Date</p>
                <Item>
                  <TextField
                    size="small"
                    type="date"
                    fullWidth
                    id="toDate"
                    label="To Date "
                    name="To Date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoFocus
                    value={toDate}
                    onChange={e => {
                      setToDate(e.target.value);
                    }}
                    disabled={!fromDate}
                  />
                </Item>
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={4} style={{ padding: "5px" }}>
              <Grid item sm={4}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleClearFilterData}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item sm={4}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                  onClick={handleSearchFilterData}
                  sx={{
                    cursor:
                      !mobileNumber &&
                      !toDate &&
                      !fromDate &&
                      !subStatusData &&
                      !mainStatusData &&
                      !categorydata &&
                      !duration &&
                      !unitNameData &&
                      !allStates &&
                      !selectedStateOptions &&
                      !callTypeData &&
                      !roleData
                        ? "not-allowed !important"
                        : "pointer",
                    opacity:
                      !mobileNumber &&
                      !toDate &&
                      !fromDate &&
                      !subStatusData &&
                      !mainStatusData &&
                      !categorydata &&
                      !duration &&
                      !unitNameData &&
                      !allStates &&
                      !selectedStateOptions &&
                      !callTypeData &&
                      !roleData
                        ? 0.3
                        : 1,
                  }}
                  disabled={
                    !mobileNumber &&
                    !toDate &&
                    !fromDate &&
                    !subStatusData &&
                    !mainStatusData &&
                    !categorydata &&
                    !duration &&
                    !unitNameData &&
                    !allStates &&
                    !selectedStateOptions &&
                    !callTypeData &&
                    !roleData
                  }
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </Box>
      <section
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          whiteSpace: "nowrap",
          border: "1px solid #111",
          borderRadius: "10px",
          marginTop: "0.5%",
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            maxHeight: window.innerWidth > 1800 ? 680 : 540,
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "70px",
                    whiteSpace: "normal",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px",
                    whiteSpace: "normal",
                  }}
                >
                  Audio
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px",
                    whiteSpace: "normal",
                  }}
                >
                  Category Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px",
                    whiteSpace: "normal",
                  }}
                >
                  Main Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px",
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  Sub Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px",
                    whiteSpace: "normal",
                  }}
                >
                  Verify
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "120px",
                    whiteSpace: "normal",
                  }}
                >
                  Edit
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px",
                    whiteSpace: "normal",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px",
                    whiteSpace: "normal",
                  }}
                >
                  Call Type
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "190px",
                    whiteSpace: "normal",
                  }}
                >
                  Call Date
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px",
                    whiteSpace: "normal",
                  }}
                >
                  Duration in Sec
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "170px",
                    whiteSpace: "normal",
                  }}
                >
                  Unit Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px",
                    whiteSpace: "normal",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px",
                    whiteSpace: "normal",
                  }}
                >
                  Author Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px",
                    whiteSpace: "normal",
                  }}
                >
                  User Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={14} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : verifierCallRecord_list.length > 0 ? (
                verifierCallRecord_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.audio ? (
                        <audio
                          src={`https://incubationb.qspiders.com/api/v1/get_audio?name=${val.audio}`}
                          controls
                          controlsList="nodownload"
                          onPlay={e => handlePlayAudio(e.target)}
                        />
                      ) : (
                        "NA"
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* {val.call_status.category_id.name} */}
                      {val &&
                      val.call_status &&
                      val.call_status.category_id &&
                      val.call_status.category_id.name
                        ? val.call_status.category_id.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* {val.call_status.call_status.main_status} */}
                      {val &&
                      val.call_status.call_status &&
                      val.call_status.call_status.main_status
                        ? val.call_status.call_status.main_status
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* {val.call_status.call_status.sub_status.sub_status} */}
                      {val &&
                      val.call_status.call_status &&
                      val.call_status.call_status.sub_status &&
                      val.call_status.call_status.sub_status.sub_status
                        ? val.call_status.call_status.sub_status.sub_status
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {Object.keys(val.verification).length === 0 ? (
                        <button
                          style={{
                            background: "#366ED8",
                            border: "none",
                            listStyle: "none",
                            padding: "5px 20px",
                            fontSize: "16px",
                            borderRadius: "10px",
                          }}
                        >
                          <Link
                            to="/create_call_verification"
                            style={{ color: "#fff", textDecoration: "none" }}
                            state={{ record: val }}
                            onClick={() =>
                              setSearchParam(
                                `?page=${page}${
                                  filteredData.filtered
                                    ? `&filter=${encodeSpl(filteredData.term)}`
                                    : ""
                                }`
                              )
                            }
                          >
                            Verify
                          </Link>
                        </button>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "3px",
                            }}
                          >
                            <VerifierCallrecordVerificationList state={val} />
                          </Box>
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Link to="/edit_call_status" state={val}>
                        <Tooltip title="Edit Call Status ">
                          <Button
                            sx={{
                              "&:hover": { background: "#DBDFFD" },
                            }}
                          >
                            <FaEdit
                              fontSize={20}
                              style={{
                                color: "#008DDA",
                                "&:hover": { color: "#EB455F" },
                                cursor: "pointer",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.number}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <p style={{ textTransform: "capitalize" }}>
                        {val && val.call_type && val.call_type.call_type
                          ? val.call_type.call_type
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.call_date === null || val.call_date === ""
                        ? "N/A"
                        : // : moment(val.call_date)
                          moment
                            .utc(toIsoFormat(val.call_date))
                            .local()
                            .format("DD-MM-YYYY hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.duration}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {val.author &&
                        val.author.unit &&
                        Object.values(val.author.unit).length > 0
                          ? Object.values(val.author.unit).map(unit => {
                              return <Box>{unit.name}</Box>;
                            })
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {val.all_states ? (
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            All states
                          </p>
                        ) : val.state && Object.keys(val.state).length > 0 ? (
                          <p>
                            {Object.keys(val.state)
                              .map(key => val.state[key].name)
                              .filter(name => name)
                              .map(name => <Box key={name}>{name}</Box>) ||
                              "NA"}
                          </p>
                        ) : (
                          <p>NA</p>
                        )}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        {val && val.author && val.author.name
                          ? val.author.name
                          : "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {/* <p style={{ textTransform: "capitalize", color: "red" }}>
                        {val.user_id && Object.keys(val.user_id).length > 0
                          ? Object.values(val.user_id).map((user) =>
                              user.name && user.role_id
                                ? Object.values(user.role_id).map(
                                    (role) => role.name
                                  )
                                : "Unknown"
                            )
                          : ["Unknown"]}
                      </p> */}

                      <p style={{ textTransform: "capitalize", color: "red" }}>
                        {val.user_id && Object.keys(val.user_id).length > 0
                          ? Object.values(val.user_id).map(user =>
                              user.name && user.role_id !== undefined
                                ? Object.keys(user.role_id).length > 0
                                  ? Object.values(user.role_id).map(
                                      role => role.name
                                    )
                                  : user.from_spoc === true
                                  ? "Spoc"
                                  : "Unknown"
                                : user.from_spoc === true
                                ? "Spoc"
                                : "Unknown"
                            )
                          : ["Unknown"]}
                      </p>

                      <p style={{ textTransform: "capitalize" }}>
                        {val.user_id && Object.keys(val.user_id).length > 0
                          ? Object.values(val.user_id).map(
                              user => user.name || "Unknown"
                            )
                          : ["Unknown"]}
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={14}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={Math.ceil(totalVerifierCallRecord / 10)}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={Number(pageParam) || page}
          onChange={handleChangePage}
        />
      </section>
    </section>
  );
}
