import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";

const CallType = () => {
  const navigate = useNavigate();
  const [callType, setCallType] = useState("");
  const [weight, setWeight] = useState("");

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      call_type: callType,
      weight: parseInt(weight),
    };

    try {
      await AxiosInstance.post(`/call_type`, payload)
        .then((response) => {
       
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/calltype_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  // for DropDown ScrollBar

  return (
    <Grid style={{ marginTop: "5%" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Create Call Type</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={12}>
                <TextField
                  size="small"
                  sx={{ minWidth: 210 }}
                  fullWidth
                  name="callType"
                  label="Call Type"
                  required
                  value={callType}
                  onChange={(e) => setCallType(e.target.value)}
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  sx={{ minWidth: 210 }}
                  fullWidth
                  required
                  name="weight"
                  label="Weight"
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 2 }}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid item container sm={12} spacing={4}>
              <Grid item sm={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CallType;
