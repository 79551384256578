import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  Box,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import AxiosInstance from "../../apis/AxiosInstance";

const EditPointsAndScripts = () => {
  let { state } = useLocation();
  const navigate = useNavigate();

  const [mainData, setMainData] = useState([
    {
      script: state?.script || "",
      scriptWeight: state?.weights || "",
    },
  ]);

  const [pointsData, setPointsData] = useState(state?.points || []);

  const [mainData1, setMainData1] = useState([
    {
      main_statusWeight: "",
      subData1: [
        {
          subStatusWeight: "",
        },
      ],
    },
  ]);

  const [mainStatusResult, setMainStatusResult] = useState(
    state?.call_substatus?.call_status?.main_status || ""
  );
  const [subStatusResult, setSubStatusResult] = useState(
    state?.call_substatus?.call_status?.sub_status?.sub_status || ""
  );
  const [categoryName, setCategoryName] = useState(
    state?.call_substatus?.category_id?.name || ""
  );

  const addField = () => {
    const arr = [...pointsData];
    arr.push({ marks: "", weight: "", point: "", added: true });
    setPointsData(arr);
  };

  const removeSub = (index) => {
    const arr = [...pointsData];
    arr.splice(index, 1);
    setPointsData(arr);
  };
  const handleSubChange = (id, field) => {
    const { name, value } = field;
    const arr = [...pointsData];
    const target = { ...arr[id], [name]: value, edited: true };
    arr[id] = target;
    setPointsData(arr);
  };
  const handleMainChange = (index, name, value) => {
    const arr = [...mainData];
    const target = { ...arr[index] };
    target[name] = value;
    arr[index] = target;
    setMainData(arr);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const arr = [];
    pointsData
      .filter((e) => e.edited || e.added)
      .forEach((point) => {
        !e.added
          ? arr.push({
              id: point.id,
              point: point.point,
              marks: parseInt(point.marks),
              weight: parseInt(point.weight),
            })
          : arr.push({
              point: point.point,
              marks: parseInt(point.marks),
              weight: parseInt(point.weight),
            });
      });
    const payload = {
      id: state.ID,
      script: mainData[0].script,
      weights: parseInt(mainData[0].scriptWeight),
      points: arr,
      call_substatus: {
        category_id: {
          id: state.call_substatus.category_id.id,
          name: state.call_substatus.category_id.name,
        },
        call_status: {
          id: state.call_substatus.call_status.id,
          main_status: state.call_substatus.call_status.main_status,
          weight: state.call_substatus.call_status.weight,
          sub_status: {
            id: state.call_substatus.call_status.sub_status.id,
            sub_status: state.call_substatus.call_status.sub_status.sub_status,
            weight: state.call_substatus.call_status.sub_status.weight,
            positive: state.call_substatus.call_status.sub_status.positive,
          },
        },
      },
    };

    try {
      await AxiosInstance.post(`/create_points_and_script`, payload);
      toast.success("CallScriptPoints updated successfully.");
      setTimeout(() => {
        navigate("/points_and_scripts_list");
      }, 1500);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.error || "Something went wrong.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const removeMain = (index) => {
    const updatedMainData = [...mainData];
    updatedMainData.splice(index, 1);
    setMainData(updatedMainData);
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border:"1px solid gray",
    borderRadius:"10px"
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  // for DropDown ScrollBar

  return (
    <Box
      style={{
        marginTop: "4.1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Script And Points</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item md={12} xs={12} style={{marginTop:"-1.5%"}}>
             
                <TextField
                  fullWidth
                  name="categoryName"
                  label="Category Name"
                  disabled
                  value={categoryName}
                />
            
            </Grid>
            <Box
              style={{
                width: "98%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "8px",
                marginTop: "1%",
                marginLeft: "-1%",
              }}
            >
              {mainData1.map((val, ind) => {
                return (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{
                      border: "1px solid #06113C",
                 
                      margin: "0 auto",
                      paddingBottom: "15px",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      item
                      sm={6}
                      style={{
                        color: "gray",
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                    >
                      <Box>Main Status</Box>
                      <Box style={{ marginTop: "1%" }}>
                        <TextField
                          sx={{ minWidth: 210 }}
                          fullWidth
                          name="mainStatusResult"
                          label="Main Status"
                          disabled
                          value={mainStatusResult}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      lg={5.5}
                      style={{
                        color: "gray",
                        textAlign: "start",
                        fontWeight: "bold",
                      }}
                    >
                      <Box>Sub Status</Box>
                      <Box>
                        {val.subData1.length > 0 ? (
                          val.subData1.map((value, index) => {
                            return (
                              <Box style={{ marginTop: "1%" }}>
                                <TextField
                                  sx={{ minWidth: 210 }}
                                  fullWidth
                                  name="subStatusResult"
                                  label="Sub Status"
                                  disabled
                                  value={subStatusResult}
                                />
                              </Box>
                            );
                          })
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              color: "red",
                              padding: "5px 10px",
                              background: "lightgrey",
                              width: "100%",
                            }}
                          >
                            No Data Found
                          </p>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                marginTop: "1%",
              }}
            >
              <Box
                style={{
                  width: "96%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                 
                }}
              >
                {mainData.map((val, ind) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      style={{
                        border: "1px solid #06113C",
                        marginTop: "5px",
                        margin: "0 auto",
                        marginLeft: "2%",
                        paddingBottom: "15px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        style={{
                          color: "#1B4242",
                          textAlign: "start",
                          fontWeight: "bold",
                        }}
                      >
                        Script
                      </Grid>
                      <Grid item sm={6} style={{ marginTop: "-0.5%" }}>
                        <TextField
                          fullWidth
                          name="script"
                          label="Script Name"
                          multiline
                          maxRows={1}
                          required
                          value={val.script}
                          onChange={(e) =>
                            handleMainChange(ind, "script", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={5} style={{ marginTop: "-0.5%" }}>
                        <TextField
                         sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                          fullWidth
                          required
                          name="scriptWeight"
                          label="Script Weight"
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={{ minLength: 1, maxLength: 2 }}
                          value={val.scriptWeight}
                          onChange={(e) =>
                            handleMainChange(
                              ind,
                              "scriptWeight",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        style={{
                          marginTop: "-1%",
                          color: "#1B4242",
                          textAlign: "start",
                          fontWeight: "bold",
                        }}
                      >
                        Points
                      </Grid>
                      <div
                        style={{
                          height: "30vh",
                          overflowY: "auto",
                          width: "100%",
                        }}
                      >
                        {pointsData.map((value, index) => {
                          return (
                            <Grid
                              item
                              container
                              sm={12}
                              spacing={2}
                              style={{
                                margin: "0 auto",
                                marginLeft: "1%",
                                paddingBottom: "15px",
                              }}
                            >
                              <Grid item sm={4}>
                                <TextField
                                  sx={{ minWidth: 210 }}
                                  fullWidth
                                  multiline
                                  maxRows={2}
                                  required
                                  name="point"
                                  label="Points Name"
                                  value={value.point}
                                  onChange={(e) =>
                                    handleSubChange(index, e.target)
                                  }
                                />
                              </Grid>
                              <Grid item sm={2.5}>
                                <TextField
                                  sx={{ minWidth: 210 }}
                                  fullWidth
                                  required
                                  name="weight"
                                  label="Points Weight"
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                  value={value.weight}
                                  onChange={(e) =>
                                    handleSubChange(index, e.target)
                                  }
                                />
                              </Grid>
                              <Grid item sm={2.5}>
                                <TextField
                                  sx={{ minWidth: 210 }}
                                  fullWidth
                                  required
                                  name="marks"
                                  label="Points Marks"
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                  value={value.marks}
                                  onChange={(e) =>
                                    handleSubChange(index, e.target)
                                  }
                                />
                              </Grid>

                              <Grid item sm={2}>
                                {!value.id && (
                                  <Button
                                    style={{
                                      border: "none",
                                      background: "crimson",
                                      color: "#fff",
                                      borderRadius: "5px",
                                      marginTop: "10%",
                                    }}
                                    onClick={() => removeSub(index)}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Grid item sm={2} style={{ marginTop: "-2%" }}>
                          <Button
                            style={{
                              border: "none",
                              background: "#87A922",
                              color: "#fff",
                              borderRadius: "5px",
                              marginTop: "10%",
                            }}
                            onClick={addField}
                          >
                            Add
                          </Button>
                        </Grid>
                        {val.showRemoveIcon && (
                          <Grid
                            style={{ marginLeft: "99.8%" }}
                            onClick={() => removeMain(ind)}
                          >
                            <CancelIcon
                              color="error"
                              style={{ fontSize: "35px" }}
                            />
                          </Grid>
                        )}
                      </div>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>

            <Grid item container sm={12} spacing={4}>
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default EditPointsAndScripts;
