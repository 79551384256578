import * as React from "react";
import { Box, Button, Typography, Modal, Tooltip } from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import { MdGroupAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function AddGDModal(props) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  let { stateAddresssing, status } = props;

  const handleClick = () => {
    navigate("/add_new_gd_creation", { state: stateAddresssing });
  };

  const handleClick1 = () => {
    navigate("/edit_same_gd_creation", { state: stateAddresssing });
  };

  return (
    <div>
      <Tooltip title="Add GD">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <MdGroupAdd
            fontSize={21}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
              padding: "-10px",
            }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography id="modal-modal-title" variant="h5">
            Do You Want To
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Button
              sx={{ background: "green", color: "white" }}
              variant="contained"
              onClick={handleClick}
            >
              Add New GD
            </Button>

            <Button
              sx={{ background: "blue", color: "white" }}
              variant="contained"
              onClick={handleClick1}
            >
              Continue Same GD
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
