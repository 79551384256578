import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Tooltip,
  Grid,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Typography,
  Modal,
  Checkbox,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance from "../apis/AxiosInstance";
import { CSVLink } from "react-csv";
// import CommentDetails from "./CommentDetails";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    padding: "5px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    padding: "5px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function LeadPassingFormList() {
  const downloadRef = React.useRef();
  const parseLog = (a) => {
    const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
    const { id } = userData.role[0];
    return id.toString();
  };
  // const role = parseLog(Cookies.get("logged"));
  const role = Cookies.get("current_role");

  const token = Cookies.get("logged");

  const tokenData = JSON.parse(token);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalState(null);
  };

  // //! Fetch the college
  let [leadPassingFormLists, setLeadPassingFormLists] = useState([]);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalLeadPassingLists, setTotalLeadPassingLists] = useState(0);

  const [defaultLeadLists, setDefaultLeadLists] = useState({
    lead_lists: [],
    Totallead_lists: 0,
  });

  let [searchFilter, setSearchFilter] = useState("");

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [defaultState, setDefaultState] = useState([]);

  const [stateOption, setStateOption] = useState([]);
  const [stateData, setStateData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  let [date_time, setDate_time] = useState("");
  let [searchNumber, setSearchNumber] = useState("");
  const [email, setEmail] = useState("");
  const [convertedNotConverted, setConvertedNotConverted] = useState(null);

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    { label: "Name", key: "name" },
    { label: "Emails", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Lead Name", key: "lead_name" },
    { label: "Date Time", key: "date_time" },
    { label: "Author Name", key: "author_name" },
    { label: "Author Branch", key: "author_branch" },
    { label: "Role", key: "role" },
    { label: "User Comment", key: "user_comment" },
    { label: "Comment", key: "comment" },
  ];

  useEffect(() => {
    let fetchData = async () => {
      const leadId = ["19", "20"].includes(role) ? 3 : 5;
      let endpoint = `/get_lead_passing?page=${page}&lead_id=${leadId}`;

      // Check if multiple state IDs exist in branch
      if (role !== "15" && role !== "5") {
        if (
          tokenData?.branch?.state_id &&
          Object.keys(tokenData?.branch.state_id).length > 0
        ) {
          const stateIds = Object.keys(tokenData?.branch.state_id);
          const stateIdsParam = stateIds
            .map((id) => `state_id=${id}`)
            .join("&");
          endpoint += `&${stateIdsParam}`;
        }
      }

      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;

        setLeadPassingFormLists(finalData);
        setTotalLeadPassingLists(data.data.filtered_count);
        setDefaultLeadLists({
          lead_lists: finalData,
          Totallead_lists: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    const leadId = ["19", "20"].includes(role) ? 3 : 5;
    let endpoint = `/get_lead_passing?page=${newPage}&lead_id=${leadId}`;

    // Append multiple state IDs if present
    if (role !== "15" && role !== "5") {
      if (
        tokenData?.branch?.state_id &&
        Object.keys(tokenData?.branch.state_id).length > 0
      ) {
        const stateIds = Object.keys(tokenData?.branch.state_id);
        const stateIdsParam = stateIds.map((id) => `state_id=${id}`).join("&");
        endpoint += `&${stateIdsParam}`;
      }
    }

    setLoad(true);
    try {
      if (date_time) {
        endpoint += `&date=${date_time}`;
      }
      if (searchNumber) {
        endpoint += `&number=${searchNumber}`;
      }
      if (email) {
        endpoint += `&email=${email}`;
      }
      if (convertedNotConverted) {
        endpoint += `&status_id=${convertedNotConverted}`;
      }
      if (stateData) {
        endpoint += `&state_id=${stateData.id}`;
      }
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      const formattedData = finalData.map((item) => ({
        name: item?.details?.name || "NA",
        email: item?.details?.email || "NA",
        mobile: item?.details?.contact?.number || "NA",
        lead_name: item?.details?.leads?.name || "NA",
        date_time:
          item.date_time === null || item.date_time === ""
            ? "N/A"
            : moment.utc(item.date_time).local().format("DD-MM-YYYY h:mm A"),
        author_name: item?.details?.author?.name || "NA",
        author_branch:
          item?.details?.author?.branches?.length > 0
            ? item.details.author.branches
                .map((branch) => branch.name)
                .join(", ")
            : "NA",
        role:
          item?.details?.author?.roles?.length > 0
            ? item.details.author.roles.map((role) => role.name).join(", ")
            : "NA",
        user_comment: item?.details?.comment?.user_comment || "NA",
        comment: item?.details?.comment?.comment || "NA",
      }));
      setCsvData(formattedData);
      setLeadPassingFormLists(finalData);
      setTotalLeadPassingLists(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    const leadId = ["19", "20"].includes(role) ? 3 : 5;
    let endpoint = `/get_lead_passing?page=1&lead_id=${leadId}`;
    // setLoad(true);
    // Append multiple state IDs if present
    if (role !== "15" && role !== "5") {
      if (
        tokenData?.branch?.state_id &&
        Object.keys(tokenData?.branch.state_id).length > 0
      ) {
        const stateIds = Object.keys(tokenData?.branch.state_id);
        const stateIdsParam = stateIds.map((id) => `state_id=${id}`).join("&");
        endpoint += `&${stateIdsParam}`;
      }
    }

    try {
      if (
        !date_time &&
        !searchNumber &&
        !email &&
        !stateData &&
        !convertedNotConverted
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }

      if (date_time) {
        endpoint += `&date=${date_time}`;
      }
      if (searchNumber) {
        endpoint += `&number=${searchNumber}`;
      }
      if (email) {
        endpoint += `&email=${email}`;
      }
      if (convertedNotConverted) {
        endpoint += `&status_id=${convertedNotConverted}`;
      }
      if (stateData) {
        endpoint += `&state_id=${stateData.id}`;
      }
      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      const formattedData = finalData.map((item) => ({
        name: item?.details?.name || "NA",
        email: item?.details?.email || "NA",
        mobile: item?.details?.contact?.number || "NA",
        lead_name: item?.details?.leads?.name || "NA",
        date_time:
          item.date_time === null || item.date_time === ""
            ? "N/A"
            : moment.utc(item.date_time).local().format("DD-MM-YYYY h:mm A"),
        author_name: item?.details?.author?.name || "NA",
        author_branch:
          item?.details?.author?.branches?.length > 0
            ? item.details.author.branches
                .map((branch) => branch.name)
                .join(", ")
            : "NA",
        role:
          item?.details?.author?.roles?.length > 0
            ? item.details.author.roles.map((role) => role.name).join(", ")
            : "NA",
        user_comment: item?.details?.comment?.user_comment || "NA",
        comment: item?.details?.comment?.comment || "NA",
      }));
      setCsvData(formattedData);
      setLeadPassingFormLists(finalData);
      setTotalLeadPassingLists(data.filtered_count);
      setPage(1);
    } catch (error) {
      toast.error("something went wrong");
      // setLoad(false);
    }
  };

  // const handleDownload = async () => {
  //   const totalPage = Math.ceil(totalLeadPassingLists / 50);
  //   try {
  //     const leadId = ["19", "20"].includes(role) ? 3 : 5;
  //     let endpoint = `/get_lead_passing?lead_id=${leadId}`;

  //     if (role !== "15") {
  //       if (
  //         tokenData?.branch?.state_id &&
  //         Object.keys(tokenData?.branch.state_id).length > 0
  //       ) {
  //         const stateIds = Object.keys(tokenData?.branch.state_id);
  //         const stateIdsParam = stateIds
  //           .map((id) => `state_id=${id}`)
  //           .join("&");
  //         endpoint += `&${stateIdsParam}`;
  //       }
  //     }

  //     let arr = [];
  //     for (let i = 1; i <= totalPage; i++) {
  //       let { data } = await AxiosInstance.get(
  //         `/get_lead_passing?lead_id=${leadId}&page=${i}`
  //       );
  //       arr = [...arr, ...data.data];
  //     }
  //     // const { data } = await AxiosInstance.get(endpoint);
  //     const formattedData = arr.map((item) => ({
  //       name: item?.details?.name || "NA",
  //       email: item?.details?.email || "NA",
  //       mobile: item?.details?.contact?.number || "NA",
  //       lead_name: item?.details?.leads?.name || "NA",
  //       date_time:
  //         item.date_time === null || item.date_time === ""
  //           ? "N/A"
  //           : moment.utc(item.date_time).local().format("DD-MM-YYYY h:mm A"),
  //       author_name: item?.details?.author?.name || "NA",
  //       author_branch:
  //         item?.details?.author?.branches?.length > 0
  //           ? item.details.author.branches
  //               .map((branch) => branch.name)
  //               .join(", ")
  //           : "NA",
  //       role:
  //         item?.details?.author?.roles?.length > 0
  //           ? item.details.author.roles.map((role) => role.name).join(", ")
  //           : "NA",
  //       company_name: item?.details?.company_name || "NA",
  //       user_comment: item?.details?.comment?.user_comment || "NA",
  //       comment: item?.details?.comment?.comment || "NA",
  //     }));
  //     setCsvData(formattedData);

  //     // Trigger the CSV download
  //     setTimeout(() => {
  //       downloadRef.current.link.click();
  //     }, 1000);
  //   } catch (error) {
  //     toast.error("Failed to download CSV");
  //   }
  // };

  const handleDownload = async () => {
    try {
      const leadId = ["19", "20"].includes(role) ? 3 : 5;
      let baseEndpoint = `/get_lead_passing?lead_id=${leadId}`;

      // Append multiple state IDs if present
      if (role !== "15" && role !== "5") {
        if (
          tokenData?.branch?.state_id &&
          Object.keys(tokenData?.branch.state_id).length > 0
        ) {
          const stateIds = Object.keys(tokenData?.branch.state_id);
          const stateIdsParam = stateIds
            .map((id) => `state_id=${id}`)
            .join("&");
          baseEndpoint += `&${stateIdsParam}`;
        }
      }

      // Add applied filters to the base endpoint
      if (date_time) {
        baseEndpoint += `&date=${date_time}`;
      }
      if (searchNumber) {
        baseEndpoint += `&number=${searchNumber}`;
      }
      if (email) {
        baseEndpoint += `&email=${email}`;
      }
      if (convertedNotConverted) {
        baseEndpoint += `&status_id=${convertedNotConverted}`;
      }
      if (stateData) {
        baseEndpoint += `&state_id=${stateData.id}`;
      }

      // Fetch all filtered data
      let arr = [];
      let totalPage = 1;

      // Fetch the first page
      const initialResponse = await AxiosInstance.get(`${baseEndpoint}&page=1`);
      arr = [...arr, ...initialResponse.data.data];
      totalPage = Math.ceil(initialResponse.data.filtered_count / 10);

      // Fetch the remaining pages
      for (let i = 2; i <= totalPage; i++) {
        const paginatedResponse = await AxiosInstance.get(
          `${baseEndpoint}&page=${i}`
        );
        arr = [...arr, ...paginatedResponse.data.data];
      }

      // Format the data
      const formattedData = arr.map((item) => ({
        name: item?.details?.name || "NA",
        email: item?.details?.email || "NA",
        mobile: item?.details?.contact?.number || "NA",
        lead_name: item?.details?.leads?.name || "NA",
        date_time:
          item.date_time === null || item.date_time === ""
            ? "N/A"
            : moment.utc(item.date_time).local().format("DD-MM-YYYY h:mm A"),
        author_name: item?.details?.author?.name || "NA",
        author_branch:
          item?.details?.author?.branches?.length > 0
            ? item.details.author.branches
                .map((branch) => branch.name)
                .join(", ")
            : "NA",
        role:
          item?.details?.author?.roles?.length > 0
            ? item.details.author.roles.map((role) => role.name).join(", ")
            : "NA",
        user_comment: item?.details?.comment?.user_comment || "NA",
        comment: item?.details?.comment?.comment || "NA",
      }));

      // Set CSV data for download
      setCsvData(formattedData);

      // Trigger the CSV download
      setTimeout(() => {
        downloadRef.current.link.click();
      }, 1000);
    } catch (error) {
      toast.error("Failed to download CSV");
    }
  };

  let ResetFilterData = async (e) => {
    setDate_time("");
    setSearchNumber("");
    setEmail("");
    setConvertedNotConverted(null);
    setStateData(null);
    // let endpoint = `/get_lead_passing?page=${page}`;
    const leadId = ["19", "20"].includes(role) ? 3 : 5;
    let endpoint = `/get_lead_passing?page=${page}&lead_id=${leadId}`;
    if (role !== "15" && role !== "5") {
      if (
        tokenData?.branch?.state_id &&
        Object.keys(tokenData?.branch.state_id).length > 0
      ) {
        const stateIds = Object.keys(tokenData?.branch.state_id);
        const stateIdsParam = stateIds.map((id) => `state_id=${id}`).join("&");
        endpoint += `&${stateIdsParam}`;
      }
    }

    setLoad(true);
    try {
      let data = await AxiosInstance.get(endpoint);
      let finalData = data.data.data;
      setLeadPassingFormLists(finalData);
      setTotalLeadPassingLists(data.data.filtered_count);
      setDefaultLeadLists({
        lead_lists: finalData,
        Totallead_lists: data.data.filtered_count,
      });
      if (searchFilter) setPage(1);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);
  //!fetching the apis
  useEffect(() => {
    const fetchData = async () => {
      if (countryMiniId !== 0) {
        try {
          const response = await AxiosInstance.get(
            `/statemini?limit=10&country_id=${countryMiniId}`
          );
          const finalStateData = response.data.data;
          setStateOption(finalStateData);
          setDefaultState(finalStateData);
        } catch (error) {
          console.error("Error fetching state data:", error);
        }
      }
    };
    fetchData();
  }, [countryMiniId]);

  // Search for states based on the search term
  const searchState = async (e) => {
    try {
      const response = await AxiosInstance.get(
        `/statemini?search_keyword=${e}&limit=10&country_id=${countryMiniId}`
      );
      const finalStateData = response.data.data;
      setStateOption(finalStateData);
    } catch (error) {
      console.error("Error fetching filtered state data:", error);
      setStateOption(defaultState);
    }
  };

  const totalPages = Math.ceil(totalLeadPassingLists / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  const paperStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    p: 1,
    height: "auto",
    zIndex: "999",
  };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <main style={{ marginTop: "4.3%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          Lead Passing List
        </Box>
        {role === "15" && (
          <>
            <Button
              onClick={handleDownload}
              style={{ background: "green", color: "#fff" }}
              size="small"
            >
              Download CSV File
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`Lead Passing details.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            />
          </>
        )}

        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalLeadPassingLists}
        </h1>
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "0 30px",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </Box>
      {filterVisible && (
        <Grid container style={{ marginTop: "0.5%" }}>
          <Grid item md={2}>
            <Item>
              <TextField
                type="date"
                fullWidth
                id="Date"
                size="small"
                label="Date "
                name="Date"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                value={date_time}
                onChange={(e) => {
                  setDate_time(e.target.value);
                }}
              />
            </Item>
          </Grid>
          <Grid item md={2}>
            <Item>
              <TextField
                placeholder="Mobile Number"
                label="Mobile Number"
                fullWidth
                size="small"
                value={searchNumber}
                name="searchNumber"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 10, maxLength: 10 }}
                onChange={(e) => {
                  setSearchNumber(e.target.value);
                }}
              />
            </Item>
          </Grid>
          <Grid item md={2}>
            <Item>
              <TextField
                placeholder="Email"
                type="email"
                label="Email"
                fullWidth
                size="small"
                value={email}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Item>
          </Grid>
          {tokenData?.branch?.state_id &&
          Object.keys(tokenData?.branch.state_id).length > 0 ? null : (
            <Grid item md={2}>
              <Item>
                <Autocomplete
                  options={stateOption}
                  size="small"
                  value={stateData}
                  name="stateOption"
                  onChange={(event, newData) => {
                    setStateData(newData);
                  }}
                  onInputChange={(event, value) => {
                    if (value && value.length > 0) {
                      searchState(value);
                    } else {
                      setStateOption(defaultState);
                    }
                    setSearchTerm(value);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      required
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                />
              </Item>
            </Grid>
          )}

          <Grid md={1.3} style={{ marginTop: "-0.8%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertedNotConverted === "1"}
                    onChange={() =>
                      setConvertedNotConverted(
                        convertedNotConverted === "1" ? null : "1"
                      )
                    }
                    style={{ transform: "scale(0.75)" }}
                  />
                }
                label={
                  <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Converted
                  </Typography>
                }
                style={{ marginRight: "1rem" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={convertedNotConverted === "2"}
                    onChange={() =>
                      setConvertedNotConverted(
                        convertedNotConverted === "2" ? null : "2"
                      )
                    }
                    style={{ transform: "scale(0.75)" }}
                  />
                }
                label={
                  <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                    Not Converted
                  </Typography>
                }
                style={{ marginTop: "-5%", fontSize: "10px" }}
              />
            </div>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              size="small"
              style={{
                backgroundColor: "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "0.7%",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}

      <section style={{ width: "99%", margin: "0.5% auto" }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 690 : 560 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "70px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Mobile
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "270px !important",
                  }}
                >
                  Email
                </StyledTableCell>
                {role === "15" && (
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Converted/Not
                  </StyledTableCell>
                )}
                {role !== "17" && role !== "18" && role !== "15" && (
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "white",
                      width: "200px !important",
                    }}
                  >
                    Create Appointment
                  </StyledTableCell>
                )}

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Date & Time
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "190px !important",
                  }}
                >
                  Lead Name
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Role Name
                </StyledTableCell>
                {/* <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  User Comment
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px !important",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  User Comment
                </StyledTableCell> */}
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px !important",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "white",
                    width: "200px !important",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  Comment
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={8} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : leadPassingFormLists && leadPassingFormLists.length > 0 ? (
                leadPassingFormLists.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {val?.details?.name || "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {val?.details?.contact?.number || "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {val?.details?.email || "NA"}
                    </StyledTableCell>
                    {role === "15" && (
                      <StyledTableCell align="center">
                        {val.status?.[1] ? (
                          <p style={{ color: "green", fontWeight: "bold" }}>
                            Converted
                          </p>
                        ) : (
                          <p style={{ color: "red", fontWeight: "bold" }}>
                            Not-Converted
                          </p>
                        )}
                      </StyledTableCell>
                    )}
                    {role !== "17" && role !== "18" && role !== "15" && (
                      <StyledTableCell align="center">
                        {val.status?.[1] ? (
                          <p style={{ fontWeight: "bold", color: "#D76C82" }}>
                            Converted
                          </p>
                        ) : (
                          <Button
                            style={{ background: "#366ED8", color: "#fff" }}
                            size="small"
                          >
                            <Link
                              to={
                                role === "19" || role === "20"
                                  ? "/campus_create_collegeappointment"
                                  : "/create_collegeappointment"
                              }
                              state={val}
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Create
                            </Link>
                          </Button>
                        )}
                      </StyledTableCell>
                    )}

                    <StyledTableCell align="center">
                      {val.date_time === null || val.date_time === ""
                        ? "N/A"
                        : moment
                            .utc(toIsoFormat(val.date_time))
                            .local()
                            .format("DD-MM-yyyy hh:mm A")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {val?.details?.leads?.name || "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {val?.details?.author?.roles?.length > 0
                        ? val.details.author.roles.map((e) => (
                            <Box style={{ height: "auto" }}>{e.name}</Box>
                          ))
                        : "NA"}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      <p>
                        <CommentDetails lead_id={val?.ID}></CommentDetails>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p
                        style={{
                          width: "200px !important",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        <Tooltip title="User Comment">
                          {val?.details?.comment &&
                          Object.values(val.details.comment).some(
                            (entry) => entry.user_comment
                          ) ? (
                            <Button
                              onClick={() => {
                                handleOpenModal();
                                setModalState(val);
                              }}
                              sx={{
                                "&:hover": { background: "#1F4529" },
                                backgroundColor: "#47663B",
                                color: "#fff",
                                border: "none",
                              }}
                              size="small"
                            >
                              View
                            </Button>
                          ) : (
                            <span
                              style={{
                                color: "#AE445A",
                                fontStyle: "italic",
                                fontWeight: "bold",
                              }}
                            >
                              No Comment
                            </span>
                          )}
                        </Tooltip>
                      </p>
                    </StyledTableCell> */}
                    <StyledTableCell align="center">
                      {val?.details?.state?.name || "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p
                        style={{
                          width: "200px !important",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {" "}
                        {val?.details?.college || "NA"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p
                        style={{
                          width: "200px !important",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {" "}
                        {val?.details?.comment?.comment || "NA"}
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            maxWidth: "100%",
            display: "flex",
            margin: "0.5% auto",
            justifyContent: "center",
            alignItems: "center",
          }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Grid>
            <Grid xs={12} item container style={gridContainer} align="center">
              <Paper elevation={10} style={paperStyle}>
                <Grid align="center" item>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <AiFillCloseSquare
                      fontSize={30}
                      onClick={handleCloseModal}
                      color="#FC2947"
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                  <h2>User Comment</h2>
                  <br />
                </Grid>
                <TableContainer
                  component={Paper}
                  style={{
                    maxHeight: window.innerWidth > 1800 ? 630 : 530,
                    marginTop: "-1%",
                  }}
                >
                  <Table
                    sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                    aria-label="simple table"
                    stickyHeader
                  >
                    <TableHead sx={{ backgroundColor: "#06113C" }}>
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#06113C",
                            fontWeight: "600",
                            fontSize: "16px",
                            width: "70px !important",
                          }}
                        >
                          Sl.No
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#06113C",
                            fontWeight: "600",
                            fontSize: "16px",
                            width: "200px !important",
                          }}
                        >
                          User Comment
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#06113C",
                            fontWeight: "600",
                            fontSize: "16px",
                            width: "150px !important",
                          }}
                        >
                          Date & Time
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#fff",
                            backgroundColor: "#06113C",
                            fontWeight: "600",
                            fontSize: "16px",
                            width: "180px !important",
                          }}
                        >
                          Comment Added By
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody style={{ overflow: "auto" }}>
                      {modalState?.details?.comment &&
                      Object.entries(modalState.details.comment).length > 0 ? (
                        Object.entries(modalState.details.comment)
                          .filter(
                            ([key, commentData]) =>
                              commentData.user_comment && commentData.name
                          )
                          .map(([id, commentData], index) => (
                            <TableRow key={id}>
                              <StyledTableCell align="center">
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ fontSize: "16px" }}
                              >
                                {commentData.user_comment || "N/A"}
                              </StyledTableCell>

                              <StyledTableCell
                                align="center"
                                sx={{ fontSize: "16px" }}
                              >
                                {commentData.date_time === null ||
                                commentData.date_time === ""
                                  ? "N/A"
                                  : moment
                                      .utc(toIsoFormat(commentData.date_time))
                                      .local()
                                      .format("DD-MM-yyyy hh:mm A")}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                sx={{ fontSize: "16px" }}
                              >
                                {commentData.name || "N/A"}
                              </StyledTableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <StyledTableCell
                            align="center"
                            colSpan={4}
                            sx={{ color: "#000" }}
                          >
                            <h3 style={{ color: "red", textAlign: "center" }}>
                              No Comment
                            </h3>
                          </StyledTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Modal>
      </section>
    </main>
  );
}
