import { useState } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Box,
  Checkbox,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";

const IndivisualMainStatusEdit = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [categoryName, setCategoryName] = useState(state.val.category);

  const initialMainData = state.item
    ? [
        {
          main_status: state.item.main_status,
          id: state.item.id,
          main_statusWeight: state.item.weight.toString(),
          main_disable: state.item.disable,
          showRemoveIcon: false,
          comment: state.item.comment || "",
        },
      ]
    : [];

  const [mainData, setMainData] = useState(initialMainData);

  const addMain = () => {
    setMainData([
      ...mainData,
      {
        main_status: "",
        main_statusWeight: "",
        main_disable: false,
        showRemoveIcon: true, // Show remove icon for new fields
      },
    ]);
  };

  const handleMainChange = (index, field) => {
    const { name, value, type, checked } = field;
    const arr = [...mainData];
    arr[index] = {
      ...arr[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setMainData(arr);
  };

  const removeMain = (index) => {
    const updatedMainData = [...mainData];
    updatedMainData.splice(index, 1);
    setMainData(updatedMainData);
  };

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const buildPayload = () => {
    return mainData.map((mainItem, index) => {
      // Always include all fields for each main item
      return {
        id: mainItem.id || null,
        main_status: mainItem.main_status,
        weight: parseInt(mainItem.main_statusWeight),
        disable: mainItem.main_disable,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      category_id: state.val.ID,
      call_status: buildPayload(),
    };

    try {
      await AxiosInstance.post(`/call_main_status`, payload)
        .then((response) => {
          toast.success("Call Main Status Updated Successfully");
          setTimeout(() => {
            navigate("/call_category_list");
          }, 1500);
        })
        .catch((error) => {
          toast.error(error.response?.data?.error || "Something went wrong");
        });
    } catch (error) {
      toast.error(error.response?.data?.error || "Something went wrong");
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "1px solid gray",
    borderRadius: "10px",
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "18px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };

  const btnstyle1 = {
    margin: "18px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  return (
    <Box
      style={{
        marginTop: "4.1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Main Status</h2>
            <br />
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Grid item sm={12}>
                <TextField
                  size="small"
                  fullWidth
                  name="categoryName"
                  label="Category"
                  required
                  disabled
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Grid>

              <Box
                style={{
                  width: "98%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {mainData.map((val, ind) => (
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{
                      border: "1px solid #06113C",
                      margin: "0 auto",
                      paddingBottom: "15px",
                      borderRadius: "10px",
                    }}
                    key={ind}
                  >
                    <Grid
                      item
                      sm={12}
                      style={{ color: "blue", textAlign: "start" }}
                    >
                      Main Status
                    </Grid>
                    <Grid item sm={5}>
                      <TextField
                        fullWidth
                        name="main_status"
                        label="Main Status"
                        required
                        value={val.main_status}
                        onChange={(e) => handleMainChange(ind, e.target)}
                      />
                    </Grid>
                    <Grid item sm={4}>
                      <TextField
                        fullWidth
                        required
                        name="main_statusWeight"
                        label="Main Status Weight"
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 2 }}
                        value={val.main_statusWeight}
                        onChange={(e) => handleMainChange(ind, e.target)}
                      />
                    </Grid>
                    <Grid item sm={2.5}>
                      <Checkbox
                        name="main_disable"
                        checked={val.main_disable}
                        onChange={(e) => handleMainChange(ind, e.target)}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                      />
                      <label
                        htmlFor="main_disable"
                        style={{ fontSize: "16px" }}
                      >
                        Disable
                      </label>
                    </Grid>

                    {val.showRemoveIcon && (
                      <Grid
                        item
                        sm={1}
                        justifyContent="flex-end"
                        style={{ marginLeft: "99.5%", marginTop: "-7%" }}
                      >
                        <CancelIcon
                          color="error"
                          style={{ fontSize: "35px", cursor: "pointer" }}
                          onClick={() => removeMain(ind)}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Box>

              <Grid
                item
                container
                sm={12}
                justifyContent="flex-end"
                style={{ marginTop: "-4.5%", marginLeft: "1.5%" }}
              >
                <AddCircleOutlineIcon
                  onClick={addMain}
                  color="success"
                  style={{ fontSize: "35px", cursor: "pointer" }}
                />
              </Grid>
            </Grid>

            <Grid item container sm={12} spacing={4}>
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default IndivisualMainStatusEdit;
