import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  Container,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function EditVideo() {
  const Navigate = useNavigate();
  let { state } = useLocation();

  const [videoName, setVideoName] = useState(state.title);
  const [date_time, setDate_time] = useState(
    moment.utc(state.date_time).local().format("YYYY-MM-DDTHH:mm:ss.SSS")
  );
  const [is_default_video, setIs_default_video] = useState(
    state.default_vid === true ? true : false
  );
  const [isDegreeMandatory, setIsDegreeMandatory] = useState(false);
  const [isStreamMandatory, setIsStreamMandatory] = useState(false);
  // const [selectedDegree, setselectedDegree] = useState(null);
  // const [degreeOptions, setDegreeOptions] = useState([]);
  // const [degTerm, setDegTerm] = useState("");
  // const [selectedStream, setselectedStream] = useState(null);
  // const [streamsOptions, setStreamsOptions] = useState([]);
  // const [streamTerm, setStreamTerm] = useState(null);
  let IDy =
    Object.keys(state.details.degree).length > 0
      ? Object.keys(state.details.degree)
      : [];
  let degreeY = Object.values(state.details.degree).map((value) => value.value);
  let OptionY = degreeY.map((value, id) => {
    return { id: IDy[id], name: value };
  });

  const [selectedDegree, setselectedDegree] = useState(
    Object.keys(state.details.degree).length > 0 ? OptionY : []
  );

  let IDx =
    Object.keys(state.details.stream).length > 0
      ? Object.keys(state.details.stream)
      : [];
  let streamX = Object.values(state.details.stream).map((value) => value.value);
  let OptionX = streamX.map((value, id) => {
    return { id: IDx[id], name: value };
  });

  const [selectedStream, setselectedStream] = useState(
    Object.keys(state.details.stream).length > 0 ? OptionX : []
  );

  const [degTerm, setDegTerm] = useState("");
  const [degreeOptions, setDegreeOptions] = useState([]);

  const [streamTerm, setStreamTerm] = useState(null);
  const [streamsOptions, setStreamsOptions] = useState([]);

  const [seletedPreviousVideo, setSeletedPreviousVideo] = useState(null);
  const [searchVideo, setSearchVideo] = useState("");
  const [previousVideoOption, setPreviousVideoOption] = useState([]);

  const [duration, setDuration] = useState("");

  //Exam type list

  const [videoTypeOption, setVideoTypeOption] = useState([]);
  const [videoType, setVideoType] = useState(state.video_type === 1 ? 1 : 2);

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let videoData = await AxiosInstance.get(`/video_type_status`);
      let finalVideoData = videoData.data.data;
      setVideoTypeOption(finalVideoData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchVideo !== "") {
        let colgStateData = await AxiosInstance.get(
          `/videos?search_keyword=${searchVideo}&limit=10`
        );
        let finalcolgStateData = colgStateData.data.data;
        setPreviousVideoOption(finalcolgStateData);
      } else {
        let colgStateData = await AxiosInstance.get(`/videos?limit=10`);
        let finalcolgStateData = colgStateData.data.data;
        setPreviousVideoOption(finalcolgStateData);
      }
    };
    fetchData();
  }, [searchVideo]);

  // useEffect(() => {
  //   let fetchData1 = async () => {
  //     try {
  //       let degreeData;
  //       if (degTerm !== "") {
  //         degreeData = await AxiosInstance.get(
  //           `/degree_types?search_keyword=${degTerm}&limit=10`
  //         );
  //       } else {
  //         degreeData = await AxiosInstance.get(`/degree_types?limit=10`);
  //       }
  //       setDegreeOptions(degreeData.data.data);
  //     } catch (error) {
  //       console.error("Error fetching degree data:", error);
  //     }
  //   };
  //   fetchData1();
  // }, [degTerm]);

  // useEffect(() => {
  //   let fetchData2 = async () => {
  //     try {
  //       let QueryParams = selectedDegree
  //         ? [`degree_id=${selectedDegree.id}`]
  //         : [];
  //       const finalUrl =
  //         QueryParams.length > 0
  //           ? `/academic_streams?${QueryParams.join("&")}`
  //           : "/academic_streams";

  //       let streamData;
  //       if (streamTerm !== null) {
  //         streamData = await AxiosInstance.get(
  //           `${finalUrl}&search_keyword=${streamTerm}`
  //         );
  //       } else {
  //         streamData = await AxiosInstance.get(finalUrl);
  //       }

  //       let finalStreamData = streamData.data.data;
  //       setStreamsOptions(finalStreamData);
  //     } catch (error) {
  //       console.error("Error fetching stream data:", error);
  //     }
  //   };
  //   fetchData2();
  // }, [streamTerm, selectedDegree]);

  const [url, setUrl] = useState("/academic_streams");

  useEffect(() => {
    let fetchData2 = async (e) => {
      let QueryParams = selectedDegree.map((value, i) => {
        return `degree_id=${value.id}`;
      });
      const finalUrl =
        QueryParams.length > 0 ? `${url}?&${QueryParams.join("&")}` : url;
      if (streamTerm !== null) {
        let streamData = await AxiosInstance.get(
          `${finalUrl}&search_keyword=${streamTerm}`
        );
        let finalstreamData = streamData.data.data;
        if (finalstreamData) {
          setStreamsOptions(finalstreamData);
        }
      } else {
        let streamData = await AxiosInstance.get(`${finalUrl}`);
        let finalstreamData = streamData.data.data;
        setStreamsOptions(finalstreamData);
      } 
    };
    fetchData2();
  }, [streamTerm, selectedDegree]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (degTerm !== "" && degTerm.length > 1) {
        // college Degree api
        let degreeData = await AxiosInstance.get(
          `/degree_types?search_keyword=${degTerm}&limit=10`
        );
        let finaldegreeData = degreeData.data.data;
        // if (finaldegreeData) {
        //   setDegreeOptions(finaldegreeData);
        // }
        setDegreeOptions(finaldegreeData);
      } else {
        // college Degree api
        let degreeData = await AxiosInstance.get(`/degree_types?limit=10`);
        let finaldegreeData = degreeData.data.data;
        setDegreeOptions(finaldegreeData);
      }
    };
    fetchData1();
  }, [degTerm]);

  // const [selectedVideo, setSelectedVideo] = useState(null);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedVideo(file);
  // };

  const [selectedVideo, setSelectedVideo] = useState(null);
  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
      setSelectedVideo(file);
  };

  const handleDefaultVideo = (event) => {
    setIs_default_video(event.target.checked);
    setIsDegreeMandatory(event.target.checked);
    setIsStreamMandatory(event.target.checked);
  };

  const [removedDegree, setRemovedDegree] = useState([]);

  // Handle chip removal
  const handleChipDelete = (chipToDelete) => () => {
    // Filter out the chip to be removed from selectedChips
    const updatedChips = selectedDegree.filter((chip) => chip !== chipToDelete);

    // Add the removed chip to removedChips
    setRemovedDegree([...removedDegree, chipToDelete]);

    // Update the selectedChips state
    setselectedDegree(updatedChips);
  };

  const [removedStream, setRemovedStream] = useState([]);

  // Handle chip removal
  const handleChipDelete1 = (chipToDelete) => () => {
    // Filter out the chip to be removed from selectedChips
    const updatedChips = selectedStream.filter((chip) => chip !== chipToDelete);

    // Add the removed chip to removedChips
    setRemovedStream([...removedStream, chipToDelete]);

    // Update the selectedChips state
    setselectedStream(updatedChips);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      const postVideoDateTime = moment(date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");

      formData.append("video_name", videoName);
      formData.append("date_time", postVideoDateTime);
      formData.append("video_type_id", videoType == 1 ? 1 : 2);
      formData.append("is_default_video", is_default_video);
      formData.append("duration", duration);
      formData.append("video", selectedVideo);
      if (seletedPreviousVideo) {
        formData.append("privious_video_id", seletedPreviousVideo.id);
      }
      // if (selectedDegree) {
      //   formData.append("degree_id", selectedDegree.id);
      // }
      // if (selectedDegree) {
      //   formData.append("degree_id",  selectedDegree.map((value) => value.id),);
      // }
      // if (selectedStream) {
      //   formData.append("stream_id", selectedStream.id);
      // }

      // if (selectedStream) {
      //   formData.append("stream_id", selectedStream.map((value) => value.id));
      // }
      if (selectedDegree.length > 0) {
        selectedDegree.forEach((value) => {
          formData.append("degree_id", value.id);
        });
      }

      if (selectedStream.length > 0) {
        selectedStream.forEach((value) => {
          formData.append("stream_id", value.id);
        });
      }

      await AxiosInstance.post("/video", formData, config, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Video Posted Successfully");
            setTimeout(() => {
              Navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.error);
    }
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "95%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Edit Video
          </Box>
        </Box>
        <Container
          component="main"
          style={{
            width: "70%",
            borderRadius: "10px",
          }}
        >
          <Paper
            variant="outlined"
            style={{ border: "1px solid #9BA4B5", width: "100%" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Video Name :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        sx={{ minWidth: 210 }}
                        label="Enter Video Name"
                        type="text"
                        fullWidth
                        name="videoName"
                        value={videoName}
                        require
                        onChange={(e) => setVideoName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Video Type:
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Video Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={videoType}
                        label="Video Type"
                        onChange={(e) => {
                          setVideoType(e.target.value);
                        }}
                      >
                        {Object.entries(videoTypeOption).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <br />
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Choose Video:
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      {/* <TextField
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: "video/*" }}
                      /> */}
                      <TextField
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: "video/*" }}                                      
                      />
                    </FormControl>  
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Video Duration :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="duration"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Select Previous Video :
                  </Grid>
                  <Grid Grid item xs={7.5}>
                    <Item
                      onChange={(e) => {
                        setSearchVideo(e.target.value);
                      }}
                    >
                      <Autocomplete
                        options={previousVideoOption || []}
                        value={seletedPreviousVideo}
                        name="state_name"
                        onChange={(event, newVideo) => {
                          setSeletedPreviousVideo(newVideo);
                        }}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Previous Video"
                            variant="outlined"
                          />
                        )}
                      />
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="date_time"
                        fullWidth
                        type="datetime-local"
                        id="date_time"
                        required
                        value={date_time}
                        onChange={(e) => setDate_time(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      name="is_default_video"
                      checked={is_default_video}
                      onChange={handleDefaultVideo}
                      id="is_default_video"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                    />{" "}
                    <label htmlFor="quiz" style={{ fontSize: "16px" }}>
                      Default Video
                    </label>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Degree :
                  </Grid>
                  <Grid Grid item xs={7.5}>
                    <Item
                      onChange={(e) => {
                        setDegTerm(e.target.value);
                      }}
                    >
                      {/* <Autocomplete
                        options={degreeOptions}
                        value={selectedDegree}
                        name="degree_name"
                        onChange={(event, newdegree) => {
                          setselectedDegree(newdegree);
                        }}
                        getOptionLabel={(option) =>
                          option.name +
                          ` (${(option.short_name || "").toUpperCase()})`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Degree"
                            variant="outlined"
                            required={isDegreeMandatory}
                          />
                        )}
                      /> */}

                      <Autocomplete
                        multiple
                        required={isDegreeMandatory}
                        value={selectedDegree}
                        onChange={(event, newValue) => {
                          setselectedDegree(newValue);
                        }}
                        options={degreeOptions}
                        getOptionLabel={(option) =>
                          option.name +
                          ` (${option.short_name?.toUpperCase() || "N/A"})`
                        }
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              key={index}
                              label={option.name}
                              {...getTagProps({ index })}
                              onDelete={handleChipDelete(option)}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Degree"
                            color="warning"
                          />
                        )}
                      />
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    Stream :
                  </Grid>
                  <Grid Grid item xs={7.5}>
                    <Item
                      onChange={(e) => {
                        setStreamTerm(e.target.value);
                      }}
                    >
                      {/* <Autocomplete
                        options={streamsOptions}
                        value={selectedStream}
                        name="stream_name"
                        onChange={(event, newstream) => {
                          setselectedStream(newstream);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Stream"
                            variant="outlined"
                            required={isStreamMandatory}
                          />
                        )}
                      /> */}

                      <Autocomplete
                        multiple
                        required={isStreamMandatory}
                        value={selectedStream}
                        onChange={(event, newValue) => {
                          setselectedStream(newValue);
                        }}
                        options={streamsOptions}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              key={index}
                              label={option.name}
                              {...getTagProps({ index })}
                              onDelete={handleChipDelete1(option)}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Streams"
                            color="warning"
                          />
                        )}
                      />
                    </Item>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "20%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Container>
      </article>
    </section>
  );
}
