import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  Box,
  Checkbox,
  Autocomplete,
  Chip,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({}));

const EditCallCategory = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [categoryName, setCategoryName] = useState(state.category);
  const [floating_window, setFloating_window] = useState(state.floating_window);
  const [categoryDisable, setCategoryDisable] = useState(state.disable);
  const [weight, setWeight] = useState(state.weight);

  const [selectedRole, setSelectedRole] = useState(
    state.roles && state.roles.length > 0 ? [...state.roles] : "NA"
  );

  const [roleTerm, setRoleTerm] = useState("");
  const [roleOption, setRoleOption] = useState([]);

  const [selectedVerifiedRole, setSelectedVerifiedRole] = useState(
    state.verifier_roles && state.verifier_roles.length > 0
      ? state.verifier_roles.map((val) => val)
      : "NA"
  );
  const [verifiedTerm, setVerifiedTerm] = useState("");
  const [verifiedOption, setVerifiedOption] = useState([]);

  const handleFloatingWindow = (event) => {
    setFloating_window(event.target.checked);
  };

  const handleCategoryDisable = (event) => {
    setCategoryDisable(event.target.checked);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let fethchData = async () => {
      if (roleTerm !== "") {
        let data = await AxiosInstance.get(
          `/roles?search_keyword=${roleTerm}&limit=10`
        );
        let finalData = data.data.data.filter((option) => option.id !== 2);
        setRoleOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/roles?limit=10`);
        let finalData = data.data.data.filter((option) => option.id !== 2);
        setRoleOption(finalData);
      }
    };
    fethchData();
  }, [roleTerm]);

  useEffect(() => {
    let fethchData = async () => {
      if (verifiedTerm !== "") {
        let data = await AxiosInstance.get(
          `/roles?search_keyword=${verifiedTerm}&page=1`
        );
        let finalData = data.data.data.filter(
          (option) => option.id === 17 || option.id === 18
        );
        setVerifiedOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/roles?page=1`);
        let finalData = data.data.data.filter(
          (option) => option.id === 17 || option.id === 18
        );
        setVerifiedOption(finalData);
      }
    };
    fethchData();
  }, [verifiedTerm]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      id: state.ID,
      category: categoryName,
      roles: selectedRole.map((val) => ({ id: val.id, name: val.name })),
      verifier_roles: selectedVerifiedRole.map((val) => ({
        id: val.id,
        name: val.name,
      })),
      floating_window: floating_window,
      disable: categoryDisable,
      weight: parseInt(weight),
    };

    try {
      await AxiosInstance.post(`/call_category`, payload)
        .then((response) => {
          toast.success("Call Category Updated Successfully");
          setTimeout(() => {
            navigate("/call_category_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "45%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "1px solid gray",
    borderRadius: "10px",
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  // for DropDown ScrollBar

  return (
    <Box
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Call Category</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Grid item md={12} xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  name="categoryName"
                  label="Category"
                  required
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Item
                  onChange={(e) => {
                    setRoleTerm(e.target.value);
                  }}
                >
                  {/* <Autocomplete
                      multiple
                      options={roleOption}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Role"
                          placeholder="Select Role"
                          required={selectedRole.length === 0}
                        />
                      )}
                      value={selectedRole}
                      onChange={(e, value) => {
                        setSelectedRole(value);
                      }}
                    /> */}
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    required
                    size="small"
                    value={selectedRole}
                    onChange={(e, value) => {
                      setSelectedRole(value);
                    }}
                    options={roleOption ? roleOption : []}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Role"
                        variant="outlined"
                        color="warning"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        required={selectedRole.length === 0}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item md={12} xs={12}>
                <Item
                  onChange={(e) => {
                    setVerifiedTerm(e.target.value);
                  }}
                >
                  {/* <Autocomplete
                      multiple
                      options={verifiedOption}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <li {...props} style={{ width: "100%" }}>
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Verifier Role"
                          placeholder="Select Role"
                          required={selectedVerifiedRole.length === 0}
                        />
                      )}
                      value={selectedVerifiedRole}
                      onChange={(e, value) => {
                        setSelectedVerifiedRole(value);
                      }}
                    /> */}
                  {/* <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      required
                      size="small"
                      value={selectedVerifiedRole}
                      onChange={(e, value) => {
                        setSelectedVerifiedRole(value);
                      }}
                      options={verifiedOption?verifiedOption:[]}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={option.name + (index + 1)}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Verifier Role"
                          variant="outlined"
                          color="warning"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          required={selectedVerifiedRole.length === 0}
                        />
                      )}
                    /> */}

                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    required
                    size="small"
                    value={selectedVerifiedRole}
                    onChange={(e, value) => {
                      setSelectedVerifiedRole(value);
                    }}
                    options={verifiedOption ? verifiedOption : []}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      Array.isArray(tagValue)
                        ? tagValue.map((option, index) => (
                            <Chip
                              key={option.name + (index + 1)}
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Verifier Role"
                        variant="outlined"
                        color="warning"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        required={selectedVerifiedRole.length === 0}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid style={{ display: "flex", width: "90%", marginTop: "2%" }}>
                <Grid item sm={4}>
                  <Checkbox
                    name="floating_window"
                    checked={floating_window}
                    onChange={handleFloatingWindow}
                    id="floating_window"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                  />{" "}
                  <label htmlFor="floating_window" style={{ fontSize: "16px" }}>
                    Floating Window
                  </label>
                </Grid>
                <Grid item sm={5} style={{ marginTop: "-2%" }}>
                  <TextField
                    fullWidth
                    name="weight"
                    label="Weight"
                    required
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 2 }}
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Checkbox
                    name="categoryDisable"
                    checked={categoryDisable}
                    onChange={handleCategoryDisable}
                    id="categoryDisable"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                  />{" "}
                  <label htmlFor="categoryDisable" style={{ fontSize: "16px" }}>
                    Disable
                  </label>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              sm={12}
              spacing={4}
              style={{ marginTop: "1%" }}
            >
              <Grid item sm={3} style={{ marginLeft: "45%" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={3}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default EditCallCategory;
