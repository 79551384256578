import React from 'react'
import { Navigate, useRoutes } from "react-router-dom";
import Profile from "../components/profile/Profile";
import BdLandingPage from "../Bd_Module/BdLandingPage";
import PageNotFound from "../pages/PageNotFound";
import CreateUnit from "../Bd_Module/bd/unitList/CreateUnit";
import StudentRegistration from '../student/StudentRegistration';
import UserRegistration from '../student/UserRegistration';
import StudentList from '../student/StudentList';
import UserList from '../student/UserList';
import EditStudentList from '../student/EditStudentList';
import EditUserInfo from '../student/EditUserInfo';
import UnitList from '../Bd_Module/bd/unitList/UnitList';
import EditUnit from '../Bd_Module/bd/unitList/EditUnit';
import QEnquiryStudent from '../student/QEnquiryStudent';

const AdminRoutes = () => {
  let routes = useRoutes([
   
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/"/> },
    {path:"/",element:<StudentRegistration/>}, 
    {path:"/create_unit",element:<CreateUnit/>},  
    {path:"/student_register",element: <StudentRegistration/>},
    {path:"/user_register",element: <UserRegistration/>},
    {path:"/student_list",element: <StudentList/>},
    {path:"/user_list",element: <UserList/>},
    {path:"/edit_student_list",element:<EditStudentList/>},
    {path:"/edit_user_info",element:<EditUserInfo/>},
    {path:"/unit_list",element:<UnitList/>},
    {path:"/edit_unit",element:<EditUnit/>},
    {path:"/student_post_enquiry",element:<QEnquiryStudent/>}
  ]);
  return routes;
}

export default AdminRoutes