import * as React from "react";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import {
  Box,
  Grid,
  TextField,
  Button,
  Avatar,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Modal,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AiFillCloseSquare } from "react-icons/ai";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { TiDelete } from "react-icons/ti";
import Cookies from "js-cookie";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

export default function FinalGDShortlist(props) {
  let Navigate = useNavigate();
  const [comment, setComment] = useState("");
  let [status, setStatus] = useState("");
  let [statusOption, setStatusOption] = useState(null);
  const [branchOption, setBranchOption] = useState([]);
  const [branchData, setBranchData] = useState(null);
  const [searchBranch, setSearchBranch] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setStatus("");
    setComment("");
    setBranchData(null);
    setOpen(false);
  };

  let { state } = props;
  //   let appointmentID = Object.keys(props.appointment_idx)[0];

  let { appointment_id1 } = props;

  //for appointmentStatus
  useEffect(() => {
    let fetchData = async () => {
      let appStatus = await AxiosInstance.get(`/final_status`);
      let finalAppStatus = appStatus.data.data;
      setStatusOption(finalAppStatus);
    };
    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchBranch !== "") {
        // State api call
        let branchData = await AxiosInstance.get(
          `/branch?search_keyword=${searchBranch}&limit=10`
        );
        let finalBranchData = branchData.data.data;
        if (finalBranchData) {
          setBranchOption(finalBranchData);
        }
      } else {
        // State api call
        let branchData = await AxiosInstance.get(`/branch?limit=10`);
        let finalBranchData = branchData.data.data;
        if (finalBranchData) {
          setBranchOption(finalBranchData);
        }
      }
    };
    if (open) {
      fetchData();
    }
  }, [searchBranch, open]);

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setStatus("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      const payload = {
        appointment_id: Number.parseInt(appointment_id1),
        student_id: [state.id],
        comment: comment,
        branch_id: branchData.id,
        status: status,
      };
      await AxiosInstance.post("/student_final_status", payload, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Final Shortlist Done");
            setTimeout(() => {
              setOpen(false);
              setStatus("");
              setComment("");
              setBranchData(null);
              Navigate("/tree_roundsto_shortlist");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,

    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#081F37" };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Final Status">
        <Button
          onClick={handleOpen}
          sx={{
            backgroundColor: "#65B741",
            color: "#fff",
            "&:hover": { background: "#1B4242" },
          }}
        >
          Final Status
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid style={{ marginTop: "7%" }}>
          <Grid xs={12} item container style={gridContainer} align="center">
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center" item>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <AiFillCloseSquare
                    fontSize={30}
                    onClick={handleClose}
                    color="#FC2947"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Avatar style={avatarStyle}>
                  <GroupAddIcon />
                </Avatar>
                <h2>Final Status</h2>
                <br />
              </Grid>
              <main
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1% auto",
                }}
              >
                <section style={{ marginTop: "1%" }}>
                  <form onSubmit={handleSubmit}>
                    <Grid style={{ marginTop: "2%" }}>
                      <Grid
                        item
                        sm={12}
                        id="refreshTool"
                        style={{ position: "relative", marginTop: "3%" }}
                      >
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            required
                            value={status}
                            label="status"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            {statusOption === null ? (
                              <MenuItem>No data Available</MenuItem>
                            ) : (
                              statusOption.map((e) => {
                                return (
                                  <MenuItem value={e.id} key={"status" + e.id}>
                                    <p style={{ textAlign: "left" }}>
                                      {" "}
                                      {e.name}
                                    </p>
                                  </MenuItem>
                                );
                              })
                            )}
                          </Select>
                          {status !== "" ? (
                            <Tooltip title="Clear Status">
                              <div
                                style={{
                                  position: "absolute",
                                  top: "9px",
                                  left: "380px",
                                  color: "#CC7351",
                                  cursor: "pointer",
                                }}
                              >
                                <TiDelete
                                  fontSize={24}
                                  title="Reset Status"
                                  onClick={resetAppStatus}
                                />
                              </div>
                            </Tooltip>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        style={{ position: "relative", marginTop: "3%" }}
                      >
                        <Item
                          onChange={(e) => {
                            setSearchBranch(e.target.value);
                          }}
                        >
                          <Autocomplete
                            options={branchOption}
                            value={branchData}
                            name="branchOption"
                            onChange={(event, newData_xy) => {
                              setBranchData(newData_xy);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search Branch"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Item>
                      </Grid>
                      <Grid style={{ marginTop: "3%" }}>
                        <TextField
                          name="comment"
                          multiline
                          maxRows={5}
                          fullWidth
                          id="comment"
                          label="Comment"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ width: "20%", marginTop: "2%" }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </section>
              </main>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
