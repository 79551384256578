import * as React from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Styles from "./_navbar.module.css";
import Menu from "./Menu";
import Cookies from "js-cookie";

const drawerWidth = 240;
let Login = (
  <Link to="/login" className={Styles.navMenuItems}>
    Login
  </Link>
);
const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
// const role = parseLog(Cookies.get("logged"));
const current_role = Cookies.get("current_role");
const role = current_role ? JSON.parse(current_role).toString() : null;

//!DRAWER FOR MOBILE VEIW
function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        <Link to="/login" className={Styles.navMenuItems}>
          Login
        </Link>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          boxShadow: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            role === "1"
              ? "#081f37"
              : role === "5"
              ? "#06113C"
              : role === "16"
              ? "#1B4242"
              : role === "12"
              ? "#176B87"
              : role === "17"
              ? "#40679E"
              : role === "18"
              ? "#40679E"
              : role === "19"
              ? "#003285"
              : role === "20"
              ? "#003285"
              : role === "13"
              ? "#124076"
              : "#081f37",
          // background: "radial-gradient(274px at 7.4% 17.9%, rgb(82, 107, 248) 0.3%, rgb(167, 139, 252) 90.5%)",
          position: "fixed",
          top: "0vh",
          zIndex: "999",
        }}
      >
        <Toolbar sx={{ width: "100vw" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", color: "red" },
            }}
          >
            <Link
              to="/"
              style={{
                color: `${
                  role === "5"
                    ? "#FCA311"
                    : role === "12"
                    ? "#FD8D14"
                    : role === "13"
                    ? "#fff"
                    : role === "16"
                    ? "#fff"
                    : role === "17"
                    ? "#fff"
                    : role === "18"
                    ? "#fff"
                    : role === "19"
                    ? "#fff"
                    : role === "20"
                    ? "#fff"
                    : role === "15"
                    ? "#39A7FF"
                    : "#8ed6ff"
                }`,
              }}
              className={Styles.brandLogo}
            >
              {role === "19" || role === "20" ? "CAMPUS CONNECT" : "INCUBATION"}
              {/* INCUBATION */}
            </Link>
          </Box>
          {/* <Box
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}
          >
            <Link to="/" className={Styles.brandLogo}>
              INCUBATION
            </Link>
          </Box> */}

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Menu
              style={{
                color: "#8aacff",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
            />
          </Box>
          <Box sx={{ display: { xs: "bold", sm: "none" } }}>
            <Menu
              style={{
                color: "#8aacff",
                fontSize: "1.1em",
                fontWeight: "bold",
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            color: "#8aacff",
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
