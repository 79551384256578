import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Box,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "@emotion/styled";
import AxiosInstance from "../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

const Item = styled(Paper)(({ theme }) => ({}));

const CallCategory = () => {
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [floating_window, setFloating_window] = useState(false);
  const [weight, setWeight] = useState("");

  const [mainData, setMainData] = useState([
    {
      main_status: "",
      main_statusWeight: "",
      subData: [
        {
          subStatus: "",
          subStatusWeight: "",
          positive_key: false,
        },
      ],
      showRemoveIcon: false,
    },
  ]);
  const addMain = () => {
    setMainData([
      ...mainData,
      {
        main_status: "",
        main_statusWeight: "",
        subData: [
          {
            subStatus: "",
            subStatusWeight: "",
            positive_key: false,
          },
        ],
        showRemoveIcon: true,
      },
    ]);
  };
  const [selectedRole, setSelectedRole] = useState([]);
  const [roleTerm, setRoleTerm] = useState("");
  const [roleOption, setRoleOption] = useState([]);

  const [selectedVerifiedRole, setSelectedVerifiedRole] = useState([]);
  const [verifiedTerm, setVerifiedTerm] = useState("");
  const [verifiedOption, setVerifiedOption] = useState([]);

  const handleFloatingWindow = (event) => {
    setFloating_window(event.target.checked);
  };

  const addField = (id) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    target["subData"] = [
      ...target["subData"],
      {
        subStatus: "",
        subStatusWeight: "",
        positive_key: false,
      },
    ];
    arr[id] = target;
    setMainData(arr);
  };

  const removeSub = (id, index) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    target["subData"].splice(index, 1);
    arr[id] = target;
    setMainData(arr);
  };
  // const handleSubChange = (id, index, field) => {
  //   const { name, value } = field;
  //   const arr = [...mainData];
  //   const target = { ...arr[id] };
  //   const fields = [...target["subData"]];
  //   fields[index] = { ...fields[index], [name]: value };
  //   target["subData"] = fields;
  //   arr[id] = target;
  //   setMainData(arr);
  // };

  const handleSubChange = (id, index, field) => {
    const { name, value, type, checked } = field; // Destructure type and checked for checkbox
    const arr = [...mainData];
    const target = { ...arr[id] };
    const fields = [...target["subData"]];

    // Check if the field type is a checkbox
    if (type === "checkbox") {
      fields[index] = { ...fields[index], [name]: checked }; // Update checked value
    } else {
      fields[index] = { ...fields[index], [name]: value }; // For other types of fields
    }

    target["subData"] = fields;
    arr[id] = target;
    setMainData(arr);
  };

  const handleMainChange = (index, name, value) => {
    const arr = [...mainData];
    const target = { ...arr[index] };
    target[name] = value;
    arr[index] = target;
    setMainData(arr);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    let fethchData = async () => {
      if (roleTerm !== "") {
        let data = await AxiosInstance.get(
          `/roles?search_keyword=${roleTerm}&limit=10`
        );
        let finalData = data.data.data.filter((option) => option.id !== 2);
        setRoleOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/roles?limit=10`);
        let finalData = data.data.data.filter((option) => option.id !== 2);
        setRoleOption(finalData);
      }
    };
    fethchData();
  }, [roleTerm]);

  useEffect(() => {
    let fethchData = async () => {
      if (verifiedTerm !== "") {
        let data = await AxiosInstance.get(
          `/roles?search_keyword=${verifiedTerm}&page=2`
        );
        let finalData = data.data.data.filter(
          (option) => option.id === 17 || option.id === 18
        );
        setVerifiedOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/roles?page=2`);
        let finalData = data.data.data.filter(
          (option) => option.id === 17 || option.id === 18
        );
        setVerifiedOption(finalData);
      }
    };
    fethchData();
  }, [verifiedTerm]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const callStatusData = mainData.map((mainItem) => {
      const subStatusData = mainItem.subData.map((subItem) => ({
        sub_status: subItem.subStatus,
        weight: parseInt(subItem.subStatusWeight),
        positive: subItem.positive_key,
      }));

      return {
        main_status: mainItem.main_status,
        weight: parseInt(mainItem.main_statusWeight),
        sub_status: subStatusData,
      };
    });

    const payload = {
      category: categoryName,
      roles: selectedRole.map((val) => ({ id: val.id, name: val.name })),
      verifier_roles: selectedVerifiedRole.map((val) => ({
        id: val.id,
        name: val.name,
      })),
      call_status: callStatusData,
      floating_window: floating_window,
      weight: parseInt(weight),
    };

    try {
      await AxiosInstance.post(`/call_category`, payload)
        .then((response) => {
          toast.success("Call Category created Successfully");
          setTimeout(() => {
            navigate("/call_category_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const removeMain = (index) => {
    const updatedMainData = [...mainData];
    updatedMainData.splice(index, 1);
    setMainData(updatedMainData);
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border:"1px solid gray",
    borderRadius:"10px"
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  // for DropDown ScrollBar

  return (
    <Box
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Create Call Category</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid container spacing={1} style={{marginTop:"-1%"}}>
              <Grid item sm={12} md={4}>
                <TextField
                  fullWidth
                  name="categoryName"
                  label="Category"
                  required
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                onChange={(e) => {
                  setRoleTerm(e.target.value);
                }}
              >
                <Autocomplete
                  multiple
                  options={roleOption}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props} style={{ width: "100%" }}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Role"
                      placeholder="Select Role"
                      required={selectedRole.length === 0}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                  value={selectedRole}
                  onChange={(e, value) => {
                    setSelectedRole(value);
                  }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                onChange={(e) => {
                  setVerifiedTerm(e.target.value);
                }}
              >
                <Autocomplete
                  multiple
                  required
                  options={verifiedOption}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props} style={{ width: "100%" }}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Verifier Role"
                      placeholder="Select Role"
                      required={selectedVerifiedRole.length === 0}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  )}
                  value={selectedVerifiedRole}
                  onChange={(e, value) => {
                    setSelectedVerifiedRole(value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              sm={12}
              spacing={1}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                marginTop: "1%",
              }}
            >
              <Box
                style={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {mainData.map((val, ind) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      style={{
                        border: "2px solid gray",
                        marginTop: "2%",
                        margin: "0 auto",
                        marginLeft: "2%",
                        paddingBottom: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                          color: "blue",
                          textAlign: "start",
                        }}
                      >
                        Main Status
                      </Grid>
                      <Grid item md={7} xs={12} style={{ marginTop: "-0.5%" }}>
                        <TextField
                          fullWidth
                          name="main_status"
                          label="Main Status"
                          required
                          value={val.main_status}
                          onChange={(e) =>
                            handleMainChange(ind, "main_status", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4.5}
                        xs={12}
                        style={{ marginTop: "-0.5%" }}
                      >
                        <TextField
                          fullWidth
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                          name="main_statusWeight"
                          label="Main Status Weight"
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={{ minLength: 1, maxLength: 2 }}
                          value={val.main_statusWeight}
                          onChange={(e) =>
                            handleMainChange(
                              ind,
                              "main_statusWeight",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        style={{
                          color: "blue",
                          textAlign: "start",
                        }}
                      >
                        Sub Status
                      </Grid>
                      {val.subData.map((value, index) => {
                        return (
                          <Grid
                            item
                            container
                            sm={12}
                            xs={12}
                            spacing={2}
                            style={{
                              margin: "0 auto",
                              marginLeft: "1%",
                              paddingBottom: "15px",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ marginTop: "-1.5%" }}
                            >
                              <TextField
                                fullWidth
                                required
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                                name="subStatus"
                                label="Sub Status"
                                value={value.subStatus}
                                onChange={(e) =>
                                  handleSubChange(ind, index, e.target)
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              md={2.9}
                              xs={12}
                              style={{ marginTop: "-1.5%" }}
                            >
                              <TextField
                                fullWidth
                                required
                                name="subStatusWeight"
                                label="Sub Status Weight"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 2 }}
                                value={value.subStatusWeight}
                                onChange={(e) =>
                                  handleSubChange(ind, index, e.target)
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              xs={12}
                              style={{ marginTop: "-1.5%" }}
                            >
                              <Checkbox
                                name="positive_key"
                                checked={value.positive_key}
                                onChange={(e) =>
                                  handleSubChange(ind, index, e.target)
                                }
                                id="positive_key"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              />{" "}
                              <label
                                htmlFor="positive_key"
                                style={{ fontSize: "16px" }}
                              >
                                Positive Key
                              </label>
                            </Grid>
                            {index !== 0 && (
                              <Grid
                                item
                                md={2}
                                xs={12}
                                style={{ marginTop: "-2.5%" }}
                              >
                                <Button
                                  style={{
                                    border: "none",
                                    background: "crimson",
                                    color: "#fff",
                                    borderRadius: "5px",
                                    marginTop: "10%",
                                  }}
                                  onClick={() => removeSub(ind, index)}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                      <Grid item sm={2} style={{ marginTop: "-3%" }}>
                        <Button
                          style={{
                            border: "none",
                            background: "#87A922",
                            color: "#fff",
                            borderRadius: "5px",
                            marginTop: "10%",
                          }}
                          onClick={() => addField(ind)}
                        >
                          Add
                        </Button>
                      </Grid>
                      {val.showRemoveIcon && (
                        <Grid
                          style={{ marginLeft: "99.8%" }}
                          onClick={() => removeMain(ind)}
                        >
                          <CancelIcon
                            color="error"
                            style={{ fontSize: "35px" }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </Box>

              <Grid
                item
                container
                sm={12}
                style={{
                  marginLeft: "97%",
                  marginTop: "-15%",
                  cursor: "pointer",
                }}
              >
                <AddCircleOutlineIcon
                  onClick={addMain}
                  color="success"
                  style={{ fontSize: "35px" }}
                />
              </Grid>
            </Grid>

            <Grid style={{ display: "flex", width: "90%", marginTop: "13%" }}>
              <Grid item sm={4}>
                <Checkbox
                  name="floating_window"
                  checked={floating_window}
                  onChange={handleFloatingWindow}
                  id="floating_window"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="floating_window" style={{ fontSize: "16px" }}>
                  Floating Window
                </label>
              </Grid>
              <Grid item sm={5} style={{ marginTop: "-1%" }}>
                <TextField
                  fullWidth
                  name="weight"
                  label="Weight"
                  required
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                  disabled={!floating_window}
                  onKeyPress={preventMinus}
                  onPaste={preventPasteNegative}
                  inputProps={{ minLength: 1, maxLength: 2 }}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </Grid>
            </Grid>
            {/* <Grid item container sm={12} spacing={4}>
              <Grid item sm={12}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid> */}

            <Grid
              item
              container
              sm={12}
              spacing={4}
              style={{ marginTop: "0.1%" }}
            >
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default CallCategory;
