import React, { useState, useEffect ,useRef} from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Styles from "./AppointmentList.module.css";
import { Await, Link, NavLink } from "react-router-dom";
import AxiosInstance from "../../../apis/AxiosInstance";
import {
  Box,
  Button,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  Pagination,
  InputLabel,
  MenuItem,
  Tooltip,
  Select,
  Paper,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import ContactModal from "./ContactModal";
import { FaUsers, FaUserTie, FaEdit, FaPlusCircle } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { RiFilterOffFill } from "react-icons/ri";
import AddressingEditModal from "../addressingForm/AddressingEditModal";
import RegisteredStudents from "./RegisteredStudents";
import "./AppointmentList.module.css";
import { toast } from "react-toastify";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    width: "auto",
    height: "10vh",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "10vh",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    width: "auto",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AppointmentList() {
  const meetingID_Ref = useRef(null);
  let [appointment, setAppointment] = useState([]);
  let [appointmentStatus, setAppointmentStatus] = useState("");
  let [appointmentStatusOption, setAppointmentStatusOption] = useState(null);

  //! SearchFilter
  let [searchFilter, setSearchFilter] = useState("");
  let [yearFilter, setYearFilter] = useState("");
  let [appointmentYear, setAppointmentYear] = useState("");
  // const [addressing_date, setAddressing_date] = useState("");
  // const [addressing_time, setAddressing_time] = useState("");
  const [appointment_date, setAppointment_date] = useState("");
  const [appointment_time, setAppointment_time] = useState("");
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  let [url, seturl] = useState("");
  //scrolling style state
  const [scrollPosition, setScrollPosition] = useState(0);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    setScrollPosition(scrollLeft);
  };

  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  const [searchDistrict, setSearchDistrict] = useState("");

  const copyToClipboard = () => {
    const textToCopy = meetingID_Ref.current.innerText;
    navigator.clipboard
      .writeText(`https://incubation.qspiders.com/appointment/${textToCopy}`)
      .then(() => {
        toast.info("Text copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  useEffect(() => {
    let fetchData = async () => {
      if(searchTerm !== ""){
        let appStatus = await AxiosInstance.get(
          "/appointment_status?search_keyword=${searchTerm}"
        );
        let finalAppStatus = appStatus.data;
        setAppointmentStatusOption(finalAppStatus.data);
      }else{
        let appStatus = await AxiosInstance.get(
          "/appointment_status"
        );
        let finalAppStatus = appStatus.data;
        setAppointmentStatusOption(finalAppStatus.data);
      }
    
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      //college state api
      if(stateSearch !== ""){
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }else{
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }
     
    };
    fetchData();
  }, [stateSearch, countryMiniId]);

  //! for college district
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        if(distSearch !== ""){
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?search_keyword=${distSearch}&state_id=${selectedColgState.id}&limit=10`
          );
          let finalcolgDistrictData= colgDistrictData.data.data
          setcolgDistrictOptions(finalcolgDistrictData);
        }else {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?state_id=${selectedColgState.id}&limit=10`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        }
      } 
    };
    fetchData();
  }, [distSearch, selectedColgState]);

  const params = {
    search_keyword: searchFilter,
    yop: yearFilter,
    appointment_year: appointmentYear,
    appointment_date: appointment_date,
    appointment_time: appointment_time,
    status: appointmentStatus === null ? "" : appointmentStatus,
    district_id: selectedColgDistrict == null ? "" : selectedColgDistrict.id,
    state_id: selectedColgState == null ? "" : selectedColgState.id,
    from_date: fromDate,
    to_date: toDate,
  };

  let [queryString, setQueryString] = useState("");

  function generateQueryString() {
    const nonEmptyKeys = Object.keys(params).filter(
      (key) => params[key] !== ""
    );
    setQueryString(
      nonEmptyKeys.map((key) => `${key}=${params[key]}`).join("&")
    );

    setTimeout(() => {
      toast.success("Successfully search");
    }, 100);
  }

  let handleAddTime = (e) => {
    setAppointment_time(e.target.value);
  };

  // const handle_appointment_page = () => {
  //   setAppointment_page((prev) => prev + 1);
  // };
  //! Filter the Appointment years
  // useEffect(() => {
  //   const fetchAllBatchData = async () => {
  //     let allBatchData = [];
  //     let lastId = null;
  //     let data;

  //     do {
  //       if (queryString == "") {
  //         const response = await AxiosInstance.get(
  //           `/appointment?page=${appointment_page}`
  //           // `/appointment?sort=descending&appointment_year=${2022}`
  //         );
  //         data = response.data.data;
  //       } else {
  //         const response = await AxiosInstance.get(
  //           `/appointment?page=${appointment_page}&${queryString}`
  //           // `/appointment?sort=descending&appointment_year=${2022}`
  //         );
  //         data = response.data.data;
  //       }

  //       if (data && data.length > 0) {
  //         allBatchData.unshift(...data);
  //         lastId = data[data.length - 1].id;
  //       }
  //     } while (data && data.length === 500);

  //     setAppointment([...appointment, ...allBatchData]);
  //   };
  //   fetchAllBatchData();
  // }, [queryString, appointment_page]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  //pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    let fetchData = async () => {
      if (queryString !== "") {
        try {
          let appData = await AxiosInstance.get(
            `/appointment?${queryString}&page=${page}`
          );
          setRowsPerPage(appData.data.filtered_count);
          let finalAppData = appData.data.data;
          setAppointment(finalAppData);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let appData = await AxiosInstance.get(`/appointment?page=${page}`);
          setRowsPerPage(appData.data.filtered_count);
          let finalAppData = appData.data.data;
          setAppointment(finalAppData);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [page, queryString]);

  const totalAppointment = Math.ceil(rowsPerPage / 10);

  // Reset Filters
  const resetFilters = async (e) => {
    e.preventDefault();
    setSearchFilter("");
    setYearFilter("");
    setAppointment_date("");
    setAppointment_time("");
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setFromDate("");
    setToDate("");
    setAppointmentStatus("");
    setAppointmentYear("");
  };

  const resetAppTime = async (e) => {
    e.preventDefault();
    setAppointment_time("");
  };

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setAppointmentStatus("");
  };
  const renderTableBody = () => {
    return appointment.map((data, i) => (
      <StyledTableRow key={data.ID}>
        <StyledTableCell
          className={`table-container ${
            scrollPosition > 0 ? "box-shadow" : ""
          }`}
          component="th"
          scope="row"
          align="left"
          onScroll={handleScroll}
          style={{
            position: "sticky",
            left: "0",
            zIndex: "2",
            backgroundColor: `${i % 2 == 0 ? "#F5F5F5" : "white"}`,
            borderRight: "1.5px solid #EAEAEA",
          }}
        >
          {data.name == "" || data.name == null ? (
            <Box sx={{ color: "#EB455F" }}>NA</Box>
          ) : (
            <p style={{ fontWeight: "bold", fontSize: "16px" }}>{data.name}</p>
          )}
        </StyledTableCell>
        {/* test comment */}
        <StyledTableCell
          component="th"
          scope="row"
          align="center"
          ref={meetingID_Ref}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: "0px",
              color: "white",
            }}
          >
            {data.id}
          </p>
          <Box sx={{ display: "flex", gap: "2px" }}>
            <Tooltip
              title="Copy Meeting Link for Addressing Faculty"
              placement="left-end"
            >
              <Button
                sx={{ background: "#DF7861" }}
                type="button"
                variant="contained"
                value={data.meeting_id}
              >
                <FaUserTie fontSize={22} />
              </Button>
            </Tooltip>

            <Tooltip
              title="Copy Meeting Link for Student"
              placement="right-end"
            >
              <Button
                type="button"
                variant="contained"
                sx={{ background: "#76549A" }}
                onClick={copyToClipboard}
              >
                <FaUsers fontSize={22} />
              </Button>
            </Tooltip>
          </Box>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <p
            style={{
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            {data.appointment_date === null || data.appointment_date === "" ? (
              <Box sx={{ color: "#EB455F" }}>NA</Box>
            ) : (
              data.appointment_date
                .replace("+0000 UTC", "")
                .replace("+0000", "")
            )}
            <Tooltip title="Edit">
              <Link to="/edit_college_appointment" state={data}>
                <FaEdit
                  fontSize={18}
                  color="#537FE7"
                  style={{ cursor: "pointer", width: "50px" }}
                />
              </Link>
            </Tooltip>
          </p>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ height: "13vh" }}>
          {data.status.id === 1 ? (
            <p
              style={{
                background: "#316B83",
                color: "white",
                padding: "8px 7px",
                borderRadius: "20px",
                fontWeight: "500",
              }}
            >
              New
            </p>
          ) : data.status.id === 2 ? (
            <p
              style={{
                background: "purple",
                color: "white",
                padding: "8px 6px",
                borderRadius: "20px",
                fontWeight: "500",
              }}
            >
              Merged
            </p>
          ) : data.status.id === 3 ? (
            <p
              style={{
                background: "#1363DF",
                color: "white",
                padding: "8px 6px",
                borderRadius: "20px",
              }}
            >
              Confirmed
            </p>
          ) : data.status.id === 4 ? (
            <p
              style={{
                background: "#379237",
                color: "white",
                padding: "8px 5px",
                borderRadius: "20px",
              }}
            >
              Conducted
            </p>
          ) : data.status.id === 5 ? (
            <p
              style={{
                background: "#537188",
                color: "white",
                padding: "8px 5px",
                borderRadius: "20px",
              }}
            >
              Date Overdue
            </p>
          ) : data.status.id === 6 ? (
            <p
              style={{
                background: "#9D5C0D",
                color: "white",
                padding: "8px 5px",
                borderRadius: "20px",
              }}
            >
              Yet to Approach
            </p>
          ) : data.status.id === 7 ? (
            <p
              style={{
                background: "#E86A33",
                color: "white",
                padding: "8px 5px",
                borderRadius: "20px",
              }}
            >
              Pending
            </p>
          ) : data.status.id === 8 ? (
            <p
              style={{
                background: "#BE0000",
                color: "white",
                padding: "8px 5px",
                borderRadius: "20px",
              }}
            >
              Not Interested
            </p>
          ) : data.status.id === 9 ? (
            <p
              style={{
                background: "#898121",
                color: "white",
                padding: "8px 5px",
                borderRadius: "20px",
              }}
            >
              Unmerge
            </p>
          ) : (
            <></>
          )}
        </StyledTableCell>
        <StyledTableCell align="center">
          <RegisteredStudents
            total_students={data.registered_students}
            profile_updated={data.profile_updated_count}
            appointmentId={data.id}
          />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {Object.keys(data.college_spocs).length === 0 ? (
                <Box sx={{ width: "100%" }}>
                  <NavLink to="/spoc_creation">
                    {Object.keys(data.college_spocs).length === 0 ? (
                      <Button
                        variant="contained"
                        style={{ background: "#2EB086" }}
                      >
                        Create Spoc
                      </Button>
                    ) : null}
                  </NavLink>
                </Box>
              ) : (
                Object.entries(Object.values(data.college_spocs))[0][1].name
              )}
            </Box>
          </Box>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          {Object.keys(data.college_spocs).length < 1 ? (
            <Box sx={{ color: "#EB455F" }}>No Spoc</Box>
          ) : (
            <>
              <ContactModal
                mobile={
                  Object.entries(Object.values(data.college_spocs))[0][1].mobile
                }
                email={
                  Object.entries(Object.values(data.college_spocs))[0][1].email
                }
                name={
                  Object.entries(Object.values(data.college_spocs))[0][1].name
                }
              />
            </>
          )}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <p style={{ fontWeight: "bold", fontSize: "16px" }}>
            {data.year_of_passout === 0 ? "N/A" : data.year_of_passout}
          </p>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <p style={{ fontWeight: "bold", fontSize: "16px" }}>
            {data.addressing_datetime == "" ? (
              <Tooltip title="Create Addressing">
                <Link
                  to="/create_newaddressing"
                  style={{
                    textDecoration: "none",
                    padding: "5px 3px",
                    borderRadius: "10px",
                    color: "Crimson",
                  }}
                >
                  NA
                  <span>
                    <FaPlusCircle
                      fontSize={18}
                      color="#537FE7"
                      style={{ cursor: "pointer", width: "40px" }}
                    />
                  </span>
                </Link>
              </Tooltip>
            ) : (
              <>
                {/* {data.addressing_datetime.replace("+0000", "")} */}
                {moment
                  .utc(data.addressing_datetime)
                  .local()
                  .format("DD-MM-yyyy hh:mm")}
                <Tooltip title="Edit">
                  <AddressingEditModal
                    stateAddresssing={data}
                    status={data.status.id}
                  />
                </Tooltip>
              </>
            )}
          </p>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="center">
          <p style={{ fontWeight: "bold", fontSize: "16px" }}>
            {data.addressing_rounds !== null ? (
              Object.keys(data.addressing_rounds.rounds).map((value, index) => {
                return <Box>{data.addressing_rounds.rounds[value].name}</Box>;
              })
            ) : (
              <Box sx={{ color: "#EB455F" }}>NA</Box>
            )}
          </p>
        </StyledTableCell>
        <StyledTableCell
          component="th"
          scope="row"
          align="left"
          onScroll={handleScroll}
          className={`table-container ${
            scrollPosition > 0 ? "box-shadow" : ""
          }`}
          style={{
            position: "sticky",
            right: "0",
            zIndex: "2",
            backgroundColor: `${i % 2 == 0 ? "#f5f5f5" : "white"}`,
            borderLeft: "1.5px solid #EAEAEA",
          }}
        >
          YET TO BE DEVELOPED
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <Box style={{ marginTop: "4.5%" }} elevation={8}>
      <Grid
        spacing={1}
        container
        style={{
          marginTop: "5px",
          gap: "10px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          padding: "2px",
        }}
      >
        <Grid item lg={3.75}>
          <TextField
            size="small"
            fullWidth
            type="search"
            label="Search College Appointments"
            placeholder="Type Atleast 3 char of College Name"
            style={{
              background: "#fff",
              borderRadius: "6px",
            }}
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          lg={2.8}
          onChange={(e) => {
            setStateSearch(e.target.value);
          }}
        >
          <Autocomplete
            size="small"
            options={colgStateOptions}
            value={selectedColgState}
            name="state_name"
            onChange={(event, newColgState) => {
              setSelectedColgState(newColgState);
              setselectedColgDistrict(null);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Colleges By State"
                variant="outlined"
                error={error && !selectedColgState}
                helperText={
                  error && !selectedColgState
                    ? "Please select College State"
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid
          item
          md={2.8}
          onChange={(e) => {
            setDistSearch(e.target.value);
          }}
        >
          <Autocomplete
            size="small"
            options={colgDistrictOptions}
            value={selectedColgDistrict}
            name="state_name"
            onChange={(event, newColgdis) => {
              setselectedColgDistrict(newColgdis);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Colleges By District"
                variant="outlined"
                error={error && !selectedColgDistrict}
                helperText={
                  error && !selectedColgDistrict
                    ? "Please select District"
                    : null
                }
              />
            )}
          />
        </Grid>
        <Grid item lg={2}>
          <TextField
            size="small"
            fullWidth
            type="search"
            label="Search Year of Passout"
            placeholder="Search Year of Passout"
            style={{
              background: "#fff",
              borderRadius: "6px",
            }}
            value={yearFilter}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^0-9]/g, ""); // remove non-numeric characters
              if (inputValue.length <= 5) {
                setYearFilter(inputValue);
              }
              // setYearFilter(e.target.value);
            }}
          />
        </Grid>
        <Grid item md={2.4} id="refreshTool" style={{ position: "relative" }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              Appointment Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={appointmentStatus}
              label="appointmentStatus"
              onChange={(e) => {
                setAppointmentStatus(e.target.value);
                seturl(
                  e.target.value.length < 1 ? "" : `&status=${e.target.value}`
                );
              }}
            >
              {appointmentStatusOption === null ? (
                <MenuItem>No data Available</MenuItem>
              ) : (
                Object.entries(appointmentStatusOption).map((e) => {
                  return (
                    <MenuItem value={e[0]} key={"status" + e[0]}>
                      {e[1]}
                    </MenuItem>
                  );
                })
              )}
            </Select>
            {appointmentStatus !== "" ? (
              <Tooltip title="Clear Status">
                <div
                  style={{
                    position: "absolute",
                    top: "9px",
                    left: "225px",
                    color: "#CC7351",
                    cursor: "pointer",
                  }}
                  className={Styles.refreshTool1}
                >
                  <TiDelete
                    fontSize={24}
                    title="Reset Status"
                    onClick={resetAppStatus}
                  />
                </div>
              </Tooltip>
            ) : null}
          </FormControl>
        </Grid>
        <Grid item lg={1.5}>
          <TextField
            fullWidth
            size="small"
            type="search"
            label="Appointment Year"
            placeholder="Appointment Year"
            style={{
              background: "#fff",
              borderRadius: "6px",
            }}
            value={appointmentYear}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^0-9]/g, "");
              if (inputValue.length <= 4) {
                setAppointmentYear(inputValue);
              }
            }}
          />
        </Grid>
        {/* //!FROMDATE */}
        <Grid item md={1.5}>
          <TextField
            type="date"
            fullWidth
            id="From Date"
            size="small"
            label="From Date "
            name="From Date"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => (e.target.type = "text")}
            autoFocus
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
        </Grid>
        {/* //!TO_DATE */}
        <Grid item md={1.5}>
          <TextField
            size="small"
            type="date"
            fullWidth
            id="toDate"
            label="To Date "
            name="To Date"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => (e.target.type = "text")}
            autoFocus
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
        </Grid>
        {/* addressing time */}
        <Grid item md={1.6} sx={{ position: "relative" }}>
          <TextField
            type="time"
            size="small"
            fullWidth
            id="appointmentTime"
            label="Appointment Time"
            name="appointment time"
            onFocus={(e) => (e.target.type = "time")}
            onBlur={(e) => (e.target.type = "text")}
            autoFocus
            value={appointment_time}
            onChange={(e) => {
              handleAddTime(e);
            }}
          />
          <Tooltip title="Clear Appointment Time">
            <div
              style={{
                position: "absolute",
                top: "17px",
                left: "155px",
                color: "#CC7351",
                cursor: "pointer",
              }}
            >
              {appointment_time !== "" ? (
                <TiDelete fontSize={24} onClick={resetAppTime} />
              ) : null}
            </div>
          </Tooltip>
        </Grid>
        <Grid
          item
          md={1.2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            style={{
              fontSize: "16px",
              color: "#fff",
              padding: "4px 30px",
              cursor: "pointer",
              backgroundColor: "#6C9BCF",
              borderRadius: "40px",
              border: "2px solid #efefef",
            }}
            onClick={generateQueryString}
          >
            Search
          </button>
        </Grid>
        <Grid item md={1.8}>
          <button
            type="button"
            style={{
              fontSize: "16px",
              color: "black",
              padding: "4px 10px",
              // width: "50%",
              cursor: "pointer",
              backgroundColor: "#FF7D7D",
              borderRadius: "40px",
              border: "2px solid #efefef",
            }}
            onClick={resetFilters}
          >
            Reset Filters{" "}
            <span>
              {" "}
              <RiFilterOffFill fontSize={16} />
            </span>
          </button>
        </Grid>
        <Grid item md={12}>
          <h1
            style={{
              fontWeight: "bolder",
              textAlign: "center",
              fontSize: "18px",
              color: "#1976D2",
              padding: "3px",
            }}
          >
            Total Count : {rowsPerPage}
          </h1>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowY: "auto",
          whiteSpace: "nowrap",
          borderTop: "5px solid white",
          position: "sticky",
          top: "5vh",
        }}
      >
        <Table aria-label="customized table" style={{ overflowX: "auto" }}>
          <TableHead variant="head">
            <TableRow
              style={{
                background: "#535962",
                color: "#EBF0F6",
                fontWeight: "400",
                position: "relative",
              }}
            >
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  position: "sticky",
                  left: "0",
                  zIndex: "2",
                  backgroundColor: "#535962",
                  borderRight: "1.5px solid #EAEAEA",
                }}
              >
                Appointment Name
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Meeting ID
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Appointment Date
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Drive status
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Registered / Profile Update Students
              </StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Spoc Name
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Spoc Contact
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Year Of Passout
              </StyledTableCell>

              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Addressing
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Rounds
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  position: "sticky",
                  right: "0",
                  backgroundColor: "#535962",
                  borderLeft: "1.5px solid #EAEAEA",
                }}
              >
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {appointment.length < 1 ? null : renderTableBody()}
          </TableBody>
        </Table>
      </TableContainer>

      {appointment.length < 1 ? (
        <h1
          style={{ color: "Red", textAlign: "center", background: "#FAD4D4" }}
        >
          Oops! No Data Found
        </h1>
      ) : null}
      <Pagination
        style={{
          maxWidth: "100%",
          marginTop: "1%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        count={totalAppointment}
        sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
        page={page}
        onChange={handleChangePage}
      />
    </Box>
  );
}
