import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  Container,
  Paper,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import AxiosInstance from "../../../apis/AxiosInstance";

//====================================

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function MultipleEditGd() {
  const Navigate = useNavigate();
  let { state } = useLocation();

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [start_date_time, setStart_date_time] = useState(
    state.stateAddresssing.start_date_time
      ? moment
          .utc(toIsoFormat(state.stateAddresssing.start_date_time))
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSS")
      : ""
  );
  const [gd_duration_per_group, setGd_duration_per_group] = useState(
    state.stateAddresssing.gd_duration_per_group === null ||
      state.stateAddresssing.gd_duration_per_group === ""
      ? ""
      : state.stateAddresssing.gd_duration_per_group
  );
  const [interval_per_group, setInterval_per_group] = useState(
    state.stateAddresssing.interval_per_group === null ||
      state.stateAddresssing.interval_per_group === ""
      ? ""
      : state.stateAddresssing.interval_per_group
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      appointment_id: Number.parseInt(
        Object.keys(state.stateAddresssing1.appointment_id)
      ),
      start_date_time: moment(start_date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
      gd_duration_per_group:
        state.stateAddresssing.is_offline === true ? 0 : Number.parseInt(gd_duration_per_group),
      interval_per_group:
        state.stateAddresssing.is_offline === true ? 0 : Number.parseInt(interval_per_group),
    };

    // const payload = {
    //   appointment_id: Number.parseInt(Object.keys(state.appointment_id)),
    // };

    // // Check if start_date_time has been edited
    // if (start_date_time !== moment.utc(state.start_date_time).local().format("YYYY-MM-DDTHH:mm:ss.SSS")) {
    //   payload.start_date_time = moment(start_date_time).utc().format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]");
    // }

    // // Check if gd_duration_per_group has been edited
    // if (gd_duration_per_group !== state.gd_duration) {
    //   payload.gd_duration_per_group = state.is_offline ? "" : Number.parseInt(gd_duration_per_group);
    // }

    // // Check if interval_per_group has been edited
    // if (interval_per_group !== state.interval_per_group) {
    //   payload.interval_per_group = state.is_offline ? "" : Number.parseInt(interval_per_group);
    // }

    try {
      await AxiosInstance.post("/gd_multiple_patch", payload)
        .then((response) => {
          if (response.status == 200) {
            toast.success("GD Updated Successfully");
            setTimeout(() => {
              Navigate("/gd_creation_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "70%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#081f37",
              fontSize: {
                xs: "20px", // Smaller font size on small screens
                sm: "25px", // Medium font size on small to medium screens
                md: "30px", // Larger font size on medium to large screens
              },
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            Mutiple GD Edit
          </Box>
        </Box>
          <Paper
            variant="outlined"
            style={{ border: "2px solid #9BA4B5", width: "100%",borderRadius:"10px" }}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    GD Start DateTime :
                  </Grid>
                  <Grid Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        name="start_date_time"
                        required
                        fullWidth
                        type="datetime-local"
                        id="start_date_time"
                        value={start_date_time}
                        onChange={(e) => setStart_date_time(e.target.value)}
                        inputProps={{
                          min: new Date().toISOString().slice(0, 16),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    GD Duration Per group :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        // sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="gd_duration_per_group"
                        value={gd_duration_per_group}
                        disabled={state.stateAddresssing.is_offline === true}
                        require
                        onChange={(e) =>
                          setGd_duration_per_group(e.target.value)
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold"  }}
                  >
                    Interval Per group :
                  </Grid>

                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        // size="small"
                        // sx={{ minWidth: 210 }}
                        label="Enter In Minutes"
                        type="tel"
                        fullWidth
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 1, maxLength: 3 }}
                        name="interval_per_group"
                        value={interval_per_group}
                        disabled={state.stateAddresssing.is_offline === true}
                        require
                        onChange={(e) => setInterval_per_group(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ width: "15%", backgroundColor: "crimson" }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "15%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
      
      </article>
    </section>
  );
}
