import React, { useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Profile from "../components/profile/Profile";
import PageNotFound from "../pages/PageNotFound";
import StudentData from "../dataAnalist/StudentData";
import StudentDetails from "../dataAnalist/StudentDetails";
import StudentCard from "../dataAnalist/StudentCard";
import PersonalDetails from "../dataAnalist/PersonalDetails";
import AcademicForm from "../dataAnalist/AcademicForm";
import OtherStreamStudentDetails from "../dataAnalist/OtherStreamStudentDetails";
import OtherDegreeStudentDetails from "../dataAnalist/OtherDegreeStudentDetails";
import OtherCollegeStudentDetails from "../dataAnalist/OtherCollegeStudentDetails";
import OtherDistrictStudentDetails from "../dataAnalist/OtherDistrictStudentDetails";
import OtherUniversityStudentDetials from "../dataAnalist/OtherUniversityStudentDetials";
import OtherPincodeStudentDetails from "../dataAnalist/OtherPincodeStudentDetails";
import CountAddressToRounds from "../Bd_Module/spiltingBdTree/treeCounts/CountAddressToRounds";
import CountAppointToAddressing from "../Bd_Module/spiltingBdTree/treeCounts/CountAppointToAddressing";
import CountBranchJourney from "../Bd_Module/spiltingBdTree/treeCounts/CountBranchJourney";
import CountOnboardingDetails from "../Bd_Module/spiltingBdTree/treeCounts/CountOnboardingDetails";
import CountRoundsToShortlist from "../Bd_Module/spiltingBdTree/treeCounts/CountRoundsToShortlist";
import CountTree from "../Bd_Module/spiltingBdTree/treeCounts/CountTree";
import MasterTableCommonCount from "../Bd_Module/bdTree/MasterTableCommonCount";
import CallRecordCountsReport from "../dataAnalist/CallRecordCountsReport";
import CallRecordVerificationReport from "../dataAnalist/CallRecordVerificationReport";
import LeadPassingForm from "../newlead/LeadPassingForm";
import Rewards from "../newlead/Rewards";
import LeadPassingFormList from "../newlead/LeadPassingFormList";

const DataAnalistRoutes = () => {
  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { data } = await AxiosInstance.get("/user");
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status == 401) {
          let handleLogout = () => {
            Cookies.remove("logged");
            toast.warn("Access blocked login again");
            setTimeout(() => {
              window.location.assign("/login");
            }, 500);
          };
          handleLogout();
        }
      }
    };
    verifyUser();
  }, []);
  let routes = useRoutes([
    { path: "/", element: <StudentCard /> },
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/" /> },
    { path: "/student_data", element: <StudentData /> },
    { path: "/student_details", element: <StudentDetails /> },
    { path: "/personal_details_form", element: <PersonalDetails /> },
    { path: "/academic_details_form", element: <AcademicForm /> },
    {
      path: "/otherstream_studentdetails",
      element: <OtherStreamStudentDetails />,
    },
    {
      path: "/otherdegree_studentdetails",
      element: <OtherDegreeStudentDetails />,
    },
    {
      path: "/othercollege_studentdetails",
      element: <OtherCollegeStudentDetails />,
    },
    {
      path: "/otherdistrict_studentdetails",
      element: <OtherDistrictStudentDetails />,
    },
    {
      path: "/otheruniversity_studentdetails",
      element: <OtherUniversityStudentDetials />,
    },
    {
      path: "/otherpincode_studentdetails",
      element: <OtherPincodeStudentDetails />,
    },
    { path: "/tree_address_torounds", element: <CountAddressToRounds /> },
    {
      path: "/tree_appoint_toaddressing",
      element: <CountAppointToAddressing />,
    },
    { path: "/tree_branchjourney", element: <CountBranchJourney /> },
    { path: "/tree_onboardingdetails", element: <CountOnboardingDetails /> },
    { path: "/tree_roundsto_shortlist", element: <CountRoundsToShortlist /> },
    { path: "/count_tree", element: <CountTree /> },
    { path: "/main_tree", element: <MasterTableCommonCount /> },
    {path:"/call_record_report",element:<CallRecordCountsReport/>},
    {path:"/call_record_verification_report",element:<CallRecordVerificationReport/>},
    { path: "/lead_passing_form", element: <LeadPassingForm /> },
    { path: "/rewards", element: <Rewards /> },
    {path:"/lead_passing_form_list",element:<LeadPassingFormList/>},
  ]);
  return routes;
};

export default DataAnalistRoutes;
