import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Grid,
  Box,
  Autocomplete,
  Container,
  Paper,
  FormControl,
  Typography,
  Button,
  CssBaseline,
} from "@mui/material";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { styled } from "@mui/material/styles";
import AxiosInstance from "../../src/apis/AxiosInstance";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import encodeSpl from "../utils/encodeSpl";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FE7A36",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FE7A36",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FE7A36",
    },
    "& .MuiInputLabel-root": {
      color: "#FE7A36",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#FE7A36",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function AcademicForm() {
  let navigate = useNavigate();
  const classes = useStyles();
  let locationState = useLocation();
  const state = locationState?.state?.data || null;
  const page = locationState?.state?.page || null;
  const filter = locationState?.state?.filter || null;
  const path = locationState?.state?.path || null;
  const handleCancelClick = () => {
    if (state && page && path) {
      const pathTogo = filter
        ? `${path}?page=${page}&filter=${encodeSpl(filter)}`
        : `${path}?page=${page}`;
      window.sessionStorage.removeItem("filter");
      navigate(pathTogo);
    } else {
      window.history.back();
    }
  };

  //Select Qualification
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState(
    state?.other_fields.student?.highest_on_going_degree
      ? state.other_fields.student.highest_on_going_degree
      : null
  );
  const [qualificationSearch, setQualificationSearch] = useState("");

  // Degree Information
  //Select Degree
  const [selectedDegrees, setselectedDegrees] = useState(
    state?.other_fields.student?.degree_details?.degree_type &&
      Object.keys(state.other_fields.student.degree_details.degree_type)
        .length > 0
      ? state?.other_fields.student?.degree_details?.degree_type
      : null
  );
  const [degTerm, setDegTerm] = useState("");
  const [degreeOptions, setDegreeOptions] = useState([]);

  //Select Stream
  const [selectedStreams, setselectedStreams] = useState(
    state?.other_fields.student?.degree_details?.degree_stream &&
      Object.keys(state.other_fields.student.degree_details.degree_stream)
        .length > 0
      ? state?.other_fields.student?.degree_details?.degree_stream
      : null
  );
  const [streamsOptions, setStreamsOptions] = useState([]);
  const [streamTerm, setStreamTerm] = useState("");

  //Yop
  const [degree_probable_year_of_passout, setDegree_probable_year_of_passout] =
    useState(
      state?.other_fields.student?.degree_details
        ?.degree_probable_year_of_passout
        ? state?.other_fields.student?.degree_details
            ?.degree_probable_year_of_passout
        : ""
    );
  const [degree_university_number, setDegree_university_number] = useState(
    state?.other_fields.student?.degree_details
      ?.degree_university_registration_number
      ? state?.other_fields.student?.degree_details
          ?.degree_university_registration_number
      : ""
  );
  const [degreePercentage, setDegreePercentage] = useState(
    state?.other_fields.student?.degree_details
      ? state?.other_fields.student?.degree_details?.degree_mark_type
      : ""
  ); //radio button
  const [degreeInCGPA, setDegreeInCGPA] = useState(
    state?.other_fields.student?.degree_details
      ? state?.other_fields.student?.degree_details?.degree_mark_type_value
      : ""
  );
  const [degreeInPercent, setDegreeInPercent] = useState(
    state?.other_fields.student?.degree_details
      ? state?.other_fields.student?.degree_details?.degree_mark_type_value
      : ""
  );
  const [degreeYearGap, setDegreeYearGap] = useState(
    state?.other_fields.student?.degree_details
      ? state?.other_fields.student?.degree_details?.degree_number_of_years
      : ""
  );
  const [degreeYear, setDegreeYear] = useState(
    state?.other_fields.student?.degree_details
      ? state?.other_fields.student?.degree_details
          ?.do_you_have_academic_gap_in_degree
      : ""
  );
  const [degreeBacklogs, setDegreeBacklogs] = useState(
    state?.other_fields.student?.degree_details
      ? state?.other_fields.student?.degree_details?.degree_active_backlogs
      : ""
  );

  const [districtOption, setDistrictOption] = useState([]);
  const [districtData, setDistrictData] = useState(
    state?.other_fields.student?.degree_details?.degree_college_district &&
      Object.keys(
        state.other_fields.student.degree_details.degree_college_district
      ).length > 0
      ? state?.other_fields.student?.degree_details?.degree_college_district
      : null
  );
  const [searchDistrict, setSearchDistrict] = useState("");

  const [collegeOption, setCollegeOption] = useState([]);
  const [collegeDataa, setCollegeDataa] = useState(
    state?.other_fields.student?.degree_details?.degree_college &&
      Object.keys(state.other_fields.student.degree_details.degree_college)
        .length > 0
      ? state?.other_fields.student?.degree_details?.degree_college
      : null
  );
  const [searchCollege, setSearchCollege] = useState("");

  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  //   const [searchUniversity, setSearchUniversity] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  const [otherDegreeType, setOtherDegreeType] = useState(
    state?.other_fields.student?.degree_details?.other_degree_type
      ? state?.other_fields.student?.degree_details?.other_degree_type
      : ""
  );
  const [otherDegreeStreamName, setOtherDegreeStreamName] = useState(
    state?.other_fields.student?.degree_details?.degree_other_stream_name
      ? state?.other_fields.student?.degree_details?.degree_other_stream_name
      : ""
  );
  const [otherDegreeState, setOtherDegreeState] = useState("");
  const [otherDegreeDistrict, setOtherDegreeDistrict] = useState(
    state?.other_fields.student?.degree_details
      ?.degree_college_other_district_name
      ? state?.other_fields.student?.degree_details
          ?.degree_college_other_district_name
      : ""
  );
  const [otherDegreeUniversity, setOtherDegreeUniversity] = useState(
    state?.other_fields.student?.degree_details?.degree_other_university_name
      ? state?.other_fields.student?.degree_details
          ?.degree_other_university_name
      : ""
  );

  const [otherDegreeCollege, setOtherDegreeCollege] = useState(
    state?.other_fields.student?.degree_details?.degree_other_college_name
      ? state?.other_fields.student?.degree_details?.degree_other_college_name
      : ""
  );

  //Master Degree Information
  const [selectedMasterDegree, setSelectedMasterDegree] = useState(
    state?.other_fields.student?.master_degree_details?.master_degree_type &&
      Object.keys(
        state.other_fields.student.master_degree_details.master_degree_type
      ).length > 0
      ? state?.other_fields.student?.master_degree_details?.master_degree_type
      : null
  );
  const [masterDegreeOptions, setMasterDegreeOptions] = useState([]);
  const [masTerm, setMasTerm] = useState("");

  const [selectedMasterStream, setSelectedmasterStream] = useState(
    state?.other_fields.student?.master_degree_details?.master_degree_stream &&
      Object.keys(
        state.other_fields.student.master_degree_details.master_degree_stream
      ).length > 0
      ? state?.other_fields.student?.master_degree_details?.master_degree_stream
      : null
  );
  const [masterStreamOptions, setMasterStreamOptions] = useState([]);
  const [masStreamTerm, setMasStreamTerm] = useState("");

  const [masterdistrictOption, setMasterDistrictOption] = useState([]);
  const [masterdistrictData, setMasterDistrictData] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_college_district &&
      Object.keys(
        state.other_fields.student.master_degree_details
          .master_degree_college_district
      ).length > 0
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_college_district
      : null
  );
  const [masDisTerm, setMasDisTerm] = useState("");

  const [masterCollegeOption, setMasterCollegeOption] = useState([]);
  const [masterCollegeData, setMasterCollegeData] = useState(
    state?.other_fields.student?.master_degree_details?.master_degree_college &&
      Object.keys(
        state.other_fields.student.master_degree_details.master_degree_college
      ).length > 0
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_college
      : null
  );
  const [masterCollegeSearch, setMasterCollegeSearch] = useState("");

  const [masterYop, setMasterYop] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_currently_pursuing_year_id
      ? state?.other_fields.student?.master_degree_details
          ?.master_currently_pursuing_year_id
      : ""
  );
  const [masterUniversity, setMasterUniversity] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_university_registration_number
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_university_registration_number
      : ""
  );
  const [masterPercentage, setMasterPercentage] = useState(
    state?.other_fields.student?.master_degree_details?.master_degree_mark_type
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_mark_type
      : ""
  ); //radio button
  const [masterInCGPA, setMasterInCGPA] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_mark_type_value
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_mark_type_value
      : ""
  );
  const [masterInPercent, setMasterInPercent] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_mark_type_value
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_mark_type_value
      : ""
  );
  const [masterYearGap, setMasterYearGap] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_number_of_years
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_number_of_years
      : ""
  );
  const [masterYear, setMasterYear] = useState(
    state?.other_fields.student?.master_degree_details
      ?.do_you_have_academic_gap_in_master_degree
      ? state?.other_fields.student?.master_degree_details
          ?.do_you_have_academic_gap_in_master_degree
      : ""
  ); //radio button
  const [masterBacklogs, setMasterBacklogs] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_active_backlogs
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_active_backlogs
      : ""
  );

  const [otherMasterDegreeType, setOtherMasterDegreeType] = useState(
    state?.other_fields.student?.master_degree_details?.other_master_degree_type
      ? state?.other_fields.student?.master_degree_details
          ?.other_master_degree_type
      : ""
  );
  const [otherMasterDegreeStreamName, setOtherMasterDegreeStreamName] =
    useState(
      state?.other_fields.student?.master_degree_details
        ?.master_degree_other_stream_name
        ? state?.other_fields.student?.master_degree_details
            ?.master_degree_other_stream_name
        : ""
    );
  const [otherMasterDegreeState, setOtherMasterDegreeState] = useState("");
  const [otherMasterDegreeDistrict, setOtherMasterDegreeDistrict] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_college_degree_other_district_name
      ? state?.other_fields.student?.master_degree_details
          ?.master_college_degree_other_district_name
      : ""
  );
  const [otherMasterDgreeUniversity, setOtherMasterDgreeUniversity] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_other_university_name
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_other_university_name
      : ""
  );
  const [otherMasterDegreeCollege, setOtherMasterDegreeCollege] = useState(
    state?.other_fields.student?.master_degree_details
      ?.master_degree_other_college_name
      ? state?.other_fields.student?.master_degree_details
          ?.master_degree_other_college_name
      : ""
  );

  //10th Information

  const [tenthPercentage, setTenthPercentage] = useState(
    state?.other_fields.student?.tenth_details?.tenth_mark_type
      ? state?.other_fields.student?.tenth_details?.tenth_mark_type
      : ""
  ); //radio button
  const [tenthInCGPA, setTenthInCGPA] = useState(
    state?.other_fields.student?.tenth_details?.tenth_mark_type_value
      ? state?.other_fields.student?.tenth_details?.tenth_mark_type_value
      : ""
  );
  const [tenthInPercent, setTenthInPercent] = useState(
    state?.other_fields.student?.tenth_details?.tenth_mark_type_value
      ? state?.other_fields.student?.tenth_details?.tenth_mark_type_value
      : ""
  );
  const [tenthYop, setTenthYop] = useState(
    state?.other_fields.student?.tenth_details?.tenth_passout_year
      ? state?.other_fields.student?.tenth_details?.tenth_passout_year
      : ""
  );

  //Intermediate Information

  //12th Information

  const [twelthPercentage, setTwelthPercentage] = useState(
    state?.other_fields.student?.puc_details?.puc_mark_type
      ? state?.other_fields.student?.puc_details?.puc_mark_type
      : ""
  ); //radio button
  const [twelthInCGPA, setTwelthInCGPA] = useState(
    state?.other_fields.student?.puc_details?.puc_mark_type_value
      ? state?.other_fields.student?.puc_details?.puc_mark_type_value
      : ""
  );
  const [twelthInPercent, setTwelthInPercent] = useState(
    state?.other_fields.student?.puc_details?.puc_mark_type_value
      ? state?.other_fields.student?.puc_details?.puc_mark_type_value
      : ""
  );
  const [twelthYop, setTwelthYop] = useState(
    state?.other_fields.student?.puc_details?.twelfth_passout_year
      ? state?.other_fields.student?.puc_details?.twelfth_passout_year
      : ""
  );
  const [twelthYearGap, setTwelthYearGap] = useState(
    state?.other_fields.student?.puc_details?.twelfth_number_of_years
      ? state?.other_fields.student?.puc_details?.twelfth_number_of_years
      : ""
  );
  const [twelthYear, setTwelthYear] = useState(
    state?.other_fields.student?.puc_details?.do_you_have_academic_gap_upto_puc
      ? state?.other_fields.student?.puc_details
          ?.do_you_have_academic_gap_upto_puc
      : ""
  ); //radio button

  //ITI Information

  const [selectedItiStream, setSelectedItiStream] = useState(
    state?.other_fields.student?.iti_details?.iti_stream &&
      Object.keys(state.other_fields.student.iti_details.iti_stream).length > 0
      ? state?.other_fields.student?.iti_details?.iti_stream
      : null
  );
  const [itiStreamOptions, setItiStreamOptions] = useState([]);
  const [streamSearch, setStreamSarch] = useState("");

  const [itidistrictOption, setItiDistrictOption] = useState([]);
  const [itidistrictData, setItiDistrictData] = useState(
    state?.other_fields.student?.iti_details?.iti_college_district &&
      Object.keys(state.other_fields.student.iti_details.iti_college_district)
        .length > 0
      ? state?.other_fields.student?.iti_details?.iti_college_district
      : null
  );
  const [itidisSearch, setItiDisSearch] = useState("");

  const [itiCollegeOption, setItiCollegeOption] = useState([]);
  const [itiCollegeData, setItiCollegeData] = useState(
    state?.other_fields.student?.iti_details?.iti_college &&
      Object.keys(state.other_fields.student.iti_details.iti_college).length > 0
      ? state?.other_fields.student?.iti_details?.iti_college
      : null
  );
  const [itiColSearch, setItiColSearch] = useState("");

  const [otherItiStreamName, setOtherItiStreamName] = useState(
    state?.other_fields.student?.iti_details?.iti_other_stream_name
      ? state?.other_fields.student?.iti_details?.iti_other_stream_name
      : ""
  );
  const [otherItiState, setOtherItiState] = useState("");
  const [otherItiDistrict, setOtherItiDistrict] = useState(
    state?.other_fields.student?.iti_details?.iti_college_other_district_name
      ? state?.other_fields.student?.iti_details
          ?.iti_college_other_district_name
      : ""
  );
  const [otherItiUniversity, setOtherItiUniversity] = useState(
    state?.other_fields.student?.iti_details?.iti_other_board_name
      ? state?.other_fields.student?.iti_details?.iti_other_board_name
      : ""
  );
  const [otherItiCollege, setOtherItiCollege] = useState(
    state?.other_fields.student?.iti_details?.iti_other_college_name
      ? state?.other_fields.student?.iti_details?.iti_other_college_name
      : ""
  );
  const [itiYop, setItiYop] = useState(
    state?.other_fields.student?.iti_details?.iti_probable_year_of_passout
      ? state?.other_fields.student?.iti_details?.iti_probable_year_of_passout
      : ""
  );
  const [itiUniNumber, setItiUniNumber] = useState(
    state?.other_fields.student?.iti_details?.iti_university_registration_number
      ? state?.other_fields.student?.iti_details
          ?.iti_university_registration_number
      : ""
  );
  const [itiPercentage, setItiPercentage] = useState(
    state?.other_fields.student?.iti_details?.iti_mark_type
      ? state?.other_fields.student?.iti_details?.iti_mark_type
      : ""
  ); //radio button
  const [itiInCGPA, setItiInCGPA] = useState(
    state?.other_fields.student?.iti_details?.iti_mark_type_value
      ? state?.other_fields.student?.iti_details?.iti_mark_type_value
      : ""
  );
  const [itiInPercent, setItiInPercent] = useState(
    state?.other_fields.student?.iti_details?.iti_mark_type_value
      ? state?.other_fields.student?.iti_details?.iti_mark_type_value
      : ""
  );
  const [itiYearGap, setItiYearGap] = useState(
    state?.other_fields.student?.iti_details?.iti_number_of_years
      ? state?.other_fields.student?.iti_details?.iti_number_of_years
      : ""
  );
  const [itiYear, setItiYear] = useState(
    state?.other_fields.student?.iti_details?.do_you_have_academic_gap_upto_iti
      ? state?.other_fields.student?.iti_details
          ?.do_you_have_academic_gap_upto_iti
      : ""
  ); //radio button

  //Diploma Information

  const [selectedDipStream, setSelectedDipStream] = useState(
    state?.other_fields.student?.diploma_details?.diploma_stream &&
      Object.keys(state.other_fields.student.diploma_details.diploma_stream)
        .length > 0
      ? state?.other_fields.student?.diploma_details?.diploma_stream
      : null
  );
  const [dipStreamOptions, setDipStreamOptions] = useState([]);
  const [dipStreamSearch, setDipStreamSearch] = useState("");

  const [dipdistrictOption, setDipDistrictOption] = useState([]);
  const [dipdistrictData, setDipDistrictData] = useState(
    state?.other_fields.student?.diploma_details?.diploma_college_district &&
      Object.keys(
        state.other_fields.student.diploma_details.diploma_college_district
      ).length > 0
      ? state?.other_fields.student?.diploma_details?.diploma_college_district
      : null
  );
  const [dipdisSearch, setDipdisSearch] = useState("");

  const [dipCollegeOption, setDipCollegeOption] = useState([]);
  const [dipCollegeData, setDipCollegeData] = useState(
    state?.other_fields.student?.diploma_details?.diploma_college &&
      Object.keys(state.other_fields.student.diploma_details.diploma_college)
        .length > 0
      ? state?.other_fields.student?.diploma_details?.diploma_college
      : null
  );
  const [dipColSearch, setDipColSearch] = useState("");

  const [otherDipStreamName, setOtherDipStreamName] = useState(
    state?.other_fields.student?.diploma_details?.diploma_other_stream_name
      ? state?.other_fields.student?.diploma_details?.diploma_other_stream_name
      : ""
  );
  const [otherDipState, setOtherDipState] = useState("");
  const [otherDipDistrict, setOtherDipDistrict] = useState(
    state?.other_fields.student?.diploma_details
      ?.diploma_college_other_district_name
      ? state?.other_fields.student?.diploma_details
          ?.diploma_college_other_district_name
      : ""
  );
  const [otherDipUniversity, setOtherDipUniversity] = useState(
    state?.other_fields.student?.diploma_details?.diploma_other_board_name
      ? state?.other_fields.student?.diploma_details?.diploma_other_board_name
      : ""
  );
  const [otherDipCollege, setOtherDipCollege] = useState(
    state?.other_fields.student?.diploma_details?.diploma_other_college_name
      ? state?.other_fields.student?.diploma_details?.diploma_other_college_name
      : ""
  );
  const [dipYop, setDipYop] = useState(
    state?.other_fields.student?.diploma_details
      ?.diploma_probable_year_of_passout
      ? state?.other_fields.student?.diploma_details
          ?.diploma_probable_year_of_passout
      : ""
  );
  const [dipUniNumber, setDipUniNumber] = useState(
    state?.other_fields.student?.diploma_details
      ?.diploma_university_registration_number
      ? state?.other_fields.student?.diploma_details
          ?.diploma_university_registration_number
      : ""
  );
  const [dipPercentage, setDipPercentage] = useState(
    state?.other_fields.student?.diploma_details?.diploma_mark_type
      ? state?.other_fields.student?.diploma_details?.diploma_mark_type
      : ""
  ); //radio button
  const [dipInCGPA, setDipInCGPA] = useState(
    state?.other_fields.student?.diploma_details?.diploma_mark_type_value
      ? state?.other_fields.student?.diploma_details?.diploma_mark_type_value
      : ""
  );
  const [dipInPercent, setDipInPercent] = useState(
    state?.other_fields.student?.diploma_details?.diploma_mark_type_value
      ? state?.other_fields.student?.diploma_details?.diploma_mark_type_value
      : ""
  );
  const [dipYearGap, setDipYearGap] = useState(
    state?.other_fields.student?.diploma_details?.diploma_number_of_years
      ? state?.other_fields.student?.diploma_details?.diploma_number_of_years
      : ""
  );
  const [dipYear, setDipYear] = useState(
    state?.other_fields.student?.diploma_details
      ?.do_you_have_academic_gap_upto_diploma
      ? state?.other_fields.student?.diploma_details
          ?.do_you_have_academic_gap_upto_diploma
      : ""
  ); //radio button

  const [intermediate, setIntermediate] = useState(
    state?.other_fields.student?.specialization
      ? state.other_fields.student.specialization
      : ""
  );

  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };
    //Master Degree details post
    // if(dipInCGPA !== state?.other_fields.student?.diploma_details?.diploma_mark_type_value){
    //   formm
    // }
    formData.append("id", state.id);
    if (
      selectedMasterDegree &&
      selectedMasterDegree !==
        state?.other_fields.student?.master_degree_details?.master_degree_type
    ) {
      formData.append("master_degree_type_id", selectedMasterDegree.id);
    }
    // if (selectedMasterDegree.id === 0) {
    //   formData.append("is_other_master_degree_type", ""); //dropdown other
    // }
    if (
      otherMasterDegreeType !==
      state?.other_fields.student?.master_degree_details
        ?.other_master_degree_type
    ) {
      formData.append("other_master_degree_type", otherMasterDegreeType); //other text field
    }
    if (
      selectedMasterStream &&
      selectedMasterStream !==
        state?.other_fields.student?.master_degree_details?.master_degree_stream
    ) {
      formData.append("master_degree_stream_id", selectedMasterStream.id);
    }
    // if (selectedMasterStream.id === 0) {
    //   formData.append("master_degree_other_stream", ""); //dropdown other
    // }
    if (
      otherMasterDegreeStreamName !==
      state?.other_fields.student?.master_degree_details
        ?.master_degree_other_stream_name
    ) {
      formData.append(
        "master_degree_other_stream_name",
        otherMasterDegreeStreamName
      );
    }
    if (
      masterYop !==
      state?.other_fields.student?.master_degree_details
        ?.master_currently_pursuing_year_id
    ) {
      formData.append("master_degree_currently_pursuing_year", masterYop);
    }
    if (
      masterUniversity &&
      masterUniversity !==
        state?.other_fields.student?.master_degree_details
          ?.master_degree_university_registration_number
    ) {
      formData.append(
        "master_degree_university_registration_number",
        masterUniversity
      );
    }
    if (
      masterPercentage !==
      state?.other_fields.student?.master_degree_details
        ?.master_degree_mark_type
    ) {
      formData.append("master_degree_mark_type", masterPercentage);
    }
    if (
      (masterInCGPA || masterInPercent) !==
      state?.other_fields.student?.master_degree_details
        ?.master_degree_mark_type_value
    ) {
      formData.append(
        "master_degree_mark_type_value",
        masterPercentage === "1" ? masterInCGPA : masterInPercent
      );
    }
    if (
      selectedStates.masterState &&
      selectedStates.masterState !==
        state?.other_fields.student?.master_degree_details
          ?.master_degree_college_state
    ) {
      formData.append(
        "master_degree_college_state_id",
        selectedStates.masterState.id
      );
    }
    if (
      masterdistrictData &&
      masterdistrictData !==
        state?.other_fields.student?.master_degree_details
          ?.master_degree_college_district
    ) {
      formData.append(
        "master_degree_college_district_id",
        masterdistrictData.id
      );
    }
    if (
      otherMasterDegreeDistrict !==
      state?.other_fields.student?.master_degree_details
        ?.master_college_degree_other_district_name
    ) {
      formData.append(
        "master_college_degree_other_district_name",
        otherMasterDegreeDistrict
      );
    }
    if (
      selectedUniversity.masterUniversity &&
      selectedUniversity.masterUniversity !==
        state?.other_fields.student?.master_degree_details
          ?.master_degree_university
    ) {
      formData.append(
        "master_degree_university_id",
        selectedUniversity.masterUniversity.id
      );
    }
    if (selectedUniversity.masterUniversity === 0) {
      formData.append("master_degree_other_university", ""); //dropdown other
    }
    if (
      otherMasterDgreeUniversity !==
      state?.other_fields.student?.master_degree_details
        ?.master_degree_other_university_name
    ) {
      formData.append(
        "master_degree_other_university_name",
        otherMasterDgreeUniversity
      );
    }
    if (
      masterCollegeData &&
      masterCollegeData !==
        state?.other_fields.student?.master_degree_details
          ?.master_degree_college
    ) {
      formData.append("master_degree_college_id", masterCollegeData.id);
    }
    if (
      otherMasterDegreeCollege &&
      otherMasterDegreeCollege !==
        state?.other_fields.student?.master_degree_details
    ) {
      formData.append(
        "master_degree_other_college_name",
        otherMasterDegreeCollege
      );
    }
    if (
      masterYearGap &&
      masterYearGap !==
        state?.other_fields.student?.master_degree_details
          ?.master_degree_number_of_years
    ) {
      formData.append("do_you_have_academic_gap_in_master_degree", masterYear);
    }

    if (masterYear === "true") {
      formData.append(
        "master_degree_number_of_years",
        Number.parseInt(masterYearGap)
      );
    }
    if (
      masterBacklogs &&
      masterBacklogs !== state?.other_fields.student?.master_degree_details
    ) {
      formData.append("master_degree_active_backlogs", masterBacklogs);
    }

    //Degree details post
    if (
      selectedDegrees &&
      selectedDegrees !==
        state?.other_fields.student?.degree_details?.degree_type
    ) {
      formData.append("degree_type_id", selectedDegrees.id);
    }
    if (
      otherDegreeType !==
      state?.other_fields.student?.degree_details?.other_degree_type
    ) {
      formData.append("other_degree_type", otherDegreeType);
    }
    if (
      selectedStreams &&
      selectedStreams !==
        state?.other_fields.student?.degree_details?.degree_stream
    ) {
      formData.append("degree_stream_id", selectedStreams.id);
    }
    if (selectedStreams === 0) {
      formData.append("degree_other_stream", "");
    }
    if (
      otherDegreeStreamName !==
      state?.other_fields.student?.degree_details?.degree_other_stream_name
    ) {
      formData.append("degree_other_stream_name", otherDegreeStreamName);
    }
    if (
      degree_probable_year_of_passout !==
      state?.other_fields.student?.degree_details
        ?.degree_probable_year_of_passout
    ) {
      formData.append(
        "degree_probable_year_of_passout",
        degree_probable_year_of_passout
      );
    }
    if (
      degree_university_number !==
      state?.other_fields.student?.degree_details
        ?.degree_university_registration_number
    ) {
      formData.append(
        "degree_university_registration_number",
        degree_university_number
      );
    }
    if (
      degreePercentage !==
      state?.other_fields.student?.degree_details?.degree_mark_type
    ) {
      formData.append("degree_mark_type", degreePercentage); //radio button
    }
    if (
      (degreeInCGPA || degreeInPercent) !==
      state?.other_fields.student?.degree_details?.degree_mark_type_value
    ) {
      formData.append(
        "degree_mark_type_value",
        degreePercentage === "1" ? degreeInCGPA : degreeInPercent
      );
    }
    if (
      selectedStates.degreeState &&
      selectedStates.degreeState !==
        state?.other_fields.student?.degree_details?.degree_college_state
    ) {
      formData.append("degree_college_state_id", selectedStates.degreeState.id);
    }
    if (
      districtData &&
      districtData !==
        state?.other_fields.student?.degree_details?.degree_college_district
    ) {
      formData.append("degree_college_district_id", districtData.id);
    }
    if (
      otherDegreeDistrict !==
      state?.other_fields.student?.degree_details
        ?.degree_college_other_district_name
    ) {
      formData.append(
        "degree_college_other_district_name",
        otherDegreeDistrict
      );
    }
    if (
      selectedUniversity.degreeUniversity &&
      selectedUniversity.degreeUniversity !==
        state?.other_fields.student?.degree_details?.degree_university
    ) {
      formData.append(
        "degree_university_id",
        selectedUniversity.degreeUniversity.id
      );
    }
    if (selectedUniversity.degreeUniversity === 0) {
      formData.append("degree_other_university", "");
    }
    if (
      otherDegreeUniversity !==
      state?.other_fields.student?.degree_details?.degree_other_university_name
    ) {
      formData.append("degree_other_university_name", otherDegreeUniversity);
    }
    if (
      collegeDataa &&
      collegeDataa !==
        state?.other_fields.student?.degree_details?.degree_college
    ) {
      formData.append("degree_college_id", collegeDataa.id);
    }
    if (
      otherDegreeCollege !==
      state?.other_fields.student?.degree_details?.degree_other_college_name
    ) {
      formData.append("degree_other_college_name", otherDegreeCollege);
    }
    if (
      degreeYear !==
      state?.other_fields.student?.degree_details
        ?.do_you_have_academic_gap_in_degree
    ) {
      formData.append("do_you_have_academic_gap_in_degree", degreeYear);
    }
    if (degreeYear === "true") {
      formData.append("degree_number_of_years", Number.parseInt(degreeYearGap));
    }
    if (
      degreeBacklogs !==
      state?.other_fields.student?.degree_details?.degree_active_backlogs
    ) {
      formData.append("degree_active_backlogs", degreeBacklogs);
    }

    //10th Details Post
    if (
      tenthPercentage !==
      state?.other_fields.student?.tenth_details?.tenth_mark_type
    ) {
      formData.append("tenth_mark_type", tenthPercentage);
    }
    // if (
    //   (tenthInCGPA || tenthInPercent) !==
    //   state?.other_fields.student?.tenth_details?.tenth_mark_type_value
    // ) {
    //   formData.append(
    //     "tenth_mark_type_value",
    //     tenthPercentage === "1" ? tenthInCGPA : tenthInPercent
    //   );
    // }
    if (
      tenthInCGPA !==
      state?.other_fields.student?.tenth_details?.tenth_mark_type_value
    ) {
      formData.append(
        "tenth_mark_type_value",tenthInCGPA
      );
    }
    if (
     tenthInPercent !==
      state?.other_fields.student?.tenth_details?.tenth_mark_type_value
    ) {
      formData.append(
        "tenth_mark_type_value",tenthInPercent
      );
    }
    if (
      tenthYop !==
      state?.other_fields.student?.tenth_details?.tenth_passout_year
    ) {
      formData.append("tenth_passout_year", tenthYop);
    }

    //12th Details Post

    if (
      twelthPercentage !==
      state?.other_fields.student?.puc_details?.puc_mark_type
    ) {
      formData.append("puc_mark_type", twelthPercentage);
    }
    // if (
    //   (twelthInCGPA || twelthInPercent) !==
    //   state?.other_fields.student?.puc_details?.puc_mark_type_value
    // ) {
    //   formData.append(
    //     "puc_mark_type_value",
    //     twelthPercentage == "1" ? twelthInCGPA : twelthInPercent
    //   );
    // }
    if (
      twelthInCGPA !==
      state?.other_fields.student?.puc_details?.puc_mark_type_value
    ) {
      formData.append(
        "puc_mark_type_value",twelthInCGPA 
      );
    }
    if (
      twelthInPercent !==
      state?.other_fields.student?.puc_details?.puc_mark_type_value
    ) {
      formData.append(
        "puc_mark_type_value",twelthInPercent
      );
    }
    if (
      twelthYop !==
      state?.other_fields.student?.puc_details?.twelfth_passout_year
    ) {
      formData.append("twelfth_passout_year", twelthYop);
    }
    if (
      twelthYear !==
      state?.other_fields.student?.puc_details
        ?.do_you_have_academic_gap_upto_puc
    ) {
      formData.append("do_you_have_academic_gap_upto_puc", twelthYear);
    }
    if (twelthYear === "true") {
      formData.append("twelfth_number_of_years", twelthYearGap);
    }

    //ITI Details Post==============
    if (
      selectedItiStream &&
      selectedItiStream !== state?.other_fields.student?.iti_details?.iti_stream
    ) {
      formData.append("iti_stream_id", selectedItiStream.id);
    }
    if (
      otherItiStreamName !==
      state?.other_fields.student?.iti_details?.iti_other_stream_name
    ) {
      formData.append("iti_other_stream_name", otherItiStreamName);
    }
    if (
      itiYop !==
      state?.other_fields.student?.iti_details?.iti_probable_year_of_passout
    ) {
      formData.append("iti_probable_year_of_passout", itiYop);
    }
    if (
      itiUniNumber !==
      state?.other_fields.student?.iti_details
        ?.iti_university_registration_number
    ) {
      formData.append("iti_university_registration_number", itiUniNumber);
    }
    if (
      itiPercentage !== state?.other_fields.student?.iti_details?.iti_mark_type
    ) {
      formData.append("iti_mark_type", itiPercentage); //radio button
    }
    if (
      (itiInPercent || itiInCGPA) !==
      state?.other_fields.student?.iti_details?.iti_mark_type_value
    ) {
      formData.append(
        "iti_mark_type_value",
        itiPercentage === "1" ? itiInCGPA : itiInPercent
      );
    }
    if (
      itiYearGap !==
      state?.other_fields.student?.iti_details?.iti_number_of_years
    ) {
      if (itiYear === "true") {
        formData.append("iti_number_of_years", Number.parseInt(itiYearGap));
      }
    }
    if (
      itiYear !==
      state?.other_fields.student?.iti_details
        ?.do_you_have_academic_gap_upto_iti
    ) {
      formData.append("do_you_have_academic_gap_upto_iti", itiYear);
    }
    if (
      selectedStates.itiState &&
      selectedStates.itiState !==
        state?.other_fields.student?.iti_details?.iti_college_state
    ) {
      formData.append("iti_college_state_id", selectedStates.itiState.id);
    }
    if (
      itidistrictData &&
      itidistrictData !==
        state?.other_fields.student?.iti_details?.iti_college_district
    ) {
      formData.append("iti_college_district_id", itidistrictData.id);
    }
    if (
      otherItiDistrict !==
      state?.other_fields.student?.iti_details?.iti_college_other_district_name
    ) {
      formData.append("iti_college_other_district_name", otherItiDistrict);
    }
    if (
      itiCollegeData &&
      itiCollegeData !== state?.other_fields.student?.iti_details?.iti_college
    ) {
      formData.append("iti_college_id", itiCollegeData.id);
    }
    if (
      otherItiCollege !==
      state?.other_fields.student?.iti_details?.iti_other_college_name
    ) {
      formData.append("iti_other_college_name", otherItiCollege);
    }
    if (itiCollegeData === 0) {
      formData.append("iti_other_college", "");
    }
    if (
      selectedUniversity.itiUniversity &&
      selectedUniversity.itiUniversity !==
        state?.other_fields.student?.iti_details?.iti_board
    ) {
      formData.append("iti_board_id", selectedUniversity.itiUniversity.id);
    }
    if (
      otherItiUniversity !==
      state?.other_fields.student?.iti_details?.iti_other_board_name
    ) {
      formData.append("iti_other_board_name", otherItiUniversity);
    }

    //Diploma Details Post==============

    if (
      selectedDipStream &&
      selectedDipStream !==
        state?.other_fields.student?.diploma_details?.diploma_stream
    ) {
      formData.append("diploma_stream_id", selectedDipStream.id);
    }
    if (
      otherDipStreamName !==
      state?.other_fields.student?.diploma_details?.diploma_other_stream_name
    ) {
      formData.append("diploma_other_stream_name", otherDipStreamName);
    }
    if (
      dipYop !==
      state?.other_fields.student?.diploma_details
        ?.diploma_probable_year_of_passout
    ) {
      formData.append("diploma_probable_year_of_passout", dipYop);
    }
    if (
      dipUniNumber !==
      state?.other_fields.student?.diploma_details
        ?.diploma_university_registration_number
    ) {
      formData.append("diploma_university_registration_number", dipUniNumber);
    }
    if (
      dipPercentage !==
      state?.other_fields.student?.diploma_details?.diploma_mark_type
    ) {
      formData.append("diploma_mark_type", dipPercentage); //radio button
    }
    if (
      (dipInCGPA || dipInPercent) !==
      state?.other_fields.student?.diploma_details?.diploma_mark_type_value
    ) {
      formData.append(
        "diploma_mark_type_value",
        dipPercentage === "1" ? dipInCGPA : dipInPercent
      );
    }
    if (
      selectedStates.diplomaState &&
      selectedStates.diplomaState !==
        state?.other_fields.student?.diploma_details?.diploma_college_state
    ) {
      formData.append(
        "diploma_college_state_id",
        selectedStates.diplomaState.id
      );
    }
    if (
      dipdistrictData &&
      dipdistrictData !==
        state?.other_fields.student?.diploma_details?.diploma_college_district
    ) {
      formData.append("diploma_college_district_id", dipdistrictData.id);
    }
    if (
      otherDipDistrict !==
      state?.other_fields.student?.diploma_details
        ?.diploma_college_other_district_name
    ) {
      formData.append("diploma_college_other_district_name", otherDipDistrict);
    }
    if (
      selectedUniversity.diplomaUniversity &&
      selectedUniversity.diplomaUniversity !==
        state?.other_fields.student?.diploma_details?.diploma_board
    ) {
      formData.append(
        "diploma_board_id",
        selectedUniversity.diplomaUniversity.id
      );
    }
    if (selectedUniversity.diplomaUniversity === 0) {
      formData.append("diploma_other_board", "");
    }
    if (
      dipCollegeData &&
      dipCollegeData !==
        state?.other_fields.student?.diploma_details?.diploma_college
    ) {
      formData.append("diploma_college_id", dipCollegeData.id);
    }
    if (dipCollegeData === 0) {
      formData.append("diploma_other_college", "");
    }
    if (
      otherDipCollege !==
      state?.other_fields.student?.diploma_details?.diploma_other_college_name
    ) {
      formData.append("diploma_other_college_name", otherDipCollege);
    }
    if (
      otherDipUniversity !==
      state?.other_fields.student?.diploma_details?.diploma_other_board_name
    ) {
      formData.append("diploma_other_board_name", otherDipUniversity);
    }
    if (
      dipYear !==
      state?.other_fields.student?.diploma_details
        ?.do_you_have_academic_gap_upto_diploma
    ) {
      formData.append("do_you_have_academic_gap_upto_diploma", dipYear);
    }
    if (
      dipYearGap !==
      state?.other_fields.student?.diploma_details?.diploma_number_of_years
    ) {
      if (dipYear === "true") {
        formData.append("diploma_number_of_years", Number.parseInt(dipYearGap));
      }
    }

    await AxiosInstance.patch("/update_students_data_analyst", formData, config)
      .then((data) => {
        toast.success("Academic Details Updated Successfully");
        setTimeout(() => {
          // navigate("/");
          // navigate(-1);
          const pathTogo = filter
          ? `${path}?page=${page}&filter=${encodeSpl(filter)}`
          : `${path}?page=${page}`;
        window.sessionStorage.removeItem("filter");
        navigate(pathTogo);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Something Went Wrong");
        }
      });
  };

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        if (qualificationSearch !== "") {
          let qualificationData = await AxiosInstance.get(
            `/qualification?search_keyword=${qualificationSearch}&limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(
            finalQualificationData.filter(
              (val) => val.name !== "diploma" && val.name !== "iti"
            )
          );
        } else {
          let qualificationData = await AxiosInstance.get(
            `/qualification?limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(
            finalQualificationData.filter(
              (val) => val.name !== "diploma" && val.name !== "iti"
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData1();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      //Fetching ITI Stream
      try {
        if (streamSearch) {
          let allStreamData = await AxiosInstance.get(
            `/academic_streams?limit=10&search_keyword=${streamSearch}&qualification_type_ids=4`
          );
          let finalAllStreamData = allStreamData.data.data;
          setItiStreamOptions(finalAllStreamData);
        }
        if (dipStreamSearch) {
          let allStreamData1 = await AxiosInstance.get(
            `/academic_streams?limit=10&search_keyword=${dipStreamSearch}&qualification_type_ids=2`
          );
          let finalAllStreamData1 = allStreamData1.data.data;
          setDipStreamOptions(finalAllStreamData1);
        }
        //Fetching diploma Stream
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [streamSearch, dipStreamSearch]);

  // deg and stream
  useEffect(() => {
    let fetchData1 = async (e) => {
      try {
        // college Degree api
        if (degTerm !== "") {
          let degreeData = await AxiosInstance.get(
            `/degree_types?limit=10&search_keyword=${degTerm}&qualification_type_ids=1`
          );
          let finaldegreeData = degreeData.data.data;
          setDegreeOptions(finaldegreeData);
        } else {
          let degreeData = await AxiosInstance.get(
            `/degree_types?limit=10&qualification_type_ids=1`
          );
          let finaldegreeData = degreeData.data.data;
          setDegreeOptions(finaldegreeData);
        }

        if (masTerm !== "") {
          let masterdegreeData = await AxiosInstance.get(
            `/degree_types?limit=10&search_keyword=${masTerm}&qualification_type_ids=3`
          );
          let finalMasterdegreeData = masterdegreeData.data.data;
          setMasterDegreeOptions(finalMasterdegreeData);
        } else {
          let masterdegreeData = await AxiosInstance.get(
            `/degree_types?limit=10&qualification_type_ids=3`
          );
          let finalMasterdegreeData = masterdegreeData.data.data;
          setMasterDegreeOptions(finalMasterdegreeData);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData1();
  }, [degTerm, masTerm]);

  useEffect(() => {
    let fetchData2 = async (e) => {
      try {
        if (selectedDegrees && selectedDegrees.id) {
          if (streamTerm !== "") {
            let streamData = await AxiosInstance.get(
              `/academic_streams?limit=10&search_keyword=${streamTerm}&degree_id=${selectedDegrees.id}`
            );
            let finalstreamData = streamData.data.data;
            setStreamsOptions(finalstreamData);
          } else {
            let streamData = await AxiosInstance.get(
              `/academic_streams?limit=10&degree_id=${selectedDegrees.id}`
            );
            let finalstreamData = streamData.data.data;
            setStreamsOptions(finalstreamData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedDegrees !== 0) {
      fetchData2();
    }
  }, [streamTerm, selectedDegrees]);

  useEffect(() => {
    let fetchData = async () => {
      try {
        if (selectedMasterDegree && selectedMasterDegree.id) {
          if (masStreamTerm !== "") {
            let streamData1 = await AxiosInstance.get(
              `/academic_streams?limit=10&search_keyword=${masStreamTerm}&degree_id=${selectedMasterDegree.id}`
            );
            let finalstreamData1 = streamData1.data.data;
            setMasterStreamOptions(finalstreamData1);
          } else {
            let streamData1 = await AxiosInstance.get(
              `/academic_streams?limit=10&degree_id=${selectedMasterDegree.id}`
            );
            let finalstreamData1 = streamData1.data.data;
            setMasterStreamOptions(finalstreamData1);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedMasterDegree !== 0) {
      fetchData();
    }
  }, [masStreamTerm, selectedMasterDegree]);

  useEffect(() => {
    let fetchData = async () => {
      try {
        let data = await AxiosInstance.get(`/countrymini?name=india`);
        let finalData = data.data.data;
        setCountryMini(finalData);
        setCountryMiniId(finalData[0].id);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  //==================== state logics================================
  const [stateInfo, setStateInfo] = useState({
    masterState: null,
    degreeState: null,
    diplomaState: null,
    itiState: null,
    defaultState: [],
    degreeTerm: "",
    masterTerm: "",
    diplomaTerm: "",
    itiTerm: "",
  });
  const [stateList, setStateList] = useState([]);
  //storing selcted state
  const [selectedStates, setSelectedStates] = useState({
    degreeState:
      state?.other_fields.student?.degree_details?.degree_college_state &&
      Object.keys(
        state.other_fields.student.degree_details.degree_college_state
      ).length > 0
        ? state?.other_fields.student?.degree_details?.degree_college_state
        : null,
    masterState:
      state?.other_fields.student?.master_degree_details
        ?.master_degree_college_state &&
      Object.keys(
        state.other_fields.student.master_degree_details
          .master_degree_college_state
      ).length > 0
        ? state?.other_fields.student?.master_degree_details
            ?.master_degree_college_state
        : null,
    diplomaState:
      state?.other_fields.student?.diploma_details?.diploma_college_state &&
      Object.keys(
        state.other_fields.student.diploma_details.diploma_college_state
      ).length > 0
        ? state?.other_fields.student?.diploma_details?.diploma_college_state
        : null,
    itiState:
      state?.other_fields.student?.iti_details?.iti_college_state &&
      Object.keys(state.other_fields.student.iti_details.iti_college_state)
        .length > 0
        ? state?.other_fields.student?.iti_details?.iti_college_state
        : null,
  });
  //storing selected state
  useEffect(() => {
    let fetchData = async () => {
      try {
        let Cdata = await AxiosInstance.get(`/countrymini?name=india`);
        let finalData = Cdata.data.data;
        setCountryMini(finalData);
        setCountryMiniId(finalData[0].id);
        const { data } = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${finalData[0].id}`
        );
        setStateInfo({ ...stateInfo, defaultState: data.data });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const searchState = async (e) => {
    try {
      const { data } = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}&search_keyword=${e}`
      );
      setStateList(data.data ? data.data : []);
    } catch (error) {
      setStateList([]);
      console.log("error");
    }
  };
  useEffect(() => {}, [stateList]);

  useEffect(() => {
    let fetchData = async (e) => {
      //Fetching Master District
      try {
        if (selectedStates.masterState && selectedStates.masterState.id) {
          if (masDisTerm !== "") {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?search_keyword=${masDisTerm}&limit=10&state_id=${selectedStates.masterState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setMasterDistrictOption(finalcolgDistrictData);
          } else {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?limit=10&state_id=${selectedStates.masterState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setMasterDistrictOption(finalcolgDistrictData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedStates.masterState !== 0) {
      fetchData();
    }
  }, [masDisTerm, selectedStates.masterState]);

  useEffect(() => {
    let fetchData = async () => {
      try {
        //Fetching Degree District
        if (selectedStates.degreeState && selectedStates.degreeState.id) {
          if (searchDistrict !== "") {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?search_keyword=${searchDistrict}&limit=10&state_id=${selectedStates.degreeState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setDistrictOption(finalcolgDistrictData);
          } else {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?limit=10&state_id=${selectedStates.degreeState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setDistrictOption(finalcolgDistrictData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedStates.degreeState) {
      fetchData();
    }
  }, [searchDistrict, selectedStates.degreeState]);

  useEffect(() => {
    let fetchData = async () => {
      try {
        //Fetching ITI District
        if (selectedStates.itiState && selectedStates.itiState.id) {
          if (itidisSearch !== "") {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?search_keyword=${itidisSearch}&limit=10&state_id=${selectedStates.itiState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setItiDistrictOption(finalcolgDistrictData);
          } else {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?limit=10&state_id=${selectedStates.itiState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setItiDistrictOption(finalcolgDistrictData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedStates.itiState !== 0) {
      fetchData();
    }
  }, [itidisSearch, selectedStates.itiState]);

  useEffect(() => {
    let fetchData = async () => {
      try {
        //Fetching Diploma District
        if (selectedStates.diplomaState && selectedStates.diplomaState.id) {
          if (dipdisSearch !== "") {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?search_keyword=${dipdisSearch}&limit=10&state_id=${selectedStates.diplomaState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setDipDistrictOption(finalcolgDistrictData);
          } else {
            let colgDistrictData = await AxiosInstance.get(
              `/district_mini?limit=10&state_id=${selectedStates.diplomaState.id}`
            );
            let finalcolgDistrictData = colgDistrictData.data.data;
            setDipDistrictOption(finalcolgDistrictData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedStates.diplomaState !== 0) {
      fetchData();
    }
  }, [dipdisSearch, selectedStates.diplomaState]);

  //============================ state logics=====================================

  //============================ university logics=====================================
  const [universityInfo, setUniversityInfo] = useState({
    masterUniversity: null,
    degreeUniversity: null,
    diplomaUniversity: null,
    itiUniversity: null,
    defaultUniversity: [],
    degreeTerm: "",
    masterTerm: "",
    diplomaTerm: "",
    itiTerm: "",
  });
  const [universityList, setUniversityList] = useState([]);
  //storing selcted University
  const [selectedUniversity, setSelectedUniversity] = useState({
    degreeUniversity:
      state?.other_fields.student?.degree_details?.degree_university &&
      Object.keys(state.other_fields.student.degree_details.degree_university)
        .length > 0
        ? state?.other_fields.student?.degree_details?.degree_university
        : null,
    masterUniversity:
      state?.other_fields.student?.master_degree_details
        ?.master_degree_university &&
      Object.keys(
        state.other_fields.student.master_degree_details
          .master_degree_university
      ).length > 0
        ? state?.other_fields.student?.master_degree_details
            ?.master_degree_university
        : null,
    diplomaUniversity:
      state?.other_fields.student?.diploma_details?.diploma_board &&
      Object.keys(state.other_fields.student.diploma_details.diploma_board)
        .length > 0
        ? state?.other_fields.student?.diploma_details?.diploma_board
        : null,
    itiUniversity:
      state?.other_fields.student?.iti_details?.iti_board &&
      Object.keys(state.other_fields.student.iti_details.iti_board).length > 0
        ? state?.other_fields.student?.iti_details?.iti_board
        : null,
  });
  //storing selected University
  useEffect(() => {
    let fetchData = async () => {
      try {
        // `/university?search_keyword=${searchUniversity}&limit=10&state_id=${stateData.id}&is_other_fields=false`
        const { data } = await AxiosInstance.get(`/university?limit=10`);
        setUniversityInfo({ ...universityInfo, defaultUniversity: data.data });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const searchUniversity = async (e) => {
    try {
      const { data } = await AxiosInstance.get(
        `/university?limit=10&search_keyword=${e}`
      );
      setUniversityList(data.data ? data.data : []);
    } catch (error) {
      setUniversityList([]);
      console.log("error");
    }
  };
  useEffect(() => {}, [universityList]);

  //===============college Fetching====================

  //!for college filter from district and university In MasterDegree============
  //masters college based on qaulification_type_id (district_id=${masterdistrictData.id})

  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (
          selectedUniversity.masterUniversity &&
          selectedUniversity.masterUniversity.id
        ) {
          if (masterCollegeSearch !== "") {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?search_keyword=${masterCollegeSearch}&limit=10&qualification_type_ids=3&university_id=${selectedUniversity.masterUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setMasterCollegeOption(finalCollegeStateData);
          } else {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?limit=10&qualification_type_ids=3&university_id=${selectedUniversity.masterUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setMasterCollegeOption(finalCollegeStateData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedUniversity.masterUniversity !== 0) {
      fetchData();
    }
  }, [masterCollegeSearch, selectedUniversity.masterUniversity]);

  //!for college filter from district and university In Degree============

  // college_mini based on qulification_type_id (district_id=${districtData.id})

  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (
          selectedUniversity.degreeUniversity &&
          selectedUniversity.degreeUniversity.id
        ) {
          if (searchCollege !== "") {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?search_keyword=${searchCollege}&limit=10&qualification_type_ids=1&university_id=${selectedUniversity.degreeUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setCollegeOption(finalCollegeStateData);
          } else {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?limit=10&qualification_type_ids=1&university_id=${selectedUniversity.degreeUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setCollegeOption(finalCollegeStateData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedUniversity.degreeUniversity !== 0) {
      fetchData();
    }
  }, [searchCollege, selectedUniversity.degreeUniversity]);

  //!for college filter from district and university In ITI============

  // ITI colleges based on the qualificatin_type_id (district_id=${itidistrictData.id})
  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (
          selectedUniversity.itiUniversity &&
          selectedUniversity.itiUniversity.id
        ) {
          if (itiColSearch !== "") {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?search_keyword=${itiColSearch}&limit=10&qualification_type_ids=4&university_id=${selectedUniversity.itiUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setItiCollegeOption(finalCollegeStateData);
          } else {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?limit=10&qualification_type_ids=4&university_id=${selectedUniversity.itiUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setItiCollegeOption(finalCollegeStateData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedUniversity.itiUniversity !== 0) {
      fetchData();
    }
  }, [itiColSearch, selectedUniversity.itiUniversity]);

  //!for college filter from district and university In Diploma============

  // Diploma colleges based on the qualification_type_id (district_id=${dipdistrictData.id})

  useEffect(() => {
    let fetchData = async (e) => {
      try {
        if (
          selectedUniversity.diplomaUniversity &&
          selectedUniversity.diplomaUniversity.id
        ) {
          if (dipColSearch !== "") {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?search_keyword=${dipColSearch}&limit=10&qualification_type_ids=2&university_id=${selectedUniversity.diplomaUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setDipCollegeOption(finalCollegeStateData);
          } else {
            let collegeStateData = await AxiosInstance.get(
              `/college_mini?limit=10&qualification_type_ids=2&university_id=${selectedUniversity.diplomaUniversity.id}`
            );
            let finalCollegeStateData = collegeStateData.data.data;
            setDipCollegeOption(finalCollegeStateData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedUniversity.diplomaUniversity !== 0) {
      fetchData();
    }
  }, [dipColSearch, selectedUniversity.diplomaUniversity]);
  //============================ university logics=====================================

  //category degrre
  return (
    <section style={{ margin: "5% 0" }}>
      <Container component="main" maxWidth="80%">
        <Paper
          variant="outlined"
          md={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <div
            component="h1"
            variant="h5"
            style={{
              padding: "10px",
              fontSize: "22px",
              color: "white",
              backgroundColor: "#081F37",
              // backgroundColor: "rgb(251,116,62)",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Academic Details Form
          </div>

          <Container component="main" maxWidth="80%">
            <CssBaseline />
            <Box sx={{ m: 5 }}>
              <form
                onSubmit={handleSubmit}
                style={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container lg={12} spacing={3}>
                  <Grid item sm={3}>
                    <FormControl fullWidth>
                      <Grid item lg={10}>
                        <Autocomplete
                          size="small"
                          options={qualificationOptions}
                          value={selectedQualification}
                          name="qualification"
                          onChange={(event, newStream, x, y) => {
                            setSelectedQualification(newStream);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Qualification"
                              variant="outlined"
                              onChange={async (e) => {
                                const term = e.target.value;
                                if (term.length > 2) {
                                  const { data } = await AxiosInstance.get(
                                    `/qualification?search_keyword=${term}&limit=10`
                                  );
                                  setQualificationOptions(data.data);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </FormControl>
                  </Grid>
                  <p
                    style={{
                      marginLeft: "55%",
                      color: "#D24545",
                      fontWeight: "bold",
                    }}
                  >
                    Mobile Number : {state.mobiles}
                  </p>
                  {selectedQualification !== null ? (
                    selectedQualification.name === "masters" ? (
                      <>
                        <Grid
                          style={{
                            width: "100%",
                            height: "1.5px",
                            borderBottom: "1px solid lightGrey",
                            margin: "1% 0 1% 0",
                          }}
                        ></Grid>

                        {/* //!Master Degree Information Starts */}
                        <div style={{ width: "100%" }}>
                          <Typography
                            component="h5"
                            variant="h6"
                            style={{
                              fontSize: "20px",
                              // color: "grey",
                              color: "rgb(248,151,28)",
                              backgroundColor: "#efefef",
                              padding: "0 0 0 10px",
                            }}
                          >
                            Master Degree Information
                          </Typography>
                        </div>
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "1%",
                          }}
                        >
                          <Box>
                            <Grid container spacing={2}>
                              {!otherMasterDegreeType && (
                                <Grid item md={3}>
                                  <Item
                                    onChange={(e) => {
                                      setMasTerm(e.target.value);
                                    }}
                                  >
                                    <Autocomplete
                                      options={masterDegreeOptions}
                                      value={selectedMasterDegree}
                                      name="degree_name"
                                      onChange={(event, newdegree) => {
                                        setSelectedMasterDegree(newdegree);
                                        setSelectedmasterStream(null);
                                        setOtherMasterDegreeType("");
                                        setOtherMasterDegreeStreamName("");
                                      }}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Search Master Degree"
                                          variant="outlined"
                                          required
                                        />
                                      )}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              {(selectedMasterDegree?.id === 0 ||
                                otherMasterDegreeType) && (
                                <Grid item md={3}>
                                  <Item>
                                    <TextField
                                      name="otherMasterDegreeType"
                                      fullWidth
                                      required
                                      id="otherMasterDegreeType"
                                      label="Other Master Degree Type"
                                      value={otherMasterDegreeType}
                                      onChange={(e) => {
                                        setOtherMasterDegreeType(
                                          e.target.value
                                        );
                                      }}
                                      className={classes.root}
                                    />
                                  </Item>
                                </Grid>
                              )}
                              {!otherMasterDegreeStreamName && (
                                <Grid item md={3}>
                                  <Item
                                    onChange={(e) => {
                                      setMasStreamTerm(e.target.value);
                                    }}
                                  >
                                    <Autocomplete
                                      options={masterStreamOptions}
                                      value={selectedMasterStream}
                                      name="stream_name"
                                      onChange={(event, newstream) => {
                                        setSelectedmasterStream(newstream);
                                        setOtherMasterDegreeStreamName("");
                                      }}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Search Master Stream"
                                          variant="outlined"
                                          required
                                        />
                                      )}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              {(selectedMasterStream?.id === 0 ||
                                otherMasterDegreeStreamName) && (
                                <Grid item md={3}>
                                  <Item>
                                    <TextField
                                      name="otherMasterDegreeStreamName"
                                      fullWidth
                                      required
                                      id="otherMasterDegreeStreamName"
                                      label="Other Master Degree Stream"
                                      value={otherMasterDegreeStreamName}
                                      onChange={(e) => {
                                        setOtherMasterDegreeStreamName(
                                          e.target.value
                                        );
                                      }}
                                      className={classes.root}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    label="Probable Year Of Passout"
                                    type="tel"
                                    fullWidth
                                    onKeyPress={preventMinus}
                                    onPaste={preventPasteNegative}
                                    inputProps={{ minLength: 4, maxLength: 4 }}
                                    name="masterYop"
                                    value={masterYop}
                                    required
                                    onChange={(e) =>
                                      setMasterYop(e.target.value)
                                    }
                                  />
                                </Item>
                              </Grid>
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    label="University Register Number / Exam Roll No"
                                    type="tel"
                                    fullWidth
                                    name="masterUniversity"
                                    value={masterUniversity}
                                    onChange={(e) =>
                                      setMasterUniversity(e.target.value)
                                    }
                                  />
                                </Item>
                              </Grid>

                              <Grid item md={3}>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  CGPA / Percentage
                                </FormLabel>

                                <Item>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="masterPercentage"
                                    value={masterPercentage}
                                    onChange={(e) => {
                                      setMasterPercentage(e.target.value);
                                    }}
                                    style={{ marginTop: "-1%" }}
                                  >
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio />}
                                      label="CGPA"
                                      name="masterPercentage"
                                    />
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio />}
                                      label="Percentage"
                                      name="masterPercentage"
                                      style={{ marginLeft: "10%" }}
                                    />
                                  </RadioGroup>
                                </Item>
                              </Grid>
                              <Grid
                                item
                                md={3}
                                style={{
                                  display:
                                    masterPercentage === "1" ? "block" : "none",
                                }}
                              >
                                <Item>
                                  <TextField
                                    name="masterInCGPA"
                                    type="tel"
                                    fullWidth
                                    id="masterInCGPA"
                                    label="CGPA"
                                    value={masterInCGPA}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (/^\d*\.?\d*$/.test(inputValue)) {
                                        setMasterInCGPA(e.target.value);
                                      }
                                    }}
                                    onInput={(e) => {
                                      e.preventDefault();
                                      const inputValue = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      setMasterInCGPA(inputValue);
                                    }}
                                    inputProps={{ minLength: 1, maxLength: 4 }}
                                  />
                                </Item>
                              </Grid>

                              <Grid
                                item
                                md={3}
                                style={{
                                  display:
                                    masterPercentage === "2" ? "block" : "none",
                                }}
                              >
                                <Item>
                                  {" "}
                                  <TextField
                                    name="masterInPercent"
                                    type="tel"
                                    // reduired
                                    fullWidth
                                    id="masterInPercent"
                                    label="Percentage"
                                    value={masterInPercent}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (/^\d*\.?\d*$/.test(inputValue)) {
                                        setMasterInPercent(e.target.value);
                                      }
                                    }}
                                    onInput={(e) => {
                                      e.preventDefault();
                                      const inputValue = e.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                      setMasterInPercent(inputValue);
                                    }}
                                    inputProps={{ minLength: 2, maxLength: 5 }}
                                  />
                                </Item>
                              </Grid>
                              {!otherMasterDegreeState && (
                                <Grid item md={3}>
                                  <Item>
                                    <Autocomplete
                                      options={
                                        stateInfo.masterTerm &&
                                        stateInfo.masterTerm.length > 1
                                          ? stateList
                                          : stateInfo.defaultState
                                      }
                                      value={selectedStates.masterState}
                                      name="masterState"
                                      onChange={(event, newData_xy, action) => {
                                        if (action === "clear") {
                                          setStateInfo({
                                            ...stateInfo,
                                            masterTerm: "",
                                          });
                                        }
                                        setSelectedStates({
                                          ...selectedStates,
                                          masterState: newData_xy,
                                        });
                                        setOtherMasterDegreeState("");
                                        setMasterDistrictData(null);
                                      }}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Master Degree College - State"
                                          variant="outlined"
                                          value={stateInfo.masterTerm}
                                          onBlur={() => {
                                            setStateInfo({
                                              ...stateInfo,
                                              masterTerm: "",
                                            });
                                          }}
                                          required
                                          onChange={async (e) => {
                                            setStateInfo({
                                              ...stateInfo,
                                              masterTerm: e.target.value,
                                            });
                                            await searchState(e.target.value);
                                          }}
                                        />
                                      )}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              {(selectedStates.masterState?.id === 0 ||
                                otherMasterDegreeState) && (
                                <Grid item md={3}>
                                  <Item>
                                    <TextField
                                      name="otherMasterDegreeState"
                                      fullWidth
                                      required
                                      id="otherMasterDegreeState"
                                      label="Other Master Degree College - State"
                                      value={otherMasterDegreeState}
                                      onChange={(e) => {
                                        setOtherMasterDegreeState(
                                          e.target.value
                                        );
                                      }}
                                      className={classes.root}
                                    />
                                  </Item>
                                </Grid>
                              )}
                              {!otherMasterDegreeDistrict && (
                                <Grid item md={3}>
                                  <Item
                                    onChange={(e) => {
                                      setMasDisTerm(e.target.value);
                                    }}
                                  >
                                    <Autocomplete
                                      options={masterdistrictOption}
                                      value={masterdistrictData}
                                      name="masterdistrictOption"
                                      onChange={(event, newData_xy) => {
                                        setMasterDistrictData(newData_xy);
                                      }}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Master Degree College - District"
                                          variant="outlined"
                                          required
                                        />
                                      )}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              {(masterdistrictData?.id === 0 ||
                                otherMasterDegreeDistrict) && (
                                <Grid item md={3}>
                                  <Item>
                                    <TextField
                                      name="otherMasterDegreeDistrict"
                                      fullWidth
                                      required
                                      id="otherMasterDegreeDistrict"
                                      label="Other Master Degree College - District"
                                      value={otherMasterDegreeDistrict}
                                      onChange={(e) => {
                                        setOtherMasterDegreeDistrict(
                                          e.target.value
                                        );
                                      }}
                                      className={classes.root}
                                    />
                                  </Item>
                                </Grid>
                              )}
                              {!otherMasterDgreeUniversity && (
                                <Grid item md={3}>
                                  <Item>
                                    <Autocomplete
                                      options={
                                        universityInfo.masterTerm &&
                                        universityInfo.masterTerm.length > 1
                                          ? universityList
                                          : universityInfo.defaultUniversity
                                      }
                                      value={
                                        selectedUniversity.masterUniversity
                                      }
                                      name="master_university"
                                      onChange={(event, newData_xy, action) => {
                                        if (action === "clear") {
                                          setUniversityInfo({
                                            ...universityInfo,
                                            masterTerm: "",
                                          });
                                        }
                                        setSelectedUniversity({
                                          ...selectedUniversity,
                                          masterUniversity: newData_xy,
                                        });
                                        setMasterCollegeData(null);
                                        setOtherMasterDegreeCollege("");
                                      }}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Master Degree University"
                                          variant="outlined"
                                          value={universityInfo.masterTerm}
                                          onBlur={() => {
                                            setUniversityInfo({
                                              ...universityInfo,
                                              masterTerm: "",
                                            });
                                          }}
                                          required
                                          onChange={async (e) => {
                                            setUniversityInfo({
                                              ...universityInfo,
                                              masterTerm: e.target.value,
                                            });
                                            await searchUniversity(
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              {(selectedUniversity.masterUniversity?.id === 0 ||
                                otherMasterDgreeUniversity) && (
                                <Grid item md={3}>
                                  <Item>
                                    <TextField
                                      name="otherMasterDgreeUniversity"
                                      fullWidth
                                      required
                                      id="otherMasterDgreeUniversity"
                                      label="Other Master Degree University"
                                      value={otherMasterDgreeUniversity}
                                      onChange={(e) => {
                                        setOtherMasterDgreeUniversity(
                                          e.target.value
                                        );
                                      }}
                                      className={classes.root}
                                    />
                                  </Item>
                                </Grid>
                              )}
                              {!otherMasterDegreeCollege && (
                                <Grid item md={3}>
                                  <Item
                                    onChange={(e) => {
                                      setMasterCollegeSearch(e.target.value);
                                    }}
                                  >
                                    <Autocomplete
                                      options={masterCollegeOption}
                                      value={masterCollegeData}
                                      name="masterCollegeOption"
                                      onChange={(event, newData_xyc) => {
                                        setMasterCollegeData(newData_xyc);
                                      }}
                                      getOptionLabel={(option) => option.name}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Master Degree College"
                                          variant="outlined"
                                          required
                                        />
                                      )}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              {(masterCollegeData?.id === 0 ||
                                otherMasterDegreeCollege) && (
                                <Grid item md={3}>
                                  <Item>
                                    <TextField
                                      name="otherMasterDegreeCollege"
                                      fullWidth
                                      required
                                      id="otherMasterDegreeCollege"
                                      label="Other Master Degree College"
                                      value={otherMasterDegreeCollege}
                                      onChange={(e) => {
                                        setOtherMasterDegreeCollege(
                                          e.target.value
                                        );
                                      }}
                                      className={classes.root}
                                    />
                                  </Item>
                                </Grid>
                              )}

                              <Grid item md={3}>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                  Any Academic Year Gap Till Masters
                                </FormLabel>
                                <Item>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="masterYear"
                                    value={masterYear}
                                    onChange={(e) => {
                                      setMasterYear(e.target.value);
                                    }}
                                    style={{ marginTop: "-2%" }}
                                  >
                                    <FormControlLabel
                                      value="true"
                                      control={<Radio />}
                                      label="YES"
                                      name="masterYear"
                                    />
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio />}
                                      label="NO"
                                      name="masterYear"
                                      style={{ marginLeft: "10%" }}
                                    />
                                  </RadioGroup>
                                </Item>
                              </Grid>
                              <Grid
                                item
                                md={3}
                                style={{
                                  display:
                                    masterYear === "true" ? "block" : "none",
                                }}
                              >
                                <Item>
                                  <TextField
                                    name="masterYearGap"
                                    type="tel"
                                    fullWidth
                                    id="masterYearGap"
                                    label="Number Of Years"
                                    value={masterYearGap}
                                    onChange={(e) => {
                                      setMasterYearGap(e.target.value);
                                    }}
                                    onKeyPress={preventMinus}
                                    onPaste={preventPasteNegative}
                                    inputProps={{ minLength: 1, maxLength: 2 }}
                                  />
                                </Item>
                              </Grid>
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    label="Active Backlogs In Master Degree"
                                    type="tel"
                                    fullWidth
                                    onKeyPress={preventMinus}
                                    onPaste={preventPasteNegative}
                                    inputProps={{ minLength: 1, maxLength: 2 }}
                                    name="masterBacklogs"
                                    value={masterBacklogs}
                                    onChange={(e) =>
                                      setMasterBacklogs(e.target.value)
                                    }
                                  />
                                </Item>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </>
                    ) : null
                  ) : null}

                  {selectedQualification !== null ? (
                    selectedQualification.name === "degree" ||
                    selectedQualification.name === "masters" ? (
                      <>
                        <Grid
                          style={{
                            width: "100%",
                            height: "1.5px",
                            borderBottom: "1px solid lightGrey",
                            margin: "1% 0 1% 0",
                          }}
                        ></Grid>
                        {/* //! Degree Information Starts */}
                        <div style={{ width: "100%" }}>
                          <Typography
                            component="h5"
                            variant="h6"
                            style={{
                              fontSize: "20px",
                              // color: "grey",
                              color: "rgb(248,151,28)",
                              backgroundColor: "#efefef",
                              padding: "0 0 0 10px",
                            }}
                          >
                            Degree Information
                          </Typography>
                        </div>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "0.5%" }}
                        >
                          {!otherDegreeType && (
                            <Grid item md={3}>
                              <Item>
                                <Autocomplete
                                  options={degreeOptions}
                                  value={selectedDegrees}
                                  name="degree_name"
                                  onChange={(event, newdegree) => {
                                    setselectedDegrees(newdegree);
                                    setOtherDegreeType("");
                                    setselectedStreams(null);
                                    setOtherDegreeStreamName("");
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Search Degree"
                                      onChange={(e) => {
                                        setDegTerm(e.target.value);
                                      }}
                                      variant="outlined"
                                      required
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          )}

                          {(selectedDegrees?.id === 0 || otherDegreeType) && (
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  name="otherDegreeType"
                                  fullWidth
                                  required
                                  id="otherDegreeType"
                                  label="Other Degree Type"
                                  value={otherDegreeType}
                                  onChange={(e) => {
                                    setOtherDegreeType(e.target.value);
                                  }}
                                  className={classes.root}
                                />
                              </Item>
                            </Grid>
                          )}
                          {!otherDegreeStreamName && (
                            <Grid item md={3}>
                              <Item
                                onChange={(e) => {
                                  setStreamTerm(e.target.value);
                                }}
                              >
                                <Autocomplete
                                  options={streamsOptions}
                                  value={selectedStreams}
                                  name="stream_name"
                                  onChange={(event, newstream) => {
                                    setselectedStreams(newstream);
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Search Stream"
                                      variant="outlined"
                                      required
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          )}

                          {(selectedStreams?.id === 0 ||
                            otherDegreeStreamName) && (
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  name="otherDegreeStreamName"
                                  fullWidth
                                  required
                                  id="otherDegreeStreamName"
                                  label="Other Degree Stream"
                                  value={otherDegreeStreamName}
                                  onChange={(e) => {
                                    setOtherDegreeStreamName(e.target.value);
                                  }}
                                  className={classes.root}
                                />
                              </Item>
                            </Grid>
                          )}
                          <Grid item md={3}>
                            <Item>
                              <TextField
                                label="Probable Year Of Passout"
                                type="tel"
                                fullWidth
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 4, maxLength: 4 }}
                                name="degree_probable_year_of_passout"
                                value={degree_probable_year_of_passout}
                                required
                                onChange={(e) =>
                                  setDegree_probable_year_of_passout(
                                    e.target.value
                                  )
                                }
                              />
                            </Item>
                          </Grid>
                          <Grid item md={3}>
                            <Item>
                              <TextField
                                label="University Register Number / Exam Roll No"
                                type="tel"
                                fullWidth
                                // onKeyPress={preventMinus}
                                // onPaste={preventPasteNegative}
                                // inputProps={{ minLength: 4, maxLength: 4 }}
                                name="degree_university_number"
                                value={degree_university_number}
                                onChange={(e) =>
                                  setDegree_university_number(e.target.value)
                                }
                              />
                            </Item>
                          </Grid>

                          <Grid item md={3}>
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              style={{ marginLeft: "2%" }}
                            >
                              CGPA / Percentage
                            </FormLabel>

                            <Item>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="degreePercentage"
                                value={degreePercentage}
                                onChange={(e) => {
                                  setDegreePercentage(e.target.value);
                                }}
                                style={{ marginTop: "-1%" }}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="CGPA"
                                  name="degreePercentage"
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label="Percentage"
                                  name="degreePercentage"
                                  style={{ marginLeft: "10%" }}
                                />
                              </RadioGroup>
                            </Item>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            style={{
                              display:
                                degreePercentage === "1" ? "block" : "none",
                            }}
                          >
                            <Item>
                              <TextField
                                name="degreeInCGPA"
                                type="tel"
                                fullWidth
                                id="degreeInCGPA"
                                label="CGPA"
                                value={degreeInCGPA}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^\d*\.?\d*$/.test(inputValue)) {
                                    setDegreeInCGPA(e.target.value);
                                  }
                                }}
                                onInput={(e) => {
                                  e.preventDefault();
                                  const inputValue = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  setDegreeInCGPA(inputValue);
                                }}
                                inputProps={{ minLength: 1, maxLength: 4 }}
                              />
                            </Item>
                          </Grid>

                          <Grid
                            item
                            md={3}
                            style={{
                              display:
                                degreePercentage === "2" ? "block" : "none",
                            }}
                          >
                            <Item>
                              {" "}
                              <TextField
                                name="degreeInPercent"
                                type="tel"
                                //reduired
                                fullWidth
                                id="degreeInPercent"
                                label="Percentage"
                                value={degreeInPercent}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (/^\d*\.?\d*$/.test(inputValue)) {
                                    setDegreeInPercent(e.target.value);
                                  }
                                }}
                                onInput={(e) => {
                                  e.preventDefault();
                                  const inputValue = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  setDegreeInPercent(inputValue);
                                }}
                                inputProps={{ minLength: 2, maxLength: 5 }}
                              />
                            </Item>
                          </Grid>
                          {!otherDegreeState && (
                            <Grid item md={3}>
                              <Item>
                                <Autocomplete
                                  options={
                                    stateInfo.degreeTerm &&
                                    stateInfo.degreeTerm.length > 1
                                      ? stateList
                                      : stateInfo.defaultState
                                  }
                                  value={selectedStates.degreeState}
                                  name="stateOption"
                                  onChange={(event, newData_xy, action) => {
                                    if (action === "clear") {
                                      setStateInfo({
                                        ...stateInfo,
                                        degreeTerm: "",
                                      });
                                    }
                                    setSelectedStates({
                                      ...selectedStates,
                                      degreeState: newData_xy,
                                    });
                                    setOtherDegreeState("");
                                    setDistrictData(null);
                                    setOtherDegreeDistrict("");
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Degree College - State"
                                      variant="outlined"
                                      value={stateInfo.degreeTerm}
                                      onBlur={() => {
                                        setStateInfo({
                                          ...stateInfo,
                                          degreeTerm: "",
                                        });
                                      }}
                                      required
                                      onChange={async (e) => {
                                        setStateInfo({
                                          ...stateInfo,
                                          degreeTerm: e.target.value,
                                        });
                                        await searchState(e.target.value);
                                      }}
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          )}

                          {(selectedStates.degreeState?.id === 0 ||
                            otherDegreeState) && (
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  name="otherDegreeState"
                                  fullWidth
                                  required
                                  id="otherDegreeState"
                                  label="Other Degree College - State"
                                  value={otherDegreeState}
                                  onChange={(e) => {
                                    setOtherDegreeState(e.target.value);
                                  }}
                                  className={classes.root}
                                />
                              </Item>
                            </Grid>
                          )}
                          {!otherDegreeDistrict && (
                            <Grid item md={3}>
                              <Item
                                onChange={(e) => {
                                  setSearchDistrict(e.target.value);
                                }}
                              >
                                <Autocomplete
                                  options={districtOption}
                                  value={districtData}
                                  name="districtOption"
                                  onChange={(event, newData_xy) => {
                                    setDistrictData(newData_xy);
                                    setOtherDegreeDistrict("");
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Degree College - District"
                                      variant="outlined"
                                      required
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          )}

                          {(districtData?.id === 0 || otherDegreeDistrict) && (
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  name="otherDegreeDistrict"
                                  fullWidth
                                  id="otherDegreeDistrict"
                                  label="Other Degree College - District"
                                  value={otherDegreeDistrict}
                                  onChange={(e) => {
                                    setOtherDegreeDistrict(e.target.value);
                                  }}
                                  className={classes.root}
                                />
                              </Item>
                            </Grid>
                          )}
                          {!otherDegreeUniversity && (
                            <Grid item md={3}>
                              <Item>
                                <Autocomplete
                                  options={
                                    universityInfo.degreeTerm &&
                                    universityInfo.degreeTerm.length > 1
                                      ? universityList
                                      : universityInfo.defaultUniversity
                                  }
                                  value={selectedUniversity.degreeUniversity}
                                  name="degree_university"
                                  onChange={(event, newData_xy, action) => {
                                    if (action === "clear") {
                                      setUniversityInfo({
                                        ...universityInfo,
                                        degreeTerm: "",
                                      });
                                    }
                                    setSelectedUniversity({
                                      ...selectedUniversity,
                                      degreeUniversity: newData_xy,
                                    });
                                    setOtherDegreeUniversity("");
                                    setCollegeDataa(null);
                                    setOtherDegreeCollege("");
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Degree University"
                                      variant="outlined"
                                      value={universityInfo.degreeTerm}
                                      onBlur={() => {
                                        setUniversityInfo({
                                          ...universityInfo,
                                          degreeTerm: "",
                                        });
                                      }}
                                      required
                                      onChange={async (e) => {
                                        setUniversityInfo({
                                          ...universityInfo,
                                          degreeTerm: e.target.value,
                                        });
                                        await searchUniversity(e.target.value);
                                      }}
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          )}

                          {(selectedUniversity.degreeUniversity?.id === 0 ||
                            otherDegreeUniversity) && (
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  name="otherDegreeUniversity"
                                  fullWidth
                                  required
                                  id="otherDegreeUniversity"
                                  label="Other Degree University"
                                  value={otherDegreeUniversity}
                                  onChange={(e) => {
                                    setOtherDegreeUniversity(e.target.value);
                                  }}
                                  className={classes.root}
                                />
                              </Item>
                            </Grid>
                          )}
                          {!otherDegreeCollege && (
                            <Grid item md={3}>
                              <Item
                                onChange={(e) => {
                                  setSearchCollege(e.target.value);
                                }}
                              >
                                <Autocomplete
                                  options={collegeOption}
                                  value={collegeDataa}
                                  name="collegeOption"
                                  onChange={(event, newData_xyc) => {
                                    setCollegeDataa(newData_xyc);
                                    setOtherDegreeCollege("");
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Degree College"
                                      variant="outlined"
                                      required
                                    />
                                  )}
                                />
                              </Item>
                            </Grid>
                          )}

                          {(collegeDataa?.id === 0 || otherDegreeCollege) && (
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  name="otherDegreeCollege"
                                  fullWidth
                                  required
                                  id="otherDegreeCollege"
                                  label="Other Degree College"
                                  value={otherDegreeCollege}
                                  onChange={(e) => {
                                    setOtherDegreeCollege(e.target.value);
                                  }}
                                  className={classes.root}
                                />
                              </Item>
                            </Grid>
                          )}
                          <Grid item md={3}>
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              style={{ marginLeft: "2%" }}
                            >
                              Any Year Gap Till Degree
                            </FormLabel>
                            <Item>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="degreeYear"
                                value={degreeYear}
                                onChange={(e) => {
                                  setDegreeYear(e.target.value);
                                }}
                                style={{ marginTop: "-2%" }}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                  label="YES"
                                  name="degreeYear"
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                  label="NO"
                                  name="degreeYear"
                                  style={{ marginLeft: "10%" }}
                                />
                              </RadioGroup>
                            </Item>
                          </Grid>
                          <Grid
                            item
                            md={3}
                            style={{
                              display: degreeYear === "true" ? "block" : "none",
                            }}
                          >
                            <Item>
                              <TextField
                                name="degreeYearGap"
                                type="tel"
                                fullWidth
                                id="degreeYearGap"
                                label="Number Of Years"
                                value={degreeYearGap}
                                onChange={(e) => {
                                  setDegreeYearGap(e.target.value);
                                }}
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 2 }}
                              />
                            </Item>
                          </Grid>
                          <Grid item md={3}>
                            <Item>
                              <TextField
                                label="Active Backlogs In Degree "
                                type="tel"
                                fullWidth
                                onKeyPress={preventMinus}
                                onPaste={preventPasteNegative}
                                inputProps={{ minLength: 1, maxLength: 2 }}
                                name="degreeBacklogs"
                                value={degreeBacklogs}
                                onChange={(e) =>
                                  setDegreeBacklogs(e.target.value)
                                }
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </>
                    ) : null
                  ) : null}

                  {selectedQualification !== null ? (
                    <>
                      <Grid
                        style={{
                          width: "100%",
                          height: "1.5px",
                          borderBottom: "1px solid lightGrey",
                          margin: "1% 0 1% 0",
                        }}
                      ></Grid>
                      <div style={{ width: "100%" }}>
                        <Typography
                          component="h5"
                          variant="h6"
                          style={{
                            fontSize: "20px",
                            // color: "grey",
                            color: "rgb(248,151,28)",
                            backgroundColor: "#efefef",
                            padding: "0 0 0 10px",
                          }}
                        >
                          Intermediate Information
                        </Typography>
                      </div>
                      <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                        <Grid item md={10}>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Please choose the below option
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="intermediate"
                            value={intermediate}
                            onChange={(e) => {
                              setIntermediate(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="puc"
                              checked={intermediate === "puc"}
                              control={<Radio />}
                              label="12th / PUC"
                              name="intermediate"
                            />
                            <FormControlLabel
                              value="iti"
                              control={<Radio />}
                              checked={intermediate === "iti"}
                              label="ITI"
                              name="intermediate"
                              style={{ marginLeft: "10%" }}
                            />
                            <FormControlLabel
                              value="diploma"
                              checked={intermediate === "diploma"}
                              control={<Radio />}
                              label="Diploma"
                              name="intermediate"
                              style={{ marginLeft: "10%" }}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      {intermediate === "puc" ? (
                        <>
                          <Grid
                            style={{
                              width: "100%",
                              height: "1.5px",
                              borderBottom: "1px solid lightGrey",
                              margin: "1% 0 1% 0",
                            }}
                          ></Grid>

                          <div style={{ width: "100%" }}>
                            <Typography
                              component="h5"
                              variant="h6"
                              style={{
                                fontSize: "20px",
                                // color: "grey",
                                color: "rgb(248,151,28)",
                                backgroundColor: "#efefef",
                                padding: "0 0 0 10px",
                              }}
                            >
                              12th Information
                            </Typography>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "0.5%" }}
                          >
                            <Grid item md={3}>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{ marginLeft: "2%" }}
                              >
                                CGPA / Percentage
                              </FormLabel>

                              <Item>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="twelthPercentage"
                                  value={twelthPercentage}
                                  onChange={(e) => {
                                    setTwelthPercentage(e.target.value);
                                  }}
                                  style={{ marginTop: "-1%" }}
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="CGPA"
                                    name="twelthPercentage"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Percentage"
                                    name="twelthPercentage"
                                    style={{ marginLeft: "10%" }}
                                  />
                                </RadioGroup>
                              </Item>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  twelthPercentage === "1" ? "block" : "none",
                              }}
                            >
                              <Item>
                                <TextField
                                  name="twelthInCGPA"
                                  type="tel"
                                  fullWidth
                                  id="twelthInCGPA"
                                  label="CGPA"
                                  value={twelthInCGPA}
                                  onChange={(e) => {
                                    setTwelthInCGPA(e.target.value);
                                  }}
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 4 }}
                                />
                              </Item>
                            </Grid>

                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  twelthPercentage === "2" ? "block" : "none",
                              }}
                            >
                              <Item>
                                {" "}
                                <TextField
                                  name="twelthInPercent"
                                  type="tel"
                                  //reduired
                                  fullWidth
                                  id="twelthInPercent"
                                  label="Percentage"
                                  value={twelthInPercent}
                                  onChange={(e) => {
                                    setTwelthInPercent(e.target.value);
                                  }}
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 2, maxLength: 5 }}
                                />
                              </Item>
                            </Grid>

                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  label="12th Year Of PassOut "
                                  type="tel"
                                  fullWidth
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 4, maxLength: 4 }}
                                  name="twelthYop"
                                  value={twelthYop}
                                  required
                                  onChange={(e) => setTwelthYop(e.target.value)}
                                />
                              </Item>
                            </Grid>
                            <Grid item md={3}>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Any Academic Gap puc
                              </FormLabel>
                              <Item>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="twelthYear"
                                  value={twelthYear}
                                  onChange={(e) => {
                                    setTwelthYear(e.target.value);
                                  }}
                                  style={{ marginTop: "-2%" }}
                                >
                                  <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="YES"
                                    name="twelthYear"
                                  />
                                  <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="NO"
                                    name="twelthYear"
                                    style={{ marginLeft: "10%" }}
                                  />
                                </RadioGroup>
                              </Item>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  twelthYear === "true" ? "block" : "none",
                              }}
                            >
                              <Item>
                                <TextField
                                  name="twelthYearGap"
                                  type="tel"
                                  fullWidth
                                  id="twelthYearGap"
                                  label="Number Of Years"
                                  value={twelthYearGap}
                                  onChange={(e) => {
                                    setTwelthYearGap(e.target.value);
                                  }}
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                />
                              </Item>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      {intermediate === "iti" ? (
                        <>
                          {/* ITI Information Starts */}
                          <Grid
                            style={{
                              width: "100%",
                              height: "1.5px",
                              borderBottom: "1px solid lightGrey",
                              margin: "1% 0 1% 0",
                            }}
                          ></Grid>
                          <div style={{ width: "100%" }}>
                            <Typography
                              component="h5"
                              variant="h6"
                              style={{
                                fontSize: "20px",
                                // color: "grey",
                                color: "rgb(248,151,28)",
                                backgroundColor: "#efefef",
                                padding: "0 0 0 10px",
                              }}
                            >
                              ITI Information
                            </Typography>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "0.5%" }}
                          >
                            {!otherItiStreamName && (
                              <Grid item md={3}>
                                <Item
                                  onChange={(e) => {
                                    setStreamSarch(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={itiStreamOptions}
                                    value={selectedItiStream}
                                    name="stream_name"
                                    onChange={(event, newstream) => {
                                      setSelectedItiStream(newstream);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Search Stream"
                                        variant="outlined"
                                        required
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(selectedItiStream?.id === 0 ||
                              otherItiStreamName) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherItiStreamName"
                                    fullWidth
                                    required
                                    id="otherItiStreamName"
                                    label="Other ITI Stream"
                                    value={otherItiStreamName}
                                    onChange={(e) => {
                                      setOtherItiStreamName(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  label="Year Of Passout"
                                  type="tel"
                                  fullWidth
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 4, maxLength: 4 }}
                                  name="itiYop"
                                  value={itiYop}
                                  required
                                  onChange={(e) => setItiYop(e.target.value)}
                                />
                              </Item>
                            </Grid>
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  label="University Register Number / Exam Roll No"
                                  type="tel"
                                  fullWidth
                                  name="itiUniNumber"
                                  value={itiUniNumber}
                                  onChange={(e) =>
                                    setItiUniNumber(e.target.value)
                                  }
                                />
                              </Item>
                            </Grid>

                            <Grid item md={3}>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{ marginLeft: "2%" }}
                              >
                                CGPA / Percentage
                              </FormLabel>

                              <Item>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="itiPercentage"
                                  value={itiPercentage}
                                  onChange={(e) => {
                                    setItiPercentage(e.target.value);
                                  }}
                                  style={{ marginTop: "-1%" }}
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="CGPA"
                                    name="itiPercentage"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Percentage"
                                    name="itiPercentage"
                                    style={{ marginLeft: "10%" }}
                                  />
                                </RadioGroup>
                              </Item>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  itiPercentage === "1" ? "block" : "none",
                              }}
                            >
                              <Item>
                                <TextField
                                  name="itiInCGPA"
                                  type="tel"
                                  fullWidth
                                  id="itiInCGPA"
                                  label="CGPA"
                                  value={itiInCGPA}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      setItiInCGPA(inputValue);
                                    }
                                  }}
                                  onInput={(e) => {
                                    e.preventDefault();
                                    const inputValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setItiInCGPA(inputValue);
                                  }}
                                  inputProps={{ minLength: 1, maxLength: 4 }}
                                />
                              </Item>
                            </Grid>

                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  itiPercentage === "2" ? "block" : "none",
                              }}
                            >
                              <Item>
                                {" "}
                                <TextField
                                  name="itiInPercent"
                                  type="tel"
                                  //reduired
                                  fullWidth
                                  id="itiInPercent"
                                  label="Percentage"
                                  value={itiInPercent}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      setItiInPercent(e.target.value);
                                    }
                                  }}
                                  onInput={(e) => {
                                    e.preventDefault();
                                    const inputValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setItiInPercent(e.target.value);
                                  }}
                                  inputProps={{ minLength: 2, maxLength: 5 }}
                                />
                              </Item>
                            </Grid>
                            {!otherItiState && (
                              <Grid item md={3}>
                                <Item>
                                  <Autocomplete
                                    options={
                                      stateInfo.itiTerm &&
                                      stateInfo.itiTerm.length > 1
                                        ? stateList
                                        : stateInfo.defaultState
                                    }
                                    value={selectedStates.itiState}
                                    name="stateOption"
                                    onChange={(event, newData_xy, action) => {
                                      if (action === "clear") {
                                        setStateInfo({
                                          ...stateInfo,
                                          itiTerm: "",
                                        });
                                      }
                                      setSelectedStates({
                                        ...selectedStates,
                                        itiState: newData_xy,
                                      });
                                      setOtherItiState("");
                                      setItiDistrictData(null);
                                      setOtherItiDistrict("");
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="ITI College - State"
                                        variant="outlined"
                                        value={stateInfo.itiTerm}
                                        onBlur={() => {
                                          setStateInfo({
                                            ...stateInfo,
                                            itiTerm: "",
                                          });
                                        }}
                                        required
                                        onChange={async (e) => {
                                          setStateInfo({
                                            ...stateInfo,
                                            itiTerm: e.target.value,
                                          });
                                          await searchState(e.target.value);
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(selectedStates.itiState?.id === 0 ||
                              otherItiState) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherItiState"
                                    fullWidth
                                    required
                                    id="otherItiState"
                                    label="Other ITI College - State"
                                    value={otherItiState}
                                    onChange={(e) => {
                                      setOtherItiState(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            {!otherItiDistrict && (
                              <Grid item md={3}>
                                <Item
                                  onChange={(e) => {
                                    setItiDisSearch(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={itidistrictOption}
                                    value={itidistrictData}
                                    name="itidistrictOption"
                                    onChange={(event, newData_xy) => {
                                      setItiDistrictData(newData_xy);
                                      setOtherItiDistrict("");
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="ITI College - District"
                                        variant="outlined"
                                        required
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(itidistrictData?.id === 0 ||
                              otherItiDistrict) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherItiDistrict"
                                    fullWidth
                                    required
                                    id="otherItiDistrict"
                                    label="Other ITI College - District"
                                    value={otherItiDistrict}
                                    onChange={(e) => {
                                      setOtherItiDistrict(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            {!otherItiUniversity && (
                              <Grid item md={3}>
                                <Item>
                                  <Autocomplete
                                    options={
                                      universityInfo.itiTerm &&
                                      universityInfo.itiTerm.length > 1
                                        ? universityList
                                        : universityInfo.defaultUniversity
                                    }
                                    value={selectedUniversity.itiUniversity}
                                    name="iti_university"
                                    onChange={(event, newData_xy, action) => {
                                      if (action === "clear") {
                                        setUniversityInfo({
                                          ...universityInfo,
                                          itiTerm: "",
                                        });
                                      }
                                      setSelectedUniversity({
                                        ...selectedUniversity,
                                        itiUniversity: newData_xy,
                                      });
                                      setOtherItiUniversity("");
                                      setItiCollegeData(null);
                                      setOtherItiCollege("");
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="ITI Board"
                                        variant="outlined"
                                        value={universityInfo.itiTerm}
                                        onBlur={() => {
                                          setUniversityInfo({
                                            ...universityInfo,
                                            itiTerm: "",
                                          });
                                        }}
                                        required
                                        onChange={async (e) => {
                                          setUniversityInfo({
                                            ...universityInfo,
                                            itiTerm: e.target.value,
                                          });
                                          await searchUniversity(
                                            e.target.value
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(selectedUniversity.itiUniversity?.id === 0 ||
                              otherItiUniversity) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherItiUniversity"
                                    fullWidth
                                    required
                                    id="otherItiUniversity"
                                    label="Other ITI Board"
                                    value={otherItiUniversity}
                                    onChange={(e) => {
                                      setOtherItiUniversity(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            {!otherItiCollege && (
                              <Grid item md={3}>
                                <Item
                                  onChange={(e) => {
                                    setItiColSearch(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={itiCollegeOption}
                                    value={itiCollegeData}
                                    name="itiCollegeOption"
                                    onChange={(event, newData_xyc) => {
                                      setItiCollegeData(newData_xyc);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="ITI College"
                                        variant="outlined"
                                        required
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(itiCollegeData?.id === 0 || otherItiCollege) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherItiCollege"
                                    fullWidth
                                    id="otherItiCollege"
                                    label="Other ITI College"
                                    required
                                    value={otherItiCollege}
                                    onChange={(e) => {
                                      setOtherItiCollege(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            <Grid item md={3}>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{ marginLeft: "2%" }}
                              >
                                Any Year Gap Till ITI
                              </FormLabel>
                              <Item>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="itiYear"
                                  value={itiYear}
                                  onChange={(e) => {
                                    setItiYear(e.target.value);
                                  }}
                                  style={{ marginTop: "-2%" }}
                                >
                                  <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="YES"
                                    name="itiYear"
                                  />
                                  <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="NO"
                                    name="itiYear"
                                    style={{ marginLeft: "10%" }}
                                  />
                                </RadioGroup>
                              </Item>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                display: itiYear === "true" ? "block" : "none",
                              }}
                            >
                              <Item>
                                <TextField
                                  name="itiYearGap"
                                  type="tel"
                                  fullWidth
                                  id="itiYearGap"
                                  label="Number Of Years"
                                  value={itiYearGap}
                                  onChange={(e) => {
                                    setItiYearGap(e.target.value);
                                  }}
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                />
                              </Item>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                      {intermediate === "diploma" ? (
                        <>
                          {/* Diploma Information Starts */}
                          <Grid
                            style={{
                              width: "100%",
                              height: "1.5px",
                              borderBottom: "1px solid lightGrey",
                              margin: "1% 0 1% 0",
                            }}
                          ></Grid>
                          <div style={{ width: "100%" }}>
                            <Typography
                              component="h5"
                              variant="h6"
                              style={{
                                fontSize: "20px",
                                // color: "grey",
                                color: "rgb(248,151,28)",
                                backgroundColor: "#efefef",
                                padding: "0 0 0 10px",
                              }}
                            >
                              Diploma Information
                            </Typography>
                          </div>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "0.5%" }}
                          >
                            {!otherDipStreamName && (
                              <Grid item md={3}>
                                <Item
                                  onChange={(e) => {
                                    setDipStreamSearch(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={dipStreamOptions}
                                    value={selectedDipStream}
                                    name="stream_name"
                                    onChange={(event, newstream) => {
                                      setSelectedDipStream(newstream);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Search Diploma Stream"
                                        variant="outlined"
                                        required
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(selectedDipStream?.id === 0 ||
                              otherDipStreamName) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherDipStreamName"
                                    fullWidth
                                    required
                                    id="otherDipStreamName"
                                    label="Other Diploma Stream"
                                    value={otherDipStreamName}
                                    onChange={(e) => {
                                      setOtherDipStreamName(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  label="Year Of Passout"
                                  type="tel"
                                  fullWidth
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 4, maxLength: 4 }}
                                  name="dipYop"
                                  value={dipYop}
                                  required
                                  onChange={(e) => setDipYop(e.target.value)}
                                />
                              </Item>
                            </Grid>
                            <Grid item md={3}>
                              <Item>
                                <TextField
                                  label="University Register Number / Exam Roll No"
                                  type="tel"
                                  fullWidth
                                  name="dipUniNumber"
                                  value={dipUniNumber}
                                  onChange={(e) =>
                                    setDipUniNumber(e.target.value)
                                  }
                                />
                              </Item>
                            </Grid>

                            <Grid item md={3}>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{ marginLeft: "2%" }}
                              >
                                CGPA / Percentage
                              </FormLabel>

                              <Item>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="dipPercentage"
                                  value={dipPercentage}
                                  onChange={(e) => {
                                    setDipPercentage(e.target.value);
                                  }}
                                  style={{ marginTop: "-1%" }}
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="CGPA"
                                    name="dipPercentage"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Percentage"
                                    name="dipPercentage"
                                    style={{ marginLeft: "10%" }}
                                  />
                                </RadioGroup>
                              </Item>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  dipPercentage === "1" ? "block" : "none",
                              }}
                            >
                              <Item>
                                <TextField
                                  name="dipInCGPA"
                                  type="tel"
                                  fullWidth
                                  id="dipInCGPA"
                                  label="CGPA"
                                  value={dipInCGPA}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      setDipInCGPA(inputValue);
                                    }
                                  }}
                                  onInput={(e) => {
                                    e.preventDefault();
                                    const inputValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setDipInCGPA(inputValue);
                                  }}
                                  inputProps={{ minLength: 1, maxLength: 4 }}
                                />
                              </Item>
                            </Grid>

                            <Grid
                              item
                              md={3}
                              style={{
                                display:
                                  dipPercentage === "2" ? "block" : "none",
                              }}
                            >
                              <Item>
                                {" "}
                                <TextField
                                  name="dipInPercent"
                                  type="tel"
                                  //reduired
                                  fullWidth
                                  id="dipInPercent"
                                  label="Percentage"
                                  value={dipInPercent}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      setDipInPercent(inputValue);
                                    }
                                  }}
                                  onInput={(e) => {
                                    e.preventDefault();
                                    const inputValue = e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                    setDipInPercent(inputValue);
                                  }}
                                  inputProps={{ minLength: 2, maxLength: 5 }}
                                />
                              </Item>
                            </Grid>
                            {!otherDipState && (
                              <Grid item md={3}>
                                <Item>
                                  <Autocomplete
                                    options={
                                      stateInfo.diplomaTerm &&
                                      stateInfo.diplomaTerm.length > 1
                                        ? stateList
                                        : stateInfo.defaultState
                                    }
                                    value={selectedStates.diplomaState}
                                    name="stateOption"
                                    onChange={(event, newData_xy, action) => {
                                      if (action === "clear") {
                                        setStateInfo({
                                          ...stateInfo,
                                          diplomaTerm: "",
                                        });
                                      }
                                      setSelectedStates({
                                        ...selectedStates,
                                        diplomaState: newData_xy,
                                      });
                                      setOtherDipState("");
                                      setDipDistrictData(null);
                                      setOtherDipDistrict("");
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Diploma College - State"
                                        variant="outlined"
                                        value={stateInfo.diplomaTerm}
                                        onBlur={() => {
                                          setStateInfo({
                                            ...stateInfo,
                                            diplomaTerm: "",
                                          });
                                        }}
                                        required
                                        onChange={async (e) => {
                                          setStateInfo({
                                            ...stateInfo,
                                            diplomaTerm: e.target.value,
                                          });
                                          await searchState(e.target.value);
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(selectedStates.diplomaState?.id === 0 ||
                              otherDipState) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherDipState"
                                    fullWidth
                                    required
                                    id="otherDipState"
                                    label="Other Diploma College - State"
                                    value={otherDipState}
                                    onChange={(e) => {
                                      setOtherDipState(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            {!otherDipDistrict && (
                              <Grid item md={3}>
                                <Item
                                  onChange={(e) => {
                                    setDipdisSearch(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={dipdistrictOption}
                                    value={dipdistrictData}
                                    name="dipdistrictOption"
                                    onChange={(event, newData_xy) => {
                                      setDipDistrictData(newData_xy);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Diploma College - District"
                                        variant="outlined"
                                        required
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(dipdistrictData?.id === 0 ||
                              otherDipDistrict) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherDipDistrict"
                                    fullWidth
                                    id="otherDipDistrict"
                                    label="Other Diploma College - District"
                                    value={otherDipDistrict}
                                    onChange={(e) => {
                                      setOtherDipDistrict(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            {!otherDipUniversity && (
                              <Grid item md={3}>
                                <Item>
                                  <Autocomplete
                                    options={
                                      universityInfo.diplomaTerm &&
                                      universityInfo.diplomaTerm.length > 1
                                        ? universityList
                                        : universityInfo.defaultUniversity
                                    }
                                    value={selectedUniversity.diplomaUniversity}
                                    name="diploma_university"
                                    onChange={(event, newData_xy, action) => {
                                      if (action === "clear") {
                                        setUniversityInfo({
                                          ...universityInfo,
                                          diplomaTerm: "",
                                        });
                                      }
                                      setSelectedUniversity({
                                        ...selectedUniversity,
                                        diplomaUniversity: newData_xy,
                                      });
                                      setOtherDipUniversity("");
                                      setDipCollegeData(null);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Diploma Board"
                                        variant="outlined"
                                        value={universityInfo.diplomaTerm}
                                        onBlur={() => {
                                          setUniversityInfo({
                                            ...universityInfo,
                                            diplomaTerm: "",
                                          });
                                        }}
                                        required
                                        onChange={async (e) => {
                                          setUniversityInfo({
                                            ...universityInfo,
                                            diplomaTerm: e.target.value,
                                          });
                                          await searchUniversity(
                                            e.target.value
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(selectedUniversity.diplomaUniversity?.id === 0 ||
                              otherDipUniversity) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherDipUniversity"
                                    fullWidth
                                    required
                                    id="otherDipUniversity"
                                    label="Other Diploma Board"
                                    value={otherDipUniversity}
                                    onChange={(e) => {
                                      setOtherDipUniversity(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            {!otherDipCollege && (
                              <Grid item md={3}>
                                <Item
                                  onChange={(e) => {
                                    setDipColSearch(e.target.value);
                                  }}
                                >
                                  <Autocomplete
                                    options={dipCollegeOption}
                                    value={dipCollegeData}
                                    name="dipCollegeOption"
                                    onChange={(event, newData_xyc) => {
                                      setDipCollegeData(newData_xyc);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Diploma College"
                                        variant="outlined"
                                        required
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            )}

                            {(dipCollegeData?.id === 0 || otherDipCollege) && (
                              <Grid item md={3}>
                                <Item>
                                  <TextField
                                    name="otherDipCollege"
                                    fullWidth
                                    required
                                    id="otherDipCollege"
                                    label="Other Diploma College"
                                    value={otherDipCollege}
                                    onChange={(e) => {
                                      setOtherDipCollege(e.target.value);
                                    }}
                                    className={classes.root}
                                  />
                                </Item>
                              </Grid>
                            )}
                            <Grid item md={3}>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                style={{ marginLeft: "2%" }}
                              >
                                Any Year Gap Till Diploma
                              </FormLabel>
                              <Item>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="dipYear"
                                  value={dipYear}
                                  onChange={(e) => {
                                    setDipYear(e.target.value);
                                  }}
                                  style={{ marginTop: "-2%" }}
                                >
                                  <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="YES"
                                    name="dipYear"
                                  />
                                  <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="NO"
                                    name="dipYear"
                                    style={{ marginLeft: "10%" }}
                                  />
                                </RadioGroup>
                              </Item>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              style={{
                                display: dipYear === "true" ? "block" : "none",
                              }}
                            >
                              <Item>
                                <TextField
                                  name="dipYearGap"
                                  type="tel"
                                  fullWidth
                                  id="dipYearGap"
                                  label="Number Of Years"
                                  value={dipYearGap}
                                  onChange={(e) => {
                                    setDipYearGap(e.target.value);
                                  }}
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                />
                              </Item>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : null}

                  {selectedQualification !== null ? (
                    <>
                      <Grid
                        style={{
                          width: "100%",
                          height: "1.5px",
                          borderBottom: "1px solid lightGrey",
                          margin: "1% 0 1% 0",
                        }}
                      ></Grid>

                      {/* //! 10th Information Starts */}
                      <div style={{ width: "100%" }}>
                        <Typography
                          component="h5"
                          variant="h6"
                          style={{
                            fontSize: "20px",
                            // color: "grey",
                            color: "rgb(248,151,28)",
                            backgroundColor: "#efefef",
                            padding: "0 0 0 10px",
                          }}
                        >
                          10th Information
                        </Typography>
                      </div>
                      <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                        <Grid item md={3}>
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            style={{ marginLeft: "2%" }}
                          >
                            CGPA / Percentage
                          </FormLabel>

                          <Item>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="tenthPercentage"
                              value={tenthPercentage}
                              onChange={(e) => {
                                setTenthPercentage(e.target.value);
                              }}
                              style={{ marginTop: "-1%" }}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="CGPA"
                                name="tenthPercentage"
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Percentage"
                                name="tenthPercentage"
                                style={{ marginLeft: "10%" }}
                              />
                            </RadioGroup>
                          </Item>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          style={{
                            display: tenthPercentage === "1" ? "block" : "none",
                          }}
                        >
                          <Item>
                            <TextField
                              name="tenthInCGPA"
                              type="tel"
                              fullWidth
                              id="tenthInCGPA"
                              label="CGPA"
                              value={tenthInCGPA}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setTenthInCGPA(inputValue);
                                }
                              }}
                              onInput={(e) => {
                                e.preventDefault();
                                const inputValue = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                setTenthInCGPA(inputValue);
                              }}
                              inputProps={{ minLength: 1, maxLength: 4 }}
                            />
                          </Item>
                        </Grid>

                        <Grid
                          item
                          md={3}
                          style={{
                            display: tenthPercentage === "2" ? "block" : "none",
                          }}
                        >
                          <Item>
                            {" "}
                            <TextField
                              name="tenthInPercent"
                              type="tel"
                              //reduired
                              fullWidth
                              id="tenthInPercent"
                              label="Percentage"
                              value={tenthInPercent}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*\.?\d*$/.test(inputValue)) {
                                  setTenthInPercent(e.target.value);
                                }
                              }}
                              onInput={(e) => {
                                e.preventDefault();
                                const inputValue = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                setTenthInPercent(inputValue);
                              }}
                              inputProps={{ minLength: 2, maxLength: 5 }}
                            />
                          </Item>
                        </Grid>

                        <Grid item md={3}>
                          <Item>
                            <TextField
                              label="10th Year Of PassOut "
                              type="tel"
                              fullWidth
                              onKeyPress={preventMinus}
                              onPaste={preventPasteNegative}
                              inputProps={{ minLength: 4, maxLength: 4 }}
                              name="tenthYop"
                              value={tenthYop}
                              required
                              onChange={(e) => setTenthYop(e.target.value)}
                            />
                          </Item>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}

                  <Grid
                    md={12}
                    item
                    spacing={2}
                    sx={{ mt: 6, mb: 4 }}
                    style={{
                      alignItems: "center",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        width: "10%",
                        backgroundColor: "#D24545",
                        color: "#fff",
                      }}
                      onClick={handleCancelClick}
                    >
                      <HiArrowNarrowLeft
                        style={{
                          fontSize: "20px",
                          color: "white",
                        }}
                      />
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ width: "10%" }}
                    >
                      Submit
                      <HiArrowNarrowRight
                        style={{
                          fontSize: "20px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Paper>
      </Container>
    </section>
  );
}
