// import * as React from "react";
// import {
//   Box,
//   Typography,
//   Modal,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Tooltip,
//   Pagination,
//   Grid,
// } from "@mui/material";
// import { BsFillEyeFill } from "react-icons/bs";
// import { AiFillCloseSquare } from "react-icons/ai";
// import AxiosInstance from "../../../apis/AxiosInstance";
// import { useState } from "react";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "40vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "auto",
//   zIndex: "999",
// };

// export default function QuizDetailsList(props) {
//   let [appointment_id, setAppontment_id] = React.useState(
//     props.appointment_idx
//   );
//   let [regdStudents, setRegdStudents] = React.useState([]);

//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [page, setPage] = React.useState(1);
//   const [totalLength, setTotalLength] = useState(0);

//   const handleChangePage = async (event, newPage) => {
//     setLoadRed(true);
//     try {
//       let response = await AxiosInstance.get(
//         `get_quiz_details?id=${appointment_id}`
//       );
//       if (response.data.data !== null) {
//         setRegdStudents(response.data.data);
//         // setTotalLength(response.data.total_length);
//         setLoadRed(false);
//       }
//       setLoadRed(false);
//       setPage(newPage);
//     } catch (error) {
//       setLoadRed(false);
//       console.log(error);
//     }
//   };

//   const [loadReg, setLoadRed] = React.useState(false);
//   const handleCollegeOpen = async (e) => {
//     handleOpen();
//     setLoadRed(true);
//     try {
//       let response = await AxiosInstance.get(
//         `get_quiz_details?id=${appointment_id}`
//       );
//       if (response.data.data !== null) {
//         setRegdStudents(response.data.data);
//         // setTotalLength(response.data.total_length);
//         setLoadRed(false);
//       }
//       setLoadRed(false);
//     } catch (error) {
//       setLoadRed(false);
//       console.log(error);
//     }
//   };

//   const totalPages = Math.ceil(totalLength / 10);

//   return (
//     <div>
//       <Tooltip title="Quiz Details">
//         <p
//           onClick={handleCollegeOpen}
//           sx={{
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <BsFillEyeFill
//             fontSize={17}
//             style={{
//               color: "#646FD4",
//               cursor: "pointer",
//               "&:hover": { color: "#EB455F" },
//             }}
//           />
//         </p>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Typography
//             id="modal-modal-title"
//             variant="h6"
//             component="h2"
//             sx={{ fontSize: "24px", fontWeight: "bold" }}
//           >
//             Quiz Details
//           </Typography>
//           <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
//             <Table
//               sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
//               // sx={{ minWidth: 650 }}
//               aria-label="simple table"
//               stickyHeader
//             >
//               <TableHead sx={{ backgroundColor: "#495579" }}>
//                 <TableRow>
//                   <TableCell
//                     align="center"
//                     sx={{
//                       color: "#fff",
//                       backgroundColor: "#495579",
//                       fontWeight: "600",
//                       fontSize: "15px",
//                       width: "100px !important",
//                     }}
//                   >
//                     Sl No
//                   </TableCell>
//                   <TableCell
//                     align="center"
//                     sx={{
//                       color: "#fff",
//                       backgroundColor: "#495579",
//                       fontWeight: "600",
//                       fontSize: "15px",
//                       width: "300px !important",
//                     }}
//                   >
//                    Appointment Name
//                   </TableCell>
//                   <TableCell
//                     align="center"
//                     sx={{
//                       color: "#fff",
//                       backgroundColor: "#495579",
//                       fontWeight: "600",
//                       fontSize: "15px",
//                       width: "300px !important",
//                     }}
//                   >
//                     Quiz Score / Total Marks
//                   </TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody style={{ overflow: "auto" }}>
//                 {loadReg ? (
//                   <TableRow align="center" colSpan={3}>
//                     Loading...
//                   </TableRow>
//                 ) : regdStudents.length > 0 ? (
//                   regdStudents.map((value, id) => {
//                     return (
//                       <TableRow
//                         sx={{
//                           "&:last-child td, &:last-child th": { border: 0 },
//                         }}
//                       >

//                         <TableCell
//                           align="center"
//                           sx={{ color: "#000", width: "10%" }}
//                         >
//                           {value.quiz_details &&
//                           Object.keys(value.quiz_details).length > 0 ? (
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 alignItems: "center",
//                               }}
//                             >
//                               {(() => {
//                                 let counter = 1; // Initialize a counter
//                                 return Object.values(
//                                   value.quiz_details
//                                 ).flatMap((e) => {
//                                   if (
//                                     e.events &&
//                                     Object.keys(e.events).length > 0
//                                   ) {
//                                     return Object.values(e.events).map((_) => (
//                                       <p key={"serial" + counter}>
//                                         {counter++}
//                                       </p> // Increment the counter for each event
//                                     ));
//                                   }
//                                   return [];
//                                 });
//                               })()}
//                             </div>
//                           ) : null}
//                         </TableCell>

//                         <TableCell align="center" sx={{ color: "#000" }}>
//                           {value.quiz_details &&
//                           Object.keys(value.quiz_details).length > 0 ? (
//                             <p
//                               style={{
//                                 display: "flex",
//                                 alignItems: "center",
//                                 justifyContent: "center",
//                                 flexDirection: "column", // Add this to stack the scores vertically
//                               }}
//                             >
//                               {Object.values(value.quiz_details).map(
//                                 (e, ie) => {
//                                   // Check if events exist and have data
//                                   if (
//                                     e.events &&
//                                     Object.keys(e.events).length > 0
//                                   ) {
//                                     return Object.values(e.events).map(
//                                       (ex, iie) => {
//                                         let totalScore = 0;
//                                         let totalMarks = 0;
//                                         if (
//                                           ex.quiz_results &&
//                                           Object.keys(ex.quiz_results).length >
//                                             0 &&
//                                           Object.keys(
//                                             ex.quiz_results.evaluation_date
//                                           ).length > 0
//                                         ) {
//                                           const latestEvaluation =
//                                             Object.values(
//                                               ex.quiz_results.evaluation_date
//                                             )[
//                                               Object.keys(
//                                                 ex.quiz_results.evaluation_date
//                                               ).length - 1
//                                             ];
//                                           totalScore +=
//                                             latestEvaluation.total_marks_scored ||
//                                             0;
//                                           totalMarks +=
//                                             latestEvaluation.total_marks || 0;
//                                         }
//                                         return (
//                                           <React.Fragment
//                                             key={"event" + ie + iie}
//                                           >
//                                             {totalScore}/{totalMarks} <br />
//                                           </React.Fragment>
//                                         );
//                                       }
//                                     );
//                                   }
//                                   return null; // If no events exist, return null
//                                 }
//                               )}
//                             </p>
//                           ) : null}
//                         </TableCell>
//                       </TableRow>
//                     );
//                   })
//                 ) : (
//                   <TableRow>
//                     <TableCell align="center" sx={{ color: "#000" }}>
//                       ---
//                     </TableCell>
//                     <TableCell align="center" sx={{ color: "#000" }}>
//                       ---
//                     </TableCell>
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  Grid,
} from "@mui/material";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function QuizDetailsList(props) {
  let [appointment_id, setAppointment_id] = React.useState(
    props.appointment_idx
  );
  let [regdStudents, setRegdStudents] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `get_quiz_details?id=${appointment_id}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleCollegeOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `get_quiz_details?id=${appointment_id}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const totalPages = Math.ceil(totalLength / 10);

  return (
    <div>
      <Tooltip title="Quiz Details">
        <p
          onClick={handleCollegeOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Quiz Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "500px !important",
                    }}
                  >
                    Appointment Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Quiz Score / Total Marks
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={value.id}
                      >
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "10%" }}
                        >
                          {value.quiz_details &&
                          Object.keys(value.quiz_details).length > 0 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {(() => {
                                let counter = 1;
                                return Object.values(value.quiz_details)
                                  .flatMap((e) => {
                                    if (
                                      e.events &&
                                      Object.keys(e.events).length > 0
                                    ) {
                                      return Object.values(e.events).map(() => (
                                        <p
                                          key={"serial" + counter}
                                          style={{ padding: "5px" }}
                                        >
                                          {counter++}
                                        </p>
                                      ));
                                    }
                                    return [];
                                  })
                                  .filter((_, index) => index < 10); // Limit to 10 entries
                              })()}
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.quiz_details &&
                          Object.keys(value.quiz_details).length > 0 ? (
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                padding: "10px",
                              }}
                            >
                              {Object.values(value.quiz_details).map(
                                (e, ie) => {
                                  if (
                                    e.events &&
                                    Object.keys(e.events).length > 0
                                  ) {
                                    return Object.values(e.events).map(
                                      (ex, iie) => {
                                        const appointmentNames = Object.values(
                                          ex.appointment || {
                                            NA: { name: "NA" },
                                          }
                                        ).map((app) => app.name);
                                        return appointmentNames.map(
                                          (name, index) => (
                                            <p
                                              key={"appointment" + index}
                                              style={{ padding: "5px" }}
                                            >
                                              {name} <br />
                                            </p>
                                          )
                                        );
                                      }
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </p>
                          ) : null}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.quiz_details &&
                          Object.keys(value.quiz_details).length > 0 ? (
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              {Object.values(value.quiz_details).map(
                                (e, ie) => {
                                  if (
                                    e.events &&
                                    Object.keys(e.events).length > 0
                                  ) {
                                    return Object.values(e.events).map(
                                      (ex, iie) => {
                                        let totalScore = 0;
                                        let totalMarks = 0;
                                        if (
                                          ex.quiz_results &&
                                          Object.keys(ex.quiz_results).length >
                                             0 &&
                                         Object.keys(
                                            ex.quiz_results.evaluation_date
                                          ).length > 0
                                        ) {
                                          const latestEvaluation =
                                            Object.values(
                                              ex.quiz_results.evaluation_date
                                            ).pop();
                                          totalScore +=
                                            latestEvaluation.total_marks_scored ||
                                            0;
                                          totalMarks +=
                                            latestEvaluation.total_marks || 0;
                                        }
                                        return (
                                          <p
                                            key={"event" + ie + iie}
                                            style={{ padding: "5px" }}
                                          >
                                            {totalScore}/{totalMarks} <br />
                                          </p>
                                        );
                                      }
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </p>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      align="center"
                      sx={{ color: "#000" }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            sx={{ display: "flex", justifyContent: "center", mt: 2 }}
          />
        </Box>
      </Modal>
    </div>
  );
}
