import * as React from "react";
import { Box, Button, Typography, Modal, Tooltip } from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function EditInterviewModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleOpen = () => {
  //   if (stateAddresssing.validate) {
  //     setOpen(true);
  //   } else {
  //     toast.warning("You can't Edit the GD");
  //   }
  // };

  const handleClose = () => setOpen(false);
  let { stateAddresssing, status } = props;

  return (
    <div>
      <Tooltip title="GD Edit">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <FaEdit
            fontSize={18}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
            }}
          />
        </Button>
        {/* <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
            color: stateAddresssing.validate == false ? "#A0A0A0" : "#646FD4",
            cursor:
              stateAddresssing.validate == false ? "not-allowed" : "pointer",
            "&:hover": {
              color: stateAddresssing.validate == false ? "#A0A0A0" : "#EB455F",
            },
          }}
        >
          <FaEdit fontSize={18} />
        </Button> */}
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography id="modal-modal-title" variant="h5">
            Select Single or Multiple Interview For Edit Interview..?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Link to="/edit_interview" state={stateAddresssing}>
              <Button
                sx={{ padding: "15px", background: "green", color: "white" }}
                variant="contained"
              >
                single
              </Button>
            </Link>

            <Link state={stateAddresssing} to="/edit_multiple_interview">
              <Button
                sx={{ padding: "15px", background: "blue", color: "white" }}
                variant="contained"
              >
                Multiple
              </Button>
            </Link>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
