import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Box,
  Grid,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import moment from "moment";
import AxiosInstance from "../apis/AxiosInstance";
import StudentJoinedList from "../Bd_Module/bd/appointmentList/StudentJoinedList";
import NotJoinedStudentList from "../Bd_Module/bd/appointmentList/NotJoinedStudentList";
import QuizNotAttendedInAppointment from "../Bd_Module/bd/appointmentList/QuizNotAttendedInAppointment";
import QuizShortlistedListInAppointment from "../Bd_Module/bd/appointmentList/QuizShortlistedListInAppointment";
import QuizNotSelectedInAppointment from "../Bd_Module/bd/appointmentList/QuizNotSelectedInAppointment";
import QuizRejectedList from "../Bd_Module/bd/appointmentList/QuizRejectedList";
import SameCollegeRegisterStudentList from "./SameCollegeRegisterStudentList";
import SameCollegeGDRegisteredStudentList from "./SameCollegeGDRegisteredStudentList";
import SameCollegeGDJoinedStudentList from "./SameCollegeGDJoinedStudentList";
import SameCollegeGDNotJoinedStudentList from "./SameCollegeGDNotJoinedStudentList";
import SameCollegeGDShortlistStudentList from "./SameCollegeGDShortlistStudentList";
import SameCollegeGDHoldStudentList from "./SameCollegeGDHoldStudentList";
import SameCollegeGDRejectStudentList from "./SameCollegeGDRejectStudentList";
import SameCollegeFinalShortlist from "./SameCollegeFinalShortlist";
import SameCollegeRejectlist from "./SameCollegeRejectlist";
import SameCollegeQuizAttendedList from "./SameCollegeQuizAttendedList";
import SameCollegeQuizNotAttendedList from "./SameCollegeQuizNotAttendedList";
import SameCollegeQuizShortlistList from "./SameCollegeQuizShortlistList";
import SameCollegeQuizNotSelectedList from "./SameCollegeQuizNotSelectedList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#124076",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TpoAppointmentList() {
  let [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [totalAppointment, setTotalAppointment] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // //! Fetch the college
  let [searchedAppointment, setSearchedAppointment] = useState([]);
  useEffect(() => {
    let fetchAppointment = async (_) => {
      if (query !== "") {
        try {
          let collegeData = await AxiosInstance.get(
            `/appointments_for_tpo?${query}&page=${page}`
          );
          setTotalAppointment(collegeData.data.filter_count);
          let finalCollege = collegeData.data.data;
          setSearchedAppointment(finalCollege);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let collegeData = await AxiosInstance.get(
            `/appointments_for_tpo?page=${page}`
          );
          setTotalAppointment(collegeData.data.filter_count);
          let finalCollege = collegeData.data.data;
          setSearchedAppointment(finalCollege);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchAppointment();
  }, [page, query]);

  const totalPages = Math.ceil(totalAppointment / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <main>
      <Grid style={{ marginTop: "4%" }}>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#124076" }}>
          Total Appointments : {totalAppointment} &nbsp;&nbsp;
        </h1>
      </Grid>

      <section style={{ marginTop: "1%" }}>
        <TableContainer component={Paper} sx={{ maxHeight: 470 }}>
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "900px !important",
                  }}
                >
                  Appointment Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Appointment Date and Time
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Addressing Date and Time
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Registered Students
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Addressing Attended
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                  }}
                >
                  Addressing Not-Attended
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Quiz Attended
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Quiz Not-Attended
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Quiz Shortlist
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Quiz Not-Selected
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Quiz Rejected
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Registered
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Joined
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Not-Joined
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Shortlist
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Rejected
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  GD Hold
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Final Shortlist
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Final Reject
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedAppointment && searchedAppointment.length > 0 ? (
                searchedAppointment.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.name} placement="bottom">
                        <p> {val.name}</p>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p>
                        {val.appointment_date === null ||
                        val.appointment_date === "" ? (
                          <Box
                            sx={{
                              color: "#EB455F",
                            }}
                          >
                            NA
                          </Box>
                        ) : (
                          moment
                            .utc(val.appointment_date)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")
                        )}
                      </p>
                    </StyledTableCell>

                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p>
                        {val.addressing_datetime === null ||
                        val.addressing_datetime === "" ? (
                          <Box
                            sx={{
                              color: "#EB455F",
                            }}
                          >
                            NA
                          </Box>
                        ) : (
                          moment
                            .utc(val.addressing_datetime)
                            .local()
                            .format("DD-MM-yyyy hh:mm A")
                        )}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {val.registered_count === null
                            ? 0
                            : val.registered_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeRegisterStudentList
                            appointment_idx={val.id}
                            level="5"
                          ></SameCollegeRegisterStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <p>
                          {val.joined_student_count === null
                            ? 0
                            : val.joined_student_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <StudentJoinedList
                            appointment_idx={val.id}
                            joinedStudentLevel="5"
                          ></StudentJoinedList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                         <p>
                          {val.not_joined_student_count === null
                            ? 0
                            : val.not_joined_student_count}
                        </p>
                       
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <NotJoinedStudentList
                            appointment_idx={val.id}
                            notJoinedStudentLevel="5"
                          ></NotJoinedStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <p>{val?.quiz_count?.quiz_attended_count || 0}</p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeQuizAttendedList
                            appointment_idx={val.id}
                            addressing_idx={val.addressing_id}
                          ></SameCollegeQuizAttendedList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <p>{val?.quiz_count?.quiz_not_attended_count || 0}</p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeQuizNotAttendedList
                            appointment_idx={val.id}
                            addressing_idx={val.addressing_id}
                          ></SameCollegeQuizNotAttendedList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <p>
                          {val?.quiz_count?.quiz_shortlisted_student_count || 0}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          <SameCollegeQuizShortlistList
                            appointment_idx={val.id}
                            addressing_idx={val.addressing_id}
                          ></SameCollegeQuizShortlistList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <p>
                          {val?.quiz_count?.quiz_not_selected_student_count ||
                            0}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeQuizNotSelectedList
                            appointment_idx={val.id}
                            addressing_idx={val.addressing_id}
                          ></SameCollegeQuizNotSelectedList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <p>
                          {val?.quiz_count?.quiz_rejected_student_count || 0}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <QuizRejectedList
                            appointment_idx={val.id}
                            quizRejectedList="5"
                          ></QuizRejectedList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.gd_registered_count === null
                            ? 0
                            : val.gd_registered_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeGDRegisteredStudentList
                            appointment_idx={val.id}
                            gdRegistered="5"
                          ></SameCollegeGDRegisteredStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.gd_joined_count === null
                            ? 0
                            : val.gd_joined_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          <SameCollegeGDJoinedStudentList
                            appointment_idx={val.id}
                            gdJoined="5"
                          ></SameCollegeGDJoinedStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.gd_not_joined_count === null
                            ? 0
                            : val.gd_not_joined_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeGDNotJoinedStudentList
                            appointment_idx={val.id}
                            gdNotJoined="5"
                          ></SameCollegeGDNotJoinedStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.gd_shortlisted_count === null
                            ? 0
                            : val.gd_shortlisted_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeGDShortlistStudentList
                            appointment_idx={val.id}
                            gdShortlist="5"
                          ></SameCollegeGDShortlistStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.gd_rejected_count === null
                            ? 0
                            : val.gd_rejected_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeGDRejectStudentList
                            appointment_idx={val.id}
                          ></SameCollegeGDRejectStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.gd_hold_count === null ? 0 : val.gd_hold_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeGDHoldStudentList
                            appointment_idx={val.id}
                            gdHold="5"
                          ></SameCollegeGDHoldStudentList>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.final_shortlist_count === null
                            ? 0
                            : val.final_shortlist_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeFinalShortlist
                            appointment_idx={val.id}
                            finalShortlist="5"
                          ></SameCollegeFinalShortlist>
                        </p>
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {" "}
                          {val.final_reject_count === null
                            ? 0
                            : val.final_reject_count}
                        </p>
                        &nbsp;&nbsp;
                        <p
                          style={{
                            fontSize: "1px",
                          }}
                        >
                          {" "}
                          <SameCollegeRejectlist
                            appointment_idx={val.id}
                            finalReject="5"
                          ></SameCollegeRejectlist>
                        </p>
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
