import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import { FormControl } from "@material-ui/core";
import { toast } from "react-toastify";
import AxiosInstance from "../../../apis/AxiosInstance";
import moment from "moment/moment";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const EditPostAddressing = () => {
  let { state } = useLocation();
  const obj = { ...state.videos };
  const [videoData, setVideoData] = useState(
    JSON.parse(JSON.stringify(state.videos))
  );

  let navigate = useNavigate();

  const [is_broadcaster, setIs_broadcaster] = useState(
    state.is_broadcaster == true ? true : false
  );

  // all appointments
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColgOptions, setSelectedColgOptions] = useState([]);
  const [colgOptions, setColgOptions] = useState([]);

  const [addFacultyOptions, setAddFacultyOptions] = useState([]);
  const [addressing_faculty, setAddressing_faculty] = useState([]);
  const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(
    state.is_offline === true ? 1 : 2
  );

  // for filtering
  const [appointmentDateFilter, setAppointmentDateFilter] = useState("");

  let handleAppointmentDateFilter = (e) => {
    setAppointmentDateFilter(e.target.value);
  };

  //Declaring New States

  const newStateData = { ...obj };
  const [newSelectedColgOptions, setNewSelectedColgOptions] = useState([]);
  const [newExamTypeList, setNewExamTypeList] = useState(
    state.is_offline === true ? 1 : 2
  );
  const [newAddressing_faculty, setNewAddressing_faculty] = useState([]);
  const [newIs_broadcaster, setNewIs_broadcaster] = useState(
    state.is_broadcaster === true ? true : false
  );

  let handleSubmit = async (ex) => {
    ex.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    const obj = {
      id: state.id,
      is_broadcaster: is_broadcaster,
      is_offline: examTypeList === "1" ? true : false,
    };
    if (
      JSON.stringify(newSelectedColgOptions) !==
      JSON.stringify(selectedColgOptions)
    ) {
      const addedAppointments = selectedColgOptions.filter(
        (obj1) => newSelectedColgOptions.indexOf(obj1) === -1
      );
      const removedAppointments = newSelectedColgOptions.filter(
        (obj1) => selectedColgOptions.indexOf(obj1) === -1
      );
      obj["appointment_id"] = addedAppointments?.map((e) => e.id) || [];
      obj["remove_appointment"] = removedAppointments?.map((e) => e.id) || [];
    } else {
      obj["appointment_id"] = [];
      obj["remove_appointment"] = [];
    }
    if (
      JSON.stringify(newAddressing_faculty) !==
      JSON.stringify(addressing_faculty)
    ) {
      const addedFaculty = addressing_faculty.filter(
        (obj1) => newAddressing_faculty.indexOf(obj1) == -1
      );
      const removedFaculty = newAddressing_faculty.filter(
        (obj1) => addressing_faculty.indexOf(obj1) == -1
      );
      obj["addressing_faculty_id"] = addedFaculty?.map((e) => e.id) || [];
      obj["remove_addressing_faculty_id"] =
        removedFaculty?.map((e) => e.id) || [];
    } else {
      obj["addressing_faculty_id"] = [];
      obj["remove_addressing_faculty_id"] = [];
    }

    if (JSON.stringify(videoData) !== JSON.stringify(newStateData)) {
      if (Object.keys(videoData).length < Object.keys(newStateData).length) {
        const editVideos = {};
        Object.keys(videoData)
          .filter((e) => editedIds.includes(e))
          .forEach((videoId, ind) => {
            const video = videoData[videoId];
            editVideos[videoId] = {
              start_date: moment(video.start_time)
                .utc()
                .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
              end_date: moment(video.end_time)
                .utc()
                .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
            };
          });
        obj["edit_videos"] = editVideos;
        const removedVideos = Object.keys(newStateData)
          .filter((e) => !Object.keys(videoData).includes(e))
          .map((e) => Number(e));
        obj["remove_video_id"] = removedVideos;
      } else {
        const editVideos = {};
        Object.keys(videoData)
          .filter((e) => editedIds.includes(e))
          .forEach((videoId, ind) => {
            const video = videoData[videoId];
            editVideos[videoId] = {
              start_date: moment(video.start_time)
                .utc()
                .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
              end_date: moment(video.end_time)
                .utc()
                .format("YYYY-MM-DD HH:mm:00 +SSSS[ UTC]"),
            };
          });
        obj["edit_videos"] = editVideos;
      }
    } else {
      obj["edit_videos"] = {};
      obj["remove_video_id"] = [];
    }
    try {
      const payload = obj;
      await AxiosInstance.patch("/post_addressing", payload, {
        "content-type": "multipart/form-data",
        Authorization: `${token}`,
      })
        .then((response) => {
          toast.success("Successfully Updated Onboarding");
          if (response.status == 200) {
            setTimeout(() => {
              navigate("/post_addressing_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  //! ================================================API CALLS=============================================================

  // Exam type

  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      const keys = Object.keys(state.addressing_faculty);
      try {
        if (keys.length > 0) {
          let addFacultyData = await AxiosInstance.get(
            `/user_mini?role_ids=11&is_not_blocked=${true}&user_id=${keys
              .toString()
              .split(",")
              .join("&user_id=")}`
          );

          let finalAddFaculty = addFacultyData.data.data
            ? addFacultyData.data.data
            : [];
          setAddressing_faculty(finalAddFaculty);
          setNewAddressing_faculty(finalAddFaculty);
        }
      } catch (error) {
        if (keys.length > 0) {
          setAddressing_faculty([]);
          setNewAddressing_faculty([]);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchAddressingFaculty !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setAddFacultyOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setAddFacultyOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchAddressingFaculty]);

  // useEffect(() => {
  //   let fetchData1 = async (e) => {
  //     if (searchTerm && searchTerm.length > 2) {
  //       // get all  appointment
  //       let allAppointments = await AxiosInstance.get(
  //         `/appointment_mini?search_keyword=${searchTerm}&limit=10`
  //       );
  //       let finalAppointments = allAppointments.data.data;
  //       if (finalAppointments) {
  //         setColgOptions(finalAppointments);
  //       }
  //     } else if (searchTerm === "" || searchTerm === null) {
  //       // get all  appointment
  //       let allAppointments = await AxiosInstance.get(
  //         `/appointment_mini?limit=10`
  //       );
  //       let finalAppointments = allAppointments.data.data;
  //       setColgOptions(finalAppointments);
  //     } else {
  //       // get all  appointment
  //       let allAppointments = await AxiosInstance.get(
  //         `/appointment_mini?limit=10`
  //       );
  //       let finalAppointments = allAppointments.data.data;
  //       setColgOptions(finalAppointments);
  //     }
  //   };
  //   fetchData1();
  // }, [searchTerm, selectedColgOptions]);

  useEffect(() => {
    let fetchData2 = async () => {
      try {
        if (appointmentDateFilter !== "") {
          let apiUrl;

          if (searchTerm !== "") {
            // get appointments based on addressing date and search keyword
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&search_keyword=${searchTerm}&limit=10`;
          } else {
            // get appointments based only on addressing date
            apiUrl = `appointment_mini?appointment_date=${appointmentDateFilter}&limit=10`;
          }

          let allAddressingAppointments = await AxiosInstance.get(apiUrl);
          let finalAppointmentsDate = allAddressingAppointments.data.data;
          setColgOptions(finalAppointmentsDate ? finalAppointmentsDate : []);
        } else if (searchTerm !== "") {
          // get appointments based only on search keyword
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?search_keyword=${searchTerm}&limit=10`
          );
          let finalAppointmentsSearch = allAddressingAppointments.data.data;
          setColgOptions(
            finalAppointmentsSearch ? finalAppointmentsSearch : []
          );
        } else {
          // get all appointments when neither date nor search term is selected
          let allAddressingAppointments = await AxiosInstance.get(
            `appointment_mini?limit=10`
          );
          let finalAppointmentsAll = allAddressingAppointments.data.data;
          setColgOptions(finalAppointmentsAll ? finalAppointmentsAll : []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData2();
  }, [searchTerm, appointmentDateFilter]);

  useEffect(() => {
    let ids1 =
      state.appointment_ids && Object.keys(state.appointment_ids).length > 0
        ? Object.keys(state.appointment_ids)
        : "";
    const id = ids1 && ids1.length > 0 ? `?id=${ids1.join("&id=")}` : "";
    let fetchData = async () => {
      let { data } = await AxiosInstance.get(`/appointment_mini${id}`);
      setSelectedColgOptions(data.data);
      setNewSelectedColgOptions(data.data);
    };
    if (id) fetchData();
  }, []);

  const handleBroadCaster = (event) => {
    setIs_broadcaster(event.target.checked);
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const obj = { ...videoData };
    obj[id][name] = value;
    setVideoData(obj);
  };
  const [deletedVideos, setDeletedVideos] = useState([]);
  const handleRemove = (id) => {
    const obj = { ...videoData };
    delete obj[id];
    setDeletedVideos([...deletedVideos, id]);
    setVideoData(obj);
  };
  const handleCancelClick = () => {
    window.history.back();
  };
  const [editedIds, setEditedIds] = useState([]);
  return (
    <div>
      <section style={{ width: "100%", height: "auto", }}>
        <article
          style={{
            width: "98%",
            height: "auto",
            margin: "0 auto",
            marginTop: "4.5%",
            border:"1px solid gray"
          }}
        >
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
             
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#081f37",
                fontSize: {
                  xs: "20px", // Smaller font size on small screens
                  sm: "25px", // Medium font size on small to medium screens
                  md: "30px", // Larger font size on medium to large screens
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "1.02px",
                justifyContent: "center",
                margin:"0 auto"
              }}
            >
              EDIT ONBOARDING
            </Box>
          </Box>
            <Paper variant="outlined">           
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper sx={{ padding: 1 }} >
                    <form onSubmit={handleSubmit}>
                      <Box
                        component={"section"}
                        sx={{
                          boxShadow:
                            " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                          padding: "5px",
                          marginBottom: "10px",
                          outline: "1px solid #D6E4E5",
                          borderLeft: "6px solid #9BA4B5",
                          borderRadius: "10px",
                          
                        }}
                      >
                        <Typography
                          // variant="h6"
                          sx={{
                            // marginBottom: 1,
                            fontWeight: "bold",
                            color: "#00337C",
                          }}
                        >
                          All Appointments
                        </Typography>

                        <Grid
                          container
                          spacing={1}
                          item={12}
                          alignItems="flex-end"
                          // style={{ marginBottom: "15px" }}
                        >
                          <Grid item md={4}>
                            <Item>
                              <TextField
                                type="datetime-local"
                                fullWidth
                                id="addressingDate"
                                label="Appointment Date"
                                name="addressing_date"
                                onFocus={(e) => (e.target.type = "date")}
                                onBlur={(e) => (e.target.type = "text")}
                                autoFocus
                                value={appointmentDateFilter}
                                onChange={handleAppointmentDateFilter}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            </Item>
                          </Grid>
                          <Grid item md={12}>
                            <Item
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                              }}
                            >
                              <Autocomplete
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                multiple
                                required
                                options={colgOptions ? colgOptions : []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.name}</li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    required={selectedColgOptions.length === 0}
                                    label="Select Multiple Appointments"
                                    placeholder="Select Multiple Appointments"
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                                value={selectedColgOptions}
                                onChange={(e, value, ex, ey) => {
                                  if (ex === "selectOption") {
                                    if (selectedColgOptions.length === 0) {
                                      setSelectedColgOptions(value);
                                    } else if (
                                      selectedColgOptions.filter(
                                        (e) => e.id === ey.option.id
                                      ).length === 0
                                    ) {
                                      setSelectedColgOptions(value);
                                    } else {
                                      setSelectedColgOptions(
                                        selectedColgOptions
                                      );
                                    }
                                  } else {
                                    setSelectedColgOptions(value);
                                  }
                                }}
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid
                        container
                        spacing={2}
                        item={12}
                        // sx={{ marginTop: "17px" }}
                      >
                        <Grid item xs={2}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Mode Of Addressing
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              displayEmpty
                              className="my-select"
                              inputProps={{
                                "aria-label": "Select an option",
                              }}
                              value={examTypeList}
                              onChange={(e) => {
                                setExamTypeList(e.target.value);
                              }}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 5)", 
                                },
                              }}
                            >
                              <MenuItem value="" disabled>
                                <span className="my-select-placeholder">
                                  Select Mode
                                </span>
                              </MenuItem>
                              {Object.entries(examTypeListOption).map(
                                ([key, value]) => (
                                  <MenuItem key={key} value={key}>
                                    {value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          md={4}
                          onChange={(e) => {
                            setSearchAddressingFaculty(e.target.value);
                          }}
                          style={{ marginTop: "1.2%" }}
                        >
                          <Autocomplete
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            required
                            multiple
                            options={addFacultyOptions ? addFacultyOptions : []}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.full_name}
                            renderOption={(props, option) => (
                              <li {...props}>{option.full_name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required={addressing_faculty.length === 0}
                                label="Select Multiple Addressing Faculty"
                                placeholder="Select Multiple Addressing Faculty"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },
                                }}
                              />
                            )}
                            value={addressing_faculty}
                            onChange={(e, value) => {
                              setAddressing_faculty(value);
                            }}
                          />
                        </Grid>

                        {videoData && Object.keys(videoData).length > 0 ? (
                          Object.entries(videoData).map((video, index) => {
                            // const start_time = video[1].start_time.includes(
                            //   "UTC"
                            // )
                            //   ? moment
                            //       .utc(video[1].start_time)
                            //       .local()
                            //       .format("YYYY-MM-DDTHH:mm:ss.SSS")
                            //   : video[1].start_time;
                            // const end_time = video[1].end_time.includes("UTC")
                            //   ? moment
                            //       .utc(video[1].end_time)
                            //       .local()
                            //       .format("YYYY-MM-DDTHH:mm:ss.SSS")
                            //   : video[1].end_time;
                            const start_time = video[1].start_time.includes(
                              "UTC"
                            )
                              ? moment
                                  .utc(
                                    video[1].start_time,
                                    "YYYY-MM-DD HH:mm:ss +SSSS [UTC]"
                                  )
                                  .local()
                                  .format("YYYY-MM-DDTHH:mm:ss.SSS")
                              : video[1].start_time;
                            const end_time = video[1].end_time.includes("UTC")
                              ? moment
                                  .utc(
                                    video[1].end_time,
                                    "YYYY-MM-DD HH:mm:ss +SSSS [UTC]"
                                  )
                                  .local()
                                  .format("YYYY-MM-DDTHH:mm:ss.SSS")
                              : video[1].end_time;

                            return (
                              <Fragment key={index}>
                                <Grid item xs={12} style={{ display: "flex" }}>
                                  <Grid item xs={4}>
                                    <h4>Videos</h4>
                                    <FormControl fullWidth>
                                      <Item>
                                        <TextField
                                          name="videos"
                                          fullWidth
                                          type="text"
                                          label="Videos"
                                          disabled
                                          id="videos"
                                          value={video[1].name}
                                          // onChange={(e) =>
                                          //   // setVideos(e.target.value)
                                          // }
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              borderColor: "rgba(0, 0, 0, 10)",
                                              "& fieldset": {
                                                borderColor: "rgba(0, 0, 0, 5)",
                                              },
                                            },
                                          }}
                                        />
                                      </Item>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <h4> Select Start Date</h4>
                                    <FormControl fullWidth>
                                      <Item>
                                        <TextField
                                          name="start_time"
                                          fullWidth
                                          type="datetime-local"
                                          id="start_time"
                                          required
                                          value={start_time}
                                          onChange={(e) => {
                                            handleChange(e, video[0]);
                                            if (!editedIds.includes(video[0])) {
                                              setEditedIds([
                                                ...editedIds,
                                                video[0],
                                              ]);
                                            }
                                            
                                            // setEditedIds()
                                          }}
                                          // inputProps={{
                                          //   min: new Date().toISOString().slice(0, 16),
                                          // }}
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              borderColor: "rgba(0, 0, 0, 10)",
                                              "& fieldset": {
                                                borderColor: "rgba(0, 0, 0, 5)",
                                              },
                                            },
                                          }}
                                        />
                                      </Item>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <h4> Select End Date</h4>
                                    <FormControl fullWidth>
                                      <Item>
                                        <TextField
                                          name="end_time"
                                          fullWidth
                                          type="datetime-local"
                                          id="end_time"
                                          required
                                          value={end_time}
                                          onChange={(e) => {
                                            handleChange(e, video[0]);
                                            if (!editedIds.includes(video[0])) {
                                              setEditedIds([
                                                ...editedIds,
                                                video[0],
                                              ]);
                                            }
                                          }}
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              borderColor: "rgba(0, 0, 0, 10)",
                                              "& fieldset": {
                                                borderColor: "rgba(0, 0, 0, 5)",
                                              },
                                            },
                                          }}
                                          // inputProps={{
                                          //   min: new Date().toISOString().slice(0, 16),
                                          // }}
                                        />
                                      </Item>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      style={{
                                        background: "red",
                                        color: "#fff",
                                        marginTop: "40px",
                                        marginLeft: "30px",
                                      }}
                                      onClick={() => handleRemove(video[0])}
                                    >
                                      Remove
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Fragment>
                            );
                          })
                        ) : (
                          <div
                            style={{
                              marginTop: "1%",
                              background: "#DDE6ED",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h1>No Video Found</h1>
                          </div>
                        )}

                        <br />
                        <Grid item md={12} sx={{ marginTop: "5px" }}>
                          <Grid container spacing={4}>
                            <Grid item>
                              <Checkbox
                                name="is_broadcaster"
                                checked={is_broadcaster}
                                onChange={handleBroadCaster}
                                id="broadcaster"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                                disabled={examTypeList === "1"}
                              />
                              &nbsp;
                              <label
                                htmlFor="broadcaster"
                                style={{ fontSize: "16px" }}
                              >
                                Is Broadcaster
                              </label>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          spacing={2}
                          style={{
                            textAlign: "right",
                            padding: "5px",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ width: "10%", backgroundColor: "crimson" }}
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              width: "10%",
                              backgroundColor: "#1976D2",
                              fontWeight: "bold",
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>{" "}
         
        </article>
      </section>
    </div>
  );
};

export default EditPostAddressing;
