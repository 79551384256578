import React from "react";
import { Box } from "@mui/material";
import LandingPage_2 from "./LandingPage_2";

const BdLandingPage = () => {
  return (
    <Box>
      <LandingPage_2 />   
    </Box>
  );
};

export default BdLandingPage;
