import * as React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { useSpring, animated } from "@react-spring/web";
import { useState, useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Modal, Button, Collapse } from "@mui/material";
import { PostContext } from "../bdTree/ContextApi";
import AxiosInstance from "../../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import Styles from "../spiltingBdTree/tree.module.css";
import { toast } from "react-toastify";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0C134F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 9l6 6 6-6" />
      </svg>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      tslint:disable-next-line: max-line-length
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0C134F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0C134F"
        strokeWidth="4"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </SvgIcon>
  );
}
function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = styled(TreeItem)(({ rootNode }) => {
  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 28.5,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #27374D` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #27374D`,
    },
  };
});

const StyledTreeItem1 = styled(TreeItem)(({ rootNode }) => {
  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 50,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #27374D` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #27374D`,
    },
  };
});

let CollegeTree = () => {
  const { colleges, appointments, setAppointments, expanded, setExpanded } =
    React.useContext(PostContext);

  // =====================trail incubation=========================
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    load: false,
    error: "",
    length: 0,
  });
  const [load, setLoad] = useState(false);
  const fetchAppointMents = async (aid) => {
    setLoad(true);
    try {
      const { data } = await AxiosInstance.get(
        `/appointment?college_aid=${aid}`
      );
      setAppointments(data?.data || []);
      setPagination({ ...pagination, length: data.filtered_count, page: 1 });
      setLoad(false);
    } catch (error) {
      setLoad(null);
      console.log(error);
      toast.error(error?.response?.data?.error || "No data found");
    }
  };
  const loadMoreAppoinments = async (aid) => {
    setPagination({
      ...pagination,
      load: true,
    });
    try {
      const { data } = await AxiosInstance.get(
        `/appointment?college_aid=${aid}&page=${pagination.page+1}`
      );
      setAppointments([...appointments, ...data.data]);
      setPagination({
        ...pagination,
        length: data.filtered_count,
        page: pagination.page + 1,
        load:
          Math.ceil(data.filtered_count / pagination.limit) ===
          pagination.page + 1
            ? null
            : false,
      });
    } catch (error) {
      console.log(error);
      setPagination({
        ...pagination,
        load: true,
      });
      toast.error(error?.response?.data?.error || "No data found");
    }
  };
  return (
    <div style={{ marginTop: "0%" }} id="mastreTree">
      {colleges.length > 0 ? (
        <section id="tree2">
          <TreeView
            aria-label="customized"
            className="treeCont"
            defaultCollapseIcon={<MinusSquare className={Styles.minusSquare} />}
            defaultExpandIcon={<PlusSquare className={Styles.plusSquare} />}
            defaultEndIcon={<CloseSquare className={Styles.closeSquare} />}
            sx={{
              flexGrow: 1,
            }}
          >
            {colleges.map((top, index) => {
              return (
                <TreeView
                  className="treeCont"
                  defaultCollapseIcon={
                    <MinusSquare className={Styles.minusSquare} />
                  }
                  defaultExpandIcon={
                    <PlusSquare className={Styles.plusSquare} />
                  }
                  defaultEndIcon={
                    <CloseSquare className={Styles.closeSquare} />
                  }
                  key={"topic" + top.id}
                  onNodeToggle={(e, x) => {
                    setExpanded(x);
                    // console.log(x)
                  }}
                >
                  <StyledTreeItem
                    style={{
                      paddingLeft: "20px",
                    }}
                    onClick={() => {
                      fetchAppointMents(top.aid);
                    }}
                    nodeId={`college-${top.aid}`}
                    id={`node+${index}`}
                    label={
                      <section
                        className="topicBranch"
                        style={{
                          overflow: "clip",
                          padding: "16px",
                          borderBottom: "2px solid white",
                          color: "#0C134F",
                          fontWeight: "bold",
                        }}
                      >
                        <Tooltip
                          title={`${top.name}`}
                          draggable
                          placement="bottom"
                          disableFocusListener
                          disableTouchListener
                          arrow
                        >
                          <span
                            style={{
                              fontFamily: " 'Poppins', sans-serif",
                              width: "5% ! important",
                            }}
                          >
                            {top.name.slice(0, 25)}
                          </span>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "40%",
                              background: "#CBFFA9",
                              width: "auto",
                              border: "none",
                              cursor: "pointer",
                              padding: "0px 4px",
                            }}
                          >
                            {top.appointment_count === null
                              ? 0
                              : top.appointment_count}
                          </button>
                        </span>
                      </section>
                    }
                  >
                    <>
                      {load ? (
                        <small>Loading...</small>
                      ) : load !== null ? (
                        <>
                          {appointments
                            ? appointments.map((apps) => {
                                return (
                                  <>
                                    <StyledTreeItem1
                                      style={{
                                        paddingLeft: "20px",
                                      }}
                                      // nodeId={`appointment-${apps.id}~college-${top.id}`}
                                      key={"apps" + apps.id}
                                      label={
                                        <section
                                          className={Styles.appointmentTree}
                                          style={{
                                            overflow: "clip",

                                            borderBottom: "2px solid white",
                                            color: "#0C134F",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <Tooltip
                                            title={`${apps.name}`}
                                            draggable
                                            placement="bottom"
                                            disableFocusListener
                                            disableTouchListener
                                            arrow
                                          >
                                            <span
                                              style={{
                                                fontFamily:
                                                  " 'Poppins', sans-serif",
                                                width: "5% ! important",
                                                color: "purple",
                                              }}
                                            >
                                              {apps.name.slice(0, 25)}
                                            </span>
                                          </Tooltip>
                                          <span
                                            style={{
                                              position: "relative",
                                            }}
                                          >
                                            <small
                                              style={{
                                                position: "absolute",
                                                left: "-30px",
                                              }}
                                            ></small>
                                          </span>
                                        </section>
                                      }
                                    ></StyledTreeItem1>
                                  </>
                                );
                              })
                            : "No Appointments Found"}
                          {pagination.load ? (
                            <small style={{ color: "#2F58CD" }}>
                              Loading...
                            </small>
                          ) : pagination.load !== null &&
                            pagination.length > 10 ? (
                            <Button
                              onClick={() => {
                                loadMoreAppoinments(top.aid);
                              }}
                              variant="text"
                              size="small"
                              style={{
                                fontSize: "10px",
                                marginTop: "-23px",
                                backgroundColor: "#2F58CD",
                                padding: "2.5px 10px",
                                variant: "text",
                                color: "#fff",
                                fontWeight: "500",
                                borderRadius: "20px",
                              }}
                            >
                              Load more Appointments
                            </Button>
                          ) : null}
                        </>
                      ) : (
                        "No data"
                      )}
                    </>
                  </StyledTreeItem>
                </TreeView>
              );
            })}
          </TreeView>
        </section>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default CollegeTree;
