import { useState } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Checkbox,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../../apis/AxiosInstance";
import styled from "@emotion/styled";
const Item = styled(Paper)(({ theme }) => ({}));
const ArchiveCollege = () => {
  let Navigate = useNavigate();
  let { state } = useLocation();

  console.log("state", state);

  const [full_name, setfull_name] = useState("");
  const [isValid, setIsValid] = useState(false);

  const defaultItem = { number: "", archive: false, isPersonal: false };
  const defaultEmailItem = {
    address: "",
    archive: false,
    isPersonal: false,
  };

  let [landlines, setLandlines] = useState(
    state.Landline?.length
      ? state.Landline.map((landline) => ({
          number: landline.landline,
          archive: false,
          isPersonal: landline.is_personal, // Use the actual value from state
        }))
      : []
  );

  let [mobiles, setmobiles] = useState(
    state.Mobile?.length
      ? state.Mobile.map((mobile) => ({
          number: mobile.mobile,
          archive: false,
          isPersonal: mobile.is_personal, // Use the actual value from state
        }))
      : []
  );

  const [emails, setEmails] = useState(
    state.Email?.length
      ? state.Email.map((email) => ({
          address: email.email,
          archive: false,
          isPersonal: email.is_personal, // Use the actual value from state
        }))
      : []
  );

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  const handleMobChange = (val, index) => {
    let { value } = val.target;
    let list = [...mobiles];
    list[index].number = value;
    setmobiles(list);
  };

  const handleMobileArchive = (index, event) => {
    let list = [...mobiles];
    list[index].archive = event.target.checked;
    setmobiles(list);
  };

  const handleMobileIsPersonal = (index, event) => {
    let list = [...mobiles];
    list[index].isPersonal = event.target.checked;
    setmobiles(list);
  };

  const handleLandlineChange = (val, index) => {
    let { value } = val.target;
    let list = [...landlines];
    list[index].number = value;
    setLandlines(list);
  };

  const handleLandlineArchive = (index, event) => {
    let list = [...landlines];
    list[index].archive = event.target.checked;
    setLandlines(list);
  };

  const handleLandlineIsPersonal = (index, event) => {
    let list = [...landlines];
    list[index].isPersonal = event.target.checked;
    setLandlines(list);
  };

  const handleEmailChange = (val, index) => {
    let { value } = val.target;
    let list = [...emails];
    list[index].address = value;
    setEmails(list);
  };

  const handleEmailArchive = (index, event) => {
    let list = [...emails];
    list[index].archive = event.target.checked;
    setEmails(list);
  };

  const handleEmailIsPersonal = (index, event) => {
    let list = [...emails];
    list[index].isPersonal = event.target.checked;
    setEmails(list);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     await AxiosInstance.patch(`/archive_college_spoc`)
  //       .then((data) => {
  //         toast.success("Successfully updated");
  //         setTimeout(() => {
  //           Navigate("/college_spoc_list");
  //         }, 1500);
  //       })
  //       .catch((error) => {
  //         if (error.response.data) {
  //           toast.error(error.response.data.error);
  //         } else {
  //           toast.error("Something Went wrong");
  //         }
  //       });
  //   } catch (error) {
  //     if (error.response) {
  //       toast.error(error.response.data.error);
  //     } else {
  //       toast.error("Something went Wrong Please Try Again");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare data object
    const archiveData = {
      id: state.id,
      archive_email: emails
        .filter((email) => email.archive)
        .map((email) => email.address)
        .join(", "), // Assuming multiple emails can be archived and joined as a comma-separated string
      archive_mobile: mobiles
        .filter((mobile) => mobile.archive)
        .map((mobile) => mobile.number)
        .join(", "), // Same assumption for mobiles
      archive_landline: landlines
        .filter((landline) => landline.archive)
        .map((landline) => landline.number)
        .join(", "), // Same assumption for landlines
    };

    try {
      await AxiosInstance.patch(`/archive_college_spoc`, archiveData)
        .then((data) => {
          toast.success("Successfully updated");
          setTimeout(() => {
            Navigate("/college_spoc_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong. Please try again");
      }
    }
  };

  const paperStyle = {
    padding: 10,
    gap: 0,
    width: "98%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "2px solid #5F6F65",
  };
  const avatarStyle = { backgroundColor: "#06113C" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#06113C",
    color: "#fff",
    border: "1px solid #06113C",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const inputRowStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  };

  return (
    <Grid container style={{ marginTop: "5%" }}>
      <Grid xs={12} item style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Archive</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container spacing={1}>
              <Grid item xs={12} lg={4} md={4}>
                <TextField
                  color="warning"
                  disabled
                  size="small"
                  // sx={{ minWidth: 210 }}
                  fullWidth
                  name="full_f_name"
                  label="Fullname"
                  value={state.name}
                  onChange={(e) => setfull_name(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid container spacing={1} style={{ marginTop: "0.5%" }}>
                {mobiles.length > 0 && (
                  <Grid item xs={4}>
                    <Card
                      sx={{
                        minWidth: 275,
                        marginBottom: "20px",
                        border: "1px solid #111",
                      }}
                    >
                      <CardContent>
                        <h3 variant="h5" component="div">
                          Mobiles
                        </h3>
                        {mobiles.map((item, index) => (
                          <div style={{ marginTop: "3%" }}>
                            <div style={inputRowStyle} key={index}>
                              <Grid item xs={12} lg={4.5} md={4.5}>
                                <Checkbox
                                  name="is_mobile_archive"
                                  fullWidth
                                  checked={item.archive}
                                  onChange={(e) =>
                                    handleMobileArchive(index, e)
                                  }
                                  id={`is_mobile_archive_${index}`}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor={`is_mobile_archive_${index}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  archive
                                </label>
                              </Grid>
                              <Grid item xs={12} lg={4.5} md={4.5}>
                                <TextField
                                  fullWidth
                                  disabled
                                  type="tel"
                                  label="Mobile"
                                  variant="outlined"
                                  onKeyPress={preventMinus}
                                  inputProps={{
                                    minLength: 10,
                                    maxLength: 10,
                                  }}
                                  name="mobile"
                                  required
                                  value={item.number}
                                  onChange={(e) => handleMobChange(e, index)}
                                  style={{ marginRight: "10px" }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} lg={3} md={3}>
                                <Checkbox
                                  name="is_mobile_personal"
                                  fullWidth
                                  disabled
                                  checked={item.isPersonal}
                                  onChange={(e) =>
                                    handleMobileIsPersonal(index, e)
                                  }
                                  id={`is_mobile_personal_${index}`}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor={`is_mobile_personal_${index}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  Is Personal
                                </label>
                              </Grid>
                            </div>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {emails.length > 0 && (
                  <Grid item xs={4}>
                    <Card
                      sx={{
                        minWidth: 275,
                        marginBottom: "20px",
                        border: "1px solid #111",
                      }}
                    >
                      <CardContent>
                        <h3 variant="h5" component="div">
                          Emails
                        </h3>
                        {emails.map((item, index) => (
                          <div style={{ marginTop: "3%" }}>
                            <div style={inputRowStyle} key={index}>
                              <Grid item xs={12} lg={4.5} md={4.5}>
                                <Checkbox
                                  name="is_email_archive"
                                  fullWidth
                                  checked={item.archive}
                                  onChange={(e) => handleEmailArchive(index, e)}
                                  id={`is_email_archive_${index}`}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor={`is_email_archive_${index}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  archive
                                </label>
                              </Grid>
                              <Grid item xs={12} lg={4.5} md={4.5}>
                                <TextField
                                  fullWidth
                                  disabled
                                  type="email"
                                  label="Email"
                                  variant="outlined"
                                  name="email"
                                  required
                                  value={item.address}
                                  onChange={(e) => handleEmailChange(e, index)}
                                  style={{ marginRight: "10px" }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} lg={3} md={3}>
                                <Checkbox
                                  name="is_email_personal"
                                  fullWidth
                                  disabled
                                  checked={item.isPersonal}
                                  onChange={(e) =>
                                    handleEmailIsPersonal(index, e)
                                  }
                                  id={`is_email_personal_${index}`}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor={`is_email_personal_${index}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  Is Personal
                                </label>
                              </Grid>
                            </div>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {landlines.length > 0 && (
                  <Grid item xs={4}>
                    <Card
                      sx={{
                        minWidth: 275,
                        marginBottom: "20px",
                        border: "1px solid #111",
                      }}
                    >
                      <CardContent>
                        <h3 variant="h5">Landlines</h3>
                        {landlines.map((item, index) => (
                          <div style={{ marginTop: "3%" }}>
                            <div style={inputRowStyle} key={index}>
                              <Grid item xs={12} lg={4} md={4}>
                                <Checkbox
                                  name="is_landline_archive"
                                  fullWidth
                                  checked={item.archive}
                                  onChange={(e) =>
                                    handleLandlineArchive(index, e)
                                  }
                                  id={`is_landline_archive_${index}`}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor={`is_landline_archive_${index}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  archive
                                </label>
                              </Grid>
                              <Grid item xs={12} lg={5} md={5}>
                                <TextField
                                  fullWidth
                                  type="tel"
                                  disabled
                                  label="Landline"
                                  variant="outlined"
                                  onKeyPress={preventMinus}
                                  inputProps={{
                                    minLength: 8,
                                    maxLength: 10,
                                  }}
                                  name="landline"
                                  required
                                  value={item.number}
                                  onChange={(e) =>
                                    handleLandlineChange(e, index)
                                  }
                                  style={{ marginRight: "10px" }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} lg={3} md={3}>
                                <Checkbox
                                  name="is_landline_personal"
                                  fullWidth
                                  disabled
                                  checked={item.isPersonal}
                                  onChange={(e) =>
                                    handleLandlineIsPersonal(index, e)
                                  }
                                  id={`is_landline_personal_${index}`}
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />
                                <label
                                  htmlFor={`is_landline_personal_${index}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  Is Personal
                                </label>
                              </Grid>
                            </div>
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ display: "flex", gap: "20px" }}
              >
                <Grid item xs={12} lg={1} md={1}>
                  <Button
                    type="button"
                    variant="contained"
                    style={btnstyle1}
                    onClick={handleCancelClick}
                    fullWidth
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} lg={1} md={1}>
                  <Button
                    type="submit"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Archive
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ArchiveCollege;
