import * as React from "react";
import { useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Avatar,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  Tooltip,
  Modal,
} from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { AiFillCloseSquare } from "react-icons/ai";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { TiDelete } from "react-icons/ti";
import Cookies from "js-cookie";
import { FaEdit } from "react-icons/fa";

export default function EditFinalGDShortlist(props) {
  let Navigate = useNavigate();
  let { state } = props;

  let { appointment_id1 } = props;

  const [comment, setComment] = useState(
    // state.final_status.status.comment === "" ||
    //   state.final_status.status.comment === null
    //   ? ""
    //   : state.final_status.status.comment
    state?.final_status?.status?.comment ?? ""
  );
  let [status, setStatus] = useState("");
  let [statusOption, setStatusOption] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //for appointmentStatus
  // useEffect(() => {
  //   let fetchData = async () => {
  //     let appStatus = await AxiosInstance.get(`/final_status`);
  //     let finalAppStatus = appStatus.data.data;
  //     setStatusOption(finalAppStatus);
  //     setStatus(
  //       finalAppStatus.filter(
  //         (e) =>
  //           e.name ===
  //           state.final_status.status.final_status.split("_").join(" ")
  //       )[0].id
  //     );
  //   };
  //   if (open) {
  //     fetchData();
  //   }
  // }, [open]);

  useEffect(() => {
    let fetchData = async () => {
      let appStatus = await AxiosInstance.get(`/final_status`);
      let finalAppStatus = appStatus.data.data;
      setStatusOption(finalAppStatus);
  
      if (state.final_status && state.final_status.status && state.final_status.status.final_status) {
        setStatus(
          finalAppStatus.filter(
            (e) =>
              e.name ===
              state.final_status.status.final_status.split("_").join(" ")
          )[0].id
        );
      }
    };
  
    if (open) {
      fetchData();
    }
  }, [open]);
  

  const resetAppStatus = async (e) => {
    e.preventDefault();
    setStatus("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      const payload = {
        appointment_id: Number.parseInt(appointment_id1),
        student_id: [state.id],
        comment: comment,
        status: status,
      };
      await AxiosInstance.post("/student_final_status", payload, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Edit Final Shortlist Done");
            setTimeout(() => {
              setOpen(false);           
              Navigate("/tree_roundsto_shortlist");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,

    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#081F37" };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Change Status">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <FaEdit
            fontSize={18}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
              cursor: "pointer",
            }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid style={{ marginTop: "7%" }}>
          <Grid xs={12} item container style={gridContainer} align="center">
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center" item>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <AiFillCloseSquare
                    fontSize={30}
                    onClick={handleClose}
                    color="#FC2947"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Avatar style={avatarStyle}>
                  <GroupAddIcon />
                </Avatar>
                <h2>Edit Final Status</h2>
                <br />
              </Grid>
              <main
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1% auto",
                }}
              >
                <section style={{ marginTop: "1%" }}>
                  <form onSubmit={handleSubmit}>
                    <Grid style={{ marginTop: "2%" }}>
                      <Grid
                        item
                        sm={12}
                        id="refreshTool"
                        style={{ position: "relative", marginTop: "3%" }}
                      >
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            required
                            value={status}
                            label="status"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            {statusOption === null ? (
                              <MenuItem>No data Available</MenuItem>
                            ) : (
                              statusOption.map((e) => {
                                return (
                                  <MenuItem
                                    value={e.id}
                                    key={"status" + e.id}
                                    style={{ textAlign: "left" }}
                                  >
                                    <p style={{ textAlign: "left" }}>
                                      {e.name}
                                    </p>
                                  </MenuItem>
                                );
                              })
                            )}
                          </Select>
                          {status !== "" ? (
                            <Tooltip title="Clear Status">
                              <div
                                style={{
                                  position: "absolute",
                                  top: "9px",
                                  left: "380px",
                                  color: "#CC7351",
                                  cursor: "pointer",
                                }}
                              >
                                <TiDelete
                                  fontSize={24}
                                  title="Reset Status"
                                  onClick={resetAppStatus}
                                />
                              </div>
                            </Tooltip>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid style={{ marginTop: "3%" }}>
                        <TextField
                          name="comment"
                          multiline
                          maxRows={5}
                          fullWidth
                          id="comment"
                          label="Comment"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ width: "20%", marginTop: "2%" }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </section>
              </main>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
