import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import moment from "moment";
import { MdContactPhone } from "react-icons/md";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: "#40679E",
//       color: "#fff",
//       padding: "5px",
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//       padding: "7px",
//     },
//   }));

//   const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     "&:nth-of-type(odd)": {
//       backgroundColor: theme.palette.action.hover,
//     },
//     "&:last-child td, &:last-child th": {
//       border: 0,
//     },
//   }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding:"7px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding:"7px"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97vw",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius:"10px",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function CallRecordHistoryInCollegeSpoc(props) {
  let [appointment_id, setAppontment_id] = React.useState(
    props.call_record_history
  );

  let [regdStudents, setRegdStudents] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const [currentAudio, setCurrentAudio] = useState(null);

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `call_record?number=${props.call_record_history}&page=${newPage}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleAppointmentOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `call_record?number=${props.call_record_history}&page=${page}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const totalPages = Math.ceil(totalLength / 10);

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const handlePlayAudio = (audioElement) => {
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }
    setCurrentAudio(audioElement);
  };

  return (
    <div>
      <Tooltip title="Call Record History Details">
        <p
          onClick={handleAppointmentOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <MdContactPhone
            fontSize={18}
            style={{
              color: "#03346E",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Call Record History Details
            <span
              style={{
                marginLeft: "50%",
                fontSize: "18px",
                color: "#06113C",
                fontWeight: "bold",
              }}
            >
              Total Count :{totalLength}
            </span>
          </Typography>

          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead
                style={{
                  background: "#081f37",
                  fontWeight: "600",
                }}
              >
                <StyledTableRow>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "70px",
                    }}
                  >
                    Sl No
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "180px",
                    }}
                  >
                    Mobile Number
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "180px",
                    }}
                  >
                    Call Date
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "170px",
                    }}
                  >
                    Call Type
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "300px",
                    }}
                  >
                    Audio
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "220px",
                      whiteSpace: "normal",
                    }}
                  >
                    Call Category
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "300px",
                      whiteSpace: "normal",
                    }}
                  >
                    Main Status
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "white",
                      width: "300px",
                      whiteSpace: "normal",
                    }}
                  >
                    Sub Status
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} align="center">
                      Loading...
                    </StyledTableCell>
                  </StyledTableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((val, ind) => {
                    return (
                      <StyledTableRow key={ind + 1}>
                        <StyledTableCell
                          align="center"
                          sx={{ fontWeight: "bold" }}
                        >
                          {calculateSlNo(ind)}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {val.number === null || val.number === ""
                            ? "N/A"
                            : val.number}
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {val.call_date === null || val.call_date === ""
                            ? "NA"
                            : moment(toIsoFormat(val?.["call_date"]))
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {val.call_type
                            ? val.call_type.call_type
                              ? val.call_type.call_type
                              : "NA"
                            : "NA"}
                        </StyledTableCell>

                        <StyledTableCell
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            width: "300px",
                          }}
                        >
                          {val.audio ? (
                            <audio
                              src={`https://incubationb.qspiders.com/api/v1/get_audio?name=${val.audio}`}
                              controls
                              controlsList="nodownload"
                              onPlay={(e) => handlePlayAudio(e.target)}
                            />
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {val.call_status &&
                          val.call_status.category_id &&
                          val.call_status.category_id.name !== ""
                            ? val.call_status.category_id.name
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {val.call_status &&
                          val.call_status.call_status &&
                          val.call_status.call_status.main_status !== ""
                            ? val.call_status.call_status.main_status
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {val.call_status &&
                          val.call_status.call_status &&
                          val.call_status.call_status.sub_status &&
                          val.call_status.call_status.sub_status.sub_status !==
                            ""
                            ? val.call_status.call_status.sub_status.sub_status
                            : "NA"}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={7}>
                      <h2 style={{ color: "red", textAlign: "center" }}>
                        No Data Found
                      </h2>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Modal>
    </div>
  );
}
