import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Styles from "../../Navbar/navLinks/_JuniorVerifierMenu.module.css";
import { toast } from "react-toastify";
import { Tooltip, Avatar, Stack } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Cookies from "js-cookie";
import DrawerJuniorVerifier from "../../../Bd_Module/pages/DrawerJuniorVerifier";

const JuniorVerifierMenu = () => {
  let handleLogout = () => {
    Cookies.remove("logged");
    Cookies.remove("current_role");
    toast.success("Logged out successfully");
    setTimeout(() => {
      window.location.assign("/login");
    }, 500);
  };

  return (
    <div>
      <section id={Styles.NavMenuContaiiner}>
        <NavLink
          to="/profile"
          style={{ textDecoration: "none", color: "white", background: "none" }}
        >
          <Stack direction="row" spacing={2}>
            <Tooltip arrow placement="bottom" title="View Profile">
              <Avatar sx={{ bgcolor: "#fff", color: "#111" }}>
                <PersonIcon size="40" />
              </Avatar>
            </Tooltip>
          </Stack>
        </NavLink>
        <DrawerJuniorVerifier title="Menu" />
        <NavLink
          onClick={handleLogout}
          style={{
            textDecoration: "none",
            color: "#fff",
            background: "none",
            fontSize: "18px",
          }}
        >
          Logout
        </NavLink>
      </section>
    </div>
  );
};

export default JuniorVerifierMenu;
