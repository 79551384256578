import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Autocomplete,
  Box,
  Grid,
  LinearProgress,
  TextField,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RiFilterOffFill } from "react-icons/ri";
import AxiosInstance from "../../apis/AxiosInstance";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CollegeListAppointmentModal from "../../Bd_Module/bd/collegeList/CollegeListAppointmentModal";
import CollegeListSpocNameModal from "../../Bd_Module/bd/collegeList/CollegeListSpocNameModal";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003285",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function CcCollegeList() {
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [totalColleges, setTotalColleges] = useState(0);

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  let [searchFilter, setSearchFilter] = useState("");

  // //! Fetch the college
  let [searchedColleges, SetsearchedColleges] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/appointmentcollege?page=${page}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        SetsearchedColleges(finalData);
        setTotalColleges(data.data.filtered_count);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/appointmentcollege?page=${newPage}`;
    setLoad(true);
    try {
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&id=${selectedCollege.id}`;
      }

      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/appointmentcollege?page=${1}`;
    try {
      if (!selectedColgState && !selectedColgDistrict && !selectedCollege) {
        toast.warning("Select at least one data to filter.");
        return;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&id=${selectedCollege.id}`;
      }

      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(1);
      setSearchSuccess(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchSuccess(false);
    }
  };

  const totalPages = Math.ceil(totalColleges / 10);

  const [searchSuccess, setSearchSuccess] = useState(false);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     //college state api
  //     if (stateSearch !== "") {
  //       let colgStateData = await AxiosInstance.get(
  //         `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${countryMiniId}`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setColgStateOptions(finalcolgStateData);
  //     } else {
  //       let colgStateData = await AxiosInstance.get(
  //         `/statemini?limit=10&country_id=${countryMiniId}`
  //       );
  //       let finalcolgStateData = colgStateData.data.data;
  //       setColgStateOptions(finalcolgStateData);
  //     }
  //   };
  //   if (countryMiniId !== 0) {
  //     fetchData();
  //   }
  // }, [stateSearch, countryMiniId]);

  const [defaultClg, setDefaultClg] = useState([]);
  //! for college district
  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (selectedColgState !== null) {
  //       let colgDistrictData = await AxiosInstance.get(
  //         `/district_mini?state_id=${selectedColgState.id}&limit=10`
  //       );
  //       let finalcolgDistrictData = colgDistrictData.data.data;
  //       setcolgDistrictOptions(finalcolgDistrictData);
  //       setDefaultClg(finalcolgDistrictData);
  //       setSearchSuccess(true);
  //     }
  //   };
  //   fetchData();
  // }, [selectedColgState]);
  // const serachClg = async (e) => {
  //   try {
  //     let colgDistrictData = await AxiosInstance.get(
  //       `/district_mini?search_keyword=${e}&state_id=${selectedColgState.id}&limit=10`
  //     );
  //     let finalcolgDistrictData = colgDistrictData.data.data;
  //     setcolgDistrictOptions(finalcolgDistrictData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setcolgDistrictOptions(defaultClg);
  //   }
  // };

  // ===================================

  // useEffect(() => {
  //   let fetchData = async (e) => {

  //       let colgDistrictData = await AxiosInstance.get(
  //         `/district_mini?limit=10`
  //       );
  //       let finalcolgDistrictData = colgDistrictData.data.data;
  //       setcolgDistrictOptions(finalcolgDistrictData);
  //       setDefaultClg(finalcolgDistrictData);
  //       setSearchSuccess(true);

  //   };
  //   fetchData();
  // }, []);
  // const serachClg = async (e) => {
  //   try {
  //     let colgDistrictData = await AxiosInstance.get(
  //       `/district_mini?search_keyword=${e}&limit=10`
  //     );
  //     let finalcolgDistrictData = colgDistrictData.data.data;
  //     setcolgDistrictOptions(finalcolgDistrictData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setcolgDistrictOptions(defaultClg);
  //   }
  // };

  const [defaultdistClg, setDefaultdistClg] = useState([]);
  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     if (selectedColgDistrict !== null) {
  //       let colgData = await AxiosInstance.get(
  //         `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
  //       );
  //       let finalcolgData = colgData.data.data;
  //       setSelectedCollegeOptions(finalcolgData);
  //       setSearchSuccess(true);
  //       setDefaultdistClg(finalcolgData);
  //     }
  //   };
  //   fetchData();
  // }, [selectedColgDistrict]);

  // const searchdistClg = async (e) => {
  //   try {
  //     let colgData = await AxiosInstance.get(
  //       `/college_mini?search_keyword=${e}&district_id=${selectedColgDistrict.id}&limit=10`
  //     );
  //     let finalcolgData = colgData.data.data;
  //     setSelectedCollegeOptions(finalcolgData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setSelectedCollegeOptions(defaultdistClg);
  //   }
  // };

  // =================================

  // useEffect(() => {
  //   let fetchData = async (e) => {
  //     let colgData = await AxiosInstance.get(`/college_mini?limit=10`);
  //     let finalcolgData = colgData.data.data;
  //     setSelectedCollegeOptions(finalcolgData);
  //     setSearchSuccess(true);
  //     setDefaultdistClg(finalcolgData);
  //   };

  //   fetchData();
  // }, []);

  // const searchdistClg = async (e) => {
  //   try {
  //     let colgData = await AxiosInstance.get(
  //       `/college_mini?search_keyword=${e}&limit=10`
  //     );
  //     let finalcolgData = colgData.data.data;
  //     setSelectedCollegeOptions(finalcolgData);
  //     setSearchSuccess(true);
  //   } catch (error) {
  //     setSelectedCollegeOptions(defaultdistClg);
  //   }
  // };

  // Fetch college states based on stateSearch and countryMiniId
  useEffect(() => {
    const fetchStateData = async () => {
      let url = `/statemini?limit=10&country_id=${countryMiniId}`;
      if (stateSearch !== "") {
        url += `&search_keyword=${stateSearch}`;
      }

      const response = await AxiosInstance.get(url);
      setColgStateOptions(response.data.data);
    };

    if (countryMiniId !== 0) {
      fetchStateData();
    }
  }, [stateSearch, countryMiniId]);

  // Fetch districts based on selectedColgState
  useEffect(() => {
    const fetchDistrictData = async () => {
      if (selectedColgState !== null) {
        const response = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        const data = response.data.data;
        setcolgDistrictOptions(data);
        setDefaultClg(data);
        setSearchSuccess(true);
      }
    };

    fetchDistrictData();
  }, [selectedColgState]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchAllDistricts = async () => {
      const response = await AxiosInstance.get(`/district_mini?limit=10`);
      const data = response.data.data;
      setcolgDistrictOptions(data);
      setDefaultClg(data);
      setSearchSuccess(true);
    };

    fetchAllDistricts();
  }, []);

  // Search colleges based on district
  const serachClg = async (searchKeyword) => {
    try {
      let url = `/district_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgState) {
        url += `&state_id=${selectedColgState.id}`;
      }

      const response = await AxiosInstance.get(url);
      setcolgDistrictOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setSelectedCollegeOptions(finalcolgData);
        setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  useEffect(() => {
    const fetchData = async () => {
      let colgData = await AxiosInstance.get(`/college_mini?limit=10`);
      let finalcolgData = colgData.data.data;
      setSelectedCollegeOptions(finalcolgData);
      setSearchSuccess(true);
      setDefaultdistClg(finalcolgData);
    };

    fetchData();
  }, []);

  const searchdistClg = async (searchKeyword) => {
    try {
      let url = `/college_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgDistrict) {
        url += `&district_id=${selectedColgDistrict.id}`;
      }

      const response = await AxiosInstance.get(url);
      setSelectedCollegeOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setSelectedCollegeOptions(defaultdistClg);
    }
  };

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    window.location.href = "/cc_college_list_data";
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <main>
      <Grid container style={{ marginTop: "4.5%" }}>
        <Box
          sx={{
            width: "100%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#081f37",
              fontSize: "25px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            College List
          </Box>
          <h1
            style={{
              color: "#06113C",
              fontSize: "17px",
              fontWeight: "bold",
              padding: "0 100px",
            }}
          >
            Total Count : {totalColleges}
          </h1>
          <span
            onClick={toggleFilterVisibility}
            style={{
              color: "#06113C",
              marginLeft: "25%",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Filter" arrow>
              {filterVisible ? (
                <>
                  <Button
                    onClick={toggleFilterVisibility}
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#B43F3F",
                      color: "#fff",
                      alignItems: "center",
                      fontSize: "13px",
                      padding: "3px",
                    }}
                  >
                    Close Filter
                    <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{
                      backgroundColor: "#06113C",
                      color: "#fff",
                      fontSize: "13px",
                      alignItems: "center",
                      padding: "3px",
                    }}
                    size="small"
                  >
                    Open Filter{" "}
                    <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                  </Button>
                </>
              )}
            </Tooltip>
          </span>
        </Box>
        {filterVisible && (
          <Grid container style={{ marginTop: "0.5%" }} gap={1}>
            <Grid item lg={2}>
              <Item
                onChange={(e) => {
                  setStateSearch(e.target.value);
                }}
              >
                <Autocomplete
                  options={colgStateOptions}
                  size="small"
                  value={selectedColgState}
                  name="state_name"
                  onChange={(event, newColgState) => {
                    setSelectedColgState(newColgState);
                    setselectedColgDistrict(null);
                    setSelectedCollege(null);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="State" variant="outlined" />
                  )}
                />
              </Item>
            </Grid>
            {/* <Grid item md={3}>
          <Item
            onChange={(e) => {
              setDistSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={selectedColgState ? colgDistrictOptions : []}
              value={selectedColgDistrict}
              name="state_name"
              onChange={(event, newColgdis) => {
                setselectedColgDistrict(newColgdis);
                setSelectedCollege(null);
              }}
              onInputChange={(x, e) => {
                if (e && e.length > 0) {
                  serachClg(e);
                } else {
                  setcolgDistrictOptions(defaultClg);
                }
                setDistSearch(e);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Colleges By District"
                  variant="outlined"                                  
                />
              )}
            />
          </Item>
        </Grid> */}
            <Grid item md={2}>
              <Item>
                <Autocomplete
                  size="small"
                  options={colgDistrictOptions}
                  value={selectedColgDistrict}
                  name="state_name"
                  onChange={(event, newColgdis) => {
                    setselectedColgDistrict(newColgdis);
                    setSelectedCollege(null);
                  }}
                  onInputChange={(x, e) => {
                    if (e && e.length > 0) {
                      serachClg(e);
                    } else {
                      setcolgDistrictOptions(defaultClg);
                    }
                    setDistSearch(e);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="District"
                      variant="outlined"
                    />
                  )}
                />
              </Item>
            </Grid>
            {/* <Grid item md={3}>
          <Item
            onChange={(e) => {
              setCollSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={selectedColgDistrict ? selectedCollegeOptions : []}
              value={selectedCollege}
              name="college_name"
              onChange={(event, newcollege) => {
                setSelectedCollege(newcollege);
              }}
              onInputChange={(x, e) => {
                if (e && e.length > 0) {
                  searchdistClg(e);
                } else {
                  setSelectedCollegeOptions(defaultdistClg);
                }
                setCollSearch(e);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search College"
                  variant="outlined"                 
                />
              )}
            />
          </Item>
        </Grid> */}
            <Grid item md={3}>
              <Item
                onChange={(e) => {
                  setCollSearch(e.target.value);
                }}
              >
                <Autocomplete
                  size="small"
                  options={selectedCollegeOptions}
                  value={selectedCollege}
                  name="college_name"
                  onChange={(event, newcollege) => {
                    setSelectedCollege(newcollege);
                  }}
                  onInputChange={(event, value) => {
                    if (value && value.length > 0) {
                      searchdistClg(value);
                    } else {
                      setSelectedCollegeOptions(defaultdistClg);
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="College" variant="outlined" />
                  )}
                />
              </Item>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleSearch}
                size="small"
                style={{
                  backgroundColor: "#003285",
                  color: "#fff",
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={ResetFilterData}
                variant="contained"
                color="warning"
                size="small"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#495579" }}>
          Total Colleges : {totalColleges} &nbsp;&nbsp;
        </h1>
      </Grid> */}

      <section >
        <TableContainer
          component={Paper}
          style={{
            maxHeight: window.innerWidth > 1800 ? 680 : 490,
          }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  District Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "400px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "400px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  University Name
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Appointment Title
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Spoc Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : searchedColleges && searchedColleges.length > 0 ? (
                searchedColleges.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.state_name} placement="bottom">
                        <p> {val.state_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.district_name} placement="bottom">
                        <p> {val.district_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.name} placement="bottom">
                        <p> {val.name.slice(0, 45)}</p>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.university_name} placement="bottom">
                        <p>
                          {val.university_name === null
                            ? "N/A"
                            : val.university_name.slice(0, 45)}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.appointment_field === null ? (
                        <button
                          style={{
                            background: "#366ED8",
                            border: "none",
                            color: "#fff",
                            listStyle: "none",
                            padding: "2px 8px",
                            fontSize: "14px",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            if (val.appointment_field === null) {
                              toast.warning("Create Spoc First");
                            }
                          }}
                        >
                          Create
                        </button>
                      ) : (
                        // (
                        //   val.appointment_field.map((val, ind) => {
                        //     return (
                        //       <Box style={{ height: "22px" }}>
                        //         {val?.["appointment_name"]}
                        //       </Box>
                        //     );
                        //   })
                        // )
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "3px",
                            }}
                          >
                            <Box>
                              {Object.keys(val.appointment_field).length}
                            </Box>
                            <CollegeListAppointmentModal state={val} />
                          </Box>
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.appointment_field === null ? (
                        <button
                          style={{
                            background: "#366ED8",
                            border: "none",
                            color: "#fff",
                            listStyle: "none",
                            padding: "2px 8px",
                            fontSize: "14px",
                            borderRadius: "10px",
                          }}
                        >
                          <Link
                            to="/cc_spoc_creation_from_collegelist"
                            state={val}
                            style={{ color: "#fff", textDecoration: "none" }}
                          >
                            Create
                          </Link>
                        </button>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "3px",
                            }}
                          >
                            <Box>
                              {val.appointment_field &&
                              val.appointment_field.length > 0 ? (
                                <Box style={{ height: "22px" }}>
                                  {val.appointment_field.reduce(
                                    (total, appointment) => {
                                      return (
                                        total +
                                        Object.keys(appointment.college_spoc)
                                          .length
                                      );
                                    },
                                    0
                                  )}
                                </Box>
                              ) : (
                                <Box style={{ height: "22px" }}>-- --</Box>
                              )}
                            </Box>
                            <CollegeListSpocNameModal state={val} />
                          </Box>

                          {/* {val.appointment_field && val.appointment_field.length > 0
                        ? val.appointment_field.map((appointment) => {
                            return (
                              appointment.college_spoc &&
                              Object.values(appointment.college_spoc).map(
                                (faculty) => (
                                  <Box style={{ height: "22px" }}>
                                    {faculty?.["name"]}
                                  </Box>
                                )
                              )
                            );
                          })
                        : "-- --"} */}
                        </>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
