import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Autocomplete,
  Box,
  Grid,
  TextField,
  Button,
  Tooltip,
  Modal,
  FormControlLabel,
  Typography,
  ButtonGroup,
  Checkbox,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { RiFilterOffFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdOutlineMoveUp } from "react-icons/md";
import { IoRemoveCircle } from "react-icons/io5";
import { FiMove } from "react-icons/fi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IoArchive } from "react-icons/io5";
import CallRecordHistoryInCollegeSpoc from "./CallRecordHistoryInCollegeSpoc";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "5px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    padding: "5px",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    padding: "5px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function CollegeSpocList() {
  let navigate = useNavigate();
  const [isSearchDisabled, setIsSearchDisabled] = useState(false);
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);
  const [searchMobile, setSearchMobile] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchName, setSearchName] = useState("");

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [totalColleges, setTotalColleges] = useState(0);

  const [inactive, setInactive] = useState(false);

  const handleCheckboxChange = (event) => {
    setInactive(event.target.checked);
  };

  const [defaultState, setDefaultState] = useState({
    escalation_list: [],
    totalEscalation: 0,
  });

  let [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/college_spoc?page=${page}&is_inactive=${inactive}`;
      setLoad(true);
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        SetsearchedColleges(finalData);
        setTotalColleges(data.data.filtered_count);
        setDefaultState({
          escalation_list: finalData,
          totalEscalation: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/college_spoc?page=${newPage}&is_inactive=${inactive}`;
    setLoad(true);
    try {
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&college_id=${selectedCollege.id}`;
      }
      if (searchEmail) {
        endpoint += `&email=${searchEmail}`;
      }
      if (searchName) {
        endpoint += `&name=${searchName}`;
      }
      if (searchMobile) {
        endpoint += `&mobile=${searchMobile}`;
      }
      // if (inactive) {
      //   endpoint += `&is_inactive=${inactive}`;
      // }

      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    let endpoint = `/college_spoc?page=${1}&is_inactive=${inactive}`;
    setIsSearchDisabled(true);
    try {
      if (
        !selectedColgState &&
        !selectedColgDistrict &&
        !selectedCollege &&
        !searchEmail &&
        !searchName &&
        !searchMobile &&
        !inactive
      ) {
        toast.warning("Select at least one data to filter.");
        return;
      }
      if (selectedColgState !== null) {
        endpoint += `&state_id=${selectedColgState.id}`;
      }
      if (selectedColgDistrict !== null) {
        endpoint += `&district_id=${selectedColgDistrict.id}`;
      }
      if (selectedCollege !== null) {
        endpoint += `&college_id=${selectedCollege.id}`;
      }
      if (searchEmail) {
        endpoint += `&email=${searchEmail}`;
      }
      if (searchName) {
        endpoint += `&name=${searchName}`;
      }
      if (searchMobile) {
        endpoint += `&mobile=${searchMobile}`;
      }
      // if (inactive) {
      //   endpoint += `&is_inactive=${inactive}`;
      // }

      const { data } = await AxiosInstance.get(endpoint);

      let finalData = data.data;
      SetsearchedColleges(finalData);
      setTotalColleges(data.filtered_count);
      setPage(1);
      setSearchSuccess(true);
      toast.success("Data filtered successfully.");
    } catch (error) {
      toast.error("Something went wrong");
      setSearchSuccess(false);
    }
  };

  // //! Fetch the college
  let [searchedColleges, SetsearchedColleges] = useState([]);
  const [load, setLoad] = useState(false);

  const [searchSuccess, setSearchSuccess] = useState(false);

  const totalPages = Math.ceil(totalColleges / 10);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  const [defaultClg, setDefaultClg] = useState([]);
  const [defaultdistClg, setDefaultdistClg] = useState([]);

  // Fetch college states based on stateSearch and countryMiniId
  useEffect(() => {
    const fetchStateData = async () => {
      let url = `/statemini?limit=10&country_id=${countryMiniId}`;
      if (stateSearch !== "") {
        url += `&search_keyword=${stateSearch}`;
      }

      const response = await AxiosInstance.get(url);
      setColgStateOptions(response.data.data);
    };

    //   if (countryMiniId !== 0) {
    //     fetchStateData();
    //   }
    // }, [stateSearch, countryMiniId]);
    if (countryMiniId !== 0 && !inactive) {
      // Only call if the checkbox is not clicked
      fetchStateData();
    }
  }, [stateSearch, countryMiniId, inactive]);

  // Fetch districts based on selectedColgState
  useEffect(() => {
    const fetchDistrictData = async () => {
      if (selectedColgState !== null) {
        const response = await AxiosInstance.get(
          `/district_mini?state_id=${selectedColgState.id}&limit=10`
        );
        const data = response.data.data;
        setcolgDistrictOptions(data);
        setDefaultClg(data);
        setSearchSuccess(true);
      }
    };

    fetchDistrictData();
  }, [selectedColgState]);

  // Fetch all districts if no state is selected
  useEffect(() => {
    const fetchAllDistricts = async () => {
      const response = await AxiosInstance.get(`/district_mini?limit=10`);
      const data = response.data.data;
      setcolgDistrictOptions(data);
      setDefaultClg(data);
      setSearchSuccess(true);
    };

    fetchAllDistricts();
  }, []);

  // Search colleges based on district
  const serachClg = async (searchKeyword) => {
    try {
      let url = `/district_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgState) {
        url += `&state_id=${selectedColgState.id}`;
      }

      const response = await AxiosInstance.get(url);
      setcolgDistrictOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setcolgDistrictOptions(defaultClg);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedColgDistrict !== null) {
        let colgData = await AxiosInstance.get(
          `/college_mini?district_id=${selectedColgDistrict.id}&limit=10`
        );
        let finalcolgData = colgData.data.data;
        setSelectedCollegeOptions(finalcolgData);
        setSearchSuccess(true);
        setDefaultdistClg(finalcolgData);
      }
    };
    fetchData();
  }, [selectedColgDistrict]);

  useEffect(() => {
    const fetchData = async () => {
      let colgData = await AxiosInstance.get(`/college_mini?limit=10`);
      let finalcolgData = colgData.data.data;
      setSelectedCollegeOptions(finalcolgData);
      setSearchSuccess(true);
      setDefaultdistClg(finalcolgData);
    };

    fetchData();
  }, []);

  const searchdistClg = async (searchKeyword) => {
    try {
      let url = `/college_mini?limit=10`;
      if (searchKeyword) {
        url += `&search_keyword=${searchKeyword}`;
      }
      if (selectedColgDistrict) {
        url += `&district_id=${selectedColgDistrict.id}`;
      }

      const response = await AxiosInstance.get(url);
      setSelectedCollegeOptions(response.data.data);
      setSearchSuccess(true);
    } catch (error) {
      setSelectedCollegeOptions(defaultdistClg);
    }
  };

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    setSearchEmail("");
    setSearchMobile("");
    setSearchName("");
    window.location.href = "/college_spoc_list";
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const handleClick = () => {
    toast.warning("Already TPO login Credentials Created");
  };

  const [filterVisible, setFilterVisible] = useState(true);

  // Function to toggle the visibility of the filter
  const toggleFilterVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const handleUserBlock = (userId, user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleYesClick = async (e) => {
    // e.preventDefault();

    const payload = {
      id: [selectedUser.id],
      is_inactive: false,
    };

    try {
      await AxiosInstance.patch("/college_spoc", payload)
        .then((response) => {
          toast.success("Successfully Inactive");
          // if (response.status === 200) {
          setTimeout(() => {
            window.location.assign("/college_spoc_list");
            // navigate("/college_spoc_list");
          }, 1000);
          // }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }

    // Close the modal
    setOpenModal(false);
  };

  const handleNoClick = () => {
    setOpenModal(false);
  };

  return (
    <main style={{ marginTop: "4.3%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          College Spoc List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count : {totalColleges}
        </h1>
        <span
          onClick={toggleFilterVisibility}
          style={{
            color: "#06113C",
            fontWeight: "bold",
            cursor: "pointer",
            padding: "0 30px",
          }}
        >
          <Tooltip title="Filter" arrow>
            {filterVisible ? (
              <>
                <Button
                  onClick={toggleFilterVisibility}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#B43F3F",
                    color: "#fff",
                    alignItems: "center",
                    fontSize: "13px",
                    padding: "3px",
                  }}
                >
                  Close Filter
                  <ArrowDropUpIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{
                    backgroundColor: "#06113C",
                    color: "#fff",
                    fontSize: "13px",
                    alignItems: "center",
                    padding: "3px",
                  }}
                  size="small"
                >
                  Open Filter <ArrowDropDownIcon style={{ fontSize: "20px" }} />
                </Button>
              </>
            )}
          </Tooltip>
        </span>
      </Box>
      {filterVisible && (
        <Grid
          container
          style={{ marginTop: "0.5%", paddingLeft: "10px" }}
          gap={1}
          onChange={(e) => {
            setStateSearch(e.target.value);
          }}
        >
          <Grid item md={1.5}>
            <Autocomplete
              options={colgStateOptions}
              value={selectedColgState}
              name="state_name"
              size="small"
              onChange={(event, newColgState) => {
                setSelectedColgState(newColgState);
                setselectedColgDistrict(null);
                setSelectedCollege(null);
                setIsSearchDisabled(false);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="State" variant="outlined" />
              )}
            />
          </Grid>
          <Grid
            item
            md={1.5}
            onChange={(e) => {
              setDistSearch(e.target.value);
            }}
          >
            <Autocomplete
              options={colgDistrictOptions}
              value={selectedColgDistrict}
              size="small"
              name="state_name"
              onChange={(event, newColgdis) => {
                setselectedColgDistrict(newColgdis);
                setSelectedCollege(null);
                setIsSearchDisabled(false);
              }}
              onInputChange={(x, e) => {
                if (e && e.length > 0) {
                  serachClg(e);
                } else {
                  setcolgDistrictOptions(defaultClg);
                }
                setDistSearch(e);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="District" variant="outlined" />
              )}
            />
          </Grid>
          <Grid
            item
            md={1.7}
            onChange={(e) => {
              setCollSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={selectedCollegeOptions}
              value={selectedCollege}
              size="small"
              name="college_name"
              onChange={(event, newcollege) => {
                setSelectedCollege(newcollege);
                setIsSearchDisabled(false);
              }}
              onInputChange={(x, e) => {
                if (e && e.length > 0) {
                  searchdistClg(e);
                } else {
                  setSelectedCollegeOptions(defaultdistClg);
                }
                setCollSearch(e);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="College" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item md={1.5}>
            <TextField
              type="tel"
              fullWidth
              size="small"
              label="Number"
              onKeyPress={preventMinus}
              onPaste={preventPasteNegative}
              inputProps={{ minLength: 10, maxLength: 10 }}
              style={{
                background: "#fff",
                borderRadius: "6px",
              }}
              value={searchMobile}
              onChange={(e) => {
                setSearchMobile(e.target.value);
                setIsSearchDisabled(false);
              }}
            />
          </Grid>
          <Grid item md={1.5}>
            <TextField
              size="small"
              label="Email"
              name="searchEmail"
              type="email"
              fullWidth
              autoComplete="user-email"
              value={searchEmail}
              onChange={(e) => {
                setSearchEmail(e.target.value);
                setIsSearchDisabled(false);
              }}
            />
          </Grid>
          <Grid item md={1.7}>
            <TextField
              size="small"
              label="Spoc Name"
              name="searchName"
              type="text"
              fullWidth
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
                setIsSearchDisabled(false);
              }}
            />
          </Grid>
          {/* <Grid item md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inactive}
                  onChange={handleCheckboxChange}
                  name="inactive"
                  color="primary"
                />
              }
              label={
                <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  Inactive
                </Typography>
              }
            />
          </Grid> */}
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleSearch}
              size="small"
              // disabled={isSearchDisabled}
              // style={{
              //   backgroundColor: isSearchDisabled ? "gray" : "#003285",
              //   color: "#fff",
              // }}
              style={{
                backgroundColor: "#003285",
                color: "#fff",
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={ResetFilterData}
              variant="contained"
              color="warning"
              size="small"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}
      {/* <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#495579" }}>
          Total College Spoc : {totalColleges} &nbsp;&nbsp;
        </h1>
      </Grid> */}

      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 680 : 490 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "250px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  Spoc Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Create TPO Login
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Edit Spoc
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Inactive
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "300px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "170px !important",
                  }}
                >
                  Landline Number
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Move College
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Remove College
                </StyledTableCell>
                {/* <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Archive
                </StyledTableCell> */}
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px !important",
                  }}
                >
                  Moved Colleges Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  District Name
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "350px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  University Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={10} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : searchedColleges && searchedColleges.length > 0 ? (
                searchedColleges.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      {val.name === null || val.name === "" ? "NA" : val.name}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(val.user_id || {}).length !== 0 ? (
                        <Button
                          onClick={handleClick}
                          size="small"
                          style={{
                            background: "#FF8E8F",
                            color: "#fff",
                            border: "none",
                            borderRadius: "10px",
                            cursor: "not-allowed",
                          }}
                        >
                          Created
                        </Button>
                      ) : (
                        <Link
                          to="/create_tpo_login"
                          state={val}
                          title="Create TPO Login Credentials"
                          style={{ cursor: "pointer" }}
                        >
                          <Button
                            size="small"
                            style={{
                              background: "#58A399",
                              color: "#fff",
                              border: "none",
                              borderRadius: "10px",
                              fontSize: "12px",
                            }}
                          >
                            Create
                          </Button>
                        </Link>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Link
                        to="/edit_spoc"
                        state={val}
                        title="Update Spoc"
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#537FE7"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                          <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                        </svg>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.is_inactive === true ? (
                        <Button
                          onClick={() => handleUserBlock(val.id, val)}
                          style={{
                            backgroundColor: "#117554",
                            color: "#fff",
                            fontSize: "13px",
                            alignItems: "center",
                            padding: "3px",
                          }}
                          size="small"
                        >
                          Active
                        </Button>
                      ) : (
                        "-----"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.Email && val.Email.length > 0 ? (
                        val.Email.map((ea, index) => (
                          <Box
                            key={index}
                            style={{
                              height: "auto",
                              overflowWrap: "break-word",
                              wordWrap: "break-word",
                            }}
                          >
                            <a
                              href={`mailto:${ea.email}`}
                              style={{
                                color: "#125B9A",
                                // textDecoration: "none",
                              }}
                            >
                              {ea.email}
                            </a>
                          </Box>
                        ))
                      ) : (
                        <Box>N/A</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        {val.Mobile && val.Mobile.length > 0 ? (
                          val.Mobile.map((ea, index) => (
                            <Box
                              key={index}
                              style={{
                                height: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                href={`tel:${ea.mobile}`}
                                style={{
                                  color: "#125B9A",
                                  // textDecoration: "none",
                                }}
                              >
                                {ea.mobile}
                              </a>
                              &nbsp;&nbsp;
                              <span style={{ fontSize: "1px" }}>
                                <CallRecordHistoryInCollegeSpoc
                                  call_record_history={ea.mobile}
                                />
                              </span>
                            </Box>
                          ))
                        ) : (
                          <Box>N/A</Box>
                        )}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.Landline && val.Landline.length > 0 ? (
                        val.Landline.map((ea, index) => (
                          <Box key={index} style={{ height: "auto" }}>
                            <a
                              href={`tel:${ea.landline}`}
                              style={{
                                color: "#125B9A",
                                // textDecoration: "none",
                              }}
                            >
                              {ea.landline}
                            </a>
                          </Box>
                        ))
                      ) : (
                        <Box>N/A</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title="Move College">
                        <Link to="/move_spoc_college" state={val}>
                          <FiMove
                            fontSize={20}
                            fontWeight="bolder"
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {Object.keys(val.colleges).length === 0 ? (
                        <IoRemoveCircle
                          fontSize={22}
                          fontWeight="bolder"
                          color="#EE4E4E"
                          style={{
                            cursor: "pointer",
                            width: "50px",
                          }}
                          onClick={() =>
                            toast.info("There is no college for this spoc")
                          }
                        />
                      ) : (
                        <Tooltip title="Remove College">
                          <Link to="/remove_spoc_college" state={val}>
                            <IoRemoveCircle
                              fontSize={25}
                              fontWeight="bolder"
                              color="#EE4E4E"
                              style={{
                                cursor: "pointer",
                                width: "50px",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      )}
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title="Archive">
                        <Link to="/archive_spoc_college" state={val}>
                          <IoArchive
                            fontSize={25}
                            fontWeight="bolder"
                            color="#40A578"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell> */}
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.college.name}>
                        <p>
                          {val && val.college && val.college.name
                            ? val.college.name.slice(0, 40)
                            : "NA"}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                    {/* <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.colleges && Object.keys(val.colleges).length > 0 ? (
                        Object.keys(val.colleges).length === 1 ? (
                          <p style={{ color: "#921A40" }}>Not Moved</p>
                        ) : (
                          Object.keys(val.colleges).map((key) => (
                            <Box key={key}>
                              <Tooltip title={val.colleges[key].name}>
                                <p>{val.colleges[key].name.slice(0, 40)}</p>
                              </Tooltip>
                            </Box>
                          ))
                        )
                      ) : (
                        "NA"
                      )}
                    </StyledTableCell> */}
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.colleges && Object.keys(val.colleges).length > 0 ? (
                        Object.keys(val.colleges).length === 1 ? (
                          // Compare the college ID from val.college.id with the key in val.colleges
                          Object.keys(val.colleges)[0] ===
                          val.college.id.toString() ? (
                            <p style={{ color: "#921A40" }}>Not Moved</p>
                          ) : (
                            // If the IDs are different, display the college name
                            <Box key={Object.keys(val.colleges)[0]}>
                              <Tooltip
                                title={
                                  val.colleges[Object.keys(val.colleges)[0]]
                                    .name
                                }
                              >
                                <p>
                                  {val.colleges[
                                    Object.keys(val.colleges)[0]
                                  ].name.slice(0, 40)}
                                </p>
                              </Tooltip>
                            </Box>
                          )
                        ) : (
                          // For more than one college, display their names
                          Object.keys(val.colleges).map((key) => (
                            <Box key={key}>
                              <Tooltip title={val.colleges[key].name}>
                                <p>{val.colleges[key].name.slice(0, 40)}</p>
                              </Tooltip>
                            </Box>
                          ))
                        )
                      ) : (
                        "NA"
                      )}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      {val && val.state && val.state.name
                        ? val.state.name
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                      }}
                    >
                      {val && val.district && val.district.name
                        ? val.district.name
                        : "NA"}
                    </StyledTableCell>

                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      <Tooltip title={val.university.name}>
                        <p>
                          {val && val.university && val.university.name
                            ? val.university.name.slice(0, 40)
                            : "NA"}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 370,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 2,
                  borderRadius: "5px",
                }}
              >
                <IconButton
                  onClick={() => setOpenModal(false)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#111",
                    fontSize: "18px",
                    fontWeight: "900",
                  }}
                >
                  Do you want to Active this Spoc?
                </Typography>
                <ButtonGroup sx={{ mt: 5 }} fullWidth>
                  <Button
                    onClick={handleYesClick}
                    style={{
                      background: "#436850",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      padding: "10px 10px",
                    }}
                  >
                    Yes
                  </Button>{" "}
                  &nbsp;&nbsp;
                  <Button
                    onClick={handleNoClick}
                    style={{
                      background: "#FF8080",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </Box>
            </Modal>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
