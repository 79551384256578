import React, { useState } from "react";
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Box,
  Container,
  Paper,
  Autocomplete,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function EditRemoveSpocCollege({ props }) {
  const navigate = useNavigate();

  const theme = useTheme();
  const [programmeData, setProgrammeData] = useState("");
  const [examTypeListOption, setExamTypeListOption] = useState([]);

  const [comment, setComment] = useState("");

  const [spocDetails, setSpocDetails] = useState([]);
  useEffect(() => {
    let fetchData = async () => {
      let { data } = await AxiosInstance.get(
        `/college_spoc?college_id=${props.id}`
      );
      setSpocDetails(data.data.length > 0 ? data.data.map((e) => e.id) : []);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/reasons`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (spocDetails.length > 0) {
      let payload = {
        id: spocDetails,
        remove_college_id: props.id,
        remove_college_reason: programmeData == "1" ? "1" : "2",
        remove_college_comment: comment,
      };
      try {
        await AxiosInstance.patch("/college_spoc", payload)
          .then((response) => {
            toast.success("Successfully Removed the College in Spoc");
            setTimeout(() => {
              navigate("/");
            }, 1600);
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } catch (error) {
        toast.error(error.response.data.error);
      }
    } else {
      toast.error("No Spoc dtails found");
    }
  };

  return (
    <section style={{ margin: "6%" }}>
      <Container maxWidth="lg">
        <Paper variant="outlined">
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                height: "6vh",
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: "2px solid #9BA4B5",
              }}
            >
              <Box
                sx={{
                  flexBasis: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color:
                    role === "1"
                      ? "#081f37"
                      : role === "5"
                      ? "#06113C"
                      : "#081f37",
                  fontSize: "34px",
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                }}
              >
                Reason for Remove College
              </Box>
            </Box>
            <form
              onSubmit={handleSubmit}
              style={{
                // marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1%",
              }}
            >
              <Box style={{ width: "90%" }}>
                <Grid container spacing={2}>
                  {/* <Grid item sm={12}>
                    <Item onChange={searchData}>
                      <Autocomplete
                        options={stateOption}
                        value={stateData}
                        name="stateOption"
                        onChange={(event, newData_xy) => {
                          setStateData(newData_xy);
                          setErrorForState(newData_xy === null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Reasons"
                            variant="outlined"
                            error={errorForState}
                            helperText={
                              errorForState ? "Please select a reasons" : ""
                            }
                            required
                          />
                        )}
                      />
                    </Item>
                  </Grid> */}
                  <Grid item md={12}>
                    <Item>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">
                          Our Programme
                        </InputLabel>
                        <Select
                          required
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={programmeData}
                          onChange={(event) => {
                            setProgrammeData(event.target.value);
                          }}
                          input={<OutlinedInput label="Our Programme" />}
                          MenuProps={MenuProps}
                        >
                          {Object.entries(examTypeListOption).map(
                            ([key, value]) => (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Item>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-comment-input"
                      label="Comment"
                      name="comment"
                      type="text"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      rows={2}
                      multiline
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>

                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 0 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "20%", color: "#fff", fontWeight: "500" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Box>
            </form>
          </ThemeProvider>
        </Paper>
      </Container>
    </section>
  );
}
