import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AxiosInstance from "../../../apis/AxiosInstance";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#176B87",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // padding: "0px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

export default function UnitList() {
  const [unitList, setUnitList] = useState([]);

  const [page, setPage] = useState(1);
  const [totalUnit, setTotalUnit] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchUnit = async (_) => {
      setLoad(true);
      try {
        let unitData = await AxiosInstance.get(`/unit?page=${page}`);
        setTotalUnit(unitData.data.filtered_count);
        let finalData = unitData.data.data;
        if (finalData !== null) {
          setUnitList(finalData);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };
    fetchUnit();
  }, [page]);

  const totalPages = Math.ceil(totalUnit / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <section style={{ width: "100%", marginTop: "4.3%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#176B87",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          Unit List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count: {totalUnit}
        </h1>
      </Box>
      <section style={{ marginTop: "0.5%" }}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 680 : 490 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead sx={{ backgroundColor: "#176B87" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#176B87",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "70px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Sl.No
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#176B87",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "200px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Unit Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#176B87",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "200px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Qualification
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#176B87",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "500px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Degree Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#176B87",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "300px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Q-Enquiry Branch
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#176B87",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "200px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : unitList.length > 0 ? (
                unitList.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.name === null || val.name === "" ? "NA" : val.name}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.degree &&
                      Object.values(val.degree.qualification_type).length > 0
                        ? Object.values(val.degree.qualification_type).map(
                            (q) => (
                              <Box style={{ height: "auto" }}>
                                {q && q.name ? q.name : "NA"}
                              </Box>
                            )
                          )
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.degree && Object.values(val.degree.degree).length > 0
                        ? Object.values(val.degree.degree).map((q) => (
                            <Box style={{ height: "auto" }}>
                              {q && q.name ? q.name : "NA"}
                            </Box>
                          ))
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                     {val && val.qenquiry_branches && Object.values(val.qenquiry_branches).length > 0
  ? Object.values(val.qenquiry_branches).map((q) => (
      <Box style={{ height: "auto" }}>
        {q && q.name ? q.name : "NA"}
      </Box>
    ))
  : "NA"}

                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title="Edit Unit">
                        <Link to="/edit_unit" state={val}>
                          <FaEdit
                            fontSize={18}
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "47%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </section>
  );
}
