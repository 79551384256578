import React, { useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Profile from "../components/profile/Profile";
import BdLandingPage from "../Bd_Module/BdLandingPage";
import PageNotFound from "../pages/PageNotFound";
import CollegeAppointment from "../Bd_Module/bd/collegeAppointment/CollegeAppointment";
import SpocCreations from "../Bd_Module/bd/spocCreation/SpocCreations";
import AppointmentList from "../Bd_Module/bd/appointmentList/AppointmentList";
import CreateAddressingForm from "../Bd_Module/bd/addressingForm/CreateAddressingForm";
import NewCreateAddressingForm from "../Bd_Module/bd/addressingForm/NewCreateAddressingForm";
import CollegeListData from "../Bd_Module/bd/collegeList/CollegeListData";
import CollegeAppointmentFromSpoc from "../Bd_Module/bd/collegeAppointment/CollegeAppointmentFromSpoc";
import EditCollegeAppointment from "../Bd_Module/bd/collegeAppointment/EditCollegeAppointment";
import EditAddressingForm from "../Bd_Module/bd/addressingForm/EditAddressingForm";
import EditAddressingMultiple from "../Bd_Module/bd/addressingForm/EditAddressingMultiple";
import SpocFormColgAppointment from "../Bd_Module/bd/spocCreation/SpocFormColgAppointment";
// import AppointmentDetails from "../Bd_Module/bd/appointmentDetails/AppointmentDetails";
// import RegisteredStudentList from "../Bd_Module/bd/appointmentDetails/RegisteredStudentList";
import CountAddressToRounds from "../Bd_Module/spiltingBdTree/treeCounts/CountAddressToRounds";
import CountAppointToAddressing from "../Bd_Module/spiltingBdTree/treeCounts/CountAppointToAddressing";
import CountBranchJourney from "../Bd_Module/spiltingBdTree/treeCounts/CountBranchJourney";
import CountRoundsToShortlist from "../Bd_Module/spiltingBdTree/treeCounts/CountRoundsToShortlist";
import CountOnboardingDetails from "../Bd_Module/spiltingBdTree/treeCounts/CountOnboardingDetails";
import QuizList from "../Bd_Module/bd/quizList/QuizList";
import AddressingList from "../Bd_Module/bd/addressingForm/AddressingList";
import AssignQuizes from "../Bd_Module/bd/addressingForm/AssignQuizes";
import GDCreation from "../Bd_Module/bd/gdCreation/GDCreation";
import GDList from "../Bd_Module/bd/gdCreation/GDList";
import GDEvolution from "../Bd_Module/bd/gdCreation/GDEvolution";
import EditGD from "../Bd_Module/bd/gdCreation/EditGD";
import MasterTableCommonCount from "../Bd_Module/bdTree/MasterTableCommonCount";
import OtpFailedList from "../Bd_Module/bd/otpFailed/OtpFailedList";
import EditSpocCollegeList from "../Bd_Module/bd/spocCreation/EditSpocCollegeList";
import InterviewCreation from "../Bd_Module/bd/interviewDetails/InterviewCreation";
import InterviewerList from "../Bd_Module/bd/interviewDetails/InterviewerList";
import EditInterview from "../Bd_Module/bd/interviewDetails/EditInterview";
import ChangeGroupName from "../Bd_Module/bd/gdCreation/ChangeGroupName";
import MultipleEditGd from "../Bd_Module/bd/gdCreation/MultipleEditGd";
import GDEvaluationCriteria from "../Bd_Module/bd/gdCreation/GDEvaluationCriteria";
import EditGDEvaluationCriteria from "../Bd_Module/bd/gdCreation/EditGDEvaluationCriteria";
import EditGdInTree from "../Bd_Module/bd/gdCreation/EditGdInTree";
import MultipleGDEditInTree from "../Bd_Module/bd/gdCreation/MultipleGDEditInTree";
import PostAddressing from "../Bd_Module/bd/addressingForm/PostAddressing";
import PostAddressingList from "../Bd_Module/bd/addressingForm/PostAddressingList";
import EditPostAddressing from "../Bd_Module/bd/addressingForm/EditPostAddressing";
import EditMultipleInterview from "../Bd_Module/bd/interviewDetails/EditMultipleInterview";
import CountTree from "../Bd_Module/spiltingBdTree/treeCounts/CountTree";
import CallRecordList from "../callRecord/CallRecordList";
import CallCategory from "../callRecord/CallCategory";
import TreeOrganaisation from "../Bd_Module/spiltingBdTree/TreeOrganaisation";
import CallType from "../callRecord/CallType";
import CallCategoryList from "../callRecord/CallCategoryList";
import EditCallCategory from "../callRecord/EditCallCategory";
import EditMainStatus from "../callRecord/EditMainStatus";
import EditSubStatus from "../callRecord/EditSubStatus";
import EditCallRecordMainStatus from "../callRecord/EditCallRecordMainStatus";
import MissedCallList from "../callRecord/MissedCallList";
import CallTypelist from "../callRecord/CallTypelist";
import NoStatusRecordList from "../callRecord/NoStatusRecordList";
import AddCallStatusRecord from "../callRecord/AddCallStatusRecord";
import CollegeSpocList from "../Bd_Module/bd/spocCreation/CollegeSpocList";
import CreateTPOLogin from "../Bd_Module/bd/spocCreation/CreateTPOLogin";
import EscalationLevelList from "../superAdmin/EscalationLevelList";
import CallNotHandledEscalationList from "../superAdmin/CallNotHandledEscalationList";
import InListEditSingleAddressingForm from "../Bd_Module/bd/addressingForm/InListEditSingleAddressingForm";
import NoStatusHandledEscalationList from "../Bd_Module/juniorVerifer/NoStatusHandledEscalationList";
import NoStatusNotHandledEscalationList from "../Bd_Module/juniorVerifer/NoStatusNotHandledEscalationList";
import MoveCollegeSpocList from "../Bd_Module/bd/spocCreation/MoveCollegeSpocList";
import RemoveCollegeSpocList from "../Bd_Module/bd/spocCreation/RemoveCollegeSpocList";
import ArchiveCollege from "../Bd_Module/bd/spocCreation/ArchiveCollege";
import EditGdMultipleInterviewer from "../Bd_Module/bd/gdCreation/EditGdMultipleInterviewer";
import EditMultipleGDInterviewerIntree from "../Bd_Module/bd/gdCreation/EditMultipleGDInterviewerIntree";
import GDCreationList from "../Bd_Module/bd/gdCreation/GDCreationList";
import EditExistingGDCreation from "../Bd_Module/bd/gdCreation/EditExistingGDCreation";
import AddNewGDCreation from "../Bd_Module/bd/gdCreation/AddNewGDCreation";
import NotificationCard from "../socket/NotificationCard";
import AppointmentDetailsList from "../Bd_Module/bd/appointmentList/AppointmentDetailsList";
import SpocCreationFromCollegeList from "../Bd_Module/bd/spocCreation/SpocCreationFromCollegeList";
import EditSpoc from "../Bd_Module/bd/spocCreation/EditSpoc";
import LeadPassingForm from "../newlead/LeadPassingForm";
import LeadPassingFormList from "../newlead/LeadPassingFormList";
import Rewards from "../newlead/Rewards";

const BdManagerRoutes = () => {
  useEffect(() => {
    const verifyUser = async () => {
      try {
        const { data } = await AxiosInstance.get("/user");
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status == 401) {
          let handleLogout = () => {
            Cookies.remove("logged");
            toast.warn("Access blocked login again");
            setTimeout(() => {
              window.location.assign("/login");
            }, 500);
          };
          handleLogout();
        }
      }
    };
    verifyUser();
  }, []);
  let routes = useRoutes([
    { path: "/", element: <BdLandingPage /> },
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/" /> },
    { path: "/create_collegeappointment", element: <CollegeAppointment /> },
    {
      path: "/college_appointmentspoc",
      element: <CollegeAppointmentFromSpoc />,
    },
    { path: "/assign-quiz", element: <AssignQuizes /> },
    { path: "/create_addressingfaculty", element: <CreateAddressingForm /> },
    { path: "/create_newaddressing", element: <NewCreateAddressingForm /> },
    { path: "/spoc_creation", element: <SpocCreations /> },
    { path: "/appointment_list", element: <AppointmentList /> },
    { path: "/college_list_data", element: <CollegeListData /> },
    { path: "/edit_college_appointment", element: <EditCollegeAppointment /> },
    { path: "/edit_addressing_form", element: <EditAddressingForm /> },
    { path: "/edit_addressing_multiple", element: <EditAddressingMultiple /> },
    {
      path: "/spoc_form_college_appointment",
      element: <SpocFormColgAppointment />,
    },
    // { path: "/appointment_details", element: <AppointmentDetails /> },
    // { path: "/registered_student_list", element: <RegisteredStudentList /> },
    { path: "/main_tree", element: <MasterTableCommonCount /> },
    { path: "/tree_address_torounds", element: <CountAddressToRounds /> },
    {
      path: "/tree_appoint_toaddressing",
      element: <CountAppointToAddressing />,
    },
    { path: "/tree_branchjourney", element: <CountBranchJourney /> },
    { path: "/tree_onboardingdetails", element: <CountOnboardingDetails /> },
    { path: "/tree_roundsto_shortlist", element: <CountRoundsToShortlist /> },
    { path: "/count_tree", element: <CountTree /> },
    // {path:"/count_tree",element:<TreeOrganaisation/>},
    { path: "/quiz_list", element: <QuizList /> },
    { path: "/addressing_list", element: <AddressingList /> },
    { path: "/gd_creation", element: <GDCreation /> },
    { path: "/gd_list", element: <GDList /> },
    { path: "/gd_evolutions", element: <GDEvolution /> },
    { path: "/edit_gd", element: <EditGD /> },
    { path: "/otp_failed_list", element: <OtpFailedList /> },
    { path: "/editspoc_college", element: <EditSpocCollegeList /> },
    { path: "/interview_creation", element: <InterviewCreation /> },
    { path: "/interview_list", element: <InterviewerList /> },
    { path: "/edit_interview", element: <EditInterview /> },
    { path: "/edit_groupname", element: <ChangeGroupName /> },
    { path: "/edit_multiple_gd", element: <MultipleEditGd /> },
    { path: "/gd_evaluation_criteria", element: <GDEvaluationCriteria /> },
    {
      path: "/edit_gd_evaluation_criteria",
      element: <EditGDEvaluationCriteria />,
    },
    { path: "/edit_gd_ingroup", element: <EditGdInTree /> },
    { path: "/edit_multiplegd_ingroup", element: <MultipleGDEditInTree /> },
    {path:"/edit_multiplegd_intree",element:<EditMultipleGDInterviewerIntree/>},
    {path:"/edit_multiplegd_interviewer",element:<EditGdMultipleInterviewer/>},
    { path: "/post_addressing", element: <PostAddressing /> },
    { path: "/post_addressing_list", element: <PostAddressingList /> },
    { path: "/edit_post_addressing", element: <EditPostAddressing /> },
    { path: "/edit_multiple_interview", element: <EditMultipleInterview /> },
    { path: "/call_record_list", element: <CallRecordList /> },
    { path: "/call_category", element: <CallCategory /> },
    { path: "/call_type", element: <CallType /> },
    { path: "/call_category_list", element: <CallCategoryList /> },
    { path: "/edit_call_category", element: <EditCallCategory /> },
    { path: "/edit_call_mainstatus", element: <EditMainStatus /> },
    { path: "/edit_call_substatus", element: <EditSubStatus /> },
    { path: "/edit_callrecord", element: <EditCallRecordMainStatus /> },
    { path: "/missed_call_details", element: <MissedCallList /> },
    { path: "/calltype_list", element: <CallTypelist /> },
    { path: "/nostatus_record_list", element: <NoStatusRecordList /> },
    { path: "/add_callstatus_record", element: <AddCallStatusRecord /> },
    { path: "/college_spoc_list", element: <CollegeSpocList /> },
    { path: "/create_tpo_login", element: <CreateTPOLogin /> },
    { path: "/call_handled_escalation_list", element: <EscalationLevelList /> },
    // {
    //   path: "/call_not_handled_escalation_list",
    //   element: <CallNotHandledEscalationList />,
    // },
    {
      path: "/missed_call_escalation_list",
      element: <CallNotHandledEscalationList />,
    },
    {
      path: "/edit_single_addressing_form",
      element: <InListEditSingleAddressingForm />,
    },
    {
      path: "/nostatus_handled_escalation_list",
      element: <NoStatusHandledEscalationList />,
    },
    
    // {
    //   path: "/nostatus_not_handled_escalation_list",
    //   element: <NoStatusNotHandledEscalationList />,
    // },
    {
      path: "/nostatus_escalation_list",
      element: <NoStatusNotHandledEscalationList />,
    },
    {path:"/move_spoc_college",element:<MoveCollegeSpocList/>},
    {path:"/remove_spoc_college",element:<RemoveCollegeSpocList/>},
    {path:"/archive_spoc_college",element:<ArchiveCollege/>},
    {path:"/gd_creation_list",element:<GDCreationList/>},
    {path:"/edit_same_gd_creation",element:<EditExistingGDCreation/>},
    {path:"/add_new_gd_creation",element:<AddNewGDCreation/>},
    {path:"/notifications", element:<NotificationCard/>},
    {path:"/appointment_details_list",element:<AppointmentDetailsList/>},
    {path:"/spoc_creation_from_collegelist",element:<SpocCreationFromCollegeList/>},
    {path:"/edit_spoc",element:<EditSpoc/>},
    { path: "/lead_passing_form", element: <LeadPassingForm /> },
    {path:"/lead_passing_form_list",element:<LeadPassingFormList/>},
    {path:"/rewards",element:<Rewards/>}
 
  ]);
  return routes;
};

export default BdManagerRoutes;
