import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Typography,
  Box,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { CgListTree } from "react-icons/cg";
import { PostContext } from "./ContextApi";
import MasterBankTree from "./MasterBDTree";
import { FaEdit } from "react-icons/fa";
import ContactModal from "../bd/appointmentList/ContactModal";
import { useRef } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import Styles from "./tree.module.css";
import AddressingEditModal from "../bd/addressingForm/AddressingEditModal";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from "moment";
import Cookies from "js-cookie";
import QuizAttendedList from "../bd/appointmentList/QuizAttendedList";
import StudentRegisterList from "../bd/appointmentList/StudentRegisterList";
import QuizAttendedInAppointment from "../bd/appointmentList/QuizAttendedInAppointment";
import QuizShortlistedList from "../bd/appointmentList/QuizShortlistedList";
import StudentJoinedList from "../bd/appointmentList/StudentJoinedList";
import NotJoinedStudentList from "../bd/appointmentList/NotJoinedStudentList";
import GDRejectedListInTree from "../bd/gdCreation/GDRejectedListInTree";
import GDShortListInTree from "../bd/gdCreation/GDShortListInTree";
import GDRegisteredListInTree from "../bd/gdCreation/GDRegisteredListInTree";
import GDJoinedListInTree from "../bd/gdCreation/GDJoinedListInTree";
import GDNotJoinedListInTree from "../bd/gdCreation/GDNotJoinedListInTree";
import QuizNotAttendedList from "../bd/appointmentList/QuizNotAttendedList";
import QuizNotAttendedInAppointment from "../bd/appointmentList/QuizNotAttendedInAppointment";
import QuizNotSelectedInAppointment from "../bd/appointmentList/QuizNotSelectedInAppointment";
import QuizNotSlectedList from "../bd/appointmentList/QuizNotSlectedList";
import GDHoldListInTree from "../bd/gdCreation/GDHoldListInTree";
import GDHoldListInAppointment from "../bd/gdCreation/GDHoldListInAppointment";
import OtherCollegeRegisterList from "../bd/appointmentList/OtherCollegeRegisterList";
import ProfileFilledWithoutAttendAddressing from "../bd/appointmentList/ProfileFilledWithoutAttendAddressing";
import ProfileFilledAfterAttendAddressing from "../bd/appointmentList/ProfileFilledAfterAttendAddressing";
import TotalProfileFilled from "../bd/appointmentList/TotalProfileFilled";
import ProfileNotFilledAfterAttendAddressing from "../bd/appointmentList/ProfileNotFilledAfterAttendAddressing";
import QuizAttendedOtherCollegeList from "../bd/appointmentList/QuizAttendedOtherCollegeList";
import QuizAttendedOtherCollegeInAppointment from "../bd/appointmentList/QuizAttendedOtherCollegeInAppointment";
import GDGroupCountListTree from "../bd/gdCreation/GDGroupCountListTree";
import GDGroupCountListInAppointment from "../bd/gdCreation/GDGroupCountListInAppointment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:
      role === "1" ? "#EAFDFC" : role === "5" ? "#EDEDED" : "#EAFDFC",
    color: "black",
    border: "none",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

const MasterTableCommonCount = () => {
  const meetingID_Ref = useRef(null);
  const { expanded, country, states, district, colleges, appointments } =
    React.useContext(PostContext);

  const [columns, setColumns] = React.useState([
    "University",
    "College",
    "Stream",
    "Appointment Date and Time",
    "Appointment Link",
    "Appointment Status",
    "Student Registration Failed",
    "Registered Students",
    "Other College Registered Students",
    "TPO Follow-up",
    "Spoc Details",
    "Addressing Date and Time",
    "Addressing Follow-up",
    "Addressing follow-up Not done",
    "Addressing Attended/Not Attended",
    "Profile Filled Without Attending Addressing",
    "Profile Filled After Attending Addressing",
    "Total Profile Filled",
    "Profile Not Filled After Attending Addressing",
    "Rounds",
    "Quiz Attended",
    "Quiz Not-Attended",
    "Quiz Shortlisted",
    "Quiz Not-Selected",
    "Quiz Attended Other College Students",
    "Quiz Follow-up",
    "Quiz follow-up Not done",
    "Quiz Rejected",
    "GD Group Count",
    "GD Registered",
    "GD Joined",
    "GD Not-Joined",
    "GD ShortListed",
    "GD Rejected",
    "GD Hold",
    "GD Follow-up",
    "GD Follow-up not done",
    "Final ShortListed",
    "ShortList Follow-up",
    "ShortList Follow-up not done",
    "Final Rejected",
    "OnBoarding Meeting Date",
    "OnBoarding Meeting Link",
    "OnBoarding Follow-up",
    "OnBoarding Follow-up not done",
    "Onbarding Attended",
    "Onboarding Not Attended",
    "Assign Branch",
    "Joining Date with Venue Follow-up",
    "Joining Date with Venue Follow-up not done",
    "Branch Joining Follow-up",
    "Branch Joining Follow-up not done",
    "Joined Students",
    "Not Joined Students",
    "Dropout Before Joining",
    "Before Joining Dropout Follow-up",
    "Before Joining Dropout Follow-up not done",
    "Dropout After Joining",
    "After Joining Dropout Follow-up",
    "After Joining Dropout Follow-up not done",
    "Replaced Students",
    "Interview Attended",
    "Interview Not Attended",
    "Got Job",
    "Got Job from Our Organization",
    "Got Job From Outside",
    "Message sent",
    "Message Field",
    "TPO Report ",
  ]);

  const copyToClipboard = async (event) => {
    const textToCopy = await event.target.value;
    navigator.clipboard
      .writeText(`https://incubation.qspiders.com/appointment/${textToCopy}`)
      .then(() => {
        toast.info("Student Login Link Copied");
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  const copyToClipboardtext = async (event) => {
    const textToCopy = await event.target.value;
    navigator.clipboard
      .writeText(`https://incubationbd.qspiders.com/login/${textToCopy}`)
      .then(() => {
        toast.info("Addressing Faculty Link Copied");
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  const [expandedState, setExpandedState] = useState([]);
  const [expandedDistrict, setExpandedDistrict] = useState([]);
  const [expandedCollege, setExpandedCollege] = useState([]);
  const [expandedAppointment, setExpandedAppointment] = useState([]);

  useEffect(() => {
    if (expanded.length > 0) {
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let arr4 = [];
      let stateArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          !ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (stateArr.length > 0) {
        stateArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr1.push(xid);
        });
        setExpandedState(arr1);
      } else {
        setExpandedState(arr1);
      }
      let distArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          !ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (distArr.length > 0) {
        distArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr2.push(xid);
        });
        setExpandedDistrict(arr2);
      } else {
        setExpandedDistrict(arr2);
      }
      let collegeArr = expanded.filter(
        (ex) =>
          ex.includes("state") &&
          ex.includes("district") &&
          ex.includes("college") &&
          !ex.includes("appointment")
      );
      if (collegeArr.length > 0) {
        collegeArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr3.push(xid);
        });
        setExpandedCollege(arr3);
      } else {
        setExpandedCollege(arr3);
      }
      setExpandedAppointment(
        expanded.filter(
          (ex) =>
            ex.includes("state") &&
            ex.includes("district") &&
            ex.includes("college") &&
            ex.includes("appointment")
        ).length > 0
          ? expanded
              .filter(
                (ex) =>
                  ex.includes("state") &&
                  ex.includes("district") &&
                  ex.includes("college") &&
                  ex.includes("appointment")
              )
              .map((element) => parseInt(element.match(/\d+/)[0]))
          : []
      );
    }
  }, [expanded]);

  return (
    <>
      <section
        id="treeStructure"
        style={{
          width: "100%",
          minHeight: "100vh",
          overflowY: "auto",
          marginTop: "3.5%",
        }}
      >
        <article
          style={{
            width: "100%",
            display: "flex",
            minHeight: "100vh",
            position: "relative",
            // top: "7vh",
          }}
        >
          <aside
            style={{
              width: "40%",
              height: "auto",
            }}
            className="treeBlock"
          >
            <Box
              style={{
                backgroundColor:
                  role === "1"
                    ? "#e2eafc"
                    : role === "5"
                    ? "#F5EDDC"
                    : "#e2eafc",
                padding: "7px",
              }}
            >
              <CgListTree fontSize={35} style={{ marginLeft: "30px" }} />{" "}
              <span
                style={{
                  fontSize: "19px",
                  fontWeight: "bold",
                  color: "#0D4C92",
                  marginLeft: "20px",
                }}
              >
                Main Tree{" "}
              </span>
            </Box>
            <div>
              <MasterBankTree />
            </div>
          </aside>
          <aside
            style={{
              width: "45%",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
            }}
            className="tableBlock"
          >
            <div
              style={{ width: "100%", minHeight: "100%", whiteSpace: "nowrap" }}
            >
              <TableContainer component={Paper} style={{ minHeight: "99vh" }}>
                <Table sx={{ minWidth: 700 }} aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <StyledTableCell key={index}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            id="innerChild"
                          >
                            {column}
                            {/* <MdDragIndicator
                              fontSize={18}
                              style={{ background: "trasnparent" }}
                            /> */}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell
                              align="left"
                              className={Styles.countryStyle}
                            >
                              {coun.university_count}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className={Styles.countryStyle}
                            >
                              {coun.college_count}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              className={Styles.countryStyle}
                            >
                              -----
                            </StyledTableCell>

                            {/* Appointment section , Registered,spoc */}

                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              {coun.registration_failed_count}
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.registered_students}</p>&nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <StudentRegisterList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    level="1"
                                  ></StudentRegisterList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {" "}
                                  {coun.other_college_registered_students ===
                                  null
                                    ? 0
                                    : coun.other_college_registered_students}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <OtherCollegeRegisterList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    otherCollegeReg="1"
                                  ></OtherCollegeRegisterList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>

                            {/* Addressing section, Profiles */}

                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              {coun.joined_students}/{coun.not_joined_students}
                            </StyledTableCell>
                            {/* profiles */}
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {
                                    coun.profile_filled_without_attending_addressing
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <ProfileFilledWithoutAttendAddressing
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    profileFilledWithouAttendAddressing="1"
                                  ></ProfileFilledWithoutAttendAddressing>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {
                                    coun.profile_filled_after_attending_addressing
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <ProfileFilledAfterAttendAddressing
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    profileFilledAfterAttendAddressing="1"
                                  ></ProfileFilledAfterAttendAddressing>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>{coun.total_profile_filled}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <TotalProfileFilled
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    totalProfileFilled="1"
                                  ></TotalProfileFilled>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                {
                                  coun.profile_not_filled_after_attending_addressing
                                }
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <ProfileNotFilledAfterAttendAddressing
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    profileNotFilledAfterAttendAddressing="1"
                                  ></ProfileNotFilledAfterAttendAddressing>
                                </p>
                              </p>
                            </StyledTableCell>

                            {/* Rounds, Quiz */}
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_attended_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizAttendedList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    quizAttendedLevel="1"
                                  ></QuizAttendedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell
                              className={Styles.roundsToShortCountry}
                            >
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_not_attended_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizNotAttendedList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    quizNotAttendedLevel="1"
                                  ></QuizNotAttendedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_shortlisted_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizShortlistedList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    quizShortListLevel="1"
                                  ></QuizShortlistedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.quiz_not_selected_student_count}</p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizNotSlectedList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    quizNotSelectedLevel="1"
                                  ></QuizNotSlectedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>
                                  {
                                    coun.quiz_attended_other_college_student_count
                                  }
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <QuizAttendedOtherCollegeList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    quizAttendedOtherCollege="1"
                                  ></QuizAttendedOtherCollegeList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_count === null ||
                                  coun.gd_count === ""
                                    ? 0
                                    : coun.gd_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDGroupCountListTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdGroupCount="1"
                                  ></GDGroupCountListTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_registered_count === null ||
                                  coun.gd_registered_count === ""
                                    ? 0
                                    : coun.gd_registered_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDRegisteredListInTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdRegistered="1"
                                  ></GDRegisteredListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_joined_count === null ||
                                  coun.gd_joined_count === ""
                                    ? 0
                                    : coun.gd_joined_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDJoinedListInTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdJoined="1"
                                  ></GDJoinedListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_not_joined_count === null ||
                                  coun.gd_not_joined_count === ""
                                    ? 0
                                    : coun.gd_not_joined_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDNotJoinedListInTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdNotJoined="1"
                                  ></GDNotJoinedListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_shortlisted_count === null ||
                                  coun.gd_shortlisted_count === ""
                                    ? 0
                                    : coun.gd_shortlisted_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDShortListInTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdShortlist="1"
                                  ></GDShortListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_rejected_count === null ||
                                  coun.gd_rejected_count === ""
                                    ? 0
                                    : coun.gd_rejected_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDRejectedListInTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdRejected="1"
                                  ></GDRejectedListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p>
                                  {coun.gd_hold_count === null ||
                                  coun.gd_hold_count === ""
                                    ? 0
                                    : coun.gd_hold_count}
                                </p>
                                &nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <GDHoldListInTree
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    gdHold="1"
                                  ></GDHoldListInTree>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>

                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.joined_students}</p>&nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <StudentJoinedList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    joinedStudentLevel="1"
                                  ></StudentJoinedList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <p>{coun.not_joined_students}</p>&nbsp;&nbsp;
                                <p style={{ fontSize: "1px" }}>
                                  {" "}
                                  <NotJoinedStudentList
                                    college_idx={coun.ids?.college.id}
                                    appointment_idx={coun.id}
                                    notJoinedStudentLevel="1"
                                  ></NotJoinedStudentList>
                                </p>
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              -----
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}

                      {expanded.length > 0
                        ? states.map((state, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="University">
                                      <p>{state.university_count}</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="College">
                                      <p> {state.college_count}</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Stream">
                                      {/* <p>{state.name.slice(0, 5)}</p> */}
                                      <p>----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Appointment Date and Time">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Appointment Link">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Appointment Status">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Registration Failed">
                                      <p>{state.registration_failed_count}</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>{state.registered_students}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <StudentRegisterList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            level="2"
                                          ></StudentRegisterList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {state.other_college_registered_students ===
                                          null
                                            ? 0
                                            : state.other_college_registered_students}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <OtherCollegeRegisterList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            otherCollegeReg="2"
                                          ></OtherCollegeRegisterList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="TPO Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Spoc Details">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Addressing Date and Time">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Addressing Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Addressing follow-up Not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Addressing Attended/Not Attended">
                                      <p>
                                        {state.joined_students}/
                                        {state.not_joined_students}
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {
                                            state.profile_filled_without_attending_addressing
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <ProfileFilledWithoutAttendAddressing
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            profileFilledWithouAttendAddressing="2"
                                          ></ProfileFilledWithoutAttendAddressing>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {
                                            state.profile_filled_after_attending_addressing
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <ProfileFilledAfterAttendAddressing
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            profileFilledAfterAttendAddressing="2"
                                          ></ProfileFilledAfterAttendAddressing>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p> {state.total_profile_filled}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <TotalProfileFilled
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            totalProfileFilled="2"
                                          ></TotalProfileFilled>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {" "}
                                          {
                                            state.profile_not_filled_after_attending_addressing
                                          }
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <ProfileNotFilledAfterAttendAddressing
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            profileNotFilledAfterAttendAddressing="2"
                                          ></ProfileNotFilledAfterAttendAddressing>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Rounds">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {state.quiz_attended_student_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizAttendedList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            quizAttendedLevel="2"
                                          ></QuizAttendedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.roundsToShortState}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>{state.quiz_not_attended_count}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizNotAttendedList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            quizNotAttendedLevel="2"
                                          ></QuizNotAttendedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>
                                          {state.quiz_shortlisted_student_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <QuizShortlistedList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            quizShortListLevel="2"
                                          ></QuizShortlistedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <p>
                                        {state.quiz_not_selected_student_count}
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        {" "}
                                        <QuizNotSlectedList
                                          college_idx={state.ids?.college.id}
                                          appointment_idx={state.id}
                                          quizNotSelectedLevel="2"
                                        ></QuizNotSlectedList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {" "}
                                      <p>
                                        {
                                          state.quiz_attended_other_college_student_count
                                        }
                                      </p>
                                      &nbsp;&nbsp;
                                      <p style={{ fontSize: "1px" }}>
                                        {" "}
                                        <QuizAttendedOtherCollegeList
                                          college_idx={state.ids?.college.id}
                                          appointment_idx={state.id}
                                          quizAttendedOtherCollege="2"
                                        ></QuizAttendedOtherCollegeList>
                                      </p>
                                    </p>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Quiz Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Quiz follow-up Not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Quiz Rejected">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_count === null ||
                                          state.gd_count === ""
                                            ? 0
                                            : state.gd_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDGroupCountListTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdGroupCount="2"
                                          ></GDGroupCountListTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_registered_count === null ||
                                          state.gd_registered_count === ""
                                            ? 0
                                            : state.gd_registered_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDRegisteredListInTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdRegistered="2"
                                          ></GDRegisteredListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_joined_count === null ||
                                          state.gd_joined_count === ""
                                            ? 0
                                            : state.gd_joined_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDJoinedListInTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdJoined="2"
                                          ></GDJoinedListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_not_joined_count === null ||
                                          state.gd_not_joined_count === ""
                                            ? 0
                                            : state.gd_not_joined_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDNotJoinedListInTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdNotJoined="2"
                                          ></GDNotJoinedListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_shortlisted_count ===
                                            null ||
                                          state.gd_shortlisted_count === ""
                                            ? 0
                                            : state.gd_shortlisted_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDShortListInTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdShortlist="2"
                                          ></GDShortListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_rejected_count === null ||
                                          state.gd_rejected_count === ""
                                            ? 0
                                            : state.gd_rejected_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDRejectedListInTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdRejected="2"
                                          ></GDRejectedListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <p>
                                          {state.gd_hold_count === null ||
                                          state.gd_hold_count === ""
                                            ? 0
                                            : state.gd_hold_count}
                                        </p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <GDHoldListInTree
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            gdHold="2"
                                          ></GDHoldListInTree>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="GD Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="GD Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Final ShortListed">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="ShortList Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="ShortList Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Final Rejected">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="OnBoarding Meeting Date">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="OnBoarding Meeting Link">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="OnBoarding Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="OnBoarding Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Onbarding Attended">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Onboarding Not Attended">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Assign Branch">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Joining Date with Venue Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Joining Date with Venue Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Branch Joining Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Branch Joining Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>{state.joined_students}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <StudentJoinedList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            joinedStudentLevel="2"
                                          ></StudentJoinedList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip>
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {" "}
                                        <p>{state.not_joined_students}</p>
                                        &nbsp;&nbsp;
                                        <p style={{ fontSize: "1px" }}>
                                          {" "}
                                          <NotJoinedStudentList
                                            college_idx={state.ids?.college.id}
                                            appointment_idx={state.id}
                                            notJoinedStudentLevel="2"
                                          ></NotJoinedStudentList>
                                        </p>
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Dropout Before Joining">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Before Joining Dropout Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Before Joining Dropout Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Dropout After Joining">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="After Joining Dropout Follow-up">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="After Joining Dropout Follow-up not done">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Replaced Students">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Interview Attended">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Interview Not Attended">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Got Job">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Got Job from Our Organization">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Got Job From Outside">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Message sent">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Message Field">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="TPO Report ">
                                      <p>-----</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == state.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="University">
                                                  <p>{dist.university_count}</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="College">
                                                  <p> {dist.college_count}</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Stream">
                                                  {/* <p>{state.name.slice(0, 5)}</p> */}
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Appointment Date and Time">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Appointment Link">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Appointment Status">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Registration Failed">
                                                  <p>
                                                    {
                                                      dist.registration_failed_count
                                                    }
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.registered_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <StudentRegisterList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        level="3"
                                                      ></StudentRegisterList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.other_college_registered_students ===
                                                      null
                                                        ? 0
                                                        : dist.other_college_registered_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <OtherCollegeRegisterList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        otherCollegeReg="3"
                                                      ></OtherCollegeRegisterList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="TPO Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Spoc Details">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Addressing Date and Time">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Addressing Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Addressing follow-up Not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Addressing Attended/Not Attended">
                                                  <p>
                                                    {dist.joined_students}/
                                                    {dist.not_joined_students}
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.profile_filled_without_attending_addressing
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <ProfileFilledWithoutAttendAddressing
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        profileFilledWithouAttendAddressing="3"
                                                      ></ProfileFilledWithoutAttendAddressing>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.profile_filled_after_attending_addressing
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <ProfileFilledAfterAttendAddressing
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        profileFilledAfterAttendAddressing="3"
                                                      ></ProfileFilledAfterAttendAddressing>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.total_profile_filled
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <TotalProfileFilled
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        totalProfileFilled="3"
                                                      ></TotalProfileFilled>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {
                                                        dist.profile_not_filled_after_attending_addressing
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <ProfileNotFilledAfterAttendAddressing
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        profileNotFilledAfterAttendAddressing="3"
                                                      ></ProfileNotFilledAfterAttendAddressing>
                                                    </p>{" "}
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Rounds">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_attended_student_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizAttendedList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizAttendedLevel="3"
                                                      ></QuizAttendedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={
                                                  Styles.roundsToShortDistrict
                                                }
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_not_attended_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizNotAttendedList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizNotAttendedLevel="3"
                                                      ></QuizNotAttendedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {
                                                        dist.quiz_shortlisted_student_count
                                                      }
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <QuizShortlistedList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        quizShortListLevel="3"
                                                      ></QuizShortlistedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <p>
                                                    {
                                                      dist.quiz_not_selected_student_count
                                                    }
                                                  </p>
                                                  &nbsp;&nbsp;
                                                  <p
                                                    style={{ fontSize: "1px" }}
                                                  >
                                                    {" "}
                                                    <QuizNotSlectedList
                                                      college_idx={
                                                        dist.ids?.college.id
                                                      }
                                                      appointment_idx={dist.id}
                                                      quizNotSelectedLevel="3"
                                                    ></QuizNotSlectedList>
                                                  </p>
                                                </p>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <p
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <p>
                                                    {
                                                      dist.quiz_attended_other_college_student_count
                                                    }
                                                  </p>
                                                  &nbsp;&nbsp;
                                                  <p
                                                    style={{ fontSize: "1px" }}
                                                  >
                                                    {" "}
                                                    <QuizAttendedOtherCollegeList
                                                      college_idx={
                                                        dist.ids?.college.id
                                                      }
                                                      appointment_idx={dist.id}
                                                      quizAttendedOtherCollege="3"
                                                    ></QuizAttendedOtherCollegeList>
                                                  </p>
                                                </p>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Quiz Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Quiz follow-up Not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Quiz Rejected">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_count === null ||
                                                      dist.gd_count === ""
                                                        ? 0
                                                        : dist.gd_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDGroupCountListTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdGroupCount="3"
                                                      ></GDGroupCountListTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_registered_count ===
                                                        null ||
                                                      dist.gd_registered_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_registered_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDRegisteredListInTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdRegistered="3"
                                                      ></GDRegisteredListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_joined_count ===
                                                        null ||
                                                      dist.gd_joined_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_joined_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDJoinedListInTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdJoined="3"
                                                      ></GDJoinedListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_not_joined_count ===
                                                        null ||
                                                      dist.gd_not_joined_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_not_joined_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDNotJoinedListInTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdNotJoined="3"
                                                      ></GDNotJoinedListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_shortlisted_count ===
                                                        null ||
                                                      dist.gd_shortlisted_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_shortlisted_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDShortListInTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdShortlist="3"
                                                      ></GDShortListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_rejected_count ===
                                                        null ||
                                                      dist.gd_rejected_count ===
                                                        ""
                                                        ? 0
                                                        : dist.gd_rejected_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDRejectedListInTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdRejected="3"
                                                      ></GDRejectedListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <p>
                                                      {dist.gd_hold_count ===
                                                        null ||
                                                      dist.gd_hold_count === ""
                                                        ? 0
                                                        : dist.gd_hold_count}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <GDHoldListInTree
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        gdHold="3"
                                                      ></GDHoldListInTree>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="GD Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="GD Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Final ShortListed">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="ShortList Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="ShortList Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Final Rejected">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="OnBoarding Meeting Date">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="OnBoarding Meeting Link">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="OnBoarding Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="OnBoarding Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Onbarding Attended">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Onboarding Not Attended">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Assign Branch">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Joining Date with Venue Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Joining Date with Venue Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Branch Joining Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Branch Joining Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {dist.joined_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <StudentJoinedList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        joinedStudentLevel="3"
                                                      ></StudentJoinedList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip>
                                                  <p
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {" "}
                                                    <p>
                                                      {dist.not_joined_students}
                                                    </p>
                                                    &nbsp;&nbsp;
                                                    <p
                                                      style={{
                                                        fontSize: "1px",
                                                      }}
                                                    >
                                                      {" "}
                                                      <NotJoinedStudentList
                                                        college_idx={
                                                          dist.ids?.college.id
                                                        }
                                                        appointment_idx={
                                                          dist.id
                                                        }
                                                        notJoinedStudentLevel="3"
                                                      ></NotJoinedStudentList>
                                                    </p>
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Dropout Before Joining">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Before Joining Dropout Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Before Joining Dropout Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Dropout After Joining">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="After Joining Dropout Follow-up">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="After Joining Dropout Follow-up not done">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Replaced Students">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Interview Attended">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Interview Not Attended">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Got Job">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Got Job from Our Organization">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Got Job From Outside">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Message sent">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Message Field">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="TPO Report ">
                                                  <p>-----</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )
                                                  // .slice(0, displayCount)
                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            align="left"
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip
                                                              title="Univerity"
                                                              placement="top"
                                                            >
                                                              <>
                                                                <Tooltip
                                                                  title={
                                                                    coll.university_name
                                                                  }
                                                                  placement="bottom"
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    {coll.university_name.slice(
                                                                      0,
                                                                      30
                                                                    )}
                                                                  </Typography>
                                                                </Tooltip>
                                                              </>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip
                                                              title="College"
                                                              placement="top"
                                                            >
                                                              <>
                                                                <Tooltip
                                                                  title={
                                                                    coll.name
                                                                  }
                                                                  placement="bottom"
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      textAlign:
                                                                        "left",
                                                                    }}
                                                                  >
                                                                    {coll.name.slice(
                                                                      0,
                                                                      30
                                                                    )}
                                                                  </Typography>
                                                                </Tooltip>
                                                              </>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Stream">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Date and Time">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Link">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Appointment Status">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Registration Failed">
                                                              <p>
                                                                {
                                                                  coll.registration_failed_count
                                                                }
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.registered_students
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <StudentRegisterList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    level="4"
                                                                  ></StudentRegisterList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.other_college_registered_students ===
                                                                  null
                                                                    ? 0
                                                                    : coll.other_college_registered_students}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <OtherCollegeRegisterList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    otherCollegeReg="4"
                                                                  ></OtherCollegeRegisterList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="TPO Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Spoc Details">
                                                              <p>
                                                                {/* <Tooltip title="Edit">
                                                                                <Link
                                                                                  to="/editSpocCreation"
                                                                                  state={
                                                                                    coll
                                                                                  }
                                                                                >
                                                                                  <FaEdit
                                                                                    fontSize={
                                                                                      18
                                                                                    }
                                                                                    color="#537FE7"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      width:
                                                                                        "50px",
                                                                                    }}
                                                                                  />
                                                                                </Link>
                                                                              </Tooltip> */}
                                                                ------
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing Date and Time">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing follow-up Not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Addressing Attended/Not Attended">
                                                              <p>
                                                                {
                                                                  coll.joined_students
                                                                }
                                                                /
                                                                {
                                                                  coll.not_joined_students
                                                                }
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.profile_filled_without_attending_addressing
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <ProfileFilledWithoutAttendAddressing
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    profileFilledWithouAttendAddressing="4"
                                                                  ></ProfileFilledWithoutAttendAddressing>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.profile_filled_after_attending_addressing
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <ProfileFilledAfterAttendAddressing
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    profileFilledAfterAttendAddressing="4"
                                                                  ></ProfileFilledAfterAttendAddressing>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.total_profile_filled
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <TotalProfileFilled
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    totalProfileFilled="4"
                                                                  ></TotalProfileFilled>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {
                                                                    coll.profile_not_filled_after_attending_addressing
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <ProfileNotFilledAfterAttendAddressing
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    profileNotFilledAfterAttendAddressing="4"
                                                                  ></ProfileNotFilledAfterAttendAddressing>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Rounds">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_attended_student_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <QuizAttendedList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizAttendedLevel="4"
                                                                  ></QuizAttendedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.roundsToShortCollege
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_not_attended_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  <QuizNotAttendedList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizNotAttendedLevel="4"
                                                                  ></QuizNotAttendedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.quiz_shortlisted_studen_count
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <QuizShortlistedList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    quizShortListLevel="4"
                                                                  ></QuizShortlistedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              {" "}
                                                              <p>
                                                                {
                                                                  coll.quiz_not_selected_student_count
                                                                }
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <QuizNotSlectedList
                                                                  college_idx={
                                                                    coll.ids
                                                                      ?.college
                                                                      .id
                                                                  }
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  quizNotSelectedLevel="4"
                                                                ></QuizNotSlectedList>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              {" "}
                                                              <p>
                                                                {
                                                                  coll.quiz_attended_other_college_student_count
                                                                }
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <QuizAttendedOtherCollegeList
                                                                  college_idx={
                                                                    coll.ids
                                                                      ?.college
                                                                      .id
                                                                  }
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  quizAttendedOtherCollege="4"
                                                                ></QuizAttendedOtherCollegeList>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Quiz Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Quiz follow-up Not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Quiz Rejected">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <p
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                justifyContent:
                                                                  "center",
                                                              }}
                                                            >
                                                              <p>
                                                                {coll.gd_count ===
                                                                  null ||
                                                                coll.gd_count ===
                                                                  ""
                                                                  ? 0
                                                                  : coll.gd_count}
                                                              </p>
                                                              &nbsp;&nbsp;
                                                              <p
                                                                style={{
                                                                  fontSize:
                                                                    "1px",
                                                                }}
                                                              >
                                                                {" "}
                                                                <GDGroupCountListTree
                                                                  college_idx={
                                                                    coll.ids
                                                                      ?.college
                                                                      .id
                                                                  }
                                                                  appointment_idx={
                                                                    coll.id
                                                                  }
                                                                  gdGroupCount="4"
                                                                ></GDGroupCountListTree>
                                                              </p>
                                                            </p>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_registered_count ===
                                                                    null ||
                                                                  coll.gd_registered_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_registered_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDRegisteredListInTree
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdRegistered="4"
                                                                  ></GDRegisteredListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_joined_count ===
                                                                    null ||
                                                                  coll.gd_joined_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_joined_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDJoinedListInTree
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdJoined="4"
                                                                  ></GDJoinedListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_not_joined_count ===
                                                                    null ||
                                                                  coll.gd_not_joined_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_not_joined_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDNotJoinedListInTree
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdNotJoined="4"
                                                                  ></GDNotJoinedListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_shortlisted_count ===
                                                                    null ||
                                                                  coll.gd_shortlisted_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_shortlisted_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDShortListInTree
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdShortlist="4"
                                                                  ></GDShortListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_rejected_count ===
                                                                    null ||
                                                                  coll.gd_rejected_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_rejected_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDRejectedListInTree
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdRejected="4"
                                                                  ></GDRejectedListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                <p>
                                                                  {coll.gd_hold_count ===
                                                                    null ||
                                                                  coll.gd_hold_count ===
                                                                    ""
                                                                    ? 0
                                                                    : coll.gd_hold_count}
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <GDHoldListInTree
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    gdHold="4"
                                                                  ></GDHoldListInTree>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="GD Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="GD Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Final ShortListed">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="ShortList Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="ShortList Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Final Rejected">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="OnBoarding Meeting Date">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="OnBoarding Meeting Link">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="OnBoarding Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="OnBoarding Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Onbarding Attended">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Onboarding Not Attended">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Assign Branch">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Joining Date with Venue Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Joining Date with Venue Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Branch Joining Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Branch Joining Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.joined_students
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <StudentJoinedList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    joinedStudentLevel="4"
                                                                  ></StudentJoinedList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip>
                                                              <p
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "center",
                                                                }}
                                                              >
                                                                {" "}
                                                                <p>
                                                                  {
                                                                    coll.not_joined_students
                                                                  }
                                                                </p>
                                                                &nbsp;&nbsp;
                                                                <p
                                                                  style={{
                                                                    fontSize:
                                                                      "1px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  <NotJoinedStudentList
                                                                    college_idx={
                                                                      coll.ids
                                                                        ?.college
                                                                        .id
                                                                    }
                                                                    appointment_idx={
                                                                      coll.id
                                                                    }
                                                                    notJoinedStudentLevel="4"
                                                                  ></NotJoinedStudentList>
                                                                </p>
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Dropout Before Joining">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Before Joining Dropout Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Before Joining Dropout Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Dropout After Joining">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="After Joining Dropout Follow-up">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="After Joining Dropout Follow-up not done">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Replaced Students">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Interview Attended">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Interview Not Attended">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Got Job">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Got Job from Our Organization">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Got Job From Outside">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Message sent">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Message Field">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.collegeStyle
                                                            }
                                                          >
                                                            <BootstrapTooltip title="TPO Report ">
                                                              <p>-----</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>

                                                        {appointments != null &&
                                                        expandedCollege.length >
                                                          0 &&
                                                        appointments.filter(
                                                          (aph) =>
                                                            aph.ids.college
                                                              .id == coll.id
                                                        ).length > 0
                                                          ? appointments
                                                              .filter(
                                                                (aph) =>
                                                                  aph.ids
                                                                    .college
                                                                    .id ==
                                                                  coll.id
                                                              )
                                                              .map(
                                                                (
                                                                  apps,
                                                                  indApps
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <TableRow
                                                                        key={
                                                                          indApps +
                                                                          1
                                                                        }
                                                                      >
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          {/* <BootstrapTooltip  title={
                                                                              apps
                                                                                .ids
                                                                                .university
                                                                                .value
                                                                            }> */}
                                                                          <Tooltip
                                                                            title={
                                                                              apps
                                                                                .ids
                                                                                .university
                                                                                .value
                                                                            }
                                                                            placement="top"
                                                                          >
                                                                            <p>
                                                                              {
                                                                                apps
                                                                                  .ids
                                                                                  .university
                                                                                  .value
                                                                              }
                                                                            </p>
                                                                          </Tooltip>
                                                                          {/* </BootstrapTooltip> */}
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="College">
                                                                            <p>
                                                                              {apps.name.slice(
                                                                                0,
                                                                                30
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Stream">
                                                                            <p>
                                                                              {apps
                                                                                .ids
                                                                                .stream
                                                                                .value ===
                                                                                null ||
                                                                              apps
                                                                                .ids
                                                                                .stream
                                                                                .value ===
                                                                                ""
                                                                                ? "N/A"
                                                                                : apps
                                                                                    .ids
                                                                                    .stream
                                                                                    .value}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Appointment date and Time">
                                                                            <p>
                                                                              {apps.appointment_date ===
                                                                                null ||
                                                                              apps.appointment_date ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                moment
                                                                                  .utc(
                                                                                    apps.appointment_date
                                                                                  )
                                                                                  .local()
                                                                                  .format(
                                                                                    "DD-MM-yyyy hh:mm"
                                                                                  )
                                                                                // apps.appointment_date.replace("+0000 UTC", "").replace("+0000", "")
                                                                              )}
                                                                              <Tooltip title="Edit">
                                                                                <Link
                                                                                  to="/edit_college_appointment"
                                                                                  state={
                                                                                    apps
                                                                                  }
                                                                                >
                                                                                  <FaEdit
                                                                                    fontSize={
                                                                                      18
                                                                                    }
                                                                                    color="#537FE7"
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      width:
                                                                                        "50px",
                                                                                    }}
                                                                                  />
                                                                                </Link>
                                                                              </Tooltip>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <BootstrapTooltip title="Appointment Link">
                                                                          <StyledTableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            className={
                                                                              Styles.appointmentStyle
                                                                            }
                                                                          >
                                                                            <p
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize:
                                                                                  "0px",
                                                                                color:
                                                                                  "white",
                                                                              }}
                                                                            >
                                                                              {
                                                                                apps.id
                                                                              }
                                                                            </p>
                                                                            <Box
                                                                              sx={{
                                                                                display:
                                                                                  "flex",
                                                                                gap: "2px",
                                                                              }}
                                                                            >
                                                                              <Tooltip
                                                                                title="Copy Meeting Link for Addressing Faculty"
                                                                                placement="left-end"
                                                                              >
                                                                                <Button
                                                                                  size="small"
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "12px",
                                                                                    padding:
                                                                                      "6px,10px",
                                                                                  }}
                                                                                  value={
                                                                                    apps.id
                                                                                  }
                                                                                  sx={{
                                                                                    background:
                                                                                      "#337CCF",
                                                                                    "&:hover":
                                                                                      {
                                                                                        background:
                                                                                          "#1450A3",
                                                                                      },
                                                                                  }}
                                                                                  type="button"
                                                                                  variant="contained"
                                                                                  onClick={
                                                                                    copyToClipboardtext
                                                                                  }
                                                                                >
                                                                                  Faculty
                                                                                </Button>
                                                                              </Tooltip>

                                                                              <Tooltip
                                                                                title="Copy Meeting Link for Student"
                                                                                placement="right-end"
                                                                              >
                                                                                <Button
                                                                                  size="small"
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "12px",
                                                                                    padding:
                                                                                      "6px,10px",
                                                                                  }}
                                                                                  value={
                                                                                    apps.id
                                                                                  }
                                                                                  type="button"
                                                                                  variant="contained"
                                                                                  sx={{
                                                                                    background:
                                                                                      "#76549A",
                                                                                    "&:hover":
                                                                                      {
                                                                                        background:
                                                                                          "#313866",
                                                                                      },
                                                                                  }}
                                                                                  onClick={
                                                                                    copyToClipboard
                                                                                  }
                                                                                >
                                                                                  Student
                                                                                </Button>
                                                                              </Tooltip>
                                                                            </Box>
                                                                          </StyledTableCell>
                                                                        </BootstrapTooltip>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Appointment Status">
                                                                            <p>
                                                                              {apps
                                                                                .status
                                                                                .id ===
                                                                              1 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#316B83",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 7px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                    fontWeight:
                                                                                      "500",
                                                                                  }}
                                                                                >
                                                                                  New
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                2 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "purple",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 6px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                    fontWeight:
                                                                                      "500",
                                                                                  }}
                                                                                >
                                                                                  Merged
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                3 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#1363DF",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 6px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Confirmed
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                4 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#379237",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Conducted
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                5 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#537188",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Date
                                                                                  Overdue
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                6 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#9D5C0D",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Yet
                                                                                  to
                                                                                  Approach
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                7 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#E86A33",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Pending
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                8 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#BE0000",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Not
                                                                                  Interested
                                                                                </p>
                                                                              ) : apps
                                                                                  .status
                                                                                  .id ===
                                                                                9 ? (
                                                                                <p
                                                                                  style={{
                                                                                    background:
                                                                                      "#898121",
                                                                                    color:
                                                                                      "white",
                                                                                    padding:
                                                                                      "8px 5px",
                                                                                    borderRadius:
                                                                                      "20px",
                                                                                  }}
                                                                                >
                                                                                  Unmerge
                                                                                </p>
                                                                              ) : (
                                                                                <>

                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Registration Failed">
                                                                            <p>
                                                                              {/* {apps
                                                                                .other_fields
                                                                                .registration
                                                                                .registration_failed_count ===
                                                                              null
                                                                                ? 0
                                                                                : apps
                                                                                    .other_fields
                                                                                    .registration
                                                                                    .registration_failed_count} */}
                                                                              0
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <Box>
                                                                              <p>
                                                                                {apps.registered_students ===
                                                                                null
                                                                                  ? 0
                                                                                  : apps.registered_students}
                                                                              </p>
                                                                            </Box>
                                                                          </BootstrapTooltip>

                                                                          <StudentRegisterList
                                                                            college_idx={
                                                                              apps
                                                                                .ids
                                                                                ?.college
                                                                                .id
                                                                            }
                                                                            appointment_idx={
                                                                              apps.id
                                                                            }
                                                                            level="5"
                                                                          ></StudentRegisterList>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps.other_college_registered_students_count ===
                                                                                null
                                                                                  ? 0
                                                                                  : apps.other_college_registered_students_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <OtherCollegeRegisterList
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  otherCollegeReg="5"
                                                                                ></OtherCollegeRegisterList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="TPO Follow-up">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip
                                                                            title="Spoc Details"
                                                                            placement="top"
                                                                          >
                                                                            <p>
                                                                              {Object.keys(
                                                                                apps.college_spocs
                                                                              )
                                                                                .length <
                                                                              1 ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  No
                                                                                  Spoc
                                                                                </Box>
                                                                              ) : (
                                                                                <>
                                                                                  <ContactModal
                                                                                    mobile={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .mobile
                                                                                    }
                                                                                    email={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .email
                                                                                    }
                                                                                    name={
                                                                                      Object.entries(
                                                                                        Object.values(
                                                                                          apps.college_spocs
                                                                                        )
                                                                                      )[0][1]
                                                                                        .name
                                                                                    }
                                                                                  />
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Addressing Date and Time">
                                                                            {/* <p>
                                                                              
                                                                              {apps.addressing_datetime ===
                                                                                null ||
                                                                              apps.addressing_datetime ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                apps.addressing_datetime.replace(
                                                                                  "+0000",
                                                                                  ""
                                                                                )
                                                                              )}
                                                                            </p> */}
                                                                            <p
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize:
                                                                                  "16px",
                                                                              }}
                                                                            >
                                                                              {apps.addressing_datetime ==
                                                                              "" ? (
                                                                                <Tooltip title="Create Addressing">
                                                                                  <Link
                                                                                    to="/create_newaddressing"
                                                                                    style={{
                                                                                      textDecoration:
                                                                                        "none",
                                                                                      padding:
                                                                                        "5px 3px",
                                                                                      borderRadius:
                                                                                        "10px",
                                                                                      color:
                                                                                        "Crimson",
                                                                                    }}
                                                                                  >
                                                                                    NA
                                                                                    <span>
                                                                                      <FaPlusCircle
                                                                                        fontSize={
                                                                                          18
                                                                                        }
                                                                                        color="#537FE7"
                                                                                        style={{
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          width:
                                                                                            "40px",
                                                                                        }}
                                                                                      />
                                                                                    </span>
                                                                                  </Link>
                                                                                </Tooltip>
                                                                              ) : (
                                                                                <>
                                                                                  {/* {apps.addressing_datetime.replace(
                                                                                    "+0000 UTC",
                                                                                    ""
                                                                                  )} */}
                                                                                  {moment
                                                                                    .utc(
                                                                                      apps.addressing_datetime
                                                                                    )
                                                                                    .local()
                                                                                    .format(
                                                                                      "DD-MM-yyyy hh:mm"
                                                                                    )}
                                                                                  <Tooltip title="Edit">
                                                                                    <AddressingEditModal
                                                                                      stateAddresssing={
                                                                                        apps
                                                                                      }
                                                                                      status={
                                                                                        apps
                                                                                          .status
                                                                                          .id
                                                                                      }
                                                                                    />
                                                                                  </Tooltip>
                                                                                </>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Addressing Follow-up">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Addressing follow-up Not done">
                                                                            <p>
                                                                              Yet
                                                                              to
                                                                              be
                                                                              developed
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Addressing Attended/Not Attended">
                                                                            <p>
                                                                              {apps.joined_student_count ===
                                                                                null ||
                                                                              apps.joined_student_count ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                apps.joined_student_count
                                                                              )}
                                                                              /
                                                                              {apps.not_joined_student_count ===
                                                                                null ||
                                                                              apps.not_joined_student_count ===
                                                                                "" ? (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              ) : (
                                                                                apps.not_joined_student_count
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.profile_filled_without_attending_addressing ===
                                                                                null
                                                                                  ? "N/A"
                                                                                  : apps.profile_filled_without_attending_addressing}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <ProfileFilledWithoutAttendAddressing
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  profileFilledWithouAttendAddressing="5"
                                                                                ></ProfileFilledWithoutAttendAddressing>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.profile_filled_after_attending_addressing_count ===
                                                                                null
                                                                                  ? "N/A"
                                                                                  : apps.profile_filled_after_attending_addressing_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <ProfileFilledAfterAttendAddressing
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  profileFilledAfterAttendAddressing="5"
                                                                                ></ProfileFilledAfterAttendAddressing>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.total_profile_filled ===
                                                                                null
                                                                                  ? "N/A"
                                                                                  : apps.total_profile_filled}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <TotalProfileFilled
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  totalProfileFilled="5"
                                                                                ></TotalProfileFilled>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.profile_not_filled_after_attending_addressing ===
                                                                                null
                                                                                  ? "N/A"
                                                                                  : apps.profile_not_filled_after_attending_addressing}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <ProfileNotFilledAfterAttendAddressing
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  profileNotFilledAfterAttendAddressing="5"
                                                                                ></ProfileNotFilledAfterAttendAddressing>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Rounds">
                                                                            <p
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize:
                                                                                  "16px",
                                                                              }}
                                                                            >
                                                                              {apps.addressing_rounds !==
                                                                              null ? (
                                                                                Object.keys(
                                                                                  apps
                                                                                    .addressing_rounds
                                                                                    .rounds
                                                                                ).map(
                                                                                  (
                                                                                    value,
                                                                                    index
                                                                                  ) => {
                                                                                    return (
                                                                                      <Box>
                                                                                        {
                                                                                          apps
                                                                                            .addressing_rounds
                                                                                            .rounds[
                                                                                            value
                                                                                          ]
                                                                                            .name
                                                                                        }
                                                                                      </Box>
                                                                                    );
                                                                                  }
                                                                                )
                                                                              ) : (
                                                                                <Box
                                                                                  sx={{
                                                                                    color:
                                                                                      "#EB455F",
                                                                                  }}
                                                                                >
                                                                                  NA
                                                                                </Box>
                                                                              )}
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps.quiz_attended_student_count ===
                                                                                  null ||
                                                                                apps.quiz_attended_student_count
                                                                                  ? "N/A"
                                                                                  : apps.quiz_attended_student_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizAttendedInAppointment
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizAttendedInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps.quiz_not_attended_count ===
                                                                                  null ||
                                                                                apps.quiz_not_attended_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.quiz_not_attended_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizNotAttendedInAppointment
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizNotAttendedInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {
                                                                                  apps.quiz_shortlisted_student_count
                                                                                }
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizShortlistedList
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  quizShortListLevel="5"
                                                                                ></QuizShortlistedList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps.quiz_not_selected_student_count ===
                                                                                  null ||
                                                                                apps.quiz_not_selected_student_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.quiz_not_selected_student_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizNotSelectedInAppointment
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizNotSelectedInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {apps.quiz_attended_other_college_student_count ===
                                                                                  null ||
                                                                                apps.quiz_attended_other_college_student_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.quiz_attended_other_college_student_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <QuizAttendedOtherCollegeInAppointment
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  addressing_idx={
                                                                                    apps.addressing_id
                                                                                  }
                                                                                ></QuizAttendedOtherCollegeInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Quiz Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Quiz follow-up Not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Quiz Rejected">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.roundsToShortAppointment
                                                                          }
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <p>
                                                                              {apps.group_count ===
                                                                                null ||
                                                                              apps.group_count ===
                                                                                ""
                                                                                ? 0
                                                                                : apps.group_count}
                                                                            </p>
                                                                            &nbsp;&nbsp;
                                                                            <p
                                                                              style={{
                                                                                fontSize:
                                                                                  "1px",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <GDGroupCountListInAppointment
                                                                                college_idx={
                                                                                  apps
                                                                                    .ids
                                                                                    ?.college
                                                                                    .id
                                                                                }
                                                                                appointment_idx={
                                                                                  apps.id
                                                                                }
                                                                                gdGroupCount="5"
                                                                              ></GDGroupCountListInAppointment>
                                                                            </p>
                                                                          </p>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.gd_registered_count ===
                                                                                  null ||
                                                                                apps.gd_registered_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.gd_registered_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDRegisteredListInTree
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdRegistered="5"
                                                                                ></GDRegisteredListInTree>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.gd_joined_students_count ===
                                                                                  null ||
                                                                                apps.gd_joined_students_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.gd_joined_students_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDJoinedListInTree
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdJoined="5"
                                                                                ></GDJoinedListInTree>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.gd_not_joined_count ===
                                                                                  null ||
                                                                                apps.gd_not_joined_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.gd_not_joined_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDNotJoinedListInTree
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdNotJoined="5"
                                                                                ></GDNotJoinedListInTree>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.gd_shortlisted_count ===
                                                                                  null ||
                                                                                apps.gd_shortlisted_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.gd_shortlisted_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDShortListInTree
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdShortlist="5"
                                                                                ></GDShortListInTree>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.gd_rejected_count ===
                                                                                  null ||
                                                                                apps.gd_rejected_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.gd_rejected_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDRejectedListInTree
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  gdRejected="5"
                                                                                ></GDRejectedListInTree>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <p>
                                                                                {apps.gd_hold_count ===
                                                                                  null ||
                                                                                apps.gd_hold_count ===
                                                                                  ""
                                                                                  ? 0
                                                                                  : apps.gd_hold_count}
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <GDHoldListInAppointment
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                ></GDHoldListInAppointment>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="GD Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="GD Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Final ShortListed">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="ShortList Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="ShortList Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Final Rejected">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="OnBoarding Meeting Date">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="OnBoarding Meeting Link">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="OnBoarding Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="OnBoarding Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Onbarding Attended">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Onboarding Not Attended">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Assign Branch">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Joining Date with Venue Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Joining Date with Venue Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Branch Joining Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Branch Joining Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {
                                                                                  apps.joined_student_count
                                                                                }
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <NotJoinedStudentList
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  joinedStudentLevel="5"
                                                                                ></NotJoinedStudentList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip>
                                                                            <p
                                                                              style={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              <p>
                                                                                {
                                                                                  apps.not_joined_student_count
                                                                                }
                                                                              </p>
                                                                              &nbsp;&nbsp;
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "1px",
                                                                                }}
                                                                              >
                                                                                {" "}
                                                                                <NotJoinedStudentList
                                                                                  college_idx={
                                                                                    apps
                                                                                      .ids
                                                                                      ?.college
                                                                                      .id
                                                                                  }
                                                                                  appointment_idx={
                                                                                    apps.id
                                                                                  }
                                                                                  notJoinedStudentLevel="5"
                                                                                ></NotJoinedStudentList>
                                                                              </p>
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Dropout Before Joining">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Before Joining Dropout Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Before Joining Dropout Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Dropout After Joining">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="After Joining Dropout Follow-up">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="After Joining Dropout Follow-up not done">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Replaced Students">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Interview Attended">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Interview Not Attended">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Got Job">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Got Job from Our Organization">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Got Job From Outside">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Message sent">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Message Field">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="TPO Report">
                                                                            <p>
                                                                              -----
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                      </TableRow>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <h4 style={{ color: "red" }}>Loading Table Data...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
          <aside style={{ width: "15%" }} className="actionBlock">
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {country.length > 0 ? (
                    <TableBody>
                      {country.map((coun, ind) => {
                        return (
                          <TableRow key={ind + 1}>
                            <StyledTableCell className={Styles.countryStyle}>
                              {/* {coun.id} */}
                              <p>NA</p>
                            </StyledTableCell>
                            <StyledTableCell className={Styles.countryStyle}>
                              {/* <FaEdit /> */}
                              <p>NA</p>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                      {expanded.length > 0
                        ? states.map((stat, indStat) => {
                            return (
                              <>
                                <TableRow key={indStat + 1}>
                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Status">
                                      {/* <p>{stat.id}</p> */}
                                      <p>NA</p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>

                                  <StyledTableCell
                                    className={Styles.stateStyle}
                                  >
                                    <BootstrapTooltip title="Action">
                                      <p>
                                        {/* <FaEdit /> */}
                                        NA
                                      </p>
                                    </BootstrapTooltip>
                                  </StyledTableCell>
                                </TableRow>
                                {expanded.length > 0 &&
                                expandedState.length > 0 &&
                                district !== undefined &&
                                district.length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0 &&
                                district.filter((stat) =>
                                  expandedState.includes(stat.state_id)
                                ).length > 0
                                  ? district
                                      .filter((stat) =>
                                        expandedState.includes(stat.state_id)
                                      )
                                      .filter((chp) => chp.state_id == stat.id)
                                      .map((dist, indDist) => {
                                        return (
                                          <>
                                            <TableRow key={indDist + 1}>
                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Status">
                                                  {/* <p>{dist.id}</p> */}
                                                  <p>NA</p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>

                                              <StyledTableCell
                                                className={Styles.districtStyle}
                                              >
                                                <BootstrapTooltip title="Action">
                                                  <p>
                                                    {/* <FaEdit /> */}
                                                    NA
                                                  </p>
                                                </BootstrapTooltip>
                                              </StyledTableCell>
                                            </TableRow>
                                            {expandedDistrict.length > 0 &&
                                            colleges.length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0 &&
                                            colleges.filter((ea) =>
                                              expandedDistrict.includes(
                                                ea.district_id
                                              )
                                            ).length > 0
                                              ? colleges
                                                  .filter((ea) =>
                                                    expandedDistrict.includes(
                                                      ea.district_id
                                                    )
                                                  )
                                                  .filter(
                                                    (ea) =>
                                                      ea.district_id == dist.id
                                                  )

                                                  .map((coll, indColl) => {
                                                    return (
                                                      <>
                                                        <TableRow
                                                          key={indColl + 1}
                                                        >
                                                          <StyledTableCell
                                                            align="center"
                                                            className={
                                                              Styles.collegeStyle1
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Status">
                                                              {/* <p> {coll.id}</p> */}
                                                              <p>NA</p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            align="center"
                                                            className={
                                                              Styles.collegeStyle1
                                                            }
                                                          >
                                                            <BootstrapTooltip title="Action">
                                                              <p>
                                                                {/* <FaEdit /> */}
                                                                NA
                                                              </p>
                                                            </BootstrapTooltip>
                                                          </StyledTableCell>
                                                        </TableRow>
                                                        {appointments != null &&
                                                        expandedCollege.length >
                                                          0 &&
                                                        appointments.filter(
                                                          (aph) =>
                                                            aph.ids.college
                                                              .id == coll.id
                                                        ).length > 0
                                                          ? appointments
                                                              .filter(
                                                                (aph) =>
                                                                  aph.ids
                                                                    .college
                                                                    .id ==
                                                                  coll.id
                                                              )
                                                              .map(
                                                                (
                                                                  apps,
                                                                  indApps
                                                                ) => {
                                                                  return (
                                                                    <>
                                                                      <TableRow
                                                                        key={
                                                                          indApps +
                                                                          1
                                                                        }
                                                                      >
                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle1
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Status">
                                                                            <p>
                                                                              NA
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                          className={
                                                                            Styles.appointmentStyle1
                                                                          }
                                                                        >
                                                                          <BootstrapTooltip title="Status">
                                                                            <p>
                                                                              NA
                                                                            </p>
                                                                          </BootstrapTooltip>
                                                                        </StyledTableCell>
                                                                      </TableRow>
                                                                    </>
                                                                  );
                                                                }
                                                              )
                                                          : null}
                                                      </>
                                                    );
                                                  })
                                              : null}
                                          </>
                                        );
                                      })
                                  : null}
                              </>
                            );
                          })
                        : null}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <h4 style={{ color: "red" }}>Loading Table Data...</h4>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};
export default MasterTableCommonCount;
