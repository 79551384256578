import * as React from "react";
import {
  Box,
  Checkbox,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../../apis/AxiosInstance";
import { BsFillEyeFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import GDEvolution from "./GDEvolution";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function GDShortListStudent(props) {
  let [appointment_id, setAppontment_id] = React.useState(props.group_idy);

  let [regdStudents, setRegdStudents] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `gd_shortlisted_students?group_id=${appointment_id}&page=${page}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleAppointmentOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `gd_shortlisted_students?group_id=${appointment_id}&page=${page}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  //! storing dta states
  const [quizList, setQuizList] = useState([]);

  useEffect(() => {
    quizList.length > 0 && toast.info(`${quizList.length} Students Selected`);
  }, [quizList]);

  //Single Checkbox logic

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setQuizList([...quizList, id]);
    } else {
      setQuizList(quizList.filter((row) => row !== id));
    }
  };

  const [modalOpen, setModalOpen] = useState(false);

  const [modalOpen1, setModalOpen1] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const totalPages = Math.ceil(totalLength / 10);

  return (
    <div>
      <Tooltip title="GD ShortList Students Details">
        <p
          onClick={handleAppointmentOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            GD ShortList Student Details
          </Typography>

          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Select For GD Evaluation
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "380px !important",
                    }}
                  >
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {calculateSlNo(id)}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          <Checkbox
                            disableRipple
                            name={`chk${value.id}`}
                            checked={quizList.includes(value.id)}
                            value={value.id}
                            onChange={(event) =>
                              handleCheckboxChange(event, value.id)
                            }
                            style={{
                              padding: "3px 10px 3px 20px",
                            }}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.full_name == null ? "NA" : value.full_name}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.mobiles == null ? "NA" : value.mobiles}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.emails == null ? "NA" : value.emails}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <h3 style={{ color: "red" }}>No Data Found</h3>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
          <Button
            style={{ background: "green", color: "#fff" }}
            onClick={() => {
              if (quizList.length > 0) {
                openModal();
              } else {
                toast.error("No students Selected");
              }
            }}
          >
            GD Evaluation
          </Button>
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            BackdropProps={{
              style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
            }}
          >
            {/* Modal content */}
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "60%",
                maxHeight: "90%",
                backgroundColor: "white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                // padding: 10,
                overflowY: "auto",
              }}
            >
              <button
                onClick={handleCloseModal}
                style={{
                  position: "fixed",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "30px",
                  color: "red",
                  zIndex: 99,
                }}
              >
                &#x2716;
              </button>
              {/* Content of your modal */}
              <GDEvolution
                studentData={
                  quizList.length > 0
                    ? quizList.map((e) => Number.parseInt(e))
                    : []
                }
                groupIdx={appointment_id}
              />
            </div>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
