import React from 'react'
import { Navigate, useRoutes } from "react-router-dom";
import Createpoints from '../Bd_Module/juniorVerifer/Createpoints';
import EditPointsAndScripts from '../Bd_Module/juniorVerifer/EditPointsAndScripts';
import PointsAndScriptsList from '../Bd_Module/juniorVerifer/PointsAndScriptsList';
import CallCategory from '../callRecord/CallCategory';
import CallCategoryList from '../callRecord/CallCategoryList';
import CallType from '../callRecord/CallType';
import CallTypelist from '../callRecord/CallTypelist';
import EditCallCategory from '../callRecord/EditCallCategory';
import EditMainStatus from '../callRecord/EditMainStatus';
import EditSubStatus from '../callRecord/EditSubStatus';
import IndivisualMainStatusEdit from '../callRecord/IndivisualMainStatusEdit';
import IndivisualSubStatusEdit from '../callRecord/IndivisualSubStatusEdit';
import Profile from "../components/profile/Profile";
import CreateNotificationType from '../superAdmin/CreateNotificationType';
import EditVideo from '../superAdmin/EditVideo';
import PostEscalationLevel from '../superAdmin/PostEscalationLevel';
import PostVideo from '../superAdmin/PostVideo';
import VideoList from '../superAdmin/VideoList';

const SuperAdminRoutes = () => {
  let routes = useRoutes([
   
    { path: "/profile", element: <Profile /> },
    { path: "*", element: <Navigate to="/"/> },
    {path:"/",element:<VideoList/>},
    {path:"/post_video",element:<PostVideo/>},
    {path:"/video_list",element:<VideoList/>},
    {path:"/edit_video",element:<EditVideo/>},
    {path:"/call_type",element:<CallType/>},
    {path:"/calltype_list",element:<CallTypelist/>},
    {path:"/call_category",element:<CallCategory/>},
    {path:"/call_category_list",element:<CallCategoryList/>},
    {path:"/edit_call_category",element:<EditCallCategory/>},
    {path:"/edit_call_mainstatus",element:<EditMainStatus/>},
    {path:"/edit_call_substatus",element:<EditSubStatus/>},
    {path:"/create_points",element:<Createpoints/>},
    {path:"/points_and_scripts_list",element:<PointsAndScriptsList/>},
    {path:"/edit_points_and_scripts",element:<EditPointsAndScripts/>},
    {path:"/create_escalation_type",element:<PostEscalationLevel/>},
    {path:"/create_notification_type",element:<CreateNotificationType/>},
    {path:"/indivisual_edit_main_status",element:<IndivisualMainStatusEdit/>},
    {path:"/indivisual_edit_sub_status",element:<IndivisualSubStatusEdit/>}
    
  
  ]);
  return routes;
}

export default SuperAdminRoutes