import {
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Avatar,
  Button,
  Typography,
  Grid,
  Paper,
  Box,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AxiosInstance from "../../../apis/AxiosInstance";

import Modal from "@mui/material/Modal";
import { AiFillCloseSquare } from "react-icons/ai";

const ShowGDEvolutionInTree = (props) => {
  let { stateAddresssing } = props;

  // let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let [statusOption, setStatusOption] = useState(null);

  //for appointmentStatus
  useEffect(() => {
    let fetchData = async () => {
      let appStatus = await AxiosInstance.get(`/gd_status`);
      let finalAppStatus = appStatus.data.data;
      setStatusOption(finalAppStatus);
    };
    if (open) {
      fetchData();
    }
  }, [open]);

  const randomKey =
    stateAddresssing?.gd_marks &&
    Object.keys(stateAddresssing.gd_marks).length > 0
      ? Object.entries(stateAddresssing.gd_marks)
      : [];
  const gdMarksFields =
    randomKey.length > 0
      ? randomKey.filter((e) => {
          return e[1] && !Array.isArray(e[1]) && Object.keys(e[1]).length > 0;
        })
      : [];
  const obj = {};
  const obj2 = {};
  if (gdMarksFields.length > 0) {
    gdMarksFields.forEach((e) => {
      if (e[0] == "comment") {
        obj["comment"] = e[1];
      } else if (e[0] == "gd_status") {
        obj["gd_status"] = Number(Object.keys(e[1])[0]);
      } else {
        obj2[e[0]] = e[1];
      }
    });
  }
  const [criteriaFields, setCriteriaFields] = useState(
    gdMarksFields.length > 0 ? obj : null
  );
  const [otherFields, setOtherFields] = useState(
    Object.keys(obj2).length > 0 ? obj2 : null
  );

  const paperStyle = {
    padding: 20,
    gap: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxHeight: "80vh",
    overflowY: "auto",
  };

  const avatarStyle = { backgroundColor: "#081F37" };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Show GD Result">
        <Button
          onClick={handleOpen}
          size="small"
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
          style={{ background: "#0766AD", color: "#fff" }}
        >
          Show Result
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid style={{ marginTop: "12%" }}>
          <Grid xs={12} item container style={gridContainer} align="center">
            <Paper elevation={10} style={paperStyle}>
              <Grid align="center" item>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <AiFillCloseSquare
                    fontSize={30}
                    onClick={handleClose}
                    color="#FC2947"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
                <Avatar style={avatarStyle}>
                  <GroupAddIcon />
                </Avatar>
                <h2>GD Evaluation Result</h2>
                <br />
              </Grid>
              <form>
                <Grid>
                  <div>
                    {criteriaFields ? (
                      <div>
                        {otherFields &&
                          Object.keys(otherFields).length > 0 &&
                          Object.entries(otherFields).map((ex) => {
                            return (
                              <>
                                <Typography
                                  variant="h6"
                                  style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    marginBottom: "20px",
                                    textTransform: "capitalize",
                                    textDecoration: "underline",
                                    fontSize: "medium",
                                  }}
                                >
                                  {ex[0].split("_").join(" ")}
                                </Typography>
                                <Grid
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Grid>
                                    <TextField
                                      label="Scored Marks"
                                      disabled
                                      name={`${ex[0]}_marks`}
                                      value={ex[1].scored_marks}
                                    />
                                  </Grid>
                                  <Grid>
                                    <TextField
                                      label="Total Marks"
                                      name={`${ex[0]}_total_marks`}
                                      value={ex[1].marks}
                                      disabled
                                    />
                                  </Grid>
                                  <Grid>
                                    <TextField
                                      label="Percentage"
                                      name={`${ex[0]}_percentage`}
                                      disabled
                                      value={ex[1].percentage}
                                    />
                                  </Grid>
                                </Grid>
                                <br />
                                <hr />
                              </>
                            );
                          })}
                        <Grid
                          item
                          sm={12}
                          id="refreshTool"
                          style={{ position: "relative", marginTop: "3%" }}
                        >
                          <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">
                              Status :
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              disabled
                              sx={{ textAlign: "left" }}
                              value={criteriaFields.gd_status}
                              label="Status"
                              onChange={(e) => {
                                setCriteriaFields({
                                  ...criteriaFields,
                                  ["gd_status"]: e.target.value,
                                });
                              }}
                            >
                              {statusOption === null ? (
                                <MenuItem>No data Available</MenuItem>
                              ) : (
                                statusOption.map((e) => {
                                  return (
                                    <MenuItem
                                      value={e.id}
                                      key={"status" + e.id}
                                    >
                                      {e.name}
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: "3%" }}>
                          <TextField
                            label="Comment"
                            disabled
                            multiline
                            maxRows={5}
                            fullWidth
                            name="comment"
                            value={criteriaFields.comment}
                            onChange={(e) => {
                              setCriteriaFields({
                                ...criteriaFields,
                                ["comment"]: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                      </div>
                    ) : (
                      <Typography variant="body1">No Data available</Typography>
                    )}
                  </div>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default ShowGDEvolutionInTree;
