import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Box,
  FormControl,
  Autocomplete,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AxiosInstance from "../apis/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

const removeDuplicatesById = array => {
  const uniqueObjects = array.reduce((acc, obj) => {
    const isDuplicate = acc.some(item => item.id === obj.id);
    if (!isDuplicate) {
      acc.push(obj);
    }
    return acc;
  }, []);
  return uniqueObjects;
};

const AddCallStatusRecord = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [searchCategory, setSearchCategory] = useState("");
  const [categoryData, setCategoryData] = useState(null);
  const [categoryOption, setCategoryOption] = useState([]);

  const [mainData, setMainData] = useState([
    {
      main_statusWeight: "",
      subData: [
        {
          subStatusWeight: "",
        },
      ],
    },
  ]);

  const [mainStatusOption, setMainStatusOption] = useState([]);
  const [mainStatusData, setMainStatusData] = useState(
    state?.call_status?.call_status &&
      Object.keys(state.call_status.call_status).length > 0
      ? state.call_status.call_status
      : null
  );
  const [comment, setComment] = useState("");
  const [subStatusOption, setSubStatusOption] = useState([]);
  const [subStatusData, setSubStatusData] = useState(
    state?.call_status?.call_status?.sub_status &&
      Object.keys(state.call_status.call_status.sub_status).length > 0
      ? state.call_status.call_status.sub_status
      : null
  );

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchCategory !== "") {
        let data = await AxiosInstance.get(
          `/call_category_mini?disable=false&search_keyword=${searchCategory}&limit=10`
        );
        let finalData = data.data.data;
        setCategoryOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/call_category_mini?disable=false&limit=10`);
        let finalData = data.data.data;
        setCategoryOption(finalData);
      }
    };
    fetchData();
  }, [searchCategory]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        record_id: state.id,
        call_status: {
          category_id: {
            id: categoryData.id,
            name: categoryData.name,
          },
          call_status: {
            id: mainStatusData.id,
            main_status: mainStatusData.main_status,
            weight: mainStatusData.weight,
            comment:comment,
            // sub_status: {
            //   id: subStatusData.id,
            //   sub_status: subStatusData.sub_status,
            //   weight: subStatusData.weight,
            //   positive: subStatusData.positive,
            // },
            sub_status: subStatusData
            ? {
                id: subStatusData.id,
                sub_status: subStatusData.sub_status,
                weight: subStatusData.weight,
                positive: subStatusData.positive,
              }
            : {},
          },
        },
      };

      await AxiosInstance.post(`/call_record_status`, payload)
        .then((response) => {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/nostatus_record_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response?.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response?.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "950px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border:"2px solid gray",
    borderRadius:"10px"
  };
  const avatarStyle = { backgroundColor: "#06113C" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#06113C",
    color: "#fff",
    border: "1px solid #06113C",
  };

  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };
  const fetchMainData = async (e) => {
    try {
      const { data } = await AxiosInstance.get(`/call_category?disable=false&id=${e.id}`);
      if (data.data && data.data.length > 0) {
        const fetcheddata = data.data.length > 0 ? data.data : null;
        if (fetcheddata) {
          let mainXData = [];
          fetcheddata.forEach((item) => {
            if (item.call_status && item.call_status.length > 0) {
              mainXData.push(...item.call_status.filter(e => !e.disable));
            }
          });
          setMainStatusOption(mainXData);
          const subStatusOptions = mainXData
          .filter((e) => (mainStatusData ? e.id === mainStatusData.id : e))
          .flatMap((item) =>
            item.sub_status.filter(e=>!e.disable).map((sub) => ({
              ...sub,
              main_status: item.main_status,
              main_id: item.id,
            }))
          );
          setSubStatusOption(removeDuplicatesById(subStatusOptions));
        } else {
          setMainStatusOption([]);
          setSubStatusOption([]);
        }
      } else {
        setMainStatusOption([]);
        setSubStatusOption([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetMain = () => {
    setMainStatusData(null);
    setSubStatusData(null);
    setSubStatusOption([]);
    setMainStatusOption([]);
    setMainData([
      {
        main_statusWeight: "",
        subData: [
          {
            subStatusWeight: "",
          },
        ],
      },
    ]);
  };
  return (
    <Box
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Add Call Status Record</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Grid item sm={12}>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3>Audio :</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {state.audio ? (
                      <audio
                        src={`https://incubationb.qspiders.com/api/v1/get_audio?name=${state.audio}`}
                        controls
                        controlsList="nodownload"
                      />
                    ) : (
                      "NA"
                    )}
                  </span>{" "}
                </Grid>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "-2%" }}>
                <FormControl fullWidth>
                  <Item
                    onChange={(e) => {
                      setSearchCategory(e.target.value);
                    }}
                  >
                    <Autocomplete
                      options={categoryOption}
                      value={categoryData}
                      name="categoryOption"
                      onChange={(event, newData_xy) => {
                        setCategoryData(newData_xy);
                        resetMain();
                        if (newData_xy) {
                          fetchMainData(newData_xy);
                        }
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Category"
                          variant="outlined"
                          required
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },                        
                          }}
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid>
              <Box
                style={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {mainData.map((val, ind) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      style={{
                        border: "2px solid gray",
                        marginTop: "5px",
                        margin: "0 auto",
                        marginLeft: "2%",
                        paddingBottom: "15px",
                        borderRadius: "20px",
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        style={{
                          color: "blue",
                          textAlign: "start",
                          fontWeight:"bold"
                        }}
                      >
                        Main Status
                      </Grid>
                      <Grid item sm={11} style={{ marginTop: "-1%" }}>
                        <Item>
                          <Autocomplete
                            options={mainStatusOption}
                            value={mainStatusData}
                            name="mainStatusOption"
                            onChange={(_, y) => {
                              setMainStatusData(y);
                              setSubStatusData(null);
                              if (y) {
                                setMainData([
                                  {
                                    ...mainData[0],
                                    main_statusWeight: y.weight,
                                  },
                                ]);
                                const subStatusOptions = y.sub_status.filter(e => !e.disable);
                                setSubStatusOption(subStatusOptions);
                              } else {
                                setMainData([
                                  { ...mainData[0], main_statusWeight: "" },
                                ]);
                                setSubStatusOption([]);
                              }
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id == value.id
                            }
                            getOptionLabel={(option) => option.main_status}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select MainStatus"
                                variant="outlined"
                                required
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    borderColor: "rgba(0, 0, 0, 10)",
                                    "& fieldset": {
                                      borderColor: "rgba(0, 0, 0, 5)",
                                    },
                                  },                        
                                }}
                              />
                            )}
                          />
                        </Item>
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        style={{
                          // marginTop: "1%",
                          color: "blue",
                          textAlign: "start",
                          fontWeight:"bold"
                        }}
                      >
                        Sub Status
                      </Grid>
                      {val.subData.length > 0 ? (
                        val.subData.map((value, index) => {
                          return (
                            <Grid
                              item
                              container
                              sm={12}
                              spacing={2}
                              style={{
                                margin: "0 auto",
                                marginLeft: "1%",
                                paddingBottom: "15px",
                              }}
                            >
                              <Grid item sm={10} style={{ marginTop: "-2.5%" }}>
                                <Item>
                                  <Autocomplete
                                    options={subStatusOption}
                                    value={subStatusData}
                                    name="subStatusOption"
                                    onChange={(e, y) => {
                                      setSubStatusData(y);
                                      if (y) {
                                        const arr = [...mainData[0].subData];
                                        arr[0] = {
                                          subStatusWeight: y.weight,
                                        };
                                        setMainData([
                                          { ...mainData[0], subData: arr },
                                        ]);
                                      } else {
                                        const arr = [...mainData[0].subData];
                                        arr[0] = {
                                          subStatusWeight: "",
                                        };
                                        setMainData([
                                          { ...mainData[0], subData: arr },
                                        ]);
                                      }
                                    }}
                                    getOptionLabel={(option) =>
                                      option.sub_status
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.id == value.id
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select SubStatus"
                                        variant="outlined"
                                        // required
                                        required={!categoryData || categoryData.id !== 6}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            borderColor: "rgba(0, 0, 0, 10)",
                                            "& fieldset": {
                                              borderColor: "rgba(0, 0, 0, 5)",
                                            },
                                          },                        
                                        }}
                                      />
                                    )}
                                  />
                                </Item>
                              </Grid>
                            </Grid>
                          );
                        })
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            color: "red",
                            padding: "5px 10px",
                            background: "lightgrey",
                            width: "100%",
                          }}
                        >
                          No Data Found
                        </p>
                      )}
                    </Grid>
                  );
                })}
              </Box>
            </Grid>
            <Grid item sm={11} style={{ marginTop: "1.5%" }}>
              <TextField
                id="outlined-comment-input"
                label="Comment"
                name="comment"
                type="text"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                rows={2}
                multiline
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderColor: "rgba(0, 0, 0, 10)",
                    "& fieldset": {
                      borderColor: "rgba(0, 0, 0, 5)",
                    },
                  },                        
                }}
                // required
              />
            </Grid>
            <Grid item container sm={12} spacing={4}>
              <Grid item sm={3} style={{ marginLeft: "45%" }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={3}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default AddCallStatusRecord;
