import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  styled,
  Grid,
  TextField,
  Autocomplete,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import Style from "../../../student/list.module.css";
import { RiFilterOffFill } from "react-icons/ri";
import AxiosInstance from "../../../apis/AxiosInstance";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#176B87",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function JoinedStudentList() {
  let [studentList, setStudentList] = useState([]);

  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  // pagination
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const params = {
    state_id: selectedColgState !== null ? selectedColgState.id : null,
    district_id: selectedColgDistrict !== null ? selectedColgDistrict.id : null,
    id: selectedCollege !== null ? selectedCollege.id : null,
  };

  const handleSearch = () => {
    const validParams = Object.keys(params).filter(
      (key) => params[key] !== null
    );
    const generateURL_Query = validParams
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    setQuery(generateURL_Query);
  };

  useEffect(() => {
    let fetchData = async () => {
      if (query !== "") {
        try {
          let data = await AxiosInstance.get(
            `/joined_students?${query}&country_id=2&page=${page}`
          );
          let finalData = data.data.data;
          setStudentList(finalData);
          setTotalLength(data.data.total_length);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let data = await AxiosInstance.get(
            `/joined_students?country_id=2&page=${page}`
          );
          let finalData = data.data.data;
          setStudentList(finalData);
          setTotalLength(data.data.total_length);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [query, page]);

  const totalPages = Math.ceil(totalLength / 10);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      //college state api
      if (stateSearch !== "") {
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      } else {
        let colgStateData = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [stateSearch, countryMiniId]);

  //! for college district
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        if (distSearch !== "") {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?search_keyword=${distSearch}&limit=10&state_id=${selectedColgState.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        } else {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?limit=10&state_id=${selectedColgState.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        }
      }
    };
    fetchData();
  }, [distSearch, selectedColgState]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgDistrict !== null) {
        if (collSearch !== null) {
          let collegeData = await AxiosInstance.get(
            `/college_mini?search_keyword=${collSearch}&limit=10&district_id=${selectedColgDistrict.id}`
          );
          let finalCollegeData = collegeData.data.data;

          setSelectedCollegeOptions(finalCollegeData);
        } else {
          let collegeData = await AxiosInstance.get(
            `/college_mini?limit=10&state_id=${selectedColgState.id}&district_id=${selectedColgDistrict.id}`
          );
          let finalCollegeData = collegeData.data.data;
          setSelectedCollegeOptions(finalCollegeData);
        }
      }
    };
    fetchData();
  }, [collSearch, selectedColgDistrict]);

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    window.location.href = "/treeregistered_studentlist";
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "5%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>Joined Student List</h1>

      <Grid
        container
        style={{
          width: "90%",
          alignItems: "center",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <Grid item lg={3}>
          <Item
            onChange={(e) => {
              setStateSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={colgStateOptions}
              value={selectedColgState}
              name="state_name"
              onChange={(event, newColgState) => {
                setSelectedColgState(newColgState);
                setselectedColgDistrict(null);
                setSelectedCollege(null);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Students By State"
                  variant="outlined"
                  required
                  error={error && !selectedColgState}
                  helperText={
                    error && !selectedColgState
                      ? "Please select College State"
                      : null
                  }
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3}>
          <Item
            onChange={(e) => {
              setDistSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={colgDistrictOptions}
              value={selectedColgDistrict}
              name="state_name"
              onChange={(event, newColgdis) => {
                setselectedColgDistrict(newColgdis);
                setSelectedCollege(null);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Students By District"
                  variant="outlined"
                  required
                  error={error && !selectedColgDistrict}
                  helperText={
                    error && !selectedColgDistrict
                      ? "Please select District"
                      : null
                  }
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3}>
          <Item
            onChange={(e) => {
              setCollSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={selectedCollegeOptions}
              value={selectedCollege}
              name="college_name"
              onChange={(event, newcollege) => {
                setSelectedCollege(newcollege);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Students By Colleges"
                  variant="outlined"
                  required
                  error={error && !selectedCollege}
                  helperText={
                    error && !selectedCollege ? "Please select College" : null
                  }
                />
              )}
            />
          </Item>
        </Grid>
        <Grid
          item
          md={1.2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            onClick={() => {
              handleSearch();
            }}
            style={{
              fontSize: "16px",
              color: "#fff",
              padding: "4px 30px",
              cursor: "pointer",
              backgroundColor: "#6C9BCF",
              borderRadius: "40px",
              border: "2px solid #efefef",
            }}
          >
            Search
          </button>
        </Grid>
        <Grid
          item
          md={1.2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            style={{
              fontSize: "16px",
              color: "black",
              padding: "4px 10px",
              // width: "50%",
              cursor: "pointer",
              backgroundColor: "#FF7D7D",
              borderRadius: "40px",
              border: "2px solid #efefef",
            }}
            onClick={ResetFilterData}
          >
            Reset Filters{" "}
            <span>
              <RiFilterOffFill fontSize={16} />
            </span>
          </button>
        </Grid>
      </Grid>
      <Grid>
        <h1 style={{ marginLeft: "35%", marginTop: "0%", color: "#495579" }}>
          Total Joined Students : {totalLength} &nbsp;&nbsp;
        </h1>
      </Grid>

      <Paper
        style={{
          maxHeight: "90vh",
          width: "90%",
          margin: "0 auto",
          border: "1px solid #111",
        }}
      >
        <TableContainer style={{ maxHeight: 600 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Student Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList !== null ? (
                studentList.map((val, ind) => {
                  return (
                    <StyledTableRow key={ind + 1}>
                      <TableCell component="th" scope="row" align="center">
                        {val.full_name === "" ? "N/A" : val.full_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.mobiles === null
                          ? "N/A"
                          : val.mobiles?.map((mob) => <Box>{mob}</Box>)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.emails === null
                          ? "N/A"
                          : val.emails?.map((mail) => <Box>{mail}</Box>)}
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow component="th" scope="row" align="center">
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    colSpan={4}
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "red",
                    }}
                  >
                    No Data
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
