// import * as React from "react";
// import {
//   Box,
//   Typography,
//   Modal,
//   Tooltip,
//   Card,
//   CardContent,
// } from "@mui/material";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { BsFillEyeFill } from "react-icons/bs";
// import AxiosInstance from "../../apis/AxiosInstance";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "60vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "50%",
//   zIndex: "999",
// };

// export default function DocTypeList(props) {
//   let [regdStudents, setRegdStudents] = React.useState("");
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [loadReg, setLoadRed] = React.useState(false);
//   const handleAppointmentOpen = async (e) => {
//     handleOpen();
//     setLoadRed(true);
//     try {
//       let response = await AxiosInstance.get(`get_doc?name=${props.docfile}`);

//       setRegdStudents(response.data);
//       setLoadRed(false);
//     } catch (error) {
//       setLoadRed(false);
//       console.log(error);
//     }
//   };

//   console.log("regdStudents", regdStudents);

//   return (
//     <div>
//       <Tooltip title="Doc Type Details">
//         <p
//           onClick={handleAppointmentOpen}
//           sx={{
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <BsFillEyeFill
//             fontSize={17}
//             style={{
//               color: "#646FD4",
//               cursor: "pointer",
//               "&:hover": { color: "#EB455F" },
//             }}
//           />
//         </p>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Typography
//             id="modal-modal-title"
//             variant="h6"
//             component="h2"
//             sx={{ fontSize: "30px", fontWeight: "bold" }}
//           >
//             Doc Type Details
//           </Typography>

//           <Card
//             style={{
//               width: "90%",
//               marginTop: "1%",
//               height: "50%",
//               overflowY: "auto",
//             }}
//           >
//             <CardContent>
//               {regdStudents && (
//                 <img
//                   src={regdStudents}
//                   alt="Document Preview"
//                   style={{ width: "100%", height: "auto" }}
//                 />
//               )}
//             </CardContent>
//           </Card>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// import * as React from "react";
// import {
//   Box,
//   Typography,
//   Modal,
//   Tooltip,
//   Card,
//   CardContent,
// } from "@mui/material";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { BsFillEyeFill } from "react-icons/bs";
// import AxiosInstance from "../../apis/AxiosInstance";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "80vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "90%",
//   zIndex: "999",
// };

// export default function DocTypeList(props) {
//   const [regdStudents, setRegdStudents] = React.useState("");
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   console.log("regStudents", regdStudents)

//   const [loadReg, setLoadRed] = React.useState(false);

//   const handleAppointmentOpen = async () => {
//     handleOpen();
//     setLoadRed(true);
//     try {
//       let response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
//         responseType: 'blob' // Important for handling different file types correctly
//       });

//       const fileType = props.docfile.split('.').pop().toLowerCase();

//       if (fileType === 'txt' || fileType === 'csv') {
//         const textData = await response.data.text();
//         setRegdStudents({ type: fileType, content: textData });
//       } else if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'pdf') {
//         const fileData = URL.createObjectURL(response.data);
//         setRegdStudents({ type: fileType, content: fileData });
//       }

//       setLoadRed(false);
//     } catch (error) {
//       setLoadRed(false);
//       console.log(error);
//     }
//   };

//   const renderDocument = () => {
//     if (!regdStudents) return null;

//     const { type, content } = regdStudents;

//     switch (type) {
//       case 'png':
//       case 'jpeg':
//       case 'jpg':
//         return <img src={content} alt="Document Preview" style={{ width: "100%", height: "auto" }} />;
//       case 'txt':
//       case 'csv':
//         return <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{content}</pre>;
//       case 'pdf':
//         return <iframe src={content} style={{ width: "100%", height: "100%" }} frameBorder="0" />;
//       default:
//         return <Typography variant="body2">Unsupported file type</Typography>;
//     }
//   };

//   return (
//     <div>
//       <Tooltip title="Doc Type Details">
//         <p
//           onClick={handleAppointmentOpen}
//           style={{
//             cursor: "pointer",
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <BsFillEyeFill
//             fontSize={17}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//             }}
//           />
//         </p>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Card
//             style={{
//               width: "100%",
//               height: "85%",
//               overflowY: "auto",
//             }}
//           >
//             <CardContent style={{ height: '100%', padding: 0 }}>
//               {renderDocument()}
//             </CardContent>
//           </Card>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

//=============================================================

// import * as React from "react";
// import {
//   Box,
//   Typography,
//   Modal,
//   Tooltip,
//   Card,
//   CardContent,
//   Button,
// } from "@mui/material";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { BsFillEyeFill } from "react-icons/bs";
// import AxiosInstance from "../../apis/AxiosInstance";

// const defaultStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "80vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "90%",
//   zIndex: "999",
// };

// const csvStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "15vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "auto",
//   zIndex: "999",
// };

// export default function DocTypeList(props) {
//   const [regdStudents, setRegdStudents] = React.useState("");
//   const [open, setOpen] = React.useState(false);
//   const [modalStyle, setModalStyle] = React.useState(defaultStyle);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [loadReg, setLoadRed] = React.useState(false);

//   const handleAppointmentOpen = async () => {
//     handleOpen();
//     setLoadRed(true);
//     try {
//       let response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
//         responseType: 'blob' // Important for handling different file types correctly
//       });

//       const fileType = props.docfile.split('.').pop().toLowerCase();

//       if (fileType === 'txt' || fileType === 'csv') {
//         const textData = await response.data.text();
//         setRegdStudents({ type: fileType, content: textData });
//         setModalStyle(fileType === 'csv' ? csvStyle : defaultStyle);
//       } else if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'pdf') {
//         const fileData = URL.createObjectURL(response.data);
//         setRegdStudents({ type: fileType, content: fileData });
//         setModalStyle(defaultStyle);
//       }

//       setLoadRed(false);
//     } catch (error) {
//       setLoadRed(false);
//       console.log(error);
//     }
//   };

//   const handleCsvDownload = () => {
//     const blob = new Blob([regdStudents.content], { type: 'text/csv;charset=utf-8;' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', `${props.docfile.split('.')[0]}.csv`);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   const renderDocument = () => {
//     if (!regdStudents) return null;

//     const { type, content } = regdStudents;

//     switch (type) {
//       case 'png':
//       case 'jpeg':
//       case 'jpg':
//         return <img src={content} alt="Document Preview" style={{ width: "100%", height: "auto" }} />;
//       case 'txt':
//         return <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{content}</pre>;
//       case 'csv':
//         return (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCsvDownload}
//             style={{ padding:"10px",margin:"20px" }}
//           >
//             Download CSV
//           </Button>
//         );
//       case 'pdf':
//         return <iframe src={content} style={{ width: "100%", height: "100%" }} frameBorder="0" />;
//       default:
//         return <Typography variant="body2">Unsupported file type</Typography>;
//     }
//   };

//   return (
//     <div>
//       <Tooltip title="Doc Type Details">
//         <p
//           onClick={handleAppointmentOpen}
//           style={{
//             cursor: "pointer",
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <BsFillEyeFill
//             fontSize={17}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//             }}
//           />
//         </p>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Card
//             style={{
//               width: "100%",
//               height: "85%",
//               overflowY: "auto",
//             }}
//           >
//             <CardContent style={{ height: '100%', padding: 0 }}>
//               {renderDocument()}
//             </CardContent>
//           </Card>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

//====================================

// import * as React from "react";
// import {
//   Box,
//   Typography,
//   Modal,
//   Tooltip,
//   Card,
//   CardContent,
//   Button,
// } from "@mui/material";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { BsFillEyeFill } from "react-icons/bs";
// import AxiosInstance from "../../apis/AxiosInstance";

// const defaultStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "80vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "90%",
//   zIndex: "999",
// };

// const smallModalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "20vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "auto",
//   zIndex: "999",
// };

// const mp3ModalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "30vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "auto",
//   zIndex: "999",
// };

// export default function DocTypeList(props) {
//   const [regdStudents, setRegdStudents] = React.useState(null);
//   const [open, setOpen] = React.useState(false);
//   const [modalStyle, setModalStyle] = React.useState(defaultStyle);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [loading, setLoading] = React.useState(false);

//   const handleAppointmentOpen = async () => {
//     handleOpen();
//     setLoading(true);
//     try {
//       let response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
//         responseType: "blob", // Important for handling different file types correctly
//       });

//       const fileType = props.docfile.split(".").pop().toLowerCase();

//       if (fileType === "txt" || fileType === "csv" || fileType === "json") {
//         const textData = await response.data.text();
//         setRegdStudents({ type: fileType, content: textData });
//         setModalStyle(
//           fileType === "csv" || fileType === "json"
//             ? smallModalStyle
//             : defaultStyle
//         );
//       } else if (
//         fileType === "png" ||
//         fileType === "jpeg" ||
//         fileType === "jpg" ||
//         fileType === "pdf" ||
//         fileType === "mp3" ||
//         fileType === "mp4"
//       ) {
//         const fileData = URL.createObjectURL(response.data);
//         setRegdStudents({ type: fileType, content: fileData });
//         setModalStyle(fileType === "mp3" ? mp3ModalStyle : defaultStyle);
//       }

//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };

//   const handleDownload = () => {
//     if (!regdStudents) return;

//     const { type, content } = regdStudents;
//     let data = null;
//     let fileName = props.docfile.split(".")[0];

//     if (type === "txt" || type === "csv") {
//       data = new Blob([content], { type: "text/csv;charset=utf-8;" });
//       fileName += `.${type}`;
//     } else if (type === "json") {
//       data = new Blob([content], { type: "application/json;charset=utf-8;" });
//       fileName += ".json";
//     }

//     if (data) {
//       const url = URL.createObjectURL(data);
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", fileName);
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   const renderDocument = () => {
//     if (!regdStudents) return null;

//     const { type, content } = regdStudents;

//     switch (type) {
//       case "png":
//       case "jpeg":
//       case "jpg":
//         return (
//           <img
//             src={content}
//             alt="Document Preview"
//             style={{ width: "100%", height: "auto" }}
//           />
//         );
//       case "txt":
//       case "json":
//         return (
//           <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
//             {content}
//           </pre>
//         );
//       case "csv":
//         return (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleDownload}
//             style={{ padding: "10px", margin: "20px" }}
//           >
//             Download {type.toUpperCase()}
//           </Button>
//         );
//       case "pdf":
//         return (
//           <iframe
//             src={content}
//             style={{ width: "100%", height: "100%" }}
//             frameBorder="0"
//           />
//         );
//       case "mp3":
//         return (
//           <audio
//             controls
//             src={content}
//             style={{ width: "100%", marginTop: "3%" }}
//           />
//         );
//       case "mp4":
//         return (
//           <video
//             controls
//             src={content}
//             style={{ width: "100%", height: "auto", marginTop: "2%" }}
//           />
//         );
//       default:
//         return <Typography variant="body2">Unsupported file type</Typography>;
//     }
//   };

//   return (
//     <div>
//       <Tooltip title="Doc Type Details">
//         <p
//           onClick={handleAppointmentOpen}
//           style={{
//             cursor: "pointer",
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <BsFillEyeFill
//             fontSize={17}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//             }}
//           />
//         </p>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Card
//             style={{
//               width: "100%",
//               height: "85%",
//               overflowY: "auto",
//             }}
//           >
//             <CardContent style={{ height: "100%", padding: 0 }}>
//               {renderDocument()}
//             </CardContent>
//           </Card>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// import * as React from "react";
// import {
//   Box,
//   Typography,
//   Modal,
//   Tooltip,
//   Card,
//   CardContent,
//   Button,
// } from "@mui/material";
// import { AiFillCloseSquare } from "react-icons/ai";
// import { BsFillEyeFill } from "react-icons/bs";
// import AxiosInstance from "../../apis/AxiosInstance";
// import { act } from "react";

// const defaultStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "80vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "90%",
//   zIndex: "999",
// };

// const smallModalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "20vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "auto",
//   zIndex: "999",
// };

// const mp3ModalStyle = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "30vw",
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   p: 2,
//   height: "auto",
//   zIndex: "999",
// };

// export default function DocTypeList(props) {
//   const [regdStudents, setRegdStudents] = React.useState(null);
//   const [open, setOpen] = React.useState(false);
//   const [modalStyle, setModalStyle] = React.useState(defaultStyle);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const [loading, setLoading] = React.useState(false);

//   const handleAppointmentOpen = async () => {
//     handleOpen();
//     setLoading(true);
//     try {
//       let response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
//         responseType: "blob", // Important for handling different file types correctly
//       });

//       const fileType = props.docfile.split(".").pop().toLowerCase();

//       if (
//         fileType === "txt" ||
//         fileType === "csv" ||
//         fileType === "json" ||
//         fileType === "xlsx"
//       ) {
//         const textData = await response.data.text();
//         setRegdStudents({ type: fileType, content: textData });
//         setModalStyle(
//           fileType === "csv" || fileType === "json" || fileType === "xlsx"
//             ? smallModalStyle
//             : defaultStyle
//         );
//       } else if (
//         fileType === "png" ||
//         fileType === "jpeg" ||
//         fileType === "jpg" ||
//         fileType === "pdf" ||
//         fileType === "mp3" ||
//         fileType === "mp4"
//       ) {
//         const fileData = URL.createObjectURL(response.data);
//         setRegdStudents({ type: fileType, content: fileData });
//         setModalStyle(fileType === "mp3" ? mp3ModalStyle : defaultStyle);
//       }

//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };

//   const handleDownload = () => {
//     if (!regdStudents) return;

//     const { type, content } = regdStudents;
//     let data = null;
//     let fileName = props.docfile.split(".")[0];

//     if (type === "txt" || type === "csv") {
//       data = new Blob([content], { type: "text/csv;charset=utf-8;" });
//       fileName += `.${type}`;
//     } else if (type === "json") {
//       data = new Blob([content], { type: "application/json;charset=utf-8;" });
//       fileName += ".json";
//     } else if (type === "xlsx") {
//       data = new Blob([content], {
//         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       });
//       fileName += ".xlsx";
//     }

//     if (data) {
//       const url = URL.createObjectURL(data);
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", fileName);
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   const renderDocument = () => {
//     if (!regdStudents) return null;

//     const { type, content } = regdStudents;

//     switch (type) {
//       case "png":
//       case "jpeg":
//       case "jpg":
//         return (
//           <img
//             src={content}
//             alt="Document Preview"
//             style={{ width: "100%", height: "auto" }}
//           />
//         );
//       case "txt":
//       case "json":
//         return (
//           <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
//             {content}
//           </pre>
//         );
//       case "csv":
//         return (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleDownload}
//             style={{ padding: "10px", margin: "20px" }}
//           >
//             Download {type.toUpperCase()}
//           </Button>
//         );
//       case "xlsx":
//         return (
//           <Button
//             variant="contained"
//             color="primary"
//             // onClick={handleDownload}
//             style={{ padding: "10px", margin: "20px",textDecoration:"none" }}
//           >
//             {/* Download {type.toUpperCase()} */}
//             <a
//             href={`http://106.51.74.69:7979/api/v1/get_doc?name=${props.docfile}`}
//           ></a>
//           </Button>

//         );
//       case "pdf":
//         return (
//           <iframe
//             src={content}
//             style={{ width: "100%", height: "100%" }}
//             frameBorder="0"
//           />
//         );
//       case "mp3":
//         return (
//           <audio
//             controls
//             src={content}
//             style={{ width: "100%", marginTop: "3%" }}
//           />
//         );
//       case "mp4":
//         return (
//           <video
//             controls
//             src={content}
//             style={{ width: "100%", height: "auto", marginTop: "2%" }}
//           />
//         );
//       default:
//         return <Typography variant="body2">Unsupported file type</Typography>;
//     }
//   };

//   return (
//     <div>
//       <Tooltip title="Doc Type Details">
//         <p
//           onClick={handleAppointmentOpen}
//           style={{
//             cursor: "pointer",
//             "&:hover": { background: "#DBDFFD" },
//           }}
//         >
//           <BsFillEyeFill
//             fontSize={17}
//             style={{
//               color: "#646FD4",
//               "&:hover": { color: "#EB455F" },
//             }}
//           />
//         </p>
//       </Tooltip>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={modalStyle}>
//           <Box
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "flex-end",
//             }}
//           >
//             <AiFillCloseSquare
//               fontSize={30}
//               onClick={handleClose}
//               color="#FC2947"
//               style={{ cursor: "pointer" }}
//             />
//           </Box>
//           <Card
//             style={{
//               width: "100%",
//               height: "85%",
//               overflowY: "auto",
//             }}
//           >
//             <CardContent style={{ height: "100%", padding: 0 }}>
//               {renderDocument()}
//             </CardContent>
//           </Card>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Tooltip,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import AxiosInstance from "../../apis/AxiosInstance";

const defaultStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "90%",
  zIndex: "999",
};

const smallModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

const mp3ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function DocTypeList(props) {
  const [regdStudents, setRegdStudents] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [modalStyle, setModalStyle] = React.useState(defaultStyle);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = React.useState(false);

  const handleAppointmentOpen = async () => {
    handleOpen();
    setLoading(true);
    try {
      let response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
        responseType: "blob", // Important for handling different file types correctly
      });

      const fileType = props.docfile.split(".").pop().toLowerCase();

      if (
        fileType === "txt" ||
        fileType === "csv" ||
        fileType === "json" ||
        fileType === "xlsx"
      ) {
        const textData = await response.data.text();
        setRegdStudents({ type: fileType, content: textData });
        setModalStyle(
          fileType === "csv" || fileType === "json" || fileType === "xlsx"
            ? smallModalStyle
            : defaultStyle
        );
      } else if (
        fileType === "png" ||
        fileType === "jpeg" ||
        fileType === "jpg" ||
        fileType === "pdf" ||
        fileType === "mp3" ||
        fileType === "mp4"
      ) {
        const fileData = URL.createObjectURL(response.data);
        setRegdStudents({ type: fileType, content: fileData });
        setModalStyle(fileType === "mp3" ? mp3ModalStyle : defaultStyle);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const handleDownload = () => {
  //   if (!regdStudents) return;

  //   const { type, content } = regdStudents;
  //   let data = null;
  //   let fileName = props.docfile.split(".")[0];

  //   if (type === "txt" || type === "csv") {
  //     data = new Blob([content], { type: "text/csv;charset=utf-8;" });
  //     fileName += `.${type}`;
  //   } else if (type === "json") {
  //     data = new Blob([content], { type: "application/json;charset=utf-8;" });
  //     fileName += ".json";
  //   } else if (type === "xlsx") {
  //     const downloadFileUrl = `http://106.51.74.69:7979/api/v1/get_doc?name=${props.docfile}`;
  //     console.log("downloadFileUrl: " , downloadFileUrl);
  //     const link = document.createElement("a");
  //     link.href = downloadFileUrl;
  //     link.setAttribute("download", props.docfile); // Ensure the filename is set correctly here
  //     console.log("aaaaaaaaaa",props.docfile)
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     return; // exit early since we've handled the xlsx download
  //   }

  //   if (data) {
  //     const url = URL.createObjectURL(data);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };

  // =====================

  // const handleDownload = async () => {
  //   if (!regdStudents) return;
  
  //   const { type, content } = regdStudents;
  //   let data = null;
  //   let fileName = props.docfile.split(".")[0];
  
  //   // Trim the first 16 characters from the file name
  //   fileName = fileName.length > 16 ? fileName.substring(16) : fileName;
  
  //   if (type === "txt" || type === "csv") {
  //     data = new Blob([content], { type: "text/csv;charset=utf-8;" });
  //     fileName += `.${type}`;
  //   } else if (type === "json") {
  //     data = new Blob([content], { type: "application/json;charset=utf-8;" });
  //     fileName += ".json";
  //   } else if (type === "xlsx") {
  //     try {
  //       const response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
  //         responseType: "blob"
  //       });
  //       data = response.data;
  //       fileName += ".xlsx";
  //     } catch (error) {
  //       console.error("Error fetching the XLSX file:", error);
  //       return;
  //     }
  //   }
  
  //   if (data) {
  //     const url = URL.createObjectURL(data);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `${fileName}.${type}`); // Ensure the filename is set correctly here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };
  
const handleDownload = async () => {
  if (!regdStudents) return;

  const { type, content } = regdStudents;
  let data = null;
  let fileName = props.docfile.split(".")[0];

  // Trim the first 16 characters from the file name
  fileName = fileName.length > 16 ? fileName.substring(16) : fileName;

  if (type === "txt" || type === "csv") {
    data = new Blob([content], { type: "text/csv;charset=utf-8;" });
    fileName += `.${type}`;
  } else if (type === "json") {
    data = new Blob([content], { type: "application/json;charset=utf-8;" });
    fileName += ".json";
  } else if (type === "xlsx") {
    try {
      const response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
        responseType: "blob"
      });
      data = response.data;
      fileName += ".xlsx";
    } catch (error) {
      console.error("Error fetching the XLSX file:", error);
      return;
    }
  }

  if (data) {
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); // Ensure the filename is set correctly here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

  // const handleDownload = async () => {
  //   if (!regdStudents) return;
  
  //   const { type, content } = regdStudents;
  //   let data = null;
  //   let fileName = props.docfile.split(".")[0];
  
  //   if (type === "txt" || type === "csv") {
  //     data = new Blob([content], { type: "text/csv;charset=utf-8;" });
  //     fileName += `.${type}`;
  //   } else if (type === "json") {
  //     data = new Blob([content], { type: "application/json;charset=utf-8;" });
  //     fileName += ".json";
  //   } else if (type === "xlsx") {
  //     try {
  //       const response = await AxiosInstance.get(`get_doc?name=${props.docfile}`, {
  //         responseType: "blob"
  //       });
  //       data = response.data;
  //       fileName += ".xlsx";
  //     } catch (error) {
  //       console.error("Error fetching the XLSX file:", error);
  //       return;
  //     }
  //   }
  
  //   if (data) {
  //     const url = URL.createObjectURL(data);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", props.docfile); // Ensure the filename is set correctly here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };
  

  const renderDocument = () => {
    if (!regdStudents) return null;

    const { type, content } = regdStudents;

    switch (type) {
      case "png":
      case "jpeg":
      case "jpg":
        return (
          <img
            src={content}
            alt="Document Preview"
            style={{ width: "100%", height: "auto" }}
          />
        );
      case "txt":
      case "json":
        return (
          <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {content}
          </pre>
        );
      case "csv":
      case "xlsx":
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            style={{ padding: "10px", margin: "20px", marginLeft: "25%" }}
          >
            Download {type.toUpperCase()}
          </Button>
        );
      case "pdf":
        return (
          <iframe
            src={content}
            style={{ width: "100%", height: "100%" }}
            frameBorder="0"
          />
        );
      case "mp3":
        return (
          <audio
            controls
            src={content}
            style={{ width: "100%", marginTop: "3%" }}
          />
        );
      case "mp4":
        return (
          <video
            controls
            src={content}
            style={{ width: "100%", height: "auto", marginTop: "2%" }}
          />
        );
      default:
        return <Typography variant="body2">Unsupported file type</Typography>;
    }
  };

  return (
    <div>
      <Tooltip title="Doc Type Details">
        <p
          onClick={handleAppointmentOpen}
          style={{
            cursor: "pointer",
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Card
            style={{
              width: "100%",
              height: "85%",
              overflowY: "auto",
            }}
          >
            <CardContent style={{ height: "100%", padding: 0 }}>
              {renderDocument()}
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </div>
  );
}
