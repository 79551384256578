import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Button, Grid, Paper, Autocomplete, Chip } from "@mui/material";
import AxiosInstance from "../../../apis/AxiosInstance";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

const EditUnit = () => {
  let { state } = useLocation();

  let Navigate = useNavigate();
  const [unitName, setUnitName] = useState(
    state.name === null || state.name === "" ? "" : state.name
  );
  const [qualificationOption, setQualificationOption] = useState([]);
  const [qualificationData, setQualificationData] = useState([]);
  const [removeQualification, setRemoveQualification] = useState([]);
  const [testQualification, setTestQualification] = useState([]);

  const [searchQualification, setSearchQualification] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);
  const [selectedDegree, setselectedDegree] = useState([]);
  const [testDegree, setTestDegree] = useState([]);
  const [removeDegree, setRemoveDegree] = useState([]);

  const [enquiryBranchOption, setEnquiryBranchOption] = useState([]);
  const [selectedEnquiryBranchOption, setSelectedEnquiryBranchOption] =
    useState([]);
  const [inputValue2, setInputValue2] = useState([]);
  const [defaultBranchList, setDefaultBranchList] = useState([]);
  const [testEnquiryBranch, setTestEnquiryBranch] = useState([]);
  const [removeTestEnquiryBranch, setRemoveTestEnquiryBranch] = useState([]);

  const [degreeOptions, setDegreeOptions] = useState([]);

  useEffect(() => {
    // const keys = Object.keys(state.qenquiry_branches).map(e =>
    //   Number.parseInt(e)
    // );
    const keys = state.qenquiry_branches && Object.keys(state.qenquiry_branches).length > 0
    ? Object.keys(state.qenquiry_branches).map(e => Number.parseInt(e))
    : [];
    let fetchData = async () => {
      let response = await AxiosInstance.get(`/get_enquiry_branch?limit=10`);
      let finalData = response.data.data;
      setEnquiryBranchOption(finalData);
      setDefaultBranchList(finalData);
      // let filterQualification = finalData.filter(item =>
      //   keys.includes(item.ID)
      // );
      let filterQualification = keys.length > 0
      ? finalData.filter(item => keys.includes(item.ID))
      : [];
      setSelectedEnquiryBranchOption(filterQualification);
      setTestEnquiryBranch(filterQualification);
    };
    fetchData();
  }, []);

  const fetchOptions2 = async searchTerm_1 => {
    try {
      // const keys = Object.keys(state.qenquiry_branches).map(e =>
      //   Number.parseInt(e)
      // );
      const keys = state.qenquiry_branches && Object.keys(state.qenquiry_branches).length > 0
      ? Object.keys(state.qenquiry_branches).map(e => Number.parseInt(e))
      : [];
      const response = await AxiosInstance.get(
        `/get_enquiry_branch?search_keyword=${searchTerm_1}&limit=10`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setEnquiryBranchOption(data);
      } else {
        setEnquiryBranchOption([]);
      }
      // let filterQualification = data.filter(item => keys.includes(item.ID));
      let filterQualification = keys.length > 0
      ? data.filter(item => keys.includes(item.ID))
      : [];
      setSelectedEnquiryBranchOption(filterQualification);
      setTestEnquiryBranch(filterQualification);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange2 = (event, newInputValue) => {
    setInputValue2(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions2(newInputValue);
    } else {
      setEnquiryBranchOption(defaultBranchList);
    }
  };

  // const handleOptionSelect2 = (_, newValue, action) => {
  //   setSelectedEnquiryBranchOption(newValue);
  // };

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      const keys = Object.keys(state.degree.qualification_type).map(e =>
        Number.parseInt(e)
      );

      if (searchQualification !== "") {
        let data = await AxiosInstance.get(
          `/qualification?search_keyword=${searchQualification}`
        );
        let finalData = data.data.data;
        setQualificationOption(finalData);

        let filterQualification = finalData.filter(item =>
          keys.includes(item.id)
        );
        setQualificationData(filterQualification);
        setTestQualification(filterQualification);
      } else {
        let data = await AxiosInstance.get(`/qualification`);
        let finalData = data.data.data;
        setQualificationOption(finalData);

        let filterQualification = finalData.filter(item =>
          keys.includes(item.id)
        );
        setQualificationData(filterQualification);
        setTestQualification(filterQualification);
      }
    };
    fetchData();
  }, [searchQualification]);

  useEffect(() => {
    if (qualificationData.length > 0) {
      fetchData();
    }
  }, [qualificationData]);

  const fetchData = async () => {
    const keys = Object.keys(state.degree.degree).map(e => Number.parseInt(e));

    let queryParams = qualificationData
      .map(value => `qualification_type_ids=${value.id}`)
      .join("&");

    try {
      const response = await AxiosInstance.get(
        `/degree_types_mini?${queryParams}`
      );
      const data = response.data.data;

      setDegreeOptions(data);
      setDefaultCourseList(data);
      let filterDegree = data.filter(item => keys.includes(item.id));
      setselectedDegree(
        state?.degree?.degree && Object.keys(state.degree.degree)
          ? Object.entries(state.degree.degree).map(e => ({
              id: Number(e[0]),
              name: e[1].name,
            }))
          : []
      );
      setTestDegree(filterDegree);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOptions1 = async searchDegree => {
    const keys = Object.keys(state.degree.degree).map(e => Number.parseInt(e));

    let queryParams = qualificationData
      .map(value => `qualification_type_ids=${value.id}`)
      .join("&");

    try {
      const response = await AxiosInstance.get(
        `/degree_types_mini?search_keyword=${searchDegree}&${queryParams}&limit=10`
      );
      const data = response.data.data;
      setDegreeOptions(data);
      let filterDegree = data.filter(item => keys.includes(item.id));
      setTestDegree(filterDegree);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setDegreeOptions(defaultCourseList);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    let formData = new FormData();

    formData.append("id", state.ID);

    const qualificationToAdd = qualificationData.filter(
      obj2 => !testQualification.some(obj1 => obj1.id === obj2.id)
    );

    if (qualificationToAdd.length > 0) {
      qualificationToAdd.forEach(val => {
        formData.append("add_qualification", val.id);
      });
    }

    if (removeQualification.length > 0) {
      removeQualification.forEach(value => {
        formData.append("remove_qualification", value.id);
      });
    }

    const degreeToAdd = selectedDegree.filter(
      obj2 => !testDegree.some(obj1 => obj1.id === obj2.id)
    );

    if (degreeToAdd.length > 0) {
      degreeToAdd.forEach(val => {
        formData.append("add_degree", val.id);
      });
    }

    if (removeDegree.length > 0) {
      removeDegree.forEach(value => {
        formData.append("remove_degree", value.id);
      });
    }

    const enaquiryBranchToAdd = selectedEnquiryBranchOption.filter(
      obj2 => !testEnquiryBranch.some(obj1 => obj1.ID === obj2.ID)
    );

    // Only add enquiry branches if they have been selected
    if (enaquiryBranchToAdd.length > 0) {
      enaquiryBranchToAdd.forEach(val => {
        formData.append("add_enquiry_branch", val.ID);
      });
    }

    if (removeTestEnquiryBranch.length > 0) {
      removeTestEnquiryBranch.forEach(value => {
        formData.append("remove_enquiry_branch", value.ID);
      });
    }

    try {
      await AxiosInstance.patch(`/unit`, formData, config)
        .then(data => {
          toast.success("Unit successfully updated");
          setTimeout(() => {
            Navigate("/unit_list");
          }, 1500);
        })
        .catch(error => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went wrong, please try again");
      }
    }
  };

  const ResetUnit = () => {
    setUnitName("");
    setselectedDegree([]);
    setQualificationData(null);
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "1px solid gray",
    borderRadius: "10px",
  };

  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1976D2",
    color: "#fff",
    fontWeight: "bold",
    width: "120px",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "#DF2E38",
    // border: "1px solid #FF5C35",
    color: "#fff",
    fontWeight: "bold",
    width: "120px",
  };
  const gridContainer = {
    margin: "0 auto",
    padding: "1em 0",
    gap: 20,
    marginTop: "5%",
  };

  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <h2
              style={{
                color: "#081F37",
                textTransform: "uppercase",
                borderBottom: "2px solid #9BA4B5",
              }}
            >
              Edit Unit
            </h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <TextField
                    // required
                    disabled
                    fullWidth
                    name="unitName"
                    label="Unit Name"
                    type="text"
                    id="unitName"
                    value={unitName}
                    onChange={e => {
                      setUnitName(e.target.value);
                    }}
                    InputLabelProps={{
                      style: { fontSize: 16 },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <Item
                    onChange={e => {
                      setSearchQualification(e.target.value);
                    }}
                  >
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      value={qualificationData}
                      inputValue={inputValue1}
                      onChange={(event, newValue) => {
                        // setQualificationData([...newValue]);
                        const removedQualification = qualificationData.filter(
                          qualificationData =>
                            !newValue.some(
                              selected => selected.id === qualificationData.id
                            )
                        );
                        setRemoveQualification([
                          ...removeQualification,
                          ...removedQualification,
                        ]);
                        setQualificationData(newValue);
                      }}
                      options={qualificationOption ? qualificationOption : []}
                      getOptionLabel={option => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            // key={index}
                            key={option.name + (index + 1)}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Search Qualification type"
                          color="warning"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          required={qualificationData.length === 0}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <Item>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      value={selectedDegree}
                      onChange={(event, newValue) => {
                        const removedDegree = selectedDegree.filter(
                          selectedDegree =>
                            !newValue.some(
                              selected => selected.id === selectedDegree.id
                            )
                        );
                        setRemoveDegree([...removeDegree, ...removedDegree]);
                        setselectedDegree(newValue);
                      }}
                      onInputChange={handleInputChange1}
                      options={degreeOptions}
                      getOptionLabel={option => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={option.name + (index + 1)}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Search Degree"
                          color="warning"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          required={selectedDegree.length === 0}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Item>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.ID === value.ID
                    }
                    multiple
                    required
                    value={selectedEnquiryBranchOption}
                    // onChange={handleOptionSelect2}
                    onChange={(event, newValue) => {
                      const removedEnqBranch =
                        selectedEnquiryBranchOption.filter(
                          selectedEnquiryBranchOption =>
                            !newValue.some(
                              selected =>
                                selected.ID === selectedEnquiryBranchOption.ID
                            )
                        );
                      setRemoveTestEnquiryBranch([
                        ...removeTestEnquiryBranch,
                        ...removedEnqBranch,
                      ]);
                      setSelectedEnquiryBranchOption(newValue);
                    }}
                    onInputChange={handleInputChange2}
                    options={enquiryBranchOption}
                    getOptionLabel={option => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Enquiry Branch"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleCancelClick}
                  style={btnstyle1}
                >
                  Cancel
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  type="submit"
                  // color="primary"
                  variant="contained"
                  style={btnstyle}
                  color="warning"
                  disabled={disableSubmit}
                >
                  {disableSubmit ? "Updating unit" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </section>
  );
};

export default EditUnit;
