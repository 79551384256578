import * as React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { useState } from "react";
import { useEffect } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Modal, Button } from "@mui/material";
import { PostContext } from "../bdTree/ContextApi";
import AxiosInstance from "../../apis/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import Styles from "../spiltingBdTree/tree.module.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}></Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "#fff",
    minWidth: "maxContent",
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    // backgroundColor: "#F6CF9B",
    fill: "#58D68D",
    color: "#D2B",
  },
}));
function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 9l6 6 6-6" />
      </svg>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      tslint:disable-next-line: max-line-length
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </SvgIcon>
  );
}
function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = styled(TreeItem)(({ rootNode }) => {
  const borderColor = "gray";

  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 28.5,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #081F37` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #081F37`,
    },
  };
});
const StyledTreeItem1 = styled(TreeItem)(({ rootNode }) => {
  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 50,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #27374D` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #27374D`,
    },
  };
});

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
  p: 1,
};

let TreeOrganaisation = () => {
  const {
    expanded,
    setExpanded,
    organaisation,
    setOrganaisation,
    organaisationOpen,
    appointments,
    setAppointments,
    appointmentOpen,
    setAppointmentOpen,
    setOrganaisationOpen,
    appointmentURL,
    setAppointmentURL,
    organaisationURL,
    setOrganaisationURL,
    appointmentDataFilter,
    setAppointmentDataFilter,
    orgBranch,
    setOrgBranch,
    orgBranchOpen,
    setOrgBranchOpen,
    branchURL,
    setBranchURL,
  } = React.useContext(PostContext);
  const compareObjects = (obj1, obj2) => {
    return obj1.id === obj2.id && obj1.name === obj2.name;
  };
  // //! image Preview
  let [openModel, setOpenModel] = useState(false);
  let [state_ID, setState_ID] = useState(0);

  let [imageAddress, setImageAddress] = useState("");

  let [state_page, setState_page] = useState(1);

  //state
  let updateState_ID = new_ID => {
    setState_ID(new_ID);
  };

  const handleImageModelClose = () => {
    setOpenModel(false);
    setImageAddress("");
  };

  // =====================trail incubation=========================

  let navigate = useNavigate();

  // BD user token
  const parseLog = a => {
    const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
    const { id } = userData.role[0];
    return id.toString();
  };
  const role = parseLog(Cookies.get("logged"));

  useEffect(() => {
    let fetchCountry = async () => {
      try {
        let response = await AxiosInstance.get(`${appointmentURL}`);
        let result = response.data.data;
        setAppointments(result);
      } catch (err) {
        setAppointments(null);
      }
    };
    fetchCountry();
  }, [appointmentURL]);

  let fetchStates = async () => {
    try {
      let { data } = await AxiosInstance.get(
        `${branchURL}&id=${organaisation.id}&page=${state_page}`
      );
      let result = data.data;
      orgBranch.length < 1
        ? setOrgBranch(result)
        : setOrgBranch(orgBranch.concat(result));
    } catch (err) {
      console.log(err);
    }
  };
  const [loadMoreState, setLoadMoreState] = useState(false);
  const [loadMoreDistrict, setLoadMoreDistrict] = useState(false);

  let fetchMoreStates = async () => {
    setLoadMoreState(true);
    try {
      let { data } = await AxiosInstance.get(
        `${branchURL}&id=${organaisation.id}&page=${state_page + 1}`
      );
      let result = data.data;
      setOrgBranch([...orgBranch, ...result]);
      setLoadMoreState(false);
      setState_page(state_page + 1);
    } catch (err) {
      toast.error(err?.response?.data?.error || "Unable to load state");
      console.log(err);
      setLoadMoreState(false);
    }
  };

  return (
    <div style={{ marginTop: "5%" }} id="mastreTree">
      {appointments.length > 0 ? (
        <section id="tree2">
          <Modal open={openModel} onClose={handleImageModelClose}>
            <Box sx={style} style={{ position: "relative" }}>
              <Button
                onClick={handleImageModelClose}
                variant="contained"
                color="warning"
                style={{
                  position: "absolute",
                  right: "1%",
                }}
              >
                close
              </Button>
              {imageAddress === "" ? <></> : <></>}
            </Box>
          </Modal>
          <TreeView
            aria-label="customized"
            className="treeCont"
            defaultCollapseIcon={<MinusSquare className={Styles.minusSquare} />}
            defaultExpandIcon={<PlusSquare className={Styles.plusSquare} />}
            defaultEndIcon={<CloseSquare className={Styles.closeSquare} />}
            sx={{
              flexGrow: 1,
            }}
          >
            {appointments.length > 0
              ? appointments.map((sub, subin) => {
                  return (
                    <TreeView
                      className="treeCont"
                      defaultCollapseIcon={
                        <MinusSquare className={Styles.minusSquare} />
                      }
                      defaultExpandIcon={
                        <PlusSquare className={Styles.plusSquare} />
                      }
                      defaultEndIcon={
                        <CloseSquare className={Styles.closeSquare} />
                      }
                      onNodeToggle={(e, x) => {
                        setExpanded(x);
                      }}
                    >
                      <StyledTreeItem
                        style={{ paddingLeft: "20px" }}
                        className="subjectBranch"
                        nodeId={`####country-${sub.id}`}
                        onClick={() => {
                          if (
                            expanded.length === 0 ||
                            !expanded.includes(`####country-${sub.id}`)
                          ) {
                            fetchStates();
                          }
                        }}
                        key={sub.id}
                        label={
                          <section
                            id="subjectBranch"
                            style={{ padding: "18.7px" }}
                          >
                            <span
                              style={{
                                textTransform: "uppercase",
                                color: "#081F37",
                                fontWeight: "700",
                                marginTop: "50px",
                                fontFamily: " 'Poppins', sans-serif",
                              }}
                            >
                              {sub.name}
                            </span>
                            &nbsp;&nbsp;
                            <span>
                              <button
                                style={{
                                  borderRadius: "40%",
                                  background: "#CBFFA9",
                                  width: "auto",
                                  border: "none",
                                  cursor: "pointer",
                                  padding: "0px 4px",
                                }}
                              >
                                {/* {sub.appointment_count === null
                                  ? 0
                                  : sub.appointment_count} */}
                              </button>
                            </span>
                          </section>
                        }
                      >
                        {orgBranch.length > 0
                          ? orgBranch
                              .filter(val => val.organization_id == sub.id)
                              .map((val, valin) => {
                                return (
                                  <>
                                    <StyledTreeItem
                                      style={{ paddingLeft: "20px" }}
                                      onClick={() => {
                                        updateState_ID(val.id);
                                      }}
                                      className="subjectBranch"
                                      nodeId={`state-${val.id}~country-${sub.id}`}
                                      key={val.id}
                                      label={
                                        <section
                                          id="subjectBranch"
                                          style={{
                                            padding: "16.1px",
                                            borderBottom:
                                              "2px solid transparent",
                                          }}
                                        >
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                              color: "#081F37",
                                              fontWeight: "bold",
                                              width: "15vw",
                                              fontFamily:
                                                " 'Poppins', sans-serif",
                                            }}
                                          >
                                            &nbsp; {val.name}
                                          </span>
                                          &nbsp;&nbsp;
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <button
                                              style={{
                                                borderRadius: "40%",
                                                background: "#CBFFA9",
                                                width: "auto",
                                                border: "none",
                                                cursor: "pointer",
                                                padding: "0px 4px",
                                              }}
                                            >
                                              {/* {val.appointment_count === null
                                                ? 0
                                                : val.appointment_count} */}
                                            </button>
                                          </span>
                                          <span
                                            style={{ position: "relative" }}
                                          >
                                            <small
                                              style={{
                                                position: "absolute",
                                                left: "-10px",
                                              }}
                                            ></small>
                                          </span>
                                        </section>
                                      }
                                    ></StyledTreeItem>
                                  </>
                                );
                              })
                          : "Loading...."}
                        {/* {appointmentDataFilter === false ? (
                          states.length > 0 ? (
                            <Button
                              onClick={() => {
                                fetchMoreStates();
                              }}
                              variant="text"
                              size="small"
                              style={{
                                display:
                                  sub.states_count ===
                                  states.filter((e) => e.organization_id === sub.id)
                                    .length
                                    ? "none"
                                    : "block",
                                fontSize: "10px",
                                marginTop: "-23px",
                                backgroundColor: loadMoreState
                                  ? "grey"
                                  : "#2F58CD",
                                padding: "2.5px 10px",
                                variant: "text",
                                color: "#fff",
                                fontWeight: "500",
                                borderRadius: "20px",
                              }}
                              disabled={loadMoreState}
                            >
                              {loadMoreState
                                ? "Loading..."
                                : "Load more states"}
                            </Button>
                          ) : undefined
                        ) : null} */}
                      </StyledTreeItem>
                    </TreeView>
                  );
                })
              : "Loading...."}
            {/* {appointmentStateFilter === false ? (
                          appointments.length > 0 ? (
                            <Button
                              onClick={() => {
                                fetchMoreStates();
                              }}
                              variant="text"
                              size="small"
                              style={{
                                display:
                                  sub.states_count ===
                                  states.filter((e) => e.organization_id === sub.id)
                                    .length
                                    ? "none"
                                    : "block",
                                fontSize: "10px",
                                marginTop: "-23px",
                                backgroundColor: loadMoreState
                                  ? "grey"
                                  : "#2F58CD",
                                padding: "2.5px 10px",
                                variant: "text",
                                color: "#fff",
                                fontWeight: "500",
                                borderRadius: "20px",
                              }}
                              disabled={loadMoreState}
                            >
                              {loadMoreState
                                ? "Loading..."
                                : "Load more states"}
                            </Button>
                          ) : undefined
                        ) : null} */}
          </TreeView>
        </section>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default TreeOrganaisation;
