import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  TextField,
  InputLabel,
  IconButton,
  FormControl,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../apis/AxiosInstance";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditCCAppointment() {
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [selectedUniversity, setSelectedUniversity] = useState(
    state?.univeristy?.name
  );
  const [selectedCollege, setSelectedCollege] = useState(state?.college?.name);
  const [expectedStudents, setExpectedStudents] = useState("");
  const [comment, setComment] = useState(state.comment);
  const [initialComment, setInitialComment] = useState(state.comment);
  const [isCommentChanged, setIsCommentChanged] = useState(false);
  const [selectedColgState, setSelectedColgState] = useState(
    state?.state?.name
  );
  const [selectedColgDistrict, setSelectedColgDistrict] = useState(
    state?.district?.name
  );
  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(state.unit);
  const [initialUnitNameData, setInitialUnitNameData] = useState(state.unit);
  const [searchUnitName, setSearchUnitName] = useState("");

  const [colgSpocOptions, setColgSpocOptions] = useState([]);
  const [selectedSpoc, setSelectedSpoc] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [doc_type_Options, setDoc_type_Options] = useState([]);
  const [searchDocType, setSearchDocType] = useState("");

  const [yop, setYop] = useState(state.yop);
  const [yopOptions, setYopOptions] = useState([]);
  const [initialYop, setInitialYop] = useState(state.yop);
  const [isYopChanged, setIsYopChanged] = useState(false);
  const currentYear = new Date().getFullYear();

  const [testUnitData, setTestUnitData] = useState([]);

  const initialFields = Array.isArray(state.file)
    ? state.file.flatMap((file) => {
        const docTypes = Object.values(file.doc_type);
        return docTypes.map((docType) => ({
          id: file.id, // Use file id as the unique identifier
          docTypeData: docType,
          selectedVideo: file.file_name ? { name: file.file_name } : null,
          status: file.status,
          disabled: true,
        }));
      })
    : [];

  // If state.file is empty, initialize fields with default values
  if (
    Object.keys(state.file).length === 0 &&
    state.file.constructor === Object
  ) {
    initialFields.push({
      docTypeData: null,
      selectedVideo: null,
      status: "",
      disabled: false,
    });
  }

  // const [fields, setFields] = useState(initialFields);

  const [fields, setFields] = useState(initialFields);
  const [initialFieldsState] = useState(initialFields);

  const handleAddFields = () => {
    // if (fields.length < 5) {
    //   setFields([
    //     ...fields,
    //     {
    //       id: Date.now(),
    //       docTypeData: null,
    //       selectedVideo: null,
    //       disabled: false,
    //     },
    //   ]);
    // } else {
    //   alert("You can only add up to 5 fields.");
    // }
    setFields([
      ...fields,
      {
        id: Date.now(),
        docTypeData: null,
        selectedVideo: null,
        status: "",
        disabled: false,
      },
    ]);
  };

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  const handleRemoveFields = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  // const handleInputChange = (id, event) => {
  //   const values = fields.map((field) => {
  //     if (field.id === id && event.target.name === "selectedVideo") {
  //       return { ...field, selectedVideo: event.target.files[0] };
  //     }
  //     return field;
  //   });
  //   setFields(values);
  // };

  const handleInputChange = (id, event) => {
    const { name, value, files } = event.target;
    const values = fields.map((field) => {
      if (field.id === id) {
        if (name === "selectedVideo") {
          return { ...field, selectedVideo: files[0] };
        } else if (name === "status") {
          return { ...field, status: value };
        }
      }
      return field;
    });
    setFields(values);
  };

  const handleAutocompleteChange = (id, event, newValue) => {
    const values = fields.map((field) => {
      if (field.id === id) {
        return { ...field, docTypeData: newValue };
      }
      return field;
    });
    setFields(values);
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData();

    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };

    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      const newSpocData = selectedSpoc.map((e) => e.id.toString());
      const initialSpocData = state.college_spoc
        ? Object.keys(state.college_spoc)
        : [];

      const addedSpocs = newSpocData.filter(
        (id) => !initialSpocData.includes(id)
      );
      const removedSpocs = initialSpocData.filter(
        (id) => !newSpocData.includes(id)
      );

      addedSpocs.forEach((id) => {
        formData.append("add_spoc_id", id);
      });

      removedSpocs.forEach((id) => {
        formData.append("remove_spoc_id", Number.parseInt(id));
      });

      formData.append("id", state.id);
      

      if (unitNameData && unitNameData.ID !== initialUnitNameData?.ID) {
        formData.append("unit_id", unitNameData.ID);
      }
      // if (yop && yop !== initialUnitNameData) {
      //   formData.append("yop", yop);
      // }

      // if (!yop == initialYop) {
      //   formData.append("yop", yop);
      // }

      if (isYopChanged) {
        formData.append("yop", yop);
      }
      if (isCommentChanged) {
        formData.append("comment", comment);
      }

      fields.forEach((field, index) => {
        const initialField = initialFieldsState[index];

        if (
          field.selectedVideo &&
          (!initialField || field.selectedVideo !== initialField.selectedVideo)
        ) {
          // Append the selected video file as binary data
          formData.append(
            "file",
            field.selectedVideo,
            field.selectedVideo.name
          ); // Use field.selectedVideo and its name
        }

        if (
          field.docTypeData &&
          field.docTypeData.ID && // Add a null check here
          (!initialField ||
            field.docTypeData.ID !== initialField.docTypeData?.ID)
        ) {
          // Append the docTypeData as JSON string
          formData.append("doc_type", JSON.stringify(field.docTypeData.ID));
        }

        if (
          field.status &&
          (!initialField || field.status !== initialField.status)
        ) {
          formData.append("status", field.status);
        }
      });

      await AxiosInstance.patch("/cc_appointment", formData, config)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Appointment Updated Successfully");
            setTimeout(() => {
              Navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const keys = Object.keys(state.college_spoc);
      try {
        if (keys.length > 0) {
          const addFacultyData = await AxiosInstance.get(
            `/college_spoc?id=${keys.toString().split(",").join("&id=")}`
          );
          let finalAddFaculty = addFacultyData.data.data
            ? addFacultyData.data.data
            : [];
          setSelectedSpoc(finalAddFaculty);
          setTestUnitData(finalAddFaculty);
        }
      } catch (error) {
        console.log(error);
        if (keys.length > 0) {
          setSelectedSpoc([]);
          setTestUnitData([]);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  useEffect(() => {
    let fetchData = async () => {
      if (searchDocType !== "") {
        let data = await AxiosInstance.get(
          `/doc_type?search_keyword=${searchDocType}&limit=10`
        );
        let finalData = data.data.data;

        setDoc_type_Options(finalData);
      } else {
        let data = await AxiosInstance.get(`/doc_type?limit=10`);
        let finalData = data.data.data;

        setDoc_type_Options(finalData);
      }
    };
    fetchData();
  }, [searchDocType]);

  useEffect(() => {
    let fetchData = async () => {
      if (selectedCollege === null) {
        return null;
      } else {
        let collegeSpocData1 = await AxiosInstance.get(
          `/college_spoc?college_id=${state?.college?.id}&university_id=${state?.univeristy?.id}`
        );
        let finalColgSpocData = collegeSpocData1.data.data;
        setColgSpocOptions(finalColgSpocData);
      }
    };
    fetchData();
  }, [selectedCollege, selectedUniversity]);

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "95%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4.3%",
          background: "#efefef",
        }}
      >
        <Box>
          <Paper variant="outlined">
          <Box
              sx={{
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "2px solid #9BA4B5",
                paddingLeft: "500px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#081f37",
                  fontSize: "30px",
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                }}
              >
                EDIT APPOINTMENT FORM
              </Box>
            
            </Box>
            <form
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "1%",
              }}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={1} item={12}>
                {/* colleg state */}
                <Grid item md={3}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      College State
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      value={selectedColgState}
                      name="state_name"
                    />
                  </Item>
                </Grid>

                {/* colleg district */}
                <Grid item md={3}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      College District
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      value={selectedColgDistrict}
                    />
                  </Item>
                </Grid>

                {/* university  */}
                <Grid item md={3}>
                  <Item>
                    <InputLabel
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      University
                    </InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled
                      value={selectedUniversity}
                      name="university_name"
                    />
                  </Item>
                </Grid>

                {/* College   */}
                <Grid item md={3}>
                  <Item>
                    <InputLabel
                      htmlFor="my-input"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#495579",
                      }}
                    >
                      College
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      variant="outlined"
                      value={selectedCollege}
                    />
                  </Item>
                </Grid>

                {/* SPOC  */}
                <Grid item md={3}>
                  <Item>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      options={colgSpocOptions ? colgSpocOptions : []}
                      value={selectedSpoc}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <li {...props} style={{ width: "100%" }}>
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={selectedSpoc.length === 0}
                          variant="outlined"
                          label="Select Spoc Name"
                          placeholder="Select Spoc Name "
                        />
                      )}
                      onChange={(e, value) => {
                        setSelectedSpoc(value);
                      }}
                    />
                  </Item>
                </Grid>

                <Grid item md={3}>
                  <Item
                    onChange={(e) => {
                      setSearchUnitName(e.target.value);
                    }}
                  >
                    <Autocomplete
                      options={unitNameOption}
                      value={unitNameData}
                      name="unitNameOption"
                      onChange={(event, newData_xy) => {
                        setUnitNameData(newData_xy);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Unit Name"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Item>
                </Grid>
                <Grid item md={3}>
                  <Item>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <Autocomplete
                        required
                        options={yopOptions}
                        value={yop}
                        name="year_of_passout"
                        onChange={(e, newyop) => {
                          setYop(newyop);
                          setIsYopChanged(newyop !== initialYop);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select YOP"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Item>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  item
                  xs={12}
                  style={{ marginTop: "0.3%", marginLeft: "3%" }}
                >
                  {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                      <Paper
                        elevation={3}
                        style={{
                          padding: "16px",
                          marginBottom: "16px",
                          width: "85%",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid
                            item
                            md={3.5}
                            style={{ marginLeft: "8px" }}
                            onChange={(e) => setSearchDocType(e.target.value)}
                          >
                            <Autocomplete
                              disabled={field.disabled}
                              options={doc_type_Options}
                              value={field.docTypeData}
                              name="doc_type_Options"
                              onChange={(event, newValue) =>
                                handleAutocompleteChange(
                                  field.id,
                                  event,
                                  newValue
                                )
                              }
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Doc Type"
                                  variant="outlined"
                                  // required
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={5} style={{ marginLeft: "17px" }}>
                            {/* <TextField
                              type="file"
                              name="selectedVideo"
                              style={{ width: "419px" }}
                              // required={field.docTypeData}
                              onChange={(e) => handleInputChange(field.id, e)}
                              inputProps={{
                                accept: ".pdf,.doc,.docx,.png,.jpeg,.jpg,.csv",
                              }}
                              disabled={field.disabled}
                            /> */}
                            <Grid item md={12} style={{ marginLeft: "17px" }}>
                              <TextField
                                type="file"
                                fullWidth
                                name="selectedVideo"
                                // required={Boolean(field.docTypeData)}
                                onChange={(e) => handleInputChange(field.id, e)}
                                inputProps={{
                                  accept: "*/*",
                                }}
                                disabled={field.disabled}
                              />
                            </Grid>
                            {field.selectedVideo && (
                              <Typography
                                variant="body2"
                                style={{ width: "419px", marginLeft: "20px" }}
                              >
                                {field.selectedVideo.name.substring(16)}{" "}
                                {/* Display the file name */}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={3} style={{ marginLeft: "17px" }}>
                            <TextField
                              fullWidth
                              id="status"
                              label="Status"
                              name="status"
                              value={field.status}
                              onChange={(e) => handleInputChange(field.id, e)}
                              variant="outlined"
                              type="text"
                              disabled={field.disabled}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      {/* <Grid
                        container
                        justifyContent="flex-end"
                        spacing={1}
                        style={{ width: "72%", marginTop: "-2%" }}
                      >
                        {index === fields.length - 1 && fields.length < 5 && (
                          <Grid item>
                            <IconButton
                              onClick={handleAddFields}
                              sx={{ color: "green" }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {fields.length > 1 && (
                          <Grid item>
                            <IconButton                          
                              onClick={() => handleRemoveFields(field.id)}
                              sx={{ color: "red" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid> */}
                      <Grid
                        container
                        justifyContent="flex-end"
                        spacing={1}
                        style={{ width: "86%", marginTop: "-2%" }}
                      >
                        {index === fields.length - 1 && (
                          <Grid item>
                            <IconButton
                              onClick={handleAddFields}
                              sx={{ color: "green" }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        )}
                        {!field.disabled && fields.length > 1 && (
                          <Grid item>
                            <IconButton
                              onClick={() => handleRemoveFields(field.id)}
                              sx={{ color: "red" }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={6} md={12}>
                  <Item sx={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                        setIsCommentChanged(e.target.value !== initialComment);
                      }}
                    />
                  </Item>
                </Grid>
                <Grid
                  xs={12}
                  item
                  md={12}
                  style={{
                    textAlign: "right",
                    padding: "5px",
                  }}
                >
                  <Button
                    onClick={handleCancelClick}
                    variant="contained"
                    style={{
                      width: "10%",
                      marginRight: "3%",
                      backgroundColor: "#DF2E38",
                      color: "#e74646",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "10%",
                      color: "#F5F5F5",
                      backgroundColor: "#1976d2",
                      fontWeight: "bold",
                    }}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </article>
    </section>
  );
}
