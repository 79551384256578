import React, { useState, useEffect, useMemo } from "react";
import {
  Paper,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormControl } from "@material-ui/core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../../apis/AxiosInstance";
import { FaRegTrashAlt, FaPlus } from "react-icons/fa";
import moment from "moment/moment";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const EditAddressingMultiple = (props) => {
  let { state } = useLocation();

  const [send_message, SetSend_message] = useState(false);
  const [send_email, setSend_email] = useState(false);
  const [is_broadcaster, setIsBroadCaster] = useState(state.is_broadcaster);

  const handleSendBroadCast = (event) => {
    setIsBroadCaster(event.target.checked);
    if (!event.target.checked) {
      setVideoData(null);
    }
  };
  // state values
  const [addressing_date, setAddressing_date] = useState(
    moment.utc(state.addressing_datetime).local().format("yyyy-MM-DD")
  );
  const [addressing_time, setAddressing_time] = useState(
    moment.utc(state.addressing_datetime).local().format("HH:mm")
  );

  let ids = Object.keys(state.addressing_faculty);
  let faculty_names = Object.values(state.addressing_faculty).map(
    (value) => value.name
  );
  let ids1 = Object.keys(state.college_executive);
  let Executive_names = Object.values(state.college_executive).map(
    (value) => value.name
  );

  let addressing_Faculty_Options = ids.map((value, id) => {
    return { full_name: faculty_names[id], id: value };
  });

  let collegeExecutiveOptions1 = ids1.map((value, id) => {
    return { full_name: Executive_names[id], id: value };
  });

  const [addressing_faculty, setAddressing_faculty] = useState(
    Object.keys(state.addressing_faculty).length > 0
      ? addressing_Faculty_Options
      : []
  );

  const [college_executive, setCollege_executive] = useState(
    Object.keys(state.college_executive).length > 0
      ? collegeExecutiveOptions1
      : []
  );

  const [removeFaculty, setRemoveFaculty] = useState([]);
  const [removeExecutive, setRemoveExecutive] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [colgOptions, setColgOptions] = useState([]);

  const [rounds, setRounds] = useState([{ id: 1, label: "Select Round 1" }]);

  //Exam type list

  const [examTypeListOption, setExamTypeListOption] = useState([]);
  const [examTypeList, setExamTypeList] = useState(
    state.is_addressing_offline ? 1 : 2
  );

  let multiKey = [];
  if (Object.values(state.addressing_rounds.rounds).length > 0) {
    Object.values(state.addressing_rounds.rounds).forEach((e) => {
      multiKey.push(e.id);
    });
  }

  const [valuesAdd, setValuesAdd] = useState(
    Object.keys(state.addressing_rounds.rounds).length > 0 ? multiKey : [""]
  );


  const [videoData, setVideoData] = useState(null);
  const [videoOption, setVideoOption] = useState([]);
  const [videoSearch, setVideoSearch] = useState("");
  const [videoError, setVideoError] = useState(false);

  const navigate = useNavigate();

  // current college Details : State values
  //  const[currentColgID,setCurrentColgID]=useState(null)
  const [collegeData, setCollegeData] = useState([]); //old  code all appointments
  const [isNameSelected, setIsNameSelected] = useState(false);

  // all appointments
  const [selectedCollege_nameDate, setSelectedCollege_nameDate] =
    useState(null);
  //new
  const [selectedCollege_nameDate_1, setSelectedCollege_nameDate_1] =
    useState(null);
  const [selectedCollege_nameDate_2, setSelectedCollege_nameDate_2] =
    useState(null);

  const [selectedColgOptions_1, setSelectedColgOptions_1] = useState([]);
  const [selectedColgOptions_2, setSelectedColgOptions_2] = useState([]);

  // State values for API calls
  const [appRoundsOptions, setAppRoundsOptions] = useState([]);
  const [addFacultyOptions, setAddFacultyOptions] = useState([]);
  const [collegeExecutiveOptions, setCollegeExecutiveOptions] = useState([]);
  const [mergedAppointments, setMergedAppointments] = useState([]);
  const [selectedColgOptions, setSelectedColgOptions] = useState([state]);

  const [removedAppointments, setRemovedAppointments] = useState([]);

  const [searchAddressingFaculty, setSearchAddressingFaculty] = useState("");
  const [searchCollegeExecutive, setSearchCollegeExecutive] = useState("");
  // ==============================================add rounds==============================================
  const addRound = () => {
    setValuesAdd([...valuesAdd, ""]);
  };

  useEffect(() => {
    let fetchData = async (e) => {
      if (videoSearch !== "") {
        let data = await AxiosInstance.get(
          `/videos?video_type_id=1&search_keyword=${videoSearch}&limit=10`
        );
        let finalData = data.data.data;
        setVideoOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/videos?video_type_id=1&limit=10`);
        let finalData = data.data.data;
        setVideoOption(finalData);

        let id = Object.keys(state.addressing_video)[0];
        let data22 = finalData.filter((item) => item.id == id)[0];
        setVideoData(data22);
      }
    };

    fetchData();
  }, [videoSearch]);

  let [OldRounds, setOldRounds] = useState([]);
  const removeRound = (idToRemove, idx) => {
    let list = [...valuesAdd];
    setOldRounds([...OldRounds, idx]);
    list.splice(idToRemove, 1);
    setValuesAdd(list);
  };

  const [faculty_copy, setFaculty_copy] = useState([...addressing_faculty]);
  useMemo(() => {
    const filtered_faculty = faculty_copy.filter(
      (item_2) => !addressing_faculty.some((item_1) => item_1.id === item_2.id)
    );
    setRemoveFaculty(filtered_faculty.map((id) => id.id));
  }, [addressing_faculty]);

  const [executive_copy, setExecutive_copy] = useState([...college_executive]);
  useMemo(() => {
    const filtered_Executive = executive_copy.filter(
      (item_2) => !college_executive.some((item_1) => item_1.id === item_2.id)
    );
    setRemoveExecutive(filtered_Executive.map((id) => id.id));
  }, [college_executive]);

  // const [appointment_copy, setAppointment_copy] = useState([...selectedColgOptions]);
  // useMemo(() => {
  //   const filtered_Appointment = appointment_copy.filter(
  //     item_2 => !selectedColgOptions.some((item_1) => item_1.id === item_2.id)
  //   );
  //   setRemoveAppointment(filtered_Appointment.map((id) => id.id));
  // }, [selectedColgOptions]);

  const handleValueChange = (id, newValue) => {
    setValuesAdd({ ...valuesAdd, [id]: newValue });
    let newval = valuesAdd.map((item, ind) => {
      return ind === newValue ? id : item;
    });
    setValuesAdd(newval);
  };
  // ==============================================add rounds==============================================

  //!===========================================FUNCTION TO HANDLE FORM SUBMITION=========================
  let handlePatch = async (event) => {
    event.preventDefault();

    let formData = new FormData();

    // Tokens and config
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    // let addressDateTime = addressing_date + " " + addressing_time + " +0000";
    // let addFac = addressing_faculty.id;
    let addFac = [];

    try {
      const time = new Date(`${addressing_date}T${addressing_time}`);
      // const datePart = moment(time).format("YYYY-MM-DD");
      const timePart = moment(time).utc().format("HH:mm +ssss [UTC]");

      const addressDateTime = `${timePart}`;

      formData.append("id", state.addressing_id);
      addressing_faculty.map((value) => {
        formData.append("add_addressing_faculty", value.id);
      });
      if (college_executive.length > 0) {
        const totalExecutive = Object.keys(state.college_executive) || [];
        const newExecutive =
          college_executive.map((e) => e.id.toString()) || [];
        const addedIds = newExecutive.filter(
          (e) => !totalExecutive.includes(e)
        );
        addedIds.map((value) => {
          formData.append("add_college_executive", value);
        });
      }

      // if (isNameSelected) {
      //   selectedColgOptions.forEach(value => {
      //     formData.append("appointment_ids", value.id);
      //   });
      // } else {
      //   }

      if (isNameSelected) {
        const addedAppointments = selectedColgOptions.filter(
          (option) => !mergedAppointments.includes(option)
        );
        addedAppointments.forEach((value) => {
          formData.append("appointment_ids", value.id);
        });
      }

      if (addressing_date !== "") {
        if (
          moment.utc(state.addressing_datetime).local().format("yyyy-MM-DD") !==
          moment.utc(addressing_date).local().format("yyyy-MM-DD")
        ) {
          formData.append("addressing_date", addressing_date);
          formData.append("addressing_time", addressDateTime);
        }
      }
      if (addressing_time !== "") {
        if (
          moment.utc(state.addressing_datetime).local().format("HH:mm") !==
          addressing_time
        ) {
          formData.append("addressing_time", addressDateTime);
          formData.append("addressing_date", addressing_date);
        }
      }

      let addressingStatus = state.is_addressing_offline ? 1 : 2;
      if (examTypeList != addressingStatus) {
        formData.append("is_offline", examTypeList == 1 ? true : false);
      }

      if (is_broadcaster !== state.is_broadcaster) {
        formData.append("is_broadcaster", is_broadcaster);
      }

      if (is_broadcaster && videoData) {
        formData.append("video_id", videoData.id);
      }

      if (removeFaculty.length > 0) {
        removeFaculty.forEach((value, index) => {
          formData.append(`remove_addressing_faculty`, Number.parseInt(value));
        });
      }

      if (removeExecutive.length > 0) {
        removeExecutive.forEach((value, index) => {
          formData.append(`remove_college_executive`, Number.parseInt(value));
        });
      }

      // let removedApp=selectedColgOptions.filter(e=>!mergedAppointments.includes(e))
      // if(removedApp.length>0){
      //   removedApp.forEach((value, index) => {
      //     formData.append(`remove_appointment`, Number.parseInt(value));
      //   });
      // }

      const removedAppointments = mergedAppointments.filter(
        (college) => !selectedColgOptions.includes(college)
      );

      removedAppointments.forEach((value) => {
        formData.append("remove_appointment", Number.parseInt(value.id));
      });

      // formData.append("add_addressing_faculty", addressing_faculty);
      valuesAdd.forEach((e, i) => {
        formData.append(`round${i + 1}_id`, Number.parseInt(e));
      });
      OldRounds.forEach((e, i) => {
        formData.append(`remove_round${i + 1}`, Number.parseInt(e));
      });

      await AxiosInstance.patch("/addressing", formData, config)
        .then((response) => {
          toast.success("Addressing Appointment Updated Successfully ");
          setTimeout(() => {
            navigate("/");
          }, 1600);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.error);
    }
  };

  //! ================================================API CALLS=============================================================
  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/exam_types`);
      let finalExamTypeData = examTypeData.data.data;
      setExamTypeListOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      // College appointment rounds api
      let appointmentRounds = await AxiosInstance.get(
        `/college_appointment_rounds`
      );
      let finalColgRoundsData = appointmentRounds.data.data;
      setAppRoundsOptions(finalColgRoundsData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async () => {
      // Addressing Faculty api
      if (searchAddressingFaculty !== "") {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&search_keyword=${searchAddressingFaculty}&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        if (finalAddFaculty) {
          setAddFacultyOptions(finalAddFaculty);
        }
      } else {
        let addFacultyData = await AxiosInstance.get(
          `/user_mini?role_ids=11&is_not_blocked=${true}`
        );
        let finalAddFaculty = addFacultyData.data.data;
        setAddFacultyOptions(finalAddFaculty);
      }
    };
    fetchData();
  }, [searchAddressingFaculty]);

  //get Executives

  useEffect(() => {
    let fetchData = async () => {
      // College Executive api
      if (searchCollegeExecutive !== "") {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&search_keyword=${searchCollegeExecutive}&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        if (collegeExecutiveData) {
          setCollegeExecutiveOptions(collegeExecutiveData);
        }
      } else {
        let collegeExecutive = await AxiosInstance.get(
          `/user_mini?role_ids=14&is_not_blocked=${true}`
        );
        let collegeExecutiveData = collegeExecutive.data.data;
        setCollegeExecutiveOptions(collegeExecutiveData);
      }
    };
    fetchData();
  }, [searchCollegeExecutive]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (searchTerm && searchTerm.length > 2) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?search_keyword=${searchTerm}&limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        if (finalAppointments) {
          setColgOptions(finalAppointments);
        }
      } else if (searchTerm === "" || searchTerm === null) {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      } else {
        // get all  appointment
        let allAppointments = await AxiosInstance.get(
          `/appointment_mini?limit=10`
        );
        let finalAppointments = allAppointments.data.data;
        setColgOptions(finalAppointments);
      }
    };
    fetchData1();
  }, [searchTerm, selectedColgOptions]);

  // Get Merged Appointments Details
  useEffect(() => {
    let fetchData = async () => {
      let mergedDetails = await AxiosInstance.get(
        `/get_merged_appointments?appointment_id=${state.id}`
      );
      let finalMergedDetails = mergedDetails.data.data;
      setMergedAppointments(finalMergedDetails);
      setSelectedColgOptions(finalMergedDetails.filter((e) => e.id !== 0));
    };
    fetchData();
  }, []);

  //! Checkbox

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //! ================================================API CALLS=============================================================

  // to handle Date and Time
  let handleAddDate = (e) => {
    setAddressing_date(e.target.value);
  };
  let handleAddTime = (e) => {
    setAddressing_time(e.target.value);
  };

  // all appointments : //old  code all appointments
  const handleAddCollege = () => {
    setCollegeData([...collegeData, null]);
  };

  const handleRemoveCollege = (index) => {
    const newData = [...collegeData];
    newData.splice(index, 1);
    setCollegeData(newData);
  };

  // Reset Data
  let resetData = (e) => {
    e.preventDefault();
    setAddressing_date("");
    setAddressing_time("");
    setAddressing_faculty(null);
    setValuesAdd([""]);
    setSelectedCollege_nameDate(null); //multiple
    //new
    setSelectedCollege_nameDate_1(null); //date
    setSelectedCollege_nameDate_2(null); //time
    setSelectedColgOptions([]);
    //new
    setSelectedColgOptions_1([]);
    setSelectedColgOptions_2([]);
    SetSend_message(false);
    setSend_email(false);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <div>
      <section style={{ width: "100%", height: "auto" }}>
        <article
          style={{
            width: "98%",
            height: "auto",
            margin: "0 auto",
            marginTop: "4.5%",
          }}
        >
          <Box>
            <Paper variant="outlined">
              <Typography
                variant="h4"
                sx={{
                  marginBottom: 2,
                  background: "#DDE6ED",
                  color: "#081f37",
                  margin: "0 auto",
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderBottom: "2px solid #9BA4B5",
                  fontSize: "25px",
                  // borderBottom: "5px solid #FF8400",
                }}
                // className={Styles.backgrounds}
              >
                EDIT ADDRESSING FORM
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {/* <TableContainer
                    component={Paper}
                    style={{ marginTop: "1%", borderBottom: "2px solid #2222" }}
                  >
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell style={{ fontSize: "20px" }}> 
                            Merged Appoinments
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mergedAppointments.map((val, ind) => {
                          return (
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {val === null ? "N/A" : val.name}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer> */}
                  <Grid item xs={12} style={{ marginTop: "1%" }}>
                    <Item
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setIsNameSelected(false);
                      }}
                    >
                      <Autocomplete
                        multiple
                        fullWidth
                        required
                        value={selectedColgOptions}
                        onChange={(event, newValue) => {
                          setSelectedColgOptions([...newValue]);
                          setIsNameSelected(true);
                        }}
                        options={colgOptions ? colgOptions : []}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              key={index}
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Appointments"
                            color="warning"
                          />
                        )}
                      />
                    </Item>
                  </Grid>

                  <Paper elevation={1} sx={{ padding: 1 }}>
                    {/* forms */}
                    <form onSubmit={handlePatch}>
                      <Grid
                        container
                        spacing={2}
                        item={12}
                        sx={{ marginTop: "10px" }}
                      >
                        {/* Newwwwwww */}
                        <Grid item md={2}>
                          <TextField
                            // required
                            // type="datetime-local"
                            // fullWidth
                            // id="addressingDate"
                            // label=" Addressing Date"
                            // name="addressing_date"
                            // onFocus={(e) => (e.target.type = "date")}
                            // onBlur={(e) => (e.target.type = "text")}
                            // autoFocus
                            // value={addressing_date}
                            // onChange={handleAddDate}
                            // inputProps={{
                            //   min: new Date().toISOString().split('T')[0],
                            // }}
                            required
                            type="date"
                            fullWidth
                            id="addressingDate"
                            label="Addressing Date"
                            name="addressing_date"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => (e.target.type = "text")}
                            onChange={handleAddDate}
                            autoFocus
                            value={addressing_date}
                            inputProps={{
                              min: new Date().toISOString().split("T")[0],
                            }}
                          />
                        </Grid>

                        <Grid item md={2}>
                          <TextField
                            // type="datetime-local"
                            // fullWidth
                            // id="addressingTime"
                            // label="Addressing Time"
                            // name="addressing_date"
                            // onFocus={(e) => (e.target.type = "time")}
                            // onBlur={(e) => (e.target.type = "text")}
                            // autoFocus
                            // value={addressing_time}
                            // onChange={handleAddTime}
                            required
                            type="time"
                            fullWidth
                            id="addressingTime"
                            label="Addressing Time"
                            name="addressingTime"
                            onChange={handleAddTime}
                            value={addressing_time}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ marginTop: "-1.3%" }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Mode Of Addressing
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              displayEmpty
                              className="my-select"
                              inputProps={{
                                "aria-label": "Select an option",
                              }}
                              value={examTypeList}
                              // name="examTypeList"
                              onChange={(e) => {
                                setExamTypeList(e.target.value);
                              }}
                            >
                              <MenuItem value="" disabled>
                                <span className="my-select-placeholder">
                                  Select Mode
                                </span>
                              </MenuItem>
                              {Object.entries(examTypeListOption).map(
                                ([key, value]) => (
                                  <MenuItem key={key} value={key}>
                                    {value}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* 
                        <Grid item md={3.75}>
                          <Autocomplete
                            required
                            options={addFacultyOptions}
                            value={addressing_faculty}
                            name="addFaculty_name"
                            onChange={(event, newaddFac) => {
                              setAddressing_faculty(newaddFac);
                            }}
                            getOptionLabel={(option) => option.full_name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Addressing Faculty"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={2.3} id="refreshTool">
        
        </Grid> */}
                        {/* <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Addressing Faculty
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={addressing_faculty}
              label="addressing_faculty"
              onChange={e => {
                setAddressing_faculty(e.target.value);
               
              }}
            >
             
            </Select>
            
          </FormControl> */}
                        {/* <Grid item md={3.75} style={{ marginTop: "-1.2%" }}>
                          <FormControl fullWidth>
                            <FormLabel id="x1" style={{ fontSize: "14px" }}>
                              Addressing Faculty
                            </FormLabel>
                            <Select
                              // labelId="x1"
                              style={{ height: "5.5vh" }}
                              size="small"
                              value={addressing_faculty}
                              name="addressing_faculty"
                              onChange={e => {
                                setAddressing_faculty(e.target.value);
                              }}
                              MenuProps={MenuProps}
                            >
                              {addFacultyOptions.map(name => (
                                <MenuItem key={name.full_name} value={name.id}>
                                  {name.full_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid> */}
                        <Grid item md={3}>
                          <Grid
                            item
                            onChange={(e) => {
                              setSearchAddressingFaculty(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              fullWidth
                              required
                              // size="small"
                              value={addressing_faculty}
                              onChange={(event, newValue) => {
                                setAddressing_faculty([...newValue]);
                              }}
                              options={
                                addFacultyOptions ? addFacultyOptions : []
                              }
                              getOptionLabel={(option) => option.full_name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.full_name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select Addressing Faculty"
                                  color="warning"
                                />
                              )}
                              disabled={examTypeList == 1}
                            />
                          </Grid>
                        </Grid>
                        <Grid item md={3}>
                          <Grid
                            item
                            onChange={(e) => {
                              setSearchCollegeExecutive(e.target.value);
                            }}
                          >
                            <Autocomplete
                              multiple
                              fullWidth
                              required
                              // size="small"
                              value={college_executive}
                              onChange={(event, newValue) => {
                                setCollege_executive([...newValue]);
                              }}
                              options={
                                collegeExecutiveOptions
                                  ? collegeExecutiveOptions
                                  : []
                              }
                              getOptionLabel={(option) => option.full_name}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    key={index}
                                    label={option.full_name}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Select College Executive"
                                  color="warning"
                                />
                              )}
                              disabled={examTypeList == 2}
                            />
                          </Grid>
                        </Grid>

                        <Grid item md={12} style={{ marginTop: "15px" }}>
                          <h3>Interview Rounds</h3>
                        </Grid>

                        <Grid container spacing={1} item>
                          {valuesAdd.map((item, ind) => (
                            <Grid
                              item
                              md={2.75}
                              style={{
                                display: "inline-flex",
                                position: "relative",
                                marginRight: "10px",
                              }}
                            >
                              <FormControl
                                style={{ width: "100%" }}
                                key={ind + 1}
                              >
                                <InputLabel
                                  id={`demo-simple-select-label-${ind}`} // Set a unique ID for each label
                                  sx={{
                                    textAlign: "left",
                                    fontWeight: "600",
                                    color: "#FC7300",
                                  }}
                                >
                                  {`Round ${ind + 1}`}{" "}
                                  {/* Update the label text here */}
                                </InputLabel>

                                <Select
                                  labelId={`demo-simple-select-label-${ind}`} // Reference the label ID
                                  id="demo-simple-select"
                                  value={item}
                                  onChange={(event) =>
                                    handleValueChange(event.target.value, ind)
                                  }
                                  displayEmpty
                                  className="my-select"
                                  inputProps={{
                                    "aria-label": "Select an option",
                                  }}
                                >
                                  <MenuItem value="" disabled>
                                    <span className="my-select-placeholder">
                                      Select Rounds
                                    </span>
                                  </MenuItem>
                                  {appRoundsOptions.map((roundsData, i) => (
                                    <MenuItem
                                      key={roundsData.ID}
                                      value={roundsData.ID}
                                    >
                                      {roundsData.name}
                                    </MenuItem>
                                  ))}
                                </Select>

                                {valuesAdd.length !== 1 && (
                                  <Button
                                    style={{
                                      position: "absolute",
                                      bottom: "-22px",
                                      right: "40%",
                                    }}
                                    onClick={() => removeRound(ind)}
                                  >
                                    <FaRegTrashAlt style={{ color: "red" }} />
                                  </Button>
                                )}
                                {valuesAdd.length - 1 === ind && (
                                  <Grid
                                    item
                                    md={1}
                                    style={{
                                      display:
                                        valuesAdd.length > 4
                                          ? "none"
                                          : "inline-flex",
                                      position: "absolute",
                                      right: "-4px",
                                      top: "25%",
                                    }}
                                  >
                                    <Button onClick={addRound}>
                                      <FaPlus />
                                    </Button>
                                  </Grid>
                                )}
                              </FormControl>
                            </Grid>
                          ))}
                        </Grid>

                        {/* trialll */}

                        <Grid
                          item
                          md={12}
                          style={{ marginTop: "0.5%" }}
                          xs={12}
                        >
                          <Grid container spacing={2} xs={12}>
                            <Grid item>
                              <Checkbox
                                name="is_broadcaster"
                                checked={is_broadcaster}
                                onChange={handleSendBroadCast}
                                id="is_broadcaster"
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                              />{" "}
                              <label
                                htmlFor="broadcaster"
                                style={{ fontSize: "16px" }}
                              >
                                Video BroadCasting
                              </label>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              onChange={(e) => {
                                setVideoSearch(e.target.value);
                              }}
                            >
                              <Autocomplete
                                options={videoOption}
                                value={videoData}
                                name="videoOption"
                                
                                onChange={(event, newData_xy) => {
                                  setVideoData(newData_xy);
                                  if (newData_xy) {
                                    setVideoError(false);
                                  }
                                }}
                                getOptionLabel={(option) => option.title}
                                disabled={!is_broadcaster}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search Video"
                                    variant="outlined"
                                    error={videoError}
                                    required={is_broadcaster}
                                    helperText={
                                      videoError
                                        ? "Video is required when broadcaster is selected"
                                        : ""
                                    }
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderColor: "rgba(0, 0, 0, 10)",
                                        "& fieldset": {
                                          borderColor: "rgba(0, 0, 0, 5)",
                                        },
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          xs={12}
                          item
                          spacing={2}
                          sx={{ mt: 5, mb: 2 }}
                          style={{
                            textAlign: "right",
                            padding: "5px",
                          }}
                        >
                          <Button
                            onClick={handleCancelClick}
                            variant="contained"
                            style={{
                              width: "10%",
                              marginRight: "3%",
                              backgroundColor: "#00337c",
                              color: "#e74646",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            Back
                          </Button>

                          {/* <Button
                            onClick={resetData}
                            type="button"
                            variant="contained"
                            style={{
                              width: "10%",
                              marginRight: "3%",
                              backgroundColor: "#E74646",
                              color: "white",
                              fontWeight: "bold",
                            }}
                          >
                            Cancel
                          </Button> */}
                          <Button
                            type="submit"
                            variant="contained"
                            style={{
                              width: "10%",
                              backgroundColor: "#1976D2",
                              fontWeight: "bold",
                            }}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>{" "}
          </Box>
        </article>
      </section>
    </div>
  );
};

export default EditAddressingMultiple;
