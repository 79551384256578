import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Button, Grid, Paper, Autocomplete, Chip } from "@mui/material";
import AxiosInstance from "../../../apis/AxiosInstance";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  boxShadow: "none",
  color: "#000",
}));

const CreateUnit = () => {
  let Navigate = useNavigate();
  const [unitName, setUnitName] = useState("");
  const [qualificationOption, setQualificationOption] = useState([]);
  const [qualificationData, setQualificationData] = useState([]);
  const [searchQualification, setSearchQualification] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);
  // const [searchDegree, setSearchDegree] = useState("");
  const [selectedDegree, setselectedDegree] = useState([]);
  const [degreeOptions, setDegreeOptions] = useState([]);

  const [enquiryBranchOption, setEnquiryBranchOption] = useState([]);
  const [selectedEnquiryBranchOption, setSelectedEnquiryBranchOption] =
    useState([]);
  const [inputValue2, setInputValue2] = useState([]);
  const [defaultBranchList, setDefaultBranchList] = useState([]);

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(`/get_enquiry_branch?limit=10`);
      let finalData = response.data.data;
      setEnquiryBranchOption(finalData);
      setDefaultBranchList(finalData);
    };
    fetchData();
  }, []);

  const fetchOptions2 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/get_enquiry_branch?search_keyword=${searchTerm_1}&limit=10`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setEnquiryBranchOption(data);
      } else {
        setEnquiryBranchOption([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange2 = (event, newInputValue) => {
    setInputValue2(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions2(newInputValue);
    } else {
      setEnquiryBranchOption(defaultBranchList);
    }
  };

  const handleOptionSelect2 = (_, newValue, action) => {
    setSelectedEnquiryBranchOption(newValue);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      name: unitName,
      degree: selectedDegree.map((value) => value.id),
      qualification_type: qualificationData.map((value) => value.id),
      enquiry_branches: selectedEnquiryBranchOption.map((value) => value.ID),
    };
    try {
      await AxiosInstance.post(`/unit`, payload)
        .then((response) => {
          toast.success("Unit Created Successfully");
          setTimeout(() => {
            Navigate("/unit_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchQualification !== "") {
        let data = await AxiosInstance.get(
          `/qualification?search_keyword=${searchQualification}`
        );
        let finalData = data.data.data;

        setQualificationOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/qualification`);
        let finalData = data.data.data;

        setQualificationOption(finalData);
      }
    };
    fetchData();
  }, [searchQualification]);

  useEffect(() => {
    if (qualificationData.length > 0) {
      fetchData();
    }
  }, [qualificationData]);

  const fetchData = async () => {
    let queryParams = qualificationData
      .map((value) => {
        return `qualification_type_ids=${value.id}`;
      })
      .join("&");
    try {
      const response = await AxiosInstance.get(
        `/degree_types_mini?limit=10&${queryParams}`
      );
      const data = response.data.data;
      setDegreeOptions(data);
      setDefaultCourseList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOptions1 = async (searchDegree) => {
    let queryParams = qualificationData
      .map((value) => {
        return `qualification_type_ids=${value.id}`;
      })
      .join("&");
    try {
      const response = await AxiosInstance.get(
        `/degree_types_mini?search_keyword=${searchDegree}&${queryParams}&limit=10`
      );
      const data = response.data.data;
      setDegreeOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 1) {
      fetchOptions1(newInputValue);
    } else {
      setDegreeOptions(defaultCourseList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setselectedDegree(newValue);
  };

  const ResetUnit = () => {
    setUnitName("");
    setselectedDegree([]);
    setQualificationData(null);
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "1px solid gray",
    borderRadius: "10px",
  };

  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1976D2",
    color: "#fff",
    fontWeight: "bold",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "#DF2E38",
    // border: "1px solid #FF5C35",
    color: "#fff",
    fontWeight: "bold",
    width: "120px",
  };
  const gridContainer = {
    margin: "0 auto",
    padding: "1em 0",
    gap: 20,
    marginTop: "5%",
  };

  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <h2
              style={{
                color: "#081F37",
                textTransform: "uppercase",
                borderBottom: "2px solid #9BA4B5",
              }}
            >
              Create Unit
            </h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <TextField
                    required
                    fullWidth
                    name="unitName"
                    label="Unit Name"
                    type="text"
                    id="unitName"
                    value={unitName}
                    onChange={(e) => {
                      setUnitName(e.target.value);
                    }}
                    InputLabelProps={{
                      style: { fontSize: 16 },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <Item
                    onChange={(e) => {
                      setSearchQualification(e.target.value);
                    }}
                  >
                    {/* <Autocomplete
                      options={qualificationOption}
                      value={qualificationData}
                      name="qualificationOption"
                      onChange={(event, newData_xy) => {
                        setQualificationData(newData_xy);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Qualification type"
                          variant="outlined"
                          required
                        />
                      )}
                    /> */}

                    <Autocomplete
                      multiple
                      value={qualificationData}
                      onChange={(event, newValue) => {
                        setQualificationData([...newValue]);
                      }}
                      options={qualificationOption ? qualificationOption : []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Qualification type"
                          color="warning"
                          required={qualificationData.length === 0}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 260 }} fullWidth>
                  <Item>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      value={selectedDegree}
                      inputValue={inputValue1}
                      onChange={handleOptionSelect1}
                      onInputChange={handleInputChange1}
                      options={degreeOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={option.name + (index + 1)}
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Degree"
                          color="warning"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          required={selectedDegree.length === 0}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Item>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.ID === value.ID
                    }
                    multiple
                    required
                    inputValue={inputValue2}
                    value={selectedEnquiryBranchOption}
                    onChange={handleOptionSelect2}
                    onInputChange={handleInputChange2}
                    options={enquiryBranchOption}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Enquiry Branch"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleCancelClick}
                  style={btnstyle1}
                >
                  Cancel
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  type="submit"
                  // color="primary"
                  variant="contained"
                  style={btnstyle}
                  color="warning"
                  disabled={disableSubmit}
                >
                  {disableSubmit ? "Creating unit" : "Create Unit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </section>
  );
};

export default CreateUnit;
