import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import Style from "./list.module.css";
import AxiosInstance from "../apis/AxiosInstance";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#176B87",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px",
  },
}));

export default function StudentList() {
  let [studentList, setStudentList] = useState([]);
  let [searchFilter, setSearchFilter] = useState("");

  // pagination
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const [defaultState, setDefaultState] = useState({
    studentList: [],
    totalLength: 0,
  });

  const [load, setLoad] = useState(false);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/user_mini?type=2&page=${page}`;
      setLoad(true);
      if (searchFilter) {
        if (searchFilter.includes("@")) {
          // Search by email
          endpoint += `&email=${searchFilter}`;
        } else if (!isNaN(searchFilter)) {
          // Search by mobile number
          endpoint += `&mobile=${searchFilter}`;
        } else {
          // Search by name
          endpoint += `&search_keyword=${searchFilter}`;
        }
      }

      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setStudentList(finalData);
        setTotalLength(data.data.filtered_count);
        setDefaultState({
          studentList: finalData,
          totalLength: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/user_mini?type=2&page=${newPage}`;
    setLoad(true);
    if (searchFilter) {
      if (searchFilter.includes("@")) {
        endpoint += `&email=${searchFilter}`;
      } else if (!isNaN(searchFilter)) {
        endpoint += `&mobile=${searchFilter}`;
      } else {
        endpoint += `&search_keyword=${searchFilter}`;
      }
    }
    try {
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setStudentList(finalData);
      setTotalLength(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchFilter(term);
    if (term && term.length > 1) {
      let endpoint = `/user_mini?type=2&page=${1}`;
      if (term) {
        if (term.includes("@")) {
          endpoint += `&email=${term}`;
        } else if (!isNaN(term)) {
          endpoint += `&mobile=${term}`;
        } else {
          endpoint += `&search_keyword=${term}`;
        }
      }
      try {
        const { data } = await AxiosInstance.get(endpoint);
        let finalData = data.data;
        setStudentList(finalData);
        setTotalLength(data.filtered_count);
        setPage(1);
      } catch (error) {
        toast.error("something went wrong");
      }
    } else {
      setStudentList(defaultState.studentList);
      setTotalLength(defaultState.totalLength);
      setPage(1);
    }
  };

  const totalPages = Math.ceil(totalLength / 10);

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "5%", width: "100%" }}
    >
      {/* <h1 className={Style.listHeader}>Student List</h1> */}
      <Paper
        style={{
          // maxHeight: "auto",
          width: "99%",
          margin: "0 auto",
          border: "1px solid gray",
          borderRadius: "10px",
          // height:"50vh",
          // backgroundColor:"red"
        }}
      >
        <aside className={Style.actionBlock} style={{ marginTop: "-1%" }}>
          <div
            className={Style.inputSearchContainer}
            style={{ padding: "4px" }}
          >
            {/* <input
              placeholder=" Search By Name"
              type="search"
              className={Style.searchField}
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
            /> */}
            <input
              placeholder=" Search By Name/Email/Mobile"
              type="search"
              className={Style.searchField}
              value={searchFilter}
              onChange={handleSearch}
            />

            <b style={{ position: "absolute", left: "6px", top: "30%" }}>
              <svg height="1.2em" viewBox="0 0 512 512" fill="#176B87">
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
              </svg>
            </b>
          </div>
          <h1
            style={{
              fontSize: "25px",
              color: "#176B87",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Student List
          </h1>
          <p
            style={{
              fontSize: "18px",
              width: "200px",

              fontWeight: "bold",
            }}
          >
            Total Count : {totalLength}
          </p>
        </aside>

        <TableContainer
          component={Paper}
          sx={{ maxHeight: 580, marginTop: "-1%" }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Student Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <TableCell colSpan={4} align="center">
                    Loading...
                  </TableCell>
                </StyledTableRow>
              ) : studentList && studentList.length > 0 ? (
                studentList.map((val, ind) => {
                  return (
                    <StyledTableRow key={ind + 1}>
                      <TableCell component="th" scope="row" align="center">
                        {val.full_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.mobiles === null
                          ? "N/A"
                          : val.mobiles?.map((mob) => <Box>{mob}</Box>)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.emails === null
                          ? "N/A"
                          : val.emails?.map((mail) => <Box>{mail}</Box>)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Link
                          to="/edit_student_list"
                          state={val}
                          title="Update Student Info"
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="orange"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                          </svg>
                        </Link>
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow component="th" scope="row" align="center">
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    colSpan={4}
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "red",
                    }}
                  >
                    No Data
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
