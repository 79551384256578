import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  styled,
  Pagination,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Style from "./studentData.module.css";
import AxiosInstance from "../apis/AxiosInstance";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#081F37",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function StudentData() {
  let [studentList, setStudentList] = useState([]);
  let [searchFilter, setSearchFilter] = useState("");

  // pagination
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // useEffect(() => {
  //   let fetchData = async () => {
  //     if (searchFilter !== "") {
  //       try {
  //         let data = await AxiosInstance.get(
  //           `/user_mini?role_ids=2&page=${page}&search_keyword=${searchFilter}`
  //         );
  //         let finalData = data.data.data;
  //         setStudentList(finalData);
  //         setTotalLength(data.data.filtered_count);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       try {
  //         let data = await AxiosInstance.get(
  //           `/user_mini?role_ids=2&page=${page}`
  //         );
  //         let finalData = data.data.data;
  //         setStudentList(finalData);
  //         setTotalLength(data.data.filtered_count);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [searchFilter, page]);

  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/user_mini?role_ids=2&page=${page}&is_not_blocked=${true}`;

      if (searchFilter) {
        if (searchFilter.includes("@")) {
          // Search by email
          endpoint += `&email=${searchFilter}`;
        } else if (!isNaN(searchFilter)) {
          // Search by mobile number
          endpoint += `&mobile=${searchFilter}`;
        } else {
          // Search by name
          endpoint += `&search_keyword=${searchFilter}`;
        }
      }

      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setStudentList(finalData);
        setTotalLength(data.data.filtered_count);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [searchFilter, page]);

  const totalPages = Math.ceil(totalLength / 10);

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "5%", width: "100%" }}
    >
      <h1 className={Style.listHeader}>Student List</h1>
      <Paper
        style={{
          maxHeight: "90vh",
          width: "90%",
          margin: "0 auto",
          border: "1px solid #111",
        }}
      >
        <aside className={Style.actionBlock}>
          <div className={Style.inputSearchContainer}>
            {/* <input
              placeholder=" Search By Name"
              type="search"
              className={Style.searchField}
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
            /> */}
            <input
              placeholder=" Search By Name/Email/Mobile"
              type="search"
              className={Style.searchField}
              value={searchFilter}
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
            />

            <b style={{ position: "absolute", left: "6px", top: "30%" }}>
              <svg height="1.2em" viewBox="0 0 512 512" fill="#081F37">
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
              </svg>
            </b>
          </div>
        </aside>

        <TableContainer style={{ maxHeight: 600 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Student Name</StyledTableCell>
                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Edit</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList !== null ? (
                studentList.map((val, ind) => {
                  return (
                    <StyledTableRow key={ind + 1}>
                      <TableCell component="th" scope="row" align="center">
                        {val.full_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.mobiles === null
                          ? "N/A"
                          : val.mobiles?.map((mob) => <Box>{mob}</Box>)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.emails === null
                          ? "N/A"
                          : val.emails?.map((mail) => <Box>{mail}</Box>)}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {/* <Link
                          to="/edit_student_list"
                          state={val}
                          title="Update Student Info"
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="orange"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                          </svg>
                        </Link> */}
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow component="th" scope="row" align="center">
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    colSpan={4}
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "red",
                    }}
                  >
                    No Data
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
