// import { useState } from "react";
// import { toast } from "react-toastify";
// import {
//   Avatar,
//   Button,
//   Grid,
//   Paper,
//   TextField,
//   FormControl,
//   InputLabel,
//   OutlinedInput,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import AxiosInstance from "../../../apis/AxiosInstance";

// const CreateTPOLogin = () => {
//   let Navigate = useNavigate();
//   let { state } = useLocation();

//   const [full_name, setfull_name] = useState(state.name);
//   const [mobiles, setmobiles] = useState(
//     state.Mobile.length > 0 ? state.Mobile[0].mobile: ""
//   );
//   const [emails, setEmails] = useState(
//     state.Email.length > 0 ? state.Email[0].email: ""
//   );

//   const [password, setpassword] = useState("");
//   const [confirm_password, setconfirm_password] = useState("");

//   // show password
//   const [showPassword, setShowPassword] = useState(false);
//   const [showPassword1, setShowPassword1] = useState(false);

//   const handleClickShowPassword = () => setShowPassword((show) => !show);
//   const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//   if (password !== confirm_password) {
//     toast.error("Passwords do not match");
//     return;
//   }

//     const payload = {
//       full_name: full_name,
//       role_id: [13],
//       college_id: [state.college.id],
//       spoc_id: state.id,
//       email: emails,
//       mobile: mobiles,
//       country_code: `+91`,
//       password: password,
//       status: 1,
//     };
//     try {
//       await AxiosInstance.post(`/tpo_create`, payload)
//         .then((data) => {
//           toast.success(data.data.message);
//           setTimeout(() => {
//             Navigate("/college_spoc_list");
//           }, 1500);
//         })
//         .catch((error) => {
//           if (error.response.data) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error("Something Went wrong");
//           }
//         });
//     } catch (error) {
//       if (error.response) {
//         toast.error(error.response.data.error);
//       } else {
//         toast.error("Something went Wrong Please Try Again");
//       }
//     }
//   };

//   const paperStyle = {
//     padding: 20,
//     gap: 0,
// border:"2px solid gray",
//     width: "700px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     margin: "10px auto",
//   };
//   const avatarStyle = { backgroundColor: "#06113C" };
//   const btnstyle = {
//     backgroundColor: "#06113C",
//     color: "#fff",
//     border: "1px solid #06113C",
//   };
//   const btnstyle1 = {

//     backgroundColor: "crimson",
//     color: "#fff",
//     border: "1px solid crimson",
//   };
//   const gridContainer = {
//     margin: "0 auto",
//     gap: 20,
//   };

//   const handleCancelClick = () => {
//     window.history.back();
//   }

//   return (
//     <Grid container style={{ marginTop: "5%" }}>
//       <Grid xs={12} item style={gridContainer} align="center">
//         <Paper elevation={10} style={paperStyle}>
//           <Grid align="center" item>
//             <Avatar style={avatarStyle}>
//               <GroupAddIcon />
//             </Avatar>
//             <h2>Create TPO Login Credentials</h2>
//             <br />
//           </Grid>
//           <form action="" onSubmit={handleSubmit}>
//             <Grid item container sm={12} spacing={2}>
//               <Grid item sm={12}>
//                 <TextField
//                   color="warning"
//                   size="small"
//                   // sx={{ minWidth: 210 }}
//                   fullWidth
//                   name="full_f_name"
//                   label="Fullname"
//                   disabled
//                   value={full_name}
//                   onChange={(e) => setfull_name(e.target.value)}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "&.Mui-disabled": {
//                         "& fieldset": {
//                           borderColor: "rgba(0, 0, 0, 0.5)",
//                         },
//                         color: "rgba(0, 0, 0, 0.5)",
//                       },
//                     },
//                   }}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 <TextField
//                   color="warning"
//                   size="small"
//                   // sx={{ minWidth: 210 }}
//                   fullWidth
//                   name="Mobile"
//                   label="Mobile"
//                   disabled
//                   value={mobiles}
//                   onChange={(e) => setmobiles(e.target.value)}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "&.Mui-disabled": {
//                         "& fieldset": {
//                           borderColor: "rgba(0, 0, 0, 0.5)",
//                         },
//                         color: "rgba(0, 0, 0, 0.5)",
//                       },
//                     },
//                   }}
//                 />
//               </Grid>
//               <Grid item sm={12}>
//                 <TextField
//                   color="warning"
//                   size="small"
//                   // sx={{ minWidth: 210 }}
//                   fullWidth
//                   name="Email"
//                   label="Email"
//                   disabled
//                   value={emails}
//                   onChange={(e) => setEmails(e.target.value)}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       "&.Mui-disabled": {
//                         "& fieldset": {
//                           borderColor: "rgba(0, 0, 0, 0.5)",
//                         },
//                         color: "rgba(0, 0, 0, 0.5)",
//                       },
//                     },
//                   }}
//                 />
//               </Grid>

//               <Grid item container sm={12} spacing={1}>
//                 <Grid item sm={6}>
//                   <FormControl sx={{ minWidth: 260 }} fullWidth>
//                     <InputLabel color="warning">Password</InputLabel>
//                     <OutlinedInput
//                       // color="warning"
//                       required
//                       name="password"
//                       value={password}
//                       autoComplete="new-password"
//                       onChange={(e) => setpassword(e.target.value)}
//                       sx={{
//                         "& .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "rgba(0, 0, 0, 1)", // Border color for the outline
//                         },
//                         "&:hover .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "rgba(0, 0, 0, 1)", // Border color on hover
//                         },
//                         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "#FCA311", // Border color when focused
//                         },
//                       }}
//                       type={showPassword ? "text" : "password"}
//                       endAdornment={
//                         <InputAdornment position="end">
//                           <IconButton
//                             aria-label="toggle password visibility"
//                             onClick={handleClickShowPassword}
//                             onMouseDown={handleMouseDownPassword}
//                             edge="end"
//                           >
//                             {showPassword ? <VisibilityOff /> : <Visibility />}
//                           </IconButton>
//                         </InputAdornment>
//                       }
//                       label="Password"
//                     />
//                   </FormControl>
//                 </Grid>
//                 <Grid item sm={6}>
//                   <FormControl sx={{ minWidth: 260 }} fullWidth>
//                     <InputLabel color="warning">Confirm Password</InputLabel>
//                     <OutlinedInput
//                       color="warning"
//                       required
//                       name="confirm_password"
//                       autoComplete="confirm-password"
//                       value={confirm_password}
//                       onChange={(e) => setconfirm_password(e.target.value)}
//                       sx={{
//                         "& .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "rgba(0, 0, 0, 1)", // Border color for the outline
//                         },
//                         "&:hover .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "rgba(0, 0, 0, 1)", // Border color on hover
//                         },
//                         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "#FCA311", // Border color when focused
//                         },
//                       }}
//                       type={showPassword1 ? "text" : "password"}
//                       endAdornment={
//                         <InputAdornment position="end">
//                           <IconButton
//                             aria-label="toggle password visibility"
//                             onClick={handleClickShowPassword1}
//                             onMouseDown={handleMouseDownPassword}
//                             edge="end"
//                           >
//                             {showPassword1 ? <VisibilityOff /> : <Visibility />}
//                           </IconButton>
//                         </InputAdornment>
//                       }
//                       label="ConfirmPassword"
//                     />
//                   </FormControl>
//                 </Grid>
//               </Grid>
//               <Grid item container sm={12} spacing={3}>
//                 <Grid item sm={3}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle1}
//                     fullWidth
//                     onClick={handleCancelClick}
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//                 <Grid item sm={3}>
//                   <Button
//                     type="submit"
//                     color="primary"
//                     variant="contained"
//                     style={btnstyle}
//                     fullWidth
//                   >
//                     Submit
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </form>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default CreateTPOLogin;

import { useState } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AxiosInstance from "../../../apis/AxiosInstance";

const CreateTPOLogin = () => {
  let Navigate = useNavigate();
  let { state } = useLocation();

  const [full_name, setfull_name] = useState(state.name);
  const [mobiles, setmobiles] = useState(
    state.Mobile.length > 0 ? state.Mobile.map((item) => item.mobile) : [""]
  );
  const [emails, setEmails] = useState(
    state.Email.length > 0 ? state.Email.map((item) => item.email) : [""]
  );

  // Store the selected mobile and email (multiple can be selected)
  const [selectedMobile, setSelectedMobile] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);

  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");

  // show password
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCheckboxChange = (type, value) => {
    if (type === "mobile") {
      if (selectedMobile.includes(value)) {
        setSelectedMobile(selectedMobile.filter((item) => item !== value));
      } else {
        setSelectedMobile([value]);
      }
    } else if (type === "email") {
      if (selectedEmail.includes(value)) {
        setSelectedEmail(selectedEmail.filter((item) => item !== value));
      } else {
        setSelectedEmail([value]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      toast.error("Passwords do not match");
      return;
    }

    const payload = {
      full_name: full_name,
      role_id: [13],
      college_id: [state.college.id],
      spoc_id: state.id,
      email: selectedEmail.length > 0 ? selectedEmail[0] : "", // Send as single string
      mobile: selectedMobile.length > 0 ? selectedMobile[0] : "", // Send as single string
      country_code: `+91`,
      password: password,
      status: 1,
    };

    try {
      await AxiosInstance.post(`/tpo_create`, payload)
        .then((data) => {
          toast.success(data.data.message);
          setTimeout(() => {
            Navigate("/college_spoc_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went Wrong Please Try Again");
      }
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    border: "2px solid gray",
    width: "700px",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#06113C" };
  const btnstyle = {
    backgroundColor: "#06113C",
    color: "#fff",
    border: "1px solid #06113C",
  };
  const btnstyle1 = {
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };
  return (
    <Grid container style={{ marginTop: "5%" }}>
      <Grid xs={12} item align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Create TPO Login Credentials</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  color="warning"
                  size="small"
                  fullWidth
                  name="full_f_name"
                  label="Fullname"
                  disabled
                  value={full_name}
                  onChange={(e) => setfull_name(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-disabled": {
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 0.5)",
                        },
                        color: "rgba(0, 0, 0, 0.5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <p
                  style={{
                    color: "#16325B",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  Select Email and Mobile
                </p>
              </Grid>
              {/* Displaying Mobile Numbers in TextFields with Checkboxes */}
              <Grid item sm={6}>
                {mobiles.map((mobile, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedMobile.includes(mobile)}
                        onChange={() => handleCheckboxChange("mobile", mobile)}
                      />
                    }
                    label={
                      <TextField
                        // size="small"
                        fullWidth
                        value={mobile}
                        disabled
                        style={{ padding: "5px" }}
                      />
                    }
                  />
                ))}
              </Grid>

              {/* Displaying Emails in TextFields with Checkboxes */}
              <Grid item md={6}>
                {emails.map((email, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedEmail.includes(email)}
                        onChange={() => handleCheckboxChange("email", email)}
                      />
                    }
                    label={
                      <TextField
                        // size="small"
                        fullWidth
                        value={email}
                        disabled
                        style={{ padding: "5px" }}
                      />
                    }
                  />
                ))}
              </Grid>

              {/* Password fields */}
              <Grid container item sm={12} spacing={2}>
                <Grid item sm={6}>
                  <FormControl sx={{ minWidth: 260 }} fullWidth>
                    <InputLabel color="warning">Password</InputLabel>
                    <OutlinedInput
                      required
                      name="password"
                      value={password}
                      autoComplete="new-password"
                      onChange={(e) => setpassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl sx={{ minWidth: 260 }} fullWidth>
                    <InputLabel color="warning">Confirm Password</InputLabel>
                    <OutlinedInput
                      required
                      name="confirm_password"
                      value={confirm_password}
                      autoComplete="new-password"
                      onChange={(e) => setconfirm_password(e.target.value)}
                      type={showPassword1 ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* Submit and Cancel buttons */}
              <Grid item container sm={12} spacing={3}>
                <Grid item sm={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={btnstyle1}
                    fullWidth
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item sm={3}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreateTPOLogin;
