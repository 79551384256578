import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Button,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Modal,
  Box,
  Typography,
  ButtonGroup,
} from "@mui/material";
import Style from "./list.module.css";
import AxiosInstance from "../apis/AxiosInstance";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#176B87",
    color: "#fff",
    fontWeight: "bold",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0px",
  },
}));

export default function UserList() {
  let navigate = useNavigate();
  let [userList, setUserList] = useState([]);
  let [searchFilter, setSearchFilter] = useState("");

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [openModal1, setOpenModal1] = useState(false);
  const [selectedUser1, setSelectedUser1] = useState(null);
  const [defaultState, setDefaultState] = useState({
    userList: [],
    totalLength: 0,
  });
  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchData = async () => {
      let endpoint = `/user_mini?type=1&page=${page}`;
      setLoad(true);
      if (searchFilter) {
        if (searchFilter.includes("@")) {
          endpoint += `&email=${searchFilter}`;
        } else if (!isNaN(searchFilter)) {
          endpoint += `&mobile=${searchFilter}`;
        } else {
          endpoint += `&search_keyword=${searchFilter}`;
        }
      }
      try {
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setUserList(finalData);
        setTotalLength(data.data.filtered_count);
        setDefaultState({
          userList: finalData,
          totalLength: data.data.filtered_count,
        });
        if (searchFilter) setPage(1);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    };
    fetchData();
  }, []);
  const handleChangePage = async (event, newPage) => {
    let endpoint = `/user_mini?type=1&page=${newPage}`;
    setLoad(true);
    if (searchFilter) {
      if (searchFilter.includes("@")) {
        endpoint += `&email=${searchFilter}`;
      } else if (!isNaN(searchFilter)) {
        endpoint += `&mobile=${searchFilter}`;
      } else {
        endpoint += `&search_keyword=${searchFilter}`;
      }
    }
    try {
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setUserList(finalData);
      setTotalLength(data.filtered_count);
      setPage(newPage);
      setLoad(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoad(false);
    }
  };
  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchFilter(term);
    if (term && term.length > 1) {
      let endpoint = `/user_mini?type=1&page=${1}`;
      if (term) {
        if (term.includes("@")) {
          endpoint += `&email=${term}`;
        } else if (!isNaN(term)) {
          endpoint += `&mobile=${term}`;
        } else {
          endpoint += `&search_keyword=${term}`;
        }
      }
      try {
        const { data } = await AxiosInstance.get(endpoint);
        let finalData = data.data;
        setUserList(finalData);
        setTotalLength(data.filtered_count);
        setPage(1);
      } catch (error) {
        toast.error("something went wrong");
      }
    } else {
      setUserList(defaultState.userList);
      setTotalLength(defaultState.totalLength);
      setPage(1);
    }
  };
  const totalPages = Math.ceil(totalLength / 10);

  const handleUserBlock = (userId, user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleUserUnBlock = (userId, user) => {
    setSelectedUser1(user);
    setOpenModal1(true);
  };

  const handleYesClick = async () => {
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      formData.append("id", selectedUser.id);
      formData.append("status", 2);

      await AxiosInstance.patch("/update_user", formData, config, {
        "content-type": "multipart/form-data",
      })
        .then((response) => {
          toast.success("Successfully User Blocked");
          if (response.status === 200) {
            setTimeout(() => {
              window.location.assign("/user_list");
              // navigate("/user_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }

    // Close the modal
    setOpenModal(false);
  };

  const handleYesClick1 = async () => {
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      formData.append("id", selectedUser1.id);
      formData.append("status", 1);

      await AxiosInstance.patch("/update_user", formData, config, {
        "content-type": "multipart/form-data",
      })
        .then((response) => {
          toast.success("Successfully User Un-Blocked");
          if (response.status === 200) {
            setTimeout(() => {
              window.location.assign("/user_list");
              // navigate("/user_list");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }

    // Close the modal
    setOpenModal(false);
  };

  const handleNoClick = () => {
    setOpenModal(false);
  };

  const handleNoClick1 = () => {
    setOpenModal1(false);
  };

  return (
    <section
      id={Style.batchListBlock}
      style={{ marginTop: "5%", width: "100%" }}
    >
      {/* <h1 className={Style.listHeader}>User List</h1> */}
      <Paper
        style={{
          width: "99%",
          margin: "0 auto",
          border: "1px solid gray",
          borderRadius: "10px",
        }}
      >
        <aside className={Style.actionBlock} style={{ marginTop: "-1%" }}>
          <div
            className={Style.inputSearchContainer}
            style={{ padding: "4px" }}
          >
            <input
              placeholder=" Search By Name/Email/Mobile"
              type="search"
              className={Style.searchField}
              value={searchFilter}
              onChange={handleSearch}
            />
            <b style={{ position: "absolute", left: "6px", top: "30%" }}>
              <svg height="1.2em" viewBox="0 0 512 512" fill="#176B87">
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
              </svg>
            </b>
          </div>
          <h1
            style={{
              fontSize: "25px",
              color: "#176B87",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            User List
          </h1>
          <p
            style={{
              fontSize: "18px",
              width: "200px",

              fontWeight: "bold",
            }}
          >
            Total Count : {totalLength}
          </p>
        </aside>

        <TableContainer component={Paper}  sx={{ maxHeight: 580, marginTop: "-1%" }}>
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" style={{ width: "200px" }}>
                  User Name
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "200px" }}>
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "400px" }}>
                  Email
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "400px" }}>
                  User Block
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "350px" }}>
                  Roles
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "250px" }}>
                  Unit Name
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "250px" }}>
                  State Name
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "200px" }}>
                  Edit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    Loading...
                  </TableCell>
                </StyledTableRow>
              ) : userList && userList.length > 0 ? (
                userList.map((val, ind) => {
                  return (
                    <StyledTableRow key={ind + 1}>
                      <TableCell component="th" scope="row" align="center">
                        {val.full_name === null || val.full_name === ""
                          ? "N/A"
                          : val.full_name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.mobiles === null || val.mobiles === ""
                          ? "N/A"
                          : val.mobiles.map((ea) => (
                              <Box style={{ height: "22px" }}>{ea}</Box>
                            ))}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.emails === null || val.emails === ""
                          ? "N/A"
                          : val.emails.map((ea) => (
                              <Box style={{ height: "22px" }}>{ea}</Box>
                            ))}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Button
                          sx={{
                            "&:hover": { background: "#DBDFFD" },
                            background:
                              val.status === 2 ? "#A0A0A0" : "#EB455F",
                            width: "100px !important",
                            color: val.status === 2 ? "#fff " : "#fff",
                            cursor:
                              val.status === 2 ? "not-allowed " : "pointer",
                            width: "100px !important",
                            "&:hover": {
                              color: val.status === 2 ? "#fff" : "#fff",
                              background:
                                val.status === 2 ? "#A0A0A0" : "#EB455F",
                              width: "100px !important",
                            },
                          }}
                          onClick={() => handleUserBlock(val.id, val)}
                          disabled={val.status === 2}
                          title={
                            val.status === 2
                              ? "This user is already blocked"
                              : ""
                          }
                        >
                          {val.status === 2 ? "Blocked" : "Block"}
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          sx={{
                            "&:hover": { background: "#DBDFFD" },
                            background: val.status === 1 ? "#A0A0A0" : "green",
                            width: "100px !important",
                            color: val.status === 1 ? "#fff" : "#fff",
                            cursor: val.status === 1 ? "" : "pointer",
                            "&:hover": {
                              color: val.status === 1 ? "#fff" : "#fff",
                              background: val.status === 1 ? "" : "green",
                              width: "100px !important",
                            },
                          }}
                          onClick={() => handleUserUnBlock(val.id, val)}
                          disabled={val.status === 1}
                          title={
                            val.status === 2
                              ? "This user is already unblocked"
                              : ""
                          }
                        >
                          {val.status === 1 ? "UnBlocked" : "UnBlock"}
                        </Button>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.role_names === undefined ||
                        val.role_names === null ||
                        val.role_names === ""
                          ? "N/A"
                          : Object.values(val.role_names).map((ea, index) => (
                              <Box key={index} style={{ height: "22px" }}>
                                {ea?.["name"]}
                              </Box>
                            ))}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.units === null ||
                        Object.keys(val.units).length === 0
                          ? "N/A"
                          : Object.values(val.units).map((ea) => (
                              <Box key={ea.id} style={{ height: "22px" }}>
                                {ea?.name}
                              </Box>
                            ))}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {val.all_states
                          ? <p style={{color:"#176B87",fontSize:"15px",fontWeight:"bold"}}>All States</p>
                          : val.branch &&
                            val.branch.state_id &&
                            Object.keys(val.branch.state_id).length > 0
                          ? Object.values(val.branch.state_id).map(
                              (state, index) => (
                                <Box
                                  key={index}
                                  style={{
                                    height: "22px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {state?.name || "N/A"}
                                </Box>
                              )
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Link
                          to="/edit_user_info"
                          state={val}
                          title="Update Student Info"
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="orange"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                          </svg>
                        </Link>
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 370,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 2,
                  borderRadius: "5px",
                }}
              >
                <IconButton
                  onClick={() => setOpenModal(false)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#111",
                    fontSize: "18px",
                    fontWeight: "900",
                  }}
                >
                  Do you want to block this user?
                </Typography>
                <ButtonGroup sx={{ mt: 5 }} fullWidth>
                  <Button
                    onClick={handleYesClick}
                    style={{
                      background: "#436850",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      padding: "10px 10px",
                    }}
                  >
                    Yes
                  </Button>{" "}
                  &nbsp;&nbsp;
                  <Button
                    onClick={handleNoClick}
                    style={{
                      background: "#FF8080",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </Box>
            </Modal>
            <Modal open={openModal1} onClose={() => setOpenModal1(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 370,
                  bgcolor: "background.paper",
                  border: "2px solid gray",
                  boxShadow: 24,
                  p: 2,
                  borderRadius: "5px",
                }}
              >
                <IconButton
                  onClick={() => setOpenModal1(false)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "red",
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#111",
                    fontSize: "18px",
                    fontWeight: "900",
                  }}
                >
                  Do you want to Unblock this user?
                </Typography>
                <ButtonGroup sx={{ mt: 5 }} fullWidth>
                  <Button
                    onClick={handleYesClick1}
                    style={{
                      background: "#436850",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                      padding: "10px 10px",
                    }}
                  >
                    Yes
                  </Button>{" "}
                  &nbsp;&nbsp;
                  <Button
                    onClick={handleNoClick1}
                    style={{
                      background: "#FF8080",
                      color: "#fff",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </Box>
            </Modal>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </section>
  );
}
