import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Chip,
} from "@mui/material";
import { Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import AxiosInstance from "../../../apis/AxiosInstance";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditCollegeAppointment() {
  let navigate = useNavigate();
  // state values  for the autocomplete component
  let { state } = useLocation();

  const [selectedColgState, setSelectedColgState] = useState("");

  const [selectedColgDistrict, setselectedColgDistrict] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [selectedCollege, setSelectedCollege] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [degTerm, setDegTerm] = useState("");
  const [streamTerm, setStreamTerm] = useState(null);

  const [selectedSpoc, setSelectedSpoc] = useState("");
  let IDy =
    Object.keys(state.ids.degree).length > 0
      ? Object.keys(state.ids.degree)
      : [];
  let degreeY = Object.values(state.ids.degree).map((value) => value.name);
  let OptionsY = degreeY.map((value, id) => {
    return { id: IDy[id], name: value };
  });
  const [selectedDegrees, setselectedDegrees] = useState(
    Object.keys(state.ids.degree).length > 0 ? OptionsY : []
  );

  const IDx =
    Object.keys(state.ids.stream).length > 0
      ? Object.keys(state.ids.stream)
      : [];
  const Courses = Object.values(state.ids.stream).map((value) => value.name);
  const OptionsX = Courses.map((value, id) => {
    return {
      id: IDx[id],
      name: value,
    };
  });
  const [selectedStreams, setselectedStreams] = useState(
    Object.keys(state.ids.stream).length > 0 ? OptionsX : []
  );

  const [yop, setYop] = useState(state.year_of_passout);
  const [expectedStudents, setExpectedStudents] = useState(
    state.expected_students
  );
  const [incubation_type, setincubation_type] = useState(
    String(state.incubation_type)
  );
  const [comment, setComment] = useState(state.comment);
  const [appointment_date, setAppointment_date] = useState(
    moment.utc(state.appointment_date).local().format("yyyy-MM-DD")
  );
  const [appointment_time, setAppointment_time] = useState(
    moment.utc(state.appointment_date).local().format("HH:mm")
  );

  const [all_degree, setAll_Degree] = useState(state.all_degree);
  const [all_stream, setall_stream] = useState(state.all_stream);

  // for rendering the data from api
  const [universityOptions, setUniversityOptions] = useState([]);
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [colgSpocOptions, setColgSpocOptions] = useState([]);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [streamsOptions, setStreamsOptions] = useState([]);
  const [yopOptions, setYopOptions] = useState([]);
  const currentYear = new Date().getFullYear();
  const [colgStateOptions, setColgStateOptions] = useState([]);

  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);

  const [searchDistrict, setSearchDistrict] = useState("");
  const [searchUniversity, setSearchUniversity] = useState("");
  const [searchCollege, setSearchCollege] = useState("");

  // All degree and All Stream
  const [isDisabledDeg, setIsDisabledDeg] = useState(all_degree);
  const [isDisabledStrm, setIsDisabledStrm] = useState(all_stream);

  const handleCheckboxDegree = (event) => {
    setIsDisabledDeg(event.target.checked);
    setAll_Degree(event.target.checked);
  };
  const handleCheckboxStream = (event) => {
    setIsDisabledStrm(event.target.checked);
    setall_stream(event.target.checked);
  };

  //!FUNCTION TO HANDLE INPUT-CHANGE
  let handleChangeIncubation = (e) => {
    setincubation_type(e.target.value);
  };

  // to expectedStudents
  let handleStudents = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ""); // remove non-numeric characters
    if (inputValue.length <= 5) {
      setExpectedStudents(inputValue);
    }
  };

  // to handle Date and Time
  let handleDate = (e) => {
    setAppointment_date(e.target.value);
    if (!appointment_date === "") {
      setError(true);
    } else {
      setError(false);
    }
  };
  let handleTime = (e) => {
    setAppointment_time(e.target.value);
  };

  const [error, setError] = useState(false);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchTerm !== "") {
        //college state api
        let colgStateData = await AxiosInstance.get(
          `/statemini?search_keyword=${searchTerm}&limit=10`
        );
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      } else {
        //college state api
        let colgStateData = await AxiosInstance.get(`/statemini?limit=10`);
        let finalcolgStateData = colgStateData.data.data;
        setColgStateOptions(finalcolgStateData);
      }
    };
    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    const generateYears = () => {
      const startYear = currentYear - 1;
      const endYear = currentYear + 5;
      const years = [];

      for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
      }

      setYopOptions(years);
    };
    generateYears();
  }, [currentYear]);

  // deg and stream
  useEffect(() => {
    let fetchData1 = async (e) => {
      // college Degree api
      if (degTerm !== "" && degTerm.length > 1) {
        let degreeData = await AxiosInstance.get(
          `/degree_types?search_keyword=${degTerm}`
        );
        let finaldegreeData = degreeData.data.data;
        setDegreeOptions(finaldegreeData);
      } else {
        let degreeData = await AxiosInstance.get(`/degree_types`);
        let finaldegreeData = degreeData.data.data;
        setDegreeOptions(finaldegreeData);
      }
    };
    fetchData1();
  }, [degTerm]);

  const [url, setUrl] = useState("/academic_streams");

  useEffect(() => {
    let fetchData2 = async (e) => {
      let QueryParams = selectedDegrees.map((value, i) => {
        return `degree_id=${value.id}`;
      });
      setStreamsOptions([]);
      const finalUrl =
        QueryParams.length > 0 ? `${url}?${QueryParams.join("&")}` : url;
      if (streamTerm !== null) {
        let streamData = await AxiosInstance.get(
          `${finalUrl}${
            finalUrl === url ? "?" : "&"
          }search_keyword=${streamTerm}`
        );
        let finalstreamData = streamData.data.data;
        if (finalstreamData) {
          setStreamsOptions(finalstreamData);
        }
      } else {
        let streamData = await AxiosInstance.get(`${finalUrl}`);
        let finalstreamData = streamData.data.data;
        setStreamsOptions(finalstreamData);
      }
    };
    fetchData2();
  }, [streamTerm, selectedDegrees]);

  // for particular spoc for its college and univrsity
  useEffect(() => {
    let fetchData = async (e) => {
      // college Spoc api
      const parseLog = (a) => {
        const userData = a ? JSON.parse(a) : { user_id: "" };
        const { user_id } = userData;
        return user_id;
      };
      let currUser_id = parseLog(Cookies.get("logged"));
      if (selectedUniversity === "") {
        return null;
      } else {
        let collegeSpocData1 = await AxiosInstance.get(
          `/college_spoc?college_id=${selectedCollege.id}&university_id=${selectedUniversity.id}&added_by_id=${currUser_id}`
        );
        let finalColgSpocData = collegeSpocData1.data.data;
        setColgSpocOptions(finalColgSpocData);
      }
    };
    fetchData();
  }, [selectedCollege, selectedUniversity]);

  //! for college district filter from state
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== "") {
        if (searchDistrict !== "") {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?search_keyword=${searchDistrict}&state_id=${selectedColgState.id}&limit=10`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        } else {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?state_id=${selectedColgState.id}&limit=10`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        }
      }
    };
    fetchData();
  }, [searchDistrict, selectedColgState]);

  //! university Filter based on state

  useEffect(() => {
    let fetchUniData = async (e) => {
      if (selectedColgState !== "") {
        if (searchDistrict !== "") {
          let universityData = await AxiosInstance.get(
            `/district_mini?search_keyword=${searchDistrict}&limit=10&state_id=${selectedColgState.id}`
          );
          let finalUniversityData = universityData.data.data;
          setUniversityOptions(finalUniversityData);
        } else {
          let universityData = await AxiosInstance.get(
            `/district_mini?limit=10&state_id=${selectedColgState.id}`
          );
          let finalUniversityData = universityData.data.data;
          setUniversityOptions(finalUniversityData);
        }
      }
    };
    fetchUniData();
  }, [searchDistrict, selectedColgState]);

  //! for college filter based on district and university
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedUniversity !== "") {
        if (searchCollege !== "") {
          // college api
          let collegeData = await AxiosInstance.get(
            `/college_mini?search_keyword=${searchCollege}&limit=10&district_id=${selectedColgDistrict.id}&university_id=${selectedUniversity.id}`
          );
          let finalCollegeData = collegeData.data.data;
          setCollegeOptions(finalCollegeData);
        } else {
          // college api
          let collegeData = await AxiosInstance.get(
            `/college_mini?limit=10&district_id=${selectedColgDistrict.id}&university_id=${selectedUniversity.id}`
          );
          let finalCollegeData = collegeData.data.data;
          setCollegeOptions(finalCollegeData);
        }
      }
    };

    fetchData();
  }, [searchCollege, selectedColgDistrict, selectedUniversity]);
  // search terms

  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  //deg and stream
  let searchDegree = async (e) => {
    if (e.target.value === "") {
      setDegTerm("a");
    } else {
      setDegTerm(e.target.value);
    }
  };

  let searchStream = async (e) => {
    if (e.target.value === "") {
      setStreamTerm("a");
    } else {
      setStreamTerm(e.target.value);
    }
  };

  //==============================
  const [removedDegree, setRemovedDegree] = useState([]);

  // Handle chip removal
  const handleChipDelete = (chipToDelete) => () => {
    // Filter out the chip to be removed from selectedChips
    const updatedChips = selectedDegrees.filter(
      (chip) => chip !== chipToDelete
    );

    // Add the removed chip to removedChips
    setRemovedDegree([...removedDegree, chipToDelete]);

    // Update the selectedChips state
    setselectedDegrees(updatedChips);
  };

  const [removedStream, setRemovedStream] = useState([]);

  // Handle chip removal
  const handleChipDelete1 = (chipToDelete) => () => {
    // Filter out the chip to be removed from selectedChips
    const updatedChips = selectedStreams.filter(
      (chip) => chip !== chipToDelete
    );

    // Add the removed chip to removedChips
    setRemovedStream([...removedStream, chipToDelete]);

    // Update the selectedChips state
    setselectedStreams(updatedChips);
  };

  // Reset Data
  let resetData = (e) => {
    e.preventDefault();

    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedUniversity(null);
    setSelectedCollege(null);
    setSelectedSpoc(null);
    setselectedDegrees(null);
    setselectedStreams(null);
    setYop(null);
    setExpectedStudents("");
    setincubation_type("");
    setComment("");
    setAppointment_date("");
    setAppointment_time("");
    setAll_Degree(false);
    setall_stream(false);
  };
  // Form Submit Function
  const handlePatch = async (e) => {
    e.preventDefault();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const time = new Date(`${appointment_date}T${appointment_time}`);
    const timePart = moment(time).utc().format("HH:mm +ssss [UTC]");
    const appointmentDateTime = `${timePart}`;

    let formData = new FormData();

    // const combinedDateTime = `${appointment_date}T${appointment_time}`;
    // const parsedDateTime = moment.utc(combinedDateTime);
    // const formattedDateTime = parsedDateTime.format("HH:mm:ss [UTC]");

    // const combinedDateTimeStr = appointment_date + 'T' + appointment_time + ':00Z';
    // const combinedDateTime = new Date(combinedDateTimeStr);
    // const utcTime = moment(combinedDateTime).utc().format('HH:mm');

    formData.append("id", state.id);
    if (
      state.year_of_passout == yop &&
      expectedStudents == state.expected_students &&
      incubation_type == state.incubation_type &&
      comment == state.comment &&
      all_degree == state.all_degree &&
      all_stream == state.all_stream &&
      appointment_date === state.appointment_date &&
      appointment_time === state.appointment_date
    ) {
      // if (
      //   state.all_degree === false &&
      //   state.all_stream === true &&
      //   selectedDegrees.id == state.ids.degree.id
      // ) {
      //   toast.info("No Changes are Made to save");
      // } else if (
      //   state.all_degree === true &&
      //   state.all_stream === false &&
      //   selectedStreams.id == state.ids.stream.id
      // ) {
      //   toast.info("No Changes are Made to save");
      // }
      // if (
      //   state.all_degree === false &&
      //   state.all_stream === false &&
      //   selectedStreams.id == state.ids.stream.id &&
      //   selectedStreams.id == state.ids.stream.id &&
      //   selectedDegrees.id == state.ids.degree.id
      // ) {
      //   toast.info("No Changes are Made to save");
      // }else
      if (
        state.all_stream === false &&
        Object.keys(state.ids.stream).length === 0 &&
        selectedStreams.id === ""
      ) {
        toast.info(
          "There is a Error in this Appointment Check All Streams or Select any Stream"
        );
      } else if (
        state.all_degree === false &&
        Object.keys(state.ids.degree).length === 0 &&
        selectedDegrees.id === ""
      ) {
        toast.info(
          "There is a Error in this Appointment Check All Streams or Select any Stream"
        );
      } else {
        if (selectedDegrees != OptionsY) {
          selectedDegrees
            .filter((va) => OptionsY.some((fa) => va.id !== fa.id))
            .forEach((ef) => {
              formData.append("add_degree", ef.id);
            });
        }
        if (removedDegree.length > 0) {
          removedDegree.forEach((val) => {
            formData.append("remove_degree", val.id);
          });
        }
        if (selectedStreams != OptionsX) {
          selectedStreams
            .filter((va) => OptionsX.some((fa) => va.id !== fa.id))
            .forEach((ef) => {
              formData.append("add_stream", ef.id);
            });
        }
        if (removedStream.length > 0) {
          removedStream.forEach((val) => {
            formData.append("remove_stream", val.id);
          });
        }
        if (yop != state.year_of_passout) {
          formData.append("year_of_passout", Number(yop));
        }
        if (expectedStudents != state.expected_students) {
          formData.append("expected_students", expectedStudents);
        }
        if (incubation_type != state.incubation_type) {
          formData.append("incubation_type", Number(incubation_type));
        }
        if (comment != state.comment) {
          // comment == state.comment;
          formData.append("comment", comment);
        }
        if (all_degree != state.all_degree) {
          formData.append("all_degree", all_degree);
        }
        if (all_stream != state.all_stream) {
          formData.append("all_stream", all_stream);
        }

        if (appointment_date !== "" || appointment_time !== "") {
          if (
            moment.utc(state.appointment_date).local().format("yyyy-MM-DD") !==
              appointment_date ||
            moment.utc(state.appointment_date).local().format("hh:mm") !==
              appointment_time
          ) {
            formData.append("appointment_date", appointment_date);
            formData.append("appointment_time", appointmentDateTime);
          }
        }

        // if (
        //   appointment_date == state.appointment_date ||
        //   appointment_time == state.appointment_date
        // ) {
        //   formData.append("appointment_date", appointment_date);
        //   formData.append("appointment_time", appointmentDateTime);
        // }
        // if (appointment_time != state.appointment_date.split(" ")[1]) {
        //   formData.append("appointment_time", appointment_time);
        // }
        await AxiosInstance.patch("/appointment", formData, config)
          .then((data) => {
            toast.success("Appointment Updated Successfully");
            // setTimeout(() => {
            //   window.location.assign("/appointment_list");
            // }, 1500);
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              toast.error(error.response.data.error);
            } else {
              toast.error("Something Went Wrong");
            }
          });
      }
    } else {
      // if (selectedDegrees.id != state.ids.degree.id) {
      // }
      // formData.append("degree_id", Number(selectedDegrees.id));
      if (selectedDegrees != OptionsY) {
        selectedDegrees
          .filter((va) => OptionsY.some((fa) => va.id !== fa.id))
          .forEach((ef) => {
            formData.append("add_degree", ef.id);
          });
      }
      if (removedDegree.length > 0) {
        removedDegree.forEach((val) => {
          formData.append("remove_degree", val.id);
        });
      }

      // if (selectedStreams.id != state.ids.stream.id) {
      // }
      // formData.append("stream_id", Number(selectedStreams.id));

      if (selectedStreams != OptionsX) {
        selectedStreams
          .filter((va) => OptionsX.some((fa) => va.id !== fa.id))
          .forEach((ef) => {
            formData.append("add_stream", ef.id);
          });
      }
      if (removedStream.length > 0) {
        removedStream.forEach((val) => {
          formData.append("remove_stream", val.id);
        });
      }

      if (yop != state.year_of_passout) {
        formData.append("year_of_passout", Number(yop));
      }
      if (expectedStudents != state.expected_students) {
        formData.append("expected_students", expectedStudents);
      }
      if (incubation_type != state.incubation_type) {
        formData.append("incubation_type", Number(incubation_type));
      }
      if (comment != state.comment) {
        // comment == state.comment;
        formData.append("comment", comment);
      }
      if (all_degree != state.all_degree) {
        formData.append("all_degree", all_degree);
      }
      if (all_stream != state.all_stream) {
        formData.append("all_stream", all_stream);
      }
      // if (appointment_time != state.appointment_date) {
      //   formData.append("appointment_time", appointmentDateTime);
      //   // formData.append("appointment_date", timePart);
      // }
      // if (appointment_date != state.appointment_date) {
      //   formData.append("appointment_date", appointment_date);
      //   // formData.append("appointment_time", timePart);
      // }

      // formData.append("appointment_date", appointment_date);
      // formData.append("appointment_time", appointmentDateTime);

      if (appointment_date !== "" || appointment_time !== "") {
        if (
          moment.utc(state.appointment_date).local().format("yyyy-MM-DD") !==
            appointment_date ||
          moment.utc(state.appointment_date).local().format("hh:mm") !==
            appointment_time
        ) {
          formData.append("appointment_date", appointment_date);
          formData.append("appointment_time", appointmentDateTime);
        }
      }
      // if(appointment_time !== ""){
      //   if(moment.utc(state.appointment_date).local().format("hh:mm") !== appointment_time){
      //     formData.append("appointment_time", appointmentDateTime);
      //     formData.append("appointment_date", appointment_date);
      //   }
      // }

      await AxiosInstance.patch("/appointment", formData, config)
        .then((data) => {
          toast.success("Appointment Edited Successfully");
          setTimeout(() => {
            navigate("/");
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went Wrong");
          }
        });

      // await AxiosInstance.patch("/appointment");
    }
  };
  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
         style={{
          width: "98%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4.3%",
          background: "#efefef",
        }}
      >
        <Box>
          <Paper variant="outlined" style={{ border: "2px solid #9BA4B5", borderRadius: "10px" }}>
            <Box
              sx={{
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "2px solid #9BA4B5",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#081f37",
                  fontSize: {
                    xs: "20px", // Smaller font size on small screens
                    sm: "25px", // Medium font size on small to medium screens
                    md: "30px", // Larger font size on medium to large screens
                  },
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
             
                 EDIT COLLEGE APPOINTMENT FORM
               
              </Box>
            </Box>
            <form
              style={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "1%",
              }}
              onSubmit={handlePatch}
            >
              <Grid container spacing={1} item={12}>
                {/* college state */}
                <Grid md={3} xs={12}>
                  <Item onChange={searchData}>
                    <TextField
                      value={state.ids.state.value}
                      onChange={() => {
                        setSelectedColgState(state.ids.state.value);
                      }}
                      fullWidth
                      disabled
                      label="State"
                    />
                  </Item>
                </Grid>
                {/* colleg district */}
                <Grid  md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchDistrict(e.target.value);
                    }}
                  >
                    <TextField
                      value={state.ids.district.value}
                      onChange={() => {
                        setselectedColgDistrict(state.ids.district.value);
                      }}
                      fullWidth
                      disabled
                      label="City"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>
                <Grid  md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchUniversity(e.target.value);
                    }}
                  >
                    <TextField
                      value={state.ids.university.value}
                      onChange={() => {
                        setSelectedUniversity(state.ids.university.value);
                      }}
                      fullWidth
                      disabled
                      label="University"
                    />
                  </Item>
                </Grid>
                <Grid  md={3} xs={12}>
                  <Item
                    onChange={(e) => {
                      setSearchCollege(e.target.value);
                    }}
                  >
                    <TextField
                      value={state.ids.college.value}
                      onChange={() => {
                        setSelectedCollege(state.ids.college.value);
                      }}
                      fullWidth
                      disabled
                      label="College"
                    />
                  </Item>
                </Grid>
                {/* SPOC */}
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      // value={state.college_spocs.name}
                      value={
                        Object.keys(state.college_spocs).length !== 0
                          ? Object.values(state.college_spocs)[0].name
                          : ""
                      }
                      onChange={() => {
                        setSelectedSpoc(state.college_spocs.name);
                      }}
                      fullWidth
                      disabled
                      label="Spoc Name"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>
                {/* YOP */}
                <Grid md={3} xs={12}>
                  <Item>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <Autocomplete
                        required
                        options={yopOptions}
                        value={yop}
                        name="year_of_passout"
                        onChange={(e, newyop) => {
                          setYop(newyop);
                        }}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select YOP"
                            variant="outlined"
                            required
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Item>
                </Grid>
                {/* Degree */}
                <Grid md={3} xs={12}>
                  <Item onChange={searchDegree}>
                    <Autocomplete
                      multiple
                      disabled={isDisabledDeg}
                      required
                      // size="small"
                      value={selectedDegrees}
                      onChange={(event, newValue) => {
                        setselectedDegrees(newValue);
                      }}
                      options={degreeOptions}
                      getOptionLabel={(option) =>
                        option.name + ` (${option.short_name.toUpperCase()})`
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                            onDelete={handleChipDelete(option)}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Degree"
                          color="warning"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Streams */}
                <Grid md={3} xs={12}>
                  <Item onChange={searchStream}>
                    <Autocomplete
                      multiple
                      disabled={isDisabledStrm}
                      required
                      // size="small"
                      value={selectedStreams}
                      onChange={(event, newValue) => {
                        setselectedStreams(newValue);
                      }}
                      options={streamsOptions}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option.name}
                            {...getTagProps({ index })}
                            onDelete={handleChipDelete1(option)}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Stream"
                          color="warning"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Item>
                </Grid>
                {/* Checkboxes */}
                <Grid md={3} xs={12}>
                  <Item Component={"aside"} sx={{ width: "100%" }}>
                    <FormControl
                      sx={{
                        position: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {selectedDegrees.length !== 0 ? (
                        <>
                          <label
                            htmlFor="chkDeg"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Degrees
                          </label>
                          <Checkbox
                            disabled={isDisabledDeg ? false : true}
                            id="chkDeg"
                            checked={isDisabledDeg}
                            onChange={handleCheckboxDegree}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                                color: "#efefef",
                                background: "grey",
                              },
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="chkDeg"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Degrees
                          </label>
                          <Checkbox
                            id="chkDeg"
                            required
                            checked={isDisabledDeg}
                            onChange={handleCheckboxDegree}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 26 } }}
                            value={all_degree}
                          />
                        </>
                      )}
                      &nbsp; &nbsp;&nbsp;
                      {selectedStreams.length !== 0 ? (
                        <>
                          <label
                            htmlFor="chkStrm"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Streams
                          </label>
                          <Checkbox
                            disabled={isDisabledStrm ? false : true}
                            id="chkStrm"
                            checked={isDisabledStrm}
                            onChange={handleCheckboxStream}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 26,
                                color: "#efefef",
                                background: "grey",
                              },
                            }}
                            label="Disabled"
                          />
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="chkStrm"
                            style={{ fontSize: "17px", fontWeight: "500" }}
                          >
                            All Streams
                          </label>
                          <Checkbox
                            id="chkStrm"
                            required
                            checked={isDisabledStrm}
                            onChange={handleCheckboxStream}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 26 } }}
                            error={error && !all_stream}
                            value={all_stream}
                          />

                          {all_stream === "" || selectedStreams === "" ? (
                            <>
                              {error && (
                                <FormHelperText style={{ color: "d32f2f" }}>
                                  Please select Appointment Date
                                </FormHelperText>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </FormControl>
                  </Item>
                </Grid>
                {/* Date and Time */}
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      type="date"
                      fullWidth
                      id="appointment_date"
                      label="Appointment Date"
                      name="appointment_date"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={handleDate}
                      autoFocus
                      value={appointment_date}
                      error={error && !appointment_date}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                    {appointment_date === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select Appointment Date
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>
                <Grid  md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      type="time"
                      fullWidth
                      id="appointmentTime"
                      label="Appointment Time"
                      name="appointment_time"
                      onChange={handleTime}
                      value={appointment_time}
                      error={error && !appointment_time}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                    {appointment_time === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select Appointment Time
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>
                <Grid md={3} xs={12}>
                  <Item>
                    <TextField
                      required
                      fullWidth
                      id="expected_students"
                      label="Students Targeted"
                      name="expected_students"
                      value={expectedStudents}
                      onChange={handleStudents}
                      inputProps={{ maxLength: 5 }}
                      variant="outlined"
                      type="text"
                      error={error && !expectedStudents}
                      helperText={
                        error && !expectedStudents
                          ? "Please Select Expected Students"
                          : null
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </Item>
                </Grid>
                <Grid item md={3}>
                  <Item Component={"aside"} sx={{ width: "100%" }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="incubation_type"
                      value={incubation_type}
                      onChange={handleChangeIncubation}
                      required
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Pre-incubation"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Post-incubation"
                      />
                    </RadioGroup>

                    {incubation_type === "" ? (
                      <>
                        {error && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select an Incubation Type.
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Item>
                </Grid>
                <Grid  md={9} xs={12}>
                  <Item sx={{ display: "flex" }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Item>
                </Grid>

                <Grid
                  xs={12}
                  item
                  md={12}
             
                  style={{
                    textAlign: "right",
                    padding: "5px",
                  }}
                >
                  <Link to="/">
                    <Button
                      type="button"
                      variant="contained"
                      style={{
                        width: "10%",
                        marginRight: "3%",
                        backgroundColor: "#00337c",
                        color: "#e74646",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      Back
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      width: "10%",
                      color: "#F5F5F5",
                      backgroundColor: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Box>
      </article>
    </section>
  );
}
