import React from "react";
import styles from "./_loader.module.css";
const Loader = () => {
  return (
    <div
      style={{
        height: "70vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
