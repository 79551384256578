import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Button,
  Box,
  Grid,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import AxiosInstance from "../apis/AxiosInstance";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1B4242",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "2px",
  },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#86AB89",
    color: "#111",
    fontWeight: "bold",
    padding: "2px",
    border: "1px solid gray",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid gray",
    padding: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function CallCategoryList() {
  const [callCategory_list, setCallCategory_list] = useState([]);

  const [page, setPage] = useState(1);
  const [totalCallCategory, setTotalCallCategory] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchCallCategory = async (_) => {
      setLoad(true);
      try {
        let categoryData = await AxiosInstance.get(
          `/call_category?page=${page}`
        );
        setTotalCallCategory(categoryData.data.total_length);
        let finalGdData = categoryData.data.data;
        if (finalGdData !== null) {
          setCallCategory_list(finalGdData);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };
    fetchCallCategory();
  }, [page]);

  const totalPages = Math.ceil(totalCallCategory / 10);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <section style={{ width: "100%", marginTop: "4.1%" }}>
      <Box
        sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#081f37",
            fontSize: "25px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          Call Category List
        </Box>
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count: {totalCallCategory}
        </h1>
      </Box>
      {/* <Grid
        item
        style={{
          position: "relative",
          width: "25%",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1%",
        }}
      >
        <h1 style={{ color: "#081F37", fontSize: "20px" }}>
          Total List : {totalCallCategory}
        </h1>
      </Grid> */}

      <section
        style={{
          width: "99%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          whiteSpace: "nowrap",
          border: "1px solid gray",
          borderRadius: "10px",
          marginTop: "0.5%",
        }}
      >
        <TableContainer
          component={Paper}
          style={{ maxHeight: window.innerWidth > 1800 ? 680 : 590 }}
        >
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "100px !important",
                  }}
                >
                  Sl No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "150px !important",
                  }}
                >
                  Author
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                  }}
                >
                  Category Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Edit Call Category
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Roles
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "180px !important",
                  }}
                >
                  Verifier Roles
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "1000px !important",
                  }}
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "200px !important",
                  }}
                >
                  Edit Main Status
                </StyledTableCell>
                {/* <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "white",
                    width: "500px",
                  }}
                >
                  Sub Status
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : callCategory_list.length > 0 ? (
                callCategory_list.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {val.author
                        ? val.author.name
                          ? val.author.name
                          : "NA"
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.category === null || val.category === ""
                        ? "N/A"
                        : val.category}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Link to="/edit_call_category" state={val}>
                        <Tooltip title="Call Category Edit">
                          <Button
                            sx={{
                              "&:hover": { background: "#DBDFFD" },
                            }}
                          >
                            <FaEdit
                              fontSize={25}
                              style={{
                                color: "#646FD4",
                                "&:hover": { color: "#EB455F" },
                                cursor: "pointer",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.roles && val.roles.length > 0 ? (
                        val.roles.map((item, index) => (
                          <Box key={index} style={{ height: "auto" }}>
                            {item?.["name"] || "NA"}
                          </Box>
                        ))
                      ) : (
                        <Box style={{ height: "auto" }}>NA</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {val.verifier_roles && val.verifier_roles.length > 0 ? (
                        val.verifier_roles.map((item, index) => (
                          <Box key={index} style={{ height: "auto" }}>
                            {item?.["name"] || "NA"}
                          </Box>
                        ))
                      ) : (
                        <Box style={{ height: "auto" }}>NA</Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Table
                        sx={{
                          borderCollapse: "separate",
                          tableLayout: "fixed",
                        }}
                        aria-label="simple table"
                        //  stickyHeader
                        // style={{ border: "1px solid #111", borderRadius: "20px" }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell1
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                color: "white",
                                width: "70px !important",
                              }}
                            >
                              Sl.No
                            </StyledTableCell1>
                            <StyledTableCell1
                              align="center"
                              sx={{
                                fontSize: "15px",
                                color: "white",
                                height: "auto",
                                width: "300px",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                                fontWeight: "bold",
                              }}
                            >
                              Main Status
                            </StyledTableCell1>
                            <StyledTableCell1
                              align="center"
                              sx={{
                                fontSize: "15px",
                                color: "white",
                                height: "auto",
                                width: "400px",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                                fontWeight: "bold",
                              }}
                            >
                              Sub Status
                            </StyledTableCell1>
                            <StyledTableCell1
                              align="center"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                color: "white",
                                width: "150px !important",
                              }}
                            >
                              Edit Sub Status
                            </StyledTableCell1>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {val.call_status && val.call_status.length > 0 ? (
                            val.call_status.map((item, index) => (
                              <TableRow>
                                <StyledTableCell1
                                  key={index}
                                  style={{ height: "auto" }}
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {index + 1}
                                </StyledTableCell1>
                                <StyledTableCell1
                                  key={index}
                                  sx={{
                                    height: "auto",
                                    width: "300px",
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.["main_status"] || "NA"}
                                  <Link
                                    to="/indivisual_edit_main_status"
                                    state={{ item, val }}
                                  >
                                    <Tooltip title="Edit Main Status">
                                      <Button
                                        sx={{
                                          "&:hover": { background: "#DBDFFD" },
                                          marginLeft: 1,
                                        }}
                                      >
                                        <FaEdit
                                          fontSize={18}
                                          style={{
                                            color: "#646FD4",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Link>
                                </StyledTableCell1>
                                <StyledTableCell1
                                  key={index}
                                  // style={{ height: "auto" }}
                                  sx={{
                                    height: "auto",
                                    width: "400px",
                                    wordBreak: "break-word",
                                    whiteSpace: "normal",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.sub_status &&
                                  Array.isArray(item.sub_status) &&
                                  item.sub_status.length > 0 ? (
                                    <Table>
                                      <TableBody>
                                        {item.sub_status.map(
                                          (subItem, index) => (
                                            <TableRow key={index}>
                                              <StyledTableCell
                                                style={{
                                                  backgroundColor:
                                                    index % 2 === 0
                                                      ? "white"
                                                      : "#F5F5F5", // Alternating row colors
                                                  fontWeight: "bold",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {subItem?.["sub_status"] ||
                                                  "NA"}
                                                <Link
                                                  to="/indivisual_edit_sub_status"
                                                  state={{ subItem, item, val }}
                                                >
                                                  <Tooltip title="Edit Sub Status">
                                                    <Button
                                                      sx={{
                                                        "&:hover": {
                                                          background: "#DBDFFD",
                                                        },
                                                        marginLeft: 1,
                                                      }}
                                                    >
                                                      <FaEdit
                                                        fontSize={18}
                                                        style={{
                                                          color: "#646FD4",
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </Button>
                                                  </Tooltip>
                                                </Link>
                                              </StyledTableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  ) : (
                                    "NA"
                                  )}
                                </StyledTableCell1>
                                <StyledTableCell1
                                  sx={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  <Link
                                    to="/edit_call_substatus"
                                    state={{ item, val }}
                                  >
                                    <Tooltip title="Call Sub Status Edit">
                                      <Button
                                        sx={{
                                          "&:hover": { background: "#DBDFFD" },
                                        }}
                                      >
                                        <FaEdit
                                          fontSize={18}
                                          style={{
                                            color: "#646FD4",
                                            "&:hover": { color: "#EB455F" },
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </Link>
                                </StyledTableCell1>
                              </TableRow>
                            ))
                          ) : (
                            <Box style={{ height: "22px" }}>NA</Box>
                          )}
                        </TableBody>
                      </Table>
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Link to="/edit_call_mainstatus" state={val}>
                        <Tooltip title="Call Main Status Edit">
                          <Button
                            sx={{
                              "&:hover": { background: "#DBDFFD" },
                            }}
                          >
                            <FaEdit
                              fontSize={25}
                              style={{
                                color: "#222831",
                                "&:hover": { color: "#EB455F" },
                                cursor: "pointer",
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>

        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "45%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </section>
  );
}
