import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Pagination,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import AxiosInstance from "../apis/AxiosInstance";
import { socketContext } from "./IoContext";

const NotificationCard = () => {
  const {
    setNotreadNotification,
    sendMessage,
    notificationData,
    notreadNotification,
    isReadAll,
    setIsReadAll,
  } = useContext(socketContext);
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(1);
  const [alreadyRead, setAlreadyRead] = useState([]);
  const [totalNotification, setTotalNotification] = useState(0);
  const [load, setLoad] = useState(false);
  const [defaultState, setDefaultState] = useState({
    notificationList: [],
    totalNotification: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      let endpoint = `/get_notification?page=${page}`;
      try {
        setLoad(true);
        let data = await AxiosInstance.get(endpoint);
        let finalData = data.data.data;
        setNotificationList(finalData);
        setTotalNotification(data.data.filtered_count);
        // setNotreadNotification(data.data.filtered_count);
        setDefaultState({
          notificationList: finalData,
          totalNotification: data.data.filtered_count,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoad(false);
      }
    };
    fetchData();
  }, [page, setNotreadNotification]);

  const handleChangePage = async (event, newPage) => {
    let endpoint = `/get_notification?page=${newPage}`;
    try {
      const { data } = await AxiosInstance.get(endpoint);
      let finalData = data.data;
      setNotificationList(finalData);
      setTotalNotification(data.filtered_count);
      //   setNotreadNotification(data.data.not_read_count);
      setIsReadAll(!isReadAll);
      if (page !== newPage) {
        setAlreadyRead([]);
      }
      setPage(newPage);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handleSubmit = async (id, user_id) => {
    const singleNotification = {
      type: "read-notification",
      notification: {
        id: id,
        user_id: user_id,
      },
    };
    sendMessage(singleNotification);
    let read = [...alreadyRead];
    read.push(id);
    setAlreadyRead(read);
    setNotreadNotification(notreadNotification - 1);
    // console.log("Single notification data sent");
  };

  const handleReadAll = async (id, user_id) => {
    const singleNotification = {
      type: "read-all-notification",
      notification: {
        id: id,
        user_id: user_id,
      },
    };
    sendMessage(singleNotification);
    let read = [...alreadyRead];
    //   read.push(id);
      notificationList.forEach((e) => {
          read.push(e.id)
      })
    setAlreadyRead(read);
    // setNotreadNotification(notreadNotification - 1);
    setIsReadAll(!isReadAll);
    // console.log("Single notification data sent");
  };

  const totalPages = Math.ceil(totalNotification / 10);

  return (
    <main style={{ width: "100vw", height: "auto", paddingBottom: "10px" }}>
      <Grid style={{ marginTop: "4%" }}>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#124076" }}>
          Notification Lists &nbsp;&nbsp;
          <span style={{ marginLeft: "60%" }}>
            {notificationList.length > 0 && notreadNotification>0 && (
              <Button
                size="small"
                style={{ background: "#E88D67", color: "#fff" }}
                onClick={() => {
                  handleReadAll(
                    notificationList[0].id,
                    notificationList[0].user_id
                  );
                }}
              >
                Read All
              </Button>
            )}
          </span>
        </h1>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "1%", width: "95%", margin: "0 auto" }}
      >
        {load ? (
          <Card style={{ height: "200px", width: "100%", marginTop: "1%" }}>
            <CardContent>
              <Typography variant="h6" textAlign="center" alignItems="center">
                {" "}
                Loading...
              </Typography>
            </CardContent>
          </Card>
        ) : notificationList.length > 0 ? (
          notificationList.map((val, ind) => (
            <Grid item xs={12} sm={6} md={3} key={ind}>
              <Card
                style={{
                  background: "#efefef",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  wordBreak: "break-word",
                }}
              >
                <CardContent style={{ minHeight: "150px", paddingTop: "8%" }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      fontSize: "17px",
                    }}
                  >
                    {val.description ?? "NA"}
                  </Typography>
                  {/* <Typography variant="h6">
                    <p>
                      <span
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          color: "#050C9C",
                        }}
                      >
                        Base_URL :
                      </span>
                      &nbsp;
                      <span>
                        {val.notifications_types?.base_url
                          ? val.notifications_types.base_url
                          : "NA"}
                      </span>
                    </p>
                  </Typography> */}
                </CardContent>
                <CardContent
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {/* {alreadyRead.includes(val.id) ? null : (
                    <Button
                      size="small"
                      style={{ background: "#6B8A7A", color: "#fff" }}
                      onClick={() => {
                        handleSubmit(val.id, val.user_id);
                      }}
                    >
                      Read
                    </Button>
                  )} */}
                  {alreadyRead.includes(val.id) || val.read ? null : (
                    <Button
                      size="small"
                      style={{ background: "#6B8A7A", color: "#fff" }}
                      onClick={() => {
                        handleSubmit(val.id, val.user_id);
                      }}
                    >
                      Read
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Card style={{ height: "200px", width: "100%", marginTop: "1%" }}>
            <CardContent
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "red", textAlign: "center" }}
              >
                No Data Found
              </Typography>
            </CardContent>
          </Card>
        )}
      </Grid>
      <Pagination
        style={{ maxWidth: "100%", marginTop: "1%", marginLeft: "40%" }}
        count={totalPages}
        sx={{ button: { color: "#1B4242", fontWeight: "bold" } }}
        page={page}
        onChange={handleChangePage}
      />
    </main>
  );
};

export default NotificationCard;

// import React, { useContext } from "react";
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
//   Pagination,
//   Button,
// } from "@mui/material";
// import { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import AxiosInstance from "../apis/AxiosInstance";
// import { socketContext } from "./IoContext";

// const NotificationCard = () => {
//   const { setNotreadNotication, ws } = useContext(socketContext);
//   const [notificationList, setNotificationList] = useState([]);
//   const [page, setPage] = useState(1);
//   const [alreadyRead, setAlreadyRead] = useState([]);
//   const [totalNotification, setTotalNotification] = useState(0);
//   const [load, setLoad] = useState(false);

//   const [defaultState, setDefaultState] = useState({
//     notificationList: [],
//     totalNotification: 0,
//   });

//   useEffect(() => {
//     let fetchData = async () => {
//       let endpoint = `/get_notification?page=${page}`;
//       try {
//         let data = await AxiosInstance.get(endpoint);
//         let finalData = data.data.data;
//         setNotificationList(finalData);
//         setNotreadNotication(data.data.filtered_count);
//         setDefaultState({
//           escalation_list: finalData,
//           totalNotification: data.data.filtered_count,
//         });
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchData();
//   }, [page, setNotreadNotication]);

//   const handleChangePage = async (event, newPage) => {
//     let endpoint = `/get_notification?page=${newPage}`;
//     try {
//       const { data } = await AxiosInstance.get(endpoint);
//       let finalData = data.data;
//       setNotificationList(finalData);
//       setTotalNotification(data.filtered_count);
//       setPage(newPage);
//     } catch (error) {
//       toast.error("something went wrong");
//     }
//   };

//   const handleSubmit = async (id, user_id) => {
//     setAlreadyRead(val => [...val, id]);
//     const singleNotification = JSON.stringify({
//       event: "read",
//       data: {
//         type: "read-notification",
//         notification: {
//           id: id,
//           user_id: user_id,
//         },
//       },
//     });

//     // Send data to WebSocket
//     ws.send(singleNotification);
//     console.log("Add user event sent");

//     // Send data to the backend
//     try {
//       await AxiosInstance.post("/read_notification", { id, user_id });
//       toast.success("Notification marked as read");
//     } catch (error) {
//       toast.error("Failed to mark notification as read");
//       setAlreadyRead(val =>
//         val.filter(notificationId => notificationId !== id)
//       );
//     }
//   };

//   const totalPages = Math.ceil(totalNotification / 10);

//   return (
//     <main style={{ width: "100vw", height: "auto", paddingBottom: "10px" }}>
//       <Grid style={{ marginTop: "4%" }}>
//         <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#124076" }}>
//           Notification Lists &nbsp;&nbsp;
//           <span style={{ marginLeft: "60%" }}>
//             <Button
//               size="small"
//               style={{ background: "#E88D67", color: "#fff" }}
//             >
//               Read All
//             </Button>
//           </span>
//         </h1>
//       </Grid>
//       <Grid
//         container
//         spacing={2}
//         style={{ marginTop: "1%", width: "95%", margin: "0 auto" }}
//       >
//         {load ? (
//           <Card>
//             <CardContent>
//               <Typography variant="h6"> Loading...</Typography>
//             </CardContent>
//           </Card>
//         ) : notificationList.length > 0 ? (
//           notificationList.map((val, ind) => (
//             <Grid item xs={12} sm={6} md={3} key={ind}>
//               <Card
//                 style={{
//                   background: "#efefef",
//                   height: "200px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "flex-end",
//                 }}
//               >
//                 <CardContent style={{ minHeight: "150px", paddingTop: "8%" }}>
//                   <Typography
//                     style={{
//                       fontWeight: "bold",
//                       textTransform: "capitalize",
//                       fontSize: "17px",
//                     }}
//                   >
//                     {val.description ?? "NA"}
//                   </Typography>
//                   <Typography variant="h6">
//                     <p>
//                       <span
//                         style={{
//                           fontSize: "17px",
//                           fontWeight: "bold",
//                           color: "#050C9C",
//                         }}
//                       >
//                         Base_URL :
//                       </span>
//                       &nbsp;
//                       <span>
//                         {val.notifications_types?.base_url
//                           ? val.notifications_types.base_url
//                           : "NA"}
//                       </span>
//                     </p>
//                   </Typography>
//                 </CardContent>
//                 <CardContent
//                   style={{ display: "flex", justifyContent: "flex-end" }}
//                 >
//                   {alreadyRead.includes(val.id) ? null : (
//                     <Button
//                       size="small"
//                       style={{ background: "#6B8A7A", color: "#fff" }}
//                       onClick={() => {
//                         handleSubmit(val.id, val.user_id);
//                       }}
//                     >
//                       Read
//                     </Button>
//                   )}
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))
//         ) : (
//           <Card style={{ height: "200px" }}>
//             <CardContent
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 height: "100%",
//               }}
//             >
//               <Typography
//                 variant="h5"
//                 style={{ color: "red", textAlign: "center" }}
//               >
//                 No Data Found
//               </Typography>
//             </CardContent>
//           </Card>
//         )}
//       </Grid>
//       <Pagination
//         style={{ maxWidth: "100%", marginTop: "1%", marginLeft: "40%" }}
//         count={totalPages}
//         sx={{ button: { color: "#1B4242", fontWeight: "bold" } }}
//         page={page}
//         onChange={handleChangePage}
//       />
//     </main>
//   );
// };

// export default NotificationCard;
