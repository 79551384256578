import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AxiosInstance from "../../apis/AxiosInstance";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1B4242",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function PointsAndScriptsList() {
  const [pointsList, setPointList] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPoints, setTotalPoints] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [load, setLoad] = useState(false);
  useEffect(() => {
    let fetchPoints = async (_) => {
      setLoad(true);
      try {
        let pointsData = await AxiosInstance.get(
          `/get_points_and_script?page=${page}`
        );
        setTotalPoints(pointsData.data.total_length);
        let finalData = pointsData.data.data;
        if (finalData !== null) {
          setPointList(finalData);
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
      }
    };
    fetchPoints();
  }, [page]);

  const totalPages = Math.ceil(totalPoints / 50);

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  return (
    <section style={{ width: "100%", marginTop: "4.1%" }}>
      <Box
         sx={{
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
         sx={{
          display: "flex",
          alignItems: "center",
          color: "#081f37",
          fontSize: "25px",
          textTransform: "capitalize",
          fontWeight: "600",
          letterSpacing: "1.02px",
          margin: "0 auto",
          justifyContent: "center",
        }}
        >
          Points And Scripts List
        </Box>       
        <h1
          style={{
            color: "#06113C",
            fontSize: "17px",
            fontWeight: "bold",
            padding: "0 100px",
          }}
        >
          Total Count: {totalPoints}
        </h1>
      </Box>
      <section
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          whiteSpace: "nowrap",
          // border: "1px solid #111",
          borderRadius: "10px",
          marginTop: "0.5%",
        }}
      >
        <TableContainer component={Paper}   style={{ maxHeight: window.innerWidth > 1800 ? 690 : 540 }}>
          <Table
            sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead sx={{ backgroundColor: "#1B4242" }}>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "70px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Sl.No
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "400px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Script Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "250px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Category Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "250px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Main Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "250px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Sub Status
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "250px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Point Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "150px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Marks
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#1B4242",
                    fontWeight: "600",
                    fontSize: "17px",
                    width: "150px !important",
                    whiteSpace: "normal",
                  }}
                >
                  Edit
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {load ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={7} align="center">
                    Loading...
                  </StyledTableCell>
                </StyledTableRow>
              ) : pointsList.length > 0 ? (
                pointsList.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                      {calculateSlNo(ind)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ fontWeight: "bold", whiteSpace: "normal",
                        height: "auto", }}>
                      {val.script}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center", whiteSpace: "normal",
                      height: "auto", }}
                    >
                      {val.call_substatus.category_id.name}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center", whiteSpace: "normal",
                      height: "auto", }}
                    >
                      {val.call_substatus.call_status.main_status}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center", whiteSpace: "normal",
                      height: "auto", }}
                    >
                      {val.call_substatus.call_status.sub_status.sub_status}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        whiteSpace: "normal",
                        height: "auto",
                      }}
                    >
                      {Object.keys(val.points).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(val.points).map((ea, index) => (
                          <Box key={index}>{ea?.["point"]}</Box>
                        ))
                      )}
                    </StyledTableCell>

                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center", whiteSpace: "normal",
                      height: "auto", }}
                    >
                      {Object.keys(val.points).length < 1 ? (
                        <Box style={{ color: "red" }}>N/A</Box>
                      ) : (
                        Object.values(val.points).map((ea) => {
                          return (
                            <Box >
                              {ea?.["marks"]}
                            </Box>
                          );
                        })
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title="Edit Points And Scripts">
                        <Link to="/edit_points_and_scripts" state={val}>
                          <FaEdit
                            fontSize={18}
                            color="#537FE7"
                            style={{
                              cursor: "pointer",
                              width: "50px",
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={8}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "45%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </section>
  );
}
