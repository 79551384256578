import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Tooltip,
  Autocomplete,
  Box,
  Grid,
  LinearProgress,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AxiosInstance from "../../../apis/AxiosInstance";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RiFilterOffFill } from "react-icons/ri";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#A1BFE0",
    color: "#081f37",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

export default function EditSpocCollegeList() {
  let { state } = useLocation();

  let navigate = useNavigate();
  const [colgStateOptions, setColgStateOptions] = useState([]);
  const [colgDistrictOptions, setcolgDistrictOptions] = useState([]);
  const [selectedColgState, setSelectedColgState] = useState(null);
  const [selectedColgDistrict, setselectedColgDistrict] = useState(null);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [selectedCollege, setSelectedCollege] = useState(null);
  const [selectedCollegeOptions, setSelectedCollegeOptions] = useState([]);
  const [error, setError] = useState(false);

  const [stateSearch, setStateSearch] = useState("");
  const [distSearch, setDistSearch] = useState("");
  const [collSearch, setCollSearch] = useState(null);
  let [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [totalColleges, setTotalColleges] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const params = {
    state_id: selectedColgState !== null ? selectedColgState.id : null,
    district_id: selectedColgDistrict !== null ? selectedColgDistrict.id : null,
    id: selectedCollege !== null ? selectedCollege.id : null,
  };

  const handleSearch = () => {
    const validParams = Object.keys(params).filter(
      (key) => params[key] !== null
    );
    const generateURL_Query = validParams
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    setQuery(generateURL_Query);
  };

  // //! Fetch the college
  let [searchedColleges, SetsearchedColleges] = useState([]);
  useEffect(() => {
    let fetchCollege = async (_) => {
      if (query !== "") {
        try {
          let collegeData = await AxiosInstance.get(
            `/appointmentcollege?${query}&page=${page}`
          );
          setTotalColleges(collegeData.data.filtered_count);
          let finalCollege = collegeData.data.data;
          SetsearchedColleges(finalCollege);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let collegeData = await AxiosInstance.get(
            `/appointmentcollege?page=${page}`
          );
          setTotalColleges(collegeData.data.filtered_count);
          let finalCollege = collegeData.data.data;
          SetsearchedColleges(finalCollege);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchCollege();
  }, [page, query]);

  const totalPages = Math.ceil(totalColleges / 10);
  const [spocDetails, setSpocDetails] = useState([]);
  useEffect(() => {
    let fetchData = async () => {
      let Cdata = await AxiosInstance.get(`/countrymini?name=india`);
      let { data } = await AxiosInstance.get(
        `/college_spoc?college_id=${state.id}`
      );
      setSpocDetails(data.data.length > 0 ? data.data.map((e) => e.id) : []);
      let finalData = Cdata.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if(stateSearch !== ""){
  //college state api
  let colgStateData = await AxiosInstance.get(
    `/statemini?search_keyword=${stateSearch}&limit=10&country_id=${countryMiniId}`
  );
  let finalcolgStateData = colgStateData.data.data;
  setColgStateOptions(finalcolgStateData);
      }else{
          //college state api
      let colgStateData = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalcolgStateData = colgStateData.data.data;
      setColgStateOptions(finalcolgStateData);
      }
    
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [stateSearch, countryMiniId]);

  //! for college district
  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgState !== null) {
        if(distSearch !== ""){
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?search_keyword=${distSearch}&limit=10&state_id=${selectedColgState.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        }else{
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?limit=10&state_id=${selectedColgState.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setcolgDistrictOptions(finalcolgDistrictData);
        }
 
      } 
    };
    fetchData();
  }, [distSearch, selectedColgState]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedColgDistrict !== null) {
        if (collSearch !== null) {
          let collegeData = await AxiosInstance.get(
            `/college_mini?search_keyword=${collSearch}&limit=10&district_id=${selectedColgDistrict.id}`
          );
          let finalCollegeData = collegeData.data.data;

          setSelectedCollegeOptions(finalCollegeData);
        } else {
          let collegeData = await AxiosInstance.get(
            `/college_mini?limit=10&district_id=${selectedColgDistrict.id}`
          );
          let finalCollegeData = collegeData.data.data;
          setSelectedCollegeOptions(finalCollegeData);
        }
      }
    };
    fetchData();
  }, [collSearch, selectedColgDistrict]);

  let ResetFilterData = (e) => {
    e.preventDefault();
    setSelectedColgState(null);
    setselectedColgDistrict(null);
    setSelectedCollege(null);
    window.location.href = "/editspoc_college";
  };

  const [quizList, setQuizList] = useState([]);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setQuizList([...quizList, id]);
    } else {
      setQuizList(quizList.filter((row) => row !== id));
    }
  };

  const handleSubmit = async (e) => {
    if (spocDetails.length > 0) {
      let payload = {
        id: spocDetails,
        college_id: quizList,
      };
      try {
        await AxiosInstance.patch("/college_spoc", payload)
          .then((response) => {
            toast.success("Successfully Added the College to Spoc");
            setTimeout(() => {
              navigate("/");
            }, 1600);
          })
          .catch((error) => {
            toast.error(error.response.data.error);
          });
      } catch (error) {
        toast.error(error.response.data.error);
      }
    } else {
      toast.error("No Spoc dtails found");
    }
  };

  return (
    <main>
      <Grid container style={{ marginTop: "4%" }}>
        <Grid item lg={3}>
          <Item
            onChange={(e) => {
              setStateSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={colgStateOptions}
              value={selectedColgState}
              name="state_name"
              onChange={(event, newColgState) => {
                setSelectedColgState(newColgState);
                setselectedColgDistrict(null);
                setSelectedCollege(null);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Colleges By State"
                  variant="outlined"
                  required
                  error={error && !selectedColgState}
                  helperText={
                    error && !selectedColgState
                      ? "Please select College State"
                      : null
                  }
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3}>
          <Item
            onChange={(e) => {
              setDistSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={colgDistrictOptions}
              value={selectedColgDistrict}
              name="state_name"
              onChange={(event, newColgdis) => {
                setselectedColgDistrict(newColgdis);
                setSelectedCollege(null);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Colleges By District"
                  variant="outlined"
                  required
                  error={error && !selectedColgDistrict}
                  helperText={
                    error && !selectedColgDistrict
                      ? "Please select District"
                      : null
                  }
                />
              )}
            />
          </Item>
        </Grid>
        <Grid item md={3}>
          <Item
            onChange={(e) => {
              setCollSearch(e.target.value);
            }}
          >
            <Autocomplete
              required
              options={selectedCollegeOptions}
              value={selectedCollege}
              name="college_name"
              onChange={(event, newcollege) => {
                setSelectedCollege(newcollege);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search College"
                  variant="outlined"
                  required
                  error={error && !selectedCollege}
                  helperText={
                    error && !selectedCollege ? "Please select College" : null
                  }
                />
              )}
            />
          </Item>
        </Grid>
        <Grid
          item
          md={1.2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            onClick={() => {
              handleSearch();
            }}
            style={{
              fontSize: "16px",
              color: "#fff",
              padding: "4px 30px",
              cursor: "pointer",
              backgroundColor: "#6C9BCF",
              borderRadius: "40px",
              border: "2px solid #efefef",
            }}
          >
            Search
          </button>
        </Grid>
        <Grid
          item
          md={1.2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            type="button"
            style={{
              fontSize: "16px",
              color: "black",
              padding: "4px 10px",
              // width: "50%",
              cursor: "pointer",
              backgroundColor: "#FF7D7D",
              borderRadius: "40px",
              border: "2px solid #efefef",
            }}
            onClick={ResetFilterData}
          >
            Reset Filters{" "}
            <span>
              <RiFilterOffFill fontSize={16} />
            </span>
          </button>
        </Grid>
      </Grid>
      <Grid>
        <h1 style={{ marginLeft: "40%", marginTop: "0%", color: "#495579" }}>
          Total Colleges : {totalColleges} &nbsp;&nbsp;
        </h1>
      </Grid>

      <section style={{ marginTop: "1%" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "95%",
            overflowY: "auto",
            whiteSpace: "nowrap",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead
              style={{
                color: "#081f37",
                fontWeight: "600",
              }}
            >
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  Select Colleges
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  State Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  District Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  College Name
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "15px", color: "white" }}
                >
                  University Name
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedColleges.length > 0 ? (
                searchedColleges.map((val, ind) => (
                  <StyledTableRow key={ind + 1}>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Checkbox
                        disableRipple
                        name={`chk${val.id}`}
                        checked={quizList.includes(val.id)}
                        value={val.id}
                        onChange={(event) =>
                          handleCheckboxChange(event, val.id)
                        }
                        style={{
                          padding: "3px 10px 3px 20px",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.state_name} placement="bottom">
                        <p> {val.state_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.district_name} placement="bottom">
                        <p> {val.district_name}</p>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.name} placement="bottom">
                        <p> {val.name}</p>
                      </Tooltip>
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <Tooltip title={val.university_name} placement="bottom">
                        <p>
                          {val.university_name === null
                            ? "N/A"
                            : val.university_name}
                        </p>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <h1 style={{ color: "red", textAlign: "center" }}>
                      No Data Found
                    </h1>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {quizList.length > 0 && (
          <div style={{ marginLeft: "50%", marginTop: "1%" }}>
            <p style={{ fontWeight: "bold" }}>
              {quizList.length === 1
                ? quizList.length + " College selected"
                : quizList.length + " Colleges selected"}
            </p>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Proceed
              </Button>
            </Box>
          </div>
        )}
        <Pagination
          style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
          count={totalPages}
          sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
          page={page}
          onChange={handleChangePage}
        />
      </section>
    </main>
  );
}
