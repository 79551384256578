import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Container,
  Paper,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../apis/AxiosInstance";

export default function CreateNotificationType() {
  const navigate = useNavigate();
  const [notificationName, setNotificationName] = useState("");
  const [description, setDescription] = useState("");
  const [baseUrl, setBaseUrl] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    let payload = {
      name: notificationName,
      description: description,
      base_url: baseUrl,
    };

    try {
      await AxiosInstance.post(`/notification_types`, payload)
        .then(response => {
          toast.success("Notification Type Successfully");
          setTimeout(() => {
            navigate("/");
          }, 1500);
        })
        .catch(error => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <section style={{ width: "100%", height: "auto",marginTop:"5%" }}>
      <article
        style={{
          width: "100%",  
          
          margin: "0 auto",
          // marginTop: "5%",
        }}
      >
        <Box
           sx={{
            width: "70%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            justifyContent: "space-between",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
           sx={{
            display: "flex",
            alignItems: "center",

            color: "#081f37",
            fontSize: {
              xs: "20px", // Smaller font size on small screens
              sm: "25px", // Medium font size on small to medium screens
              md: "30px", // Larger font size on medium to large screens
            },
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
            justifyContent: "center",
            margin: "0 auto",
          }}
          >
            Create Notification Type
          </Box>
        </Box>
      
          <Paper
            variant="outlined"
            style={{ border: "1px solid #9BA4B5", width: "70%",margin: "0 auto",borderRadius:"10px"}}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "3%",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold" }}
                  >
                    Notification Name :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)", 
                            },
                          },
                        }}
                        label="Enter Notification Name"
                        type="text"
                        fullWidth
                        name="notificationName"
                        value={notificationName}
                        require
                        onChange={e => setNotificationName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold" }}
                  >
                    Description :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                       sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)", 
                          },
                        },
                      }}
                        label="Enter Description"
                        multiline
                        maxRows={3}
                        type="text"
                        fullWidth
                        name="description"
                        value={description}
                        require
                        onChange={e => setDescription(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex",fontWeight:"bold" }}
                  >
                    Base URL :
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl style={{ width: "80%" }}>
                      <TextField
                         sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)", 
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)", 
                            },
                          },
                        }}
                        label="Enter BaseURL"
                        type="text"
                        fullWidth
                        name="baseUrl"
                        value={baseUrl}
                        require
                        onChange={e => setBaseUrl(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 5, mb: 2 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ width: "15%", backgroundColor: "crimson" }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ width: "15%" }}
                  >
                    Assign
                  </Button>
                </Grid>
              </Box>
            </form>
          </Paper>
       
      </article>
    </section>
  );
}
