import React from "react";
import { PostContextProvider } from "./bdTree/ContextApi";
import CountAppointToAddressing from "./spiltingBdTree/treeCounts/CountAppointToAddressing";

const LandingPage_2 = () => {
  return (
    <div
      id="landingPageb2"
      style={{
        display: "flex",
        width: "auto",
        flexDirection: "column",
      }}
    >
      <PostContextProvider>
        <div style={{overflowY:"auto"}}>
          <CountAppointToAddressing/>
        </div>
      </PostContextProvider>
    </div>
  );
};

export default LandingPage_2;
