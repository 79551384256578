import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  FormControlLabel,
  Chip,
  Button,
  TextField,
  Grid,
  Box,
  Container,
  Paper,
  Checkbox,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Styles from "./SpocCreation.module.css";
import { useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import Cookies from "js-cookie";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { role: [{ id: "" }] };
  const { id } = userData.role[0];
  return id.toString();
};
const role = parseLog(Cookies.get("logged"));

export default function EditSpocCreation() {
  let spocDetail = useLocation();
  //!MUI THEME
  const theme = useTheme();
  //!AUTOCOMPLETE OPTIONS STORE
  const [uniOption, setUniOption] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [collegeOption, setCollegeOption] = useState([]);
  const [designationOption, setDesignationOption] = useState([]);
  const [designationOption_1, setDesignationOption_1] = useState([]);
  // const [uniStataeOptions, setUniStataeOptions] = useState([]);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  //!EMAIL VALIDATION STATE
  const [isValid, setIsValid] = useState(false);
  const [isValid_1, setIsValid_1] = useState(false);

  //!SEARCH
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDesignation, setSearchDesignation] = useState("");
  const [searchDesignation_1, setSearchDesignation_1] = useState("");
  // const [uniSeacrh, setUniSeacrh] = useState("a");
  const [searchDistrict, setSearchDistrict] = useState("");
  const [searchUniversity, setSearchUniversity] = useState("");
  const [searchCollege, setSearchCollege] = useState("");

  //!FORM STATE VALUES
  //navigation
  const Navigate = useNavigate();

  //college spoc name
  const [name, setName] = useState("");
  const [name_1, setName_1] = useState("");
  //comment
  const [comment, setComment] = useState("");
  const [comment_1, setComment_1] = useState("");
  //university
  const [uniData, setUniData] = useState(null);

  //state
  const [stateData, setStateData] = useState(null);

  //district
  const [districtData, setDistrictData] = useState(null);

  //college
  const [collegeDataa, setCollegeDataa] = useState([]);
  const [selectedUniState, setSelectedUniState] = useState(null);

  //designation
  const [designationData, setDesignationData] = useState(null);
  const [designationData_1, setDesignationData_1] = useState(null);
  //our programme
  const [programmeData, setProgrammeData] = useState("");
  //Primary spoc checkbox
  const [primarySpoc, setChecked] = React.useState(false);
  const [primarySpoc1, setChecked1] = useState(false);
  //Landline
  let [landlines, setLandlines] = useState([{ landline: null }]);

  let [landlines1, setLandlines1] = useState([{ landline: null }]);
  const [error, setError] = useState(false);
  //Personal Mobile  Number
  let [mobile, setmobile] = React.useState([
    {
      mobile: "",
      is_personal: false,
    },
  ]);

  //Personal Email
  let [email, setEmail] = React.useState([
    {
      email: "",
      is_personal: false,
    },
  ]);

  //Personal Mobile  Number1
  let [mobile1, setmobile1] = React.useState([
    {
      mobile: "",
      is_personal: false,
    },
  ]);

  //Personal Email1
  let [email1, setEmail1] = React.useState([
    {
      email: "",
      is_personal: false,
    },
  ]);

  //!ERROR MESSAGE STATES
  let [errorForUni, setErrorForUni] = React.useState(false);
  let [errorForState, setErrorForState] = React.useState(false);
  let [errorForDistrict, setErrorForDistrict] = React.useState(false);
  let [errorForCollege, seterrorForCollege] = React.useState(false);
  let [errorForProgramme, setErrorForProgramme] = React.useState(false);
  let [errorForDesignation_1, setErrorForDesignation_1] = React.useState(false);
  let [errorForDesignation_2, setErrorForDesignation_2] = React.useState(false);

  // let searchUniState = async v => {
  //   v.target.value === "" ? setUniSeacrh("a") : setUniSeacrh(v.target.value);
  // };

  //!EMAIL VALIDATION FUNVTION

  const handleResetForm = () => {
    setName("");
    setName_1("");
    setComment("");
    setComment_1("");
    setUniData(null);
    setStateData(null);
    setDistrictData(null);
    setCollegeDataa(null);
    setDesignationData(null);
    setDesignationData_1(null);
    setProgrammeData("");
    setChecked(false);
    setChecked1(false);
    setLandlines([{ landline: null }]);
    setLandlines1([{ landline: null }]);
    setEmail([{ email: "", is_personal: false }]);
    setEmail1([
      {
        email: "",
        is_personal: false,
      },
    ]);
    setmobile([
      {
        mobile: "",
        is_personal: false,
      },
    ]);
    setmobile1([
      {
        mobile: "",
        is_personal: false,
      },
    ]);
  };

  //!DISABLE FORM SUBMIT BUTTON
  let handleDisableForm =
    name == "" ||
    // name_1 == "" ||
    // comment_1 == "" ||
    uniData == null ||
    stateData == null ||
    districtData == null ||
    collegeDataa == [] ||
    designationData == null ||
    // designationData_1 == null ||
    programmeData == "" ||
    landlines == [{ landline: null }] ||
    // landlines1 == [{ landline: "" }] ||
    mobile ==
      [
        {
          mobile: "",
          is_personal: false,
        },
      ] ||
    // mobile1 ==
    //   [
    //     {
    //       mobile: "",
    //       is_personal: false,
    //     },
    //   ] ||
    email ==
      [
        {
          email: "",
          is_personal: false,
        },
      ];
  // ||
  // email1 ==
  //   [
  //     {
  //       email: "",
  //       is_personal: false,
  //     },
  //   ];

  // const handleChange_22 = event => {
  //   setProgrammeData(event.target.value);

  // };

  const [state, setState] = useState({
    primarySpoc: "",
    primarySpoc1: "",
    college_contact_details: "",
    college_contact_details1: "",
  });

  //! ? handle change for Landline
  const handleLandChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...landlines];
    list[index][name] = value;
    setLandlines(list);
  };

  const handleRemoveLand = (index) => {
    const list = [...landlines];
    list.splice(index, 1);
    setLandlines(list);
  };
  const handleAddLand = () => {
    setLandlines([...landlines, { landline: null }]);
  };

  //! handle change for LandLine1

  //! ? handle change for Landline
  const handleLandChange1 = (val, index) => {
    let { name, value } = val.target;
    let list = [...landlines1];
    list[index][name] = value;
    setLandlines1(list);
  };

  const handleRemoveLand1 = (index) => {
    const list = [...landlines1];
    list.splice(index, 1);
    setLandlines1(list);
  };
  const handleAddLand1 = () => {
    setLandlines1([...landlines1, { landline: null }]);
  };

  //! ? handle change for mobile
  const handleMobChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...mobile];
    list[index][name] = value;
    setmobile(list);
  };

  let handlepersonalMobChange = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...mobile];
    list[ind][name] = checked;
    setmobile(list);
  };

  const handleRemoveMob = (index) => {
    const list = [...mobile];
    list.splice(index, 1);
    setmobile(list);
  };
  const handleAddMob = () => {
    setmobile([
      ...mobile,
      {
        mobile: "",
        is_personal: false,
      },
    ]);
  };

  //! ? handle change for mobile1
  const handleMobChange1 = (val, index) => {
    let { name, value } = val.target;
    let list = [...mobile1];
    list[index][name] = value;
    setmobile1(list);
  };

  let handlepersonalMobChange1 = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...mobile1];
    list[ind][name] = checked;
    setmobile1(list);
  };

  const handleRemoveMob1 = (index) => {
    const list = [...mobile1];
    list.splice(index, 1);
    setmobile1(list);
  };
  const handleAddMob1 = () => {
    setmobile1([
      ...mobile1,
      {
        mobile: "",
        is_personal: false,
      },
    ]);
  };

  //! ? handle change for Email
  const handleEmailChange = (val, index) => {
    let { name, value } = val.target;
    if (value !== "") {
      let list = [...email];
      list[index][name] = value;
      setEmail(list);
    } else {
      let list = [...email];
      list[index][name] = value;
      setEmail(list);
    }
  };

  let handlepersonalEmailChange = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...email];
    list[ind][name] = checked;
    setEmail(list);
  };

  const handleRemoveEmail = (index) => {
    const list = [...email];
    list.splice(index, 1);
    setEmail(list);
  };
  const handleAddEmail = () => {
    setEmail([
      ...email,
      {
        email: "",
        is_personal: false,
      },
    ]);
  };

  //! ? handle change for Email1
  const handleEmailChange1 = (val, index) => {
    let { name, value } = val.target;
    if (value !== "") {
      let list = [...email1];
      list[index][name] = value;
      setEmail1(list);
    } else {
      let list = [...email1];
      list[index][name] = value;
      setEmail1(list);
    }
  };

  let handlepersonalEmailChange1 = (val, ind) => {
    let { name, checked } = val.target;
    let list = [...email1];
    list[ind][name] = checked;
    setEmail1(list);
  };

  const handleRemoveEmail1 = (index) => {
    const list = [...email1];
    list.splice(index, 1);
    setEmail1(list);
  };
  const handleAddEmail1 = () => {
    setEmail1([
      ...email1,
      {
        email: "",
        is_personal: false,
      },
    ]);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  //! textfiled validation name

  const handleInputChange = (e) => {
    const { value } = e.target;
    const lettersOnlyRegex = /^[a-zA-Z\s]+$/;

    if (!lettersOnlyRegex.test(value)) {
      // If the input contains non-letter characters, remove them
      setName(value.replace(/[^a-zA-Z\s]/g, ""));
    } else {
      setName(value);
    }
  };

  //! textfiled validation name1

  const handleInputChange1 = (e) => {
    const { value } = e.target;
    const lettersOnlyRegex1 = /^[a-zA-Z\s]*$/;

    if (!lettersOnlyRegex1.test(value)) {
      // If the input contains non-letter characters, remove them
      setName_1(value.replace(/[^a-zA-Z\s]/g, ""));
    } else {
      setName_1(value);
    }
  };

  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange2 = (event) => {
    setChecked1(event.target.checked);
  };

  //! Add Page

  let [addPage, setAddPage] = useState(false);

  let handleAddPageClick = () => {
    setAddPage(!addPage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      let payload;

      if (addPage === false) {
        payload = {
          college_id: [collegeDataa.id],
          state_id: stateData.id,
          district_id: districtData.id,
          university_id: uniData.id,
          programme_id: programmeData == "Incubation" ? 1 : 2,
          college_contact_details: [
            {
              name: name,
              email: email,
              mobile: mobile,
              landline: landlines,
              designation_id: designationData.ID,
              comment: comment,
              is_primary_spoc: 1,
            },
          ],
        };
      } else {
        payload = {
          college_id: [collegeDataa.id],
          state_id: stateData.id,
          district_id: districtData.id,
          university_id: uniData.id,
          programme_id: programmeData == "Incubation" ? 1 : 2,
          college_contact_details: [
            {
              name: name,
              email: email,
              mobile: mobile,
              landline: landlines,
              designation_id: designationData.ID,
              comment: comment,
              is_primary_spoc: primarySpoc == true ? 1 : 2,
            },
            {
              name: name_1,
              email: email1,
              mobile: mobile1,
              landline: landlines1,
              designation_id: designationData_1.ID,
              comment: comment_1,
              is_primary_spoc: primarySpoc1 == true ? 1 : 2,
            },
          ],
        };
      }
      await AxiosInstance.post("/college_spoc", payload, {
        "content-type": "multipart/form-data",
        Authorization: `${token}`,
      })
        .then((response) => {
          // response.data.data[0];
          if (response.status == 200) {
            toast.success("Spoc Created Successfully");
            setTimeout(() => {
              Navigate("/college_appointmentspoc", {
                state: {
                  data: response.data.data[0],
                },
              });
            }, 1000);
          } else if (response.status == 401) {
            Navigate("/login", {
              state: {
                data: response.data.data[0],
              },
            });
          }
        })
        .catch((error) => {
          // toast.error(error.response.data.error1);
          // toast.error(error.response.data.error2);
          // toast.error(error.response.data.error3);
          // toast.error(error.response.data.error4);
          // toast.error(error.response.data.error5);
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.code);
    }
  };

  let searchData = async (e) => {
    if (e.target.value === "") {
      setSearchTerm("a");
    } else {
      setSearchTerm(e.target.value);
    }
  };

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);
  //!fetching the apis
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchTerm !== "") {
        // State api call
        let stateData_x = await AxiosInstance.get(
          `/statemini?search_keyword=${searchTerm}&limit=10&country_id=${countryMiniId}`
        );
        let finalStateData_X = stateData_x.data.data;
        setStateOption(finalStateData_X);
      } else {
        // State api call
        let stateData_x = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalStateData_X = stateData_x.data.data;
        setStateOption(finalStateData_X);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [searchTerm, countryMiniId]);

  useEffect(() => {
    let fetchData = async () => {
      if (searchDesignation !== "") {
        //Designation api call
        let designationApi = await AxiosInstance.get(
          "/spoc_designation?search_keyword=${searchDesignation}"
        );
        let finalDesignation = designationApi.data.data;
        setDesignationOption(finalDesignation);
      } else {
        //Designation api call
        let designationApi = await AxiosInstance.get("/spoc_designation");
        let finalDesignation = designationApi.data.data;
        setDesignationOption(finalDesignation);
      }
    };
    fetchData();
  }, [searchDesignation]);

  useEffect(() => {
    let fetchData = async () => {
      if (searchDesignation_1 !== "") {
        //Designation api call_1
        let designationApi_1 = await AxiosInstance.get(
          "/spoc_designation?search_keyword=${searchDesignation_1}"
        );
        let finalDesignation_1 = designationApi_1.data.data;
        setDesignationOption_1(finalDesignation_1);
      } else {
        //Designation api call_1
        let designationApi_1 = await AxiosInstance.get("/spoc_designation");
        let finalDesignation_1 = designationApi_1.data.data;
        setDesignationOption_1(finalDesignation_1);
      }
    };
    fetchData();
  }, [searchDesignation_1]);

  //! for district filter from state
  useEffect(() => {
    let fetchData = async (e) => {
      if (stateData !== null) {
        if (searchDistrict !== "") {
          let colgDistrictData = await AxiosInstance.get(
            // `/district_mini?search_keyword=${searchTerm}&limit=10&state_id=${stateData.id}&is_other_fields=false`
            `/district_mini?search_keyword=${searchDistrict}&limit=10&state_id=${stateData.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setDistrictOption(finalcolgDistrictData);
        } else {
          let colgDistrictData = await AxiosInstance.get(
            // `/district_mini?search_keyword=${searchTerm}&limit=10&state_id=${stateData.id}&is_other_fields=false`
            `/district_mini?limit=10&state_id=${stateData.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setDistrictOption(finalcolgDistrictData);
        }
      }
    };
    fetchData();
  }, [searchDistrict, stateData]);

  //!for college filter from district and university
  useEffect(() => {
    let fetchData = async (e) => {
      if (uniData !== null && districtData !== null) {
        if (searchCollege !== "") {
          let collegeStateData = await AxiosInstance.get(
            `/college_mini?search_keyword=${searchCollege}&limit=10&district_id=${districtData.id}&university_id=${uniData.id}`
          );
          let finalCollegeStateData = collegeStateData.data.data;
          setCollegeOption(finalCollegeStateData);
        } else {
          let collegeStateData = await AxiosInstance.get(
            `/college_mini?limit=10&district_id=${districtData.id}&university_id=${uniData.id}`
          );
          let finalCollegeStateData = collegeStateData.data.data;
          setCollegeOption(finalCollegeStateData);
        }
      }
    };
    fetchData();
  }, [searchCollege, districtData, uniData]);

  useEffect(() => {
    // university api filter based on state
    let fetchStateUni = async (e) => {
      if (searchUniversity !== "") {
        let uniData_x = await AxiosInstance.get(
          // `/university?search_keyword=${searchUniversity}&limit=10&state_id=${stateData.id}&is_other_fields=false`
          `/university?search_keyword=${searchUniversity}&limit=10`
        );
        let finalUniData_X = uniData_x.data.data;
        setUniOption(finalUniData_X);
      } else {
        let uniData_x = await AxiosInstance.get(`/university?limit=10`);
        let finalUniData_X = uniData_x.data.data;
        setUniOption(finalUniData_X);
      }
    };
    fetchStateUni();
  }, [searchUniversity]);

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <section style={{ margin: "6%" }}>
      <Container maxWidth="lg">
        <Paper
          sx={{}}
          variant="outlined"
          // smx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
        >
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                height: "6vh",
                width: "100%",
                background: "#DDE6ED",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: "2px solid #9BA4B5",
              }}
            >
              <Box
                sx={{
                  flexBasis: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color:
                    role === "1"
                      ? "#081f37"
                      : role === "5"
                      ? "#06113C"
                      : "#081f37",
                  fontSize: "34px",
                  textTransform: "capitalize",
                  fontWeight: "600",
                  letterSpacing: "1.02px",
                }}
              >
                EDIT SPOC CREATION
              </Box>
            </Box>
            <form
              onSubmit={handleSubmit}
              style={{
                // marginTop: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "1%",
              }}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <Item onChange={searchData}>
                      {/* <Autocomplete
                        options={stateOption}
                        value={stateData}
                        name="stateOption"
                        onChange={(event, newData_xy) => {
                          setStateData(newData_xy);
                          setErrorForState(newData_xy === null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search State"
                            variant="outlined"
                            error={errorForState}
                            helperText={
                              errorForState ? "Please select a state" : ""
                            }
                            required
                          />
                        )}
                      /> */}
                      <TextField
                        value={spocDetail.state.state_name}
                        onChange={() => {
                          setStateData(spocDetail.state.state_name);
                        }}
                        fullWidth
                        disabled
                        label="State"
                      />
                    </Item>
                  </Grid>
                  <Grid item sm={4}>
                    <Item
                      onChange={(e) => {
                        setSearchDistrict(e.target.value);
                      }}
                    >
                      {/* <Autocomplete
                        options={districtOption}
                        value={districtData}
                        name="districtOption"
                        onChange={(event, newData_xy) => {
                          setDistrictData(newData_xy);
                          setErrorForDistrict(newData_xy === null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search City"
                            variant="outlined"
                            error={errorForDistrict}
                            helperText={
                              errorForDistrict ? "Please select a city" : ""
                            }
                            required
                          />
                        )}
                      /> */}
                      <TextField
                        value={spocDetail.state.district_name}
                        onChange={() => {
                          setDistrictData(spocDetail.state.district_name);
                        }}
                        fullWidth
                        disabled
                        label="City"
                      />
                    </Item>
                  </Grid>
                  {/* <Grid item sm={4}>
                  <Item onChange={searchUniState}>
                    <Autocomplete
                      required
                      options={uniStataeOptions}
                      value={selectedUniState}
                      name="state_name"
                      onChange={(event, newColgState) => {
                        setStateData(newColgState);
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Search University State"
                          variant="outlined"
                          required
                          error={error && !selectedUniState}
                          helperText={
                            error && !selectedUniState
                              ? "Please select State"
                              : null
                          }
                        />
                      )}
                    />
                  </Item>
                </Grid> */}
                  <Grid item sm={4}>
                    <Item
                      onChange={(e) => {
                        setSearchUniversity(e.target.value);
                      }}
                    >
                      {/* <Autocomplete
                        options={uniOption}
                        value={uniData}
                        name="uniOption"
                        onChange={(event, newData_xy) => {
                          setUniData(newData_xy);
                          setErrorForUni(newData_xy === null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search University"
                            variant="outlined"
                            error={errorForUni}
                            helperText={
                              errorForUni ? "Please select a university" : ""
                            }
                            required
                          />
                        )}
                      /> */}
                      <TextField
                        value={spocDetail.state.university_name}
                        onChange={() => {
                          setUniData(spocDetail.state.university_name);
                        }}
                        fullWidth
                        disabled
                        label="University"
                      />
                    </Item>
                    {/* ========================================== */}
                    {/* <Autocomplete
                      required={uniData !== null}
                      options={uniOption}
                      value={uniData}
                      name="uniOption"
                      onChange={(event, newData_xy) => {
                        setUniData(newData_xy);
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="*Search University"
                          variant="outlined"
                        />
                      )}
                    /> */}

                    {/* <Autocomplete
                      required={uniData !== null}
                      options={uniOption}
                      value={uniData}
                      name="uniOption"
                      onChange={(event, newData_xy) => {
                        setUniData(newData_xy);
                        setErrorForUni(newData_xy === null);
                      }}
                      getOptionLabel={option => option.name}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="*Search University"
                          variant="outlined"
                          error={errorForUni}
                          helperText={
                            errorForUni ? "Please select a university" : ""
                          }
                        />
                      )}
                    /> */}
                    {/* ============================================= */}
                  </Grid>

                  <Grid item sm={4}>
                    {/* ========================================== */}
                    <Item
                      onChange={(e) => {
                        setSearchCollege(e.target.value);
                      }}
                    >
                      {/* <Autocomplete
                        options={collegeOption}
                        value={collegeDataa}
                        name="collegeOption"
                        onChange={(event, newData_xyc) => {
                          setCollegeDataa(newData_xyc);
                          seterrorForCollege(newData_xyc === null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search College"
                            variant="outlined"
                            error={errorForCollege}
                            helperText={
                              errorForCollege ? "Please select College" : ""
                            }
                            required
                          />
                        )}
                      /> */}
                      <Autocomplete
                        multiple
                        required
                        // size="small"
                        value={collegeDataa}
                        onChange={(event, newValue) => {
                          setCollegeDataa([...newValue]);
                          seterrorForCollege(newValue === []);
                        }}
                        options={collegeOption}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              key={index}
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select College"
                            color="warning"
                            error={errorForCollege}
                            helperText={
                              errorForCollege ? "Please select a college" : ""
                            }
                          />
                        )}
                      />
                    </Item>
                  </Grid>
                  <Grid item sm={4}>
                    <Item>
                      <FormControl sx={{ m: 0, width: 340 }}>
                        <InputLabel id="demo-multiple-name-label">
                          Our Programme
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          value={programmeData}
                          onChange={(event) => {
                            setProgrammeData(event.target.value);
                            setErrorForProgramme(setProgrammeData == "");
                          }}
                          input={<OutlinedInput label="Our Programme" />}
                          MenuProps={MenuProps}
                          error={errorForProgramme}
                          helperText={
                            errorForProgramme ? "Please select Programme" : ""
                          }
                        >
                          <MenuItem value="Incubation">Incubation</MenuItem>
                          {/* {names.map(name => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, programmeData, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))} */}
                        </Select>
                      </FormControl>
                    </Item>
                  </Grid>

                  <Paper
                    item
                    sm={12}
                    style={{ marginLeft: "2%", position: "relative" }}
                    variant="outlined"
                    sx={{
                      my: { xs: 4, md: 4 },
                      p: { xs: 2, md: 3 },
                      width: "100%",
                      boxShadow: "1px 2px 10px #efefef",
                    }}
                  >
                    <Grid>
                      <Grid item xs={12} container>
                        <Grid item sm={12} lg={12} md={12}>
                          <Box
                            fontSize={22}
                            fontWeight={600}
                            color={
                              role === "1"
                                ? "#00337c"
                                : role === "5"
                                ? "#F86F03"
                                : "#00337c"
                            }
                          >
                            College Contact Details
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <br />
                    <Grid item container spacing={2}>
                      <Grid item xs={12} lg={4} md={4}>
                        <TextField
                          name="name"
                          required
                          fullWidth
                          id="name"
                          label="College Spoc Name"
                          value={name}
                          // onChange={e => {
                          //   setName(e.target.value);
                          // }}
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        lg={4}
                        md={4}
                        onChange={(e) => setSearchDesignation(e.target.value)}
                      >
                        {/* ========================================== */}
                        <Autocomplete
                          options={designationOption}
                          value={designationData}
                          name="designationOption"
                          onChange={(event, newData_xyc) => {
                            setDesignationData(newData_xyc);
                            setErrorForDesignation_1(newData_xyc == null);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Designation"
                              variant="outlined"
                              error={errorForDesignation_1}
                              helperText={
                                errorForDesignation_1
                                  ? "Select Your Designation"
                                  : ""
                              }
                              required
                            />
                          )}
                        />
                        {/* ============================================= */}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        // value={mobile}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="primarySpoc"
                              checked={primarySpoc}
                              onChange={handleChange1}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Primary Spoc"
                          style={{ color: "grey" }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Grid item container spacing={2}>
                      <Grid item xs={12} lg={3.8} md={3.8}>
                        {landlines.map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                              }}
                              key={index + 1}
                            >
                              <TextField
                                style={{ marginBottom: "5px" }}
                                type="tel"
                                label="Landline"
                                name="landline"
                                variant="outlined"
                                onKeyPress={preventMinus}
                                inputProps={{ minLength: 8, maxLength: 10 }}
                                // size="small"
                                fullWidth
                                value={item.landline}
                                onChange={(e) => handleLandChange(e, index)}
                              />

                              <div style={{ width: "min-content" }}>
                                {landlines.length !== 1 && (
                                  <span
                                    title="Delete Landline Number"
                                    className={Styles.btns}
                                    type="button"
                                    onClick={() => handleRemoveLand(index)}
                                  >
                                    <CancelIcon color="error" />
                                  </span>
                                )}
                                {landlines.length - 1 === index && (
                                  <span
                                    title="Add More Landline Number"
                                    className={Styles.btns}
                                    type="button"
                                    style={
                                      landlines.length < 3
                                        ? { display: "inline-block" }
                                        : { display: "none" }
                                    }
                                    onClick={handleAddLand}
                                  >
                                    <AddCircleOutlineIcon color="success" />
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </Grid>

                      <Grid item xs={4} position="relative" lg={4}>
                        {mobile.map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                              }}
                              key={index + 1}
                            >
                              <Grid>
                                <Grid item>
                                  <TextField
                                    style={{
                                      marginBottom: "5px",
                                      width: "290px",
                                      marginLeft: "16px",
                                    }}
                                    type="tel"
                                    label="Mobile"
                                    variant="outlined"
                                    onKeyPress={preventMinus}
                                    inputProps={{
                                      minLength: 10,
                                      maxLength: 10,
                                    }}
                                    fullWidth
                                    name="mobile"
                                    required
                                    value={item.mobile}
                                    onChange={(e) => handleMobChange(e, index)}
                                  />
                                </Grid>
                                <Grid item sx={{ marginLeft: "15px" }}>
                                  <Checkbox
                                    name="is_personal"
                                    value={item.is_personal}
                                    onChange={(e) => {
                                      handlepersonalMobChange(e, index);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                    style={{ marginRight: "2%" }}
                                  />
                                  Personal Number
                                </Grid>
                              </Grid>

                              <div
                                style={{
                                  width: "min-content",
                                }}
                              >
                                {mobile.length !== 1 && (
                                  <span
                                    title="Delete Mobile Number"
                                    className={Styles.btns}
                                    type="button"
                                    onClick={() => handleRemoveMob(index)}
                                  >
                                    <CancelIcon color="error" />
                                  </span>
                                )}
                                {mobile.length - 1 === index && (
                                  <span
                                    title="Add More email"
                                    className={Styles.btns}
                                    type="button"
                                    style={
                                      mobile.length < 3
                                        ? { display: "inline-block" }
                                        : { display: "none" }
                                    }
                                    onClick={handleAddMob}
                                  >
                                    <AddCircleOutlineIcon color="success" />
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </Grid>

                      <Grid item position="relative" lg={4} md={4} xs={12}>
                        {email.map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                              }}
                              key={index + 1}
                            >
                              <Grid>
                                <Grid>
                                  <TextField
                                    style={{
                                      marginBottom: "5px",
                                      width: "290px",
                                    }}
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    required
                                    value={item.email}
                                    onChange={(e) => {
                                      const regexPattern =
                                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                      handleEmailChange(e, index);
                                      return setIsValid(
                                        regexPattern.test(e.target.value)
                                      );
                                    }}
                                    helperText={
                                      email[0].email == ""
                                        ? ""
                                        : isValid
                                        ? ""
                                        : "Please enter the valid email"
                                    }
                                    error={
                                      email[0].email == ""
                                        ? ""
                                        : isValid
                                        ? ""
                                        : "Please enter the valid email"
                                    }
                                  />
                                </Grid>
                                <Grid>
                                  <Checkbox
                                    name="is_personal"
                                    value={item.is_personal}
                                    onChange={(e) => {
                                      handlepersonalEmailChange(e, index);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                    style={{ marginRight: "2%" }}
                                  />
                                  Personal Email
                                </Grid>
                              </Grid>

                              <div style={{ width: "min-content" }}>
                                {email.length !== 1 && (
                                  <span
                                    title="Delete Email "
                                    className={Styles.btns}
                                    type="button"
                                    onClick={() => handleRemoveEmail(index)}
                                  >
                                    <CancelIcon color="error" />
                                  </span>
                                )}
                                {email.length - 1 === index && (
                                  <span
                                    title="Add More email"
                                    className={Styles.btns}
                                    type="button"
                                    style={
                                      email.length < 3
                                        ? { display: "inline-block" }
                                        : { display: "none" }
                                    }
                                    onClick={handleAddEmail}
                                  >
                                    <AddCircleOutlineIcon color="success" />
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-comment-input"
                          label="Comment"
                          name="comment"
                          type="text"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          rows={2}
                          multiline
                          // style={{ width: "850px" }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    {addPage === true ? (
                      <span
                        className={Styles.addPage}
                        type="button"
                        onClick={handleAddPageClick}
                      >
                        <CancelIcon color="error" />
                      </span>
                    ) : (
                      ""
                    )}

                    <span
                      className={Styles.addPage}
                      type="button"
                      onClick={handleAddPageClick}
                      style={{ display: addPage === true ? "none" : "block" }}
                    >
                      <AddCircleOutlineIcon color="success" />
                    </span>
                  </Paper>
                  {addPage ? (
                    <Paper
                      item
                      sm={12}
                      style={{
                        marginLeft: "2%",
                        position: "relative",
                        display: addPage === true ? "block" : "none",
                      }}
                      variant="outlined"
                      sx={{
                        my: { xs: 4, md: 4 },
                        p: { xs: 2, md: 3 },
                        width: "100%",
                        boxShadow: "1px 2px 10px #efefef",
                      }}
                    >
                      <Grid>
                        <Grid item xs={12} container>
                          <Grid item sm={12} lg={12} md={12}>
                            <Box
                              fontSize={22}
                              fontWeight={600}
                              color={"#00337c"}
                            >
                              College Contact Details
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid item container spacing={2}>
                        <Grid item xs={7} md={7} lg={3.7}>
                          <TextField
                            name="college_contact_details1"
                            required
                            fullWidth
                            id="college_contact_details1"
                            label="College Spoc Name"
                            value={name_1}
                            // onChange={event => {
                            //   setName_1(event.target.value);
                            // }}
                            onChange={handleInputChange1}
                          />
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          lg={4}
                          md={4}
                          onchange={(e) =>
                            setSearchDesignation_1(e.target.value)
                          }
                        >
                          {/* ========================================== */}
                          <Autocomplete
                            options={designationOption_1}
                            value={designationData_1}
                            name="designationOption_1"
                            onChange={(event, newData_xyc) => {
                              setDesignationData_1(newData_xyc);
                              setErrorForDesignation_2(newData_xyc == null);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="*Search Designation"
                                variant="outlined"
                                error={errorForDesignation_2}
                                helperText={
                                  errorForDesignation_2
                                    ? "Select Your Designation"
                                    : ""
                                }
                              />
                            )}
                          />
                          {/* ============================================= */}
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          lg={3}
                          sx={{ marginRight: "10px" }}
                          // value={mobile}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="primarySpoc1"
                                checked={primarySpoc1}
                                onChange={handleChange2}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Primary Spoc"
                            style={{ color: "grey" }}
                          />
                        </Grid>
                      </Grid>

                      <br />
                      <Grid item container spacing={2}>
                        <Grid item xs={7} md={7} lg={3.7}>
                          {landlines1.map((item, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                }}
                                key={index + 1}
                              >
                                <TextField
                                  style={{ marginBottom: "5px" }}
                                  type="tel"
                                  label="Landline"
                                  variant="outlined"
                                  onKeyPress={preventMinus}
                                  inputProps={{ minLength: 8, maxLength: 10 }}
                                  // size="small"
                                  fullWidth
                                  name="landline"
                                  value={item.landline}
                                  onChange={(e) => handleLandChange1(e, index)}
                                />

                                <div style={{ width: "min-content" }}>
                                  {landlines1.length !== 1 && (
                                    <span
                                      title="Delete Landline Number"
                                      className={Styles.btns}
                                      type="button"
                                      onClick={() => handleRemoveLand1(index)}
                                    >
                                      <CancelIcon color="error" />
                                    </span>
                                  )}
                                  {landlines1.length - 1 === index && (
                                    <span
                                      title="Add More Landline Number"
                                      className={Styles.btns}
                                      type="button"
                                      style={
                                        landlines1.length < 3
                                          ? { display: "inline-block" }
                                          : { display: "none" }
                                      }
                                      onClick={handleAddLand1}
                                    >
                                      <AddCircleOutlineIcon color="success" />
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </Grid>

                        <Grid item position="relative" xs={7} md={7} lg={4}>
                          {mobile1.map((item, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                }}
                                key={index + 1}
                              >
                                <Grid>
                                  <Grid>
                                    <TextField
                                      style={{
                                        marginBottom: "5px",
                                        width: "295px",
                                      }}
                                      type="text"
                                      label="Mobile"
                                      variant="outlined"
                                      onKeyPress={preventMinus}
                                      inputProps={{
                                        minLength: 10,
                                        maxLength: 10,
                                      }}
                                      fullWidth
                                      name="mobile"
                                      required
                                      value={item.mobile}
                                      onChange={(e) => {
                                        handleMobChange1(e, index);
                                      }}
                                    />
                                  </Grid>
                                  <Grid>
                                    <Checkbox
                                      name="is_personal"
                                      value={item.is_personal}
                                      onChange={(e) => {
                                        handlepersonalMobChange1(e, index);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      style={{ marginRight: "2%" }}
                                    />
                                    Personal Number
                                  </Grid>
                                </Grid>

                                <div
                                  style={{
                                    width: "min-content",
                                  }}
                                >
                                  {mobile1.length !== 1 && (
                                    <span
                                      title="Delete Mobile Number"
                                      className={Styles.btns}
                                      type="button"
                                      onClick={() => handleRemoveMob1(index)}
                                    >
                                      <CancelIcon color="error" />
                                    </span>
                                  )}
                                  {mobile1.length - 1 === index && (
                                    <span
                                      title="Add More Mobile Number"
                                      className={Styles.btns}
                                      type="button"
                                      style={
                                        mobile1.length < 3
                                          ? { display: "inline-block" }
                                          : { display: "none" }
                                      }
                                    >
                                      <AddCircleOutlineIcon
                                        color="success"
                                        onClick={handleAddMob1}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </Grid>

                        <Grid item xs={4} position="relative" lg={3.7} md={4}>
                          {email1.map((item, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  // marginLeft: "25px",
                                }}
                                key={index + 1}
                              >
                                <Grid>
                                  <Grid>
                                    <TextField
                                      style={{
                                        marginBottom: "5px",
                                        width: "290px",
                                      }}
                                      type="email"
                                      label="Email"
                                      variant="outlined"
                                      fullWidth
                                      name="email"
                                      required
                                      value={item.email}
                                      onChange={(e) => {
                                        const regexPattern =
                                          /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        handleEmailChange1(e, index);
                                        return setIsValid_1(
                                          regexPattern.test(e.target.value)
                                        );
                                      }}
                                      error={
                                        email1[0].email == ""
                                          ? null
                                          : isValid_1
                                          ? null
                                          : true
                                      }
                                      helperText={
                                        email1[0].email == ""
                                          ? null
                                          : isValid_1
                                          ? null
                                          : "Please enter the valid Email"
                                      }
                                    />
                                  </Grid>
                                  <Grid>
                                    <Checkbox
                                      name="is_personal"
                                      value={item.is_personal}
                                      onChange={(e) => {
                                        handlepersonalEmailChange1(e, index);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      // style={{ marginRight: "2%" }}
                                    />
                                    Personal Email
                                  </Grid>
                                </Grid>

                                <div style={{ width: "min-content" }}>
                                  {email1.length !== 1 && (
                                    <span
                                      title="Delete Email "
                                      className={Styles.btns}
                                      type="button"
                                      onClick={() => handleRemoveEmail1(index)}
                                    >
                                      <CancelIcon color="error" />
                                    </span>
                                  )}
                                  {email1.length - 1 === index && (
                                    <span
                                      title="Add More email"
                                      className={Styles.btns}
                                      type="button"
                                      style={
                                        email1.length < 3
                                          ? { display: "inline-block" }
                                          : { display: "none" }
                                      }
                                      onClick={handleAddEmail1}
                                    >
                                      <AddCircleOutlineIcon color="success" />
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-comment-input"
                            label="Comment"
                            name="comment_1"
                            type="text"
                            value={comment_1}
                            onChange={(e) => {
                              setComment_1(e.target.value);
                            }}
                            rows={2}
                            multiline
                            // style={{ width: "850px" }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <span
                        className={Styles.addPage}
                        type="button"
                        onClick={handleAddPageClick}
                      >
                        <CancelIcon color="error" />
                      </span>
                    </Paper>
                  ) : null}
                </Grid>

                <Grid
                  xs={12}
                  item
                  spacing={2}
                  sx={{ mt: 0 }}
                  style={{
                    textAlign: "center",
                    padding: "5px",
                  }}
                >
                  <p
                    hidden={!handleDisableForm}
                    style={{ fontSize: "14px", color: "#E74646" }}
                  >
                    Please fill in all the details to proceed
                  </p>
                  <Button
                    onClick={handleResetForm}
                    type="button"
                    variant="contained"
                    style={{
                      width: "20%",
                      marginRight: "3%",
                      backgroundColor: "#E74646",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    disabled={handleDisableForm}
                    variant="contained"
                    // color={"#1976D2"}
                    style={{ width: "20%", color: "#fff", fontWeight: "500" }}
                  >
                    {/* <Link style={{ textDecoration: "none", color: "#fff" }}>
                      Save
                    </Link> */}
                    Save
                  </Button>
                </Grid>
              </Box>
            </form>
          </ThemeProvider>
        </Paper>
      </Container>
    </section>
  );
}
