import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  Grid,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../../apis/AxiosInstance";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import Loader from "./../../../pages/Loader";
import { useState } from "react";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  height: "250px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  pb: 2,
  zIndex: "1000",
  display: "flex",
  alignItems: "end",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

export default function StudentJoinedList(props) {
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx
  );
  const downloadRef = React.useRef();
  let [regdStudents, setRegdStudents] = React.useState([]);
  let level = props.joinedStudentLevel;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  let paramValue =
    level === "1"
      ? `&country_id=${appointment_id}`
      : level === "2"
      ? `&state_id=${appointment_id}`
      : level === "3"
      ? `&district_id=${appointment_id}`
      : level === "4"
      ? `&college_id=${appointment_id}`
      : level === "5"
      ? `&appointment_id=${appointment_id}`
      : "";

  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    { label: "Full Name", key: "full_name" },
    { label: "Emails", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Whatsapp Number", key: "whattsapp_number" },
  ];

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `joined_students?page=${newPage}${paramValue}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            // item.mobiles && item.mobiles.length > 0
            //   ? item.mobiles
            //       .map((mobile, index) => {
            //         return mobile.length > 10 ? mobile.slice(3) : mobile;
            //       })
            //       .join(", ")
            //   : ""
            item.mobiles &&
            Array.isArray(item.mobiles) &&
            item.mobiles.length > 0
              ? item.mobiles
                  .filter((mobile) => mobile !== null && mobile !== undefined)
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whattsapp_number: `${
            !item.whattsapp_number ||
            Object.keys(item.whattsapp_number).length === 0 ||
            item.whattsapp_number?.number === ""
              ? "N/A"
              : item.whattsapp_number.number
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const loadAllData = async (data) => {
    const totalPage = Math.ceil(totalLength / 10);
    try {
      let arr = [];
      for (let i = 1; i <= totalPage; i++) {
        let { data } = await AxiosInstance.get(
          `/joined_students?limit=10&page=${i}${paramValue}`
        );
        arr = [...arr, ...data.data];
      }

      const formattedData = arr.map((item) => ({
        full_name:
          item.full_name === null || item.full_name === ""
            ? "NA"
            : item.full_name,
        email: `${
          item.emails === null || item.emails === ""
            ? "N/A"
            : item.emails.map((ea, d) => ea)
        }`,
        mobile: `${
          // item.mobiles && item.mobiles.length > 0
          //   ? item.mobiles
          //       .map((mobile, index) => {
          //         return mobile.length > 10 ? mobile.slice(3) : mobile;
          //       })
          //       .join(", ")
          //   : ""
          item.mobiles && Array.isArray(item.mobiles) && item.mobiles.length > 0
            ? item.mobiles
                .filter((mobile) => mobile !== null && mobile !== undefined)
                .map((mobile, index) => {
                  return mobile.length > 10 ? mobile.slice(3) : mobile;
                })
                .join(", ")
            : ""
        }`,
        whattsapp_number: `${
          !item.whattsapp_number ||
          Object.keys(item.whattsapp_number).length === 0 ||
          item.whattsapp_number?.number === ""
            ? "N/A"
            : item.whattsapp_number.number
        }`,
      }));
      setCsvData(formattedData);

      setTimeout(() => {
        downloadRef.current.link.click();
        handleClose1();
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error("Downloading Failed");
      handleClose1();
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleStateOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `joined_students?page=${page}${paramValue}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            // item.mobiles && item.mobiles.length > 0
            //   ? item.mobiles
            //       .map((mobile, index) => {
            //         return mobile.length > 10 ? mobile.slice(3) : mobile;
            //       })
            //       .join(", ")
            //   : ""
            item.mobiles &&
            Array.isArray(item.mobiles) &&
            item.mobiles.length > 0
              ? item.mobiles
                  .filter((mobile) => mobile !== null && mobile !== undefined)
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whattsapp_number: `${
            !item.whattsapp_number ||
            Object.keys(item.whattsapp_number).length === 0 ||
            item.whattsapp_number?.number === ""
              ? "N/A"
              : item.whattsapp_number.number
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const totalPages = Math.ceil(totalLength / 10);

  //Modal Data
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalState(null);
  };

  const paperStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    p: 2,
    height: "auto",
    zIndex: "999",
  };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Addressing Attended Students Details">
        <p
          onClick={handleStateOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "22px", fontWeight: "bold" }}
          >
            Addressing Attended Student Details
            <Button
              style={{ background: "green", marginLeft: "10%", color: "#fff" }}
              onClick={(e) => {
                handleOpen1();
                loadAllData();
              }}
            >
              Download CSV File
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`Addressing Attended list.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            />
            <span
              style={{ marginLeft: "35%", color: "#06113C", fontSize: "18px" }}
            >
              Total Count : {totalLength}
            </span>
          </Typography>
          {/* <h3 style={{ marginLeft: "45%",  color: "#495579" }}>Total Count : {totalLength}</h3> */}
          <TableContainer
            component={Paper}
            style={{
              marginTop: "0.5%",
              maxHeight: window.innerWidth > 1800 ? 630 : 500,
            }}
          >
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "70px !important",
                    }}
                  >
                    Sl No
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Name
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Mobile
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                      alignItems: "center",
                    }}
                  >
                    Whatsapp Number
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Email
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Appointment Count
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell
                          align="center"
                          sx={{ color: "#000", width: "10%" }}
                        >
                          {calculateSlNo(id)}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          {value.full_name == null || value.full_name == ""
                            ? "NA"
                            : value.full_name}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          {value.mobiles === null || value.mobiles === ""
                            ? "N/A"
                            : value.mobiles.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {!value.whattsapp_number ||
                          Object.keys(value.whattsapp_number).length === 0 ||
                          value.whattsapp_number.number === ""
                            ? "N/A"
                            : value.whattsapp_number.number}
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ color: "#000" }}>
                          {value.emails === null || value.emails === ""
                            ? "N/A"
                            : value.emails.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              {" "}
                              {value.appointment_count == null
                                ? "NA"
                                : value.appointment_count}
                            </p>
                            &nbsp;&nbsp;
                            <Tooltip title="Appointment List">
                              <p
                                onClick={() => {
                                  handleOpenModal();
                                  setModalState(value.appointment);
                                }}
                                sx={{
                                  "&:hover": { background: "#DBDFFD" },
                                }}
                              >
                                <BsFillEyeFill
                                  fontSize={17}
                                  style={{
                                    color: "#646FD4",
                                    cursor: "pointer",
                                    "&:hover": { color: "#EB455F" },
                                  }}
                                />
                              </p>
                            </Tooltip>
                          </p>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={6}>
                      <h3 style={{ color: "red", textAlign: "center" }}>
                        No Data Found
                      </h3>
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "0.5%", marginLeft: "40%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Grid style={{ marginTop: "3%" }}>
              <Grid xs={12} item container style={gridContainer} align="center">
                <Paper elevation={10} style={paperStyle}>
                  <Grid align="center" item>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AiFillCloseSquare
                        fontSize={30}
                        onClick={handleCloseModal}
                        color="#FC2947"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <h2>Appointment List</h2>
                    <br />
                  </Grid>
                  <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
                    <Table
                      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead sx={{ backgroundColor: "#495579" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "100px !important",
                            }}
                          >
                            Sl.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "700px !important",
                            }}
                          >
                            Appointment Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {modalState && Object.keys(modalState).length > 0 ? (
                          Object.entries(modalState).map((value, id) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{id + 1}</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {value[1]?.["college"]?.value || "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{ color: "#000" }}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
