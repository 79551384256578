import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import StudentRegisterList from "../appointmentList/StudentRegisterList";
import AddressingRegisteredList from "./AddressingRegisteredList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06113C",
    color: "#fff",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "8px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function AddressingFacultyModal(props) {
  const [stateNames, setStateNames] = React.useState([]);
  const [districtNames, setDistrictNames] = React.useState([]);
  const [universityNames, setUniversityNames] = React.useState([]);

  React.useEffect(() => {
    const appointmentEntries = Object.entries(props.data);
    setAppointments(appointmentEntries);

    // Extract college names and total students
    const collegeNames = appointmentEntries.map(
      ([id, entry]) => Object.values(entry.college)[0]
    );
    const stateNames = appointmentEntries.map(
      ([id, entry]) => Object.values(entry.state)[0]
    );
    const districtNames = appointmentEntries.map(
      ([id, entry]) => Object.values(entry.district)[0]
    );
    const universityNames = appointmentEntries.map(
      ([id, entry]) => Object.values(entry.university)[0]
    );
    const totalStudents = appointmentEntries.map(
      ([id, entry]) => entry.registered_students
    );

    setAppointments(appointmentEntries);
    setAppointments_names(collegeNames);
    setStateNames(stateNames);
    setDistrictNames(districtNames);
    setUniversityNames(universityNames);
    setTotalStudents(totalStudents);
    setAppointmentIds(appointmentEntries.map(([id]) => id));
  }, [props]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [appointment, setAppointments] = React.useState([]);
  const [appointments_names, setAppointments_names] = React.useState([]);
  const [totalStudents, setTotalStudents] = React.useState([]);
  const [appointmentIds, setAppointmentIds] = React.useState([]);

  return (
    <div>
      <Tooltip title="Appointment Names">
        <Button
          size="small"
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={19}
            style={{ color: "#646FD4", "&:hover": { color: "#EB455F" } }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "23px", fontWeight: "bold" }}
          >
            Appointment Details
          </Typography>
          <TableContainer
            component={Paper}
            style={{
              marginTop: "0.5%",
              maxHeight: window.innerWidth > 1800 ? 550 : 430,
            }}
          >
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "70px !important",
                    }}
                  >
                    Sl.No
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "16px",
                      whiteSpace: "normal", // Allow text to wrap
                      wordBreak: "break-word", // Break long words
                      width: "300px !important",
                    }}
                  >
                    Appointment Name
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "200px !important",
                    }}
                  >
                    Total Students
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "200px !important",
                    }}
                  >
                    State Name
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "200px !important",
                    }}
                  >
                    District Name
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "300px !important",
                    }}
                  >
                    University Name
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {appointments_names.length > 0 ? (
                  appointments_names.map((name, index) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={index}
                      >
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#000",
                            background: `${
                              index % 2 === 0 ? "#efefef" : "#fff"
                            }`,
                          }}
                        >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#000",
                            background: `${
                              index % 2 === 0 ? "#efefef" : "#fff"
                            }`,
                            whiteSpace: "normal", // Allow text to wrap
                            wordBreak: "break-word", // Break long words
                          }}
                        >
                          {name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#000",
                            background: `${
                              index % 2 === 0 ? "#efefef" : "#fff"
                            }`,
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p> {totalStudents[index] || "0"}</p>
                            &nbsp;&nbsp;
                            <p style={{ fontSize: "1px" }}>
                              <AddressingRegisteredList
                                // group_idz={appointmentIds[index]}
                                appointment_idx={appointmentIds[index]}
                                // level="5"
                              />
                            </p>
                          </p>
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#000",
                            background: `${
                              index % 2 === 0 ? "#efefef" : "#fff"
                            }`,
                          }}
                        >
                          {stateNames[index] || "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#000",
                            background: `${
                              index % 2 === 0 ? "#efefef" : "#fff"
                            }`,
                          }}
                        >
                          {districtNames[index] || "NA"}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={{
                            color: "#000",
                            background: `${
                              index % 2 === 0 ? "#efefef" : "#fff"
                            }`,
                            whiteSpace: "normal", // Allow text to wrap
                            wordBreak: "break-word", // Break long words
                          }}
                        >
                          {universityNames[index] || "NA"}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <StyledTableCell colSpan={3}>
                      <h3 style={{ color: "red", textAlign: "center" }}>
                        No Data Found
                      </h3>
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
