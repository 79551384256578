import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Autocomplete,
  Chip,
  Checkbox,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AxiosInstance from "../apis/AxiosInstance";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({}));

const EditUserInfo = () => {
  let Navigate = useNavigate();
  let { state } = useLocation();

  const [full_name, setfull_name] = useState(state.full_name);

  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [selectedRole, setSelectedRole] = useState(
    state.role_names && state.role_names.length > 0 ? [...state.role_names] : []
  );

  const [roleTerm, setRoleTerm] = useState("");
  const [roleOption, setRoleOption] = useState([]);

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateOptions, setSelectedStateOptions] = useState([]);

  const [testState, setTestState] = useState([]);

  const [unitNameOption, setUnitNameOption] = useState([]);
  const [unitNameData, setUnitNameData] = useState(
    state.units && Object.keys(state.units).length > 0
      ? Object.entries(state.units).map((e) => ({
          ID: e[0],
          name: e[1].name,
        }))[0] || null
      : null
  );
  const [searchUnitName, setSearchUnitName] = useState("");

  const [removedRole, setRemovedRole] = useState([]);

  const [assign_state, setAssign_State] = useState(
    state.all_states === true ? true : false
  );

  const handleAssignDate = (event) => {
    setAssign_State(event.target.checked);
    if (event.target.checked) {
      setSelectedStateOptions([]);
    }
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  // let [mobiles, setmobiles] = useState([state.mobiles[0]]);
  // const [emails, setEmails] = useState([state.emails[0]]);

  const [mobiles, setmobiles] = useState(
    state.mobiles && state.mobiles.length > 0 ? [state.mobiles[0]] : [""]
  );
  const [emails, setEmails] = useState(
    state.emails && state.emails.length > 0 ? [state.emails[0]] : [""]
  );

  useEffect(() => {
    let fethchData = async () => {
      if (roleTerm !== "") {
        let data = await AxiosInstance.get(
          `/roles?search_keyword=${roleTerm}&limit=10`
        );
        // let finalData = data.data.data;
        let finalData = data.data.data.filter((option) => option.id !== 2);
        setRoleOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/roles?limit=10`);
        // let finalData = data.data.data;
        let finalData = data.data.data.filter((option) => option.id !== 2);
        setRoleOption(finalData);
      }
    };
    fethchData();
  }, [roleTerm]);

  useEffect(() => {
    let fetchData = async () => {
      //Qualification Api
      if (searchUnitName !== "") {
        let data = await AxiosInstance.get(
          `/unit?search_keyword=${searchUnitName}&limit=10`
        );
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      } else {
        let data = await AxiosInstance.get(`/unit?limit=10`);
        let finalData = data.data.data;

        setUnitNameOption(finalData);
      }
    };
    fetchData();
  }, [searchUnitName]);

  //Selecting All Appointments
  const [inputValue1, setInputValue1] = useState([]);
  const [defaultCourseList, setDefaultCourseList] = useState([]);
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   let fetchData = async () => {
  //     // Addressing Faculty api
  //     const keys = Object.keys(state.branch.state_id);
  //     try {
  //       if (keys.length > 0) {
  //         let addFacultyData = await AxiosInstance.get(
  //           `/statemini?country_id=${countryMiniId}&id=${keys
  //             .toString()
  //             .split(",")
  //             .join("&id=")}`
  //         );
  //         let finalAddFaculty = addFacultyData.data.data
  //           ? addFacultyData.data.data
  //           : [];
  //         setSelectedStateOptions(finalAddFaculty);
  //         setTestState(finalAddFaculty);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       if (keys.length > 0) {
  //         setSelectedStateOptions([]);
  //         setTestState([]);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [countryMiniId]);

  useEffect(() => {
    let fetchData = async () => {
      // Ensure state.branch and state.branch.state_id are defined
      if (state.branch && state.branch.state_id) {
        const keys = Object.keys(state.branch.state_id);
        try {
          if (keys.length > 0) {
            let addFacultyData = await AxiosInstance.get(
              `/statemini?country_id=${countryMiniId}&id=${keys
                .toString()
                .split(",")
                .join("&id=")}`
            );
            let finalAddFaculty = addFacultyData.data.data
              ? addFacultyData.data.data
              : [];
            setSelectedStateOptions(finalAddFaculty);
            setTestState(finalAddFaculty);
          } else {
            setSelectedStateOptions([]);
            setTestState([]);
          }
        } catch (error) {
          console.log(error);
          setSelectedStateOptions([]);
          setTestState([]);
        }
      } else {
        // Handle the case when state.branch or state.branch.state_id is undefined or null
        setSelectedStateOptions([]);
        setTestState([]);
      }
    };

    fetchData();
  }, [countryMiniId, state.branch]); // Adding state.branch as a dependency

  useEffect(() => {
    let fetchData = async () => {
      let response = await AxiosInstance.get(
        `/statemini?limit=10&country_id=${countryMiniId}`
      );
      let finalData = response.data.data;
      setStateOptions(finalData);
      setDefaultCourseList(finalData);
    };
    fetchData();
  }, [countryMiniId]);
  const fetchOptions1 = async (searchTerm_1) => {
    try {
      const response = await AxiosInstance.get(
        `/statemini?search_keyword=${searchTerm_1}&limit=10&country_id=${countryMiniId}`
      );
      const data = response.data.data;
      if (Array.isArray(data)) {
        setStateOptions(data);
      } else {
        setStateOptions([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);
    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    } else {
      setStateOptions(defaultCourseList);
    }
  };

  const handleOptionSelect1 = (_, newValue, action) => {
    setSelectedStateOptions(newValue);
  };

  // useEffect(() => {
  //   let ids1 =
  //     state.units && Object.keys(state.units).length > 0
  //       ? Object.keys(state.units)
  //       : "";
  //   const id = ids1 && ids1.length > 0 ? `?id=${ids1.join("&id=")}` : "";
  //   let fetchData = async () => {
  //     let { data } = await AxiosInstance.get(`/unit${id}`);
  //     setUnitNameData(data.data);
  //   };
  //   if (id) fetchData();
  // }, []);

  //! ? handle change for mobile
  const handleMobChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...mobiles];
    list[index] = value;
    setmobiles(list);
  };

  const handleRemoveMob = (index) => {
    const list = [...mobiles];
    list.splice(index, 1);

    setmobiles(list);
  };
  const handleAddMob = () => {
    setmobiles([...mobiles, ""]);
  };

  //! ? handle change for Email
  const handleEmailChange = (val, index) => {
    let { name, value } = val.target;
    let list = [...emails];
    list[index] = value;
    setEmails(list);
  };

  const handleRemoveEmail = (index) => {
    const list = [...emails];
    list.splice(index, 1);
    setEmails(list);
  };
  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  // const handleRemoveRole = (roleToRemove) => {
  //   setRemovedRole((prevRemovedRoles) => [
  //     ...prevRemovedRoles,
  //     roleToRemove.id,
  //   ]);
  //   setSelectedRole((prevSelectedRoles) =>
  //     prevSelectedRoles.filter((role) => role.id !== roleToRemove.id)
  //   );
  // };

  const handleRemoveRole = (roleToRemove) => {
    setSelectedRole((prevSelectedRoles) =>
      prevSelectedRoles.filter((role) => role.id !== roleToRemove.id)
    );
    setRemovedRole((prevRemovedRoles) => [
      ...prevRemovedRoles,
      roleToRemove.id,
    ]);
  };

  // show password
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };
  //   let formData = new FormData();
  //   formData.append("id", state.id);
  //   emails.forEach((val, imd) => {
  //     if (imd != 0) {
  //       formData.append("email", val);
  //     }
  //   });
  //   //Remove Interviewers
  //   const newState =
  //     selectedStateOptions.length > 0
  //       ? selectedStateOptions.map((e) => e.id.toString())
  //       : [];
  //   const removedState =
  //     selectedStateOptions.length > 0 &&
  //     newState &&
  //     Object.keys(state.branch.state_id).length > 0
  //       ? Object.keys(state.branch.state_id).filter(
  //           (ex) => !newState.includes(ex)
  //         )
  //       : [];

  //   mobiles.forEach((value, ind) => {
  //     if (ind != 0) {
  //       formData.append("mobile", value);
  //       formData.append("country_code", `+91`);
  //     }
  //   });
  //   if (password.trim() !== "") {
  //     formData.append("password", password);
  //   }
  //   if (confirm_password.trim() !== "") {
  //     formData.append("confirm_password", confirm_password);
  //   }

  //   if (unitNameData) {
  //     formData.append("unit_id", unitNameData.ID);
  //   }
  //   if (full_name) {
  //     formData.append("full_name", full_name);
  //   }
  //   if(assign_state){
  //     formData.append("all_states",assign_state)
  //   }

  //   const hasExtraObjectsInArray2 = selectedStateOptions.some(
  //     (obj2) => !testState.some((obj1) => obj1.id === obj2.id)
  //   );

  //   if (testState.length > 0 || selectedStateOptions.length > 0) {
  //     if (hasExtraObjectsInArray2) {
  //       selectedStateOptions.map((value) => {
  //         formData.append("add_state", value.id);
  //       });
  //     }
  //   }

  //     removedState.forEach((e) => {
  //       formData.append("remove_state", Number.parseInt(e));
  //     });

  //   const oldData = state.role_names ? [...state.role_names] : [];
  //   if (oldData.length > 0) {
  //     const removedRole = oldData.filter((e) => selectedRole.indexOf(e) == -1);
  //     const addedRole = selectedRole.filter((e) => oldData.indexOf(e) == -1);
  //     addedRole.forEach((e, ind) => {
  //       formData.append("role_id", e.id);
  //     });
  //     removedRole.forEach((removedRoleId) => {
  //       formData.append("remove_role_id", removedRoleId.id);
  //     });
  //   } else {
  //     selectedRole.forEach((e, ind) => {
  //       formData.append("role_id", e.id);
  //     });
  //   }
  //   try {
  //     await AxiosInstance.patch(`/update_user`, formData, config)
  //       .then((data) => {
  //         toast.success("Successfully User updated");
  //         setTimeout(() => {
  //           Navigate("/user_list");
  //         }, 1500);
  //       })
  //       .catch((error) => {
  //         if (error.response.data) {
  //           toast.error(error.response.data.error);
  //         } else {
  //           toast.error("Something Went wrong");
  //         }
  //       });
  //   } catch (error) {
  //     if (error.response) {
  //       toast.error(error.response.data.error);
  //     } else {
  //       toast.error("Something went Wrong Please Try Again");
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    let formData = new FormData();
    formData.append("id", state.id);
    emails.forEach((val, imd) => {
      if (imd !== 0) {
        formData.append("email", val);
      }
    });

    // Remove Interviewers
    const newState =
      selectedStateOptions.length > 0
        ? selectedStateOptions.map((e) => e.id.toString())
        : [];
    // const removedState =
    //   selectedStateOptions.length > 0 &&
    //   newState &&
    //   Object.keys(state.branch.state_id).length > 0
    //     ? Object.keys(state.branch.state_id).filter(
    //         (ex) => !newState.includes(ex)
    //       )
    //     : Object.keys(state.branch.state_id); // Include all states if selectedStateOptions is empty

    const removedState =
    selectedStateOptions.length > 0 &&
    newState &&
    state.branch &&
    state.branch.state_id &&
    Object.keys(state.branch.state_id).length > 0
      ? Object.keys(state.branch.state_id).filter(
          (ex) => !newState.includes(ex)
        )
      : state.branch && state.branch.state_id
      ? Object.keys(state.branch.state_id)
      : [];

    mobiles.forEach((value, ind) => {
      if (ind !== 0) {
        formData.append("mobile", value);
        formData.append("country_code", `+91`);
      }
    });
    if (password.trim() !== "") {
      formData.append("password", password);
    }
    if (confirm_password.trim() !== "") {
      formData.append("confirm_password", confirm_password);
    }

    if (unitNameData) {
      formData.append("unit_id", unitNameData.ID);
    }
    if (full_name) {
      formData.append("full_name", full_name);
    }

    formData.append("all_states", assign_state);

    const hasExtraObjectsInArray2 = selectedStateOptions.some(
      (obj2) => !testState.some((obj1) => obj1.id === obj2.id)
    );

    if (testState.length > 0 || selectedStateOptions.length > 0) {
      if (hasExtraObjectsInArray2) {
        selectedStateOptions.forEach((value) => {
          formData.append("add_state", value.id);
        });
      }
    }

    if (removedState.length > 0) {
      removedState.forEach((e) => {
        formData.append("remove_state", Number.parseInt(e));
      });
    }

    // // Ensure remove_state key is sent even if no states are removed
    // if (removedState.length === 0) {
    //   formData.append("remove_state", ""); // Send an empty string or some default value
    // }

    const oldData = state.role_names ? [...state.role_names] : [];
    if (oldData.length > 0) {
      const removedRole = oldData.filter((e) => selectedRole.indexOf(e) === -1);
      const addedRole = selectedRole.filter((e) => oldData.indexOf(e) === -1);
      addedRole.forEach((e) => {
        formData.append("role_id", e.id);
      });
      removedRole.forEach((removedRoleId) => {
        formData.append("remove_role_id", removedRoleId.id);
      });
    } else {
      selectedRole.forEach((e) => {
        formData.append("role_id", e.id);
      });
    }

    try {
      await AxiosInstance.patch(`/update_user`, formData, config)
        .then((data) => {
          toast.success("Successfully User updated");
          setTimeout(() => {
            Navigate("/user_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something went Wrong Please Try Again");
      }
    }
  };

  const paperStyle = {
    padding: 20,
    gap: 0,

    width: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };
  const avatarStyle = { backgroundColor: "#FD8D14" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#176B87",
    color: "#FD8D14",
    border: "1px solid #176B87",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };
  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };
  // for DropDown ScrollBar

  const handleCancelClick = () => {
    window.history.back();
  };

  return (
    <Grid container style={{ marginTop: "5%" }}>
      <Grid xs={12} item style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit User Info</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={12}>
                <TextField
                  color="warning"
                  size="small"
                  sx={{ minWidth: 210 }}
                  fullWidth
                  name="full_f_name"
                  label="Fullname"
                  value={full_name}
                  onChange={(e) => setfull_name(e.target.value)}
                />
              </Grid>
              <Grid item sm={12}>
                {mobiles.map((item, index) => {
                  return (
                    <div
                      style={{
                        // display: "flex",
                        position: "relative",
                      }}
                      key={index + 1}
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          type="tel"
                          label="Mobile"
                          variant="outlined"
                          onKeyPress={preventMinus}
                          inputProps={{
                            minLength: 10,
                            maxLength: 10,
                          }}
                          name="mobile"
                          required
                          disabled={index === 0 ? true : false}
                          value={item}
                          onChange={(e) => handleMobChange(e, index)}
                        />
                      </Grid>
                      <div
                        style={{
                          width: "min-content",
                        }}
                      >
                        {mobiles.length !== 1 && (
                          <span
                            title="Delete Mobile Number"
                            // className={Styles.btns}
                            style={{ left: "546px", marginTop: "-10px" }}
                            type="button"
                            onClick={() => handleRemoveMob(index)}
                          >
                            <CancelIcon color="error" />
                          </span>
                        )}
                        {mobiles.length - 1 === index && (
                          <span
                            title="Add More email"
                            // className={Styles.btns}
                            type="button"
                            style={
                              mobiles.length < 3
                                ? {
                                    display: "inline-block",
                                    position: "absolute",
                                    left: "546px",
                                    marginTop: "-10px",
                                  }
                                : { display: "none" }
                            }
                            onClick={handleAddMob}
                          >
                            <AddCircleOutlineIcon color="success" />
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid item sm={12}>
                {emails.map((item, index) => {
                  return (
                    <div
                      style={{
                        // display: "flex",
                        position: "relative",
                      }}
                      key={index + 1}
                    >
                      <Grid item>
                        <TextField
                          type="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          name="email"
                          required
                          disabled={index === 0 ? true : false}
                          value={item}
                          onChange={(e) => {
                            handleEmailChange(e, index);
                            const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                            return setIsValid(
                              regexPattern.test(e.target.value)
                            );
                          }}
                        />
                      </Grid>
                      <div style={{ width: "min-content" }}>
                        {emails.length !== 1 && (
                          <span
                            title="Delete Email "
                            type="button"
                            onClick={() => handleRemoveEmail(index)}
                          >
                            <CancelIcon color="error" />
                          </span>
                        )}
                        {emails.length - 1 === index && (
                          <span
                            title="Add More email"
                            type="button"
                            style={
                              emails.length < 3
                                ? {
                                    display: "inline-block",
                                    position: "absolute",
                                    left: "546px",
                                    marginTop: "-10px",
                                  }
                                : { display: "none" }
                            }
                            onClick={handleAddEmail}
                          >
                            <AddCircleOutlineIcon color="success" />
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Grid>
              <Grid item container sm={12} spacing={1}>
                <Grid item sm={6}>
                  <FormControl sx={{ minWidth: 260 }} fullWidth>
                    <InputLabel color="warning">Password*</InputLabel>
                    <OutlinedInput
                      color="warning"
                      name="password"
                      value={password}
                      autoComplete="new-password"
                      onChange={(e) => setpassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl sx={{ minWidth: 260 }} fullWidth>
                    <InputLabel color="warning">Confirm Password*</InputLabel>
                    <OutlinedInput
                      color="warning"
                      name="confirm_password"
                      autoComplete="confirm-password"
                      value={confirm_password}
                      onChange={(e) => setconfirm_password(e.target.value)}
                      type={showPassword1 ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="ConfirmPassword"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                id="refreshTool"
                style={{ position: "relative" }}
              >
                <FormControl fullWidth size="small">
                  <Item
                    onChange={(e) => {
                      setRoleTerm(e.target.value);
                    }}
                  >
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                      required
                      size="small"
                      value={selectedRole}
                      onChange={(e, value) => {
                        setSelectedRole(value);
                      }}
                      options={roleOption ? roleOption : []}
                      getOptionLabel={(option) => option.name}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={option.name + (index + 1)}
                            label={option.name}
                            onDelete={() => handleRemoveRole(option)}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Role"
                          variant="outlined"
                          color="warning"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          required={selectedRole.length === 0}
                        />
                      )}
                    />
                  </Item>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <Item
                  onChange={(e) => {
                    setSearchUnitName(e.target.value);
                  }}
                >
                  <Autocomplete
                    options={unitNameOption}
                    value={unitNameData}
                    name="unitNameOption"
                    onChange={(event, newData_xy) => {
                      setUnitNameData(newData_xy);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Unit Name"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item sm={12} sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="assign_state"
                  checked={assign_state}
                  onChange={handleAssignDate}
                  id="assign_state"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="assignInterviewer" style={{ fontSize: "16px" }}>
                  All States
                </label>
              </Grid>
              <Grid item sm={12}>
                <Item>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    multiple
                    disabled={assign_state}
                    value={selectedStateOptions}
                    onChange={handleOptionSelect1}
                    onInputChange={handleInputChange1}
                    options={stateOptions}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.name + (index + 1)}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select State"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        required={selectedStateOptions.length === 0}
                      />
                    )}
                  />
                </Item>
              </Grid>
              <Grid item container sm={12} spacing={4}>
                <Grid item sm={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={btnstyle1}
                    fullWidth
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item sm={4}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnstyle}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EditUserInfo;
