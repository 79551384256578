import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Pagination,
  Modal,
} from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../apis/AxiosInstance";
import Style from "./_addressing.module.css";
import AssignQuiz from "../assignQuiz/AssignQuiz";

function AssignQuizes() {
  //Search Params
  const [searchParams, setSearchParams] = useSearchParams();
  const paramId = searchParams.get("to");
  const addMore = searchParams.get("addmore");
  let [searchFilter, setSearchFilter] = useState("");

  //! Route State
  const location = useLocation();
  const addressingData = location.state;
  const [users, setUsers] = useState([]);
  //! Other component states

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setChildData({
      data: quizList,
      aid: paramId === "2" ? addressingData.ID : addressingData.id,
      to: Number(paramId),
    });
    setModalOpen(true);
  };
  const [childData, setChildData] = useState(null);
  const handleCloseModal = () => {
    setModalOpen(false);
    // setChildData(null);
  };

  //! storing dta states
  const [quizList, setQuizList] = useState(
    addMore
      ? Object.keys(addressingData.quiz_details.quizes).map((e) =>
          Number.parseInt(e)
        )
      : []
  );

  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const assignedQuizzes =
    addressingData.quiz_details.quizes &&
    Object.keys(addressingData.quiz_details.quizes).length > 0
      ? Object.keys(addressingData.quiz_details.quizes)
      : [];

  //!Checkbox Select Logic
  useEffect(() => {
    let fetchBatch = async () => {
      if (searchFilter !== "") {
        let { data } = await AxiosInstance.get(
          `/event?page=${page}&search_keyword=${searchFilter}`
        );
        if (assignedQuizzes.length > 0 && !addMore) {
          setUsers(
            data.data.length > 0
              ? data.data.filter(
                  (ex) => !assignedQuizzes.includes(ex.ID.toString())
                )
              : []
          );
          // setSelectedObj(
          //   data.data.length > 0
          //     ? data.data.filter(
          //         (ex) => !assignedQuizzes.includes(ex.ID.toString())
          //       )
          //     : []
          // );
          setTotalLength(data.total_length);
        } else {
          setUsers(data.data.length > 0 ? data.data : []);
          setTotalLength(data.total_length);
        }
      } else {
        let { data } = await AxiosInstance.get(`/event?page=${page}`);
        if (assignedQuizzes.length > 0 && !addMore) {
          setUsers(
            data.data.length > 0
              ? data.data.filter(
                  (ex) => !assignedQuizzes.includes(ex.ID.toString())
                )
              : []
          );
          setTotalLength(data.total_length);
        } else {
          setUsers(data.data.length > 0 ? data.data : []);
          setTotalLength(data.total_length);
        }
      }
    };
    fetchBatch();
    // setUsers(userData);
  }, [page, searchFilter]);

  // modal state and change
  const [selectedObj, setSelectedObj] = useState([]);
  const handleCheckboxChange = (event, row) => {
    const newId = row.ID;
    if (event.target.checked) {
      setQuizList([...quizList, newId]);
      setSelectedObj([...selectedObj, row]);
    } else {
      setQuizList(quizList.filter((row) => row !== newId));
      setSelectedObj(selectedObj.filter((row) => row.ID !== newId));
    }
  };

  const totalPages = Math.ceil(totalLength / 10);

  return (
    <section className={Style.quizAssignBlock}>
      <div className={Style.inputSearchContainer}>
        <input
          placeholder=" Search By Title Name"
          type="search"
          className={Style.searchField}
          value={searchFilter}
          onChange={(e) => {
            setSearchFilter(e.target.value);
          }}
        />

        <b style={{ position: "absolute", left: "6px", top: "30%" }}>
          <svg height="1.2em" viewBox="0 0 512 512" fill="#176B87">
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
          </svg>
        </b>
      </div>
      <h1
        style={{
          borderBottom: "1px solid black",
          width: "180px",
          marginLeft: "2%",
        }}
      >
        Assigned Quiz List
      </h1>
      <div className={Style.tableContainer}>
        {selectedObj.length > 0 && (
          <p>{selectedObj.map((e) => e.title).join(", ")}</p>
        )}
      </div>

      <table className={Style.questionListBlock}>
        <thead>
          <tr className={Style.tableHeader1}>
            <th style={{ display: "flex", gap: "10px" }}>
              <svg height="1em" viewBox="0 0 440 500" fill="#fff">
                <path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-44.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z" />
              </svg>
              Title
            </th>
            <th>Subject</th>
            <th>No. of Questions</th>
            <th>Duration</th>
            <th>Marks</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((row) => (
              <tr key={row.ID + "QnTable"}>
                <td>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableRipple
                        name={`chk${row.ID}`}
                        checked={quizList.includes(row.ID)}
                        value={row.ID}
                        onChange={(event) => {
                          handleCheckboxChange(event, row);
                        }}
                        style={{
                          padding: "3px 10px 3px 20px",
                        }}
                      />
                    }
                    label={<span>{row.title}</span>}
                  />
                </td>
                <td>
                  {Object.keys(row.section_field).length < 1
                    ? "NA"
                    : row.section_field?.["1"].subject.name}
                </td>
                <td>
                  {Object.keys(row.section_field).length < 1
                    ? "NA"
                    : Object.values(row.section_field?.["1"].question).length}
                </td>
                <td>
                  {row.duration.value}{" "}
                  {row.duration.id === 1
                    ? "Hrs"
                    : row.duration.id === 2
                    ? "Mins"
                    : "Secs"}
                </td>
                <td>
                  {row.other_fields.section_total_marks === null
                    ? 0
                    : row.other_fields.section_total_marks}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                {assignedQuizzes.length === 0 ? (
                  "No Quiz data found"
                ) : (
                  <>
                    All available Quizzes have been assigned already{" "}
                    <button
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      Back
                    </button>
                  </>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {quizList.length > 0 && (
        <div className={Style.assignButtonBlock}>
          <p>
            {quizList.length === 1
              ? quizList.length + " Quiz selected"
              : quizList.length + " Quizzes selected"}
          </p>
          <Box id={Style.buttonContainer}>
            {" "}
            <Button className={Style.assignButton} onClick={openModal}>
              Proceed
            </Button>
          </Box>
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        }}
      >
        {/* Modal content */}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "90%",
            backgroundColor: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            padding: 10,
            overflowY: "auto",
          }}
        >
          <button
            onClick={handleCloseModal}
            style={{
              position: "fixed",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "30px",
              color: "red",
              zIndex: 99,
            }}
          >
            &#x2716;
          </button>
          {/* Content of your modal */}
          <AssignQuiz
            data={childData}
            addMore={addMore ? addressingData : null}
          />
        </div>
      </Modal>
      <Pagination
        style={{
          maxWidth: "100%",
          marginTop: "1%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        count={totalPages}
        sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
        page={page}
        onChange={handleChangePage}
      />
    </section>
  );
}

export default AssignQuizes;
