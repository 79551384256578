import axios from "axios";

// let token = window.sessionStorage.getItem("token");
import Cookies from "js-cookie";
const parseLog = (a) => {
  const userData = a ? JSON.parse(a) : { token: "" };
  const { token } = userData;
  return token;
};
const token = parseLog(Cookies.get("logged"));
let AxiosInstance = axios.create({
  // baseURL: "http://192.168.1.252:7979/api/v1",//local
  // baseURL: "http://106.51.74.69:7979/api/v1", //local
  baseURL: "https://incubationb.qspiders.com/api/v1", //Global zoro 106.51.76.227
  // baseURL: "https://testincub2.qspiders.com/api/v1", // kakashi global 106.51.74.69x
  headers: {
    "Content-type": "application/json",
    // 'Content-Type': 'multipart/form-data' ,
    Authorization: `${token}`,
  },
});

export default AxiosInstance;
