import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../../apis/AxiosInstance";
import { BsFillEyeFill } from "react-icons/bs";
import { CSVLink } from "react-csv";
import Loader from "./../../../pages/Loader";
import { toast } from "react-toastify";

import { useState, useEffect } from "react";
import { RiFilterOffFill } from "react-icons/ri";
import QuizDetailsList from "./QuizDetailsList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  height: "250px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  pb: 2,
  zIndex: "1000",
  display: "flex",
  alignItems: "end",
};

export default function QuizRejectedList(props) {
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx
  );
  const downloadRef = React.useRef();
  let [regdStudents, setRegdStudents] = React.useState([]);
  let level = props.quizRejectedList;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  //! SearchFilter
  let [searchName, setSearchName] = useState("");
  const [searchPercentage, setSearchPercentage] = useState("");
  let [searchBacklogs, setSearchBacklogs] = useState("");
  let [searchTotalMarks, setSearchTotalMarks] = useState("");
  const [streamOptions, setStreamOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedStream, setSelectedStream] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [qualificationSearch, setQualificationSearch] = useState("");

  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  let QuizShortListValue =
    level === "1"
      ? `&country_id=${appointment_id}`
      : level === "2"
      ? `&state_id=${appointment_id}`
      : level === "3"
      ? `&district_id=${appointment_id}`
      : level === "4"
      ? `&college_id=${appointment_id}`
      : level === "5"
      ? `&appointment_id=${appointment_id}`
      : "";

  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    // { label: "ID", key: "id" },
    { label: "Full Name", key: "full_name" },
    { label: "Emails", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Whatsapp Number", key: "whattsapp_number" },
    { label: "Appointment Count", key: "appointment_count" },
    { label: "Total Marks", key: "total_marks" },
    { label: "Quiz Score", key: "total_marks_scored" },
    { label: "10th Percentage/CGPA", key: "tenth_percentage" },
    { label: "12th Percentage/CGPA", key: "twelth_percentage" },
    { label: "ITI Percentage/CGPA", key: "iti_percentage" },
    { label: "ITI Stream", key: "iti_stream" },
    { label: "Diploma Percentage/CGPA", key: "diploma_percentage" },
    { label: "Diploma Stream", key: "diploma_stream" },
    { label: "Degree Percentage/CGPA", key: "degree_percentage" },
    { label: "Degree Marks", key: "degree_marks" },
    { label: "Degree YOP", key: "degree_yop" },
    { label: "Degree College Name", key: "degree_college" },
    { label: "Degree Stream", key: "degree_stream" },
    { label: "Degree Type", key: "degree_type" },
    { label: "Degree Backlogs", key: "degree_backlogs" },
    { label: "Master Degree Percentage/CGPA", key: "master_degree_pecentage" },
    { label: "Master Degree Marks", key: "master_degree_marks" },
    { label: "Master Degree YOP", key: "master_degree_yop" },
    { label: "Master Degree College Name", key: "master_degree_college" },
    { label: "Master Degree Stream", key: "master_degree_stream" },
    { label: "Master Degree Type", key: "master_degree_type" },
    { label: "Master Degree Backlogs", key: "master_degree_backlogs" },
  ];

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    const qualifictionParams =
      selectedQualification === null
        ? ""
        : `${
            searchPercentage !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage=" +
                searchPercentage
              : ""
          }${
            searchBacklogs !== ""
              ? "&" +
                selectedQualification.name +
                "_active_backlogs=" +
                searchBacklogs
              : ""
          }${
            selectedStream === null
              ? ""
              : "&" +
                selectedQualification.name +
                "_stream=" +
                selectedStream.name
          }`;
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${
      searchTotalMarks !== "" ? "&total_marks_scored=" + searchTotalMarks : ""
    }`;
    try {
      let response = await AxiosInstance.get(
        `quiz_rejected_students?page=${newPage}${QuizShortListValue}${
          blockFilter ? qualifictionParams + otherParams : ""
        }`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whattsapp_number: `${
            Object.keys(item.whattsapp_number).length === 0 ||
            item.whattsapp_number?.number === ""
              ? "N/A"
              : item.whattsapp_number.number
          }`,
          appointment_count:
            item.appointment_count == null ? 0 : item.appointment_count,
          total_marks: `${
            item.total_marks === null || item.total_marks === ""
              ? 0
              : item.total_marks
          }`,
          total_marks_scored: `${
            item.total_marks_scored === null || item.total_marks_scored === ""
              ? 0
              : item.total_marks_scored
          }`,
          tenth_percentage: `${
            item.other_fields.student.tenth_details.tenth_mark_type_value ===
              null ||
            item.other_fields.student.tenth_details.tenth_mark_type_value === ""
              ? 0
              : item.other_fields.student.tenth_details.tenth_mark_type_value
          }`,
          twelth_percentage: `${
            item.other_fields.student.puc_details.puc_mark_type_value ===
              null ||
            item.other_fields.student.puc_details.puc_mark_type_value === ""
              ? 0
              : item.other_fields.student.puc_details.puc_mark_type_value
          }`,
          iti_percentage: `Percentage:${
            item.other_fields.student.iti_details.iti_mark_type_value ===
              null ||
            item.other_fields.student.iti_details.iti_mark_type_value === ""
              ? 0
              : item.other_fields.student.iti_details.iti_mark_type_value
          }`,
          iti_stream: `${
            item.other_fields.student.iti_details.iti_stream &&
            item.other_fields.student.iti_details.iti_stream.name
              ? item.other_fields.student.iti_details.iti_stream.name
              : item.other_fields.student.iti_details.iti_other_stream_name
              ? item.other_fields.student.iti_details.iti_other_stream_name
              : "NA"
          }`,
          diploma_percentage: `${
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === null ||
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === ""
              ? 0
              : item.other_fields.student.diploma_details
                  .diploma_mark_type_value
          }`,
          diploma_stream: `${
            item.other_fields.student.diploma_details.diploma_stream &&
            item.other_fields.student.diploma_details.diploma_stream.name
              ? item.other_fields.student.diploma_details.diploma_stream.name
              : item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              ? item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              : "NA"
          }`,
          degree_percentage: ` ${
            item.other_fields.student.degree_details.degree_mark_type ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_mark_type ===
                "1"
              ? "CGPA"
              : "Percentage"
          }`,
          degree_marks: ` ${
            item.other_fields.student.degree_details.degree_mark_type_value ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type_value ===
              ""
              ? 0
              : item.other_fields.student.degree_details.degree_mark_type_value
          }`,
          degree_yop: `${
            item.other_fields.student.degree_details
              .degree_probable_year_of_passout === null ||
            item.other_fields.student.degree_details
              .degree_probable_year_of_passout === ""
              ? "NA"
              : item.other_fields.student.degree_details
                  .degree_probable_year_of_passout
          }`,
          degree_college: `${
            item.other_fields.student.degree_details.degree_college &&
            item.other_fields.student.degree_details.degree_college.name
              ? item.other_fields.student.degree_details.degree_college.name
              : item.other_fields.student.degree_details
                  .degree_other_college_name
              ? item.other_fields.student.degree_details
                  .degree_other_college_name
              : "NA"
          }`,
          degree_stream: `${
            item.other_fields.student.degree_details.degree_stream &&
            item.other_fields.student.degree_details.degree_stream.name
              ? item.other_fields.student.degree_details.degree_stream.name
              : item.other_fields.student.degree_details
                  .degree_other_stream_name
              ? item.other_fields.student.degree_details
                  .degree_other_stream_name
              : "NA"
          }`,
          degree_type: `${
            item.other_fields.student.degree_details.degree_type &&
            item.other_fields.student.degree_details.degree_type.name
              ? item.other_fields.student.degree_details.degree_type.name
              : item.other_fields.student.degree_details.other_degree_type
              ? item.other_fields.student.degree_details.other_degree_type
              : "NA"
          } `,
          degree_backlogs: `${
            item.other_fields.student.degree_details.degree_active_backlogs ===
              null ||
            item.other_fields.student.degree_details.degree_active_backlogs ===
              ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_active_backlogs
          }`,
          master_degree_pecentage: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          master_degree_marks: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === ""
              ? 0
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type_value
          }`,
          master_degree_yop: `${
            item.other_fields.student.master_degree_details
              ?.master_degree_currently_pursuing_year !== undefined &&
            item.other_fields.student.master_degree_details
              ?.master_degree_currently_pursuing_year !== ""
              ? item.other_fields.student.master_degree_details
                  .master_degree_currently_pursuing_year
              : "NA"
          }`,
          master_degree_college: `${
            item.other_fields.student.master_degree_details
              .master_degree_college &&
            item.other_fields.student.master_degree_details
              .master_degree_college.name
              ? item.other_fields.student.master_degree_details
                  .master_degree_college.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              : "NA"
          }`,

          master_degree_stream: `${
            item.other_fields.student.master_degree_details
              .master_degree_stream &&
            item.other_fields.student.master_degree_details.master_degree_stream
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_stream.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              : "NA"
          }`,
          master_degree_type: `${
            item.other_fields.student.master_degree_details
              .master_degree_type &&
            item.other_fields.student.master_degree_details.master_degree_type
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_type.name
              : item.other_fields.student.master_degree_details
                  .other_master_degree_type
              ? item.other_fields.student.master_degree_details
                  .other_master_degree_type
              : "NA"
          }`,
          master_degree_backlogs: `${
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === null ||
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_active_backlogs
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const loadAllData = async (e) => {
    const totalPage = Math.ceil(totalLength / 10);
    const qualifictionParams =
      selectedQualification === null
        ? ""
        : `${
            searchPercentage !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage=" +
                searchPercentage
              : ""
          }${
            searchBacklogs !== ""
              ? "&" +
                selectedQualification.name +
                "_active_backlogs=" +
                searchBacklogs
              : ""
          }${
            selectedStream === null
              ? ""
              : "&" +
                selectedQualification.name +
                "_stream=" +
                selectedStream.name
          }`;
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${
      searchTotalMarks !== "" ? "&total_marks_scored=" + searchTotalMarks : ""
    }`;
    try {
      let arr = [];
      for (let i = 1; i <= totalPage; i++) {
        let { data } = await AxiosInstance.get(
          `/quiz_rejected_students?page=${i}${QuizShortListValue}${
            blockFilter ? qualifictionParams + otherParams : ""
          }`
        );
        arr = [...arr, ...data.data];
      }
      const formattedData = arr.map((item) => ({
        // ...item,
        full_name:
          item.full_name === null || item.full_name === ""
            ? "NA"
            : item.full_name,
        email: `${
          item.emails === null || item.emails === ""
            ? "N/A"
            : item.emails.map((ea, d) => ea)
        }`,
        mobile: `${
          item.mobiles && item.mobiles.length > 0
            ? item.mobiles
                .map((mobile, index) => {
                  return mobile.length > 10 ? mobile.slice(3) : mobile;
                })
                .join(", ")
            : ""
        }`,
        whattsapp_number: `${
          Object.keys(item.whattsapp_number).length === 0 ||
          item.whattsapp_number?.number === ""
            ? "N/A"
            : item.whattsapp_number.number
        }`,
        appointment_count:
          item.appointment_count == null ? 0 : item.appointment_count,
        total_marks: `${
          item.total_marks === null || item.total_marks === ""
            ? 0
            : item.total_marks
        }`,
        total_marks_scored: `${
          item.total_marks_scored === null || item.total_marks_scored === ""
            ? 0
            : item.total_marks_scored
        }`,
        tenth_percentage: `${
          item.other_fields.student.tenth_details.tenth_mark_type_value ===
            null ||
          item.other_fields.student.tenth_details.tenth_mark_type_value === ""
            ? 0
            : item.other_fields.student.tenth_details.tenth_mark_type_value
        }`,
        twelth_percentage: `${
          item.other_fields.student.puc_details.puc_mark_type_value === null ||
          item.other_fields.student.puc_details.puc_mark_type_value === ""
            ? 0
            : item.other_fields.student.puc_details.puc_mark_type_value
        }`,
        iti_percentage: `${
          item.other_fields.student.iti_details.iti_mark_type_value === null ||
          item.other_fields.student.iti_details.iti_mark_type_value === ""
            ? 0
            : item.other_fields.student.iti_details.iti_mark_type_value
        }`,
        iti_stream: `${
          item.other_fields.student.iti_details.iti_stream &&
          item.other_fields.student.iti_details.iti_stream.name
            ? item.other_fields.student.iti_details.iti_stream.name
            : item.other_fields.student.iti_details.iti_other_stream_name
            ? item.other_fields.student.iti_details.iti_other_stream_name
            : "NA"
        }`,
        diploma_percentage: `${
          item.other_fields.student.diploma_details.diploma_mark_type_value ===
            null ||
          item.other_fields.student.diploma_details.diploma_mark_type_value ===
            ""
            ? 0
            : item.other_fields.student.diploma_details.diploma_mark_type_value
        }`,
        diploma_stream: `${
          item.other_fields.student.diploma_details.diploma_stream &&
          item.other_fields.student.diploma_details.diploma_stream.name
            ? item.other_fields.student.diploma_details.diploma_stream.name
            : item.other_fields.student.diploma_details
                .diploma_other_stream_name
            ? item.other_fields.student.diploma_details
                .diploma_other_stream_name
            : "NA"
        }`,
        degree_percentage: ` ${
          item.other_fields.student.degree_details.degree_mark_type === null ||
          item.other_fields.student.degree_details.degree_mark_type === ""
            ? "NA"
            : item.other_fields.student.degree_details.degree_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        degree_marks: ` ${
          item.other_fields.student.degree_details.degree_mark_type_value ===
            null ||
          item.other_fields.student.degree_details.degree_mark_type_value === ""
            ? 0
            : item.other_fields.student.degree_details.degree_mark_type_value
        }`,
        degree_yop: `${
          item.other_fields.student.degree_details
            .degree_probable_year_of_passout === null ||
          item.other_fields.student.degree_details
            .degree_probable_year_of_passout === ""
            ? "NA"
            : item.other_fields.student.degree_details
                .degree_probable_year_of_passout
        }`,
        degree_college: `${
          item.other_fields.student.degree_details.degree_college &&
          item.other_fields.student.degree_details.degree_college.name
            ? item.other_fields.student.degree_details.degree_college.name
            : item.other_fields.student.degree_details.degree_other_college_name
            ? item.other_fields.student.degree_details.degree_other_college_name
            : "NA"
        }`,
        degree_stream: `${
          item.other_fields.student.degree_details.degree_stream &&
          item.other_fields.student.degree_details.degree_stream.name
            ? item.other_fields.student.degree_details.degree_stream.name
            : item.other_fields.student.degree_details.degree_other_stream_name
            ? item.other_fields.student.degree_details.degree_other_stream_name
            : "NA"
        }`,
        degree_type: `${
          item.other_fields.student.degree_details.degree_type &&
          item.other_fields.student.degree_details.degree_type.name
            ? item.other_fields.student.degree_details.degree_type.name
            : item.other_fields.student.degree_details.other_degree_type
            ? item.other_fields.student.degree_details.other_degree_type
            : "NA"
        } `,
        degree_backlogs: `${
          item.other_fields.student.degree_details.degree_active_backlogs ===
            null ||
          item.other_fields.student.degree_details.degree_active_backlogs === ""
            ? "NA"
            : item.other_fields.student.degree_details.degree_active_backlogs
        }`,
        master_degree_pecentage: `${
          item.other_fields.student.master_degree_details
            .master_degree_mark_type === null ||
          item.other_fields.student.master_degree_details
            .master_degree_mark_type === ""
            ? "NA"
            : item.other_fields.student.master_degree_details
                .master_degree_mark_type === "1"
            ? "CGPA"
            : "Percentage"
        }`,
        master_degree_marks: `${
          item.other_fields.student.master_degree_details
            .master_degree_mark_type_value === null ||
          item.other_fields.student.master_degree_details
            .master_degree_mark_type_value === ""
            ? 0
            : item.other_fields.student.master_degree_details
                .master_degree_mark_type_value
        }`,
        master_degree_yop: `${
          item.other_fields.student.master_degree_details
            ?.master_degree_currently_pursuing_year !== undefined &&
          item.other_fields.student.master_degree_details
            ?.master_degree_currently_pursuing_year !== ""
            ? item.other_fields.student.master_degree_details
                .master_degree_currently_pursuing_year
            : "NA"
        }`,
        master_degree_college: `${
          item.other_fields.student.master_degree_details
            .master_degree_college &&
          item.other_fields.student.master_degree_details.master_degree_college
            .name
            ? item.other_fields.student.master_degree_details
                .master_degree_college.name
            : item.other_fields.student.master_degree_details
                .master_degree_other_college_name
            ? item.other_fields.student.master_degree_details
                .master_degree_other_college_name
            : "NA"
        }`,

        master_degree_stream: `${
          item.other_fields.student.master_degree_details
            .master_degree_stream &&
          item.other_fields.student.master_degree_details.master_degree_stream
            .name
            ? item.other_fields.student.master_degree_details
                .master_degree_stream.name
            : item.other_fields.student.master_degree_details
                .master_degree_other_stream_name
            ? item.other_fields.student.master_degree_details
                .master_degree_other_stream_name
            : "NA"
        }`,
        master_degree_type: `${
          item.other_fields.student.master_degree_details.master_degree_type &&
          item.other_fields.student.master_degree_details.master_degree_type
            .name
            ? item.other_fields.student.master_degree_details.master_degree_type
                .name
            : item.other_fields.student.master_degree_details
                .other_master_degree_type
            ? item.other_fields.student.master_degree_details
                .other_master_degree_type
            : "NA"
        }`,
        master_degree_backlogs: `${
          item.other_fields.student.master_degree_details
            .master_degree_active_backlogs === null ||
          item.other_fields.student.master_degree_details
            .master_degree_active_backlogs === ""
            ? "NA"
            : item.other_fields.student.master_degree_details
                .master_degree_active_backlogs
        }`,
      }));
      setCsvData(formattedData);
      setTimeout(() => {
        downloadRef.current.link.click();
        handleClose1();
      }, 1000);
    } catch (error) {
      toast.error("Downloading Failed");
      handleClose1();
    }
  };
  const [loadReg, setLoadRed] = React.useState(false);
  const handleAppointmentOpen = async (e, x) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `quiz_rejected_students?page=${page}${QuizShortListValue}${x ? x : ""}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = response.data.data.map((item) => ({
          ...item,
          email: `${
            item.emails === null || item.emails === ""
              ? "N/A"
              : item.emails.map((ea, d) => ea)
          }`,
          mobile: `${
            item.mobiles && item.mobiles.length > 0
              ? item.mobiles
                  .map((mobile, index) => {
                    return mobile.length > 10 ? mobile.slice(3) : mobile;
                  })
                  .join(", ")
              : ""
          }`,
          whattsapp_number: `${
            Object.keys(item.whattsapp_number).length === 0 ||
            item.whattsapp_number?.number === ""
              ? "N/A"
              : item.whattsapp_number.number
          }`,
          appointment_count:
            item.appointment_count == null ? 0 : item.appointment_count,
          total_marks: `${
            item.total_marks === null || item.total_marks === ""
              ? 0
              : item.total_marks
          }`,
          total_marks_scored: `${
            item.total_marks_scored === null || item.total_marks_scored === ""
              ? 0
              : item.total_marks_scored
          }`,
          tenth_percentage: `${
            item.other_fields.student.tenth_details.tenth_mark_type_value ===
              null ||
            item.other_fields.student.tenth_details.tenth_mark_type_value === ""
              ? 0
              : item.other_fields.student.tenth_details.tenth_mark_type_value
          }`,
          twelth_percentage: `${
            item.other_fields.student.puc_details.puc_mark_type_value ===
              null ||
            item.other_fields.student.puc_details.puc_mark_type_value === ""
              ? 0
              : item.other_fields.student.puc_details.puc_mark_type_value
          }`,
          iti_percentage: `Percentage:${
            item.other_fields.student.iti_details.iti_mark_type_value ===
              null ||
            item.other_fields.student.iti_details.iti_mark_type_value === ""
              ? 0
              : item.other_fields.student.iti_details.iti_mark_type_value
          }`,
          iti_stream: `${
            item.other_fields.student.iti_details.iti_stream &&
            item.other_fields.student.iti_details.iti_stream.name
              ? item.other_fields.student.iti_details.iti_stream.name
              : item.other_fields.student.iti_details.iti_other_stream_name
              ? item.other_fields.student.iti_details.iti_other_stream_name
              : "NA"
          }`,
          diploma_percentage: `${
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === null ||
            item.other_fields.student.diploma_details
              .diploma_mark_type_value === ""
              ? 0
              : item.other_fields.student.diploma_details
                  .diploma_mark_type_value
          }`,
          diploma_stream: `${
            item.other_fields.student.diploma_details.diploma_stream &&
            item.other_fields.student.diploma_details.diploma_stream.name
              ? item.other_fields.student.diploma_details.diploma_stream.name
              : item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              ? item.other_fields.student.diploma_details
                  .diploma_other_stream_name
              : "NA"
          }`,
          degree_percentage: ` ${
            item.other_fields.student.degree_details.degree_mark_type ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_mark_type ===
                "1"
              ? "CGPA"
              : "Percentage"
          }`,
          degree_marks: ` ${
            item.other_fields.student.degree_details.degree_mark_type_value ===
              null ||
            item.other_fields.student.degree_details.degree_mark_type_value ===
              ""
              ? 0
              : item.other_fields.student.degree_details.degree_mark_type_value
          }`,
          degree_yop: `${
            item.other_fields.student.degree_details
              .degree_probable_year_of_passout === null ||
            item.other_fields.student.degree_details
              .degree_probable_year_of_passout === ""
              ? "NA"
              : item.other_fields.student.degree_details
                  .degree_probable_year_of_passout
          }`,
          degree_college: `${
            item.other_fields.student.degree_details.degree_college &&
            item.other_fields.student.degree_details.degree_college.name
              ? item.other_fields.student.degree_details.degree_college.name
              : item.other_fields.student.degree_details
                  .degree_other_college_name
              ? item.other_fields.student.degree_details
                  .degree_other_college_name
              : "NA"
          }`,
          degree_stream: `${
            item.other_fields.student.degree_details.degree_stream &&
            item.other_fields.student.degree_details.degree_stream.name
              ? item.other_fields.student.degree_details.degree_stream.name
              : item.other_fields.student.degree_details
                  .degree_other_stream_name
              ? item.other_fields.student.degree_details
                  .degree_other_stream_name
              : "NA"
          }`,
          degree_type: `${
            item.other_fields.student.degree_details.degree_type &&
            item.other_fields.student.degree_details.degree_type.name
              ? item.other_fields.student.degree_details.degree_type.name
              : item.other_fields.student.degree_details.other_degree_type
              ? item.other_fields.student.degree_details.other_degree_type
              : "NA"
          } `,
          degree_backlogs: `${
            item.other_fields.student.degree_details.degree_active_backlogs ===
              null ||
            item.other_fields.student.degree_details.degree_active_backlogs ===
              ""
              ? "NA"
              : item.other_fields.student.degree_details.degree_active_backlogs
          }`,
          master_degree_pecentage: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type === "1"
              ? "CGPA"
              : "Percentage"
          }`,
          master_degree_marks: `${
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === null ||
            item.other_fields.student.master_degree_details
              .master_degree_mark_type_value === ""
              ? 0
              : item.other_fields.student.master_degree_details
                  .master_degree_mark_type_value
          }`,
          master_degree_yop: `${
            item.other_fields.student.master_degree_details
              ?.master_degree_currently_pursuing_year !== undefined &&
            item.other_fields.student.master_degree_details
              ?.master_degree_currently_pursuing_year !== ""
              ? item.other_fields.student.master_degree_details
                  .master_degree_currently_pursuing_year
              : "NA"
          }`,
          master_degree_college: `${
            item.other_fields.student.master_degree_details
              .master_degree_college &&
            item.other_fields.student.master_degree_details
              .master_degree_college.name
              ? item.other_fields.student.master_degree_details
                  .master_degree_college.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_college_name
              : "NA"
          }`,

          master_degree_stream: `${
            item.other_fields.student.master_degree_details
              .master_degree_stream &&
            item.other_fields.student.master_degree_details.master_degree_stream
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_stream.name
              : item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              ? item.other_fields.student.master_degree_details
                  .master_degree_other_stream_name
              : "NA"
          }`,
          master_degree_type: `${
            item.other_fields.student.master_degree_details
              .master_degree_type &&
            item.other_fields.student.master_degree_details.master_degree_type
              .name
              ? item.other_fields.student.master_degree_details
                  .master_degree_type.name
              : item.other_fields.student.master_degree_details
                  .other_master_degree_type
              ? item.other_fields.student.master_degree_details
                  .other_master_degree_type
              : "NA"
          }`,
          master_degree_backlogs: `${
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === null ||
            item.other_fields.student.master_degree_details
              .master_degree_active_backlogs === ""
              ? "NA"
              : item.other_fields.student.master_degree_details
                  .master_degree_active_backlogs
          }`,
        }));
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const resetFilters = async (e) => {
    e.preventDefault();
    setSearchName("");
    setSearchTotalMarks("");
    setSelectedQualification(null);
    setSelectedStream(null);
    setSearchPercentage("");
    setSearchBacklogs("");
    setBlockFilter(false);
    handleAppointmentOpen();
  };

  const totalPages = Math.ceil(totalLength / 10);
  //? filter logic starts.

  // fecthing data for dropdown
  //! qualification on load
  useEffect(() => {
    const fetchData1 = async () => {
      try {
        if (qualificationSearch !== "") {
          let qualificationData = await AxiosInstance.get(
            `/qualification?search_keyword=${qualificationSearch}&limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(finalQualificationData);
          // setDataFetched(true);
        } else {
          let qualificationData = await AxiosInstance.get(
            `/qualification?limit=10`
          );
          let finalQualificationData = qualificationData.data.data;
          setQualificationOptions(finalQualificationData);
          // setDataFetched(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (open) {
      fetchData1();
    }
  }, [open]);
  const [defaultStream, setDefaultStream] = useState([]);
  //! stream on selcting qualification
  const fetchStream = async (e) => {
    try {
      const { data } = await AxiosInstance.get(
        `/academic_streams?qualification_type_ids=${e}&limit=10`
      );
      setStreamOptions(data.data);
      setDefaultStream(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleConbinedChange = (e) => {
    setQualificationSearch(e.target.value);
  };

  const [blockFilter, setBlockFilter] = useState(false);
  const applyFilter = async () => {
    setBlockFilter(true);
    const qualifictionParams =
      selectedQualification === null
        ? ""
        : `${
            searchPercentage !== ""
              ? "&" +
                selectedQualification.name +
                "_percentage=" +
                searchPercentage
              : ""
          }${
            searchBacklogs !== ""
              ? "&" +
                selectedQualification.name +
                "_active_backlogs=" +
                searchBacklogs
              : ""
          }${
            selectedStream === null
              ? ""
              : "&" +
                selectedQualification.name +
                "_stream=" +
                selectedStream.name
          }`;
    const otherParams = `${
      searchName !== "" ? "&search_keyword=" + searchName : ""
    }${
      searchTotalMarks !== "" ? "&total_marks_scored=" + searchTotalMarks : ""
    }`;
    try {
      const { data } = await AxiosInstance.get(
        `/quiz_rejected_students?limit=10${QuizShortListValue}${qualifictionParams}${otherParams}`
      );
      if (data && data.length > 0) {
        setBlockFilter(true);
        handleAppointmentOpen(null, `${qualifictionParams}${otherParams}`);
      } else {
        toast.error("No data found for the applied filters");
        setBlockFilter(false);
      }
    } catch (error) {
      setBlockFilter(false);
      console.log(error);
    }
  };
  //? filter logic Ends

  //Modal Data
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalState(null);
  };

  const paperStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    p: 2,
    height: "auto",
    zIndex: "999",
  };

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  return (
    <div>
      <Tooltip title="Quiz Rejected Students Details">
        <p
          onClick={handleAppointmentOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Quiz Rejected Student Details
            <Button
              style={{ background: "green", marginLeft: "10%", color: "#fff" }}
              onClick={(e) => {
                handleOpen1();
                loadAllData();
              }}
            >
              Download CSV File
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`Quiz-Rejected list.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            />
          </Typography>
          {/* <Grid
            spacing={1}
            container
            style={{
              marginTop: "5px",
              gap: "10px",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              padding: "2px",
            }}
          >
            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                type="search"
                disabled={blockFilter}
                label="Search Students Name"
                placeholder="Type Atleast 3 char Name"
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={searchName}
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
            </Grid>

            <Grid item lg={2}>
              <TextField
                size="small"
                fullWidth
                disabled={blockFilter}
                type="tel"
                label="Search QuizScore"
                onKeyPress={preventMinus}
                onPaste={preventPasteNegative}
                inputProps={{ minLength: 1, maxLength: 3 }}
                style={{
                  background: "#fff",
                  borderRadius: "6px",
                }}
                value={searchTotalMarks}
                onChange={(e) => {
                  setSearchTotalMarks(e.target.value);
                }}
              />
            </Grid>
            <Grid item lg={1.5}>
              <Autocomplete
                size="small"
                options={qualificationOptions}
                disabled={blockFilter}
                value={selectedQualification}
                name="qualification"
                onChange={(event, newStream, x, y) => {
                  setSelectedQualification(newStream);
                  if (x === "selectOption") {
                    const qual = y.option.id;
                    fetchStream(qual);
                  }
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Qualification"
                    variant="outlined"
                    onChange={async (e) => {
                      const term = e.target.value;
                      if (term.length > 2) {
                        const { data } = await AxiosInstance.get(
                          `/qualification?search_keyword=${term}&limit=10`
                        );
                        setQualificationOptions(data.data);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {selectedQualification && (
              <>
                <Grid item lg={2.8}>
                  <Autocomplete
                    size="small"
                    options={streamOptions}
                    value={selectedStream}
                    name="stream_name"
                    disabled={blockFilter}
                    onChange={(event, newStream) => {
                      setSelectedStream(newStream);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onFocus={() => {
                          setStreamOptions(defaultStream);
                        }}
                        onChange={async (e) => {
                          const term = e.target.value;
                          if (term.length > 1) {
                            const { data } = await AxiosInstance.get(
                              `/academic_streams?search_keyword=${term}&limit=10`
                            );
                            setStreamOptions(data.data);
                          }
                        }}
                        label="Search Stream"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    label="Search Backlogs"
                    disabled={blockFilter}
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 2 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={searchBacklogs}
                    onChange={(e) => {
                      setSearchBacklogs(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={2}>
                  <TextField
                    size="small"
                    fullWidth
                    type="tel"
                    label="Search Percentage"
                    disabled={blockFilter}
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    style={{
                      background: "#fff",
                      borderRadius: "6px",
                    }}
                    value={searchPercentage}
                    onChange={(e) => {
                      setSearchPercentage(e.target.value);
                    }}
                  />
                </Grid>
              </>
            )}
            {blockFilter ? (
              <Grid item md={1.8}>
                <button
                  type="button"
                  style={{
                    fontSize: "16px",
                    color: "#111",
                    padding: "4px 10px",
                    cursor: "pointer",
                    backgroundColor: "#DDE6ED",
                    borderRadius: "40px",
                    border: "3px solid #efefef",
                  }}
                  onClick={resetFilters}
                >
                  Reset Filters
                  <span>
                    <RiFilterOffFill fontSize={16} />
                  </span>
                </button>
              </Grid>
            ) : (
              <Grid
                item
                md={1.2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="button"
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    padding: "4px 30px",
                    cursor: "pointer",
                    backgroundColor: "#6C9BCF",
                    borderRadius: "40px",
                    border: "2px solid #efefef",
                  }}
                  onClick={() => {
                    if (
                      searchName === "" &&
                      searchBacklogs === "" &&
                      searchPercentage === "" &&
                      selectedStream === null &&
                      selectedQualification === null &&
                      searchTotalMarks === ""
                    ) {
                      toast.warn("No filters selected");
                    } else if (searchName !== "" && searchName.length < 3) {
                      toast.warn(
                        "Type atleast 3 characters to search student by name"
                      );
                    } else {
                      applyFilter();
                    }
                  }}
                >
                  Search
                </button>
              </Grid>
            )}
          </Grid> */}
          <h3
            style={{ marginLeft: "45%", marginTop: "0.5%", color: "#495579" }}
          >
            Total Count : {totalLength}
          </h3>
          <TableContainer component={Paper} sx={{ maxHeight: 370 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                      alignItems: "center",
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                      alignItems: "center",
                    }}
                  >
                    Whatsapp Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Appointment Count
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Quiz Count
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Total Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Quiz Score
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    10th Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    12th Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    ITI Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Diploma Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Degree Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Degree Passout Year
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree College Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Degree Backlogs
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Percentage/CGPA
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Marks
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree YOP
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree College Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Stream
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Type
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "200px !important",
                    }}
                  >
                    Master Degree Backlogs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "10%" }}
                        >
                          {calculateSlNo(id)}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {value.full_name == null ? "NA" : value.full_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.mobiles === null || value.mobiles === ""
                            ? "N/A"
                            : value.mobiles.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {Object.keys(value.whattsapp_number).length === 0 ||
                          value.whattsapp_number?.number === ""
                            ? "N/A"
                            : value.whattsapp_number.number}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.emails === null || value.emails === ""
                            ? "N/A"
                            : value.emails.map((ea) => (
                                <Box style={{ height: "22px" }}>{ea}</Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              {" "}
                              {value.appointment_count == null
                                ? 0
                                : value.appointment_count}
                            </p>
                            &nbsp;&nbsp;
                            <Tooltip title="Appointment List">
                              <p
                                onClick={() => {
                                  handleOpenModal();
                                  setModalState(value.appointment);
                                }}
                                sx={{
                                  "&:hover": { background: "#DBDFFD" },
                                }}
                              >
                                <BsFillEyeFill
                                  fontSize={17}
                                  style={{
                                    color: "#646FD4",
                                    cursor: "pointer",
                                    "&:hover": { color: "#EB455F" },
                                  }}
                                />
                              </p>
                            </Tooltip>
                          </p>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <p>
                              {value.quiz_count === null ||
                              value.quiz_count === ""
                                ? 0
                                : value.quiz_count}
                            </p>
                            &nbsp;&nbsp;
                            <p style={{ fontSize: "1px" }}>
                              {" "}
                              <QuizDetailsList
                                appointment_idx={value.id}
                              ></QuizDetailsList>
                            </p>
                          </p>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.total_marks === null ||
                          value.total_marks === ""
                            ? 0
                            : value.total_marks}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.total_marks_scored === null ||
                          value.total_marks_scored === ""
                            ? 0
                            : value.total_marks_scored}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.tenth_details
                            .tenth_mark_type_value === null ||
                          value.other_fields.student.tenth_details
                            .tenth_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.tenth_details
                                .tenth_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.puc_details
                            .puc_mark_type_value === null ||
                          value.other_fields.student.puc_details
                            .puc_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.puc_details
                                .puc_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details
                            .iti_mark_type_value === null ||
                          value.other_fields.student.iti_details
                            .iti_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.iti_details
                                .iti_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.iti_details.iti_stream &&
                          value.other_fields.student.iti_details.iti_stream.name
                            ? value.other_fields.student.iti_details.iti_stream
                                .name
                            : value.other_fields.student.iti_details
                                .iti_other_stream_name
                            ? value.other_fields.student.iti_details
                                .iti_other_stream_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_mark_type_value === null ||
                          value.other_fields.student.diploma_details
                            .diploma_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.diploma_details
                                .diploma_mark_type_value}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.diploma_details
                            .diploma_stream &&
                          value.other_fields.student.diploma_details
                            .diploma_stream.name
                            ? value.other_fields.student.diploma_details
                                .diploma_stream.name
                            : value.other_fields.student.diploma_details
                                .diploma_other_stream_name
                            ? value.other_fields.student.diploma_details
                                .diploma_other_stream_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_mark_type === null ||
                          value.other_fields.student.degree_details
                            .degree_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.degree_details
                                .degree_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_mark_type_value === null ||
                          value.other_fields.student.degree_details
                            .degree_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.degree_details
                                .degree_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_probable_year_of_passout === null ||
                          value.other_fields.student.degree_details
                            .degree_probable_year_of_passout === ""
                            ? "NA"
                            : value.other_fields.student.degree_details
                                .degree_probable_year_of_passout}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_college &&
                          value.other_fields.student.degree_details
                            .degree_college.name
                            ? value.other_fields.student.degree_details
                                .degree_college.name
                            : value.other_fields.student.degree_details
                                .degree_other_college_name
                            ? value.other_fields.student.degree_details
                                .degree_other_college_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_stream &&
                          value.other_fields.student.degree_details
                            .degree_stream.name
                            ? value.other_fields.student.degree_details
                                .degree_stream.name
                            : value.other_fields.student.degree_details
                                .degree_other_stream_name
                            ? value.other_fields.student.degree_details
                                .degree_other_stream_name
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_type &&
                          value.other_fields.student.degree_details.degree_type
                            .name
                            ? value.other_fields.student.degree_details
                                .degree_type.name
                            : value.other_fields.student.degree_details
                                .other_degree_type
                            ? value.other_fields.student.degree_details
                                .other_degree_type
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.other_fields.student.degree_details
                            .degree_active_backlogs === null ||
                          value.other_fields.student.degree_details
                            .degree_active_backlogs === ""
                            ? "NA"
                            : value.other_fields.student.degree_details
                                .degree_active_backlogs}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_mark_type === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_mark_type === ""
                            ? "NA"
                            : value.other_fields.student.master_degree_details
                                .master_degree_mark_type === "1"
                            ? "CGPA"
                            : "Percentage"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_mark_type_value === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_mark_type_value === ""
                            ? 0
                            : value.other_fields.student.master_degree_details
                                .master_degree_mark_type_value}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            ?.master_degree_currently_pursuing_year !==
                            undefined &&
                          value.other_fields.student.master_degree_details
                            ?.master_degree_currently_pursuing_year !== ""
                            ? value.other_fields.student.master_degree_details
                                .master_degree_currently_pursuing_year
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_college &&
                          value.other_fields.student.master_degree_details
                            .master_degree_college.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_college.name
                            : value.other_fields.student.master_degree_details
                                .master_degree_other_college_name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_other_college_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_stream &&
                          value.other_fields.student.master_degree_details
                            .master_degree_stream.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_stream.name
                            : value.other_fields.student.master_degree_details
                                .master_degree_other_stream_name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_other_stream_name
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_type &&
                          value.other_fields.student.master_degree_details
                            .master_degree_type.name
                            ? value.other_fields.student.master_degree_details
                                .master_degree_type.name
                            : value.other_fields.student.master_degree_details
                                .other_master_degree_type
                            ? value.other_fields.student.master_degree_details
                                .other_master_degree_type
                            : "NA"}
                          <br />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "200px !important" }}
                        >
                          {value.other_fields.student.master_degree_details
                            .master_degree_active_backlogs === null ||
                          value.other_fields.student.master_degree_details
                            .master_degree_active_backlogs === ""
                            ? "NA"
                            : value.other_fields.student.master_degree_details
                                .master_degree_active_backlogs}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={28}>
                      <h3 style={{ color: "red" }}>No Data Found</h3>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "30%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Grid style={{ marginTop: "3%" }}>
              <Grid xs={12} item container style={gridContainer} align="center">
                <Paper elevation={10} style={paperStyle}>
                  <Grid align="center" item>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AiFillCloseSquare
                        fontSize={30}
                        onClick={handleCloseModal}
                        color="#FC2947"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <h2>Appointment List</h2>
                    <br />
                  </Grid>
                  <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
                    <Table
                      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead sx={{ backgroundColor: "#495579" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "100px !important",
                            }}
                          >
                            Sl.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "700px !important",
                            }}
                          >
                            Appointment Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {modalState && Object.keys(modalState).length > 0 ? (
                          Object.entries(modalState).map((value, id) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{id + 1}</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {value[1]?.["college"]?.value || "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{ color: "#000" }}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
