import { createContext, useState } from "react";

export const PostContext = createContext();

export const PostContextProvider = ({ children }) => {
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [stateOpen, setStateOpen] = useState(false);
  const [distOpen, setDistOpen] = useState(false);
  const [collegeOpen, setCollegeOpen] = useState(false);
  const [appointmentOpen, setAppointmentOpen] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [displayCount, setDisplayCount] = useState(10);
  let [countryIDx, setCountryIDx] = useState(0);
  let [appointmentStateFilter, setAppointmentStateFilter] = useState(true);
  let [appointmentDistrictFilter, setAppointmentDistrictFilter] =
    useState(true);
  let [appointmentCollegeFilter, setAppointmentCollegeFilter] = useState(true);
  let [appointmentDataFilter, setAppointmentDataFilter] = useState(true);
  const [countsUpdated, setCountsUpdated] = useState(false);
  // mini
  const [country_mini, setCountry_mini] = useState([]);
  let [countryMiniIDx, setCountryMiniIDx] = useState(0);

  const [appointmentOrg, setAppointmentOrg] = useState([]);
  const [appointmentOrgOpen, appointmentOrgOpenOpen] = useState(false);

  const [organaisation, setOrganaisation] = useState([]);
  const [organaisationOpen, setOrganaisationOpen] = useState(false);

  const [orgBranch, setOrgBranch] = useState([]);
  const [orgBranchOpen, setOrgBranchOpen] = useState(false);

  // search filters
  const [countryURL, setCountryURL] = useState(`/country?name=india`);

  //sattee filter
  const [stateURL, setStateURL] = useState(`/state?limit=10`);

  //district filter
  const [districtURL, setDistrictURL] = useState(`/district?limit=10`);

  //College filter
  const [collegeURL, setCollegeURL] = useState(`/college?limit=10`);

  //Appointment filter
  const [appointmentURL, setAppointmentURL] = useState(`/appointment?limit=10`);

  // mini
  const [countryMiniURL, setCountryMiniURL] = useState(`/country?name=india`);

  //Organaisation

  const [organaisationURL, setOrganaisationURL] = useState(
    `/organization?limit=10`
  );
  const [collegeFilter, setCollegeFilter] = useState(false);
  const [branchURL, setBranchURL] = useState(`/branch?limit=10`);

  return (
    <PostContext.Provider
      value={{
        expanded,
        setExpanded,
        country,
        setCountry,
        states,
        setStates,
        district,
        setDistrict,
        collegeFilter,
        setCollegeFilter,
        colleges,
        setColleges,
        appointments,
        setAppointments,
        stateOpen,
        setStateOpen,
        distOpen,
        setDistOpen,
        collegeOpen,
        setCollegeOpen,
        appointmentOpen,
        setAppointmentOpen,
        displayCount,
        setDisplayCount,
        organaisation,
        setOrganaisation,
        organaisationOpen,
        setOrganaisationOpen,
        orgBranch,
        setOrgBranch,
        orgBranchOpen,
        setOrgBranchOpen,
        branchURL,
        setBranchURL,
        //search filters
        stateURL,
        setStateURL,
        districtURL,
        setDistrictURL,
        collegeURL,
        setCollegeURL,
        appointmentURL,
        setAppointmentURL,
        countryIDx,
        setCountryIDx,
        organaisationURL,
        setOrganaisationURL,
        appointmentStateFilter,
        setAppointmentStateFilter,
        appointmentDistrictFilter,
        setAppointmentDistrictFilter,
        appointmentCollegeFilter,
        appointmentDataFilter,
        setAppointmentDataFilter,
        setAppointmentCollegeFilter,
        countsUpdated,
        setCountsUpdated,
        countryMiniURL,
        setCountryMiniURL,
        countryURL,
        setCountryURL,
        country_mini,
        setCountry_mini,
        countryMiniIDx,
        setCountryMiniIDx,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
