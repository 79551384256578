import React from "react";
import styles from "./loader.module.css"

const Loader = () => {
  return (
   <section style={{height:"30vh",width:"50vw",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
     <span class={styles.loader}></span>
     <p>Dowloading Please wait...</p>
   </section>
  );
};

export default Loader;
