import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  Grid,
} from "@mui/material";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import AxiosInstance from "../../../apis/AxiosInstance";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import Loader from "../../../pages/Loader";
import moment from "moment";
import EditGDModalInTree from "./EditGDModalInTree";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "97vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "250px",
  height: "250px",
  bgcolor: "background.paper",
  borderRadius: "5px",
  pb: 2,
  zIndex: "1000",
  display: "flex",
  alignItems: "end",
};

export default function GDGroupCountListInAppointment(props) {
  let [college_Id, setCollege_Id] = React.useState(props.college_idx);
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx
  );
  const downloadRef = React.useRef();
  let [regdStudents, setRegdStudents] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const toIsoFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const [csvData, setCsvData] = React.useState([]);
  const headers = [
    // { label: "ID", key: "id" },
    { label: "Group Name", key: "group_name" },
    { label: "Interviewer", key: "interviewer" },
    // { label: "Total Students", key: "total_student" },
    { label: "Students Name", key: "name" },
    { label: "Students Email", key: "email" },
    // { label: "Appointment Count", key: "appointment_count" },
    { label: "Students Mobile Number", key: "mobile" },
    { label: "Students Whatsapp Number", key: "whatsapp_number" },
    { label: "Start Date Time", key: "start_date_time" },
    { label: "End Date Time", key: "end_date_time" },
  ];

  const handleChangePage = async (event, newPage) => {
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `group_list?page=${newPage}&appointment_id=${appointment_id}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = [];
        response.data.data.forEach((item) => {
          Array(item.total_student).forEach((sub, subIn) => {
            formattedData.push({
              ...item,
              group_name:
                item.group_name === null || item.group_name === ""
                  ? "NA"
                  : item.group_name,
              interviewer: `${
                item.interviewer === "" || item.interviewer === null
                  ? "N/A"
                  : Object.values(item.interviewer).map((e, d) => e?.["name"])
              }`,
              // total_student: `${
              //   item.total_student == null || item.total_student == ""
              //     ? "NA"
              //     : item.total_student
              // }`,
              name: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["name"]
              }`,
              email: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["email"]
              }`,
              mobile: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["mobile"]
              }`,
              whatsapp_number: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["whatsapp_number"]?.number ||
                    "N/A"
              }`,
              start_date_time: `${
                item.start_date_time === null || item.start_date_time === ""
                  ? "N/A"
                  : moment(toIsoFormat(item.start_date_time))
                      .local()
                      .format("DD-MM-yyyy hh:mm A")
              }`,
              end_date_time: `${
                item.end_date_time === null || item.end_date_time === ""
                  ? "N/A"
                  : moment(toIsoFormat(item.end_date_time))
                      .local()
                      .format("DD-MM-yyyy hh:mm A")
              }`,
            });
          });
        });
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
      setPage(newPage);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const loadAllData = async (e) => {
    const totalPage = Math.ceil(totalLength / 10);
    try {
      let arr = [];
      for (let i = 1; i <= totalPage; i++) {
        let { data } = await AxiosInstance.get(
          `/group_list?page=${i}&appointment_id=${appointment_id}`
        );
        arr = [...arr, ...data.data];
      }
      const formattedData = [];
      arr.forEach((item) => {
        for (let i = 1; i <= item.total_student; i++) {
          formattedData.push({
            group_name:
              item.group_name === null || item.group_name === ""
                ? "NA"
                : item.group_name,
            interviewer: `${
              item.interviewer === "" || item.interviewer === null
                ? "N/A"
                : Object.values(item.interviewer).map((e, d) => e?.["name"])
            }`,
            name: `${
              !item.student_details
                ? "N/A"
                : item.student_details[i - 1]["name"]
            }`,
            email: `${
              !item.student_details
                ? "N/A"
                : item.student_details[i - 1]["email"]
            }`,
            mobile: `${
              !item.student_details
                ? "N/A"
                : item.student_details[i - 1]["mobile"]
            }`,
            whatsapp_number: `${
              !item.student_details
                ? "N/A"
                : item.student_details[i - 1]["whatsapp_number"]?.number ||
                  "N/A"
            }`,
            start_date_time: `${
              item.start_date_time === null || item.start_date_time === ""
                ? "N/A"
                : moment(toIsoFormat(item.start_date_time))
                    .local()
                    .format("DD-MM-yyyy hh:mm A")
            }`,
            end_date_time: `${
              item.end_date_time === null || item.end_date_time === ""
                ? "N/A"
                : moment(toIsoFormat(item.end_date_time))
                    .local()
                    .format("DD-MM-yyyy hh:mm A")
            }`,
          });
        }
      });
      setCsvData(formattedData);
      setTimeout(() => {
        downloadRef.current.link.click();
        handleClose1();
      }, 1000);
    } catch (error) {
      toast.error("Downloading Failed");
      handleClose1();
    }
  };

  const [loadReg, setLoadRed] = React.useState(false);
  const handleStateOpen = async (e) => {
    handleOpen();
    setLoadRed(true);
    try {
      let response = await AxiosInstance.get(
        `group_list?page=${page}&appointment_id=${appointment_id}`
      );
      if (response.data.data !== null) {
        setRegdStudents(response.data.data);
        setTotalLength(response.data.total_length);
        const formattedData = [];
        response.data.data.forEach((item) => {
          Array(item.total_student).forEach((sub, subIn) => {
            formattedData.push({
              ...item,
              interviewer: `${
                item.interviewer === "" || item.interviewer === null
                  ? "N/A"
                  : Object.values(item.interviewer).map(
                      (e, d) => e?.["name"] + "\n"
                    )
              }`,
              total_student: `${
                item.total_student == null || item.total_student == ""
                  ? "NA"
                  : item.total_student
              }`,
              name: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["name"]
              }`,
              email: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["email"]
              }`,
              mobile: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["mobile"]
              }`,
              whatsapp_number: `${
                !item.student_details
                  ? "N/A"
                  : item.student_details[subIn]["whatsapp_number"]?.number ||
                    "N/A"
              }`,
              start_date_time: `${
                item.start_date_time === null || item.start_date_time === ""
                  ? "N/A"
                  : moment(toIsoFormat(item.start_date_time))
                      .local()
                      .format("DD-MM-yyyy hh:mm A")
              }`,
              end_date_time: `${
                item.end_date_time === null || item.end_date_time === ""
                  ? "N/A"
                  : moment(toIsoFormat(item.end_date_time))
                      .local()
                      .format("DD-MM-yyyy hh:mm A")
              }`,
            });
          });
        });
        setCsvData(formattedData);
        setLoadRed(false);
      }
      setLoadRed(false);
    } catch (error) {
      setLoadRed(false);
      console.log(error);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  const totalPages = Math.ceil(totalLength / 10);

  const gridContainer = {
    margin: "0 auto",
    gap: 20,
  };

  //Modal Data
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [modalState, setModalState] = useState(null);
  const handleOpenModal1 = () => {
    setModalOpen1(true);
  };

  const handleCloseModal1 = () => {
    setModalOpen1(false);
    setModalState(null);
  };

  const paperStyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    p: 2,
    height: "auto",
    zIndex: "999",
  };

  return (
    <div>
      <Tooltip title="GD Group Count Details">
        <p
          onClick={handleStateOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          />
        </p>
      </Tooltip>
      <Modal
        open={open1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <Loader />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            GD Group Count Details{" "}
            <Button
              style={{ background: "green", marginLeft: "10%", color: "#fff" }}
              onClick={(e) => {
                handleOpen1();
                loadAllData();
              }}
            >
              Download CSV File
            </Button>
            <Button
              style={{
                marginLeft: "10%",
                color: "#06113C",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Total Count : {totalLength}
            </Button>
            <CSVLink
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
              headers={headers}
              filename={`GD-Group-Count List.csv`}
              ref={downloadRef}
              data={csvData}
              target="_blank"
            />
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "100px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "900px !important",
                    }}
                  >
                    Group Name
                  </TableCell>
               
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                      alignItems: "center",
                    }}
                  >
                    Interviewer
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "150px !important",
                    }}
                  >
                    Total Students
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "450px !important",
                    }}
                  >
                    Students Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "300px !important",
                    }}
                  >
                    Appointment Count
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "350px !important",
                    }}
                  >
                    Students Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Students Mobile Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    Whatsapp Number
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    GD Start Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    GD End Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "15px",
                      width: "250px !important",
                    }}
                  >
                    GD Edit
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {loadReg ? (
                  <TableRow align="center" colSpan={3}>
                    Loading...
                  </TableRow>
                ) : regdStudents.length > 0 ? (
                  regdStudents.map((value, id) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" sx={{ color: "#000" }}>
                          {calculateSlNo(id)}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#000",
                            width: "600px !important",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {value.group_name == null ? "NA" : value.group_name}
                        </TableCell>
                      
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "150px !important" }}
                        >
                          {value.interviewer === null ||
                          value.interviewer === ""
                            ? "N/A"
                            : Object.values(value.interviewer).map((ea) => (
                                <Box style={{ height: "22px" }}>
                                  {ea?.["name"]}
                                </Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.total_student == null ||
                          value.total_student == ""
                            ? "NA"
                            : value.total_student}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.student_details === null ||
                          value.student_details === ""
                            ? "N/A"
                            : value.student_details.map((ea) => (
                                <Box style={{ height: "22px" }}>
                                  {ea?.["name"]}
                                </Box>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              {/* {value.student_details.map((val) => (
                                <Box
                                  style={{ height: "22px", display: "flex" }}
                                >
                                  {val?.["appointment_count"]} &nbsp;&nbsp;
                                  <Tooltip title="Appointment List">
                                    <p
                                      onClick={() => {
                                        handleOpenModal1();
                                        setModalState(val.appointment);
                                      }}
                                      sx={{
                                        "&:hover": { background: "#DBDFFD" },
                                      }}
                                    >
                                      <BsFillEyeFill
                                        fontSize={17}
                                        style={{
                                          color: "#646FD4",
                                          cursor: "pointer",
                                          "&:hover": { color: "#EB455F" },
                                        }}
                                      />
                                    </p>
                                  </Tooltip>
                                </Box>
                              ))} */}
                              {value.student_details ? (
                                value.student_details.map((val) => (
                                  <Box
                                    key={val.id}
                                    style={{ height: "22px", display: "flex" }}
                                  >
                                    {val?.["appointment_count"]} &nbsp;&nbsp;
                                    <Tooltip title="Appointment List">
                                      <p
                                        onClick={() => {
                                          handleOpenModal1();
                                          setModalState(val.appointment);
                                        }}
                                        sx={{
                                          "&:hover": { background: "#DBDFFD" },
                                        }}
                                      >
                                        <BsFillEyeFill
                                          fontSize={17}
                                          style={{
                                            color: "#646FD4",
                                            cursor: "pointer",
                                            "&:hover": { color: "#EB455F" },
                                          }}
                                        />
                                      </p>
                                    </Tooltip>
                                  </Box>
                                ))
                              ) : (
                                <span>NA</span>
                              )}
                            </p>
                          </p>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.student_details === null ||
                          value.student_details === ""
                            ? "N/A"
                            : value.student_details.map((ea) => (
                                <div>
                                  {ea?.["email"].map((va) => (
                                    <div>{va}</div>
                                  ))}
                                </div>
                              ))}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {/* {(value.student_details &&
                            value.student_details === null) ||
                          value.student_details === ""
                            ? "N/A"
                            : value.student_details.map((ea) => (
                                <div>
                                  {ea?.["mobile"].map((va) => (
                                    <div>{va}</div>
                                  ))}
                                </div>
                              ))} */}
                          {value.student_details === null ||
                          value.student_details === ""
                            ? "N/A"
                            : value.student_details.map((ea) => (
                                <div>
                                  {ea?.["mobile"].map((va) => (
                                    <div>{va}</div>
                                  ))}
                                </div>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.student_details === null ||
                          value.student_details === "" ||
                          !value.student_details.some(
                            (ea) =>
                              ea.whatsapp_number &&
                              Object.keys(ea.whatsapp_number).length > 0
                          )
                            ? "N/A"
                            : value.student_details.map((ea, index) => (
                                <div key={index}>
                                  {ea.whatsapp_number &&
                                  Object.keys(ea.whatsapp_number).length > 0
                                    ? ea.whatsapp_number.number
                                    : "NA"}
                                </div>
                              ))}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          {value.start_date_time === null ||
                          value.start_date_time === ""
                            ? "N/A"
                            : moment(toIsoFormat(value.start_date_time))
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                         {value.end_date_time === null ||
                          value.end_date_time === ""
                            ? "N/A"
                            : moment(toIsoFormat(value.end_date_time))
                                .local()
                                .format("DD-MM-yyyy hh:mm A")}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "#000", width: "300px !important" }}
                        >
                          <Tooltip title="Edit">
                            <EditGDModalInTree stateAddresssing={value} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={11}>
                      <h3 style={{ color: "red" }}>No Data Found</h3>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{ maxWidth: "100%", marginTop: "2%", marginLeft: "45%" }}
            count={totalPages}
            sx={{ button: { color: "#1976D2", fontWeight: "bold" } }}
            page={page}
            onChange={handleChangePage}
          />
          <Modal open={isModalOpen1} onClose={handleCloseModal1}>
            <Grid style={{ marginTop: "3%" }}>
              <Grid xs={12} item container style={gridContainer} align="center">
                <Paper elevation={10} style={paperStyle1}>
                  <Grid align="center" item>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AiFillCloseSquare
                        fontSize={30}
                        onClick={handleCloseModal1}
                        color="#FC2947"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <h2>Appointment List</h2>
                    <br />
                  </Grid>
                  <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table
                      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead sx={{ backgroundColor: "#495579" }}>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "100px !important",
                            }}
                          >
                            Sl.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              color: "#fff",
                              backgroundColor: "#495579",
                              fontWeight: "600",
                              fontSize: "16px",
                              width: "700px !important",
                            }}
                          >
                            Appointment Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ overflow: "auto" }}>
                        {modalState && Object.keys(modalState).length > 0 ? (
                          Object.entries(modalState).map((value, id) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{id + 1}</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                >
                                  {value[1]?.["college"]?.value || "N/A"}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{ color: "#000" }}
                            >
                              No Data
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
}
