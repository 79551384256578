import * as React from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useState, useEffect } from "react";
import AxiosInstance from "../../../apis/AxiosInstance";
import {
  Box,
  Table,
  TableBody,
  MenuItem,
  Select,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  Paper,
  FormControl,
  Grid,
  Modal,
} from "@mui/material";
import AssignQuiz from "../assignQuiz/AssignQuiz";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#081F37",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function QuizList() {
  const [expandedRow, setExpandedRow] = React.useState(null);
  const [eventList, setEventList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  let [subjectOptions, setSubjectOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleAccordion = (rowIndex) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowIndex);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    let fetchData = async () => {
      let quizData = await AxiosInstance.get(`/event`);
      let finalData = quizData.data.response;
      setEventList(finalData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      let subjectData = await AxiosInstance.get(`/subjects`);
      let finalSubject = subjectData.data.data;

      setSubjectOptions(finalSubject);
    };
    fetchData();
  }, []);

  return (
    <section style={{ width: "100%", marginTop: "4%" }}>
      <Box
        sx={{
          height: "6vh",
          width: "100%",
          background: "#DDE6ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "2px solid #9BA4B5",
        }}
      >
        <Box
          sx={{
            flexBasis: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#081f37",
            fontSize: "34px",
            textTransform: "capitalize",
            fontWeight: "600",
            letterSpacing: "1.02px",
          }}
        >
          QUIZ LIST
        </Box>
      </Box>
      <Grid
        item
        style={{
          position: "relative",
          width: "15%",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2%",
        }}
      >
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Subjects</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedSubject}
            label="selectedSubject"
            onChange={(e) => {
              setSelectedSubject(e.target.value);
            }}
          >
            {subjectOptions.map((val, ind) => {
              return (
                <MenuItem key={ind} value={val.name}>
                  {val.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <article
        style={{
          width: "90%",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          border: "1px solid #081F37",
          marginTop: "2%",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Event Name</StyledTableCell>
                <StyledTableCell align="center">Subject</StyledTableCell>
                <StyledTableCell align="center">No.of Question</StyledTableCell>
                <StyledTableCell align="center">Marks</StyledTableCell>
                <StyledTableCell align="center">Duration</StyledTableCell>
                <StyledTableCell align="center">Assigned</StyledTableCell>
                <StyledTableCell align="center">Assign</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eventList.map((row, index) => (
                <React.Fragment>
                  <StyledTableRow onClick={() => toggleAccordion(index)}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.section_field?.["1"].subject.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {Object.values(row.section_field[1].question).length}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.section_field?.["1"].generate_marks}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.duration.value}
                    </StyledTableCell>
                    <StyledTableCell align="center">View</StyledTableCell>
                    <StyledTableCell align="center">
                      <button onClick={openModal}>Assign</button>
                    </StyledTableCell>
                  </StyledTableRow>
                  <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    BackdropProps={{
                      style: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
                    }}
                  >
                    {/* Modal content */}
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxHeight: "90%", // Set your desired max height here
                        backgroundColor: "white",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        padding: 10,
                        overflowY: "auto", // Add overflowY for scrolling if content exceeds the max height
                      }}
                    >
                      <button
                        onClick={handleCloseModal}
                        style={{
                          position: "fixed",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "30px",
                          color: "red",
                          zIndex: 99,
                        }}
                      >
                        &#x2716;
                      </button>
                      {/* Content of your modal */}
                      <AssignQuiz />
                    </div>
                  </Modal>

                  {/* <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={8}
                    >
                      <Collapse
                        in={expandedRow === index}
                        timeout="auto"
                        unmountOnExit
                      >
                     
                        <Table size="small">
                          <TableBody>
                            {Object.values(row.section_field[1].question)
                              .length < 1 ? (
                              <TableRow>
                                <TableCell colSpan={8} align="center">
                                  Question Not Available
                                </TableCell>
                              </TableRow>
                            ) : (
                              Object.values(row.section_field[1].question).map(
                                (val, ind) => {
                                  return (
                                    <TableRow key={ind + 1}>
                                      <TableCell align="center">
                                      <p style={{width:"40%"}}>  {val.mark}</p>
                                      </TableCell>
                                      <TableCell align="center">
                                      <p style={{width:"25%"}}>  {val.mark}</p>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )
                            )}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow> */}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </article>
    </section>
  );
}
