import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Grid,
  Paper,
  TextField,
  Box,
  Checkbox,
} from "@mui/material";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AxiosInstance from "../apis/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";

const IndivisualSubStatusEdit = () => {
  const navigate = useNavigate();
  let { state } = useLocation();

  const [categoryName, setCategoryName] = useState(state.val.category);

  const [mainData, setMainData] = useState([
    {
      id: state.item.id,
      main_status: state.item.main_status,
      main_statusWeight: parseInt(state.item.weight),
      subData: state.subItem
        ? [
            {
              subStatus: state.subItem.sub_status,
              id: state.subItem.id,
              subStatusWeight: state.subItem.weight,
              positive_key: state.subItem.positive,
              subStatus_disable: state.subItem.disable,
            },
          ]
        : [],
    },
  ]);

  const addField = (id) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    if (!Array.isArray(target["subData"])) {
      target["subData"] = [];
    }

    target["subData"] = [
      ...target["subData"],
      {
        subStatus: "",
        subStatusWeight: "",
        positive_key: false,
        subStatus_disable: false,
      },
    ];
    arr[id] = target;
    setMainData(arr);
  };

  const removeSub = (id, index) => {
    const arr = [...mainData];
    const target = { ...arr[id] };
    target["subData"].splice(index, 1);
    arr[id] = target;
    setMainData(arr);
  };

  const handleSubChange = (id, index, field) => {
    const { name, value, type, checked } = field; 
    const arr = [...mainData];
    const target = { ...arr[id] };
    const fields = [...target["subData"]];

    // Check if the field type is a checkbox
    if (type === "checkbox") {
      fields[index] = { ...fields[index], [name]: checked }; 
    } else {
      fields[index] = { ...fields[index], [name]: value };
    }

    target["subData"] = fields;
    arr[id] = target;
    setMainData(arr);
  };

  const handleMainChange = (index, name, value) => {
    const arr = [...mainData];
    const target = { ...arr[index] };
    target[name] = value;
    arr[index] = target;
    setMainData(arr);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hasEmptySubStatus = mainData.some((data) => {
      if (Array.isArray(data.subData)) {
        return data.subData.some((sub) => sub.subStatus === "");
      }
      return true;
    });

    if (hasEmptySubStatus) {
      toast.error("Please add sub-status fields");
      return;
    }

    try {
      const payload = {
        category_id: state.val.ID,
        main_status_id: mainData[0].id,
        sub_status: mainData[0].subData.map((sub) => ({
          id: sub.id,
          sub_status: sub.subStatus,
          weight: parseInt(sub.subStatusWeight),
          positive: sub.positive_key,
          disable: sub.subStatus_disable,
        })),
      };

      await AxiosInstance.post(`/call_sub_status`, payload)
        .then((response) => {
          toast.success("Call Substatus Updated Successfully");
          setTimeout(() => {
            navigate("/call_category_list");
          }, 1500);
        })
        .catch((error) => {
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Something Went wrong");
          }
        });
    } catch (error) {
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Something Went wrong");
      }
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
    border: "1px solid gray",
    borderRadius: "10px",
  };
  const avatarStyle = { backgroundColor: "#1B4242" };
  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#1B4242",
    color: "#fff",
    border: "1px solid #1B4242",
  };
  const btnstyle1 = {
    margin: "8px 0",
    backgroundColor: "crimson",
    color: "#fff",
    border: "1px solid crimson",
  };

  return (
    <Box
      style={{
        marginTop: "4.1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // gap: "10px",
      }}
    >
      <Grid xs={12} item container align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <Avatar style={avatarStyle}>
              <GroupAddIcon />
            </Avatar>
            <h2>Edit Call Substatus</h2>
            <br />
          </Grid>
          <form action="" onSubmit={handleSubmit}>
            <Grid
              item
              container
              sm={12}
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                marginTop: "-2%",
              }}
            >
              <Grid item sm={12}>
                <TextField
                  size="small"
                  fullWidth
                  name="categoryName"
                  label="Category"
                  disabled
                  required
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderColor: "rgba(0, 0, 0, 10)",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 5)",
                      },
                    },
                  }}
                />
              </Grid>
              <Box
                style={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {mainData.map((val, ind) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      style={{
                        border: "1px solid #06113C",
                        marginTop: "5px",
                        margin: "0 auto",
                        marginLeft: "2%",
                        paddingBottom: "15px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        style={{
                          color: "blue",
                          textAlign: "start",
                        }}
                      >
                        Main Status
                      </Grid>
                      <Grid item md={7} xs={12} style={{ marginTop: "-0.5%" }}>
                        <TextField
                          fullWidth
                          name="main_status"
                          label="Main Status"
                          required
                          disabled
                          value={val.main_status}
                          onChange={(e) =>
                            handleMainChange(ind, "main_status", e.target.value)
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4.5}
                        xs={12}
                        style={{ marginTop: "-0.5%" }}
                      >
                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderColor: "rgba(0, 0, 0, 10)",
                              "& fieldset": {
                                borderColor: "rgba(0, 0, 0, 5)",
                              },
                            },
                          }}
                          fullWidth
                          required
                          disabled
                          name="main_statusWeight"
                          label="Main Status Weight"
                          onKeyPress={preventMinus}
                          onPaste={preventPasteNegative}
                          inputProps={{ minLength: 1, maxLength: 2 }}
                          value={val.main_statusWeight}
                          onChange={(e) =>
                            handleMainChange(
                              ind,
                              "main_statusWeight",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                          marginTop: "-0.3%",
                          color: "blue",
                          textAlign: "start",
                        }}
                      >
                        Sub Status
                      </Grid>
                      {val.subData.length > 0 ? (
                        val.subData.map((value, index) => {
                          return (
                            <Grid
                              item
                              container
                              sm={12}
                              spacing={2}
                              style={{
                                margin: "0 auto",
                                marginLeft: "1%",
                                paddingBottom: "15px",
                              }}
                            >
                              <Grid item md={4} style={{ marginTop: "-2.2%" }}>
                                <TextField
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                  fullWidth
                                  name="subStatus"
                                  label="Sub Status"
                                  value={value.subStatus}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                md={2.9}
                                style={{ marginTop: "-2.2%" }}
                              >
                                <TextField
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "rgba(0, 0, 0, 10)",
                                      "& fieldset": {
                                        borderColor: "rgba(0, 0, 0, 5)",
                                      },
                                    },
                                  }}
                                  fullWidth
                                  name="subStatusWeight"
                                  label="Sub Status Weight"
                                  onKeyPress={preventMinus}
                                  onPaste={preventPasteNegative}
                                  inputProps={{ minLength: 1, maxLength: 2 }}
                                  value={value.subStatusWeight}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                sm={2.2}
                                style={{ marginTop: "-2.2%" }}
                              >
                                <Checkbox
                                  name="positive_key"
                                  checked={value.positive_key}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                  id="positive_key"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />{" "}
                                <label
                                  htmlFor="positive_key"
                                  style={{ fontSize: "16px" }}
                                >
                                  Positive Key
                                </label>
                              </Grid>
                              <Grid
                                item
                                sm={2.2}
                                style={{ marginTop: "-2.2%" }}
                              >
                                <Checkbox
                                  name="subStatus_disable"
                                  checked={value.subStatus_disable}
                                  onChange={(e) =>
                                    handleSubChange(ind, index, e.target)
                                  }
                                  id="subStatus_disable"
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                />{" "}
                                <label
                                  htmlFor="subStatus_disable"
                                  style={{ fontSize: "16px" }}
                                >
                                  Disable
                                </label>
                              </Grid>
                              
                            </Grid>
                          );
                        })
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            color: "red",
                            padding: "5px 10px",
                            background: "lightgrey",
                            width: "100%",
                          }}
                        >
                          No Data Found
                        </p>
                      )}
                      <Grid item sm={2} style={{ marginTop: "-3%" }}>
                        <Button
                          style={{
                            border: "none",
                            background: "#87A922",
                            color: "#fff",
                            borderRadius: "5px",
                            marginTop: "10%",
                          }}
                          onClick={() => addField(ind)}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Grid>

            <Grid
              item
              container
              sm={12}
              spacing={4}
              // style={{ marginTop: "1%" }}
            >
              <Grid item sm={2}>
                <Button
                  color="primary"
                  variant="contained"
                  style={btnstyle1}
                  fullWidth
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sm={2}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={btnstyle}
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default IndivisualSubStatusEdit;
