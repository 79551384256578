import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Container,
  Box,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AxiosInstance from "../../../apis/AxiosInstance";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { RxCross1 } from "react-icons/rx";

//====================================

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DDE6ED",
    color: "#111",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function GDEvaluationCriteria() {
  const Navigate = useNavigate();

  //Select Rounds
  const [roundsOption, setRoundsOption] = useState([]);
  const [rounds, setRounds] = useState("");

  //Exam type list

  let [status, setStatus] = useState("");
  let [statusOption, setStatusOption] = useState(null);

  const [criteriaTake, setCriteriaTake] = useState(false);

  const handleCriteriaTake = (e) => {
    setCriteriaTake(e.target.checked);
  };

  const [criteria, setCriteria] = useState([]);

  // Rounds
  useEffect(() => {
    let fetchData = async () => {
      let examTypeData = await AxiosInstance.get(`/college_appointment_rounds`);
      let finalExamTypeData = examTypeData.data.data;
      setRoundsOption(finalExamTypeData);
    };
    fetchData();
  }, []);

  //for appointmentStatus
  useEffect(() => {
    let fetchData = async () => {
      let appStatus = await AxiosInstance.get(`/status_for_evaluation`);
      let finalAppStatus = appStatus.data.data;
      setStatusOption(finalAppStatus);
    };

    fetchData();
  }, []);

  // const [gdEvaluations, setGDEvaluations] = useState([{ name: "", marks: "" }]);

  const [gdEvaluations, setGDEvaluations] = useState([
    { name: "", marks: "", status: "", selected: false },
  ]);

  const handleAddButtonClick = () => {
    setGDEvaluations([...gdEvaluations, { name: "", marks: "", status: "" }]);
  };

  // const handleInputChange = (index, field, value) => {
  //   const updatedEvaluations = [...gdEvaluations];
  //   if (field === "status") {
  //     updatedEvaluations[index][field] = value;
  //   } else {
  //     updatedEvaluations[index][field] = value;
  //   }
  //   setGDEvaluations(updatedEvaluations);
  // };

  const handleInputChange = (index, field, value) => {
    const updatedEvaluations = [...gdEvaluations];

    if (field === "status" || field === "selected") {
      updatedEvaluations[index][field] = value;
    } else {
      updatedEvaluations[index][field] = value;
    }

    setGDEvaluations(updatedEvaluations);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));

    try {
      const payload = {
        round: rounds,
        criteria: gdEvaluations.map((evaluation) => ({
          name: evaluation.name,
          marks: parseInt(evaluation.marks),
          status: evaluation.status,
          take: evaluation.selected ? true : false,
        })),
      };

      await AxiosInstance.post("/college_appointment_rounds", payload, {
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("GD Evaluation Criteria Created Successfully");
            setTimeout(() => {
              Navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCancelClick = (index) => {
    const updatedEvaluations = [...gdEvaluations];
    updatedEvaluations.splice(index, 1);
    setGDEvaluations(updatedEvaluations);
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <Paper
        style={{
          width: "99%",
          height: "auto",
          margin: "0 auto",
          marginTop: "5%",
          border: "2px solid #9BA4B5",
          borderRadius: "10px",
        }}
      >
        {/* <Box
          sx={{
            height: "6vh",
            width: "70%",
            margin: "0 auto",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "2px solid #9BA4B5",
          }}
        >
          <Box
            sx={{
              flexBasis: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#081f37",
              fontSize: "34px",
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
            }}
          >
            Evaluation Criteria
          </Box>
        </Box> */}
        <Box
          sx={{
            width: "100%",
            background: "#DDE6ED",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "2px solid #9BA4B5",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#081f37",
              fontSize: {
                xs: "20px", // Smaller font size on small screens
                sm: "25px", // Medium font size on small to medium screens
                md: "30px", // Larger font size on medium to large screens
              },
              textTransform: "capitalize",
              fontWeight: "600",
              letterSpacing: "1.02px",
              justifyContent: "center",
              margin: "0 auto",
            }}
          >
            Evaluation Criteria
          </Box>
        </Box>

        <Paper
          variant="outlined"
          style={{ width: "100%" }}
        >
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} xs={12} style={{ marginTop: "0.6%" }}>
              <Grid
                item
                md={3}
                xs={12}
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                Rounds:
              </Grid>

              <Grid item md={7} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Rounds</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rounds}
                    required
                    label="Rounds"
                    onChange={(e) => {
                      setRounds(e.target.value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 10)", // Default border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Round
                    </MenuItem>
                    {roundsOption.map((round) => (
                      <MenuItem key={round.ID} value={round.ID}>
                        {round.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  overflowY: "auto",
                  whiteSpace: "nowrap",
                  marginTop: "2%",
                  marginLeft: "2%",
                }}
              >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Select
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Criteria Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Criteria Marks
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          width: "400px",
                        }}
                      >
                        Status
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontWeight: "bold", fontSize: "15px" }}
                      >
                        Cancel
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gdEvaluations.map((evaluation, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          <Checkbox
                            name={`criteriaTake_${index}`}
                            checked={evaluation.selected}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "selected",
                                e.target.checked
                              )
                            }
                            id={`criteriaTake_${index}`}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          <TextField
                            name={`gdEvaluationName_${index}`}
                            fullWidth
                            required
                            id={`gdEvaluationName_${index}`}
                            label="Enter Criteria Name"
                            value={evaluation.name}
                            onChange={(e) =>
                              handleInputChange(index, "name", e.target.value)
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          <TextField
                            label="Enter Criteria Marks"
                            type="tel"
                            fullWidth
                            required
                            onKeyPress={preventMinus}
                            onPaste={preventPasteNegative}
                            inputProps={{ minLength: 1, maxLength: 3 }}
                            name={`gdEvaluationMarks_${index}`}
                            value={evaluation.marks}
                            require
                            onChange={(e) =>
                              handleInputChange(index, "marks", e.target.value)
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderColor: "rgba(0, 0, 0, 10)",
                                "& fieldset": {
                                  borderColor: "rgba(0, 0, 0, 5)",
                                },
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          <FormControl style={{ width: "80%" }}>
                            <InputLabel id={`status-label-${index}`}>
                              Status
                            </InputLabel>
                            <Select
                              labelId={`status-label-${index}`}
                              id={`status-select-${index}`}
                              required
                              value={evaluation.status}
                              label="Status"
                              onChange={(e) => {
                                handleInputChange(
                                  index,
                                  "status",
                                  e.target.value
                                );
                              }}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", // Default border color
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 10)", // Same color on hover
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(0, 0, 0, 5)", // Same color on focus
                                },
                              }}
                            >
                              {statusOption === null ? (
                                <MenuItem>No data Available</MenuItem>
                              ) : (
                                statusOption.map((e) => {
                                  return (
                                    <MenuItem
                                      value={e.id}
                                      key={`status-${e.id}`}
                                    >
                                      <p style={{ textAlign: "left" }}>
                                        {e.name}
                                      </p>
                                    </MenuItem>
                                  );
                                })
                              )}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          {/* <Button
                                onClick={() => handleCancelClick(index)}
                                style={{ background: "#EF4040", color: "#fff" }}
                              >
                                Remove
                              </Button> */}
                          <h1
                            style={{
                              fontSize: "25px",
                              color: "red",
                              fontWeight: "900",
                              cursor: "pointer",
                            }}
                            onClick={() => handleCancelClick(index)}
                          >
                            <RxCross1 />
                          </h1>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <Grid style={{ padding: "10px" }}>
                    <Button
                      onClick={handleAddButtonClick}
                      style={{
                        width: "20%",
                        background: "lightgreen",
                        color: "#111",
                        fontWeight: "bold",
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              md={4}
              item
              spacing={1}
              style={{
                textAlign: "center",
                padding: "5px",
                marginTop: "1%",
              }}
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </form>
        </Paper>
      </Paper>
    </section>
  );
}
