import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Pagination,
  Button,
} from "@mui/material";
import { BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import { useState } from "react";
import OnboardingJoinedList from "./OnboardingJoinedList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  height: "auto",
  zIndex: "999",
};

export default function StudentJoinedList(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStateOpen = async (e) => {
    handleOpen();
  };
  let [appointment_id, setAppontment_id] = React.useState(
    props.appointment_idx
  );

  if (!appointment_id || !appointment_id.post_addressing) {
    return <div>No video data available</div>;
  }

  const videoData = appointment_id.post_addressing;

  return (
    <div>
      <Tooltip title="Onboarding Joined Students Details">
        <p
          onClick={handleStateOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          {/* <BsFillEyeFill
            fontSize={17}
            style={{
              color: "#646FD4",
              cursor: "pointer",
              "&:hover": { color: "#EB455F" },
            }}
          /> */}

          <Button
            style={{
              borderRadius: "5px",
              color: "#fff",
              background: "#495579",
            }}
          >
            View details
          </Button>
        </p>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "24px", fontWeight: "bold" }}
          >
            Onboarding Joined Student Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead sx={{ backgroundColor: "#495579" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "100px !important",
                    }}
                  >
                    Video Id
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "#fff",
                      backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "17px",
                      width: "200px !important",
                    }}
                  >
                    Joined Counts
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {Object.entries(videoData).length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <h2 style={{ color: "#D74B76" }}> No data found</h2>
                    </TableCell>
                  </TableRow>
                ) : (
                  Object.entries(videoData).map(([postId, post]) => {
                    if (!post.video) {
                      return null;
                    }
                    return Object.entries(post.video).map(
                      ([videoId, video]) => {
                        if (!video || !video.video_name) {
                          return null;
                        }

                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={videoId}
                          >
                            <TableCell align="center" sx={{ color: "#000" }}>
                              {videoId}
                            </TableCell>
                            <TableCell align="center" sx={{ color: "#000" }}>
                              {video.video_name}
                            </TableCell>
                            <TableCell align="center" sx={{ color: "#000" }}>
                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p> {video.joined_student_count}</p>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <p
                                  style={{
                                    fontSize: "1px",
                                  }}
                                >
                                  {" "}
                                  <OnboardingJoinedList
                                    appointment_idx1={appointment_id.id}
                                    appointment_idx2={appointment_id}
                                    video_idx={videoId}
                                  />
                                </p>
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
