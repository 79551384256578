import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  Checkbox,
  Container,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Cookies from "js-cookie";
import AxiosInstance from "../apis/AxiosInstance";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

//! Mobile field validation
const preventMinus = (e) => {
  if (isNaN(e.key)) {
    e.preventDefault();
  }
};
const preventPasteNegative = (e) => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData("text"));
  if (pastedData < 0) {
    e.preventDefault();
  }
};

export default function PostVideo() {
  const Navigate = useNavigate();
  const [videoName, setVideoName] = useState("");
  const [date_time, setDate_time] = useState("");
  const [is_default_video, setIs_default_video] = useState(false);
  const [isDegreeMandatory, setIsDegreeMandatory] = useState(false);
  const [isStreamMandatory, setIsStreamMandatory] = useState(false);

  // const [selectedDegree, setselectedDegree] = useState(null);
  // const [degreeOptions, setDegreeOptions] = useState([]);
  // const [degTerm, setDegTerm] = useState("");
  // const [selectedStream, setselectedStream] = useState(null);
  // const [streamsOptions, setStreamsOptions] = useState([]);
  // const [streamTerm, setStreamTerm] = useState(null);

  const [selectedDegree, setselectedDegree] = useState([]);
  const [degTerm, setDegTerm] = useState("");
  const [degreeOptions, setDegreeOptions] = useState("");
  const [selectedStream, setselectedStream] = useState([]);
  const [streamTerm, setStreamTerm] = useState(null);
  const [streamsOptions, setStreamsOptions] = useState("");

  const [seletedPreviousVideo, setSeletedPreviousVideo] = useState(null);
  const [searchVideo, setSearchVideo] = useState("");
  const [previousVideoOption, setPreviousVideoOption] = useState([]);

  //state and district
  const [countryMini, setCountryMini] = useState([]);
  const [countryMiniId, setCountryMiniId] = useState(0);
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);

  const [stateData, setStateData] = useState(null);
  const [districtData, setDistrictData] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchDistrict, setSearchDistrict] = useState("");

  let [errorForState, setErrorForState] = useState(false);
  let [errorForDistrict, setErrorForDistrict] = useState(false);

  const [duration, setDuration] = useState("");

  //Exam type list

  const [videoTypeOption, setVideoTypeOption] = useState([]);
  const [videoType, setVideoType] = useState("");

  // Exam type
  useEffect(() => {
    let fetchData = async () => {
      let videoData = await AxiosInstance.get(`/video_type_status`);
      let finalVideoData = videoData.data.data;
      setVideoTypeOption(finalVideoData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let fetchData = async (e) => {
      if (searchVideo !== "") {
        let colgStateData = await AxiosInstance.get(
          `/videos?search_keyword=${searchVideo}&limit=10`
        );
        let finalcolgStateData = colgStateData.data.data;
        setPreviousVideoOption(finalcolgStateData);
      } else {
        let colgStateData = await AxiosInstance.get(`/videos?limit=10`);
        let finalcolgStateData = colgStateData.data.data;
        setPreviousVideoOption(finalcolgStateData);
      }
    };
    fetchData();
  }, [searchVideo]);

  useEffect(() => {
    let fetchData = async () => {
      let data = await AxiosInstance.get(`/countrymini?name=india`);
      let finalData = data.data.data;
      setCountryMini(finalData);
      setCountryMiniId(finalData[0].id);
    };
    fetchData();
  }, []);

  //!fetching the apis
  useEffect(() => {
    let fetchData = async (e) => {
      if (searchTerm !== "") {
        // State api call
        let stateData_x = await AxiosInstance.get(
          `/statemini?search_keyword=${searchTerm}&limit=10&country_id=${countryMiniId}`
        );
        let finalStateData_X = stateData_x.data.data;
        setStateOption(finalStateData_X);
      } else {
        // State api call
        let stateData_x = await AxiosInstance.get(
          `/statemini?limit=10&country_id=${countryMiniId}`
        );
        let finalStateData_X = stateData_x.data.data;
        setStateOption(finalStateData_X);
      }
    };
    if (countryMiniId !== 0) {
      fetchData();
    }
  }, [searchTerm, countryMiniId]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (stateData !== null) {
        if (searchDistrict !== "") {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?search_keyword=${searchDistrict}&limit=10&state_id=${stateData.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setDistrictOption(finalcolgDistrictData);
        } else {
          let colgDistrictData = await AxiosInstance.get(
            `/district_mini?limit=10&state_id=${stateData.id}`
          );
          let finalcolgDistrictData = colgDistrictData.data.data;
          setDistrictOption(finalcolgDistrictData);
        }
      }
    };
    fetchData();
  }, [searchDistrict, stateData]);

  // useEffect(() => {
  //   let fetchData1 = async () => {
  //     try {
  //       let degreeData;
  //       if (degTerm !== "") {
  //         degreeData = await AxiosInstance.get(
  //           `/degree_types?search_keyword=${degTerm}&limit=10`
  //         );
  //       } else {
  //         degreeData = await AxiosInstance.get(`/degree_types?limit=10`);
  //       }
  //       setDegreeOptions(degreeData.data.data);
  //     } catch (error) {
  //       console.error("Error fetching degree data:", error);
  //     }
  //   };
  //   fetchData1();
  // }, [degTerm]);

  useEffect(() => {
    let fetchData1 = async (e) => {
      if (degTerm !== "") {
        // college Degree api
        let degreeData = await AxiosInstance.get(
          `/degree_types?search_keyword=${degTerm}&limit=10`
        );
        let finaldegreeData = degreeData.data.data;
        setDegreeOptions(finaldegreeData);
      } else {
        // college Degree api
        let degreeData = await AxiosInstance.get(`/degree_types?limit=10`);
        let finaldegreeData = degreeData.data.data;
        setDegreeOptions(finaldegreeData);
      }
    };
    fetchData1();
  }, [degTerm]);

  // useEffect(() => {
  //   let fetchData2 = async () => {
  //     try {
  //       let QueryParams = selectedDegree
  //         ? [`degree_id=${selectedDegree.id}`]
  //         : [];
  //       const finalUrl =
  //         QueryParams.length > 0
  //           ? `/academic_streams?${QueryParams.join("&")}`
  //           : "/academic_streams";

  //       let streamData;
  //       if (streamTerm !== null) {
  //         streamData = await AxiosInstance.get(
  //           `${finalUrl}&search_keyword=${streamTerm}`
  //         );
  //       } else {
  //         streamData = await AxiosInstance.get(finalUrl);
  //       }

  //       let finalStreamData = streamData.data.data;
  //       setStreamsOptions(finalStreamData);
  //     } catch (error) {
  //       console.error("Error fetching stream data:", error);
  //     }
  //   };
  //   fetchData2();
  // }, [streamTerm, selectedDegree]);

  const [url, setUrl] = useState("/academic_streams");

  useEffect(() => {
    let fetchData2 = async () => {
      let queryParams = selectedDegree.map((value, i) => {
        return `degree_id=${value.id}`;
      });

      const degreeQueryString =
        queryParams.length > 0 ? queryParams.join("&") : "";

      const finalUrl =
        degreeQueryString.length > 0 ? `${url}?${degreeQueryString}` : url;

      try {
        let streamData;

        if (streamTerm !== null) {
          streamData = await AxiosInstance.get(
            `${finalUrl}&search_keyword=${streamTerm}`
          );
        } else {
          streamData = await AxiosInstance.get(finalUrl);
        }

        let finalstreamData = streamData.data.data;

        if (finalstreamData) {
          setStreamsOptions(finalstreamData);
        }
      } catch (error) {
        // Handle the error, e.g., log it or show a user-friendly message
        console.error("Error fetching stream data:", error);
      }
    };

    fetchData2();
  }, [streamTerm, selectedDegree]);

  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedVideo(file);
  };

  const handleDefaultVideo = (event) => {
    setIs_default_video(event.target.checked);
    setIsDegreeMandatory(event.target.checked);
    setIsStreamMandatory(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    const parseLog = (a) => {
      const userData = a ? JSON.parse(a) : { token: "" };
      const { token } = userData;
      return token;
    };
    const token = parseLog(Cookies.get("logged"));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `${token}`,
      },
    };

    try {
      const postVideoDateTime = moment(date_time)
        .utc()
        .format("YYYY-MM-DD HH:mm +SSSS[ UTC]");

      formData.append("video_name", videoName);
      formData.append("date_time", postVideoDateTime);
      formData.append("video_type_id", videoType == 1 ? 1 : 2);
      formData.append("is_default_video", is_default_video);
      formData.append("video", selectedVideo);
      formData.append("duration", Number.parseInt(duration));
      if (seletedPreviousVideo) {
        formData.append("privious_video_id", seletedPreviousVideo.id);
      }
      // if (selectedDegree) {
      //   formData.append("degree_id", selectedDegree.id);
      // }
      // if (selectedDegree) {
      //   formData.append("degree_id",  selectedDegree.map((value) => value.id),);
      // }
      // if (selectedStream) {
      //   formData.append("stream_id", selectedStream.id);
      // }

      // if (selectedStream) {
      //   formData.append("stream_id", selectedStream.map((value) => value.id));
      // }
      if (selectedDegree.length > 0) {
        selectedDegree.forEach((value) => {
          formData.append("degree_id", value.id);
        });
      }

      if (selectedStream.length > 0) {
        selectedStream.forEach((value) => {
          formData.append("stream_id", value.id);
        });
      }

      await AxiosInstance.post("/video", formData, config, {
        Authorization: `${token}`,
      })
        .then((response) => {
          if (response.status == 200) {
            toast.success("Video Posted Successfully");
            setTimeout(() => {
              Navigate("/");
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          console.log(error);
        });
    } catch (error) {
      console.log(error.error);
    }
  };

  return (
    <section style={{ width: "100%", height: "auto" }}>
      <article
        style={{
          width: "85%",
          height: "auto",
          margin: "0 auto",
          marginTop: "4%",
          background: "#efefef",
        }}
      >
        <Paper
          variant="outlined"
          style={{ border: "2px solid #9BA4B5", borderRadius: "10px" }}
        >
          <Box
            sx={{
              width: "100%",
              background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #9BA4B5",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#081f37",
                fontSize: {
                  xs: "20px", // Smaller font size on small screens
                  sm: "25px", // Medium font size on small to medium screens
                  md: "30px", // Larger font size on medium to large screens
                },
                textTransform: "capitalize",
                fontWeight: "600",
                letterSpacing: "1.02px",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              Post Video
            </Box>
          </Box>
          <form
            onSubmit={handleSubmit}
            style={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "2%",
            }}
          >
            <Grid container spacing={2} item={12}>
              <Grid md={4} xs={12}>
                <Item>
                  <TextField
                    fullWidth
                    label="Enter Video Name"
                    type="text"
                    name="videoName"
                    value={videoName}
                    require
                    onChange={(e) => setVideoName(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>

              <Grid md={4} xs={12}>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Video Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={videoType}
                      label="Video Type"
                      onChange={(e) => {
                        setVideoType(e.target.value);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 10)", 
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 10)", 
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 5)", 
                        },
                      }}
                    >
                      {Object.entries(videoTypeOption).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
              <Grid md={4} xs={12}>
                <Item>
                  <FormControl fullWidth>
                    <TextField
                      type="file"
                      onChange={handleFileChange}
                      inputProps={{ accept: "video/*" }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderColor: "rgba(0, 0, 0, 10)",
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 5)",
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Item>
              </Grid>          
              <Grid md={4} xs={12}>

               <Item>
                  <TextField                   
                    label="Enter In Minutes"
                    type="tel"
                    fullWidth
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ minLength: 1, maxLength: 3 }}
                    name="duration"
                    value={duration}
                    required
                    onChange={(e) => setDuration(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
                </Item>
              </Grid>
             
              <Grid md={4} xs={12}>
                <Item
                  onChange={(e) => {
                    setSearchVideo(e.target.value);
                  }}
                >
                  <Autocomplete
                    options={previousVideoOption || []}
                    value={seletedPreviousVideo}
                    name="state_name"
                    onChange={(event, newVideo) => {
                      setSeletedPreviousVideo(newVideo);
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Previous Video"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
             
              <Grid md={4} xs={12}>
               <Item>
                  <TextField
                    name="date_time"
                    fullWidth
                    type="datetime-local"
                    id="date_time"
                    required
                    value={date_time}
                    onChange={(e) => setDate_time(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderColor: "rgba(0, 0, 0, 10)",
                        "& fieldset": {
                          borderColor: "rgba(0, 0, 0, 5)",
                        },
                      },
                    }}
                  />
              </Item>
              </Grid>
              <Grid md={4} xs={12}>
                <Item>
                <Checkbox
                  name="is_default_video"
                  checked={is_default_video}
                  fullWidth
                  onChange={handleDefaultVideo}
                  id="is_default_video"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 25 } }}
                />{" "}
                <label htmlFor="quiz" style={{ fontSize: "16px" }}>
                  Default Video
                </label>
                </Item>
              </Grid>
              
              <Grid md={4} xs={12}>
                <Item
                  onChange={(e) => {
                    setDegTerm(e.target.value);
                  }}
                >
                  {/* <Autocomplete
                        options={degreeOptions}
                        value={selectedDegree}
                        name="degree_name"
                        onChange={(event, newdegree) => {
                          setselectedDegree(newdegree);
                        }}
                        getOptionLabel={(option) =>
                          option.name +
                          ` (${(option.short_name || "").toUpperCase()})`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Degree"
                            variant="outlined"
                            required={isDegreeMandatory}
                          />
                        )}
                      /> */}

                  <Autocomplete
                    multiple
                    required={isDegreeMandatory}
                    value={selectedDegree}
                    onChange={(event, newValue) => {
                      setselectedDegree([...newValue]);
                    }}
                    options={degreeOptions}
                    getOptionLabel={(option) =>
                      option.name + ` (${option.short_name.toUpperCase()})`
                    }
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Degree"
                        color="warning"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
             
              <Grid md={4} xs={12}>
                <Item
                  onChange={(e) => {
                    setStreamTerm(e.target.value);
                  }}
                >
                  {/* <Autocomplete
                        options={streamsOptions}
                        value={selectedStream}
                        name="stream_name"
                        onChange={(event, newstream) => {
                          setselectedStream(newstream);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search Stream"
                            variant="outlined"
                            required={isStreamMandatory}
                          />
                        )}
                      /> */}

                  <Autocomplete
                    multiple
                    required={isStreamMandatory}
                    value={selectedStream}
                    onChange={(event, newValue) => {
                      setselectedStream([...newValue]);
                    }}
                    options={streamsOptions}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Streams"
                        color="warning"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderColor: "rgba(0, 0, 0, 10)",
                            "& fieldset": {
                              borderColor: "rgba(0, 0, 0, 5)",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Item>
              </Grid>
              {/* <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    State :
                  </Grid>
                  <Grid Grid item xs={7.5}>
                    <Item
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    >
                      <Autocomplete
                        options={stateOption}
                        value={stateData}
                        name="stateOption"
                        onChange={(event, newData_xy) => {
                          setStateData(newData_xy);
                          setErrorForState(newData_xy === null);
                          setDistrictData(null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search State"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ alignItems: "center", display: "flex" }}
                  >
                    District :
                  </Grid>
                  <Grid Grid item xs={7.5}>
                    <Item
                      onChange={(e) => {
                        setSearchDistrict(e.target.value);
                      }}
                    >
                      <Autocomplete
                        options={districtOption}
                        value={districtData}
                        name="districtOption"
                        onChange={(event, newData_xy) => {
                          setDistrictData(newData_xy);
                          setErrorForDistrict(newData_xy === null);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search District"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </Item>
                  </Grid> */}

              <Grid
                xs={12}
                item
                md={12}
                spacing={2}
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    width: "7%",
                    color: "#F5F5F5",
                    backgroundColor: "#1976d2",
                    fontWeight: "bold",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </article>
    </section>
  );
}
