import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import { FaUser } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
};

export default function ContactModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title="Spoc Contact Details">
        <Button
          onClick={handleOpen}
          sx={{
            "&:hover": { background: "#DBDFFD" },
          }}
        >
          <FaUser
            fontSize={18}
            style={{ color: "#646FD4", "&:hover": { color: "#EB455F" } }}
          />
        </Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <AiFillCloseSquare
              fontSize={30}
              onClick={handleClose}
              color="#FC2947"
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "30px", fontWeight: "bold" }}
          >
            Spoc Contact Information
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: "#495579" }}>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Name
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Mobile
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    {props.name === null || props.name === ""
                      ? "NA"
                      : props.name}
                  </TableCell>
                  <TableCell align="center">
                    {props.mobile === null ||
                    props.mobile === "" ||
                    props.mobile === undefined
                      ? "NA"
                      : props.mobile.map((value) => {
                          return <Box>{value.mobile}</Box>;
                        })}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {props.email === null || props.email === ""
                      ? "NA"
                      : props.email.map((value) => {
                          return <Box>{value.email}</Box>;
                        })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
